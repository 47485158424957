import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledAutoComplete = styled(Box)(() => ({
  '.MuiInput-input': {
    cursor: 'pointer',
  },
  '& .dropdown-arrow-img': {
    width: '16px',
    aspectRatio: 'auto',
    position: 'absolute',
    right: '8px',
    cursor: 'pointer',
  },
}));
