import { useRef, useState, useCallback, useMemo, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from 'common/components/tooltip';
import useClickAway from 'common/utils/hooks/useClickAway';
import { ReactComponent as FilterIcon } from 'images/icons/gh_filter.svg';
import { ReactComponent as CloseChipIcon } from 'images/icons/chipDelete.svg';
import {
  clearAllFilters,
  setIsUnassigned,
  selectFilterModel,
  selectIsQuickFilterApplied,
  removeColumnFilter,
} from '../slice';
import { handleUnassigned } from '../utils';
import {
  StyledStack,
  StyledGridFilters,
  StyledChip,
  StyleFilterIconContiner,
  StyledChipContainer,
  StyledChipDropDown,
  StyledDropDown,
  StyledDropDownItems,
  StyledClearAll,
  StyledDropdownContainer,
  StyledChipFilter,
  StyledFilterRightPanel,
} from './style';
import useTexts from './useTexts';
import { COLUMN_FILTER_TYPES } from '../grid/constants';
import AssignessFilter from './Assignees/AssigneesFilter';
import StatusesFilter from './Status';
import useGetFiltersWithDisplayKeys from './useGetFiltersWithDisplayKeys';
import SearchText from './SearchText';

export default function GridFilters() {
  const dispatch = useDispatch();
  const filterList = useSelector(selectFilterModel);
  const isQuickFilterApplied = useSelector(selectIsQuickFilterApplied);
  const [splitIndex, setSplitIndex] = useState(-1);
  const [openDropDown, setOpenDropDown] = useState(false);
  const [shouldRecalculateChipList, setShouldRecalculateChipList] = useState(false);

  const chipContainerRef = useRef();
  const chipDropDownRef = useRef(null);
  const { clearAllText, noValue } = useTexts();

  const filterModel = useGetFiltersWithDisplayKeys(filterList);

  useClickAway(chipDropDownRef, () => {
    setOpenDropDown(false);
  });

  // ST-TODO: Need to work on the responsiveness of the GridFilters as soon as the grid table undergoes some changes.
  // const width = useResizeObserver(chipContainerRef);

  const handleResize = useCallback(() => {
    setTimeout(() => {
      setSplitIndex(-1);
      setShouldRecalculateChipList(true);
    }, 1000);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const filteredModel = useMemo(
    () =>
      filterModel?.filter(
        (filter) =>
          filter?.field !== 'assignee' &&
          filter?.field !== 'assignedGroup' &&
          filter?.field !== 'status' &&
          filter?.field !== 'archive' &&
          (isQuickFilterApplied ? filter?.field !== 'srType' : true),
      ),
    [filterModel, isQuickFilterApplied],
  );

  const isClearAllShowed = useMemo(
    () => Boolean(filterModel?.filter((filter) => (isQuickFilterApplied ? filter?.field !== 'srType' : true))?.length),
    [filterModel, isQuickFilterApplied],
  );

  const calcSplitIndex = useCallback(() => {
    const containerRight = chipContainerRef.current.getBoundingClientRect().right;
    const index = Array.from(chipContainerRef.current.childNodes).findIndex(
      (node) =>
        node.getBoundingClientRect().right > containerRight || node.classList.contains('dropdown-chip-container'),
    );
    setSplitIndex(index);
  }, []);

  useLayoutEffect(() => {
    calcSplitIndex();
  }, [filteredModel, calcSplitIndex]);

  useLayoutEffect(() => {
    if (shouldRecalculateChipList) {
      calcSplitIndex();
      setShouldRecalculateChipList(false);
    }
  }, [shouldRecalculateChipList, calcSplitIndex]);

  const { visibleChips, dropdownChips } = useMemo(
    () =>
      splitIndex > -1
      ? { visibleChips: filteredModel?.slice(0, splitIndex), dropdownChips: filteredModel?.slice(splitIndex) }
      : { visibleChips: filteredModel },
    [filteredModel, splitIndex],
  );

  const handleDelete = useCallback(
    (chip) => () => {
      dispatch(removeColumnFilter({ field: chip.field }));
      if (chip.field === COLUMN_FILTER_TYPES.assignee && chip.values.includes(COLUMN_FILTER_TYPES.none)) {
        dispatch(setIsUnassigned(false));
      }
    },
    [dispatch],
  );

  const handleClearAll = useCallback(() => {
    dispatch(clearAllFilters());
  }, [dispatch]);

  const toggleDropdown = () => setOpenDropDown((prev) => !prev);

  const addSpace = (value, withDescription) => {
    if (!value) {
      return '';
    }
    const newValue = value.replaceAll(',', ', ');
    if (withDescription?.fieldType?.id === 1 && withDescription?.type !== 'equals') {
      let val;
      if (withDescription?.type === 'lessThan') {
        val = 'less than';
      } else if (withDescription?.type === 'greaterThan') {
        val = 'greater than';
      }
      const key = newValue.slice(0, newValue.indexOf(':') + 1);
      const value = newValue.slice(newValue.indexOf(':') + 1, newValue.length);
      return (
        <span>
          {key}
          <StyledChipFilter>{val}</StyledChipFilter>
          {value}
        </span>
      );
    }
    return newValue;
  };

  return (
    <StyledGridFilters>
      <StyledStack direction="row" spacing={1}>
        <StyleFilterIconContiner data-testid="queue-filter-icon">
          <FilterIcon />
        </StyleFilterIconContiner>

        <AssignessFilter filterModel={filterModel} />

        <StatusesFilter statusFilter={filterModel.find((filter) => filter.field === 'status')} />

        <StyledChipContainer ref={chipContainerRef}>
          {visibleChips?.map((chip) => {
            let chipValue = handleUnassigned(chip?.name, chip?.displayKeys || chip?.values || '');
            //Check if value text doesnt found. For example displayKeys can consist array with empty text something like that [''].
            //In that case we should show No Value text.
            if (!chipValue.join()) {
              chipValue = noValue;
            }
            const chipText = addSpace(`${chip?.name}: ${chipValue}`, chip);
            return (
              <StyledChip
                id={`chip_${chip?.field}`}
                key={chip?.field}
                label={
                  <Tooltip
                    placement="top"
                    isTruncatedText
                    title={chipText}
                    text={chipText}
                    style={{ fontSize: '0.875rem', lineHeight: 1.2858 }}
                  />
                }
                variant="outlined"
                onDelete={handleDelete(chip)}
                deleteIcon={<CloseChipIcon />}
              />
            );
          })}
          {dropdownChips?.length > 0 && (
            <StyledDropdownContainer ref={chipDropDownRef} className="dropdown-chip-container">
              <StyledChipDropDown onClick={toggleDropdown} label={`+${dropdownChips.length}`} variant="outlined" />
              {openDropDown && (
                <StyledDropDown>
                  {dropdownChips.map((chip) => (
                    <StyledDropDownItems
                      key={chip?.field}
                      label={
                        <Tooltip
                          isTruncatedText
                          title={addSpace(`${chip?.name}: ${chip?.displayKeys}`)}
                          text={addSpace(`${chip?.name}: ${chip?.displayKeys}`)}
                          style={{ fontSize: '0.875rem', lineHeight: 1.2858 }}
                        />
                      }
                      variant="outlined"
                      onDelete={handleDelete(chip)}
                      deleteIcon={<CloseChipIcon />}
                    />
                  ))}
                </StyledDropDown>
              )}
            </StyledDropdownContainer>
          )}
        </StyledChipContainer>
        {isClearAllShowed && (
          <StyledClearAll aria-label="clear-all" onClick={() => handleClearAll()}>
            {clearAllText}
          </StyledClearAll>
        )}
      </StyledStack>
      <StyledFilterRightPanel>
        <SearchText />
      </StyledFilterRightPanel>
    </StyledGridFilters>
  );
}
