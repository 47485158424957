import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDatePickerActions = styled(Box)(() => ({
  boxSizing: 'border-box',
  borderRadius: '0 0 10px 10px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: '24px',

  '&:last-child div': {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
}));

export const ClearButton = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isDirty',
})(({ theme, isDirty }) => ({
  fontSize: '0.8rem',
  color: isDirty ? theme.palette.generic.sysaid : theme.palette.text.secondary_txt,
  fontFamily: theme.fonts.robotoRegular,
  '&:hover': {
    cursor: 'pointer',
  },
}));
