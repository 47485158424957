import { AvatarGroup } from '@mui/material';
import { useCallback, useMemo } from 'react';

import { AvatarGroupWrapper } from 'common/components/avatars/avatarGroupWrapper';
import { BasicAvatar } from 'common/components/avatars/basicAvatar';
import { AVATAR_CONSTANTS } from 'constants/index';
import { isNone } from 'common/utils/utils';
import Tooltip from 'common/components/tooltip';

import { StyledAssigneeButton } from './style';
import { ASSIGNEE_CONSTANTS } from '../constants';
import useText from '../useText';

export const AssigneeButton = ({ currentAssigned, avatarSize, disabled, handleClick, isRequired }) => {
  const isUnassigned = currentAssigned.user === ASSIGNEE_CONSTANTS.UNASSIGNED;
  const hasGroup = !isNone(currentAssigned.group?.groupName);
  const hasUser = !isNone(currentAssigned.user?.calculatedUserName);
  const { getColumnText } = useText();
  const renderCurrentAssignedAvatars = useCallback(() => {
    if (isUnassigned) {
      return (
        <BasicAvatar
          type={AVATAR_CONSTANTS.TYPES.UNASSIGNED}
          size={avatarSize}
          disableHover={disabled}
          data-testid="unassigned-avatar"
        />
      );
    }
    return (
      <AvatarGroup component={({ children }) => <AvatarGroupWrapper size={avatarSize}>{children}</AvatarGroupWrapper>}>
        {hasGroup && (
          <BasicAvatar
            type={AVATAR_CONSTANTS.TYPES.GROUP}
            size={avatarSize}
            disableHover={disabled}
            data-testid="basic-avatar"
          />
        )}
        {hasUser && (
          <BasicAvatar
            size={avatarSize}
            userName={currentAssigned?.user?.calculatedUserName}
            profileImage={currentAssigned?.user?.profileImage}
            disableHover={disabled}
            data-testid="basic-avatar"
          />
        )}
      </AvatarGroup>
    );
  }, [
    avatarSize,
    currentAssigned?.user?.calculatedUserName,
    currentAssigned?.user?.profileImage,
    disabled,
    hasGroup,
    hasUser,
    isUnassigned,
  ]);

  const assigneesNames = useMemo(() => {
    if (isUnassigned) return getColumnText(ASSIGNEE_CONSTANTS.UNASSIGNED.calculatedUserName);
    if (!hasGroup) return currentAssigned.user.calculatedUserName;
    if (!hasUser) return currentAssigned.group.groupName;
    return `${currentAssigned.group.groupName} / ${currentAssigned?.user?.calculatedUserName}`;
  }, [
    currentAssigned?.group?.groupName,
    currentAssigned?.user?.calculatedUserName,
    hasGroup,
    hasUser,
    isUnassigned,
    getColumnText,
  ]);

  return (
    <StyledAssigneeButton
      onClick={handleClick}
      data-testid="assignee-button"
      disabled={disabled}
      hasError={isUnassigned && isRequired}
    >
      {renderCurrentAssignedAvatars()}
      <Tooltip isTruncatedText text={assigneesNames}>
        {assigneesNames}
      </Tooltip>
    </StyledAssigneeButton>
  );
};
