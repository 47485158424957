import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTemplateBuilderPanel = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  gap: '24px',
  '@media only screen and (max-width: 1440px)': {
    gap: '12px',
  },
  height: 'calc(100% - 116px)',

  ' > div': {
    width: '100%',
    flexShrink: 3,
    background: theme.palette.generic.primary,
    border: theme.shape.border.border,
    borderRadius: '10px',
  },
  '.template-left, .template-right': {
    maxWidth: '460px',
    width: '100%',
    height: '100%',
    flexBasis: '235px',
    flexGrow: 1,
    flexShrink: 1,
  },
  '.template-middle': {
    flexShrink: 2,
    flexGrow: 2,
    flexBasis: '482px',
    height: '100%',
    overflow: 'hidden',
  },
  h3: {
    margin: '0 0 10px',
    fontSize: theme.fontSize.medium,
    fontWeight: 'normal',
  },
}));
