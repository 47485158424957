import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

export const StyledMoreNewServiceRequests = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'direction',
})(({ theme, direction }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette?.text.title_txt,
  color: 'white',
  height: '48px',
  width: '48px',
  zIndex: 10,
  position: 'relative',
  transform: direction === 'right' ? 'scaleX(-1)' : undefined,
  borderRadius: '50%',
  cursor: 'pointer',
  transition: 'background-color 0.4s ease',
  '&:hover': {
    backgroundColor: theme.palette?.generic.sysaid,
  },
  '& svg': {
    color: theme.palette?.switchToggle.unchecked,
    width: '14px',
    height: '14px',
  },
}));
