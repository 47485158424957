import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { HIDE_KEBAB_MENU_TYPES } from '../../../constants';

export const StyledKebabMenu = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isHovered' && prop !== 'logType' && prop !== 'isTicketLocked',
})(({ isHovered, logType, isTicketLocked }) => ({
  visibility: isHovered && !HIDE_KEBAB_MENU_TYPES.includes(logType) && !isTicketLocked ? 'visible' : 'hidden',
  cursor: 'pointer',
}));

export const StyledMenu = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '180px',
  boxShadow: '0px 2px 8px rgba(118, 130, 150, 0.35)',
  borderRadius: '6px',
  backgroundColor: 'white',
  position: 'absolute',
  padding: '8px 0px',
  zIndex: 3,
}));

export const StyledActionItem = styled(Box)(() => ({
  display: 'flex',
  height: '46px',
  padding: '11px 16px',
  fontSize: '14px',
  color: '#4F4F4F',
  gap: '10px',
  alignItems: 'center',
  ':hover': {
    backgroundColor: '#F5F6FA',
  },
}));
