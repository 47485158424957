import { UploadProgressBar } from 'common/components/uploadFile/uploadProgressBar';
import { StyledProgressBar } from './style';

export function ProgressBar({ id, fileName, error, uploadProgress, onClose, isActionItem }) {
  return (
    <StyledProgressBar isActionItem={isActionItem}>
      <UploadProgressBar id={id} fileName={fileName} value={uploadProgress} error={error} onClose={onClose} />
    </StyledProgressBar>
  );
}
