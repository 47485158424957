import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import {
  MultiSelectFieldWrapper as CommonMultiSelectFieldWrapper,
  StyledMultiSelectField as CommonStyledMultiSelectField,
  StyledListWrapper as CommonStyledListWrapper,
} from 'common/components/controls/MultiSelectField/style';

export const MultiSelectFieldWrapper = styled(CommonMultiSelectFieldWrapper, {
  shouldForwardProp: (prop) => !['isDropdownOpen', 'error'].includes(prop),
})(({ disabled }) => ({
  borderRadius: '4px',
  marginLeft: '-8px',
  '& .multi-select-field': {
    padding: '2px 0',
  },
  '&:hover': {
    cursor: disabled ? 'default' : 'pointer',
  },
}));

export const StyledMultiSelectField = styled(CommonStyledMultiSelectField)(() => ({
  border: 'none',
}));

export const StyledListWrapper = styled(CommonStyledListWrapper, {
  shouldForwardProp: (prop) => prop !== 'listHeight',
})(() => ({
  maxHeight: '32px',
  padding: '0 8px',
}));

export const StyledChipContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'setWidth',
})(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '5px',
  overflowX: 'hidden',
}));
