import { styled } from '@mui/material/styles';
import { Box, Chip, Stack } from '@mui/material';

export const StyledGridFilters = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '10px 0 10px 10px',
  borderRadius: '10px 10px 0 0',
  backgroundColor: theme.palette.grey.bt_alt_grey_light,
}));

export const StyledStack = styled(Stack)(() => ({
  '&&': {
    justifyContent: 'flex-start',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '100%',
  },
}));

export const StyleFilterIconContiner = styled(Box)(() => ({
  margin: '10px',
  width: '16px',
  height: '16px',
  objectFit: 'contain',
}));
export const StyledChipFilter = styled('span')(({ theme }) => ({
  color: theme.palette.grey.chip_grey,
  margin: '0 1px',
}));
export const StyledChipContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'setWidth',
})(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '16px',
  overflowX: 'hidden',
}));

export const StyledChip = styled(Chip)(({ theme }) => ({
  '&&': {
    height: 'fit-content',
    maxWidth: '200px',
    flexGrow: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3px 8px',
    borderRadius: '2px',
    border: 'none',
    backgroundColor: theme.palette.grey.bt_alt_grey_hover,
    position: 'relative',
  },

  '&& .MuiChip-label': {
    flexGrow: 0,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: theme.palette.text.title_txt,
    padding: 0,
  },

  '&&&:hover': {
    backgroundColor: theme.palette.grey.divider_grey_v3,
  },

  '&& .MuiChip-deleteIcon': {
    width: '16px',
    height: '16px',
    flexGrow: 0,
    objectFit: 'contain',
    margin: 0,
    marginLeft: '12px',
    overflow: 'visible',
  },
}));

export const StyledChipDropDown = styled(StyledChip)(({ theme }) => ({
  '&&': {
    maxWidth: '55px',
    marginLeft: '8px',
    height: 'fit-content',
    padding: '3px 8px',
    borderRadius: '2px',
    backgroundColor: theme.palette.grey.bt_alt_grey_hover,
    fontSize: '0.875rem',
    lineHeight: 1.2858,
  },
}));

export const StyledDropDown = styled(Box)(({ theme }) => ({
  padding: '12px',
  borderRadius: '10px',
  boxShadow: `0 0 8px 0 ${theme.palette.background.boxShadow}`,
  backgroundColor: theme.palette.generic.primary,
  zIndex: 10,
  position: 'absolute',
  // right: 0,
  top: '35px',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  maxWidth: '224px',
}));

export const StyledDropDownItems = styled(StyledChip)(() => ({
  '&&': {
    justifyContent: 'space-between',
  },
}));

export const StyledClearAll = styled(Box)(({ theme }) => ({
  // fontFamily: theme.fonts.robotoLight,
  fontSize: '0.75rem',
  lineHeight: 1.667,
  textAlign: 'center',
  color: theme.palette.generic.sysaid,
  cursor: 'pointer',
  minWidth: '40px',
}));

export const StyledDropdownContainer = styled(Box)(() => ({}));
