import { useCallback, useEffect, useMemo, useState } from 'react';
import { useStatusList } from 'remote-state/ticketServiceHooks';
import { SR_TYPE_CODE_MAP } from 'features/TicketPanel/constants';
import { fieldProperties, switchId } from '../constants';
import {
  getAllViewGroups,
  getSelectedGroupList,
  getSelectedStatusList,
  getUpdatedGroupList,
  getUpdatedStatusList,
  checkIsStatusOrPriorityField,
} from '../utils/utils';
import FieldNameProperty from './FieldNameProperty';
import HintProperty from './HintProperty';
import LimitEditProperty from './LimitEditProperty';
import RequiredFieldProperty from './RequiredFieldProperty';

export default function FieldPropertiesPanel(props) {
  const { isHeaderField, allGroups, fieldData, handleUpdateProperties, srType } = props;
  const [hideFieldCaption, setHideFieldCaption] = useState(fieldData?.hideFieldCaption || false);
  const [showLimitEdit, setShowLimitEdit] = useState(fieldData?.limitedPermissions || false);
  const [showRequiredField, setShowRequiredField] = useState(fieldData?.required);
  const [showHint, setShowHint] = useState(fieldData?.hint || false);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const isStatusOrPriorityField = checkIsStatusOrPriorityField(fieldData?.fieldId);
  const srTypeValue = SR_TYPE_CODE_MAP[srType];
  const getAll = true;
  const statusListInput = useMemo(() => ({ srType, getAll }), [srType, getAll]);
  const { data: allStatuses } = useStatusList(statusListInput);

  useEffect(() => {
    setShowRequiredField(fieldData?.required);
    setHideFieldCaption(fieldData?.hideFieldCaption);
    setShowHint(fieldData?.hint);
    setShowLimitEdit(
      fieldData?.limitedPermissions ||
        (isStatusOrPriorityField && !fieldData?.required && !fieldData?.permissions?.length),
    );
  }, [
    isStatusOrPriorityField,
    fieldData?.hint,
    fieldData?.required,
    fieldData?.hideFieldCaption,
    fieldData?.limitedPermissions,
    fieldData?.permissions,
  ]);

  useEffect(() => {
    if (!fieldData) return;
    if (!isStatusOrPriorityField) {
      setSelectedStatuses(getSelectedStatusList(fieldData?.requiredStatuses));
    }
  }, [fieldData, isStatusOrPriorityField]);

  useEffect(() => {
    if (!fieldData) return;
    const updatedPermissionsProperties = {};
    if (fieldData?.limitedPermissions && !fieldData?.permissions?.length) {
      // If limited permission property is true and no groups are selected, setting all groups as 'view'.
      updatedPermissionsProperties[fieldProperties.permissions] = getAllViewGroups(allGroups);
      updatedPermissionsProperties[fieldProperties.limitedPermissions] = fieldData?.limitedPermissions;
    }
    if (fieldData?.limitedPermissions && fieldData?.permissions?.length) {
      setSelectedGroups(getSelectedGroupList(fieldData?.permissions));
    }

    if (Object.keys(updatedPermissionsProperties).length) {
      handleUpdateProperties(updatedPermissionsProperties);
    }
  }, [allGroups, fieldData, handleUpdateProperties]);

  const handleStatusChange = useCallback(
    (selectedStatusList, isShowRequiredField = showRequiredField) => {
      const updatedProperties = {
        [fieldProperties.required]: isShowRequiredField,
        [fieldProperties.requiredStatuses]: getUpdatedStatusList(selectedStatusList),
      };
      handleUpdateProperties(updatedProperties);
    },
    [handleUpdateProperties, showRequiredField],
  );

  const handleFieldCaptionChange = (isHideFieldCaption = hideFieldCaption) => {
    const updatedProperties = {
      [fieldProperties.hideFieldCaption]: isHideFieldCaption,
    };
    handleUpdateProperties(updatedProperties);
  };

  const handleHintChange = (hintText, isShowHint = showHint) => {
    const updatedProperties = {
      [fieldProperties.hint]: isShowHint,
      [fieldProperties.hintText]: hintText,
    };
    handleUpdateProperties(updatedProperties);
  };

  const handleGroupChange = useCallback(
    (selectedGroupList, isShowLimitChecked = showLimitEdit) => {
      const updatedProperties = {
        [fieldProperties.permissions]: selectedGroupList.length ? getUpdatedGroupList(selectedGroupList) : [],
        [fieldProperties.limitedPermissions]: isShowLimitChecked,
      };
      handleUpdateProperties(updatedProperties);
    },
    [handleUpdateProperties, showLimitEdit],
  );

  const handleSwitchChange = (event) => {
    const {
      target: { id, checked },
    } = event;
    switch (id) {
      case switchId.REQUIRED_FIELD:
        setShowRequiredField(checked);
        handleStatusChange([], checked);
        break;
      case switchId.HIDE_FIELD_CAPTION:
        setHideFieldCaption(checked);
        handleFieldCaptionChange(checked);
        break;
      case switchId.HINT:
        setShowHint(checked);
        handleHintChange('', checked);
        break;
      case switchId.LIMIT_EDIT:
        setShowLimitEdit(checked);
        handleStatusChange([], !checked);
        handleGroupChange([], checked);
        break;
      default:
        setShowRequiredField(false);
        setHideFieldCaption(false);
        setShowHint(false);
        setShowLimitEdit(false);
    }
  };

  return (
    <>
      {!isStatusOrPriorityField && (
        <RequiredFieldProperty
          srType={srTypeValue}
          switchId={switchId.REQUIRED_FIELD}
          showRequiredField={showRequiredField}
          onSwitchChange={handleSwitchChange}
          isSwitchDisabled={showLimitEdit}
          handleStatusChange={handleStatusChange}
          selectedStatuses={selectedStatuses}
          allStatuses={allStatuses}
        />
      )}
      {!isHeaderField && (
        <>
          <FieldNameProperty
            switchId={switchId.HIDE_FIELD_CAPTION}
            onSwitchChange={handleSwitchChange}
            isSwitchDisabled={false}
            hideFieldCaption={hideFieldCaption}
          />
          <HintProperty
            switchId={switchId.HINT}
            showHint={showHint}
            onSwitchChange={handleSwitchChange}
            isSwitchDisabled={false}
            handleHintChange={handleHintChange}
            hintText={fieldData?.hintText}
          />
        </>
      )}
      <LimitEditProperty
        switchId={switchId.LIMIT_EDIT}
        showLimitEdit={showLimitEdit}
        onSwitchChange={handleSwitchChange}
        isSwitchDisabled={isStatusOrPriorityField ? false : showRequiredField}
        handleGroupChange={handleGroupChange}
        selectedGroups={selectedGroups}
        allGroups={allGroups}
      />
    </>
  );
}
