import { StyledPopupButton } from './style';

export default function PopupButton(props) {
  const { variant, action, onAction, isDisabled, dataTestId } = props;

  const actionHandler = (isToSave) => () => {
    onAction(isToSave);
  };

  return (
    <StyledPopupButton disabled={isDisabled} variant={variant} onClick={actionHandler(action)} data-testid={dataTestId}>
      {props.children}
    </StyledPopupButton>
  );
}
