import { convertFromClientField } from 'common/utils/fieldUtils';

export const mapFieldsToSectionsRows = (template) => {
  const sections = template.sections;
  if (!sections.length) return template;
  const newSections = sections.map((section) => {
    const fields = section.fields;
    if (!fields.length) return section;
    const sectionRows = [
      {
        rowOrder: 1,
      },
    ];
    let sectionRowsCount = 1;
    const sectionFields = [];
    for (let i = 0; i < fields.length; i++) {
      const fieldDefaultValue =
        fields[i].fieldName === 'description' && fields[i].defaultValue?.jsonContent
          ? JSON.stringify([fields[i].defaultValue])
          : fields[i].defaultValue;
      const isFieldStretch = fields[i].position === 'STRETCH';

      let currentField = convertFromClientField(fields[i], 'fieldId');

      currentField = {
        ...currentField,
        templateFieldAttributes: fields[i].templateFieldAttributes
          ? { ...currentField.templateFieldAttributes, id: currentField.fieldId, defaultValue: fieldDefaultValue }
          : undefined,
      };
      if (i === 0) {
        currentField = {
          ...currentField,
          position: isFieldStretch ? 'STRETCH' : 'LEFT',
        };
        sectionRows[0].fields = [currentField];
      } else if (
        isFieldStretch ||
        fields[i - 1].position === 'STRETCH' ||
        sectionRows[sectionRowsCount - 1]?.fields?.length === 2
      ) {
        currentField = {
          ...currentField,
          position: isFieldStretch ? 'STRETCH' : 'LEFT',
        };
        sectionRows.push({ rowOrder: sectionRowsCount + 1, fields: [currentField] });
        sectionRowsCount++;
      } else {
        currentField = {
          ...currentField,
          position: 'RIGHT',
        };
        sectionRows[sectionRowsCount - 1].fields.push(currentField);
      }
      sectionFields.push(currentField);
    }
    return { ...section, sectionRows, fields: sectionFields };
  });
  return { ...template, sections: newSections };
};
