import { styled } from '@mui/material/styles';

export const IdFieldWrapperStyled = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const CellComponentWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'fullWidth',
})(({ fullWidth = false }) => ({
  maxWidth: '100%',
  width: fullWidth ? '100%' : undefined,
}));
