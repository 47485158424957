import { memo, useCallback, useState, useContext, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useQueryClient } from '@tanstack/react-query';
import { getUserDetailsByName } from 'services/userService';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import MessagePrompt from 'common/components/messagePrompt';
import CustomIconButton from 'common/components/buttons/IconButton/index';
import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';
import { QUERIES_KEYS } from 'constant';
import { PENDO_TRACK_EVENTS } from 'constants/pendoTrackEvents';
import usePendoTrackEvents from 'common/utils/hooks/usePendoTrackEvents';
import { StyledTemplateButtonWrapper } from '../StyledTemplateButton';
import { StyledTemplateForm } from './StyledTemplateForm';
import useTexts from '../useTexts';
import TemplateSection from '../TemplateSection';
import FormHeader from './FormHeader';
import { CONSTANTS, fieldNames } from '../constants';
import { TemplateBuilderContext } from '../TemplateBuilderPanel/context';
import { useLinkedSrLists } from '../../../remote-state/ticketServiceHooks';

function TemplateForm(props) {
  const { updateAvailableFieldsDefaultValue } = props;
  const {
    templateId,
    sections,
    sectionsOrder: templateSectionsOrder,
    handleStateUpdate: updateSections,
    dependantFields,
    header,
  } = useContext(TemplateBuilderContext);
  const { newSectionText, maxSectionLimitTitle, maxSectionLimitText } = useTexts();
  const [showSectionsErrorModal, setShowSectionsErrorModal] = useState(false);
  const uuid = uuidv4();
  const queryClient = useQueryClient();

  const pendoTrackEvents = usePendoTrackEvents();

  const fields = useMemo(
    () => [...(header || []), ...(sections?.flatMap((row) => row.fields) || [])],
    [header, sections]
  );

  const initialSection = {
    id: uuid,
    name: '',
    nonSection: false,
    order: 0,
    instructions: null,
    fields: [],
    sectionRows: [],
    viewOnlyGroups: [],
    ticketTemplateSectionPermissions: [],
  };
  const handleAddSection = () => {
    if (sections.length < CONSTANTS.MAX_SECTIONS_PER_TEMPLATE) {
      const newSections = sections?.map((section) => ({ ...section, order: section.order + 1 }));
      const updatedSections = [initialSection, ...newSections];
      const tempSectionsOrder = { ...templateSectionsOrder };
      Object.keys(templateSectionsOrder).forEach((key) => {
        tempSectionsOrder[key] = Number(templateSectionsOrder[key]) + 1;
      });
      tempSectionsOrder[initialSection.id] = 0;
      updateSections([{ sections: updatedSections }, { sectionsOrder: tempSectionsOrder }]);
    } else {
      setShowSectionsErrorModal(true);
    }
  };

  const handleDeleteSection = useCallback(
    (id) => {
      const newSectionOrder = { ...templateSectionsOrder };
      const deletedSectionOrder = newSectionOrder[id];
      delete newSectionOrder[id];
      Object.keys(newSectionOrder).forEach((key) => {
        if (newSectionOrder[key] > deletedSectionOrder) {
          newSectionOrder[key] = Number(newSectionOrder[key] - 1);
        }
      });
      const newSections = sections.filter((section) => section.id !== id);
      updateSections([{ sections: newSections }, { sectionsOrder: newSectionOrder }]);
    },
    [sections, templateSectionsOrder, updateSections],
  );

  const handleUpdateSectionByValueArray = useCallback(
    (id, values) => {
      updateSections([
        {
          sections: sections.map((section) => {
            if (section.id === id) {
              return {
                ...section,
                ...values.reduce((prev, { value, property }) => ({ ...prev, [property]: value }), {}),
              };
            }
            return section;
          }),
        },
      ]);
    },
    [sections, updateSections],
  );

  const handleUpdateSection = useCallback(
    (id, value, property) => {
      updateSections([
        {
          sections: sections.map((section) => {
            if (section.id === id) {
              return { ...section, [property]: value };
            }

            return section;
          }),
        },
      ]);
    },
    [sections, updateSections],
  );

  const handleOkClick = () => {
    setShowSectionsErrorModal(false);
  };

  const handleChangeFieldValue = useCallback(
    async (value, sectionId) => {
      const isRichText = Object.keys(value)[0] === 'richTextFields';
      const fieldName = isRichText ? JSON.parse(value.richTextFields)[0]?.columnName : Object.keys(value)[0];
      const fieldValue = isRichText ? value.richTextFields : value[Object.keys(value)[0]];
      let fieldToUpdate;
      let newSections = sections.map((section) => ({
        ...section,
        fields: section.fields.map((field) => {
          if (section.id === sectionId && field.fieldName === fieldName) {
            fieldToUpdate = field;
            return { ...field, defaultValue: fieldValue };
          }
          return field;
        }),
      }));
      if (fieldName === fieldNames.requestUser) {
        const userName = fieldValue;
        const userQueryData = userName
          ? await queryClient.fetchQuery({
              queryKey: [QUERIES_KEYS.USER_DETAILS_BY_NAME, userName],
              queryFn: () => getUserDetailsByName(userName),
            })
          : {};
        updateAvailableFieldsDefaultValue(fieldNames.userDepartment, userQueryData.department);
        updateAvailableFieldsDefaultValue(fieldNames.userManager, userQueryData.userManagerName);
        updateAvailableFieldsDefaultValue(fieldNames.userAgreement, userQueryData.agreement);
        newSections = newSections.map((section) => ({
          ...section,
          fields: section.fields?.map((field) => {
            if (field.fieldName === fieldNames.userManager)
              return { ...field, defaultValue: userQueryData.userManagerName };
            if (field.fieldName === fieldNames.userDepartment)
              return { ...field, defaultValue: userQueryData.department };
            if (field.fieldName === fieldNames.userAgreement)
              return { ...field, defaultValue: userQueryData.agreement };
            return field;
          }),
        }));
      }
      if (fieldToUpdate.customColumn) {
        pendoTrackEvents(PENDO_TRACK_EVENTS.CUSTOM_COLUMN_POPULATED, {
          location: 'template',
          templateId,
          fieldName,
          fieldValue,
        });
      }
      updateSections([{ sections: newSections }]);
    },
    [sections, updateSections, updateAvailableFieldsDefaultValue, queryClient, templateId, pendoTrackEvents],
  );
  const formSections = useMemo(
    () =>
      sections?.map((section) => (
        <TemplateSection
          handleChangeFieldValue={handleChangeFieldValue}
          key={section.id}
          updateSection={handleUpdateSection}
          updateSectionByValuesArray={handleUpdateSectionByValueArray}
          onDeleteSection={handleDeleteSection}
          {...section}
        />
      )),
    [sections, handleChangeFieldValue, handleUpdateSection, handleUpdateSectionByValueArray, handleDeleteSection],
  );

  const formSectionsItems = useMemo(() => sections?.map((section) => `section-${section.id}`), [sections]);

  useLinkedSrLists({
    fields,
    dependantFields,
    updateSrFunc: (obj) => {
      const targetSectionId = fields?.find((field) => field.fieldName === Object.keys(obj)[0])?.sectionId;
      const newSections = sections.map((section) => ({
        ...section,
        fields: section.fields.map((field) => {
          if (section.sectionId === targetSectionId && field.fieldName === Object.keys(obj)[0]) {
            return { ...field, defaultValue: Object.values(obj)[0] }
          }
          return field;
        })
      }));
      updateSections([{ sections: newSections }]);
    },
  });

  return (
    <>
      <StyledTemplateForm>
        <FormHeader updateSections={updateSections} />
        <StyledTemplateButtonWrapper>
          <CustomIconButton
            data-testid="new-section-btn"
            className="newSectionBtn"
            onClick={handleAddSection}
            btnTheme="smallIconButtonText"
            text={newSectionText}
            icon={<PlusIcon />}
          />
        </StyledTemplateButtonWrapper>
        <div className="template-form" data-cy="sections-wrapper">
          {sections && (
            <SortableContext id="template-form" items={formSectionsItems} strategy={rectSortingStrategy}>
              {formSections}
            </SortableContext>
          )}
        </div>
      </StyledTemplateForm>
      {showSectionsErrorModal && (
        <MessagePrompt
          data-testid="message-prompt-sections"
          open
          title={maxSectionLimitTitle}
          onOkClick={handleOkClick}
        >
          {maxSectionLimitText}
        </MessagePrompt>
      )}
    </>
  );
}

export default memo(TemplateForm);
