import { useCallback } from 'react';
import { useMutation, useQuery, useInfiniteQuery } from '@tanstack/react-query';
import {
  createTemplate as createTemplateService,
  getAvailableFields,
  getTemplateDataByTemplateId,
  updateTemplate as updateTemplateService,
  duplicateTemplate as duplicateTemplateService,
  deleteTemplate as deleteTemplateService,
  setTemplateAsDefault,
  getAvailableColumns,
  getOOTBTemplateBySrType,
} from 'services/templateService';
import { convertClientColumnConfig } from 'features/queue/utils';
import { convertToClientField } from 'common/utils/fieldUtils';
import queryClient from './queryClient';

export function useTemplateData(srTypeOrTemplateId, source) {
  const options = {
    staleTime: 1000 * 60 * 15,
  };
  const isExisting = !!srTypeOrTemplateId && !Number.isNaN(Number(srTypeOrTemplateId));
  const queryByTemplateId = useQuery({
    queryKey: ['templateData', srTypeOrTemplateId],
    queryFn: () => getTemplateDataByTemplateId(srTypeOrTemplateId, source),
    enabled: Boolean(!!srTypeOrTemplateId && isExisting),
    select: useCallback(
      (template) => ({
        ...template,
        sections: template.sections.map((section) => ({
          ...section,
          sectionRows: section.sectionRows.map((sectionRow) => ({
            ...sectionRow,
            fields: sectionRow.fields.map((field) => convertToClientField(field, 'fieldId')),
          })),
        })),
      }),
      [],
    ),
    ...options,
  });
  const queryBySrType = useQuery({
    queryKey: ['templateBySrType', srTypeOrTemplateId],
    queryFn: () => getOOTBTemplateBySrType(srTypeOrTemplateId),
    enabled: Boolean(!!srTypeOrTemplateId && !isExisting),
    select: useCallback(
      (template) => ({
        ...template,
        sections: template.sections.map((section) => ({
          ...section,
          sectionRows: section.sectionRows.map((sectionRow) => ({
            ...sectionRow,
            fields: sectionRow.fields.map((field) => convertToClientField(field, 'fieldId')),
          })),
        })),
      }),
      [],
    ),
    ...options,
  });
  return isExisting ? queryByTemplateId : queryBySrType;
}

export const useGetTemplateById = (templateId, source) => {
  const options = {
    staleTime: 1000 * 60 * 15,
  };

  const select = useCallback(
    (template) => ({
      ...template,
      sections: template.sections.map((section) => ({
        ...section,
        sectionRows: section.sectionRows.map((sectionRow) => ({
          ...sectionRow,
          fields: sectionRow.fields.map((field) => convertToClientField(field, 'fieldId')),
        })),
      })),
    }),
    [],
  );

  const queryByTemplateId = useQuery({
    queryKey: ['templateData', templateId],
    queryFn: () => getTemplateDataByTemplateId(templateId, source),
    enabled: Boolean(templateId && templateId !== 'new'),
    select,
    ...options,
  });

  return queryByTemplateId;
};

export const useSaveTemplate = (templateId) => {
  const isNewTemplate = !templateId || templateId === 'new';
  const create = useMutation(createTemplateService, {
    onSuccess: (template) => {
      if (template) {
        queryClient.setQueryData(['templateData', template.id], (oldTemplate) => {
          const updatedTemplate = {
            ...oldTemplate,
            ...template,
          };
          return updatedTemplate;
        });
        queryClient.invalidateQueries({ queryKey: ['templatesList'] });
        queryClient.invalidateQueries({ queryKey: ['templatesListTotalSize'] });
      }
    },
  });
  const update = useMutation(updateTemplateService, {
    onSuccess: (template) => {
      if (template) {
        queryClient.setQueryData(['templateData', template.id], (oldTemplate) => {
          const updatedTemplate = {
            ...oldTemplate,
            ...template,
          };
          return updatedTemplate;
        });
        if (template.default) {
          queryClient.setQueryData(['defaultTemplate', template.srType], (oldData) => {
            const updatedTemplate = {
              ...oldData,
              ...template,
            };
            return updatedTemplate;
          });
        }
        queryClient.invalidateQueries({ queryKey: ['templatesList'] });
        queryClient.invalidateQueries({ queryKey: ['templatesListTotalSize'] });
      }
    },
  });
  return isNewTemplate ? create : update;
};

export const useDuplicateTemplate = () => {
  const duplicateTemplate = useMutation(duplicateTemplateService, {
    onSuccess: (template) => {
      if (template) {
        queryClient.setQueryData(['templateData', template.id], () => template);
        queryClient.invalidateQueries({ queryKey: ['templatesList'] });
        queryClient.invalidateQueries({ queryKey: ['templatesListTotalSize'] });
      }
    },
  });
  return duplicateTemplate;
};

export const useDeleteTemplate = (templateId) => {
  const deleteTemplate = useMutation(deleteTemplateService, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['templateData', templateId] });
      queryClient.invalidateQueries({ queryKey: ['templatesList'] });
      queryClient.invalidateQueries({ queryKey: ['templatesListTotalSize'] });
    },
  });
  return deleteTemplate;
};

export const useSetTemplateAsDefault = () =>
  useMutation(setTemplateAsDefault, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['templatesList'] });
      queryClient.invalidateQueries({ queryKey: ['defaultTemplate'] });
    },
  });

export const useLazyLoadFieldsList = (search) => {
  const { data, hasNextPage, fetchNextPage, isSuccess, isFetching } = useInfiniteQuery({
    queryKey: ['templateDesignerAvailableFields', 'infinite', search],
    queryFn: ({ pageParam = 0 }) => getAvailableFields({ search, page: pageParam }),
    getNextPageParam: (lastPage) => {
      const { last, pageNumber } = lastPage;
      const nextPage = pageNumber + 1;
      return !last ? nextPage : undefined;
    },
    select: (availableFields) => ({
      ...availableFields,
      pages: availableFields.pages.map((page) => ({
        ...page,
        currentList: page.currentList.map((field) => convertClientColumnConfig(field)),
      })),
    }),
  });

  return { data, hasNextPage, fetchNextPage, isSuccess, isFetching };
};

export const useLazyLoadAvialableColumsList = (search) => {
  const { data, hasNextPage, fetchNextPage, isSuccess, isFetching } = useInfiniteQuery({
    queryKey: ['columnOrderAvailableFields', 'infinite', search],
    queryFn: ({ pageParam = 0 }) => getAvailableColumns({ search, limit: 50, page: pageParam }),
    getNextPageParam: (lastPage) => {
      if (lastPage) {
        const { last, pageNumber } = lastPage;
        const nextPage = pageNumber + 1;
        return !last ? nextPage : undefined;
      }
      return 0;
    },
    select: (availableFields) => ({
      ...availableFields,
      pages: availableFields.pages.map((page) => ({
        ...page,
        currentList: page.currentList.map((field) => convertClientColumnConfig(field)),
      })),
    }),
  });

  return { data, hasNextPage, fetchNextPage, isSuccess, isFetching };
};
