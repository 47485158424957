import { useEffect, useRef, useState, useMemo, memo, useContext, useCallback } from 'react';
import { Box } from '@mui/material';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Scrollbars } from 'react-custom-scrollbars-2';
import ColumnAddInput from './ColumnAddInput';
import { ColumnListItem, ColumnListSortableItem } from './ColumnListItem';
import {
  ColumnListItemWrap,
  ColumnListWrapper,
  DnDContextContainer,
  ColumnListContainer,
  SearchResultBox,
} from './styledDrawer';
import { getHighlightedText } from './HighLight/HighLight';
import { COLUMN_MAX_LIMIT, PINNED_COLUMS_NAMES } from './constants';
import ActionBox from './ActionBox';
import { ColItemsContext } from './context';
import useTexts from './useTexts';
import useColumnTexts from '../customColumnHeader/useTexts';

const ColumnList = ({ searchStr }) => {
  const { items, setItems } = useContext(ColItemsContext);
  const { noSearchResult } = useTexts();
  const { getColumnText } = useColumnTexts();
  const [activeId, setActiveId] = useState(null);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const initialItemsOrder = useRef(JSON.stringify(items));
  const isMaxLimitReached = items.length >= COLUMN_MAX_LIMIT;
  const isDirty = useMemo(() => JSON.stringify(items) !== initialItemsOrder.current, [items]);
  const activeItem = useMemo(() => items.find((item) => item?.id === activeId), [activeId, items]);
  const activeItemTranslatedSortableName = getColumnText(activeItem?.displayName) || activeItem?.sortableName;
  const scrollbar = useRef(null);
  const highlightedItems = useMemo(() => {
    if (searchStr) {
      return items.reduce((result, item) => {
        const search = getHighlightedText(getColumnText(item.displayName), searchStr);
        if (search?.isFound) {
          result[item.id] = search.elem;
        }
        return result;
      }, {});
    }
    return {};
  }, [items, searchStr, getColumnText]);

  const scrollBoxHeight = useMemo(() => {
    const calculatedHeight = 44 * items.length;
    return calculatedHeight > windowHeight - 255 ? '100%' : `${calculatedHeight}px`;
  }, [windowHeight, items]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragStart = (event) => {
    const { active } = event;
    setActiveId(active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    // 2 is "ID" column id
    if (active.id !== over.id && over.id !== 2) {
      setItems((items) => {
        const oldIndex = items.findIndex(({ id }) => id === active.id);
        const newIndex = items.findIndex(({ id }) => id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
    setActiveId(null);
  };

  const customScrollToBottom = useCallback(() => {
    const clientHeight = scrollbar.current.getClientHeight();
    const scrollHeight = scrollbar.current.getScrollHeight();
    scrollbar.current.scrollToBottom(scrollHeight - clientHeight);
  }, []);

  const handleRemove = useCallback(
    (id) => {
      setItems((items) => items.filter((item) => item.id !== id));
    },
    [setItems],
  );

  const handleAdd = useCallback(
    (item) => {
      setItems((items) => [...items, item]);
      setTimeout(() => customScrollToBottom(), 0);
    },
    [setItems, customScrollToBottom],
  );

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const pinnedColumns = useMemo(() => items.filter((item) => PINNED_COLUMS_NAMES.includes(item.fieldName)), [items]);
  const SortableItem = memo(({ item, index, disabled, onRemove }) => {
    const translatedSortableName = getColumnText(item?.sortableName) || item?.sortableName;
    const isHighlighted = searchStr && highlightedItems[item.id] ? highlightedItems[item.id] : false;

    return (
      <ColumnListItemWrap data-testid="cc-list-item">
        <Box className="indexNumber">{index + 1}</Box>
        <ColumnListSortableItem
          isHighlighted={Boolean(isHighlighted)}
          onRemove={onRemove}
          disabled={disabled}
          id={item.id}
        >
          {isHighlighted || <span>{translatedSortableName}</span>}
        </ColumnListSortableItem>
      </ColumnListItemWrap>
    );
  });

  return (
    <ColumnListWrapper>
      <SearchResultBox>
        {Object.keys(highlightedItems).length === 0 && searchStr && <span>{noSearchResult}</span>}
      </SearchResultBox>
      <ColumnListContainer>
        <Box style={{ height: scrollBoxHeight }}>
          <Scrollbars
            ref={scrollbar}
            style={{ height: '100%' }}
            renderThumbVertical={(props) => <DnDContextContainer {...props} />}
          >
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
            >
              {pinnedColumns.map((item, index) => (
                <SortableItem key={item.id} item={item} index={index} onRemove={handleRemove} disabled />
              ))}
              <SortableContext items={items} strategy={verticalListSortingStrategy}>
                {items.map(
                  (item, index) =>
                    !PINNED_COLUMS_NAMES.includes(item.fieldName) && (
                      <SortableItem key={item.id} item={item} index={index} onRemove={handleRemove} disabled={false} />
                    ),
                )}
              </SortableContext>
              <DragOverlay>
                {activeId ? (
                  <ColumnListItem id={activeId} isDragging>
                    <span>{activeItemTranslatedSortableName}</span>
                  </ColumnListItem>
                ) : null}
              </DragOverlay>
            </DndContext>
          </Scrollbars>
        </Box>
        <ColumnAddInput onAdd={handleAdd} disabled={isMaxLimitReached} />
      </ColumnListContainer>
      <ActionBox isDirty={isDirty} />
    </ColumnListWrapper>
  );
};

export default memo(ColumnList);
