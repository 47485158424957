import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledActionItemStatusChange = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: 'auto',
  width: '100%',
  marginLeft: '20px',
  alignItems: 'center',
  img: {
    height: '21px',
  },
}));

export const StyledStatus = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '4px',
  alignItems: 'center',
  color: theme.palette.text.title_txt,
}));
