import sysAidLogo from 'images/photos/sysaid_logo.png';
import collapsedSysAidLogo from 'images/photos/logo_collapsed.png';
import StyledSysAidLogo from './style';
import { StyledCustomerLogoFooterWrapper } from '../navHeader/style';

const NavFooter = ({ expanded }) => (
  <StyledCustomerLogoFooterWrapper>
    <StyledSysAidLogo
      expanded={expanded}
      alt="sysaid-logo"
      src={expanded ? sysAidLogo : collapsedSysAidLogo}
      className="sysaid-logo"
    />
  </StyledCustomerLogoFooterWrapper>
);

export default NavFooter;
