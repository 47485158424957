const markDataMap = new Map();

const createPerformanceMarkWithData = (markName, data) => {
  performance.mark(markName);
  markDataMap.set(markName, data);
};

const getMarkAndData = (markName) => markDataMap.get(markName);

export { createPerformanceMarkWithData, getMarkAndData };
