import { useState } from 'react';
import { createPortal } from 'react-dom';
import FilterComponentByType from '../filterComponentByType';
import { StyledFilterIcon } from '../../style';

export default function ColumnFilter({ column }) {
  const [isFilterOpen, setFilterOpen] = useState(false);
  const toggleFilter = () => setFilterOpen((prev) => !prev);
  return (
    <div>
      <StyledFilterIcon className="columnHeaderFilterIcon" data-testid="header-filter-icon" onClick={toggleFilter} />
      {isFilterOpen && (
        <>
          {createPortal(
            <div className="columnHeaderFilterMenu">
              <FilterComponentByType column={column} toggleFilter={toggleFilter} />
            </div>,
            document.getElementById(`${column.id}Header`),
          )}
        </>
      )}
    </div>
  );
}
