import { useEffect, useState } from 'react';
import Froalaeditor from 'froala-editor';
import { pluginOverloads, customPlugins } from '../customPlugins';

const useCustomPlugins = () => {
  const [isConfigured, setIsConfigured] = useState(false);

  useEffect(() => {
    if (!isConfigured) {
      [...pluginOverloads, ...customPlugins].forEach((item) => {
        const { name, templateName, popupTemplate, callback } = item;
        Froalaeditor.POPUP_TEMPLATES[templateName] = popupTemplate;
        if (callback) {
          Froalaeditor.PLUGINS[name] = function froalaeditorPlugin(editor) {
            // don't change to arrow function - causes a bug in production
            callback({ editor, templateName, pluginName: name });
          };
        }
      });
      setIsConfigured(true);
    }
  }, [isConfigured]);
};

export { useCustomPlugins };
