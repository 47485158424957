const responseType = {
  USE_PRESIGNED_URL_FOR_UPLOAD: 'USE_PRESIGNED_URL_FOR_UPLOAD',
  OBJECT_ALREADY_EXISTS: 'OBJECT_ALREADY_EXISTS',
};

export { responseType };

// Attachments from File System has id = -1, the identifier to use for such files should be property "fileId"
// This identifier should not be changed without a reason
// If changed - needs to be synced with com.sysaid.attachments.mappers.AttachmentsMapper DUMMY_ATTACHMENT_ID
export const DUMMY_ATTACHMENT_ID = -1;