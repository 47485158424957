import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  getBulkActionsFields,
  deleteTicketsByIds,
  changeFieldValuesByIds,
  archiveTickets,
} from 'services/bulkActionsService';

export const useGetBulkActionsFieldsQuery = () =>
  useQuery(['bulkActionsFields'], () => getBulkActionsFields(), { placeholderData: [] });

export function useDeleteTicketsByIdsQuery() {
  const deleteTicketsMutation = useMutation(deleteTicketsByIds);

  const deleteTickets = async (ticketIds) => {
    const data = await deleteTicketsMutation.mutateAsync(ticketIds);
    return data;
  };

  return {
    deleteTickets,
    ...deleteTicketsMutation,
  };
}

export function useUpdateTicketsByIdsQuery() {
  const changeFieldTicketsMutation = useMutation(changeFieldValuesByIds);

  const changeFieldValues = async (ticketIds) => {
    const data = await changeFieldTicketsMutation.mutateAsync(ticketIds);
    return data;
  };

  return {
    changeFieldValues,
    ...changeFieldTicketsMutation,
  };
}

export function useArchiveTickets() {
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation({
    mutationFn: archiveTickets,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['queueData'] });
    },
  });

  return { archiveTickets: mutateAsync };
}
