import { useState, useEffect, memo, useContext, useRef, forwardRef } from 'react';
import { Box, Paper } from '@mui/material';
import { ReactComponent as PlusIcon } from 'images/icons/plus-icon.svg';
import Autocomplete from '@mui/material/Autocomplete';
import { useLazyLoadAvialableColumsList } from 'remote-state/templateHooks';
import InfiniteScroll from 'react-infinite-scroll-component';
import { StyledTooltip } from 'common/components/controls/Tooltip/Tooltip';
import { forIn } from 'lodash';
import usePendoTrackEvents from 'common/utils/hooks/usePendoTrackEvents';
import { PENDO_TRACK_EVENTS } from '../../../../constants';
import {
  StyledAddColumn,
  StyledAddIcon,
  StyledAutoComplete,
  StyledAddInput,
  StyledColumnLi,
  StyledFieldsList,
} from './styledDrawer';
import useTexts from './useTexts';
import { ColItemsContext } from './context';
import useColumnTexts from '../customColumnHeader/useTexts';
import { getAvialableFields, getColumLi } from './helper';

export const ListboxComponent = forwardRef((props, ref) => {
  const [scrollHeight, setScrollHeight] = useState('255px');
  const { children, handleFetchNextPage, hasNextPage } = props;
  const childrenAmount = children?.props?.children?.length;
  useEffect(() => {
    const height = childrenAmount ? `${Math.min(childrenAmount * 50, 250) + 6}px` : '52px';
    if (height !== scrollHeight) {
      setScrollHeight(height);
    }
  }, [childrenAmount, setScrollHeight, scrollHeight]);

  return (
    <Paper ref={ref}>
      <StyledFieldsList id="scrollableFieldsList" data-testid="scrollableFieldsList" style={{ height: scrollHeight }}>
        <InfiniteScroll
          dataLength={10}
          hasMore={hasNextPage}
          next={handleFetchNextPage}
          scrollableTarget="scrollableFieldsList"
        >
          {children}
        </InfiniteScroll>
      </StyledFieldsList>
    </Paper>
  );
});

export const UseAutocomplete = ({ onChange, value, existingColums, disabled }) => {
  const { addColumn, noResults } = useTexts();
  const [availableFieldsItems, setAvailableFieldsItems] = useState([]);
  const { data, fetchNextPage, isSuccess, isLoading, hasNextPage } = useLazyLoadAvialableColumsList('');
  const { getColumnText } = useColumnTexts();
  const inputRef = useRef();
  const pendoTrackEvents = usePendoTrackEvents();
  useEffect(() => {
    if (isSuccess && !isLoading && data?.pages) {
      setAvailableFieldsItems(getAvialableFields(data, existingColums, getColumnText));
    }
  }, [data, isSuccess, isLoading, existingColums, getColumnText]);
  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage]);
  return (
    <StyledAutoComplete>
      <Autocomplete
        data-testid="autocomplete"
        disablePortal
        id="custom-input-demo"
        disabled={disabled}
        loading={isLoading}
        options={availableFieldsItems}
        noOptionsText={noResults || 'This field does not exist'}
        onChange={(e, val) => {
          pendoTrackEvents(PENDO_TRACK_EVENTS.ADD_NEW_COLUMN, { val });
          onChange(val);
        }}
        // PopperProps={{
        //   anchorEl: inputRef.current,
        //   placement: 'bottom-start',
        //   disablePortal: true,
        // }}
        value={value}
        getOptionLabel={(option) => getColumnText(option?.sortableName) || option?.sortableName}
        renderOption={(props, option, { inputValue }) => {
          const { firstPart, matchPart, secondPart } = getColumLi(
            getColumnText(option?.sortableName) || option?.sortableName,
            inputValue,
          );
          return (
            <StyledColumnLi {...props} key={option.id}>
              {firstPart}
              <span className="match_part">{matchPart}</span>
              {secondPart}
            </StyledColumnLi>
          );
        }}
        PaperComponent={(props) => (
          <ListboxComponent {...props} handleFetchNextPage={fetchNextPage} hasNextPage={hasNextPage} />
        )}
        ListboxProps={{
          style: {
            maxHeight: 'none',
            overflow: 'auto',
            boxSizing: 'border-box',
          },
        }}
        renderInput={(params) => (
          <Box ref={params.InputProps.ref}>
            <StyledAddInput ref={inputRef} {...params.inputProps} placeholder={addColumn || 'Add a Column...'} />
          </Box>
        )}
      />
    </StyledAutoComplete>
  );
};

const ColumnAddInput = ({ onAdd, disabled }) => {
  const { errorText } = useTexts();
  const [selected, setSelected] = useState(null);
  const { items } = useContext(ColItemsContext);

  const handleAdd = (item) => {
    setTimeout(() => setSelected(() => null), 0);
    onAdd(item);
  };

  return (
    <StyledTooltip placement="top" title={disabled ? errorText : ''} arrow>
      <StyledAddColumn data-testid="addColumn">
        <Box className="addIconWrap">
          <StyledAddIcon disabled={disabled}>
            <PlusIcon />
          </StyledAddIcon>
        </Box>
        <Box className="addInputWrap">
          <UseAutocomplete
            onChange={(val) => {
              setSelected(val);
              handleAdd(val);
            }}
            value={selected}
            disabled={disabled}
            existingColums={[
              ...items.reduce((result, el) => {
                if (Array.isArray(el.children)) {
                  forIn(el.children, (item) => result.push(item.id));
                }
                result.push(el.id);
                return result;
              }, []),
            ]}
          />
        </Box>
      </StyledAddColumn>
    </StyledTooltip>
  );
};

export default memo(ColumnAddInput);
