import { ProgressContainer, StyledProgressBar, ProgressText } from './style';

export const ProgressBar = ({ totalActionItems, completedActionItems, completedItemsPercentage }) => (
  <ProgressContainer data-testid="progress-wrapper">
    <StyledProgressBar variant="determinate" value={completedItemsPercentage} />
    <ProgressText>
      {completedActionItems}/{totalActionItems}
    </ProgressText>
  </ProgressContainer>
);
