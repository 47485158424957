import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchText: '',
  menuItems: [],
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSearchText: (state, { payload }) => {
      state.searchText = payload;
    },
    setMenuItmes: (state, { payload }) => {
      state.menuItems = payload;
    },
    resetSearchText: (state) => {
      state.searchText = initialState.searchText;
    },
    resetSettingsState: () => initialState,
  },
});

export const { setSearchText, resetSearchText, resetSettingsState, setMenuItmes } = settingsSlice.actions;

export const selectSearchText = (state) => state.settings.searchText;
export const selectMenuItems = (state) => state.settings.menuItems;

export default settingsSlice.reducer;
export const settingsSliceName = settingsSlice.name;
