import { Box, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledExternalPagesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',

  '.go-back': {
    padding: '10px 10px 20px 10px',
    cursor: 'pointer',
    'button.back-icon-button': {
      minWidth: 'auto',
      marginRight: '10px',
    },
    '&> span': {
      fontSize: '12px',
    },
  },
}));

export const StyledIframeContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  borderRadius: '10px',
  marginTop: '3px',
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: theme?.palette.generic?.primary,
  iframe: {
    border: 'none',
    backgroundColor: theme.palette.generic.primary,
  },
}));

export const StyledLoaderWrapper = styled(Box)(({ isLoaded }) => ({
  transition: 'right 0.4s ease, top 0.4s ease, opacity 0.4s ease',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: isLoaded ? -1 : 100,
  opacity: isLoaded ? 0 : 1,
}));

export const StyledLoader = styled('div')(({ theme }) => ({
  position: 'absolute',
  zIndex: 10,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: 0,
  marginTop: '153px',
  marginBottom: '80px',
  backgroundColor: theme.palette.generic.primary,
}));

export const StyledTabsHeader = styled(Box)(({ theme }) => ({
  width: '100%',
  fontSize: '14px',
  fontFamily: theme?.fonts?.robotoRegular,
  padding: '0px 24px 0px 24px',
  display: 'flex',
  justifyContent: 'space-between',

  '#tabs-listcontainer': {
    display: 'flex',
    marginRight: '60px',

    '.MuiButtonBase-root': {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      textTransform: 'none',
      color: theme?.palette?.text?.secondary_txt,
      fontFamily: theme?.fonts?.robotoRegular,
      padding: '4px 8px 6px 8px',
      lineHeight: '20px',
      height: 'auto',
      width: 'auto',
      minHeight: 'auto',
      '&.selected-tab': {
        color: `${theme?.palette?.text?.txt_default} !important`,
      },
    },
  },
}));

export const StyledSettingsTabs = styled(Tabs)(({ theme }) => ({
  height: 'auto',
  minHeight: 'auto',
  '.MuiTabs-flexContainer': {
    gap: '24px',
  },
  '.MuiTabs-indicator': {
    background: theme?.palette?.generic?.sysaid,
    borderRadius: '10px',
    zIndex: '2',
  },
}));

export const HeaderTitle = styled('span')(({ theme }) => ({
  color: theme?.palette?.text?.txt_default,
  fontFamily: theme?.fonts?.robotoLight,
  fontSize: theme?.fontSize?.big,
  lineHeight: theme?.components?.headerTitle?.lineHeight,
  whiteSpace: 'nowrap',
  marginRight: '60px',
}));

export const NextOrPrev = styled(Box)(({ theme }) => ({
    '& .next-or-prev': {
    position: 'fixed',
    right: '30px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '8px',
    padding: 0,
    marginLeft: 'auto',
  },

  '& .next-or-prev span': {
    fontSize: '12px',
  },

  '& .next-or-prev button': {
    backgroundColor: theme?.palette?.grey?.scroll,
    borderRadius: '4px',
    width: '24px',
    height: '24px',
    textAlign: 'center',
    border: 'none',
    outline: 'none',
    ':hover': {
      backgroundColor: theme?.palette?.grey?.divider_grey_v1,
      cursor: 'pointer',
    },
  },

  '& .next-or-prev button[disabled]': {
    backgroundColor: theme?.palette?.grey?.scroll,
  },

  '& .next-or-prev svg': {
    top: '25%',
    left: '25%',
  },
}));
