export const AVATAR_CONSTANTS = {
  SIZES: {
    BIG: 'big',
    MEDIUM: 'medium',
    SMALL: 'small',
    XL: 'xl',
  },
  TYPES: {
    USER: 'user',
    ADMIN: 'admin',
    GROUP: 'group',
    UNASSIGNED: 'unassigned',
    CUSTOM: 'custom',
    EMAIL: 'email',
  },
  MAX_UPLOAD_FILE_SIZE: 500 * 1024, // 500 KB,
  ALLOWED_UPLOAD_FILE_TYPES: ['jpeg', 'jpg', 'png', 'gif'],
};
