import { useQuery } from '@tanstack/react-query';
import { getChatbotConfigs } from 'services/sysaiService';

const CHATBOT_CONFIGS = 'chatbotConfigs';
const AGENT_CHATBOT_ID = 1;

export const useGetAgentChatbotConfig = () =>
  useQuery({
    queryKey: [CHATBOT_CONFIGS],
    queryFn: getChatbotConfigs,
    select: (data) => {
      const chatbots = data.chatbots;
      const agentChatbot = chatbots.filter((chatbot) => chatbot.id === AGENT_CHATBOT_ID)[0];
      return agentChatbot;
    },
  });
