import PropTypes from 'prop-types';

import { StyledClearButton } from './style';

export const ClearButton = ({ text, icon, iconPlacement, onClick, disabled }) => (
  <StyledClearButton
    iconPlacement={iconPlacement}
    onClick={onClick}
    disabled={disabled}
    data-testid="dropdown-clear-button"
  >
    {icon}
    <span>{text}</span>
  </StyledClearButton>
);

ClearButton.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.element,
  iconPlacement: PropTypes.oneOf(['left', 'right']),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

ClearButton.defaultProps = {
  text: 'Clear',
  icon: null,
  iconPlacement: 'left',
  onClick: () => {},
  disabled: false,
};
