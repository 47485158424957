import { styled } from '@mui/material/styles';
import { LinearProgress } from '@mui/material';

export const StyledPhaseTitle = styled('p')(({ theme }) => ({
  marginBlock: 0,
  height: '24px',
  fontSize: '1.125rem',
  lineHeight: '1.5rem',
  color: theme.palette.text.txt_default,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));

export const ProgressContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

export const StyledProgressBar = styled(LinearProgress)(({ theme }) => ({
  width: '120px',
  height: '12px',
  background: theme.palette.grey.bt_alt_grey_hover,
  borderRadius: '10px',
  '.MuiLinearProgress-bar': {
    background: theme.palette.generic.sysaid,
    borderRadius: '10px',
  },
}));

export const ProgressText = styled('p')(({ theme }) => ({
  fontSize: '.75rem',
  lineHeight: '1.25rem',
  color: theme.palette.text.txt_default,
  width: '32px',
}));
