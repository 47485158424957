import { useEffect, useRef, useState } from 'react';
import { StyledHoursList, StyledHour } from './style';
import { CONSTANTS } from './constants';

export default function HoursList(props) {
  const dummy = useRef();
  const { onSelectHour, inputHour, is12HourTimeFormat } = props;
  const [selectedHour, setSelectedHour] = useState(null);
  useEffect(() => {
    if (inputHour !== null && inputHour !== undefined) {
      setSelectedHour(String(inputHour).padStart(2, '0'));
    }
  }, [inputHour, selectedHour, is12HourTimeFormat]);

  const selectHourHandler = (newSelectedHour) => () => {
    setSelectedHour(newSelectedHour);
    onSelectHour(newSelectedHour);
  };

  const isSelected = (hour) => hour === (selectedHour?.length === 1 ? `0${selectedHour}` : selectedHour);

  useEffect(() => {
    if (dummy?.current && !is12HourTimeFormat) {
      dummy.current.scrollIntoView();
    }
  }, [is12HourTimeFormat]);

  return (
    <StyledHoursList data-testid="hours-list">
      {is12HourTimeFormat ?
        CONSTANTS.MIDDAY_HOURS_LIST.map((middayHour) => (
          <StyledHour
            key={middayHour}
            isSelected={isSelected(middayHour)}
            onClick={selectHourHandler(middayHour)}
            aria-selected={isSelected(middayHour) ? true : undefined}
          >
            {middayHour}
          </StyledHour>
        )) :
        CONSTANTS.HOURS_LIST.map((hour) => (
          <StyledHour
            key={hour}
            isSelected={isSelected(hour)}
            onClick={selectHourHandler(hour)}
            aria-selected={isSelected(hour) ? true : undefined}
          >
            {hour}
          </StyledHour>
        ))
      }
      <div ref={dummy} />
    </StyledHoursList>
  );
}
