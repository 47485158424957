import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGetAttachmentByIdQuery } from 'remote-state/attachments';

import { ReactComponent as DefaultFilePreview } from 'images/icons/defaultFilePreview.svg';
import { attachmentIcons } from 'features/attachments/attachmentsList/attachmentIcons';
import { attachmentPreviewTypes } from 'features/attachments/attachmentsList/attachmentPreviewTypes';
import { StyledCarouseItem, StyledImage, StyledNoPreviewContainer, StyledVideo } from './styles';
import useTexts from './useTexts';
import PdfPreview from './PdfPreview';

const CarouselItem = ({ index, file, zoomAmount = 1, isVisible = true, previewUrl, activeIndex, onClick }) => {
  const { canNotBePreviewed } = useTexts();
  const { isLoading, metadata: attachmentMetadata } = useGetAttachmentByIdQuery({ attachmentId: file.attachmentId });
  const [containerSize, setContainerSize] = useState({ width: `${file?.width}`, height: `${file?.height}` });
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const imageRef = useRef(null);

  const hasPreview = attachmentPreviewTypes.includes(file.extension);

  let fileExtension;
  if (file.extension || attachmentMetadata?.extension) {
    fileExtension = attachmentIcons[(file.extension || attachmentMetadata?.extension).toLowerCase()];
  }

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  const isImageFile = !file.extension || !['pdf', 'txt', 'mp4', 'mkv', 'mov'].includes(file.extension.toLowerCase());

  const calculateImageSize = useCallback(() => {
    try {
      if (imageRef.current && isImageFile) {
        const carouselWrapper = imageRef.current.closest('.carousel-wrapper');
        const image = imageRef.current;
        const containerWidth = carouselWrapper.offsetWidth;
        const containerHeight = carouselWrapper.offsetHeight;
        const imageAspectRatio = image.naturalWidth / image.naturalHeight;
  
        
        let newWidth; 
        let newHeight;
        
        if (containerWidth / containerHeight > imageAspectRatio) {
          newHeight = containerHeight;
          newWidth = newHeight * imageAspectRatio;
        } else {
          newWidth = containerWidth;
          newHeight = newWidth / imageAspectRatio;
        }
        
        setIsImageLoaded(false);
        setContainerSize({ width: newWidth, height: newHeight });
      }
    } catch (error) {
      console.error('Error calculating image size:', error);
    }
  }, [isImageFile]);

  useEffect(() => {
    if (imageRef?.current?.naturalWidth && imageRef?.current?.naturalHeight ) {
      calculateImageSize();
    }
  }, [calculateImageSize, imageRef?.current?.naturalWidth, imageRef?.current?.naturalHeight, activeIndex, isImageLoaded]);

  const FilePreview = useMemo(() => {
    switch (file.extension) {
      case 'pdf':
        return (
          <PdfPreview
            pdfUrl={previewUrl}
            scale={zoomAmount}
            dataTestId={`${index}-${fileExtension}-preview`}
            dataCy={`${index}-${fileExtension}-preview`}
            type="pdf"
            onClick={onClick}
          />
        );
      case 'txt':
        return <iframe src={previewUrl} width="100%" title="txtAttachmentPreview" />;
      case 'mp4':
      case 'mkv':
      case 'mov':
        return (
          <StyledVideo
            zoomAmount={zoomAmount}
            controls
            data-testid={`${index}-${fileExtension}-video-preview`}
            data-cy={`${index}-${fileExtension}-video-preview`}
          >
            <source src={file.isScreenCapture ? file.src : previewUrl} />
          </StyledVideo>
        )
      default:
        return (
          <StyledImage
            className="carousel-img"
            ref={imageRef}
            src={file.isScreenCapture ? file.src : previewUrl}
            zoomAmount={zoomAmount}
            maxWidth={containerSize.width}
            maxHeight={containerSize.height}
            visible={isVisible}
            data-testid={`${index}-${fileExtension}-image-preview`}
            data-cy={`${index}-${fileExtension}-image-preview`}
            onLoad={handleImageLoad}
          />
        );
    }
  }, [containerSize.height, containerSize.width, file.extension, file.isScreenCapture, file.src, fileExtension, index, isVisible, onClick, previewUrl, zoomAmount]);

  return (
    !isLoading && (
      <StyledCarouseItem
        width={containerSize.width}
        height={containerSize.height}
        visible={isVisible}
        fileExtension={fileExtension}
        className={hasPreview ? 'carousel-item' : 'carousel-item no-preview-item'}
      >
        {hasPreview ? FilePreview : (
          <StyledNoPreviewContainer data-testid="no-preview" data-cy="no-preview">
            <DefaultFilePreview className="no-preview-icon" />
            <div className="no-preview-text">{canNotBePreviewed}</div>
          </StyledNoPreviewContainer>
        )}
        {isVisible && (
          <div className="carouse-item-title" data-testid="attachment-preview-title" data-cy="attachment-preview-title">
            {file.fileName || attachmentMetadata?.originalName}
          </div>
        )}
      </StyledCarouseItem>
    )
  );
};

export default CarouselItem;
