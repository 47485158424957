import { StyledToggleButton } from './styled';
import { ReactComponent as ChevronRightIcon } from '../../images/icons/chevronRight.svg';
import { ReactComponent as ChevronLeftIcon } from '../../images/icons/chevronLeft.svg';

export default function ToggleButton(props) {
  const { leftChevron, toggle, isNavBarCollapsed, className, isDisplay = true, ...rest } = props;
  const chevron = leftChevron ? <ChevronLeftIcon /> : <ChevronRightIcon />;

  return (
    isDisplay && (
      <StyledToggleButton {...rest} onClick={toggle} isNavBarCollapsed={isNavBarCollapsed} className={className}>
        {chevron}
      </StyledToggleButton>
    )
  );
}
