import { ReactComponent as DeleteIconComponent } from 'images/icons/deleteIcon.svg';
import Tooltip from 'common/components/tooltip';
import { Box, IconButton } from '@mui/material';
import useTexts from './useTexts';

const DeleteIcon = ({ onDelete }) => {
  const { deleteField } = useTexts();

  return (
    <Tooltip
      text={
        <Box minWidth="24px">
          <IconButton className="DeleteIcon" onClick={onDelete} size="small">
            <DeleteIconComponent />
          </IconButton>
        </Box>
      }
      title={deleteField}
      placement="top-start"
    />
  );
};

export default DeleteIcon;
