import { styled } from '@mui/styles';
import Chip from '@mui/material/Chip';

export const StyledChip = styled(({ target, href, ...props }) => (
  <a href={href} target={target}>
    <Chip {...props} />
  </a>
))(() => ({
  height: '1.5rem',
}));
