import { styled } from '@mui/material/styles';

export const StyledTemplateMetadata = styled('div')(() => ({
  display: 'flex',
  gap: '20px',
  flex: 2,
  '> div': {
    display: 'flex',
    alignItems: 'center',
  },
  '.avatar-wrapper': {
    marginRight: '6px',
  },
  '.capitalize': {
    textTransform: 'capitalize',
  },
}));
