import PropTypes from 'prop-types';
import { StyledDefaultButton } from './style';
import { useTexts } from '../hooks';

const DefaultButton = ({ isDefault, handleSetDefault = () => {} }) => {
  const { defaultOption, setAsDefault } = useTexts();

  const handleClick = () => {
    if (!isDefault) {
      handleSetDefault();
    }
  }

  return (
    <StyledDefaultButton
      className="set-default-btn"
      data-testid="set-default-journey-filter-btn"
      isDefault={isDefault}
      onClick={handleClick}
    >
      <span className={isDefault ? "default-label" : "set-default-label"}>
        {isDefault ? defaultOption : setAsDefault}
      </span>
    </StyledDefaultButton>
  )
}

DefaultButton.propTypes = {
  isDefault: PropTypes.bool,
  handleSetDefault: PropTypes.func,
}

export { DefaultButton }
