import { useState } from 'react';
import PropTypes from 'prop-types';

import { useTicketHistory } from 'remote-state/ticketMigrationHooks';
import { useUserInfo } from 'remote-state/userServiceHooks';
import { getDate } from 'common/utils/dateTimeUtils';
import { QUERIES_KEYS } from 'constant';
import MigrationHistoryViewDialog from './migrationHistoryViewDialog';
import { StyledEvent, StyledLinkButton } from '../../style';
import useTexts from '../../../../../useTexts';

const MigrationHistoryEvent = ({ srId, logTimestamp }) => {
  const { migrationTicketEventText, viewHistoryText } = useTexts();
  const [isHistoryViewOpen, setIsHistoryViewOpen] = useState(false);
  const { timezone, dateFormat } = useUserInfo(QUERIES_KEYS.CURRENT_USER_FROM_CORE);
  const { data: historyList } = useTicketHistory({
    srId,
    historyCutoffDate: logTimestamp,
    selectFn: (data) =>
      data.map((item) => ({
        ...item,
        logTime: getDate(timezone, dateFormat, item.logTime),
      })),
  });

  const toggleHistoryView = () => {
    setIsHistoryViewOpen((prevState) => !prevState);
  };

  return (
    <StyledEvent data-testId="migration-history-event" data-cy="migration-history-event">
      {migrationTicketEventText}
      {historyList?.length > 0 && (
        <div data-testId="view-history" data-cy="view-history">
          <StyledLinkButton data-testId="view-history-button" data-cy="view-history-button" onClick={toggleHistoryView}>
            {viewHistoryText}
          </StyledLinkButton>
          <MigrationHistoryViewDialog historyList={historyList} open={isHistoryViewOpen} onClose={toggleHistoryView} />
        </div>
      )}
    </StyledEvent>
  );
};

MigrationHistoryEvent.propTypes = {
  srId: PropTypes.number,
  logTimestamp: PropTypes.number,
};

MigrationHistoryEvent.defaultProps = {
  srId: -1,
  logTimestamp: 0,
};
export default MigrationHistoryEvent;
