import { useCallback, useMemo } from 'react';
import { isArray } from 'lodash-es';
import { useFieldAttributes } from 'remote-state/ticketServiceHooks';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { getFieldAttributes } from 'services/ticketService';
import { getValueKeyByFieldName } from 'common/utils/fieldUtils';
import { FilterByNumber } from 'common/components/forwardRefFilter/NumberFilter/FilterByNumber';
import useDateSelectOptions from '../../hooks/useDateSelectOptions';
import useGetCustomDateRange from '../../hooks/useGetCustomDateRange';
import useTexts from '../../hooks/useTexts';
import { resolveFilterValueByFieldType } from '../helpers';
import { COLUMN_FILTER_FIELDTYPE } from '../../constants';
import { DropdownSelect } from '../../../dropdownSelect';

export default function FilterComponentByType({ column, toggleFilter }) {
  const { id, columnDef, getFilterValue, setFilterValue } = column;
  const { filterType, header, fieldType, sortBy, templateFieldId, filterMultipleSelection, filterHasHierarchy } =
    columnDef;
  const fieldAttributesInput = useMemo(() => [{ fieldId: templateFieldId }], [templateFieldId]);
  const data = useFieldAttributes(fieldAttributesInput)?.[0]?.data?.values;
  const { dates } = useDateSelectOptions();
  const { getCustomDateRange } = useGetCustomDateRange();
  const { filterByText } = useTexts();
  const isSelectDateType = filterType === COLUMN_FILTER_FIELDTYPE.date;
  const keyString = fieldType === 'srType' ? 'value' : getValueKeyByFieldName(fieldType); // @TODO should make srType support ids and not strings (same as in queue)

  const optionList = useMemo(() => {
    let optionList;
    if (isSelectDateType) {
      optionList = dates;
    } else {
      switch (fieldType) {
        // case 'primaryCategory':
        //   optionList = primaryCategories;
        //   break;
        // case 'secondaryCategory':
        //   optionList = secondaryCategories;
        //   break;
        // case 'thirdLevelCategory':
        //   optionList = thirdLevelCategories;
        //   break;
        default:
          optionList = data;
          // if (addedValues?.length) {
          //   optionList = optionList.concat(addedValues);
          // }
          break;
      }
    }
    return optionList;
  }, [fieldType, data, dates, isSelectDateType]);

  const handleSelectionChange = useCallback(
    (selectedValue) => {
      let values;
      const type = 'equals';
      const isSelectDateType = filterType === COLUMN_FILTER_FIELDTYPE.date;
      if (isSelectDateType) {
        if (isArray(selectedValue)) {
          values = selectedValue;
        } else if (Number(selectedValue[keyString]) < dates.length + 1) {
          values = selectedValue.values;
        } else {
          const customDate = getCustomDateRange(selectedValue);
          values = [customDate.startDate, customDate.endDate];
        }
      } else {
        values = selectedValue;
      }
      if ((isArray(selectedValue) && selectedValue.length > 0) || selectedValue != null) {
        values = resolveFilterValueByFieldType(values, fieldType);
        setFilterValue({ values, type });
        //If its multiple then dont close popup filter
        if (!filterMultipleSelection) {
          toggleFilter();
        }
      } else {
        setFilterValue();
      }
    },
    [
      filterType,
      fieldType,
      keyString,
      dates,
      setFilterValue,
      toggleFilter,
      filterMultipleSelection,
      getCustomDateRange,
    ],
  );

  const handleClean = useCallback(() => {
    setFilterValue();
  }, [setFilterValue]);

  const handleSearch = useCallback(
    (event) => {
      if (!event.target.value) {
        setFilterValue();
      }
      setFilterValue({ values: event.target.value ? [event.target.value] : [], type: event.target.filterType });
    },
    [setFilterValue],
  );

  const backendQueryConfig = useMemo(
    () => ({
      fetchingPromise: (query) => getFieldAttributes(templateFieldId, { query }),
    }),
    [templateFieldId],
  );

  const filterValue = getFilterValue();
  const selection = useMemo(() => filterValue?.values || [], [filterValue]);

  switch (filterType) {
    case COLUMN_FILTER_FIELDTYPE.set:
    case COLUMN_FILTER_FIELDTYPE.date:
      return (
        <ClickAwayListener onClickAway={toggleFilter}>
          <div data-testid="filter-dropdown">
            <DropdownSelect
              options={optionList}
              selection={selection}
              handleChange={handleSelectionChange}
              handleClean={handleClean}
              stylingVariant={id}
              isMultiple={filterMultipleSelection}
              hasHierarchy={filterHasHierarchy}
              title={`${filterByText} ${header}`}
              isDateType={isSelectDateType}
              keyString={keyString}
              captionString="valueCaption"
              sortBy={sortBy}
              backendQueryConfig={backendQueryConfig}
            />
          </div>
        </ClickAwayListener>
      );
    case COLUMN_FILTER_FIELDTYPE.number:
      return (
        <ClickAwayListener onClickAway={toggleFilter}>
          <div>
            <FilterByNumber selectedFilter={filterValue} handleSearch={handleSearch} />
          </div>
        </ClickAwayListener>
      );
    default:
      return <></>;
    //   return (
    //     <FilterContainer>
    //       <FilterTitle>{`${filterByText} ${colDef?.headerName}`}</FilterTitle>
    //       <FilterSearch
    //         autoFocus
    //         placeholderText={searchPlaceholderText}
    //         onChange={handleSearch}
    //         handleSearch={handleSearch}
    //         onClear={clearSearch}
    //       />
    //     </FilterContainer>
    //   );
  }
}
