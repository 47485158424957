import { styled } from '@mui/material/styles';
import { Box, TextField, MenuItem, List, ListItem } from '@mui/material';

export const StyledContentBody = styled(Box)(({ theme }) => ({
  minHeight: '650px',
  paddingBlock: theme.spacing(1.5),
  overflow: 'auto',
  ...theme.mixins.scrollbar,
}));
export const StyledSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  '.signature-wrapper': {
    marginTop: '2px',
    '.MuiInputBase-root': {
      padding: 0,
    },
    '#signature-input': {
      minHeight: '50px',
      '&:empty:not(:focus)': {
        backgroundColor: theme.palette.background.bg_new_input_text,
      },
      '&:hover:not(:focus)': {
        backgroundColor: theme.palette.background.bg_hover_main,
        borderRadius: '4px',
      },
    },
  },

  '.input-error': {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    color: theme?.palette?.labels?.bt_red_reg,
    fontSize: theme?.fontSize?.main,
    marginTop: '12px',
  },
}));

export const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => !['width', 'open'].includes(prop),
})(({ theme, width = 'auto', open }) => ({
  width,
  backgroundColor: open
    ? `${theme?.palette?.background?.bg_hover_main}!important`
    : `${theme.palette.generic.primary}!important`,
  '& .MuiOutlinedInput-root': {
    fontFamily: theme.fonts.robotoRegular,

    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },

    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },

    '& .MuiOutlinedInput-input': {
      width: '200px',
      fontSize: '.875rem',
      height: 'unset',
      lineHeight: 1.4286,
      minHeight: 'unset',
      padding: theme.spacing(0.5, 1),
      color: theme.palette.text.txt_default,
    },

    '&.MuiInputBase-fullWidth': {
      '& .MuiOutlinedInput-input': {
        width: '100%',
        ...theme.mixins.scrollbar,
      },
    },

    '& .MuiPopover-root': {
      position: 'absolute',
      transform: 'translate(0, 100%)',

      '& .MuiPaper-root': {
        position: 'static',
      },
    },

    '&.Mui-error': {
      backgroundColor: theme.palette.labels.bt_red_light,

      '& .MuiOutlinedInput-input': {
        color: theme.palette.labels.bt_red_reg,
      },
    },
  },

  '& .MuiFormHelperText-root': {
    margin: 0,
  },
}));

export const StyledPasswordField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'focused',
})(({ theme, value, focused }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: theme?.shape?.border?.borderRadius,
    ...(!value && { backgroundColor: theme?.palette?.background?.bg_new_input_text }),

    'input:-webkit-autofill, input:-webkit-autofill:focus': {
      transition: 'background-color 0s 600000s, color 0s 600000s !important',
    },

    '.MuiInputAdornment-root': {
      visibility: 'hidden',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },

    ...(focused && {
      '&.Mui-focused': {
        backgroundColor: 'unset',
        color: 'unset',

        '.MuiInputAdornment-root': {
          visibility: 'visible',
        },

        '&.Mui-error': {
          backgroundColor: 'unset',

          '& .MuiOutlinedInput-input': {
            color: 'unset',
          },
        },
      },
    }),

    '& .MuiOutlinedInput-input': {
      width: '180px',
      fontSize: '.875rem',
      height: 'unset',
      lineHeight: 1.4286,
      minHeight: 'unset',
      padding: theme?.spacing(0.5, 1),
      color: theme?.palette?.text?.txt_default,
    },

    '&.MuiInputBase-fullWidth': {
      '& .MuiOutlinedInput-input': {
        width: '100%',
        ...theme?.mixins?.scrollbar,
      },
    },

    '&.Mui-error': {
      backgroundColor: theme?.palette?.labels?.bt_red_light,

      '.MuiInputAdornment-root': {
        visibility: 'visible',
      },

      '& .MuiOutlinedInput-input': {
        color: theme?.palette?.labels?.bt_red_reg,
      },
    },
  },
}));

export const StyledSelect = styled(StyledTextField)(({ open, theme }) => ({
  '.MuiSelect-select': {
    borderRadius: '4px',
    backgroundColor: open
      ? `${theme?.palette?.background?.bg_hover_main}!important`
      : `${theme.palette.generic.primary}!important`,
  },
}));

export const StyledListItem = styled(MenuItem)(({ theme, width = 'auto' }) => ({
  width,
  padding: theme.spacing(1.5),
  paddingRight: theme.spacing(2.25),
  borderRadius: '4px',
  '&.Mui-focused': {
    backgroundColor: `${theme?.palette?.background?.bg_hover_main}!important`,
  },
  span: {
    fontSize: theme.fontSize.main,
    lineHeight: 1.3,
    textAlign: 'start',
  },
  '&:hover, &.Mui-selected': {
    backgroundColor: theme?.palette?.background?.selected_and_hover,
  },

  '&.Mui-selected:hover': {
    backgroundColor: theme?.palette?.background?.selected_and_hover,
  },
}));

export const StyledSwitchWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 1),
}));

export const StyledFormField = styled(Box)(({ theme }) => ({
  '& .form-field-label': {
    padding: theme.spacing(0.125, 1),

    '& span:has(.HintIcon)': {
      height: '16px',
    },
  },
  '.MuiInputBase-root:not(.menu-select)': {
    border: theme.shape.border.border_transparent,
    '&.Mui-focused': {
      borderColor: theme.palette.grey.divider_grey_v3,
    },
  },
}));

export const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => !['maxHeight'].includes(prop),
})(({ theme }) => ({
  width: 'calc(100% - 4px)',
  overflow: 'overlay',
  overflowX: 'hidden',
  position: 'relative',
  maxHeight: '236px',
  marginTop: '12px',
  padding: '0 4px 0 8px',
  ...theme.mixins.scrollbar,
}));

export const StyledErrorMessage = styled(Box)(() => ({
  marginTop: '-14px',
}));

export const StyledMenuWrapper = styled(Box)(() => ({
  overflow: 'hidden',
  '.SearchField': {
    width: 'auto',
    margin: '12px 8px 0',
    '.MuiOutlinedInput-notchedOutline': {
      border: 'unset!important',
    },
    '.Mui-focused': {
      border: 'unset!important',
    },
  },
  '.MuiList-root': {
    width: 'auto',
    paddingTop: 0,
  },
}));

export const StyledPasswordValidationsList = styled(List)(({ theme }) => ({
  'li:nth-of-type(n + 2):nth-of-type(-n + 3)': {
    marginLeft: '17px',
  },
  'li:nth-of-type(2)': {
    marginTop: '4px',
  },
  'li:nth-of-type(3)': {
    marginTop: 0,
    lineHeight: '24px',
  },
  'li:nth-of-type(n + 4)': {
    marginLeft: '34px',
    marginTop: 0,

    '.MuiListItemText-root': {
      margin: 0,
      span: {
        fontSize: theme?.fontSize?.small,
        lineHeight: '20px',
      },
    },
  },
}));

export const StyledPasswordValidation = styled(ListItem, {
  shouldForwardProp: (prop) => !['isPasswordValid', 'hasCriteria', 'variant'].includes(prop),
})(({ theme, isPasswordValid, hasCriteria }) => {
  let color = theme.palette.text.title_txt;

  if (hasCriteria) {
    color = theme.palette.primary.main;
  } else if (!isPasswordValid) {
    color = theme.palette.labels.bt_red_reg;
  }

  return {
    marginTop: theme.spacing(1),
    display: 'grid',
    gridTemplateColumns: '28px 1fr',
    gridTemplateAreas: "'icon text'",

    '& .MuiListItemText-root': {
      gridArea: 'text',
      '& .MuiListItemText-primary': {
        fontSize: '.875rem',
        lineHeight: 1.2858,
        color,
      },
    },

    '& .MuiListItemIcon-root': {
      gridArea: 'icon',
      margin: theme.spacing(0, 0.5, 0, 1),
    },

    '&#password-validation-title': {
      '& .MuiListItemText-primary': {
        fontFamily: theme.fonts.robotoMedium,
        color: theme.palette.text.title_txt,
      },
    },
  };
});

export const StyledOutOfOffice = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  columnGap: '3px',
  fontSize: theme.fontSize.main,
  color: theme.palette.text.secondary_txt,
  '.assign-wrapper': {
    display: 'flex',
    alignItems: 'center',
    columnGap: '7px',
  },
}));

export const StyledPasswordTabContent = styled(Box)(() => ({
  display: 'flex',
  gap: '20px',

  '.inputs-container': {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
}));
