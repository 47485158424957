import { useRbTexts } from 'remote-state/applicationHooks';
import { WORFKLOWS_CONSTANTS } from '../constants';

const getDisabledText = ({ disabledType, texts }) => {
  const actionItemDisabledTypes = WORFKLOWS_CONSTANTS.ACTION_ITEM_DISABLED_TYPES;
  switch (disabledType) {
    case actionItemDisabledTypes.DEPENDENCY:
      return texts?.['spaces.actionItem.indicators.disabled.dependency'];
    case actionItemDisabledTypes.INSUFFICIENT_PERMISSIONS:
      return texts?.['spaces.actionItem.indicators.disabled.insufficientPermissions'];
    default:
      return texts?.['spaces.actionItem.indicators.disabled'];
  }
};
export default function useTexts(disabledType) {
  const texts = useRbTexts();
  const disabledText = getDisabledText({ disabledType, texts });

  const componentTexts = {
    changeStatusPromptTitle: texts?.['spaces.actionItem.prompt.changeStatusError.title'],
    changeStatusPromptText: texts?.['spaces.actionItem.prompt.changeStatusError.body'],
    tryAgainText: texts?.['spaces.actionItem.prompt.tryAgainBtn'],
    completeText: texts?.['spaces.actionItem.button.complete'],
    reopenText: texts?.['spaces.actionItem.button.reopen'],
    actionItemsCompletedText: texts?.['spaces.actionItem.actionItemsCompleted'],
    enabledText: texts?.['spaces.actionItem.indicators.enabled'],
    disabledText,
    completedText: texts?.['spaces.actionItem.indicators.completed'],
    dependencyDetailsText: texts?.['spaces.actionItem.indicators.dependencyDetails'],
    dependentOnText: texts?.['spaces.actionItem.indicators.dependentOn'],
    automateJoeText: texts?.['spaces.actionItem.indicators.automateJoe'],
    autoCompleteText: texts?.['spaces.actionItem.indicators.autoComplete'],
    overdueText: texts?.['spaces.actionItem.indicators.overdue'],
    enableStatusText: texts?.['spaces.actionItem.journey.enableStatus'],
    reopenInfoText: texts?.['spaces.actionItem.journey.reopenInfo'],
    startText: texts?.['spaces.actionItem.journey.start'],
    finishText: texts?.['spaces.actionItem.journey.finish'],
    orOperatorText: texts?.['spaces.actionItem.dependency.orOperator'],
    andOperatorText: texts?.['spaces.actionItem.dependency.andOperator'],
    reopenModalTitleText: texts?.['spaces.actionItem.reopenModal.title'],
    reopenModalSubTitleText: texts?.['spaces.actionItem.reopenModal.subTitle'],
    requiredFieldsMissingText: texts?.['spaces.actionItem.tooltip.requiredFieldsMissing'],
    requiredFieldText: texts?.['spaces.actionItem.tooltip.requiredField'],
    noPermissionsMessageText: texts?.['spaces.actionItem.permissions.noPermissionsMessage'],
    filterTitleText: texts?.['spaces.actionItem.filter.title'],
    allActionItemsText: texts?.['spaces.actionItem.filter.allActionItems'],
    validationModalTitleText: texts?.['spaces.actionItem.validation.modal.title'],
    validationModalBodyText: texts?.['spaces.actionItem.validation.modal.body'],
  };

  return componentTexts;
}
