import { useDispatch } from 'react-redux';
import { useRouter } from '@tanstack/react-router';
import { setToasterMessage } from 'store/globalSlice';
import sysaidLogoGreen from 'images/photos/sysaid-logo-green.png';
import useTexts from '../../useTexts';
import LeftSideUpperLogo from '../../leftSideUpperLogo';
import RightPanel from '../../rightPanel';
import UserForm from '../../userForm';
import { trimFormValues } from '../../utils';
import { forgotPassword } from '../../../../services/forgotPassword';

import { StyledForgotPasswordPage } from './style';

export const ForgotPasswordPage = () => {
  const router = useRouter();
  const { forgotPasswordText, fillFieldsText, passwordWillBeSentText } = useTexts();
  const { state } = router.latestLocation; // get state from previous page
  let customization;
  if (state) {
    customization = state.customization; // read values passed on state
  }
  const dispatch = useDispatch();

  // executes when user submits his form - either by pressing ENTER key or by pressing submit button
  const onSubmitForm = (formValues) => {
    // trim form values
    trimFormValues(formValues);

    forgotPassword(formValues).then((data) => {
      if (data.status === 'true') {
        router.navigate({ to: '/spaces/check-mail', state: { customization } });
      } else {
        dispatch(setToasterMessage({ message: data.message, type: 'error' }));
      }
    });
  };

  return (
    <StyledForgotPasswordPage>
      <div id="login-wrapper">
        <div id="left-side-container">
          <LeftSideUpperLogo />
          <div id="left-side-wrapper">
            <div className="left-side-logo">
              <img alt="sysaid-logo" src={sysaidLogoGreen} />
            </div>
            <div id="left-side-header">{forgotPasswordText}</div>
            <div id="left-side-sub-header">
              <div className="line">{fillFieldsText}</div>
              <div className="line">{passwordWillBeSentText}</div>
            </div>
            <UserForm onSubmitForm={onSubmitForm} isForgotPasswordPage />
          </div>
        </div>
        <RightPanel />
      </div>
    </StyledForgotPasswordPage>
  );
};
