import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();

  const componentTexts = {
    deleteSectionWarningText: texts?.['spaces.templateBuilder.deleteSectionWarningText'],
    templatesListText: texts?.['spaces.templateBuilder.templatesList'],
    templateNameTitleText: texts?.['spaces.templateBuilder.templateNameTitle'],
    generalText: texts?.['spaces.templateBuilder.general'],
    createDateText: texts?.['spaces.templateBuilder.createDate'],
    createdByText: texts?.['spaces.templateBuilder.createdBy'],
    templateTypeText: texts?.['spaces.templateBuilder.templateType'],
    visibleInSspText: texts?.['spaces.templateBuilder.visibleInSsp'],
    visibleToAutomationPanelText: texts?.['spaces.templateBuilder.visibleToAutomationPanel'],
    deleteTemplateText: texts?.['spaces.templateBuilder.deleteTemplate'],
    deleteAction: texts?.['spaces.templateBuilder.deleteAction'],
    deletePromptMessageText: texts?.['spaces.templateBuilder.deletePromptMessageText'],
    deleteSuccessText: texts?.['spaces.templateBuilder.deleteSuccessText'],
    deleteErrorText: texts?.['spaces.templateBuilder.deleteErrorText'],
    duplicateText: texts?.['spaces.templateBuilder.duplicate'],
    saveTemplateText: texts?.['spaces.templateBuilder.saveTemplate'],
    saveTemplateTooltipText: texts?.['spaces.templateBuilder.saveTemplateTooltip'],
    saveTemplateSuccessText: texts?.['spaces.templateBuilder.saveTemplateSuccess'],
    saveTemplateFailureText: texts?.['spaces.templateBuilder.saveTemplateFailure'],
    duplicateSuccessText: texts?.['spaces.templateBuilder.duplicateSuccessText'],
    duplicateErrorText: texts?.['spaces.templateBuilder.duplicateErrorText'],
    duplicateChangesSavedText: texts?.['spaces.templateBuilder.duplicateChangesSavedText'],
    duplicateSaveChangesBtnText: texts?.['spaces.templateBuilder.duplicateSaveChangesBtnText'],
    duplicateCancelBtnText: texts?.['spaces.prompt.cancelBtn'],
    duplicatePromptTitleText: texts?.['spaces.templateBuilder.duplicatePromptTitleText'],
    duplicatePromptMessageText: texts?.['spaces.templateBuilder.duplicatePromptMessageText'],
    fieldsText: texts?.['spaces.templateBuilder.fields'],
    searchFieldsPlaceholder: texts?.['spaces.templateBuilder.searchFieldsPlaceholder'],
    deleteSectionText: texts?.['spaces.templateBuilder.deleteSectionText'],
    sectionTitlePlaceholder: texts?.['spaces.templateBuilder.sectionTitlePlaceholder'],
    sectionInstructionsPlaceholder: texts?.['spaces.templateBuilder.sectionInstructionsPlaceholder'],
    newSectionText: texts?.['spaces.templateBuilder.newSectionText'],
    maxSectionLimitTitle: texts?.['spaces.templateBuilder.maxSectionLimitTitle'],
    maxSectionLimitText: texts?.['spaces.templateBuilder.maxSectionLimitText'],
    maxFieldsLimitTitle: texts?.['spaces.templateBuilder.maxFieldsLimitTitle'],
    maxFieldsLimitText: texts?.['spaces.templateBuilder.maxFieldsLimitText'],
    uniqueNameErrorTitle: texts?.['spaces.templateBuilder.uniqueNameErrorTitle'],
    uniqueNameErrorText: texts?.['spaces.templateBuilder.uniqueNameErrorText'],
    uniqueNameButtonOkText: texts?.['spaces.templateBuilder.uniqueNameButtonOkText'],
    placeholderFieldText: texts?.['spaces.templateBuilder.placeholderFieldText'],
    propertiesPanelDefaultTitle: texts?.['spaces.templateBuilder.propertiesPanelDefaultTitle'],
    propertiesPanelDefaultText: texts?.['spaces.templateBuilder.propertiesPanelDefaultText'],
    propertiesPanelSpecialFieldText:
      texts?.['spaces.templateBuilder.propertiesPanelSpecialFieldText'] || 'No properties for this field',
    limitEditTitle: texts?.['spaces.templateBuilder.limitEditTitle'],
    limitViewTitle: texts?.['spaces.templateBuilder.limitViewTitle'],
    exceptionGroupsViewText: texts?.['spaces.templateBuilder.exceptionGroupsViewText'],
    exceptionGroupsEditText: texts?.['spaces.templateBuilder.exceptionGroupsEditText'],
    exceptionGroupsViewSectionTooltip: texts?.['spaces.templateBuilder.exceptionGroupsViewSectionTooltip'],
    exceptionGroupsEditFieldTooltip: texts?.['spaces.templateBuilder.exceptionGroupsEditFieldTooltip'],
    exceptionGroupsPlaceholder: texts?.['spaces.templateBuilder.exceptionGroupsPlaceholder'],
    hideFieldCaptionTitle: texts?.['spaces.templateBuilder.hideFieldCaptionTitle'],
    requiredFieldTitle: texts?.['spaces.templateBuilder.requiredFieldTitle'],
    requiredFieldText: texts?.['spaces.templateBuilder.requiredFieldText'],
    requiredFieldPlaceholder: texts?.['spaces.templateBuilder.requiredFieldPlaceholder'],
    hintTitle: texts?.['spaces.templateBuilder.hintTitle'],
    hintPlaceholder: texts?.['spaces.templateBuilder.hintPlaceholder'],
    fieldPropertiesTitle: texts?.['spaces.templateBuilder.fieldPropertiesTitle'],
    sectionPropertiesTitle: texts?.['spaces.templateBuilder.sectionPropertiesTitle'],
    templateSetAsDefaultText: texts?.['spaces.templateBuilder.templateSetAsDefaultText'],
    setAsDefaultPromptTitleText: texts?.['spaces.templateBuilder.setAsDefaultPromptTitle'],
    setAsDefaultPromptMessageText: texts?.['spaces.templateBuilder.setAsDefaultPromptMessage'],
    setAsDefaultSuccessText: texts?.['spaces.templateBuilder.setAsDefaultSuccess'],
    setAsDefaultErrorText: texts?.['spaces.templateBuilder.setAsDefaultError'],
    templatePreviewText: texts?.['spaces.templateBuilder.templatePreviewText'],
    templateCategoryTitle: texts?.['spaces.template.fields.category'],
    viewPermissionsTitle: texts?.['spaces.permissions.viewPermissions.template.title'],
    viewPermissionsText: texts?.['spaces.permissions.viewPermissions.template.text'],
    exitWithoutSaveTitle: texts?.['spaces.templateBuilder.exitWithoutSave.title'],
    exitWithoutSaveText: texts?.['spaces.templateBuilder.exitWithoutSave.text'],
    addWorkflowText: texts?.['spaces.templateBuilder.addWorkflow.btn'],
    addWorkflowDisableTooltip: texts?.['spaces.templateBuilder.addWorkflow.disableTooltip'],
    settingsText: texts?.['spaces.menu.settings'],
  };

  return componentTexts;
}
