import PropTypes from 'prop-types';
import { ReactComponent as DisabledIcon } from 'images/icons/workflows/disabled-ai.svg';
import { styled } from '@mui/material/styles';
import { Tooltip } from 'common/components/sysaidLibrary/Tooltip';
import { Indicator } from '../Indicator';
import useTexts from '../../hooks/useTexts';

export const DisabledIndicator = styled(({ className, type }) => {
  const { disabledText } = useTexts(type);

  return (
    <Tooltip title={disabledText} arrow placement="top" forceDisplayOnHover>
      <Indicator
        className={className}
        icon={<DisabledIcon />}
        title="disabled-indicator"
        data-cy="disabled-indicator"
      />
    </Tooltip>
  );
})(({ theme }) => ({
  backgroundColor: theme.palette.icons.bt_def_grey_reg,
}));

DisabledIndicator.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
};

DisabledIndicator.defaultProps = {
  type: null,
};
