import { useSelector } from 'react-redux';
import SearchField from 'common/components/searchField';
import FilesPreview from 'common/components/thumbnail/FilesPreview';

import { HeaderActiveFilters } from './headerActiveFilters';
import { HeaderFiltersSelection } from './headerFiltersSelection';

import { useTexts } from './hooks/useTexts';
import { useUploadFromExistingAttachments } from './hooks/useUploadFromExistingAttachments';
import { selectActiveFilters } from '../../../features/resolutionPanel/middlePanel/auditLog/store/slice';

import {
  StyledAddButton,
  StyledCancelButton,
  StyledTitleWrapper,
  StyledButtonsWrapper,
  StyledThumbnailsWrapper,
  StyledThumbnailsFiltersHeader,
  StyledThumbnailsActiveFiltersHeader,
  StyledUploadFromExistingAttachments,
  StyledThumbnailsFiltersHeaderWrapper,
  StyledUploadFromExistingAttachmentsWrapper,
  StyledUploadFromExistingAttachmentsBodyWrapper,
} from './styles';

export const UploadFromExistingAttachments = ({ setIsOpen, ...props }) => {
  const {
    onSearchClear,
    onSearchChange,
    setDisplayPreview,
    handleImageRemove,
    handleCancelBtnClick,
    addAttachmentsHandler,
    filesData,
    fileTypes,
    thumbnails,
    searchString,
    displayPreview,
    selectedFileIndex,
    isSomethingSelected,
  } = useUploadFromExistingAttachments({ setIsOpen });

  const activeFilters = useSelector(selectActiveFilters);
  const hasActiveFilters = activeFilters && activeFilters?.length > 0;
  const { addBtnText, cancelBtnText, defaultSearchPlaceholder, selectAttachmentsTitleText } = useTexts();

  return (
    <StyledUploadFromExistingAttachments {...props}>
      <StyledUploadFromExistingAttachmentsWrapper hasActiveFilters={hasActiveFilters}>
        <StyledTitleWrapper>
          <p>{selectAttachmentsTitleText || 'Select Attachments'}</p>
          <SearchField
            autoFocus
            value={searchString}
            placeholderText={defaultSearchPlaceholder}
            onClear={onSearchClear}
            onChange={onSearchChange}
          />
        </StyledTitleWrapper>

        <StyledUploadFromExistingAttachmentsBodyWrapper hasActiveFilters={hasActiveFilters}>
          <StyledThumbnailsFiltersHeaderWrapper>
            <StyledThumbnailsFiltersHeader>
              <HeaderFiltersSelection fileTypes={fileTypes} />
            </StyledThumbnailsFiltersHeader>

            <StyledThumbnailsActiveFiltersHeader>
              {hasActiveFilters && <HeaderActiveFilters />}
            </StyledThumbnailsActiveFiltersHeader>
          </StyledThumbnailsFiltersHeaderWrapper>

          <StyledThumbnailsWrapper>
            {thumbnails}

            {displayPreview && (
              <FilesPreview
                filesData={filesData}
                onFileRemove={handleImageRemove}
                selectedFileIndex={selectedFileIndex}
                onClose={() => setDisplayPreview(false)}
                deleteFileEnabled
              />
            )}
          </StyledThumbnailsWrapper>
        </StyledUploadFromExistingAttachmentsBodyWrapper>

        <StyledButtonsWrapper>
          <StyledCancelButton onClick={handleCancelBtnClick} variant="contained">
            {cancelBtnText}
          </StyledCancelButton>

          <StyledAddButton disabled={!isSomethingSelected} variant="contained" onClick={addAttachmentsHandler}>
            {addBtnText}
          </StyledAddButton>
        </StyledButtonsWrapper>
      </StyledUploadFromExistingAttachmentsWrapper>
    </StyledUploadFromExistingAttachments>
  );
};
