export const FloatingTicketInstanceModes = {
  COLLAPSED: 1,
  REGULAR_MODE_OPENED: 2,
  EXPANDED: 3,
  AUTO_POPULATE: 4,
  LOADING: 5,

  CLOSED: 999,
};

export const FloatingTicketInstanceActions = {
  ErrorCreatingTicket: 'ErrorCreatingTicket',
  RequestModeChange: 'RequestModeChange',
  TicketCreated: 'TicketCreated',
  RequestClosedMessage: 'RequestClosedMessage',
  RequestLoader: 'RequestLoader',
  RequestStopLoader: 'RequestStopLoader',
  CollapsedPanelWidthChange: 'CollapsedPanelWidthChange',
};
