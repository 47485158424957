import { styled } from '@mui/material/styles';
import { textFieldClasses } from '@mui/material/TextField';
import { Box } from '@mui/system';

export const TextFieldWrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isFocused' && prop !== 'textAdded',
})(({ theme, isFocused, textAdded }) => () => {
    const hoverOverNew = !isFocused && !textAdded;
    const clicked = isFocused && !textAdded;
    return {
        '.text-field': {
            fontFamily: theme.fonts.robotoRegular,
            fontSize: theme.fontSize.main,
            [`&.${textFieldClasses.root}`]: clicked ? {
                    background: theme.palette.generic.primary,
                    borderRadius: '4px',
                    border: theme.shape.border.border,
                } : textAdded && {
                        background: theme.palette.generic.primary,
                    },
            ':hover': hoverOverNew && {
                background: theme.palette.background.bg_hover_main,
            },
        },
    };
});
