import { useDispatch, useSelector } from 'react-redux';
import { selectPanels, selectStartIndexToShow, setStartIndexToShow } from 'store/floatingTicketPanelSlice';
import { selectIsNavBarCollapsed } from 'features/loggedInRoutesPanel/slice';
import { FloatingTicketPanelInstance } from '../floatingTicketPanelInstance';
import MoreFloatingTickets from '../moreFloatingTickets';
import { FloatingTicketInstanceModes } from './modes';
import { StyledFloatingTicketPanelBackdrop } from './style';
import { getVisiblePanelsOnScreenCount } from '../utils/utils';
import { CONSTANTS } from '../utils/constants';

export default function FloatingTicketPanelManager() {
  const panels = useSelector(selectPanels);
  const startIndexToShow = useSelector(selectStartIndexToShow);
  const dispatch = useDispatch();
  const keys = Object.keys(panels);
  const isDisabled = keys.some((k) => panels[k].isLoading);
  const onShowMoreClicked = (move) => {
    dispatch(setStartIndexToShow(move));
  };

  const navBarCollapsed = useSelector(selectIsNavBarCollapsed);
  const navBarWidth = navBarCollapsed ? CONSTANTS.NAV_BAR_COLLAPSED_WIDTH : CONSTANTS.NAV_BAR_EXPANDED_WIDTH;

  const isFloatingTicketPanelActive = keys.length > 0;
  const onLeft = keys.length > getVisiblePanelsOnScreenCount(navBarWidth) + 1 ? keys.slice(0, startIndexToShow) : [];
  const onRight =
    keys.length - onLeft.length - getVisiblePanelsOnScreenCount(navBarWidth) > 0
      ? keys.slice(startIndexToShow + getVisiblePanelsOnScreenCount(navBarWidth))
      : [];
  const openedTicketPanelIndex = keys.findIndex(
    (url) =>
      panels[url].mode === FloatingTicketInstanceModes.REGULAR_MODE_OPENED ||
      panels[url].mode === FloatingTicketInstanceModes.AUTO_POPULATE,
  );
  const expandedTicketPanelIndex = keys.findIndex((url) => panels[url].mode === FloatingTicketInstanceModes.EXPANDED);
  const hasExpandedTicketPanel = expandedTicketPanelIndex !== -1;

  return (
    <>
      {hasExpandedTicketPanel && <StyledFloatingTicketPanelBackdrop />}
      {onLeft.length > 0 && (
        <MoreFloatingTickets
          onClick={() => {
            if (isDisabled) return;
            onShowMoreClicked(-1);
          }}
          count={onLeft.length}
        />
      )}
      {isFloatingTicketPanelActive && (
        <>
          {keys.map((key, index) => (
            <FloatingTicketPanelInstance
              openedTicketPanelIndex={openedTicketPanelIndex}
              expandedTicketPanelIndex={expandedTicketPanelIndex}
              startIndexToShow={startIndexToShow}
              index={index}
              key={key}
              totalPanels={keys.length}
              panel={panels[key]}
              isRightArrowShown={onRight.length > 0}
            />
          ))}
        </>
      )}
      {onRight.length > 0 && (
        <MoreFloatingTickets
          direction="right"
          onClick={() => {
            if (isDisabled) return;
            onShowMoreClicked(+1);
          }}
          count={onRight.length}
        />
      )}
    </>
  );
}
