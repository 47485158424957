import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';

export const StyledAssigneeRender = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
  width: 'max-content',
  maxWidth: '90%',
}));
