import { useMemo } from 'react';
import CloseIcon from '@mui/icons-material/Close';

import { ActionWrapper, ClearButton, SelectButton } from './style';

const DropDownActions = ({
  isDirty,
  isMultiSelect,
  clearText,
  clearAllText,
  selectAllText,
  handleClearAll,
  clearValueHandler,
  handleSelectAll,
  isClearHidden,
  isSelectAllDisabled,
  isSelectAllHidden,
  isClearDisabled,
  subTitle,
}) => {
  const isMultiActions = useMemo(
    () => isMultiSelect !== null && isMultiSelect !== undefined && isMultiSelect,
    [isMultiSelect],
  );

  return (
    <>
      {!isMultiActions && clearValueHandler && !isClearHidden && (
        <ActionWrapper className="action-wrapper">
          <ClearButton
            isDirty={false}
            onClick={() => !isClearDisabled && clearValueHandler()}
            data-testid="select-clear-button"
            disabled={isClearDisabled}
          >
            <CloseIcon sx={{ fontSize: '12px', marginRight: '4px' }} />
            {clearText}
          </ClearButton>
        </ActionWrapper>
      )}
      {subTitle && <div className="sub-title">{subTitle}</div>}
      {isMultiActions && (
        <ActionWrapper className="action-wrapper" isMultiActions={isMultiActions}>
          {!isSelectAllHidden && (
            <SelectButton
              data-testid="select-all-button"
              onClick={() => !isSelectAllDisabled && handleSelectAll()}
              disabled={isSelectAllDisabled}
            >
              {selectAllText}
            </SelectButton>
          )}
          <ClearButton
            isDirty={isDirty}
            onClick={() => !isClearDisabled && handleClearAll()}
            disabled={isClearDisabled}
          >
            <CloseIcon sx={{ fontSize: '12px', marginRight: '4px' }} />
            {clearAllText}
          </ClearButton>
        </ActionWrapper>
      )}
    </>
  );
};

export default DropDownActions;
