import { ButtonUnstyled } from '@mui/core';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledRelatedItems = styled(Box, {
  shouldForwardProp: (prop) => !['hasNoRelatedItems'].includes(prop),
})(({ hasNoRelatedItems }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  paddingTop: '11px',
  marginRight: '9px',
  justifyContent: hasNoRelatedItems && 'center',
  alignItems: hasNoRelatedItems && 'center',
}));

export const StyledEmptyRelatedItems = styled(Box)(() => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
}));

export const StyledCreateRelatedItemsTitle = styled(Box)(({ theme }) => ({
  fontSize: '24px',
  lineHeight: '28.8px',
  color: theme.palette.text.txt_default,
  marginBottom: '8px',
  marginTop: '38px',
}));

export const StyledCreateRelatedItemsDescription = styled(Box)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '20px',
  color: theme.palette.text.title_txt,
  marginBottom: '16px',
}));

export const StyledLinkItemBtn = styled(ButtonUnstyled)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  height: '28px',
  width: 'fit-content',
  background: '#699D8C',
  color: 'white',
  borderRadius: '60px',
  padding: '4px 12px',
  cursor: 'pointer',
  outline: 'none',
  border: 'none',
  gap: '2px',
  fontSize: '12px',
  lineHeight: '20px',
  alignSelf: 'end',

  ':hover': {
    backgroundColor: theme?.palette?.generic?.sysaid_default_hover,
  },
}));
