import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    notExistInTemplateMessage: texts?.['spaces.field.tooltip.notInTemplate'] || 'Field not found in template',
    submitUser: texts?.['spaces.useColumnDefinitions.submitUser'],
    open: texts?.['spaces.cellRender.openSr'],
    notSavedModalMessage: texts?.['spaces.changes.fieldsNotSavedModal.description'],
    notSavedByPermissionMessage: texts?.['spaces.changes.fieldsNotSavedModal.noPermission'],
    notAvailableField: texts?.['spaces.queue.notAvailableField.title'],
    notAvailableFieldText: texts?.['spaces.queue.notAvailableField.text'],
    mandatoryFieldError: texts?.['spaces.field.tooltip.error.mandatoryFieldError'] || 'Missing required field',
    migrationFailedMessage:
      texts?.['spaces.migration.error'] ||
      'This ticket failed to be optimized for Spaces and is displayed in classic view.',
  };
  return componentTexts;
}
