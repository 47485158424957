import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    historyTitle: texts?.['spaces.resolutionPanel.journey.migrationTicket.history.title'],
    historyTimestampText: texts?.['spaces.resolutionPanel.journey.migrationTicket.history.headers.timestamp'],
    historyUserText: texts?.['spaces.resolutionPanel.journey.migrationTicket.history.headers.user'],
    historyChangeText: texts?.['spaces.resolutionPanel.journey.migrationTicket.history.headers.change'],
    historyDescriptionText: texts?.['spaces.resolutionPanel.journey.migrationTicket.history.headers.description'],
  };

  return componentTexts;
}
