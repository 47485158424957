import { useCallback, useMemo, useRef, useState } from 'react';
import { useRouter } from '@tanstack/react-router';
import { sortingFns as importedSortingFns } from '@tanstack/react-table';
import { useUserDetailsByName } from 'remote-state/ticketServiceHooks';
import { useUserInfo } from 'remote-state/userServiceHooks';
import { QUERIES_KEYS } from 'constant';
import { AVATAR_CONSTANTS, ROUTES_PATHS } from 'constants/index';
import { USER_CONSTANTS } from 'constants/users';
import { getTemplatesList } from 'services/templateService';
import Grid from 'common/components/grid';
import { getDate } from 'common/utils/dateTimeUtils';
import { COLUMN_FILTER_FIELDTYPE, FIELD_TYPE } from 'common/components/grid/constants';
import { dateFilterFn, getColumnsMap, numberFilterFn, setFilterFn, textFilterFn } from 'common/components/grid/filters/helpers';
import Tooltip from 'common/components/tooltip';
import { BasicAvatar } from 'common/components/avatars/basicAvatar';
import { ReactComponent as IconChecked } from 'images/icons/circledChecked.svg';
import TemplateListPageHeader from './TemplateListPageHeader';
import TemplateListActions from './TemplateListActions';
import { StyledTemplateList } from './style';
import useTexts from './useTexts';
import TabsHeader from '../externalPage/tabs-header';
import GoBack from '../externalPage/go-back';
import { getResourceInfo } from '../externalPage';

const templatesListFilterFn = (row, columnId, value, column) => {
  if (row.original.default) {
    return true;
  }
  switch (column?.filterType) {
    case COLUMN_FILTER_FIELDTYPE.set:
      return setFilterFn(row, columnId, value);
    case COLUMN_FILTER_FIELDTYPE.date:
      return dateFilterFn(row, columnId, value);
    case COLUMN_FILTER_FIELDTYPE.number:
      return numberFilterFn(row, columnId, value);
    default:
      return textFilterFn(row, columnId, value);
  }
};

function AvatarCell({ createdBy }) {
  const userDetailsData = useUserDetailsByName(createdBy);

  return (
    <>
      {createdBy && userDetailsData && (
        <BasicAvatar
          size={AVATAR_CONSTANTS.SIZES.MEDIUM}
          userName={userDetailsData.userName}
          profileImage={userDetailsData.profileImage}
          disableHover
        />
      )}
    </>
  );
}

export default function TemplateList() {
  const router = useRouter();
  const {
    searchTemplatePlaceholderText,
    listHeaderNameText,
    listHeaderTypeText,
    listHeaderCreateDateText,
    listHeaderVisibleInSSPText,
    listHeaderCreatedByText,
    listHeaderActionsText,
  } = useTexts();
  const gridRef = useRef(null);
  const queryFn = useCallback(getTemplatesList, []);
  const userInfo = useUserInfo(QUERIES_KEYS.CURRENT_USER_FROM_CORE);
  const { valueCaption: timezone } = userInfo?.data?.info?.find((atr) => atr.key === USER_CONSTANTS.TIMEZONE) || {};
  const { valueCaption: dateFormat } =
    userInfo?.data?.info?.find((atr) => atr.key === USER_CONSTANTS.DATE_FORMAT) || {};
  const { settingIds } = getResourceInfo();

  const initialState = useMemo(
    () => ({
      sortBy: [
        {
          id: 'dateCreated',
          desc: true,
        },
      ],
    }),
    [],
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        filterType: COLUMN_FILTER_FIELDTYPE.number,
        filterFn: 'templatesListFilterFn',
        sortingFn: 'templatesListSortingFn',
      },
      {
        accessorKey: 'templateName',
        header: listHeaderNameText,
        cell: (info) => (
          <>
            <div className="template-name">
              <Tooltip
                text={info.getValue()}
                isTruncatedText
                placement="top"
                style={{ fontFamily: 'Roboto-Regular', lineHeight: '16px' }}
              />
              {info.row.original.default && <span className="default-chip">default</span>}
            </div>
          </>
        ),
        filterType: COLUMN_FILTER_FIELDTYPE.text,
        filterFn: 'templatesListFilterFn',
        sortingFn: 'templatesListSortingFn',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'srType',
        header: listHeaderTypeText,
        templateFieldId: 32,
        cell: (info) => String(info.getValue()).charAt(0).toUpperCase() + String(info.getValue()).slice(1),
        filterType: COLUMN_FILTER_FIELDTYPE.set,
        fieldType: FIELD_TYPE.srType,
        filterMultipleSelection: true,
        filterFn: 'templatesListFilterFn',
        sortingFn: 'templatesListSortingFn',
      },
      {
        accessorKey: 'dateCreated',
        header: listHeaderCreateDateText,
        cell: (info) => getDate(timezone, dateFormat, info.getValue()),
        filterType: COLUMN_FILTER_FIELDTYPE.date,
        filterFn: 'templatesListFilterFn',
        sortingFn: 'templatesListSortingFn',
      },
      {
        accessorKey: 'visibleInSelfServicePortal',
        header: listHeaderVisibleInSSPText,
        cell: (info) => info.getValue() && <IconChecked data-testid="icon-svg" />,
        enableColumnFilter: false,
        sortingFn: 'templatesListSortingFn',
      },
      {
        accessorKey: 'createdBy',
        header: listHeaderCreatedByText,
        cell: (info) => (
          <div className="created-by">
            {info.getValue() && <AvatarCell createdBy={info.getValue()} />}
            <span className="user-info">{info.getValue()}</span>
          </div>
        ),
        templateFieldId: 62,
        filterType: COLUMN_FILTER_FIELDTYPE.set,
        fieldType: FIELD_TYPE.requestUser,
        filterMultipleSelection: true,
        filterFn: 'templatesListFilterFn',
        sortingFn: 'templatesListSortingFn',
      },
      {
        header: listHeaderActionsText,
        cell: (info) => (
          <TemplateListActions templateObject={info.row.original} templateList={info.table.options.data} />
        ),
        enableSorting: false,
        enableColumnFilter: false,
      },
    ],
    [
      dateFormat,
      listHeaderActionsText,
      listHeaderCreateDateText,
      listHeaderCreatedByText,
      listHeaderNameText,
      listHeaderTypeText,
      listHeaderVisibleInSSPText,
      timezone,
    ],
  );

  const columnsMap = useMemo(() => getColumnsMap(columns), [columns]);

  const templatesListDefaultSort = useCallback((rowA, rowB, desc) => {
    const defaultSrTypeOrder = ['incident', 'request', 'change', 'problem'];
    const rowASrTypeIndex = defaultSrTypeOrder.indexOf(rowA.getValue('srType'));
    const rowBSrTypeIndex = defaultSrTypeOrder.indexOf(rowB.getValue('srType'));
    if (rowASrTypeIndex > rowBSrTypeIndex) {
      return desc ? -1 : 1;
    }
    if (rowASrTypeIndex < rowBSrTypeIndex) {
      return desc ? 1 : -1;
    }
    return 0;
  }, []);

  const templatesListSortingFn = useCallback(
    (rowA, rowB, columnId, sorting) => {
      const desc = sorting && sorting[0].desc;
      if (rowA.original.default && !rowB.original.default) {
        return desc ? 1 : -1;
      }
      if (rowB.original.default && !rowA.original.default) {
        return desc ? -1 : 1;
      }
      if (rowA.original.default && rowB.original.default) {
        return templatesListDefaultSort(rowA, rowB, desc);
      }
      return sorting ? importedSortingFns.alphanumeric(rowA, rowB, columnId) : 0;
    },
    [templatesListDefaultSort],
  );

  const sortingFns = useMemo(
    () => ({
      templatesListSortingFn,
    }),
    [templatesListSortingFn],
  );

  const filterFns = useMemo(
    () => ({
      templatesListFilterFn: (row, columnId, value) =>
        templatesListFilterFn(row, columnId, value, columnsMap[columnId]),
    }),
    [columnsMap],
  );

  const handleRowClick = useCallback(
    (template) => router.navigate({ to: `/spaces/template?id=${template.id}` }),
    [router],
  );
  const [headerRef, setHeaderRef] = useState();
  const handleUpdateHeaderRef = (el) => setHeaderRef(el);

  const [columnFilters, setColumnFilters] = useState([]);
  const handleColumnFiltersChange = useCallback((columnFilters) => setColumnFilters(columnFilters), []);

  return (
    <StyledTemplateList hasFilters={columnFilters.length}>
      <GoBack onClick={() => router.navigate({ to: ROUTES_PATHS.SETTINGS_URL })} show />
      <TabsHeader settingIds={settingIds} />
      <div className="template-list-page">
        <TemplateListPageHeader
          updateRef={handleUpdateHeaderRef}
          columnFilters={columnFilters}
          columnsMap={columnsMap}
        />
        <Grid
          ref={gridRef}
          onRowClicked={handleRowClick}
          queryFn={queryFn}
          queryKey="templatesList"
          columns={columns}
          initialState={initialState}
          sortingFns={sortingFns}
          filterFns={filterFns}
          topFilterName="templateName"
          enableSortingRemoval={false}
          inputSearchLocation={headerRef}
          inputSearchPlaceholder={searchTemplatePlaceholderText}
          onColumnFiltersChange={handleColumnFiltersChange}
        />
      </div>
    </StyledTemplateList>
  );
}
