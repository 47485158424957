export const JOURNEY_FILTERS = {
  SUMMARY: 0,
  FULL_JOURNEY: 1,
  ACTIVITIES: 2,
  ATTACHMENTS: 3,
  AUDIT_LOG_ITEMS: 4,
};
export const JOURNEY_TABS = {
  TICKET_DETAILS: 0,
  WORKFLOW: 1,
  JOURNEY: 2,
  ATTACHMENTS: 3,
};
