import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    fromText: texts?.['spaces.datePicker.range.from'],
    toText: texts?.['spaces.datePicker.range.to'],
  };

  return componentTexts;
}
