import get from 'lodash/get';
import { RICH_TEXT_FIELD_IDS, SR_PANEL_CONSTANTS } from 'features/srPanel/consts';
import { getRichTextValueByColumnName } from 'common/components/controls/RichTextEditor/utils';
import { DEFAULT_RICH_TEXT_VALUE } from 'features/srPanel/templateFieldsGrid/richTextField/useRichTextEditor';
import {
  REQUEST_USER_FIELD_ID,
  USER_MANAGER_FIELD_ID,
  CC_USER_FIELD_ID,
  UPDATE_USER_FIELD_ID,
  RESPONSIBLE_MANAGER_FIELD_ID,
  FOLLOWUP_USER_FIELD_ID,
} from 'features/queue/grid/constants';
import {
  FIRST_LEVEL_CATEGORY_FIELD_ID,
  SECOND_LEVEL_CATEGORY_FIELD_ID,
  THIRD_LEVEL_CATEGORY_FIELD_ID,
} from 'features/header/categories/constants';

export const getIsCategory = (fieldId) =>
  [FIRST_LEVEL_CATEGORY_FIELD_ID, SECOND_LEVEL_CATEGORY_FIELD_ID, THIRD_LEVEL_CATEGORY_FIELD_ID].includes(
    Number(fieldId),
  );

const findRichTextByFieldName = (richTextFields, fieldName) =>
  fieldName ? richTextFields.find((richTextField) => richTextField.columnName === fieldName) : richTextFields[0];

const isJsonContentTextExists = (field) => {
  if (field?.jsonContent && typeof field.jsonContent === 'string') {
    try {
      const jsonContent = JSON.parse(field.jsonContent);
      return jsonContent.text !== '';
    } catch (error) {
      // Handle the case where jsonContent is not valid JSON
      console.error('Invalid JSON content:', error);
      return false;
    }
  } else if (typeof field?.jsonContent === 'object') {
    return field.jsonContent.text !== '';
  }
  return false;
};

const isRichTextValueExists = (richTextData, fieldName) => {
  if (!richTextData) return false; // if null in the new empty template

  if (typeof richTextData === 'string') {
    try {
      const richTextFields = JSON.parse(richTextData);
      const field = findRichTextByFieldName(richTextFields, fieldName);
      return isJsonContentTextExists(field);
    } catch (error) {
      // Handle the case where jsonContent is not valid JSON
      return !!richTextData;
    }
  } else if (typeof richTextData === 'object') {
    const field = findRichTextByFieldName(richTextData, fieldName);
    return isJsonContentTextExists(field);
  }

  return false;
};

export const fieldValueExists = (props) => {
  if (!props) return false;
  const { value, fieldId, fieldTypeId, fieldName, isTripleCategory } = props;
  if (value === 'none') {
    return false;
  }
  let parsedValue;
  if (getIsCategory(fieldId)) {
    parsedValue = value;
    if ((typeof value === 'string' || typeof value === 'object') && !!value) {
      let categories;
      if (typeof value === 'string') {
        try {
          parsedValue = JSON.parse(value);
        } catch {
          parsedValue = value;
        }
      }
      if (typeof parsedValue === 'object') {
        categories = Object.values(parsedValue);
        const isOneOfCategoriesCleared = categories.some((category) => String(category) === '-1');
        return !isOneOfCategoriesCleared && isTripleCategory ? categories.length === 3 : categories.length === 2;
      }
    }
    return !!parsedValue || parsedValue === 0;
  }

  if (SR_PANEL_CONSTANTS.MULTI_SELECT === fieldTypeId) {
    try {
      parsedValue = JSON.parse(value);
    } catch {
      parsedValue = value;
    }
    return !!parsedValue?.length;
  }
  if (SR_PANEL_CONSTANTS.BOOLEAN === fieldTypeId) {
    return typeof value === 'boolean';
  }

  if (RICH_TEXT_FIELD_IDS.includes(fieldId)) {
    return isRichTextValueExists(value, fieldName);
  }
  if (typeof value === 'number' && SR_PANEL_CONSTANTS.DATE !== fieldTypeId) {
    return !Number.isNaN(value) && (!!value || value === 0);
  }
  return !!value;
};

export const getIsUserFieldByFieldName = (fieldName) => {
  switch (fieldName) {
    case 'cc':
    case 'followupUser':
    case 'updateUser':
    case 'responsibleManager':
    case 'requestUser':
    case 'requestUser.userManagerName':
      return true;
    default:
      return false;
  }
};

export const getIsUserFieldByFieldId = (fieldName) => {
  switch (fieldName) {
    case CC_USER_FIELD_ID:
    case FOLLOWUP_USER_FIELD_ID:
    case UPDATE_USER_FIELD_ID:
    case RESPONSIBLE_MANAGER_FIELD_ID:
    case REQUEST_USER_FIELD_ID:
    case USER_MANAGER_FIELD_ID:
      return true;
    default:
      return false;
  }
};

export const getValueKeyByFieldName = (fieldName) => (getIsUserFieldByFieldName(fieldName) ? 'value' : 'valueKey');

export const getValueKeyByFieldId = (fieldId) => (getIsUserFieldByFieldId(fieldId) ? 'value' : 'valueKey');

export const getFieldsMap = (fields) => fields?.reduce((acc, field) => ({ ...acc, [field.fieldName]: field }), {});

export const getFieldAttributesMap = (fieldAttributes, fields) =>
  fieldAttributes?.reduce((fieldMap, { data: field }) => {
    if (field?.fieldName) {
      const { fieldName } = field;
      if (!fieldMap[fieldName]) {
        fieldMap[fieldName] = field;
      }
      const fieldDefinition = fields.find((f) => f.fieldName === fieldName);
      switch (fieldName) {
        case 'requestUser':
          fieldMap[fieldName].defaultValue = fieldDefinition?.defaultValue;
          fieldMap[fieldName].sortBy = 'valueCaption';
          break;
        case 'requestUser.agreement':
          fieldMap[fieldName].defaultValue = 1;
          break;
        default:
          fieldMap[fieldName].defaultValue = fieldDefinition?.defaultValue;
      }
    }
    return fieldMap;
  }, {});

export const isRequiredField = (srStatusValue, required = false, requiredStatuses = []) => {
  if (srStatusValue === undefined || srStatusValue === null || srStatusValue === '' || required === false) return false;
  if (required && Array.isArray(requiredStatuses) && !requiredStatuses.length) return true;
  if (required && Array.isArray(requiredStatuses) && requiredStatuses.length > 0) {
    return requiredStatuses.some((key) => String(typeof key === 'number' ? key : key.status) === String(srStatusValue));
  }
};

const extractValue = (sr, fieldName, isRichTextField) => {
  if (isRichTextField) return getRichTextValueByColumnName(sr.richTextFields, fieldName);

  //In some cases tickets are created by non user because of that API wouldnt be able return requstUser but only e-mail as requestUserName.
  const value = get(sr, fieldName);
  return fieldName === 'requestUser' && !value ? sr?.requestUserName ?? value : value;
};
export const getFieldValue = (field, sr) => {
  const { fieldName, fieldId, defaultValue, customColumn } = field;
  let value = defaultValue;
  const isRichTextField = RICH_TEXT_FIELD_IDS.includes(fieldId) && !customColumn;
  if (sr) {
    value = extractValue(sr, fieldName, isRichTextField);
  }
  if (!isRichTextField) {
    return value;
  }
  if (!value?.jsonContent) {
    if (!sr) {
      return undefined;
    }
    return DEFAULT_RICH_TEXT_VALUE;
  }
  let dataToReachTextField;
  try {
    dataToReachTextField = JSON.parse(value?.jsonContent);
  } catch (e) {
    dataToReachTextField = value?.jsonContent;
  }
  return dataToReachTextField;
};

export const customColumnIdSuffix = '_customColumn';

export const convertFromClientFieldId = (fieldId, customColumn) =>
  customColumn ? parseInt(fieldId.toString().replace(customColumnIdSuffix, ''), 10) : fieldId;

export const convertToClientFieldId = (fieldId, customColumn) =>
  customColumn ? `${fieldId}${customColumnIdSuffix}` : fieldId;

export const convertToClientField = (field, key = 'id') => ({
  ...field,
  [key]: convertToClientFieldId(field[key], field.customColumn),
});

export const convertFromClientField = (field, key = 'id') => ({
  ...field,
  [key]: convertFromClientFieldId(field[key], field.customColumn),
});
