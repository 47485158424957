const SR_TYPES = { request: 'request', change: 'change', incident: 'incident', problem: 'problem' };

const SR_TYPE_CODE_MAP = {
  10: SR_TYPES.request,
  4: SR_TYPES.change,
  1: SR_TYPES.incident,
  6: SR_TYPES.problem,
};

const SR_CODE_TYPE_MAP = {};
for (const code in SR_TYPE_CODE_MAP) {
  SR_CODE_TYPE_MAP[SR_TYPE_CODE_MAP[code]] = code;
}

const SR_SOURCE_TYPE = {
  SR_SOURCE_NA: 0,
  SR_SOURCE_ADMIN_PORTAL: 1,
  SR_SOURCE_ADMIN_PORTAL_MOBILE: 2,
  SR_SOURCE_PHONE_CALL: 3,
  SR_SOURCE_END_USER_PORTAL: 4,
  SR_SOURCE_AGENT: 5,
  SR_SOURCE_EMAIL_INTEGRATION: 6,
  SR_SOURCE_MONITORING: 7,
  SR_SOURCE_TASK: 8,
  SR_SOURCE_CHAT: 9,
  SR_SOURCE_REMINDER: 11,
  SR_SOURCE_SELF_SERVICE: 12,
  SR_SOURCE_PASSWORD_SERVICES: 13,
};

const SR_PREVIEW_MODE_KEY = 'preview';

export { SR_TYPE_CODE_MAP, SR_CODE_TYPE_MAP, SR_PREVIEW_MODE_KEY, SR_SOURCE_TYPE, SR_TYPES };
