import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledCheckMailPage = styled(Box)(({ theme }) => ({
  height: '100%',

  '#login-wrapper': {
    width: '100%',
    height: '100%',
    display: 'flex',
    padding: '3.41vw',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.background.pages_background,
  },

  '#left-side-container': {
    display: 'flex',
    flexDirection: 'column',
    width: '41.59%',
    height: '100%',
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    position: 'relative',
  },

  '#left-side-wrapper': {
    width: '352px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: '-50px',
  },

  '.left-side-logo': {
    margin: '0 auto 50px',
  },

  '.placeholder-label': {
    width: '100%',
  },

  '#left-side-header': {
    fontSize: theme?.fontSize?.xLarge,
    color: theme.palette.text.title_txt,
    textAlign: 'left',
    border: 'none',
    outline: 'none',
    padding: 0,
    width: '100%',
    marginBottom: '40px',
  },

  '#left-side-sub-header': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontSize: theme?.fontSize?.medium,
  },

  '.line': {
    width: '100%',
    fontFamily: theme.fonts.robotoLight,
    fontSize: theme?.fontSize?.normal,
    color: theme.palette.text.autocompleteSecondary,
    border: 'none',
    outline: 'none',
    padding: 0,
    marginTop: '3px',
  },

  '.check-mail-sub-header': {
    marginBottom: '40px',
    width: '100%',
  },

  '#left-side-submit-button': {
    width: '185px',
    height: '40px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '6px 28px',
    borderRadius: '60px',
    backgroundColor: theme.palette.generic.sysaid,
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    margin: '0 auto',
    fontSize: theme?.fontSize?.main,
  },

  '@media screen and (max-width: 1024px)': {
    '#left-side-container': {
      minWidth: '100%',
      borderRadius: '10px',
      borderTopRightRadius: '25px !important',
      borderBottomRightRadius: '25px !important',
      position: 'relative',
    },
    '#left-side-wrapper': {
      margin: 'auto',
    },

    '.left-side-logo': {
      display: 'none',
    },

    '#left-side-submit-button': {
      height: '38px !important',
      marginTop: '25px !important',
    },
    '.check-mail-sub-header': {
      marginBottom: '20px !important',
    },
  },

  '@media screen and (max-width: 584px)': {
    '#left-side-wrapper': {
      minWidth: '70%',
    },
  },

  '@media screen and (max-height: 800px)': {
    '#left-side-wrapper': {
      marginTop: 0,
      '.left-side-logo': {
        display: 'none',
      },
      '.login-reset-password': {
        marginTop: '-35px',
      },
    },
  },
}));
