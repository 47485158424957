import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledRightPanel = styled(Box)(({ theme }) => ({
  width: '58%',
  height: '100%',
  display: 'flex',
  backgroundColor: theme.palette.generic.primary,
  borderLeft: `1px solid ${theme.palette.border.login}`,
  position: 'relative',
  borderRadius: '10px',
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  justifyContent: 'center',
  alignItems: 'center',

  '#sysaid-logo': {
    width: '95%',
    height: '95%',
    objectFit: 'contain',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },

  '@media screen and (max-width: 1024px)': {
    display: 'none !important',
  },
}));
