import { createContext } from 'react';

export const ConfigOpenContext = createContext({
  open: false,
  setOpen: () => {},
});

export const ColItemsContext = createContext({
  items: [],
  setItems: () => {},
});
