import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

export const StyledDueDateField = styled(Box, {
  shouldForwardProp: (prop) => !['bgColorIndicator', 'disabled'].includes(prop),
})(({ theme, bgColorIndicator, disabled }) => {
  let bg = theme.palette.blueLabels.bt_light_blue_reg;
  let bgHover = theme.palette.blueLabels.bt_light_blue_hover;
  let bgColor = 'transparent';

  if (bgColorIndicator === 'critical') {
    bg = theme.palette.labels.bt_red_reg;
    bgHover = theme.palette.labels.bt_red_hover;
  } else if (bgColorIndicator === 'medium') {
    bg = theme.palette.labels.bt_yellow_reg;
    bgHover = theme.palette.labels.bt_yellow_hover;
  } else if (bgColorIndicator === 'low') {
    bg = theme.palette.blueLabels.bt_light_blue_reg;
    bgHover = theme.palette.blueLabels.bt_light_blue_hover;
  } else if (bgColorIndicator === 'ended') {
    bg = theme.palette.text.title_txt;
    bgHover = theme.palette.text.title_txt;
  } else if (bgColorIndicator === 'closed') {
    bgColor = '#787878';
  }
  return {
    display: 'flex',
    flexDirection: 'column',
    width: '110px',
    height: '24px',
    backgroundColor: bgColor,
    borderRadius: '12px',
    position: 'relative',
    cursor: disabled ? 'default' : 'pointer',
    '.MuiLinearProgress-root': {
      backgroundColor: theme.palette.text.disabled,
      transition: 'background-color 0.4s ease',
    },
    '.MuiLinearProgress-bar': {
      cursor: 'pointer',
      backgroundColor: bg,
      transition: 'background-color 0.4s ease',
    },
    '&:hover': {
      '.MuiLinearProgress-root': {
        backgroundColor: theme.palette.text.disabled,
      },
      '.MuiLinearProgress-bar': {
        cursor: 'pointer',
        backgroundColor: bgHover,
      },
    },
  };
});

export const StyledDueDateLabel = styled(Box)(({ theme }) => ({
  position: 'absolute',
  fontSize: '12px',
  lineHeight: '24px',
  width: '100%',
  heght: '100%',
  borderRadius: '12px',
  color: theme.palette.generic.primary,
  alignSelf: 'center',
  zIndex: 3,
  textAlign: 'center',
  bottom: '0',
  background: 'tranparent',
  fontFamily: theme.fonts.robotoBold,
}));
