import moment from 'moment-timezone';
import { timezoneMapping } from './constants';

export const getTwoDigits = (number) => {
  if (String(number).length === 1) {
    return `0${number}`;
  }
  return number;
};

export const get12HourTimeFormat = (selectedHour) => {
  if (selectedHour > 12) return selectedHour - 12;
  if (selectedHour === 0 || selectedHour === 12) return 12;

  return selectedHour;
};

export const getDays = (diffInSeconds) => Math.trunc(diffInSeconds / 86400);

export const getHours = (diffInSeconds) => Math.trunc(diffInSeconds / 3600) % 24;

export const getMinutes = (diffInSeconds) => Math.trunc(diffInSeconds / 60) % 60;

export const getFillBarWidth = (startTime, currentTime, endTime) => {
  let fillBarWidth;
  if (!endTime) {
    fillBarWidth = 0;
  } else if (endTime - currentTime < 0) {
    fillBarWidth = 100;
  } else {
    const timeLeftPercentage = Math.round(((currentTime - startTime) / (endTime - startTime)) * 100);
    fillBarWidth = `${timeLeftPercentage}`;
  }

  return fillBarWidth;
};

export const getLeftTimeIndicator = (data, startTime, currentTime, endTime) => {
  let leftTimeIndicator;
  if (endTime - currentTime < 0) {
    leftTimeIndicator = 'critical';
  } else {
    const timeLeftPercentage = Math.round(((currentTime - startTime) / (endTime - startTime)) * 100);
    if (timeLeftPercentage < 50) {
      leftTimeIndicator = 'low';
    } else {
      leftTimeIndicator = 'medium';
    }
  }
  if (data?.valueClass === 1) {
    leftTimeIndicator = 'ended';
  }

  return leftTimeIndicator;
};

export function getDefaultTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export const getDate = (timezone, dateFormat = 'MM/DD/yyyy hh:mm a', logTimestamp) => {
  //If the timezone format is not region/city so convert it to this format.
  const defaultTimezone = getDefaultTimezone();
  const timezoneRegionCity = timezoneMapping[timezone] || timezone || defaultTimezone;
  if (logTimestamp) {
    return moment(logTimestamp).tz(timezoneRegionCity)?.format(dateFormat);
  }
  return moment().tz(timezoneRegionCity)?.format(dateFormat);
};

export const getCurrentTimeAtTimezone = (timezone, timeFormat = 'hh:mm A', logTimestamp) => {
  //If the timezone format is not region/city so convert it to this format.
  const defaultTimezone = getDefaultTimezone();
  const timezoneRegionCity = timezoneMapping[timezone] || timezone || defaultTimezone;
  if (logTimestamp) {
    return moment(logTimestamp).tz(timezoneRegionCity)?.format(timeFormat);
  }
  return moment().tz(timezoneRegionCity)?.format(timeFormat);
};
