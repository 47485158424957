import { Box } from '@mui/material';
import { AddAttachmentButton } from 'features/resolutionPanel/middlePanel/attachments/addAttachmentButton';
import { useUploadAttachments } from 'features/resolutionPanel/middlePanel/attachments/hooks/useUploadAttachments';

function Button({ content }) {
  return <Box className="footer-item">{content}</Box>;
}

export function AttachmentButton({ content, showTicketAsTab }) {
  const { handleUploadNewAttachment, handleUploadMultipleAttachments } = useUploadAttachments({ showTicketAsTab });

  return (
    <AddAttachmentButton
      handleUploadNewAttachment={handleUploadNewAttachment}
      handleUploadMultipleAttachments={handleUploadMultipleAttachments}
      btnComponent={Button}
      btnProps={{ content }}
    />
  );
}
