import { Button } from 'common/components/sysaidLibrary/Button';
import { ReactComponent as CompleteButtonIcon } from 'images/icons/checkmark-icon-thin.svg';
import { ReactComponent as ReverseButtonIcon } from 'images/icons/reverse-icon.svg';
import { StyledTooltip } from 'common/components/sysaidLibrary/Tooltip';
import useTexts from '../../../hooks/useTexts';

const CompleteButton = ({ completed, onClick, showError, disabled, showUpdateLoader }) => {
  const { completeText, reopenText, requiredFieldsMissingText } = useTexts();
  // TODO: Refactor props to use whatever error message comes from the form dynamically
  const errorProps = { title: requiredFieldsMissingText, type: 'error' };

  return (
    <StyledTooltip arrow placement="top" {...(showError && errorProps)} data-cy="complete-button-tooltip">
      <div>
        <Button
          data-testid="complete-button"
          data-cy="complete-button"
          text={completed ? reopenText : completeText}
          variant={completed ? 'secondary' : 'primary'}
          Icon={completed ? ReverseButtonIcon : CompleteButtonIcon}
          completed={completed}
          disabled={disabled}
          onClick={onClick}
          showUpdateLoader={showUpdateLoader}
        />
      </div>
    </StyledTooltip>
  );
};

export default CompleteButton;
