/* tslint:disable */
/* eslint-disable */
/**
 * Attachments APIs
 * This is the Open API specification for Attachments microservice API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: sysaid-rnd-spaces@sysaid.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { axiosInstance } from 'services/axiosConfig';
import { BaseAPI } from '../base';
import { handleParams } from './utils';
import { AttachmentPath } from '../constants';

export const AccountLimitationsApiAxiosParamCreator = function () {
  return {
    /**
     *
     * @summary Get account's attachments extensions whitelist
     */
    findAccountAttachmentsExtensionsWhitelist: async () => {
      const localVarPath = `/${AttachmentPath.DEFAULT}limitation/supported_extensions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');

      const localVarRequestOptions = { method: 'GET' };
      return handleParams(localVarUrlObj, localVarRequestOptions);
    },
    /**
     *
     * @summary Get account attachments usage in KB
     */
    findAccountUsageStorage: async () => {
      const localVarPath = `/${AttachmentPath.DEFAULT}limitation/usage`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');

      const localVarRequestOptions = { method: 'GET' };
      return handleParams(localVarUrlObj, localVarRequestOptions);
    },
    /**
     *
     * @summary Get account attachments max file size in KB
     */
    findMaxFileSize: async () => {
      const localVarPath = `/${AttachmentPath.DEFAULT}limitation/max_file_size`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      const localVarRequestOptions = { method: 'GET' };
      return handleParams(localVarUrlObj, localVarRequestOptions);
    },
  };
};

/**
 * AccountLimitationsApi - functional programming interface
 * @export
 */
export const AccountLimitationsApiFp = function () {
  return {
    /**
     *
     * @summary Get account's attachments extensions whitelist
     */
    async findAccountAttachmentsExtensionsWhitelist() {
      const localVarAxiosArgs =
        await AccountLimitationsApiAxiosParamCreator().findAccountAttachmentsExtensionsWhitelist();
      return (axios = axiosInstance) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get account attachments usage in KB
     */
    async findAccountUsageStorage() {
      const localVarAxiosArgs = await AccountLimitationsApiAxiosParamCreator().findAccountUsageStorage();
      return (axios = axiosInstance) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },

    /**
     *
     * @summary Get account attachments max file size in kb
     */
    async findMaxFileSize() {
      const localVarAxiosArgs = await AccountLimitationsApiAxiosParamCreator().findMaxFileSize();
      return (axios = axiosInstance) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * AccountLimitationsApi - object-oriented interface
 * @export
 * @class AccountLimitationsApi
 * @extends {BaseAPI}
 */
export class AccountLimitationsApi extends BaseAPI {
  /**
   *
   * @summary Get account's attachments extensions whitelist
   * @memberof AccountLimitationsApi
   */
  async findAccountAttachmentsExtensionsWhitelist() {
    return AccountLimitationsApiFp()
      .findAccountAttachmentsExtensionsWhitelist()
      .then((request) => request(this.axios));
  }
  /**
   *
   * @summary Get account attachments usage in KB
   * @memberof AccountLimitationsApi
   */
  async findAccountUsageStorage() {
    return AccountLimitationsApiFp()
      .findAccountUsageStorage()
      .then((request) => request(this.axios));
  }
  /**
   *
   * @summary Get account attachments max file size in KB
   * @memberof AccountLimitationsApi
   */
  async findMaxFileSize() {
    return AccountLimitationsApiFp()
      .findMaxFileSize()
      .then((request) => request(this.axios))
      .then((response) => response.data);
  }
}
