import Tooltip from 'common/components/tooltip';
import useTexts from 'features/queue/quickFiltersSelector/useTexts';
import { StyledIncidentLabel, StyledTicketNumber } from './style';

export const IncidentsLabel = ({ SrTypeFilterCounters }) => {
    const texts = useTexts();
    return (
      <StyledIncidentLabel>
        <Tooltip data-testid="incidents-label" isTruncatedText title={texts.incident} text={texts.incident} />
        <StyledTicketNumber isIncidentLabel>
          <span data-testid="incidents-number" className="text">{SrTypeFilterCounters.INCIDENT}</span>
        </StyledTicketNumber>
      </StyledIncidentLabel>
    );
};
