import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

export const StyledToggleButton = styled(Box, {
  shouldForwardProp: (prop) => !['isNavBarCollapsed'].includes(prop),
})(({ theme, isNavBarCollapsed }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '32px',
  height: '48px',
  position: 'absolute',
  right: 0,
  top: '20px',
  backgroundColor: theme.palette.background?.background,
  borderTopLeftRadius: '12px',
  borderBottomLeftRadius: '12px',
  background: theme.palette.grey.bt_alt_grey_light,
  zIndex: 1,
  '&:hover': {
    cursor: 'pointer',
  },
  '&.sr-panel-btn': {
    top: '12px',
    [theme.breakpoints.down(isNavBarCollapsed ? 1024 : 1210)]: {
      display: 'none',
    },
  },
}));
