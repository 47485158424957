import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledErrorBoundary = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  alignItems: 'center',

  '.error-sign': {
    minWidth: 'fit-content',
    width: '24px',
    height: '24px',
  },

  a: {
    textDecoration: 'underline',
    color: theme.palette.text.blue_user_name,
    cursor: 'pointer',
  },
}));

export const StyledMessage = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    fontFamily: theme.fonts.robotoRegular,
    fontSize: '0.875rem',
}));
