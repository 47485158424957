import { ReactComponent as AudioIcon } from 'images/icons/audioIcon.svg';
import { ReactComponent as ExcelIcon } from 'images/icons/excelIcon.svg';
import { ReactComponent as JpgIcon } from 'images/icons/jpgIcon.svg';
import { ReactComponent as PdfIcon } from 'images/icons/pdfIcon.svg';
import { ReactComponent as ScreenshotIcon } from 'images/icons/screenshotIcon.svg';
import { ReactComponent as PPTIcon } from 'images/icons/pptIcon.svg';
import { ReactComponent as txtIcon } from 'images/icons/textIcon.svg';
import { ReactComponent as VideoIcon } from 'images/icons/videoIcon.svg';
import { ReactComponent as WordIcon } from 'images/icons/wordIcon.svg';
import { ReactComponent as ArchiveIcon } from 'images/icons/zipIcon.svg';
import Audio from 'images/photos/Audio.png';
import Excel from 'images/photos/Excel.png';
import Jpg from 'images/photos/Jpg.png';
import Pdf from 'images/photos/Pdf.png';
import Screenshot from 'images/photos/Screen.png';
import PPT from 'images/photos/PPT.png';
import txt from 'images/photos/Text.png';
import Video from 'images/photos/Video.png';
import Word from 'images/photos/Word.png';
import Archive from 'images/photos/Zip.png';

export const FILES_ICONS = {
  Audio: AudioIcon,
  Excel: ExcelIcon,
  Jpg: JpgIcon,
  Pdf: PdfIcon,
  Screenshot: ScreenshotIcon,
  PPT: PPTIcon,
  txt: txtIcon,
  Video: VideoIcon,
  Word: WordIcon,
  Archive: ArchiveIcon,
};

export const FILES_PHOTOS = {
  Audio,
  Excel,
  Jpg,
  Pdf,
  Screenshot,
  PPT,
  txt,
  Video,
  Word,
  Archive,
};

export const SCREENSHOT_ICON_NAME = 'screenshot';
export const IMAGE_ICON_NAME = 'image';
export const VIDEO_ICON_NAME = 'video';
export const SCREEN_CAPTURE_TYPES = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
};
