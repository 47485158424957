import { styled } from '@mui/material/styles';
import ListItemButton from '@mui/material/ListItemButton';

export const StyledDragOverlayField = styled(ListItemButton, { shouldForwardProp: (prop) => prop !== 'isBig' })(
  ({ theme, isBig }) => {
    const background = theme?.palette?.background?.bg_hover_main;
    const boxShadow = '4px 4px 4px rgba(208, 231, 223, 0.5)';
    const border = `1px solid ${theme?.palette?.generic?.sysaid_default}`;
    const borderLeft = '4px solid #699D8C';
    return {
      borderRadius: theme.shape.border.borderRadius,
      background,
      border,
      borderLeft,
      boxShadow,
      cursor: 'grabbing',
      alignItems: 'center',
      padding: '6px 10px',
      transform: 'rotate(-9.37deg)',
      width: isBig ? '200%' : '100%',
      maxWidth: '100%',
      '&:hover': {
        background,
        border,
        boxShadow,
        borderLeft,
      },
      '.drag-icon': {
        width: '14px',
        height: '14px',
        '*': {
          stroke: theme.palette.generic.sysaid_hover,
        },
      },
      '.MuiListItemText-root': {
        marginLeft: '12px',
        marginTop: '5px',
        marginBottom: '3px',
      },
      '.MuiListItemText-primary': {
        fontSize: theme.fontSize.main,
        lineHeight: 1,
      },
    };
  },
);
