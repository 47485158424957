import { memo, useCallback } from 'react';
import { useNavigate } from '@tanstack/react-router';
import { useDispatch } from 'react-redux';
import { useHandleTicketUnlock, useMigrationFailedHandler } from 'remote-state/ticketServiceHooks';
import useValidation from 'common/utils/hooks/useValidation';
import { PERFORMANCE_MEASURE } from 'constants/performanceMeasure';
import { OpenTextPara, OpenTextWrapper, OpenTicketWrapper } from './styles';
import { rememberRowChanged, setRedirectURL, setShowErrorModal } from '../../../slice';
import { TICKETS_OPEN_URL } from '../../../../../constants';
import ExternalModal from '../../../../externalModal';
import { getSrById } from '../../../../../services/ticketService';

const OpenTicketCell = memo(({ checkAvailableTicket, username, rowIndex, srId, gridApi }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [checkRequiredFieldsValidation] = useValidation();
  const { classicSRUrl, handleMigrationFailed, handleModalClose } = useMigrationFailedHandler();
  const { mutate: unlockSR } = useHandleTicketUnlock(srId);

  const handleOpenSR = useCallback(() => {
    const spacesUrl = TICKETS_OPEN_URL.SPACES(srId);
    const isValid = checkRequiredFieldsValidation(srId);

    if (isValid) {
      if (checkAvailableTicket) {
        unlockSR({ username, srId });
      }
      dispatch(rememberRowChanged({ rowIndex, rowId: srId }));
      gridApi?.destroy();
      navigate({ to: spacesUrl });
    } else {
      dispatch(setShowErrorModal(true));
      dispatch(setRedirectURL(spacesUrl));
    }
  }, [
    checkRequiredFieldsValidation,
    checkAvailableTicket,
    unlockSR,
    username,
    srId,
    rowIndex,
    dispatch,
    navigate,
    gridApi,
  ]);

  const openTicketHandler = useCallback(() => {
    performance.mark(PERFORMANCE_MEASURE.QUEUE.OPEN_EXISTING_TICKET);
    getSrById(srId)
      .then((res) => {
        if (res.id) {
          handleOpenSR();
        }
      })
      .catch((error) => {
        if (error.response.status === 422) {
          handleMigrationFailed(srId);
        }
      });
  }, [handleOpenSR, srId, handleMigrationFailed]);

  return (
    <>
      <OpenTicketWrapper onClick={openTicketHandler} data-testid={`ticket-id-${srId}`}>
        <OpenTextWrapper id={`open-ticket-id-${srId}`} data-testid={`open-ticket-id-${srId}`}>
          <OpenTextPara data-testid={`open-ticket-button-${srId}`}>open</OpenTextPara>
        </OpenTextWrapper>
      </OpenTicketWrapper>
      <ExternalModal url={classicSRUrl} open={!!classicSRUrl} onClose={handleModalClose} />
    </>
  );
});

export default OpenTicketCell;
