import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';

export const StyledLogUser = styled(Box, {}) (() => ({

  maxWidth: '180px',
  minWidth: '180px',
  width: '180px',
  display: 'flex',
  flexDirection: 'flex-end',
  justifyContent: 'flex-end',

  '& .username': {
    maxWidth: '180px',
    display: 'inline-block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
}))