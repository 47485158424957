import { Tab } from '@mui/material';
import { StyledTabs } from './styles';

export default function MeMenuNavBar({ tabs, currentTab, setCurrentTab }) {
  return (
    <StyledTabs variant="fullWidth" value={currentTab} onChange={(e, selectedTab) => setCurrentTab(selectedTab)}>
      {tabs.map((tab, index) => (
        <Tab data-testid={`${tab}-${index + 1}`} key={tab} label={tab} disableRipple />
      ))}
    </StyledTabs>
  );
}
