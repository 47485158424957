import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

function useDebounceSearch(value, delay = 300) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = debounce(() => setDebouncedValue(value), delay);
    handler();
    return () => {
      handler.cancel();
    };
  }, [value, delay]);

  return debouncedValue;
}

export default useDebounceSearch;
