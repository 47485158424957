import Tooltip from 'common/components/tooltip';
import useTexts from '../../useTexts';

const MissingTemplateTooltip = ({ placement = "top", text = "--" }) => {
  const { notExistInTemplateMessage } = useTexts();
  
  return (
    <Tooltip
      title={notExistInTemplateMessage}
      placement={placement}
      text={text}
    />
  );
};

export default MissingTemplateTooltip;
