import { ASSIGNEE_CONSTANTS } from 'common/components/sysaidLibrary/Assignee/constants';
import { get } from 'lodash-es';
import { WORFKLOWS_CONSTANTS } from './constants';

export const getNestedOrBaseValue = (obj, key, nestedKey) =>
  get(obj, nestedKey) || (get(obj, nestedKey) == null && get(obj, key));

export const looseCompare = (val1, val2) => {
  if (!val1 && !val2) {
    return true;
  }
  //eslint-disable-next-line eqeqeq
  return val1 == val2;
};

export const buildAssigneeApiObject = ({ value }) => {
  const isUnassigned = value?.user === ASSIGNEE_CONSTANTS.UNASSIGNED;
  const assignedToValue = isUnassigned || !value?.user?.userName ? '' : value?.user?.userName;
  const assignedGroupValue = value?.group?.groupName || '';
  return [
    { fieldName: WORFKLOWS_CONSTANTS.FIELDS.ASSIGNED_TO.NAME, value: assignedToValue },
    { fieldName: WORFKLOWS_CONSTANTS.FIELDS.ASSIGNED_GROUP.NAME, value: assignedGroupValue },
  ];
};
