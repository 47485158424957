import { styled } from '@mui/material/styles';

import { BasicAvatar } from '../avatars/basicAvatar';

export const StyledChangeProfileBadge = styled(BasicAvatar)(({ theme }) => ({
  '&&&': {
    backgroundColor: theme.palette.generic.sysaid_light,
    height: '24px',
    width: '24px',
  },

  '&&& svg': {
    height: '14px',
    width: '14px',
  },
}));
