import { useState } from 'react';
import PropTypes from 'prop-types';

import Assignee from 'common/components/assignee';
import useAssigneeTexts from 'common/components/assignee/useTexts';
import { ReactComponent as AssigneeButton } from 'images/icons/bulkActions/assignee.svg';
import { useAssigneesList } from 'remote-state/ticketServiceHooks';
import { useUpdateTicketsByIdsQuery } from 'remote-state/bulkActionsHooks';

import useTexts from '../useTexts';
import { StyledActionIcon, StyledActionTitle, StyledAction } from '../style';
import { actionTypes } from '../constants';

export const AssigneeAction = ({
  selectedRows,
  onLoading,
  onActionClicked,
  onSetIsCacheRefreshNeeded,
  onChangeToasterMessage,
  onChangeAssigneeValue,
  dataset,
}) => {
  const [dropdownGroupName, setDropdownGroupName] = useState();
  const { changeFieldValues } = useUpdateTicketsByIdsQuery();
  const {
    admins: { data: admins },
    groups: { data: groups },
    adminsByGroup: { data: dropdownGroupList },
  } = useAssigneesList(dropdownGroupName);
  const { assignText, assignedTicketMessageText, assignedSubTitleText } = useTexts(selectedRows.length);
  const { assigneeText } = useAssigneeTexts();

  const handleSelectAssignee = async (admin, group) => {
    onChangeAssigneeValue(admin && admin.userName ? admin.userName : group?.groupName);
    const assigneeList = selectedRows.map(({ id }) => ({
      id,
      queueLocking: true,
      requestParams: { assignee: admin?.userName || 'none', assignedGroup: group?.groupName || 'none' },
    }));
    const selectedTicketsCount = selectedRows.length;
    let messageType = null;
    let messageText = null;
    let failedTickets = [];

    onLoading(true);

    try {
      const { numOfSuccessTickets, numOfFailedTickets, tickets } = await changeFieldValues(assigneeList);
      onSetIsCacheRefreshNeeded(true);

      failedTickets = tickets.filter((ticket) => !ticket.updateTicketResponse.success);
      messageType = numOfFailedTickets > 0 ? 'error' : 'success';
      messageText = assignedTicketMessageText({
        selectedTicketsCount,
        failureCount: numOfFailedTickets,
        successCount: numOfSuccessTickets,
        assignee: admin && admin.userName ? admin.userName : group?.groupName,
      });
    } catch (error) {
      messageType = 'error';
      messageText = assignedTicketMessageText({
        selectedTicketsCount,
        failureCount: selectedTicketsCount,
        successCount: 0,
        assignee: null,
      });
    }
    onLoading(false);
    onChangeToasterMessage({
      errorDetails: failedTickets,
      message: messageText,
      type: messageType,
    });
  };

  const CustomAssigneeButton = () => (
    <>
      <StyledActionIcon>
        <AssigneeButton />
      </StyledActionIcon>
      <StyledActionTitle>{assignText}</StyledActionTitle>
    </>
  );

  return (
    <StyledAction isCursorPointer onClick={() => onActionClicked({ actionType: actionTypes.ASSIGN })} {...dataset}>
      <Assignee
        assigneeTitle={assigneeText}
        filteredAssignees={[]}
        filteredGroups={[]}
        handleChangeAssignees={(admin, group) => handleSelectAssignee(admin, group)}
        isMultiSelect={false}
        disablePopover={false}
        adminList={admins}
        groupList={groups}
        dropdownGroupName={dropdownGroupName}
        setDropdownGroupName={setDropdownGroupName}
        dropdownGroupList={dropdownGroupList}
        subTitle={assignedSubTitleText}
        disabledClear
        buttonStyle={{
          flexDirection: 'column',
        }}
        menuStyle={{
          marginTop: '-100px',
          marginLeft: '-17px',
          bottom: '113px',
        }}
        CustomAssigneeButton={CustomAssigneeButton}
      />
    </StyledAction>
  );
};

AssigneeAction.propTypes = {
  onLoading: PropTypes.func,
  onActionClicked: PropTypes.func,
  onSetIsCacheRefreshNeeded: PropTypes.func,
  onChangeToasterMessage: PropTypes.func,
  onChangeAssigneeValue: PropTypes.func,
};

AssigneeAction.defaultProps = {
  onLoading: () => {},
  onActionClicked: () => {},
  onSetIsCacheRefreshNeeded: () => {},
  onChangeToasterMessage: () => {},
  onChangeAssigneeValue: () => {},
};

export default AssigneeAction;
