import { ReactComponent as ErrorSign } from 'images/icons/ErrorSign.svg';
import useTexts from './useTexts';
import { StyledErrorBoundary, StyledMessage } from './style';

export const ErrorBoundary = () => {
  const { message, refreshText, supportText } = useTexts();

  const errorMessage = message || "We're sorry but we encountered a problem while loading the journey items. We suggest that you __refresh__ this page. If this problem repeats itself please contact __support_link__.";

  const handleReplaceStringToElement = (formattedMessage, key, element) =>
    formattedMessage?.replace(key, element);

  const formatErrorMessage = () => {
    let formattedMessage = errorMessage;
    formattedMessage = handleReplaceStringToElement(formattedMessage, '__refresh__', `<a onclick="window.location.reload()">${refreshText || 'refresh'}</a>`);
    formattedMessage = handleReplaceStringToElement(formattedMessage, '__support_link__', `<a href="https://helpdesk.sysaid.com/Home.jsp" target="_blank">${supportText || 'support'}</a>`);
    return formattedMessage;
  };

  return (
    <StyledErrorBoundary data-testid="error-boundary-fallback">
      <ErrorSign className="error-sign" />
      <StyledMessage>
        <div dangerouslySetInnerHTML={{ __html: formatErrorMessage() }} />
      </StyledMessage>
    </StyledErrorBoundary>
  );
};
