import { styled, Box } from '@mui/material';

export const StyledTypography = styled(Box)(({ theme }) => ({
  fontSize: '0.9rem',
  padding: theme.spacing(0, 1),
  display: 'flex',
  alignItems: 'center',
  height: theme.spacing(5.25),
  color: theme.palette.text.title_txt,
}));

export const InfoTypography = ({ message }) => <StyledTypography>{message}</StyledTypography>;
