import { useRef, useCallback } from 'react';
import { ReactComponent as MoreMenuIcon } from 'images/icons/moreMenu.svg';
import { StyledMenuButton, StyledDropDownMenu, StyledText } from './StyledDropDownMenu';

export const DropDownMenu = ({
  children,
  anchorOrigin = { vertical: 'bottom', horizontal: 'right' },
  transformOrigin = { vertical: 'top', horizontal: 'right' },
  handleOpen,
  open,
  icon,
  'data-cy': cyKey,
  text = '',
  minWidth,
}) => {
  const ref = useRef();
  const handleOpenMenu = useCallback(
    (e) => {
      e.stopPropagation();
      handleOpen(true);
    },
    [handleOpen],
  );
  const handleCloseMenu = useCallback(
    (e) => {
      e.stopPropagation();
      handleOpen(false);
    },
    [handleOpen],
  );

  return (
    <>
      <StyledMenuButton
        className="more-action-button"
        disableRipple
        data-cy={cyKey}
        data-testid="drop-down-menu-btn"
        onClick={handleOpenMenu}
        ref={ref}
        isOpen={open}
        text={text}
      >
        <>
          {text && <StyledText>{text}</StyledText>}
          {icon || <MoreMenuIcon />}
        </>
      </StyledMenuButton>
      <StyledDropDownMenu
        anchorEl={ref.current}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        open={open}
        onClose={handleCloseMenu}
        minWidth={minWidth}
      >
        {children}
      </StyledDropDownMenu>
    </>
  );
};
