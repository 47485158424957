import axios from 'axios';

export function getKeepALive() {
    const timeStamp = new Date().getTime();
    return axios
        .get(`/KeepAlive.jsp?stamp=${timeStamp}&notAddingIndexJSP=true&ssp=true`)
        .then(response => Promise.resolve(response.data))
        .catch(error => {
            console.error('Error fetching KeepAlive:', error);
            throw error;
        });
}
