import { useEffect, useState } from 'react';
import { useRouter } from '@tanstack/react-router';
import { useSetTemplateAsDefault } from 'remote-state/templateHooks';
import { useDispatch, useSelector } from 'react-redux';
import { setToasterMessage } from 'store/globalSlice';
import { selectActiveUser } from 'store/userSlice';
import { ReactComponent as TemplateIcon } from 'images/icons/template.svg';
import MessagePrompt from 'common/components/messagePrompt';
import usePromptTexts from 'common/components/messagePrompt/useTexts';
import usePendoTrackEvents from 'common/utils/hooks/usePendoTrackEvents';
import { PENDO_TRACK_EVENTS } from '../../../constants';
import useTexts from '../useTexts';
import { StyledTemplateButton } from '../StyledTemplateButton';
import { TEMPLATES_MENU_ITEM_ID, TEMPLATES_SUB_MENU_ITEM_ID, TEMPLATES_SUB_MENU_LIST_ID } from '../../settings/constants';

export default function TemplateSetAsDefaultButton({ newTemplate, oldTemplate, handleOpenMenu }) {
  const { id: templateId } = newTemplate;
  const { srType, templateName } = oldTemplate;
  const [showPrompt, setShowPrompt] = useState(false);
  const dispatch = useDispatch();
  const router = useRouter();
  const userAccount = useSelector(selectActiveUser);
  const pendoTrackEvents = usePendoTrackEvents();
  const {
    templateSetAsDefaultText,
    setAsDefaultPromptTitleText,
    setAsDefaultPromptMessageText,
    setAsDefaultSuccessText,
    setAsDefaultErrorText,
  } = useTexts();
  const { okBtn } = usePromptTexts();
  const { mutate, status, isLoading } = useSetTemplateAsDefault(newTemplate);
  const handleSetAsDefault = () => {
    pendoTrackEvents(PENDO_TRACK_EVENTS.SET_TEMPLATE_AS_DEFAULT_WAS_CLICKED, {
      updateUser: userAccount?.username,
      templateId,
    });
    mutate({ updateUser: userAccount?.username, templateId });
  };

  useEffect(() => {
    if (status === 'success') {
      dispatch(setToasterMessage({ message: setAsDefaultSuccessText }));
      const settingIds = { menuItemId: TEMPLATES_MENU_ITEM_ID, subMenuListId: TEMPLATES_SUB_MENU_LIST_ID, subMenuItemId: TEMPLATES_SUB_MENU_ITEM_ID };
      const path = `/spaces/templates-list?settingIds=${encodeURIComponent(JSON.stringify(settingIds))}`;
      router.navigate({ to: path });
    }
    if (status === 'error') {
      dispatch(setToasterMessage({ message: setAsDefaultErrorText, type: 'error' }));
    }
    if (!isLoading && ['success', 'error'].includes(status)) {
      setShowPrompt(false);
      handleOpenMenu(false);
    }
  }, [status, isLoading, setAsDefaultSuccessText, setAsDefaultErrorText, dispatch, handleOpenMenu, router]);

  return (
    <>
      <StyledTemplateButton
        shouldhover={false}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShowPrompt(true);
        }}
        fullWidth
        startIcon={<TemplateIcon />}
      >
        {templateSetAsDefaultText}
      </StyledTemplateButton>
      {showPrompt && (
        <MessagePrompt
          open
          showCancelBtn
          title={setAsDefaultPromptTitleText}
          isOkButtonDisable={isLoading}
          btnOkText={okBtn}
          onOkClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleSetAsDefault();
          }}
          onClose={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowPrompt(false);
          }}
        >
          {setAsDefaultPromptMessageText?.replace('$type', srType).replace('$template_name', templateName)}
        </MessagePrompt>
      )}
    </>
  );
}
