import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import NavLink from 'features/navBar/navLink';
import { useApplicationData } from 'remote-state/applicationHooks';
import { useGetAIConfigData } from 'remote-state/aiConfigDataHook';
import { useFeatureFlagQuery } from 'remote-state/featureFlagsHooks';
import { QUERIES_KEYS } from 'constant';
import { ReactComponent as CopilotStar } from 'images/icons/copilotStar.svg';
import { FEATURE_FLAGS_KEYS } from 'constants/featureFlags';
import { icons } from './icons';
import Title from './menuTitle';
import useTexts from './useTexts';
import { StyledMenuButtonsContainer } from './styles';
import { COPILOT_MENU_CLASS, COPILOT_SUB_MENU_ITEM_ID } from './constants';



const MenuItem = ({ id: subMenuListId, rbToken, subMenuList, searchFilter, menuItemId, highlightColor }) => {
  const { data: isAiEnabled } = useApplicationData(QUERIES_KEYS.IS_AI_ENABLED);
  const { data: isAiAdministrator } = useGetAIConfigData(QUERIES_KEYS.IS_AI_ADMINISTRATOR);
  const { data: isCopilotSpacesLobbySettings } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.COPILOT_SPACES_LOBBY_SETTINGS,
    defaultValue: true,
  });
  const { availableUpgrade, getItemTitle } = useTexts();
  const Icon = icons[subMenuListId];
  const isCopilotSubMenu = subMenuListId === COPILOT_SUB_MENU_ITEM_ID;
  let displayCopilotAvailableBadge = false;
  let copilotMenuClass = '';
  if (isCopilotSubMenu) {
    if (!isAiEnabled) {
      copilotMenuClass = COPILOT_MENU_CLASS.UNAVAILABLE;
      displayCopilotAvailableBadge = true;
    } else if (!isAiAdministrator) {
      copilotMenuClass = COPILOT_MENU_CLASS.DISABLE;
    }
    if (!isCopilotSpacesLobbySettings) return null;
  }
  return (
    <StyledMenuButtonsContainer className={`menu-item ${copilotMenuClass}`} data-testid="menu-buttons-container">
      {Icon && <Icon data-testid="icon" />}
      {displayCopilotAvailableBadge && (
        <Box className="copilot-badge" data-testid="copilot-available-badge">
          {availableUpgrade} <CopilotStar />
        </Box>
      )}
      <div className="menu-item-header" data-testid={getItemTitle(rbToken).replaceAll(' ', '')}>
        <Title
          title={getItemTitle(rbToken)}
          highlightText={searchFilter}
          highlightColor={highlightColor}
          id={rbToken?.replaceAll('.', '-')}
          className="title"
        />
        <div className="links-wrapper">
          {subMenuList.map(({ id, rbToken, url, isOpenInNewTab }) => {
            const setting = { menuItemId, subMenuListId, subMenuItemId: id };
            return (
              <NavLink
                data-testid={`${id}-nav-link`}
                settingIds={setting}
                to={url}
                isOpenInNewTab={isOpenInNewTab}
                isSettingsPage
                key={`${id}-sub-menu-title`}
                disableLink={isCopilotSubMenu && (!isAiEnabled || !isAiAdministrator)}
              >
                <Title
                  id={rbToken?.replaceAll('.', '-')}
                  className="link-menu-title"
                  title={getItemTitle(rbToken)}
                  highlightText={searchFilter}
                  highlightColor={highlightColor}
                />
              </NavLink>
            );
          })}
        </div>
      </div>
    </StyledMenuButtonsContainer>
  );
};

Title.propTypes = {
  rbToken: PropTypes.string,
  subMenuList: PropTypes.array,
  searchFilter: PropTypes.string,
  highlightColor: PropTypes.string,
};

Title.defaultProps = {
  rbToken: '',
  subMenuList: [],
  searchFilter: '',
};

export default MenuItem;
