import CheckboxMUI from '@mui/material/Checkbox';
import { ReactComponent as CheckboxOnIcon } from '../../../images/icons/checkedOn.svg';
import { ReactComponent as CheckboxOff } from '../../../images/icons/checkedOff.svg';
import { ReactComponent as IndeterminateIcon } from '../../../images/icons/indeterminate.svg';

//TODO: add locked icon for disabled state  (inline edit)
//import { ReactComponent as CheckboxLocked } from '../../../images/icons/checkedLocked.svg';

import { StyledCheckbox } from './style';

export default function Checkbox(props) {
  const { className = '', checked, disabled, indeterminate } = props;

  return (
    <StyledCheckbox className={`Checkbox ${className}`} data-testid="common-checkbox" data-cy="common-checkbox">
      <CheckboxMUI
        {...props}
        icon={<CheckboxOff />}
        checkedIcon={<CheckboxOnIcon />}
        indeterminateIcon={<IndeterminateIcon />}
        aria-checked={checked}
        checked={checked}
        disabled={disabled}
        indeterminate={indeterminate}
      />
    </StyledCheckbox>
  );
}

//no prop types because the props are MUI checkbox props
