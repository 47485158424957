import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledChooseCategoryItem = styled(Box)(({ theme, style }) => ({
  paddingTop: '0.75rem',
  paddingLeft: '0.5rem',
  paddingRight: '12px',
  paddingBottom: '0.25rem',
  lineHeight: '1.25rem',
  fontSize: theme.fontSize.small,
  fontFamily: 'Roboto-Bold',
  color: theme.palette.text.title_text,
  ...style,
}));
