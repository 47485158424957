import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip from '../../tooltip';

export const AssigneeText = styled(Tooltip, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isError',
})(({ theme, isError, isSelected }) => {
  const expandedStyles = {
    background: isError ? theme.palette.labels.bt_red_mid : theme.palette.background.bg_hover_alt,
    color: isError ? theme.palette.labels.bt_red_hover : theme.palette.text.txt_default,
  };

  return {
    fontSize: '14px',
    color: isError ? theme.palette.labels.bt_red_reg : theme.palette.text.txt_default,
    padding: theme.spacing(0.5, 0.75),
    borderRadius: theme.spacing(0.5),
    lineHeight: '20px',
    ...(isSelected ? expandedStyles : {}),
  };
});

export const StyledAssignees = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'isError' && prop !== 'disabled' && prop !== 'isTemplatePage' && prop !== 'isFloatingTicketPanel',
})(({ theme, isError, disabled, isTemplatePage }) => ({
  height: '22px',
  display: 'flex',
  flexFirection: 'row',
  justifyContent: 'left',
  alignItems: 'center',
  gap: '11px',
  padding: 0,
  maxWidth: '100%',
  cursor: disabled ? 'default' : 'pointer',
  '& #unassigned-avatar': isError && {
    backgroundColor: theme.palette.labels.bt_red_light,
    borderColor: theme.palette.labels.bt_red_reg,
    '& svg *': {
      fill: theme.palette.labels.bt_red_reg,
    },
  },
  '& .assignee-open-popup-button.opened #unassigned-avatar': isError && {
    backgroundColor: theme.palette.labels.bt_red_mid,
    borderColor: theme.palette.labels.bt_red_hover,
    '& svg *': {
      fill: theme.palette.labels.bt_red_hover,
    },
  },
  '& .MuiAvatar-root': isError && {
    filter: 'none !important',
    '&:after': {
      display: 'none',
    },
  },
  '&:hover': isError && {
    '& #unassigned-avatar': {
      backgroundColor: theme.palette.labels.bt_red_mid,
      borderColor: theme.palette.labels.bt_red_hover,
      '& svg *': {
        fill: theme.palette.labels.bt_red_hover,
      },
    },
    '& .current-assignee': {
      backgroundColor: theme.palette.labels.bt_red_mid,
      color: theme.palette.labels.bt_red_hover,
    },
  },

  '& .current-assigned-ellipse': {
    margin: '0 18px 5px 0',
    padding: '4px 1px 1px 4px',
  },

  '& .assigned': {
    margin: '0 0 5px 8px',
    padding: '4px',
  },
  '& .active': {
    width: '7px',
    height: '7px',
    margin: '25px 0 0 20px',
    backgroundColor: theme.palette.generic.sysaid,
    borderRadius: '50%',
  },
  '& .current-assigned-active': {
    width: '8px',
    height: '8px',
    margin: '24px 0 0 22px',
    backgroundColor: theme.palette.generic.sysaid,
    borderRadius: '50%',
  },

  '& .all-assignees': {
    visibility: 'hidden',
    display: 'flex',
    flexFirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
    gap: '11px',
    padding: 0,
    maxWidth: '100%',
    cursor: disabled ? 'default' : 'pointer',

    '& .all-assignees .assigned': {
      marginLeft: '-9px',
    },

    '& .current-assignee': {
      fontSize: '14px',
      lineHeight: '16px',
      color: isError ? theme.palette.labels.bt_red_reg : theme.palette.text.txt_default,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      '@media only screen and (max-width: 1440px)': {
        display: isTemplatePage ? 'none' : 'inline',
      },
    },
    '& #unassigned-avatar': {
      backgroundColor: isError && theme.palette.labels.bt_red_light,
      border: isError && `1px dashed ${theme.palette.labels.bt_red_reg}`,
    },
    '&.unassigned': {
      '& .avatar-wrapper': {
        border: `1px dashed ${theme.palette.labels.bt_red_reg}`,
      },
      '& .active': {
        width: '7px',
        height: '7px',
        margin: '25px 0 0 20px',
        backgroundColor: theme.palette.generic.sysaid,
        borderRadius: '50%',
      },
      '& .current-assigned-active': {
        width: '8px',
        height: '8px',
        margin: '24px 0 0 22px',
        backgroundColor: theme.palette.generic.sysaid,
        borderRadius: '50%',
      },

      '& .all-assignees': {
        visibility: 'hidden',
        display: 'flex',
        flexDirection: 'row',
      },

      '& .all-assignees .assigned': {
        marginLeft: '-9px',
      },

      '& .current-assignee': {
        fontSize: '14px',
        lineHeight: '16px',
        color: theme.palette.text.txt_default,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        '@media only screen and (max-width: 1440px)': {
          display: isTemplatePage ? 'none' : 'inline',
        },
      },
    },
    '@media only screen and (max-width: 1920px)': {
      maxWidth: '160px',
    },
    '@media only screen and (max-width: 1440px)': {
      maxWidth: '140px',
    },
    '@media only screen and (max-width: 1280px)': {
      maxWidth: '120px',
    },
  },
}));
