import { useMemo, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { useQueueData } from 'remote-state/ticketServiceHooks';
import usePreviousValue from 'common/utils/hooks/usePreviousValue';
import { FEATURE_FLAGS_KEYS } from 'constants/featureFlags';
import { useFeatureFlagQuery } from 'remote-state/featureFlagsHooks';
import { selectSearchSrParams, setSearchSrParams } from '../../slice';
import { getLastRowIndex, getQueryKeyFromParams, prepareFilterDataForSearch } from '../helpers';
import { GRID_MODELS, GRID } from '../constants';
import { useGetSuggestedCategories } from './useGetSuggsetedCategories';
import useQueueWebSocketDataSync from './useQueueWebSocketDataSync';

export const useGridDataSource = ({ gridRef, gridMode, searchBeforeFetchCallback, searchAfterFetchCallback }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const searchSrParams = useSelector(selectSearchSrParams);
  const isAfterFirstRender = useRef();
  const initialDataFromServer = useRef();
  const newData = useRef();
  const loadingStuckPatchTimer = useRef();

  const { data: isWebsocketSyncFFOn } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.REAL_TIME_UPDATES,
    defaultValue: false,
  });

  useGetSuggestedCategories();

  const getRows = useCallback(
    (params) => {
      const converTedFilterModel = prepareFilterDataForSearch(
        gridRef?.current?.api?.getFilterModel(),
        params.context?.queueSearchText,
      );
      dispatch(
        setSearchSrParams({
          ...params,
          filterModel: converTedFilterModel,
          columnsOrder: params.context?.columnsOrder,
        }),
      );
    },
    [gridRef, dispatch],
  );

  const gridDataSource = useMemo(
    () => ({
      rowCount: undefined,
      /* Loads a single cache block: startRow - endRow */
      getRows,
    }),
    [getRows],
  );

  const queryKey = useMemo(() => getQueryKeyFromParams(searchSrParams), [searchSrParams]);
  const prevQueryKey = usePreviousValue(queryKey);
  const { data: queueData, isInitialLoading, status, isFetching, isFetched, isStale } = useQueueData();

  useEffect(() => {
    if (!isFetched) {
      gridRef?.current?.api?.showLoadingOverlay();
    } else {
      gridRef?.current?.api?.hideOverlay();
    }
  }, [isFetched, gridRef]);

  if (searchSrParams && queryKey && !isEqual(prevQueryKey, queryKey) && gridMode !== GRID_MODELS.Zen) {
    const dataFromCache = queryClient.getQueryData(queryKey);
    // if we are about to fetch new data - we want to invoke searchBeforeFetchCallback. if data is from cache - we don't want to cancel requests or timers for cache refreshing
    if (!dataFromCache) {
      searchBeforeFetchCallback();
    }
  }
  const { isConnectionFailed } = useQueueWebSocketDataSync({
    gridMode,
    queryKey,
    isEnabled: isWebsocketSyncFFOn,
  });

  if (isInitialLoading) {
    initialDataFromServer.current = true;
  }

  const isGridEmptyState = searchSrParams?.startRow === 0 && queueData?.count === 0;

  const prevIsFetching = usePreviousValue(isFetching);
  // const prevQueueData = usePreviousValue(queueData);

  // We will change auto refresh interval of search request to 10 minutes if FF turned on and websocket work properly
  // Either way we will just return usual 10 second interval.
  const refetchIntervalMillis = useMemo(() => {
    if (isWebsocketSyncFFOn) {
      return isConnectionFailed ? GRID.REFETCH_INTERVAL_MILLIS : GRID.REFETCH_TIMEOUT_MILLIS_WITHIN_WEBSOCKET;
    }
    return GRID.REFETCH_INTERVAL_MILLIS;
  }, [isConnectionFailed, isWebsocketSyncFFOn]);

  if (gridMode !== GRID_MODELS.Zen) {
    if (status === 'success') {
      if (queueData && !isEmpty(queueData)) {
        newData.current = false;
        const lastRow = getLastRowIndex(queueData.countTotal, searchSrParams.endRow);
        try {
          searchSrParams.successCallback(queueData.serviceRequests, lastRow);
          gridRef?.current?.api?.setRowCount(queueData.countTotal);
          // eslint-disable-next-line no-empty
        } catch (e) {}
      }
      if (!isAfterFirstRender.current && !initialDataFromServer.current && !isStale) {
        // we want to refetch fresh data from server immidiatly after first cached data display
        // if the query is stale it will refetch anyway
        initialDataFromServer.current = false;
        global.queueAbortController?.abort(); // cancel on going search requests
        queryClient.invalidateQueries({ queryKey: ['queueData'] });
      }
      isAfterFirstRender.current = true;
    }

    if (
      prevIsFetching !== isFetching &&
      typeof prevIsFetching !== 'undefined' &&
      !isFetching &&
      searchAfterFetchCallback // we want this to happen even if current results set is equal to previous and will not invoke successCallback again, so we ask if query is finishing fetching to begin the new timeout for refresh cycle
    ) {
      searchAfterFetchCallback();
    }
  }

  useEffect(
    () => () => {
      global.queueAbortController?.abort(); // cancel on going requests because grid is unmounted
      dispatch(setSearchSrParams(null));
      clearTimeout(loadingStuckPatchTimer.current);
      delete global.queueAbortController;
    },
    [queryClient, dispatch],
  );

  return { gridDataSource, isGridEmptyState, refetchIntervalMillis };
};
