import { useRouter } from '@tanstack/react-router';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Tab } from '@mui/material';
import { useDispatch } from 'react-redux';
import { HeaderTitle, NextOrPrev, StyledSettingsTabs, StyledTabsHeader } from '../styles';
import useTexts from '../useTexts';
import { ReactComponent as PrevIcon } from '../../../images/icons/prev.svg';
import { ReactComponent as NextIcon } from '../../../images/icons/next.svg';
import { useApplicationData } from '../../../remote-state/applicationHooks';
import { QUERIES_KEYS } from '../../../constant';
import { SETTINGS_MENU_ITEM_ID, TEMPLATES_SUB_MENU_ITEM_ID } from '../../settings/constants';
import { TAB_MARGIN_PX, WINDOW_MARGIN_PX } from '../constants';
import { ROUTES_PATHS } from '../../../constants';

export const getRelatedTabs = (settingElements, menuItemId, subMenuListId) =>
  settingElements
    ?.find((menuItem) => menuItem.id === menuItemId)
    ?.subMenuList.find((subMenuListItem) => subMenuListItem.id === subMenuListId).subMenuList;

export const getHeaderRbToken = (settingElements, menuItemId, subMenuListId) =>
  settingElements
    ?.find((menuItem) => menuItem.id === menuItemId)
    ?.subMenuList.find((subMenuListItem) => subMenuListItem.id === subMenuListId).rbToken;

export default function TabsHeader({ settingIds }) {
  const dispatch = useDispatch();
  const router = useRouter();
  const texts = useTexts();
  const { data: navElements } = useApplicationData(QUERIES_KEYS.MENU_ITEMS);

  const settingElements = useMemo(
    () =>
      navElements
        ? Object.values(navElements).flatMap((menu) => menu.filter((item) => item.id === SETTINGS_MENU_ITEM_ID))?.[0]
            ?.subMenuList
        : [],
    [navElements],
  );

  const relatedTabs = useMemo(
    () => getRelatedTabs(settingElements, settingIds?.menuItemId, settingIds?.subMenuListId, settingIds?.subMenuItemId),
    [settingElements, settingIds],
  );

  const headerRbToken = getHeaderRbToken(
    settingElements,
    settingIds?.menuItemId,
    settingIds?.subMenuListId,
    settingIds?.subMenuItemId,
  );

  const [selectedTabIndex, setSelectedTabIndex] = useState(
    relatedTabs?.findIndex((subMenuItem) => subMenuItem.id === settingIds?.subMenuItemId),
  );

  const [currentPage, setCurrentPage] = useState(0);
  const [isPrevEnable, setIsPrevEnable] = useState(true);
  const [isNextEnable, setIsNextEnable] = useState(true);
  const [visibleTabs, setVisibleTabs] = useState(relatedTabs);
  const [initialTabsLengthsArray, setInitialTabsLengthsArray] = useState([]);
  const [isFirstRender, setIsFirstRender] = useState(false);
  const tabWrapperRef = useRef(null);

  const handleClickTab = (currentSelectedTab) => {
    const setting = {
      menuItemId: settingIds?.menuItemId,
      subMenuListId: settingIds?.subMenuListId,
      subMenuItemId: currentSelectedTab.id,
    };
    const isMovingToTemplatePage = currentSelectedTab.id === TEMPLATES_SUB_MENU_ITEM_ID;
    if (isMovingToTemplatePage) {
      const search = {
        settingIds: setting
      };
      return router.navigate({ to: ROUTES_PATHS.TEMPLATE_LIST_URL, search });
    }
    const urlToResource = `${currentSelectedTab.url}${
      currentSelectedTab.url.includes('?') ? '&' : '?'
    }settingIds=${JSON.stringify(setting)}&openedInIframe=1&showBack=1`;
    router.navigate({ to: ROUTES_PATHS.EXTERNAl_PAGES_URL, search: { url: urlToResource } });
  };

  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
    setSelectedTabIndex(selectedTabIndex - 1);
  };

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
    setSelectedTabIndex(selectedTabIndex + 1);
  };

  const generateTabClassName = (tabIndex) => {
    let className = '';
    if (tabIndex === selectedTabIndex) {
      className += 'selected-tab';
    }
    return className;
  };

  const updateVisibleTabs = useCallback(() => {
    const windowWidth =
      window.innerWidth -
      document.getElementById('nextOrPrev')?.offsetWidth -
      document.getElementById('headerTitle')?.offsetWidth -
      WINDOW_MARGIN_PX;

    let totalWidth = 0;
    const currentTabs = [];
    let i = currentPage;

    while (i < relatedTabs?.length && totalWidth + initialTabsLengthsArray[i] <= windowWidth) {
      currentTabs.push(relatedTabs[i]);
      totalWidth += initialTabsLengthsArray[i];
      i++;
    }
    if (isFirstRender && selectedTabIndex >= currentTabs.length && relatedTabs?.length) {
      let preWidth = 0;
      let lastWidth = 0;
      while (i <= selectedTabIndex) {
        lastWidth += initialTabsLengthsArray[i];
        i++;
      }
      i = currentPage;
      while (preWidth <= lastWidth) {
        preWidth += initialTabsLengthsArray[i];
        i++;
      }

      setIsFirstRender(false);
      setCurrentPage(i);
      setSelectedTabIndex(selectedTabIndex - i);
    }

    setVisibleTabs(currentTabs);

    if (
      relatedTabs?.length &&
      (currentTabs.length === relatedTabs?.length ||
        currentTabs[currentTabs.length - 1]?.id === relatedTabs[relatedTabs?.length - 1]?.id)
    ) {
      setIsNextEnable(false);
    } else {
      setIsNextEnable(true);
    }

    if (
      relatedTabs?.length &&
      (currentTabs.length === relatedTabs?.length || currentTabs[0]?.id === relatedTabs[0]?.id)
    ) {
      setIsPrevEnable(false);
    } else {
      setIsPrevEnable(true);
    }
  }, [currentPage, initialTabsLengthsArray, isFirstRender, relatedTabs, selectedTabIndex]);

  useEffect(() => {
    const tabsWrapper = tabWrapperRef.current.querySelector('.MuiTabs-flexContainer');
    const tabsLengthsArray = Array.from(tabsWrapper.children).map((tab) => tab.offsetWidth + TAB_MARGIN_PX);
    setInitialTabsLengthsArray(tabsLengthsArray);
    setIsFirstRender(true);
  }, []);

  useEffect(() => {
    const currentIndex = visibleTabs?.findIndex((subMenuItem) => subMenuItem.id === settingIds?.subMenuItemId);
    setSelectedTabIndex(currentIndex);
  }, [visibleTabs, settingIds?.subMenuItemId, dispatch]);

  useEffect(() => {
    // on first render and on click on prev/next
    if (initialTabsLengthsArray.length > 0) {
      updateVisibleTabs();
    }
  }, [initialTabsLengthsArray, currentPage, updateVisibleTabs]);

  useEffect(() => {
    const handleResize = () => {
      if (initialTabsLengthsArray.length > 0) {
        updateVisibleTabs();
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [initialTabsLengthsArray, updateVisibleTabs]);

  return (
    <StyledTabsHeader data-testid="tabs header">
      <Box id="tabs-listcontainer">
        <HeaderTitle id="headerTitle">{texts[headerRbToken] || ''}</HeaderTitle>
        <StyledSettingsTabs value={selectedTabIndex ?? false} ref={tabWrapperRef}>
          {visibleTabs?.map((tab, index) => (
            <Tab
              className={generateTabClassName(index)}
              key={index}
              label={texts[tab.rbToken] || ''}
              value={index}
              onFocus={() => handleClickTab(tab)}
              disabled={index === selectedTabIndex}
            />
          ))}
        </StyledSettingsTabs>
      </Box>
      <NextOrPrev data-testid="prev and next buttons">
        <div id="nextOrPrev" className="next-or-prev">
          {(isPrevEnable || isNextEnable) && (
            <>
              <button onClick={handlePrevClick} disabled={!isPrevEnable}>
                <PrevIcon />
              </button>
              <button onClick={handleNextClick} disabled={!isNextEnable}>
                <NextIcon />
              </button>
            </>
          )}
        </div>
      </NextOrPrev>
    </StyledTabsHeader>
  );
}
