import { styled } from '@mui/system';

export const StyledEndOfLoading = styled('div')({
  height: '50px',
  margin: 0,
  position: 'absolute',
  top: '74%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

export const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px 673px',
  gap: '5px',
});

export const StyledEndOfLoadingWithNoData = styled('div')({});

export const StyledContainerWithNoData = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '5px',
});

export const StyledTitle = styled('div')(({ theme }) => ({
  fontStyle: 'normal',
  fontSize: '20px',
  lineHeight: '24px',
  color: theme.palette.text.title_txt,
}));

export const StyledSecondaryTitle = styled('div')(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '24px',
  color: theme.palette.text.secondary_txt,
}));

export const StyledImg = styled('img')({});

export const StyledGridWithDataTitle = styled('div')(({ theme }) => ({
  fontSize: '18px',
  lineHeight: '24px',
  color: theme.palette.text.secondary_txt,
}));
