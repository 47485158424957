import { InlineFieldContainer } from 'common/components/controls/InlineField/InlineFieldContainer';
import { TitleField } from 'common/components/controls/TitleField/index';
import { useCallback, useEffect, useState } from 'react';
import { StyledTemplateName } from './StyledTemplateName';
import useTexts from './useTexts';

export default function TemplateName(props) {
  const { updateTemplateName, templateName } = props;
  const { titlePlaceholder } = useTexts();
  const [name, setName] = useState();

  useEffect(() => {
    setName(templateName);
  }, [templateName]);

  const handleSaveName = useCallback(
    (value) => {
      if (value.title !== name) {
        updateTemplateName([{ templateName: value.title }]);
      }
    },
    [updateTemplateName, name],
  );
  return (
    <StyledTemplateName>
      <InlineFieldContainer
        text={name}
        limit={250}
        fieldName="title"
        className="template-name"
        placeholder={titlePlaceholder}
        variant="templateDesignerTitle"
        handleSaveValue={handleSaveName}
        CustomFieldComponent={TitleField}
      />
    </StyledTemplateName>
  );
}
