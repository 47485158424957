import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Tooltip from 'common/components/tooltip';
import { selectFilterModel, selectIsQuickFilterApplied } from '../slice';
import { isIncludedInFilters } from './helpers/helpers';
import { StyledToggleButton, StyledToggleButtonGroup, StyledTicketNumber } from './style';

const getButtonProps = (index, quickFiltersLength) => {
  if (index === 0) {
    return {
      'data-testid': 'toggle-button-first',
      isFirstButton: true,
    };
  }
  if (index === quickFiltersLength - 1) {
    return {
      'data-testid': 'toggle-button-last',
    };
  }
  return {
    'data-testid': 'toggle-button-middle',
  };
};


export const ToggleButtonsWrapper = ({ quickFilters, handleTypeFilterClick, SrTypeFilterCounters }) => {
  const filterModelSelector = useSelector(selectFilterModel);
  const isQuickFilterApplied = useSelector(selectIsQuickFilterApplied);
  const [srCount, setSrCount] = useState(SrTypeFilterCounters);
  const srTypeActiveFilterKeys = useMemo(() => filterModelSelector?.find((filter) => filter?.field === 'srType')?.values,[filterModelSelector]);
  const isAllChipSelected = !isQuickFilterApplied;

  const isFilterSelected = ({ filter, displayName, index }) => {
    if (index === 0) return isAllChipSelected;
    return (
      isQuickFilterApplied &&
      isIncludedInFilters(displayName, filter.idForFilter, filterModelSelector) &&
      !(srTypeActiveFilterKeys?.length && srTypeActiveFilterKeys?.length === quickFilters?.length)
    );
  };

  useEffect(() => {
    if (SrTypeFilterCounters?.ALL !== undefined) {
      setSrCount(SrTypeFilterCounters);
    }
  }, [SrTypeFilterCounters]);

  return (
    <StyledToggleButtonGroup exclusive aria-label="Quick Filters" value="Quick Filter">
      {quickFilters?.map((filter, index) => {
        const displayName = filter?.valueToDisplay || filter?.value?.charAt(0).toUpperCase() + filter?.value?.slice(1);
        const selected = isFilterSelected({ filter, displayName, index });
        return (
          <StyledToggleButton
            value={filter}
            key={`${filter?.valueForFilter}_${index}`}
            aria-label={filter.valueForFilter}
            selected={selected}
            onClick={() => handleTypeFilterClick(filter)}
            {...getButtonProps(index, quickFilters.length)}
          >
            <Tooltip id={filter?.value} isTruncatedText title={displayName} text={displayName} />
            <StyledTicketNumber isSelected={selected}>
              <span className="text">{srCount[filter.value.toUpperCase()]}</span>
            </StyledTicketNumber>
          </StyledToggleButton>
        );
      })}
    </StyledToggleButtonGroup>
  );
};
