import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    getColumnText: (displayKey) => texts?.[displayKey],
    addColumn: texts?.['spaces.queue.columnconfiguration.addcolumn'],
    noResults: texts?.['spaces.queue.columnconfiguration.searchnoresult'],
    headTitle: texts?.['spaces.queue.columnconfiguration.title'],
    subtitle: texts?.['spaces.queue.columnconfiguration.subtitle'],
    noSearchResult: texts?.['spaces.select.info.noResults'],
    cancel: texts?.['spaces.prompt.cancelBtn'],
    apply: texts?.['spaces.prompt.apply'],
    errorText: texts?.['spaces.queue.columnconfiguration.errorText'],
    save: texts?.['spaces.header.queueViews.actions.save'],
    saveAs: texts?.['spaces.header.queueViews.actions.saveAs'],
    queueViewsMessageSaveSuccess: texts?.['spaces.header.queueViews.message.saveSuccess'],
  };
  return componentTexts;
}
