import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectSrRequiredFieldsList, selectSrIsClearRequiredField } from 'store/saveSrValidationSlice';
import MessagePrompt from 'common/components/messagePrompt';
import usePendoTrackEvents from 'common/utils/hooks/usePendoTrackEvents';
import { useRbTexts } from 'remote-state/applicationHooks';
import { SAVE_ERRORS } from 'features/queue/grid/constants';
import useTexts from './useTexts';
import { PENDO_TRACK_EVENTS } from '../../../constants';

export default function FieldsNotSavedPrompt({ saveError, onOkClick, onCancelClick }) {
  const {
    notSavedTitle,
    forbiddenErrorTitle,
    notSavedModalMessage,
    notSavedByPermissionMessage,
    notSavedByForbiddenMessage,
    notSavedByRequiredFieldMessage,
    notSavedByRequiredFieldTitle,
    forbiddenSaveOkBtnText,
  } = useTexts();

  const requiredFieldsList = useSelector(selectSrRequiredFieldsList);
  const isClearRequiredField = useSelector(selectSrIsClearRequiredField);
  const texts = useRbTexts();
  const pendoTrackEvents = usePendoTrackEvents();

  const fieldsToTrack = useMemo(
    () => requiredFieldsList?.map((field) => texts[field])?.toString(),
    [requiredFieldsList, texts],
  );

  let title = notSavedTitle;
  let message = notSavedModalMessage;
  let btnOkText;
  let showCancelBtn = false;

  const getStatusErrorMessage = () => {
    let fieldsList = '';
    if (requiredFieldsList?.length) {
      const onlyOneField = requiredFieldsList.length === 1;
      const lastIndex = requiredFieldsList.length - 1;

      const getFieldElement = (field, index) => {
        if (onlyOneField || index === lastIndex) {
          return <span>{texts[field]}</span>;
        }
        return <span>{texts[field]}, </span>;
      };

      fieldsList = requiredFieldsList.map((field, index) => <span key={field}>{getFieldElement(field, index)}</span>);
    }
    if (isClearRequiredField) {
      return (
        <>
          {notSavedByRequiredFieldMessage}
        </>
      );
    }
    return (
      <>
        {`${notSavedByForbiddenMessage} - `}
        {fieldsList}
      </>
    );
  };

  function getStatusErrorTitle() {
    return isClearRequiredField ? notSavedByRequiredFieldTitle : forbiddenErrorTitle;
  }

  switch (saveError) {
    case SAVE_ERRORS.isSaveFieldsPermissionError:
      message = notSavedByPermissionMessage;
      break;
    case SAVE_ERRORS.isSaveStatusError:
      title = getStatusErrorTitle();
      message = getStatusErrorMessage();
      btnOkText = forbiddenSaveOkBtnText;
      showCancelBtn = true;
      break;
    default:
      break;
  }

  pendoTrackEvents(PENDO_TRACK_EVENTS.TICKET_MISSING_REQUIRED_FIELDS, { fields: fieldsToTrack });

  return (
    <MessagePrompt
      open
      title={title}
      btnOkText={btnOkText}
      onOkClick={onOkClick}
      showCancelBtn={showCancelBtn}
      onClose={onCancelClick}
    >
      {message}
    </MessagePrompt>
  );
}
