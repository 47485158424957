import { editorActionTypes } from 'features/resolutionPanel/middlePanel/auditLog/constants/editorActionTypes';
import { QUERIES_KEYS } from 'constant';
import { SwitchToggle } from 'common/components/controls/Switch/SwitchToggle';
import { useUserInfo } from 'remote-state/userServiceHooks';
import useTexts from 'features/resolutionPanel/useTexts';
import { useUpdateDefaultSharingNoteQuery } from 'remote-state/noteHooks';
import DefaultChecker from './DefaultChecker';
import { StyledShareSwitch } from './Styled';

export const ShareSwitch = (props) => {
  const {
    isNewEditor,
    editorData,
    lastSavedData,
    editorType,
    handleEditorChange,
    className = '',
    editorClassName = 'editor-element',
    dataTestId = '',
    keyChanged = 'shareWithReqUser',
  } = props;

  const { shareWithRequestUser, unshareTooltipText, shareWithReqUserTitle } = useTexts(editorType);
  const {
    data: { userConf },
  } = useUserInfo(QUERIES_KEYS.CURRENT_USER);
  const { mutateAsync: updateDefaultShareWithReqUser } = useUpdateDefaultSharingNoteQuery();
  const isDisableUnshareEditMode = !isNewEditor && lastSavedData?.shareWithReqUser;

  const handleChange = (checked) => {
    handleEditorChange(keyChanged, { shareWithReqUser: checked });
  };

  const handleUpdateDefaultSharing = async (shouldShareWithRequestUser) => {
    updateDefaultShareWithReqUser(shouldShareWithRequestUser);
    if (isNewEditor) {
      handleChange(shouldShareWithRequestUser);
    }
  };

  return (
    <StyledShareSwitch className={`${editorClassName} ${className}`}>
      <SwitchToggle
        handleSwitchChange={({ target }) => handleChange(target.checked)}
        switchText={shareWithRequestUser}
        tooltipText={isDisableUnshareEditMode && unshareTooltipText()}
        isDisabled={isDisableUnshareEditMode}
        isChecked={editorData?.shareWithReqUser}
        editorClassName={editorClassName}
        dataTestId={dataTestId}
      />
      {editorType === editorActionTypes.NOTE && (
        <DefaultChecker
          checked={userConf?.shareNoteByDefault}
          onChange={handleUpdateDefaultSharing}
          title={shareWithReqUserTitle}
          editorClassName={editorClassName}
        />
      )}
    </StyledShareSwitch>
  );
};
