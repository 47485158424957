import { forwardRef, useCallback } from 'react';
import { ClickAwayListener, MenuList } from '@mui/material';
import { useFeatureFlagQuery } from 'remote-state/featureFlagsHooks';
import { FEATURE_FLAGS_KEYS } from 'constants/featureFlags';
import useTexts from 'features/navBar/useTexts';
import navIcons from 'features/navBar/navIcons';
import StyledNavSubList from './style';
import NavSubListMenu from './navSubListMenu';
import NavSubListLink from './navSubListLink';
import DocumentationNavLink from './documentationNavLink';
import { DOCUMENTATION_RB_TOKEN } from './documentationNavLink/constants';
import NavSubListToggle from './navSubListToggle';
import { PENDO_TRACK_EVENTS } from '../../../../../constants';
import usePendoTrackEvents from '../../../../../common/utils/hooks/usePendoTrackEvents';

const RESOURCE_CENTER_MENU_ITEM_ID = 189;
const DEFAULT_SUB_LIST_ITEM = {
  NavSubListItem: undefined
};

const getNavListChildComponent = (item) =>
  ({
    menu: {
      NavSubListItem: item.subMenuList.length === 0 ? null : NavSubListMenu,
    },
    link: {
      NavSubListItem: item.rbToken === DOCUMENTATION_RB_TOKEN ? DocumentationNavLink : NavSubListLink,
    },
    toggle: {
      NavSubListItem: NavSubListToggle
    }
  }[item.type]) || DEFAULT_SUB_LIST_ITEM;

/*
* Make pendo resource center button visible
*/
const togglePendoResourceCenterBadge = () => {
  const pendo = window.pendo;

  pendo.pro?.toggleRcBadge();
  pendo?.onGuideDismissed?.();
};

const NavSubList = forwardRef(({ expanded, title, sublist, closeMenu }, ref) => {

  const { getNavItemTitle } = useTexts();
  const pendoTrackEvents = usePendoTrackEvents();
  const { data: showResourceCenterMenuItem } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.SHOW_RESOURCE_CENTER_MENU_ITEM,
    defaultValue: false,
  });

  const handleClick = useCallback((itemId) => {
    if (itemId === RESOURCE_CENTER_MENU_ITEM_ID) {
      togglePendoResourceCenterBadge();
      pendoTrackEvents(PENDO_TRACK_EVENTS.RESOURCE_CENTER_MENU_CLICK);
    }
  }, [pendoTrackEvents]);

  const getColumnCount = () => {
    const MAX_COLUMN_ITEMS = 16;
    const itemCount = sublist.flatMap((section) => section.subMenuList).length + sublist.length;
    return Math.ceil(itemCount / MAX_COLUMN_ITEMS);
  };

  return (
    <StyledNavSubList expanded={expanded} columnCount={getColumnCount()} className={`${title}-menu`} ref={ref}>
      <ClickAwayListener onClickAway={closeMenu}>
        <MenuList className="submenu">
          {sublist.map((item) => {
            const icon = navIcons[item.id];
            const title = getNavItemTitle(item.rbToken);
            const { NavSubListItem } = getNavListChildComponent(item);

            if (item.id === RESOURCE_CENTER_MENU_ITEM_ID && !showResourceCenterMenuItem) {
              return null; // do not render 'Resource Center' until it has an implementation
            }
            
            return (
              NavSubListItem && (
                <NavSubListItem
                  icon={icon}
                  title={title}
                  url={item.url}
                  menu={item}
                  closeMenu={closeMenu}
                  isOpenInNewTab={item.isOpenInNewTab}
                  itemId={item.id}
                  onClick={handleClick}
                />
              )
            );
          })}
        </MenuList>
      </ClickAwayListener>
    </StyledNavSubList>
  );
});

export default NavSubList;
