import { Grid, Divider } from '@mui/material';

// DO NOT DELETE - We will be adding the rich text option in the future
// import RichTextEditor from 'common/components/controls/RichTextEditor';
// import { RICH_TEXT_EDITOR_TYPES } from 'common/components/controls/RichTextEditor/constants';
// import RichTextEditor from 'common/components/controls/RichTextEditor/RichTextEditor';
import { SwitchToggle } from 'common/components/controls/Switch/SwitchToggle';
import Assignee from 'common/components/assignee';
import { useAssigneesList } from 'remote-state/ticketServiceHooks';

import useTexts from '../../useTexts';

import MeMenuContentSection from './meMenuContentSection';
import MeMenuFormField from './meMenuFormField';
import TextField from './meMenuFormField/TextField';
import { StyledOutOfOffice } from './styles';

export default function ProfileTabContent({
  settings: {
    updatedSettings: {
      firstName = '',
      lastName = '',
      displayName = '',
      emailAddress = '',
      secondaryEmail = '',
      phone = '',
      mobile = '',
      signature = '',
      outOfOffice = false,
      takeOverUser = null,
    },
  },
  handleChange = () => {},
}) {
  const {
    admins: { data: admins },
  } = useAssigneesList();
  const {
    myInformationHeaderText,
    firstNameLabelText,
    lastNameLabelText,
    displayNameLabelText,
    emailLabelText,
    secondaryEmailLabelText,
    phoneLabelText,
    mobileLabelText,
    mobileHintText,
    outgoingEmailHeaderText,
    signatureLabelText,
    signaturePlaceholderText,
    signatureHintText,
    outOfOfficeHeaderText,
    outOfOfficeLabelText,
    assignOutOfOfficeText,
  } = useTexts();

  const handleChangeReAssign = (admin) => {
    handleChange(admin?.userName && admin.userName !== 'none' ? admin.userName : null, 'takeOverUser');
  };

  return (
    <>
      <MeMenuContentSection name="profile" header={myInformationHeaderText}>
        <Grid container rowGap={1.25}>
          <Grid container rowGap={1.25} columnGap={3}>
            <Grid item>
              <MeMenuFormField
                label={firstNameLabelText}
                name="firstName"
                inputComponent={TextField}
                inputProps={{ value: firstName, handleChange }}
              />
            </Grid>
            <Grid item>
              <MeMenuFormField
                label={lastNameLabelText}
                name="lastName"
                inputComponent={TextField}
                inputProps={{ value: lastName, handleChange }}
              />
            </Grid>
            <Grid item>
              <MeMenuFormField
                label={displayNameLabelText}
                name="displayName"
                inputComponent={TextField}
                inputProps={{ value: displayName, handleChange }}
              />
            </Grid>
          </Grid>
          <Grid container rowGap={1.25} columnGap={3}>
            <Grid item>
              <MeMenuFormField
                label={emailLabelText}
                name="emailAddress"
                type="email"
                inputComponent={TextField}
                inputProps={{ value: emailAddress, handleChange }}
              />
            </Grid>
            <Grid item>
              <MeMenuFormField
                label={secondaryEmailLabelText}
                name="secondaryEmail"
                type="email"
                inputComponent={TextField}
                inputProps={{ value: secondaryEmail, handleChange }}
              />
            </Grid>
          </Grid>
          <Grid container rowGap={1.25} columnGap={3}>
            <Grid item>
              <MeMenuFormField
                label={phoneLabelText}
                name="phone"
                type="tel"
                inputComponent={TextField}
                inputProps={{ value: phone, handleChange }}
              />
            </Grid>
            <Grid item>
              <MeMenuFormField
                label={mobileLabelText}
                name="mobile"
                type="tel"
                labelProps={{
                  hintText: <div style={{ textAlign: 'start' }} dangerouslySetInnerHTML={{ __html: mobileHintText }} />,
                }}
                inputComponent={TextField}
                inputProps={{ value: mobile, handleChange }}
              />
            </Grid>
          </Grid>
        </Grid>
      </MeMenuContentSection>
      <Divider />
      <MeMenuContentSection header={outgoingEmailHeaderText}>
        <MeMenuFormField
          label={signatureLabelText}
          name="signature"
          labelProps={{ hintText: signatureHintText }}
          inputComponent={TextField}
          inputProps={{
            value: signature,
            handleChange,
            multiline: true,
            maxRows: 3,
            placeholder: signaturePlaceholderText,
            InputProps: { fullWidth: true },
            sx: { width: '100%' },
            className: 'signature-wrapper',
          }}
          // DO NOT DELETE - We will be adding the rich text option in the future
          // inputComponent={RichTextEditor}
          // inputProps={{
          //   type: RICH_TEXT_EDITOR_TYPES.SIGNATURE,
          //   editorText: signature,
          //   placeholder: 'Signature',
          //   onChange: handleChange,
          //   isExpanded: true,
          //   onImageUpload: () => {},
          //   mentionsEnabled: false,
          // }}
        />
      </MeMenuContentSection>
      <Divider />
      <MeMenuContentSection header={outOfOfficeHeaderText}>
        <StyledOutOfOffice>
          <SwitchToggle
            isChecked={outOfOffice}
            handleSwitchChange={(e) => handleChange(e.target.checked, 'outOfOffice')}
            switchText={outOfOfficeLabelText}
          />
          ,
          <div className="assign-wrapper">
            {assignOutOfOfficeText}
            <Assignee
              filteredAssignees={[]}
              filteredGroups={[]}
              handleChangeAssignees={handleChangeReAssign}
              isMultiSelect={false}
              disablePopover={false}
              adminList={admins}
              groupList={[]}
              currentAssigned={{ admin: { calculatedUserName: takeOverUser } }}
            />
          </div>
        </StyledOutOfOffice>
      </MeMenuContentSection>
    </>
  );
}
