import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

export const StyledMainItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  paddingInline: '14px',

  '&:hover': {
    background: theme.palette.background.selected,
  },
}));
export const useStyles = makeStyles(
  () => ({
    root: {
      position: 'absolute',
      left: 0,
      top: 0,

      zIndex: 30,
      width: '222px',
      background: 'white',
      borderRadius: '6px',
      boxShadow: '0px 2px 8px rgba(118, 130, 150, 0.35)',
    },

    header: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',

      paddingInline: '16px',
    },

    subfiltersWrapper: {
      display: 'flex',
    },
  }),
  { name: 'filterDropdown' },
);
