import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    selectCategoryText: texts?.['spaces.header.category.selectCategory'],
    selectSubCategoryText: texts?.['spaces.header.category.selectSubCategory'],
    select3rdLevelCategoryText: texts?.['spaces.header.category.select3rdLevelCategory'],
    categoryNoValue: texts?.['spaces.categories.noValue'],
    mandatoryFieldError: texts?.['spaces.field.tooltip.error.mandatoryFieldError'] || 'Missing required field',
  };

  return componentTexts;
}
