import { styled } from '@mui/material/styles';
// import { Alert, AlertTitle } from '@mui/material';

export const StyleStatusMessage = styled('div')(({ theme }) => ({
  '& .MuiPaper-root': {
    maxwidth: '400px',
    top: '-39vh',
    height: '60px',
  },

  '& .MuiAlert-root': {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'inline-flex',
    padding: '12px',
    gap: '12px',
    borderRadius: theme.shape.border.borderRadius,
    boxShadow: '0px 4px 12px 0px rgba(118, 130, 150, 0.25)',
    background: theme.palette.labels.bt_orange_light,
  },

  '& .MuiAlert-icon': {
    padding: 0,
    marginRight: 0,
  },

  '& .MuiTypography-root': {
    color: theme.palette.text.title_txt,
    fontFamily: theme.fonts.robotoRegular,
    fontSize: '16px',
    fontStyle: 'normal',
  },
  '& .MuiAlertTitle-root': {
    marginBottom: '0px',
    marginTop: '2px',
  },
}));
