import { Box, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledAutoPopulateTicket = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#FFFFFF',
  height: '100%',
}));

export const StyledTitle = styled(Box)(({ theme }) => ({
  fontSize: '18px',
  color: theme?.palette?.text?.title_txt,
  lineHeight: '24px',
  marginBottom: '24px',
}));

export const StyledFooter = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

export const StyledNoDescriptionAlert = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  color: theme?.palette?.labels?.bt_orange_hover,
  fontFamily: theme?.fonts?.robotoRegular,
  lineHeight: '20px',
  gap: '3px',
  fontSize: '12px',
}));

export const StyledWarningIcon = styled(Box)(() => ({
  marginTop: '2px',
  minWidth: '14px',
  maxWidth: '14px',
}));

export const StyledButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  justifyContent: 'flex-end',
  flex: 1,
  height: '32px',

  '.btn': {
    fontFamily: theme?.fonts?.robotoRegular,
    fontSize: '1rem',
    lineHeight: '16px',
    flexGrow: '0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '4px',
    padding: '8px 16px',
    borderRadius: '60px',
    border: 'none',
    cursor: 'pointer',
    transition: '0.2s ease',
  },

  '.cancel': {
    color: theme?.palette?.icons?.bt_def_grey_reg,
    backgroundColor: theme?.palette?.grey?.bt_alt_grey_reg,
    ':hover': {
      color: theme?.palette?.text?.title_txt,
      backgroundColor: theme?.palette?.grey?.bt_def_grey_light,
    },
  },

  '.save': {
    display: 'flex',
    flexDirection: 'row',
    color: '#FFFFFF',
    backgroundColor: theme?.palette?.grey?.bt_def_grey_reg,
    ':hover': {
      background: theme?.palette?.grey?.bt_def_grey_hover,
    },
    ':disabled': {
      cursor: 'default',
      backgroundColor: theme?.palette?.grey?.bt_def_grey_light,
    },
  },
}));

const handleTextBoxHeight = (isPanelExpanded) => {
  if (isPanelExpanded) {
    return '100% !important';
  }
  return '38.813vh !important';
};

export const StyledTextBox = styled(TextField, {
  shouldForwardProp: (prop) => !['isDirty', 'isPanelExpanded'].includes(prop),
})(({ theme, isDirty, isPanelExpanded }) => ({
  marginBottom: '18px',
  background: isDirty ? 'none' : theme?.palette?.background?.bg_new_input_text,
  borderRadius: '4px',
  height: handleTextBoxHeight(isPanelExpanded),
  flex: 2,
  '.MuiInputBase-root': {
    fontFamily: theme?.fonts?.robotoRegular,
    fontSize: '14px',
    color: theme?.palette?.text?.txt_default,
    lineHeight: '20px',
    padding: '4px 8px',
    height: '100%',
    '&.MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '4px',
        border: isDirty ? theme?.shape?.border?.border_2 : 'none',
      },
      '&.Mui-focused': {
        backgroundColor: theme?.palette?.generic?.primary,
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #ccc',
      },
    },
  },
  '.MuiFormHelperText-root': {
    position: 'absolute',
    bottom: 0,
    right: '8px',
    fontSize: '12px',
    margin: '0 0 4px 0',
    padding: '4px',
    lineHeight: '10px',
    display: 'inline-flex',
    color: theme?.palette?.text?.secondary_txt,
    fontFamily: theme?.fonts?.robotoRegular,
    alignSelf: 'end',
    '&:placeholder': {
      color: theme?.palette?.text?.secondary_txt,
      fontSize: '10px',
    },
  },
  textarea: {
    height: '100% !important',
    overflow: 'auto !important',
    '&::placeholder': {
      fontFamily: theme?.fonts?.robotoRegular,
      color: theme?.palette?.text?.disable_txt_V2,
      opacity: 0.9,
      fontSize: '14px',
      lineHeight: '20px',
    },
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme?.palette?.grey.divider_grey_v1,
      borderRadius: '4px',
    },
  },
}));
