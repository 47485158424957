import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTexts } from '../../dropdownSelect/hooks';
import { StyledMenuItem, StyledSelect } from '../../dropdownSelect/CustomDate/CustomDateSelect/CustomDateSelect.styles';

export const IdSelect = ({ handleSelectChange, filterType }) => {
  const { equalsTo, lessThan = 'Less than', greaterThan = 'Greater than' } = useTexts();

  const handleChange = (event) => {
    handleSelectChange(event.target.value);
  };

  return (
    <StyledSelect
      MenuProps={{
        disablePortal: true,
      }}
      id="custom-date-select"
      value={filterType}
      onChange={handleChange}
      fullWidth
      IconComponent={KeyboardArrowDownIcon}
    >
      <StyledMenuItem value="equals">{equalsTo}</StyledMenuItem>
      <StyledMenuItem value="lessThan">{lessThan}</StyledMenuItem>
      <StyledMenuItem value="greaterThan">{greaterThan}</StyledMenuItem>
    </StyledSelect>
  );
};
