import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',

      height: '100%',
      paddingLeft: '34px',
      borderBottom: `1px solid ${theme.palette.grey.bt_alt_grey_hover}`,
    },
    filterItemWrapper: {
      position: 'relative',

      background: 'white',
      padding: '12px 40px',

      '&:hover': {
        background: theme.palette.background.selected,
      },
    },

    filterItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',

      height: '100%',
    },
  }),
  { name: 'headerSelector' },
);

export const StyledFilterItem = styled('div')(({ theme }) => ({
  position: 'relative',

  background: 'white',
  padding: '12px 40px',
  borderRadius: '4px 4px 0px 0px',

  '&:hover': {
    background: theme.palette.background.selected,
  },
}));
