import { useMutation } from '@tanstack/react-query';
import { updateDefaultShareWithReqUser } from 'services/noteService';
import { QUERIES_KEYS } from 'constant';
import { useUserInfo } from './userServiceHooks';

export const useUpdateDefaultSharingNoteQuery = () => {
  const { invalidUserInfo } = useUserInfo(QUERIES_KEYS.CURRENT_USER);

  return {
    ...useMutation({
      mutationFn: updateDefaultShareWithReqUser,
      onSuccess: async () => {
        invalidUserInfo();
      },
    }),
  };
};
