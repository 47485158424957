const changeDirection = (editor, dir, align) => {
  editor.selection.save();
  editor.html.wrap(true, true, true, true);
  editor.selection.restore();

  const elements = editor.selection.blocks();

  editor.selection.save();

  elements.forEach((element) => {
    if (element !== editor.el) {
      editor.$(element).css('directionTool', dir).css('text-align', align).removeClass('fr-temp-div');
    }
  });

  editor.html.unwrap();
  editor.selection.restore();
  editor.directionTool.hideDirectionToolPopup();
};

export { changeDirection };
