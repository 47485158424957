import { styled } from '@mui/material/styles';

export const StyledTruncatedTextButton = styled('button')(({ theme, isRichTextTruncated }) => ({
  display: 'flex',
  alignItems: 'center',
  columnGap: '4px',
  border: 'none',
  cursor: 'pointer !important',
  background: 'none',
  alignSelf: 'flex-start',
  textAlign: 'start',
  height: '20px',
  width: 'max-content',
  padding: '0',
  marginTop: '4px',

  '& .text': {
    fontFamily: theme.fonts.robotoRegular,
    fontSize: theme.fontSize.main,
    color: theme.palette.grey.bt_def_grey_reg,
    lineHeight: '20px',
  },
  '& svg': {
    transition: 'all 100ms ease-out',
    transform: `rotate(${isRichTextTruncated ? 0 : 180}deg)`,
    '& path': {
      fill: theme.palette.grey.bt_def_grey_reg,
    },
  },
  '&:hover': {
    textDecoration: `underline solid ${theme.palette.grey.bt_def_grey_reg}`,
  },
}));
