import { Children, cloneElement, isValidElement, useCallback, useEffect, useRef, useState } from 'react';
import { useRouter } from '@tanstack/react-router';
import { useDispatch, useSelector } from 'react-redux';
import { clearPageValidation, selectPageValidations } from 'store/pageValidationSlice';
import { PAGE_VALIDATION_CONSTANTS } from 'constants/index';
import MessagePrompt from 'common/components/messagePrompt';
import { ReactComponent as WarningIcon } from 'images/icons/WarningSign.svg';
import useTexts from './useTexts';

export default function ExitPrompt({ children }) {
  const dispatch = useDispatch();
  const router = useRouter();
  const [prompt, setPrompt] = useState({});
  const { titleText, descriptionText, btnOkText, btnCancelText } = useTexts();
  const blockerRef = useRef({
    isActive: false,
  });

  const pageValidations = useSelector(selectPageValidations);
  const isPageValid = pageValidations?.isValid ?? true;
  const data = Object.values(pageValidations?.components || {}).filter(
    (component) => !component.isValid,
  )[0];
  const isDescriptionShouldExist = pageValidations?.components?.[PAGE_VALIDATION_CONSTANTS.TICKET_PANEL]?.isDescriptionShouldExist;
  const isIconShouldExist = pageValidations?.components?.[PAGE_VALIDATION_CONSTANTS.TICKET_PANEL]?.isIconShouldExist;
  
  blockerRef.current.promptTexts = data?.promptTexts;

  const closePrompt = () => {
    setPrompt((prev) => ({ ...prev, toShow: false }));
  };

  const handleOkClick = () => {
    if (window.setAbort) window.setAbort();
    if (window.closeMeMenu) window.closeMeMenu();
    dispatch(clearPageValidation());
    closePrompt();
    blockerRef.current.isActive = false;
    blockerRef.current.exit();
    router.history.go(-1);
  };

  const handleCancelClick = () => {
    closePrompt();
  };

  const showPromptOnExit = useCallback(() => {
    blockerRef.current.isActive = true;
    blockerRef.current.unblock = router.history.block(() => {
      blockerRef.current.exit = () => blockerRef.current.unblock();
      if (blockerRef.current.isActive) {
        setPrompt({
          toShow: true,
          texts: blockerRef.current.promptTexts,
        });
      } else {
        router.history.unblock();
      }
    });
  }, [router]);

  useEffect(() => {
    if (blockerRef.current.isActive) {
      if (!isPageValid) return;
      blockerRef.current.unblock();
      blockerRef.current.isActive = false;
    }

    if (!isPageValid) {
      showPromptOnExit();
    }
  }, [isPageValid, showPromptOnExit]);

  const childrenWithProps = Children.map(children, (child) => {
    if (!isValidElement(child)) return child;

    return cloneElement(child, { showPromptOnExit });
  });

  return (
    <>
      {childrenWithProps}
      {prompt.toShow && (
        <MessagePrompt
          open
          title={prompt.texts?.title || titleText}
          Icon={prompt.texts?.PromptIcon || WarningIcon}
          btnOkText={prompt.texts?.btnOkText || btnOkText}
          btnCancelText={prompt.texts?.btnCancelText || btnCancelText}
          onOkClick={handleOkClick}
          showCancelBtn
          showIcon={isIconShouldExist}
          onClose={handleCancelClick}
        >
          {isDescriptionShouldExist && (prompt.texts?.description || descriptionText)}
        </MessagePrompt>
      )}
    </>
  );
}
