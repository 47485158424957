import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledLogMetaData = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'isRichTextTruncated' && prop !== 'isRichTextTruncatedSecond' && prop !== 'srPanelCollapsed',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  fontSize: '12px',
  alignSelf: 'flex-start',
  gridColumn: 3,
  width: '180px',
  maxWidth: '180px',
  minWidth: '180px',
  padding: '6px 0px 2px',
  marginBottom: 'auto',

  '& span': {
    padding: '2px 8px',

    '&:nth-of-type(even)': {
      marginTop: '1px',
    },
  },
  '.dot': {
    height: '4px',
    width: '4px',
    backgroundColor: theme.palette.grey.divider_grey_v1,
    borderRadius: '50%',
    display: 'none',
    marginLeft: '7px',
    marginRight: '7px',
  },
  '.username': {
    color: theme.palette.text.txt_default,
  },
  '.date': {
    lineHeight: '16px',
    padding: '2px 8px',

    '& .is-edited': {
      color: '#ABAEB2',
      padding: '0',
      lineHeight: '16px',
      marginRight: '8px',
    },
  },

  '.bird-wrapper': {
    display: 'flex',
    columnGap: '6px',
    color: theme.palette.text.txt_default,
    fontWeight: 400,
  },
  '.bird-title': {
    height: '100%',
    fontSize: theme.fontSize.small,
    display: 'flex',
    alignItems: 'flex-end',
    padding: 0,
    lineHeight: '16px',
  },
  '.bird-icon': {
    width: '16px',
    height: '16px',
  },
}));
