import { forwardRef, memo } from 'react';
import { Box } from '@mui/material';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import CloseIcon from '@mui/icons-material/Close';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import usePendoTrackEvents from 'common/utils/hooks/usePendoTrackEvents';
import { PENDO_TRACK_EVENTS } from '../../../../constants';
import { ReactComponent as DragIcon } from '../../../../images/icons/gh_dragg.svg';
import { ColumnListItemStyled } from './styledDrawer';

export const ColumnListSortableItem = memo(
  ({ id, onRemove, disabled, handle, handleProps, isHighlighted, children, ...rest }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
      id,
      disabled,
    });

    const pendoTrackEvents = usePendoTrackEvents();

    const style = {
      transform: CSS.Translate.toString(transform),
      transition,
    };

    return (
      <ColumnListItemStyled
        ref={setNodeRef}
        {...rest}
        style={style}
        {...attributes}
        data-cypress="draggable-item"
        isDisabled={disabled}
        isHighlighted={isHighlighted}
      >
        {!disabled ? (
          <Box className="drag" data-testid="draggable-enabled" data-cypress="draggable-handle" {...handleProps} {...listeners}>
            {' '}
            <DragIcon className="drag-icon" />{' '}
          </Box>
        ) : (
          <Box className="drag" data-testid="draggable-disabled" data-cypress="draggable-handle">
            <DragIcon className="drag-icon" />
          </Box>
        )}
        <Box className="title">{children}</Box>
        <Box className="toolbar">
          {disabled ? (
            <Box className="pinned">
              <PushPinOutlinedIcon className="icon" />
            </Box>
          ) : (
            <Box
              className="remove"
              onClick={() => {
              pendoTrackEvents(PENDO_TRACK_EVENTS.DELETE_COLUMN, { id });
              onRemove(id);
              }}
            >
              <CloseIcon className="icon" />
            </Box>
          )}
        </Box>
      </ColumnListItemStyled>
    );
  },
);

export const ColumnListItem = forwardRef(({ children, ...props }, ref) => (
  <ColumnListItemStyled {...props} ref={ref} isBlocked>
    <Box className="drag" style={{ cursor: 'grabbing' }}>
      <DragIcon className="drag-icon" />
    </Box>
    <Box className="title">{children}</Box>
  </ColumnListItemStyled>
));

export default ColumnListItem;
