import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDisplayKeys = styled(Box)(({ theme }) => ({
  '.valueClass': {
    color: theme.palette.text.txt_default,
    fontFamily: theme.fonts.robotoRegular,
    fontSize: '14px',
    lineHeight: '18px',

    '&:not(:last-of-type):after': {
      content: "','",
      marginRight: '2px',
    },
  },
}));
