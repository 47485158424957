import { CATEGORIES } from 'features/header/categories/constants';
import { SR_PREVIEW_MODE_KEY } from '../../features/TicketPanel/constants';

export const mapToSrKey = (key) => {
  switch (key) {
    case CATEGORIES.FIRST_LEVEL_CATEGORY_FIELD_KEY:
      return { key: 'primaryCategory', isCategoryKey: true };
    case CATEGORIES.SECOND_LEVEL_CATEGORY_FIELD_KEY:
      return { key: 'secondaryCategory', isCategoryKey: true };
    case CATEGORIES.THIRD_LEVEL_CATEGORY_FIELD_KEY:
      return { key: 'thirdLevelCategory', isCategoryKey: true };
    default:
      return { key, isCategoryKey: false };
  }
};

export const shouldShowDeEscalationPrompt = (
  updateTicket,
  oldTicket,
  updateTicketFields,
  deescalationPromptEnabled,
  manualPriorityChangesEnabled,
) => {
  const updateField = updateTicketFields ? Object.keys(updateTicketFields)?.[0] : null;
  return (
    deescalationPromptEnabled &&
    updateTicket?.escalation > 0 &&
    (!oldTicket?.escalation || !oldTicket?.deescalation) &&
    !(manualPriorityChangesEnabled && updateField === 'priority')
  );
};

export const getIsNewSr = (srId) => srId === 'new';

export const getIsExistingSr = (srId) => !!srId && !Number.isNaN(Number(srId));

export const getIsPreviewSr = (srId) => srId === SR_PREVIEW_MODE_KEY;
