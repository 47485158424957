import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledToasterWrapper = styled(Box)(() => ({
  position: 'fixed',
  top: '8px',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '4px',
}));

export const StyledToaster = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '12px',
  gap: '15px',
  minHeight: '60px',
  background: ' #FFFFFF',
  boxShadow: '0px 4px 12px rgba(118, 130, 150, 0.25)',
  borderRadius: '4px',

  svg: {
    width: '36px',
    height: '36px',
  },
}));

export const StyledCloseButton = styled('button')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0,
  background: 'transparent',
  border: 'none',
  marginLeft: 'auto',
  svg: {
    cursor: 'pointer',
    width: '16px',
    height: '16px',
    marginLeft: '24px',
  },
}));

export const StyledMessage = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  columnGap: '3px',
}));

export const StyledDetailsText = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  color: theme.palette.text.blue_user_name,
}));

export const StyledDetails = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flexStart',
  isolation: 'isolate',
  borderRadius: '8px',
  background: ' #FFFFFF',
  boxShadow: '0px 4px 12px rgba(118, 130, 150, 0.25)',
  padding: '24px',
  width: '500px',
  margin: 'auto',
}));

export const StyledCloseDetailsButton = styled(StyledCloseButton)(() => ({
  position: 'absolute',
  top: '18px',
  right: '18px',
}));
