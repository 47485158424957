import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledCounter = styled(Box)(({ theme }) => ({
  marginLeft: '10px',
  backgroundColor: theme?.palette?.generic?.grey_200,
  borderRadius: '12px',
  padding: '3px 5px',
  fontSize: theme?.fontSize?.small,
}));
