import RichTextEditor from 'common/components/controls/RichTextEditor';
import { lineHeightHandler } from '../../../utils';

export const RichTextField = ({ auditLogValue, showTruncationButton, isRichTextField, isRegularText }) => (
  <div className={`field-change ${showTruncationButton ? 'rich-text-ellipsis' : ''}`}>
    {isRichTextField ? (
      <RichTextEditor isReadOnly isExpanded editorText={lineHeightHandler(auditLogValue)} shouldShowAttachments={false} />
    ) : (
      auditLogValue.split(/\r?\n/).map((line, index) => (
        <div key={index} className={isRegularText ? 'regular-text' : ''}>
          {line}
        </div>
      ))
    )}
  </div>
);
