import { memo, useState } from 'react';
import useHover from 'common/utils/hooks/useHover';
import { saveFile } from 'common/utils/files';
import * as icons from 'images/icons/attachments';
import { fileIcon } from 'features/attachments/attachmentsList/attachmentIcons';
import { useGeneratedPresignedUrlByAttachmentId } from 'remote-state/attachments';
import { defaultFileIcon } from 'images/icons/attachments';
import {
  StyledThumbnail,
  StyledFileTypeLogo,
  StyledContainer,
  StyledAttachmentUsageCount,
  StyledVideoContainer,
} from './style';
import MetadataOverlay from './metadataOverlay';
import Tooltip from '../tooltip';
import { IMAGE_ICON_NAME, SCREEN_CAPTURE_TYPES, SCREENSHOT_ICON_NAME, VIDEO_ICON_NAME } from './constants';

const Thumbnail = (props) => {
  const {
    extendedFileName,
    extension,
    username,
    timestamp,
    attachmentId,
    subResourceType,
    fileId,
    fileUrl,
    index,
    onAttachmentDeleted,
    onDisplayPreview,
    attachmentUsageCount,
    onCheckHandler,
    metadata,
    isItemChecked,
    disabled,
    isDefaultImage,
    isScreenCapture,
    screenCaptureType,
    isActionItem,
    isTicketLocked,
    isHideDetails,
    isMinimizedSize = false,
  } = props;

  const [isThumbnailChecked, setIsThumbnailChecked] = useState(false);
  const [hoverRef, isHovered] = useHover();
  const {
    data: { downloadUrl: presignedUrl },
  } = useGeneratedPresignedUrlByAttachmentId(attachmentId, fileId, subResourceType);
  const icon = isScreenCapture ? SCREENSHOT_ICON_NAME : fileIcon(extension);
  const isImage = icon === IMAGE_ICON_NAME || icon === SCREENSHOT_ICON_NAME;
  const isVideo = icon === VIDEO_ICON_NAME || screenCaptureType === SCREEN_CAPTURE_TYPES.VIDEO;

  const getThumbnailSource = () => {
    if (isImage || isVideo) {
      if (isScreenCapture || !presignedUrl) {
        return fileUrl;
      }
      return presignedUrl;
    }
    return icons[icon];
  };

  const toggleThumbnailCheck = (e) => {
    e.stopPropagation();

    if (onCheckHandler) {
      const isChecked = onCheckHandler({ isChecked: !isThumbnailChecked, metadata, attachmentId });
      setIsThumbnailChecked(isChecked);
    } else {
      setIsThumbnailChecked((prev) => !prev);
    }
  };

  const handleDownload = (e) => {
    e.stopPropagation();
    const downloadUrl = isScreenCapture || !presignedUrl ? fileUrl : presignedUrl;
    saveFile({ url: downloadUrl, fileName: extendedFileName });
  };

  const handleDisplayPreview = () => {
    onDisplayPreview(index);
  };

  const showOverlayIcon = isImage || isVideo || isActionItem;

  const thumbnailContent = (
    <>
      <StyledThumbnail
        isMinimizedSize={isMinimizedSize}
        className="attachment-uploader-thumbnail"
        onClick={handleDisplayPreview}
        fileUrl={getThumbnailSource()}
        isImage={isImage}
        data-cy={`attachment-uploader-thumbnail-${attachmentId}`}
        isDefault={isDefaultImage}
        isActionItem={isActionItem}
        fileType={icon}
      >
        {showOverlayIcon && (
          <StyledFileTypeLogo isActionItem={isActionItem}>
            <img alt="" src={icons[icon] || defaultFileIcon} />
          </StyledFileTypeLogo>
        )}

        {attachmentUsageCount > 1 && !isActionItem && (
          <StyledAttachmentUsageCount isImage={isImage} isActionItem={isActionItem}>
            {attachmentUsageCount}
          </StyledAttachmentUsageCount>
        )}
        {!isActionItem && (
          <div className="metadata-overlay-wrapper">
            <MetadataOverlay
              showDownloadOption={!isMinimizedSize}
              username={username}
              timestamp={timestamp}
              toggleCheck={toggleThumbnailCheck}
              isChecked={isItemChecked || isThumbnailChecked}
              handleDownload={handleDownload}
              handleDelete={onAttachmentDeleted}
              fileName={extendedFileName}
              attachmentId={attachmentId}
              fileId={fileId}
              attachmentUsageCount={attachmentUsageCount}
              disabled={disabled}
              isScreenCapture={isScreenCapture}
              screenCaptureType={screenCaptureType}
              isTicketLocked={isTicketLocked}
              isHideDetails={isHideDetails}
            />
          </div>
        )}
      </StyledThumbnail>

      <Tooltip
        data-cy={`attachment-uploader-thumbnail-title-${attachmentId}`}
        className="attach-fileUrl-text"
        isTruncatedText
        title={extendedFileName}
        text={extendedFileName}
      />
      {isActionItem && (
        <div className="metadata-overlay-wrapper">
          <MetadataOverlay
            showDownloadOption={!isMinimizedSize}
            isActionItem={isActionItem}
            subResourceType={subResourceType}
            username={username}
            timestamp={timestamp}
            toggleCheck={toggleThumbnailCheck}
            isChecked={isItemChecked || isThumbnailChecked}
            handleDownload={handleDownload}
            handleDelete={onAttachmentDeleted}
            fileName={extendedFileName}
            attachmentId={attachmentId}
            fileId={fileId}
            attachmentUsageCount={attachmentUsageCount}
            disabled={disabled}
            isScreenCapture={isScreenCapture}
            isTicketLocked={isTicketLocked}
            isHideDetails={isHideDetails}
          />
        </div>
      )}
    </>
  );

  if (isVideo && !isActionItem) {
    return (
      <StyledVideoContainer
        aria-label={`uploadedFile-${index}`}
        visible={isHovered || isItemChecked || isThumbnailChecked}
        ref={hoverRef}
      >
        <video muted src={getThumbnailSource()} />
        {thumbnailContent}
      </StyledVideoContainer>
    );
  }

  return (
    <StyledContainer
      isMinimizedSize={isMinimizedSize}
      data-testid={`thumbnail-list-item-${fileId}`}
      aria-label={`uploadedFile-${index}`}
      visible={isHovered || isItemChecked || isThumbnailChecked}
      isActionItem={isActionItem}
      ref={hoverRef}
      className="thumbnail-list-item"
    >
      {thumbnailContent}
    </StyledContainer>
  );
};

export default memo(Thumbnail);
