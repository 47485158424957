import { USER_CONSTANTS } from 'constants/users';
import { AUTH_CONSTANTS } from 'constants/auth';

const generateDateFormatsArr = (userDateFormat) => {
  const isAmericanFormat = userDateFormat === USER_CONSTANTS.AMERICAN;
  const dateFormatNoTime = isAmericanFormat ? 'MM/DD/yyyy' : 'DD-MM-yyyy';
  const dateFormat = dateFormatNoTime.concat(isAmericanFormat ? ' hh:mm a' : ' HH:mm');
  return [
    {
      key: USER_CONSTANTS.DATE_FORMAT,
      valueClass: '',
      valueCaption: dateFormat,
      value: dateFormat,
      keyCaption: 'Date Format',
    },
    {
      key: USER_CONSTANTS.DATE_FORMAT_NO_TIME,
      valueClass: '',
      valueCaption: dateFormatNoTime,
      value: dateFormatNoTime,
      keyCaption: 'Date Format No Time',
    },
  ];
};

export const modifyUserDateInfo = (userInfo) => {
  if (userInfo) {
    const { info: userAttributes } = userInfo;
    const { valueCaption: userDateFormat } = userAttributes?.find((atr) => atr.key === USER_CONSTANTS.INPUT_DATE) || {};
    const dateFormatsArr = generateDateFormatsArr(userDateFormat);
    if (
      dateFormatsArr?.length &&
      userAttributes?.length &&
      !userAttributes?.some((atr) => atr.key === USER_CONSTANTS.DATE_FORMAT)
    ) {
      userAttributes.push(...dateFormatsArr);
    }
    return userInfo;
  }
};

export const getUserDomains = () =>
  // axiosInstance
  //   .get('')
  //   .then((response) => response.data);
  ['Local User', 'Domain 1', 'Domain 2'];

export const getUserLanguage = (userAttributes) => {
  const userLanguageAtr = userAttributes.find((userAtribute) => userAtribute.key === AUTH_CONSTANTS.LOCALE);
  if (userLanguageAtr) {
    return userLanguageAtr.valueCaption;
  }
  return 'en';
};
export const getCalculatedUserName = (user) => {
  const calculatedUserName = user.info?.find(
    (userAtribute) => userAtribute.key === AUTH_CONSTANTS.CALCULATED_USER_NAME,
  );
  if (calculatedUserName) {
    return calculatedUserName.valueCaption;
  }
  return user.name;
};

export const getRole = (user) => (user.isAdmin ? AUTH_CONSTANTS.ADMIN : AUTH_CONSTANTS.END_USER);

export const getCalculatedRole = (user) => {
  if (user.isSysAidAdmin) return 'SysAid Admin';
  if (user.isAdmin) return 'Admin';
  return 'End User';
};
