import { SwitchToggle } from 'common/components/controls/Switch/SwitchToggle';
import useTexts from 'features/templateBuilder/useTexts';
import { StyledPropertyWrapper } from '../StyledTemplatePropertiesPanel';

export default function FieldNameProperty(props) {
  const { switchId, hideFieldCaption, isSwitchDisabled, onSwitchChange } = props;
  const { hideFieldCaptionTitle } = useTexts();

  const handleSwitchChange = (event) => {
    onSwitchChange(event);
  };

  return (
    <StyledPropertyWrapper data-testid="hide-field-caption">
      <SwitchToggle
        handleSwitchChange={handleSwitchChange}
        isChecked={hideFieldCaption}
        switchText={hideFieldCaptionTitle}
        isTemplateBuilder
        switchId={switchId}
        isDisabled={isSwitchDisabled}
        classNames={{ labelClass: 'template-switch-toggle-label' }}
      />
    </StyledPropertyWrapper>
  );
}
