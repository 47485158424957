import { useCallback, useEffect } from 'react';

const useOutsideRowsClick = (onClickAway, ignoreSelectors) => {
  const handleClickOutside = useCallback(
    (event) => {
      const ignoreElements = ignoreSelectors.flatMap((ignoreSelector) =>
        Array.from(document.querySelectorAll(ignoreSelector)),
      );

      const sortingHeaderRef = document.getElementsByClassName('ag-header')?.[0];
      const centerTableRef = document.getElementsByClassName('ag-center-cols-container')?.[0];
      const leftTableRef = document.getElementsByClassName('ag-pinned-left-cols-container')?.[0];
      const horizontalScroll = document.getElementsByClassName('ag-body-horizontal-scroll-viewport')?.[0];

      const isOverHeader = sortingHeaderRef?.contains(event?.target);
      const isOverLeftTableBody = centerTableRef?.contains(event?.target);
      const isOverCenterTableBody = leftTableRef?.contains(event?.target);
      const isOverHorizontalScroll = horizontalScroll?.contains(event?.target);
      const isInTheWhiteArea = event?.target?.innerHTML?.includes('ag-center-cols-container');

      if (
        centerTableRef &&
        leftTableRef &&
        (isInTheWhiteArea ||
          (!isOverCenterTableBody && !isOverLeftTableBody && !isOverHorizontalScroll) ||
          isOverHeader) &&
        document.body.contains(event?.target) &&
        !ignoreElements?.includes(event?.target) &&
        !ignoreElements?.find((ignoreEl) => ignoreEl?.contains(event?.target))
      ) {
        onClickAway();
      }
    },
    [onClickAway, ignoreSelectors],
  );
  useEffect(() => {
    document.addEventListener('click', handleClickOutside); // should be click and not mousedown so the blur event (that updates sr) will happen before
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);
};

export default useOutsideRowsClick;
