import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledFieldName = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  maxWidth: '100%',
}));

export const FieldNameText = styled('span')(() => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

export const StyledField = styled(Box, { shouldForwardProp: (prop) => prop !== 'isTemplatePage' })(
  ({ theme, isTemplatePage }) => ({
    color: theme.palette.text.secondary_txt,
    marginBottom: isTemplatePage ? '1px' : '2px',
    fontSize: '12px',
    lineHeight: '14px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: '6px',
    height: isTemplatePage ? '18px' : '14px',
    width: '100%',
    a: {
      textDecoration: 'none',
      color: 'inherit',
    },
    '& .kebab-wrapper': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .kebab-icon': {
      height: '10px',
      width: '10px',
    },
  }),
);
