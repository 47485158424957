import { useCallback, useEffect, useState, useRef } from 'react';

import MessagePrompt from 'common/components/messagePrompt';
import { ReactComponent as RelatedSrsIcon } from 'images/icons/related-service-records.svg';
import { ReactComponent as RelatedAssetsIcon } from 'images/icons/related-assets.svg';
import { ReactComponent as RelatedCisIcon } from 'images/icons/related-cis.svg';
import { ReactComponent as AccordionToggleButton } from 'images/icons/accordionToggleButton.svg';
import { SR_TYPE_CODE_MAP } from 'features/TicketPanel/constants';
import usePendoTrackEvents from 'common/utils/hooks/usePendoTrackEvents';
import { openWindow } from 'common/utils/utils';
import { PENDO_TRACK_EVENTS } from 'constants';
import { StyledRelatedItemsTables } from './style';
import {
  ASSET_URL,
  CI_URL,
  ITEM_TYPES,
  RELATED_ITEMS_COLLAPSED_SESSION_KEY,
  SCROLL_POSITION_KEY_PREFIX,
  TABLE_MAX_ROWS,
  TABLE_ROW_HEIGHT,
} from '../constants';
import { Table } from './table';
import { useTableData } from './useTableData';
import useTexts from '../hooks/useTexts';
import { useUnlinkRelatedItem } from '../hooks/useRelatedItems';

export const RelatedItemsTables = ({ relatedItems, srId, srType, isRelatedAssetAndCiFFOn }) => {
  const [tablesCollapsed, setTablesCollapsed] = useState();
  const [unlinkPrompt, setUnlinkPrompt] = useState({ open: false, relationship: '', linkedItemId: 0 });
  const srRelatedSrsGridRef = useRef(null);
  const srRelatedAssetsGridRef = useRef(null);
  const srRelatedCisGridRef = useRef(null);
  const { srTableColumns, assetTableColumns, ciTableColumns } = useTableData();
  const pendoTrackEvents = usePendoTrackEvents();
  const { mutateAsync: unlinkItem } = useUnlinkRelatedItem(srId);
  const srTypeValue = SR_TYPE_CODE_MAP[srType];
  const {
    componentTexts: {
      unlinkSrPromptTitle,
      unlinkPromptCancelBtn,
      unlinkPromptOkBtn,
      unlinkPromptDescription,
      srTableTitle,
      assetTableTitle,
      unlinkAssetCiPromptTitle,
      assetText,
      ciText,
      ciTableTitle,
    },
    getSrType,
  } = useTexts(srTypeValue);

  const getCollapsedTables = () => {
    let storedTableData = sessionStorage.getItem(RELATED_ITEMS_COLLAPSED_SESSION_KEY);
    storedTableData = JSON.parse(storedTableData);
    if (!storedTableData) {
      storedTableData = {};
    }
    return storedTableData;
  };

  const setTablesCollapsedInSession = (key, value) => {
    const tablesStatus = getCollapsedTables();
    tablesStatus[key] = value;
    setTablesCollapsed(tablesStatus);
    sessionStorage.setItem(RELATED_ITEMS_COLLAPSED_SESSION_KEY, JSON.stringify(tablesStatus));
  };

  useEffect(() => {
    if (srId) {
      const tablesStatus = getCollapsedTables();
      if (tablesStatus) {
        setTablesCollapsed(tablesStatus);
      }
    }
  }, [srId]);

  const handleExpansion = (table, value) => setTablesCollapsedInSession(`${srId}_relatedItems_${table}`, value);

  const onSrRowclicked = useCallback(
    (row, event) => {
      const unlinkItemCellClicked = event?.target?.closest('svg');
      if (unlinkItemCellClicked) {
        pendoTrackEvents(PENDO_TRACK_EVENTS.Unlink_existing_related_item_icon_click, {});
        setUnlinkPrompt({
          ...unlinkPrompt,
          open: true,
          relationship: row?.relationship,
          linkedItemId: row?.id,
          itemType: ITEM_TYPES?.SR?.id,
          title: unlinkSrPromptTitle
            ?.replace('__srType__', getSrType(SR_TYPE_CODE_MAP[row?.srType]).toLowerCase())
            ?.replace('__id__', row?.id),
        });
      } else {
        window.open(`/spaces/ticket?id=${row?.id}`, '_blank').focus();
      }
    },
    [unlinkPrompt, unlinkSrPromptTitle, getSrType, pendoTrackEvents],
  );

  const onAssetCiRowclicked = useCallback(
    (row, event, itemType) => {
      const isAssetEntity = itemType === ITEM_TYPES.ASSET.id;
      const entityType = isAssetEntity ? assetText.toLowerCase() : ciText;
      const linkedItemId = isAssetEntity ? row?.serialNumber : row?.id;
      const unlinkItemCellClicked = event?.target?.closest('svg');
      if (unlinkItemCellClicked) {
        setUnlinkPrompt({
          ...unlinkPrompt,
          open: true,
          relationship: row?.relationship,
          linkedItemId,
          itemType,
          title: unlinkAssetCiPromptTitle.replace('__entityType__', entityType),
        });
      } else {
        const url = isAssetEntity ? `${ASSET_URL}${row?.serialNumber}` : `${CI_URL}${row?.id}`;
        openWindow(url);
      }
    },
    [unlinkPrompt, unlinkAssetCiPromptTitle, assetText, ciText],
  );

  const handleClosePrompt = () => {
    pendoTrackEvents(PENDO_TRACK_EVENTS.Cancel_unlinking_existing_related_item_button_click_in_popup, {});
    setUnlinkPrompt({ ...unlinkPrompt, open: false });
  };

  const onPromptUnlinkClick = async () => {
    pendoTrackEvents(PENDO_TRACK_EVENTS.Unlink_existing_related_item_button_click_in_popup, {});
    await unlinkItem({
      srId,
      linkedItemId: unlinkPrompt?.linkedItemId,
      relationship: unlinkPrompt?.relationship,
      itemType: unlinkPrompt?.itemType,
    });
    setUnlinkPrompt({ ...unlinkPrompt, open: false });
  };

  const getScrollingPositions = () => {
    const scrollingPositionsData = localStorage.getItem(SCROLL_POSITION_KEY_PREFIX);
    let scrollingPositions = JSON.parse(scrollingPositionsData);
    if (!scrollingPositions) {
      scrollingPositions = {};
    }
    return scrollingPositions;
  };

  const handleScrollPosition = (position, tableName) => {
    const scrollingPositions = getScrollingPositions();
    scrollingPositions[`${srId}_${tableName}`] = position;
    localStorage.setItem(SCROLL_POSITION_KEY_PREFIX, JSON.stringify(scrollingPositions));
  };

  const getTableScrollPosition = useCallback((srId, tableName) => {
    const positions = getScrollingPositions();
    return parseInt(positions[`${srId}_${tableName}`], 10) || 0;
  }, []);

  const tablesOrder = [
    { key: ITEM_TYPES.SR.key, data: relatedItems?.serviceRequests },
    { key: ITEM_TYPES.ASSET.key, data: relatedItems?.assets },
    { key: ITEM_TYPES.CI.key, data: relatedItems?.cis },
  ];
  const lastTableKey = tablesOrder.filter((table) => !!table.data?.length)?.slice(-1)?.[0]?.key;

  return (
    <StyledRelatedItemsTables>
      <MessagePrompt
        open={unlinkPrompt?.open}
        title={unlinkPrompt?.title}
        onOkClick={onPromptUnlinkClick}
        btnOkText={unlinkPromptOkBtn}
        showCancelBtn
        btnCancelText={unlinkPromptCancelBtn}
        onClose={handleClosePrompt}
        showIcon={false}
      >
        {unlinkPromptDescription}
      </MessagePrompt>
      {!!relatedItems?.serviceRequests?.length && (
        <Table
          accordionId={ITEM_TYPES.SR.key}
          expanded={(tablesCollapsed && tablesCollapsed[`${srId}_relatedItems_sr`]) ?? true}
          handleExpansion={handleExpansion}
          ExpandIcon={<AccordionToggleButton className="expandIcon" />}
          headerIcon={RelatedSrsIcon}
          relatedItems={relatedItems?.serviceRequests}
          onRowclicked={onSrRowclicked}
          columns={srTableColumns}
          queryKey="srRelatedSrItems"
          ref={srRelatedSrsGridRef}
          tableTitle={`${srTableTitle} (${relatedItems?.serviceRequests?.length})`}
          rowHeight={TABLE_ROW_HEIGHT}
          rowsCountFitInTable={
            relatedItems?.serviceRequests?.length >= TABLE_MAX_ROWS
              ? TABLE_MAX_ROWS
              : relatedItems?.serviceRequests?.length
          }
          isLastTable={lastTableKey === ITEM_TYPES.SR.key}
          handleScrollPosition={(position) => handleScrollPosition(position, ITEM_TYPES.SR.key)}
          scrollPosition={getTableScrollPosition(srId, ITEM_TYPES.SR.key)}
        />
      )}
      {isRelatedAssetAndCiFFOn && !!relatedItems?.assets?.length && (
        <Table
          accordionId={ITEM_TYPES.ASSET.key}
          expanded={(tablesCollapsed && tablesCollapsed[`${srId}_relatedItems_asset`]) ?? true}
          handleExpansion={handleExpansion}
          ExpandIcon={<AccordionToggleButton className="expandIcon" />}
          headerIcon={RelatedAssetsIcon}
          relatedItems={relatedItems?.assets}
          onRowclicked={(row, event) => onAssetCiRowclicked(row, event, ITEM_TYPES.ASSET.id)}
          columns={assetTableColumns}
          queryKey="srRelatedAssetItems"
          ref={srRelatedAssetsGridRef}
          tableTitle={`${assetTableTitle} (${relatedItems?.assets?.length})`}
          rowHeight={TABLE_ROW_HEIGHT}
          rowsCountFitInTable={
            relatedItems?.assets?.length >= TABLE_MAX_ROWS ? TABLE_MAX_ROWS : relatedItems?.assets?.length
          }
          isLastTable={lastTableKey === ITEM_TYPES.ASSET.key}
          handleScrollPosition={(position) => handleScrollPosition(position, ITEM_TYPES.ASSET.key)}
          scrollPosition={getTableScrollPosition(srId, ITEM_TYPES.ASSET.key)}
        />
      )}
      {isRelatedAssetAndCiFFOn && !!relatedItems?.cis?.length && (
        <Table
          accordionId={ITEM_TYPES.CI.key}
          expanded={(tablesCollapsed && tablesCollapsed[`${srId}_relatedItems_ci`]) ?? true}
          handleExpansion={handleExpansion}
          ExpandIcon={<AccordionToggleButton className="expandIcon" />}
          headerIcon={RelatedCisIcon}
          relatedItems={relatedItems?.cis}
          onRowclicked={(row, event) => onAssetCiRowclicked(row, event, ITEM_TYPES.CI.id)}
          columns={ciTableColumns}
          queryKey="srRelatedCiItems"
          ref={srRelatedCisGridRef}
          tableTitle={`${ciTableTitle} (${relatedItems?.cis?.length})`}
          rowHeight={TABLE_ROW_HEIGHT}
          rowsCountFitInTable={relatedItems?.cis?.length >= TABLE_MAX_ROWS ? TABLE_MAX_ROWS : relatedItems?.cis?.length}
          isLastTable={lastTableKey === ITEM_TYPES.CI.key}
          handleScrollPosition={(position) => handleScrollPosition(position, ITEM_TYPES.CI.key)}
          scrollPosition={getTableScrollPosition(srId, ITEM_TYPES.CI.key)}
        />
      )}
    </StyledRelatedItemsTables>
  );
};
