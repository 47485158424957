import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import { routeConfig } from 'routes';
import { selectDirection, selectNavigationObject, setNavigationObject } from 'store/globalSlice';
import usePreviousValue from 'common/utils/hooks/usePreviousValue';
import { useMemoryReporting } from 'common/utils/hooks/useMemoryUsage';
import { handlePreventXssAttack, removeCookie } from 'common/utils/utils';
import { APP_CONSTANTS } from 'constants/index';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { GlobalStyle } from './global';
import { StyledWrapper } from './style';
import startAppSession from "../../keepAlive";
import { useFeatureFlagQuery } from '../../remote-state/featureFlagsHooks';
import { FEATURE_FLAGS_KEYS } from '../../constants/featureFlags';

export default function App() {
  const dispatch = useDispatch();
  const navigationObject = useSelector(selectNavigationObject);
  const router = useMemo(() => createRouter({ routeTree: routeConfig() }), []);
  const prevNavigationObject = usePreviousValue(navigationObject);
  const { data: isRTLEnabled } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.RTL_ENABLED,
    defaultValue: false,
  });
  const direction = useSelector(selectDirection);
  const isRTL = direction === 'rtl' && isRTLEnabled;
  const handleBeforeUnload = useCallback(() => {
    removeCookie(APP_CONSTANTS.IN_SPACES_IFRAME_COOKIE);
  }, []);

  useEffect(() => {
    handlePreventXssAttack(router);
  }, [router]);

  useEffect(() => {
    startAppSession();
  }, []);

  useEffect(() => {
    if (navigationObject && prevNavigationObject !== navigationObject) {
      if (navigationObject.to && navigationObject.forceWindow) {
        window.location.href = navigationObject.to;
      } else {
        router.navigate(navigationObject);
      }
      dispatch(setNavigationObject(null));
    }
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [navigationObject, prevNavigationObject, router, dispatch, handleBeforeUnload]);

  useMemoryReporting(60000);

  const rtlCache = createCache({
    key: 'cssrtl',
    stylisPlugins: [rtlPlugin],
  });
  const ltrCache = createCache({
    key: 'css',
  });


  return (
    <StyledWrapper>
      <GlobalStyle />
      <CacheProvider  value={isRTL ? rtlCache : ltrCache}>
        <RouterProvider router={router} />
      </CacheProvider>
    </StyledWrapper>
  );
}
