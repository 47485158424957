import { ButtonUnstyled } from '@mui/core';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTimeFormatList = styled(Box)(() => ({
  width: '100%',
}));

export const StyledTimeFormat = styled(ButtonUnstyled, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme, isSelected }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '30px',
  height: '30px',
  border: 'none',
  borderRadius: '50%',
  fontSize: '12px',
  color: isSelected ? theme?.palette?.generic?.primary : theme?.palette?.text?.title,
  backgroundColor: isSelected ? theme?.palette?.generic.sysaid : 'transparent',
  '&:hover': {
    backgroundColor: isSelected ? theme?.palette?.generic.sysaid : theme?.palette?.text?.inactive_secondary,
    cursor: 'pointer',
  },
}));
