import { useState } from 'react';
import PropTypes from 'prop-types';
import { useLoginObject } from 'remote-state/userServiceHooks';
import useTexts from '../useTexts';
import { StyledRememberForgotSection } from './style';

export default function RememberForgotSection({ onClickForgotPassword }) {
  const { rememberMeText, forgotPasswordText } = useTexts();

  const { data: loginObject } = useLoginObject();

  const { displayRememberMeInLoginPage, rememberMeDefaultValue } = loginObject;

  const [rememberMeChacked, setRememberMeChacked] = useState(rememberMeDefaultValue);

  const handleChangeRememberMe = () => {
    setRememberMeChacked((prev) => !prev);
  };

  return (
    <StyledRememberForgotSection>
      {displayRememberMeInLoginPage && (
        <div className="login-util remember-checkbox">
          <label className="checkbox" htmlFor="checkbox">
            <input type="checkbox" id="checkbox" checked={rememberMeChacked} onChange={handleChangeRememberMe} />
            <span>{rememberMeText}</span>
          </label>
        </div>
      )}
      <div
        className="login-util login-forgot-password-button"
        onClick={onClickForgotPassword}
        role="navigation"
        aria-hidden="true"
      >
        {forgotPasswordText}
      </div>
    </StyledRememberForgotSection>
  );
}

RememberForgotSection.propTypes = {
  onClickForgotPassword: PropTypes.func,
};
