import { useState, useRef } from 'react';
import { useRouter } from '@tanstack/react-router';
import { Popper, ClickAwayListener, MenuList, MenuItem, Button } from '@mui/material';
import { useUserInfo } from 'remote-state/userServiceHooks';
import { ReactComponent as CopyIcon } from 'images/icons/new_Template.svg';
import { SR_TYPES } from 'features/TicketPanel/constants';
import { QUERIES_KEYS } from 'constant';
import { BASIC_EDITION } from 'features/settings/constants';
import { useApplicationData } from 'remote-state/applicationHooks';
import useTexts from '../useTexts';
import { StyledNewTemplateButton, StyledNewTemplateMenu } from './style';

export default function NewTemplateButton() {
  const anchorRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [newTemplateMenuOpen, setNewTemplateMenuOpen] = useState(false);
  const { data: edition } = useApplicationData(QUERIES_KEYS.EDITION);
  const { newTemplateText, srTypeIncidentText, srTypeRequestText, srTypeProblemText, srTypeChangeText } = useTexts();
  const isBasicEdition = edition === BASIC_EDITION;
  const newTicketPath = '/spaces/template?id=new';
  const router = useRouter();

  const newTemplateTypes = [];
  const { data: userPermissions } = useUserInfo(QUERIES_KEYS.CURRENT_USER_PERMISSIONS);
  if (userPermissions?.canCreateModifyIncidentTemplates) {
    newTemplateTypes.push({ type: SR_TYPES.incident, title: srTypeIncidentText });
  }
  if (userPermissions?.changeManager) {
    newTemplateTypes.push(
      { type: SR_TYPES.request, title: srTypeRequestText },
      { type: SR_TYPES.problem, title: srTypeProblemText },
      { type: SR_TYPES.change, title: srTypeChangeText },
    );
  }

  const toggleMenu = () => {
    setNewTemplateMenuOpen((prevOpen) => !prevOpen);
    setOpen(!isOpen);
  };

  const onClickNewTicket = (srType) => {
    router.navigate({
      to: `${newTicketPath}&srType=${srType}`,
    });
  };

  const closeMenu = (templateType) => (event) => {
    if (anchorRef?.current?.contains(event.target)) {
      return;
    }
    setNewTemplateMenuOpen(false);
    onClickNewTicket(templateType);
  };

  return (
    <StyledNewTemplateButton>
      <Button
        className="create-btn"
        startIcon={<CopyIcon />}
        onClick={() => {
          if (isBasicEdition) {
            onClickNewTicket(SR_TYPES.incident);
          } else {
            toggleMenu();
          }
        }}
        ref={anchorRef}
        data-testid="new-template-button"
      >
        {newTemplateText}
      </Button>
      <Popper open={newTemplateMenuOpen} placement="bottom" anchorEl={anchorRef.current}>
        {() => (
          <StyledNewTemplateMenu menuWidth={anchorRef.current.clientWidth}>
            <ClickAwayListener onClickAway={toggleMenu}>
              <MenuList className="new-template-menu">
                {newTemplateTypes.map(({ type: templateType, title }) => (
                  <MenuItem
                    key={templateType}
                    data-cy={`new-${templateType}-template`}
                    onClick={closeMenu(templateType)}
                    className="new-template-menu-item"
                  >
                    {title}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </StyledNewTemplateMenu>
        )}
      </Popper>
    </StyledNewTemplateButton>
  );
}
