import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledSrPanel = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFloatingTicketPanel',
})(({ theme, isFloatingTicketPanel }) => ({
  backgroundColor: theme.palette.generic.primary,
  overflowY: 'auto',
  borderRadius: !isFloatingTicketPanel && '10px',
  position: 'relative',
  padding: isFloatingTicketPanel ? '0px' : '24px 0px 12px',
  height: isFloatingTicketPanel && 'calc(100vh - 146px - 81px)',
  '&:hover .vertical-scrollbar': {
    opacity: '1 !important',
  },
  '&.collapsed': {
    paddingLeft: '28px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },

  '&.collapsedVertical': {
    width: 'unset',
    flex: 1,
  },

  '&.expanded': {
    width: '100%',
    flex: 2,
  },

  ' .multi-select-field-wrapper': {
    borderRadius: theme?.shape?.border?.borderRadius,
    // this className should be changed beacuse this class is used also inside chip
    '.multi-select-field': {
      border: 'none',
    },
    '.plus-button': {
      opacity: 0,
    },
    '&:hover': {
      backgroundColor: theme?.palette?.grey?.bt_alt_grey_reg,
      '.plus-button': {
        opacity: 1,
      },
    },
    '.MuiButtonBase-root, .MuiChip-root': {
      minHeight: '0px !important',
      maxWidth: isFloatingTicketPanel && 'none',
    },

    '&:has(.multi-select-dropdown)': {
      backgroundColor: theme?.palette?.generic?.primary,
    },
  },
}));

export const StyledCollapsedPanelLabel = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey.bt_alt_grey_light,
  color: theme.palette.text.secondary_txt,
  borderTopRightRadius: '10px',
  borderBottomRightRadius: '10px',
  WebkitWritingMode: 'vertical-lr',
  writingMode: ['vertical-lr', 'tb-lr'],
  transform: 'scale(-1, -1)',
  textAlign: 'center',
  fontSize: '16px',
  lineHeight: '20px',
  padding: '24px 8px',
}));
