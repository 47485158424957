import { axiosInstance } from './axiosConfig';

export function getOpenaiSummarizeTicket(id) {
  return axiosInstance.get(`/sysai/summarize/${id}`).then((response) => response.data);
}

export const getSuggestedCategoriesBySrIds = (srIds) =>
  axiosInstance.post('sysai/suggested_categories/search', { srIds });

export const getRephrasedText = ({ rephraseRequest, signal }) =>
  axiosInstance.post('sysai/rephrase', rephraseRequest, { signal }).then((res) => res.data);

export const markSuggestedCategoriesChosen = (srId) => axiosInstance.get(`sysai/suggested_categories/chosen/${srId}`);

export const getSuggestedCategoriesByText = (data) =>
  axiosInstance.post('sysai/api/sr/suggest_category_for_text', data).catch((error) => error);

export const getAIConfigData = () => axiosInstance.post('sysai/api/login').then((response) => response.data);

export const getChatbotConfigs = () => axiosInstance.get('sysai/api/chatbot').then((response) => response.data);
