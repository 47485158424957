import { Button } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { debounce, uniqBy } from 'lodash-es';
import PropTypes from 'prop-types';
import { MORE_ATTACHMENTS_BTN_WIDTH, SMALL_ATTACHMENT_WIDTH, REGULAR_ATTACHMENT_WIDTH } from './style';
import { AttachmentChipList } from './AttachmentChipList';
import Thumbnail from '../../../common/components/thumbnail';
import { getDate } from '../../../common/utils/dateTimeUtils';
import { useUserInfo } from '../../../remote-state/userServiceHooks';
import { QUERIES_KEYS } from '../../../constant';
import { USER_CONSTANTS } from '../../../constants';

export const MAX_DISPLAY_ATTACHMENTS_LIMIT = 5;
export const ACTION_ITEM_ATTACHMENT_WIDTH = 202;

export const MoreAttachmentsWrapper = ({
  isActionItem = false,
  attachments,
  wrapperRef,
  isReadOnly,
  isDeleted,
  isDownloaded,
  deleteAttachmentEnabled,
  shouldTriggerDeleteApi,
  onAttachmentDeleted,
  handleDisplayPreview,
  handleDeleteAttachment,
  disabled,
  hideButton,
  isTicketLocked,
  srPanelCollapsed,
  filesData = [],
  isHideDetails,
  displayAsThumbnail,
  displayAttachmentsLImit,
  isMinimizedSize = false,
}) => {
  const [toggleMoreAttachments, setToggleMoreAttachments] = useState(false);
  const [displayAttachmentsLimit, setDisplayAttachmentsLimit] = useState(
    displayAttachmentsLImit || MAX_DISPLAY_ATTACHMENTS_LIMIT,
  );
  const attachmentsLength = attachments?.length;
  const showMoreAttachments = attachmentsLength > displayAttachmentsLimit;
  const moreAttachmentsSum = attachmentsLength - displayAttachmentsLimit;
  const userInfo = useUserInfo(QUERIES_KEYS.CURRENT_USER_FROM_CORE);
  const { valueCaption: timezone } = userInfo?.data?.info?.find((atr) => atr.key === USER_CONSTANTS.TIMEZONE) || {};
  const { valueCaption: dateFormat } =
    userInfo?.data?.info?.find((atr) => atr.key === USER_CONSTANTS.DATE_FORMAT) || {};

  let uniqueAttachments = attachments ? [...attachments] : [];
  if (isDeleted || isDownloaded) {
    uniqueAttachments = uniqBy(attachments, (attachment) => attachment.originalName);
  }
  const displayThumbnail = displayAsThumbnail || isActionItem || hideButton;

  const ATTACHMENT_WIDTH = useMemo(
    () => (isMinimizedSize ? SMALL_ATTACHMENT_WIDTH : REGULAR_ATTACHMENT_WIDTH),
    [isMinimizedSize],
  );

  const setLimit = useMemo(
    () =>
      debounce(() => {
        if (srPanelCollapsed === undefined || srPanelCollapsed === null) {
          return;
        }
        const offsetWidth = wrapperRef?.current?.offsetWidth;
        const attachmentWidth = isActionItem ? ACTION_ITEM_ATTACHMENT_WIDTH : ATTACHMENT_WIDTH;
        let elementsCount = Math.floor((offsetWidth - MORE_ATTACHMENTS_BTN_WIDTH) / attachmentWidth);
        if (elementsCount <= 0) {
          elementsCount = 1;
        }
        const limit = isActionItem ? Math.min(elementsCount, MAX_DISPLAY_ATTACHMENTS_LIMIT) : elementsCount;
        setDisplayAttachmentsLimit(limit);
      }, 300),
    [wrapperRef, isActionItem, srPanelCollapsed, ATTACHMENT_WIDTH],
  );

  useEffect(() => {
    setLimit();
  }, [setLimit]);

  useEffect(() => {
    window.addEventListener('resize', setLimit);
    return () => window.removeEventListener('resize', setLimit);
  }, [setLimit]);

  const journeyThumbnails = useMemo(
    () =>
      filesData?.map((file, index) => {
        const { key, attachmentId, subResourceType, fileName, extension, fileId } = file;
        return (
          <Thumbnail
            key={key}
            attachmentId={attachmentId}
            extendedFileName={fileName}
            extension={extension}
            fileId={fileId}
            onDisplayPreview={handleDisplayPreview}
            onAttachmentDeleted={handleDeleteAttachment}
            index={index}
            subResourceType={subResourceType}
          />
        );
      }),
    [filesData, handleDeleteAttachment, handleDisplayPreview],
  );

  const thumbnails = useMemo(() => {
    const sliceIndex = toggleMoreAttachments || hideButton ? attachmentsLength : displayAttachmentsLimit;

    return attachments
      ?.map((file, index) => {
        const {
          key,
          id,
          attachmentId,
          subResourceType,
          fileName,
          originalName,
          extension,
          src,
          userName,
          createdDate,
          attachmentUsageCount,
          fileId,
          isScreenCapture,
          screenCaptureType,
        } = file;
        return (
          <Thumbnail
            key={key || fileName}
            isTicketLocked={isTicketLocked}
            attachmentId={attachmentId || id}
            subResourceType={subResourceType}
            extendedFileName={fileName || originalName}
            extension={extension}
            username={userName}
            fileId={fileId}
            timestamp={getDate(timezone, dateFormat, createdDate)}
            fileUrl={src}
            onDisplayPreview={handleDisplayPreview}
            onAttachmentDeleted={handleDeleteAttachment}
            index={index}
            attachmentUsageCount={attachmentUsageCount}
            disabled={disabled}
            isScreenCapture={isScreenCapture}
            screenCaptureType={screenCaptureType}
            isActionItem={isActionItem}
            isHideDetails={isHideDetails}
          />
        );
      })
      ?.slice(0, sliceIndex);
  }, [
    attachments,
    attachmentsLength,
    displayAttachmentsLimit,
    toggleMoreAttachments,
    dateFormat,
    disabled,
    handleDeleteAttachment,
    handleDisplayPreview,
    hideButton,
    isActionItem,
    isHideDetails,
    isTicketLocked,
    timezone,
  ]);

  if (!attachments) {
    return null;
  }

  return (
    <>
      {displayThumbnail ? (
        thumbnails
      ) : (
        <AttachmentChipList // Description and Journey attachments
          attachments={toggleMoreAttachments ? uniqueAttachments : uniqueAttachments?.slice(0, displayAttachmentsLimit)}
          onAttachmentDeleted={onAttachmentDeleted}
          isReadOnly={isReadOnly}
          isDeleted={isDeleted}
          isDownloaded={isDownloaded}
          shouldTriggerDeleteApi={shouldTriggerDeleteApi}
          deleteAttachmentEnabled={deleteAttachmentEnabled}
          onDisplayPreview={handleDisplayPreview}
          journeyThumbnails={journeyThumbnails}
          isMinimizedSize={isMinimizedSize}
        />
      )}
      {showMoreAttachments && !toggleMoreAttachments && !hideButton && (
        <Button className="more-attachments-btn" onClick={() => setToggleMoreAttachments((prevToggle) => !prevToggle)}>
          +{moreAttachmentsSum}
        </Button>
      )}
    </>
  );
};

MoreAttachmentsWrapper.propTypes = {
  attachments: PropTypes.any,
  wrapperRef: PropTypes.elementType,
  deleteAttachmentEnabled: PropTypes.bool,
  shouldTriggerDeleteApi: PropTypes.bool,
  handleDisplayPreview: PropTypes.func,
  isReadOnly: PropTypes.bool,
  isDeleted: PropTypes.bool,
  isDownloaded: PropTypes.bool,
  onAttachmentDeleted: PropTypes.func,
  isMinimizedSize: PropTypes.bool,
};
