import { useCallback, useState } from 'react';

import { StyledCardContent } from '../styles';

import MeMenuTabContent from './meMenuTabContent';
import MeMenuNavBar from './meMenuNavBar';
import ProfileTabContent from './meMenuTabContent/ProfileTabContent';
import PasswordTabContent from './meMenuTabContent/PasswordTabContent';
import PreferencesTabContent from './meMenuTabContent/PreferencesTabContent';
import useTexts from '../useTexts';

export default function MeMenuBody({ settings, handleChange, handleBlur, handleTabChange }) {
  const { profileTabTitle, passwordTabTitle, preferencesTabTitle } = useTexts();
  const [currentTab, setCurrentTab] = useState(0);

  const tabs = [
    {
      title: profileTabTitle,
      content: ProfileTabContent,
    },
    {
      title: passwordTabTitle,
      content: PasswordTabContent,
    },
    {
      title: preferencesTabTitle,
      content: PreferencesTabContent,
    },
  ];

  const handleSetCurrentTab = useCallback(
    (data) => {
      setCurrentTab(data);
      handleTabChange(data);
    },
    [handleTabChange],
  );

  return (
    <StyledCardContent>
      <MeMenuNavBar tabs={tabs.map((tab) => tab.title)} currentTab={currentTab} setCurrentTab={handleSetCurrentTab} />
      <MeMenuTabContent>
        {tabs.map(
          ({ title, content: Content }, index) =>
            index === currentTab &&
            Content && <Content key={title} settings={settings} handleChange={handleChange} handleBlur={handleBlur} />,
        )}
      </MeMenuTabContent>
    </StyledCardContent>
  );
}
