import { useUserDetailsById } from 'remote-state/ticketServiceHooks';
import { DetailedAvatar } from 'common/components/avatars/detailedAvatar';
import useTexts from '../../../../useTexts';
import { actionEnums } from '../../constants/actionEnums';
import { StyledAuditLogEvent } from './style';

export const AuditLogEvent = ({ auditLog, srPanelCollapsed }) => {
  // const { openEvent, closeEvent, reopenEvent, viewEvent, theTicket } = useTexts();
  const { data: userDetailsById } = useUserDetailsById(auditLog.usernameId);
  const { via } = useTexts();

  // return the text of the action in past tense
  // const renderEventActionText = () => {
  //   switch (auditLog?.logType) {
  //     case actionEnums.OPERATION_CREATE:
  //       return ` ${openEvent}`;
  //     case actionEnums.EVENT_CLOSE:
  //       return ` ${closeEvent}`;
  //     case actionEnums.EVENT_REOPEN:
  //       return ` ${reopenEvent}`;
  //     case actionEnums.EVENT_VIEW:
  //       return ` ${viewEvent}`;
  //     default:
  //       return '';
  //   }
  // };
  return (
    <StyledAuditLogEvent
      srPanelCollapsed={srPanelCollapsed}
      logType={auditLog.logType}
      isLogTypeCreateTicket={auditLog?.logType === actionEnums.OPERATION_CREATE}
    >
      {auditLog?.logType !== actionEnums.EVENT_DUE_DATE ? (
        <div className="event-wrapper">
          <div className="detailed-avatar-wrapper">
            <DetailedAvatar
              userId={userDetailsById?.id}
              userName={auditLog?.username}
              previewName={auditLog?.username}
              profileImage={userDetailsById?.profileImage}
            />
          </div>
          <div className="event-action-wrapper">
            <span className="event-action-username">{auditLog?.username}</span>
            {auditLog.logInformation.source && <span className="event-action-source">{`${via} ${auditLog.logInformation.source}`}</span>}
            {/* <span className="event-action-text">
              {renderEventActionText()} {theTicket}
            </span> */}
          </div>
        </div>
      ) : (
        <div className="due-date-wrapper">
          <span className="due-date-text">{auditLog?.logInformation?.dueDate}</span>
          <div className="red-line" />
        </div>
      )}
    </StyledAuditLogEvent>
  );
};
