import { styled } from '@mui/material/styles';
import { ITEMS_DROPDOWN_LEFT_OFFSET } from 'features/resolutionPanel/middlePanel/relatedItems/constants';

export const HeaderLabel = styled('span')(({ theme }) => ({
  color: theme?.palette?.text?.title_txt,
  fontFamily: theme?.fonts?.robotoRegular,
  fontSize: theme?.fontSize?.small,
  fontWeight: 700,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

export const HeadersWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'start',
  overflow: 'auto!important',
  scrollbarWidth: 'none',
  gap: `${ITEMS_DROPDOWN_LEFT_OFFSET}px`,
}));
