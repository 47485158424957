import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from '@tanstack/react-router';
import { setError } from 'store/globalSlice';
import { APP_CONSTANTS } from 'constants/app';
import { ERROR_TYPES } from 'constants/common';
import { SR_CODE_TYPE_MAP, SR_PREVIEW_MODE_KEY } from 'features/TicketPanel/constants';
import { clearRichFields } from 'features/srPanel/templateFieldsGrid/richTextField/store/slice';
import { useTemplateData } from 'remote-state/templateHooks';
import { useUserInfo } from 'remote-state/userServiceHooks';
import { setPageValidations, selectIsPageValid } from 'store/pageValidationSlice';
import { PAGE_VALIDATION_CONSTANTS } from 'constants/index';
import { QUERIES_KEYS } from 'constant';
import { RICH_TEXT_FIELD_IDS } from 'features/srPanel/consts';
import { getRichTextValueByColumnName } from 'common/components/controls/RichTextEditor/utils';
import { hideNavBar } from 'features/loggedInRoutesPanel/slice';
import { CategoryProvider } from 'store/CategoryContext';
import { canEditTemplate } from './utils/utils';
import useTexts from './useTexts';
import TemplateBuilderHeader from './TemplateBuilderHeader';
import { StyledTemplateBuilder } from './StyledTemplateBuilder';
import TemplateBuilderPanel from './TemplateBuilderPanel';

function TemplateBuilderContainer() {
  const router = useRouter();
  const { exitWithoutSaveTitle, exitWithoutSaveText } = useTexts();
  const { data: userPermissions } = useUserInfo(QUERIES_KEYS.CURRENT_USER_PERMISSIONS);
  const dispatch = useDispatch();
  const templateId = router.latestLocation.search.id;
  const isFromPreview = router.latestLocation.search.previous === SR_PREVIEW_MODE_KEY;
  const previewTemplate = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.PREVIEW_TEMPLATE_OR_ID_KEY));
  const isExistingTemplate = !!templateId && templateId !== 'new';
  const srType = router.latestLocation.search.srType;
  const { data } = useTemplateData(isExistingTemplate ? templateId : srType);
  const [template, setTemplate] = useState(isFromPreview ? { ...previewTemplate } : {});
  const isPageValid = useSelector(selectIsPageValid);

  useEffect(() => {
    dispatch(hideNavBar(true));
    return () => {
      dispatch(hideNavBar(false));
    };
  }, [dispatch]);

  useEffect(() => {
    if (data?.sections?.length && !isFromPreview) {
      const sectionsOrder = {};
      let sections;
      //temp code for fixing the sections structure returned from the API
      if (!isExistingTemplate) {
        sections = data.sections.map((section, sectionIndex) => {
          sectionsOrder[sectionIndex + 1] = sectionIndex;
          return {
            ...section,
            fields: section.sectionRows?.flatMap((row) => row.fields),
            id: sectionIndex + 1,
          };
        });
        sections.sort((a, b) => a.order - b.order);
        setTemplate({ sectionsOrder, ...data, id: null, templateName: '', sections });
      } else {
        sections = data.sections.map((section, sectionIndex) => {
          sectionsOrder[sectionIndex + 1] = sectionIndex;
          const sectionFields = section.sectionRows.map((sectionRow) =>
            sectionRow.fields.map((field) => {
              const { fieldName, fieldId, defaultValue } = field;
              const isRichTextField = RICH_TEXT_FIELD_IDS.includes(fieldId);
              return {
                ...field,
                defaultValue:
                  isRichTextField && defaultValue
                    ? getRichTextValueByColumnName(defaultValue, fieldName)
                    : defaultValue,
              };
            }),
          );
          return {
            ...section,
            fields: sectionFields.flatMap((field) => field),
            id: sectionIndex + 1,
          };
        });
        sections.sort((a, b) => a.order - b.order);
        setTemplate({ sectionsOrder, ...data, sections });
      }
    }
  }, [data, isFromPreview, isExistingTemplate]);

  useEffect(() => {
    if (!canEditTemplate({ template, userPermissions })) {
      dispatch(setError(ERROR_TYPES.NO_VIEW_PERMISSIONS_TEMPLATE));
    }
  }, [template, userPermissions, dispatch]);

  const handleStateUpdate = useCallback(
    (updatedTemplateProperties) => {
      const newValues = {};
      updatedTemplateProperties.forEach((property) => {
        const currentKey = Object.keys(property)[0];
        newValues[currentKey] = property[currentKey];
      });
      setTemplate((prev) => ({ ...prev, ...newValues }));
      if (isPageValid) {
        dispatch(
          setPageValidations({
            pageKey: '/spaces/template',
            component: `${PAGE_VALIDATION_CONSTANTS.TEMPLATE}`,
            validationDetails: {
              isValid: false,
              promptTexts: {
                title: exitWithoutSaveTitle,
                description: exitWithoutSaveText,
              },
            },
          }),
        );
      }
    },
    [dispatch, exitWithoutSaveTitle, exitWithoutSaveText, isPageValid],
  );

  useEffect(() => {
    const cleanUp = () => {
      dispatch(clearRichFields());
    };
    return () => cleanUp();
  }, [dispatch]);

  const { sections, header, sectionsOrder } = template;

  const fieldsMap = useMemo(() => {
    if (sections?.length) {
      const map = new Map();
      sections.forEach((section) =>
        section.fields?.forEach((field) => {
          map.set(field.fieldId, field);
        }),
      );
      return map;
    }
  }, [sections]);

  return (
    <StyledTemplateBuilder>
      {sections?.length && (
        <>
          <TemplateBuilderHeader template={template} handleStateUpdate={handleStateUpdate} />
          <TemplateBuilderPanel
            templateId={templateId}
            template={template}
            header={header}
            sections={sections}
            sectionsOrder={sectionsOrder}
            handleStateUpdate={handleStateUpdate}
            fieldsMap={fieldsMap}
            srType={SR_CODE_TYPE_MAP[template?.srType]}
            dependantFields={template?.dependantFields}
          />
        </>
      )}
    </StyledTemplateBuilder>
  );
}

const TemplateBuilder = () => (
  <CategoryProvider>
    <TemplateBuilderContainer />
  </CategoryProvider>
);
export default TemplateBuilder;
