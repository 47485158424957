import { styled } from '@mui/material/styles';
import { ListItem } from '@mui/material';

export const StyledUserInfoCardTeamDetails = styled(ListItem, {
  shouldForwardProp: (prop) => !['isUserCard'].includes(prop)
})(({ theme, isUserCard }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingLeft: isUserCard ? theme.spacing(4) : theme.spacing(2),
  paddingRight: theme.spacing(2),

  '.MuiListItemAvatar-root': {
    minWidth: 0,
    alignSelf: 'flex-start',
    alignContent: 'center',

    '& .MuiBox-root': {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),

      '& .MuiAvatar-root': {
        height: isUserCard ? '16px' : '30px',
        width: isUserCard ? '16px' : '30px',

        '& svg': {
          height: isUserCard ? '16px' : '30px',
          width: isUserCard ? '16px' : '30px',
        },
      },
    },
  },

  '.MuiList-root': {
    minWidth: `calc(100% - 16px${isUserCard ? '' : ' - 16px'})`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  '.MuiListItemText': {
    '&-root': {
      margin: 0,
    },

    '&-primary .primary-text, &-secondary': {
      fontFamily: theme.fonts.robotoRegular,
      color: theme.palette.text.title_txt,
    },

    '&-primary .primary-text': {
      fontSize: isUserCard ? '0.75rem' : '1.25rem',
      fontWeight: isUserCard ? '700' : '400',
      color: 'black',
      lineHeight: 1.2,
      textAlign: 'start',
      paddingLeft: theme.spacing(1.5),
    },
  },

  '#user-info-card-team-details-text-secondary': {
    fontSize: isUserCard ? '0.75rem' : '0.825rem',
    textAlign: 'end',
  },

  '#user-info-card-team-details-content': {
    padding: 0,
  },
}));
