import { styled } from '@mui/styles';
import { Box } from '@mui/material';

export const StyledAssigneeButton = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'disabled' && prop !== 'hasError',
})(({ theme, disabled, hasError }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '4px',
  alignItems: 'center',
  cursor: 'pointer',
  width: '100%',
  border: 'none',
  outline: 'none',
  background: 'none',
  pointerEvents: disabled ? 'none' : 'auto',

  '&:hover': {
    '& .MuiAvatar-root': {
      filter: !hasError && 'brightness(0.85)',
    },
    '& .MuiTypography-root': {
      backgroundColor: !hasError ? theme.palette?.background.bg_hover_main : theme.palette?.labels.bt_red_mid,
      color: hasError && theme.palette.labels.bt_red_dark,
    },
  },

  '&& .MuiTypography-root': {
    fontSize: '0.875rem',
    fontFamily: theme.fonts.RobotoRegular,
    color: !hasError ? theme.palette.text.txt_default : theme.palette.labels.bt_red_hover,
    lineHeight: '20px',
    borderRadius: '4px',
    padding: !hasError ? '4px 6px' : '0px 6px',
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));
