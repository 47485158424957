import { useSelector } from 'react-redux';
import { CONSTANTS, categoriesFieldName } from 'features/templateBuilder/constants';
import { useRbTexts } from 'remote-state/applicationHooks';
import useTexts from 'features/templateBuilder/useTexts';
import { selectActiveComponent } from 'features/templateBuilder/activeComponentSlice';
import { getAllFormFields } from '../utils';

const useComponentName = (header, sections) => {
  const { fieldPropertiesTitle, sectionPropertiesTitle, propertiesPanelDefaultTitle } = useTexts();
  const { componentId, componentType } = useSelector(selectActiveComponent);
  const texts = useRbTexts();
  const allFormFields = getAllFormFields(header, sections);

  const getFieldName = () => {
    let fieldName = allFormFields.find((field) => field.fieldId === componentId)?.fieldName;
    fieldName = categoriesFieldName[fieldName] ? 'category' : fieldName;
    return texts[`spaces.template.fields.${fieldName}`];
  };

  const getSectionName = () => {
    const selectedSection = sections?.find((section) => (section?.sectionId || section?.id) === componentId);
    return texts[selectedSection?.name] || selectedSection?.name;
  };

  const isFieldSelected = () => {
    if (componentType === CONSTANTS.FIELDS || componentType === CONSTANTS.HEADER) {
      return true;
    }
    return false;
  };

  const isSectionSelected = () => {
    if (componentType === CONSTANTS.SECTION && componentId !== CONSTANTS.SECTION_INSTRUCTIONS) {
      return true;
    }
    return false;
  };

  const getPropertyTitle = () => {
    const isField = isFieldSelected();
    const isSection = isSectionSelected();
    if (isField && !isSection) {
      return fieldPropertiesTitle;
    }
    if (!isField && isSection) {
      return sectionPropertiesTitle;
    }
    return propertiesPanelDefaultTitle;
  };

  const getPropertyName = () => {
    const isField = isFieldSelected();
    const isSection = isSectionSelected();
    if (isField && !isSection) {
      return getFieldName();
    }
    if (!isField && isSection) {
      return getSectionName();
    }
    return '';
  };

  return { getPropertyTitle, getPropertyName };
};

export default useComponentName;
