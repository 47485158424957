import { TextField, RadioGroup } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => !['value'].includes(prop),
})(({ theme, value }) => ({
  marginTop: '20px',
  border: 'none',
  fieldset: {
    display: 'none',
  },
  '&.MuiFormControl-root': {
    backgroundColor: value ? 'transparent' : theme?.palette?.background?.bg_new_input_text,
    borderRadius: theme?.shape?.border?.borderRadius,
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&:hover': {
      backgroundColor: theme?.palette?.background?.bg_hover_main,
    },
    '& .MuiInputBase-input': {
      fontSize: theme?.fontSize?.main,
      lineHeight: 1.4286,
      color: theme?.palette?.text?.txt_default,
      fontFamily: theme?.fonts?.robotoRegular,
      '&:focus::placeholder': {
        color: 'transparent',
      },
    },
  },
}));

export const StyledRadioGroup = styled(
  RadioGroup,
  {},
)(({ theme }) => ({
  fontSize: theme?.fontSize?.small,
  '& .MuiTypography-root': {
    fontSize: theme?.fontSize?.main,
    lineHeight: 1.4286,
    color: theme?.palette?.text?.txt_default,
    fontFamily: theme?.fonts?.robotoRegular,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 19,
  },
}));
