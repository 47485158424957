import { useSelector } from 'react-redux';
import { useRouter } from '@tanstack/react-router';
import { useHandleTicketLock } from 'remote-state/ticketServiceHooks';
import { activeUsername } from 'store/userSlice';
import { InputLabel } from '@mui/material';
import PropTypes from 'prop-types';
import { ReactComponent as AttachIcon } from '../../../../../images/icons/attachment.svg';
import { FileInput } from '../../../../../common/components/uploadFile/uploadButton/fileInput';
import useTexts from '../../../../LoginSession/useTexts';

export function AddAttachmentText({ handleUploadNewAttachment, handleUploadMultipleAttachments }) {
  const username = useSelector(activeUsername);
  const router = useRouter();
  const srId = router.latestLocation?.search?.id;

  const { mutate: lockSR } = useHandleTicketLock(srId);
  const { chooseFileText, dropText } = useTexts();
  const handleChange = ({ target }) => {
    const files = Object.values(target.files);
    if (files.length > 1) {
      handleUploadMultipleAttachments(files);
    } else {
      handleUploadNewAttachment(files[0]);
    }
    target.value = '';
  };

  const lockTicket = () => {
    lockSR({ username, srId, queueLocking: false });
  };

  return (
    <InputLabel>
      <FileInput handleChange={handleChange} handleClick={lockTicket} />
      <div className="text-wrapper">
        <AttachIcon />
        <span>
          <u>{chooseFileText}</u>
          {dropText}
        </span>
      </div>
    </InputLabel>
  );
}

AddAttachmentText.propTypes = {
  handleUploadNewAttachment: PropTypes.func,
  handleUploadMultipleAttachments: PropTypes.func,
};
