import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { Box } from '@mui/material';

export const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 12,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(12px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    color: theme?.palette?.generic?.primary,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: theme?.palette?.generic?.primary,
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette?.switchToggle?.checked,
      },
    },
    '&.Mui-disabled': {
      color: theme?.palette?.generic?.primary,
    },
  },
  '& .MuiSwitch-thumb': {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  '& .MuiSwitch-track': {
    borderRadius: 10,
    backgroundColor: theme.palette?.switchToggle?.unchecked,
    opacity: 1,
  },
  '+ span': {
    marginLeft: '8px',
  },
}));

export const StyledShareSwitch = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '24px',
  '&& .Mui-disabled': {
    '+ .MuiSwitch-track': {
      backgroundColor: `${theme.palette?.switchToggle?.disabled} !important`,
      opacity: 1,
    },
  },
}));

export const StyledSwitchLabel = styled('span')(({ theme }) => ({
  color: theme.palette?.text.secondary_txt,
  fontSize: theme.fontSize?.main,
}));
