import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setToasterMessage } from 'store/globalSlice';
import { FEATURE_FLAGS_KEYS } from 'constants/featureFlags';
import { PUBLIC_VISIBILITY_VALUE } from 'common/components/queueViews/saveViewAsModal/constants';
import { DropDownMenu } from 'common/components/dropDownMenu';
import ExternalModal from 'features/externalModal';
import { ReactComponent as DetailsIcon } from 'images/icons/details.svg';
import { ReactComponent as HomeIcon } from 'images/icons/home.svg';
import { ReactComponent as TrashIcon } from 'images/icons/trash.svg';
import { ReactComponent as OpenClassicIcon } from 'images/icons/open-classic.svg';
import { details } from 'store/userSlice';
import { useSetViewAsDefault } from 'remote-state/queueViewsHooks';
import { useUserInfo } from 'remote-state/userServiceHooks';
import { useFeatureFlagQuery } from 'remote-state/featureFlagsHooks';
import { QUERIES_KEYS } from 'constant';
import usePendoTrackEvents from 'common/utils/hooks/usePendoTrackEvents';
import useTexts from '../../useTexts';
import { selectActiveView, selectDefaultView } from '../../../slice';
import { StyledActionButton, StyledActionWrapper, StyledDivider, StyledMenuItem } from './style';
import { useShowLinkToClassic } from '../../../../../remote-state/accountSettingsServiceHooks';
import { removeCookie, setCookie } from '../../../../../common/utils/utils';
import { APP_CONSTANTS, PENDO_TRACK_EVENTS } from '../../../../../constants';

export const ViewListAction = ({ setIsDeleteModalOpen, setIsDetailsPopUpOpen }) => {
  const dispatch = useDispatch();

  const { mutate: setViewAsDefault } = useSetViewAsDefault();
  const { data: userData } = useUserInfo(QUERIES_KEYS.CURRENT_USER_FROM_CORE);
  const { data: sysaidUser } = useUserInfo(QUERIES_KEYS.USER_DATA);

  const { data: isProjectOneEnabled } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.PROJECT_ONE_ENABLED,
    defaultValue: false,
  });
  const showLinkToClassic = useShowLinkToClassic();
  const pendoTrackEvents = usePendoTrackEvents();
  const [menuOpen, setMenuOpen] = useState(false);
  const [classicIsOpened, setClassicIsOpened] = useState(false);
  const url = '/HelpDesk.jsp?fromId=List&showFirstPage=true';

  const openClassicQueue = () => {
    pendoTrackEvents(PENDO_TRACK_EVENTS.open_classic_queue_click);
    setCookie(APP_CONSTANTS.IN_SPACES_IFRAME_COOKIE, 'true');
    setClassicIsOpened(true);
  };

  const closeClassicQueue = () => {
    removeCookie(APP_CONSTANTS.IN_SPACES_IFRAME_COOKIE);
    setClassicIsOpened(false);
  };

  const { queueViewsMessageSetDefaultSuccess, viewDetails, setAsDefault, deleteView, launchClassicQueue } = useTexts();

  const activeView = useSelector(selectActiveView);
  const user = useSelector(details);
  const defaultView = useSelector(selectDefaultView);
  const isPublicView = activeView?.visibility === PUBLIC_VISIBILITY_VALUE;
  const isSuperAdmin = user?.isAdmin === 'Y';
  const isSysAidAdmin = userData?.isSysAidAdmin;
  const isDefaultView = defaultView?.id === activeView?.id && defaultView?.default;
  const isDeleteViewDisabled = isDefaultView ? true : !isSuperAdmin;
  const handleOpenMenu = useCallback((val) => {
    setMenuOpen(val);
  }, []);

  const handleOpenDetails = useCallback(() => {
    setIsDetailsPopUpOpen(true);
    handleOpenMenu(false);
  }, [setIsDetailsPopUpOpen, handleOpenMenu]);

  const handleDeleteView = useCallback(() => {
    setIsDeleteModalOpen(true);
    handleOpenMenu(false);
  }, [setIsDeleteModalOpen, handleOpenMenu]);

  const handleSetAsDefaultSelect = useCallback(() => {
    setViewAsDefault(
      { userName: sysaidUser?.userName, queueViewId: activeView?.id },
      { onSuccess: () => dispatch(setToasterMessage({ message: queueViewsMessageSetDefaultSuccess })) },
    );

    handleOpenMenu(false);
  }, [
    dispatch,
    setViewAsDefault,
    handleOpenMenu,
    queueViewsMessageSetDefaultSuccess,
    sysaidUser?.userName,
    activeView?.id,
  ]);

  const viewListActions = [
    {
      text: viewDetails,
      svg: <DetailsIcon />,
      isDisable: isPublicView && !isSysAidAdmin,
      handleClick: handleOpenDetails,
    },
    {
      text: setAsDefault,
      svg: <HomeIcon />,
      isDisable: isDefaultView,
      handleClick: handleSetAsDefaultSelect,
    },
    {
      text: deleteView,
      svg: <TrashIcon />,
      isDisable: isDeleteViewDisabled || (isPublicView && !isSysAidAdmin),
      handleClick: handleDeleteView,
    },
  ];

  if (showLinkToClassic) {
    viewListActions.push({
      text: launchClassicQueue,
      svg: <OpenClassicIcon />,
      isDisable: false,
      isWithDivider: true,
      handleClick: openClassicQueue,
    });
  }

  return (
    <div className="list-actions">
      <DropDownMenu
        handleOpen={handleOpenMenu}
        open={menuOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {!!viewListActions?.length && (
          <StyledActionWrapper>
            {viewListActions.map(({ svg, text, isDisable, handleClick, isWithDivider }, idx) => (
              <>
                {isWithDivider && <StyledDivider />}
                <StyledMenuItem key={idx} className="view-list-item">
                  <StyledActionButton
                    fullWidth
                    startIcon={svg}
                    shouldhover={false}
                    disabled={isDisable}
                    onClick={handleClick}
                    data-testid={`menu-item-${idx}`}
                  >
                    {text}
                  </StyledActionButton>
                </StyledMenuItem>
              </>
            ))}
          </StyledActionWrapper>
        )}
      </DropDownMenu>
      {isProjectOneEnabled && <ExternalModal url={url} open={classicIsOpened} onClose={closeClassicQueue} />}
    </div>
  );
};
