const FETCH_SIZE = 50;
const FETCH_MORE_OFFSET = 300;

const COLUMN_FILTER_FIELDTYPE = {
  text: 'text',
  number: 'number',
  set: 'set',
  date: 'date',
};

const FIELD_TYPE = {
  srType: 'srType',
  user: 'user',
  requestUser: 'requestUser',
  category: 'category',
  assignee: 'assignee',
};

const CHIP_GAP = 16;
const CHIP_MAX_WIDTH = 200;

export { FETCH_SIZE, FETCH_MORE_OFFSET, COLUMN_FILTER_FIELDTYPE, FIELD_TYPE, CHIP_GAP, CHIP_MAX_WIDTH };
