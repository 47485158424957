import { useState, useRef } from 'react';
import { IconButton, Box, ClickAwayListener } from '@mui/material';

import { ReactComponent as DefaultSettingButton } from 'images/icons/menu-small-icon.svg';
import Checkbox from 'common/components/checkbox';
import { useDispatch } from 'react-redux';
import { StyledActions, StyledMenu, StyledMenuItem, StyledTitle } from './Styled';
import { updateActionLine } from '../../store/slice';

const DefaultChecker = ({ checked, onChange, title, editorClassName }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isChecked, setIsChecked] = useState(checked);
  const defaultSettingBtnRef = useRef(null);
  const dispatch = useDispatch();

  const handleChangeIsChangeProgress = (isEditorChangeProgress) => {
    dispatch(updateActionLine({ isEditorChangeProgress }));
  };

  const handleChange = ({ target }) => {
    const status = target.checked;
    setIsChecked(status);
    onChange(status);
    handleChangeIsChangeProgress(false);
  };

  const handleOpenMenu = () => {
    setAnchorEl(defaultSettingBtnRef.current);
    handleChangeIsChangeProgress(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    handleChangeIsChangeProgress(false);
  };

  return (
    <StyledActions
      className={editorClassName}
      isActive={Boolean(anchorEl)}
      data-testid="open-actions-pop-up"
      data-cy="open-actions-pop-up"
    >
      <ClickAwayListener onClickAway={handleCloseMenu}>
        <Box className={editorClassName}>
          <IconButton
            data-testid="set-default-share-button"
            data-cy="set-default-share-button"
            onClick={handleOpenMenu}
            ref={defaultSettingBtnRef}
            className={editorClassName}
          >
            <DefaultSettingButton />
          </IconButton>
          <StyledMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
            <StyledMenuItem>
              <Checkbox
                checked={isChecked}
                onChange={handleChange}
                inputProps={{
                  'data-cy': 'set-default-share-with-RU',
                  'data-testid': 'set-default-share-with-RU',
                }}
              />
              <StyledTitle> {title}</StyledTitle>
            </StyledMenuItem>
          </StyledMenu>
        </Box>
      </ClickAwayListener>
    </StyledActions>
  );
};
export default DefaultChecker;
