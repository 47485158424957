import { useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from '@tanstack/react-router';
import { selectActiveUser } from 'store/userSlice';
import { AttachmentMetadata, ResourceType, SubResourceType } from 'services/attachments';
import { FEATURE_FLAGS_KEYS } from 'constants/featureFlags';
import { useFeatureFlagQuery } from 'remote-state/featureFlagsHooks';
import { useGetAttachmentQuery, useNewAttachment, useV2NewAttachment, useV2NewAttachments } from './useAttachments';
import { DUMMY_ATTACHMENT_ID } from './constants';

export const SERVICE_REQUEST_SOURCE = 'service_request';

export const useTicketAttachments = () => {
  const router = useRouter();
  const queryKey = 'srAttachments';
  const srId = router.latestLocation.search.id;
  const queryClient = useQueryClient();
  const userAccount = useSelector(selectActiveUser);
  const srAttachments = useGetAttachmentQuery({
    queryKey,
    resourceId: srId,
    resourceType: ResourceType.SR,
    distinct: true,
  });
  const { mutateAsync: createV2NewAttachment } = useV2NewAttachment(srId);
  const { mutateAsync: createV2NewAttachments } = useV2NewAttachments();
  const { mutateAsync: createNewAttachment } = useNewAttachment();
  const { data: isAttachmentSizeLimited } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.ATTACHMENT_SIZE_LIMITATION,
  });

  const updateSRAttachments = (data) => {
    const srAttachmentIndex = srAttachments.data.findIndex(
      ({ attachment }) => data.metadata.subResourceType === SubResourceType.ACTION_ITEM ?
        attachment.metadata.originalName === data.metadata.originalName &&
        attachment.metadata.subResourceId === data.metadata.subResourceId :
        attachment.metadata.originalName === data.metadata.originalName,
    );
    if (srAttachmentIndex !== -1) {
      queryClient.setQueryData([queryKey, srId], (data) => {
        const srAttachments = [...data];
        srAttachments[srAttachmentIndex].count++;
        return srAttachments;
      });
    } else {
      queryClient.setQueryData([queryKey, srId], (oldData) => [...oldData, { attachment: { ...data }, count: 1 }]);
    }
  };

  const updateMultipleSrAttachments = (data) => {
    data.forEach(attachmentData => {
      const srAttachmentIndex = srAttachments?.data?.findIndex(
        ({ attachment }) => attachment.metadata.originalName === attachmentData.metadata.originalName,
      );
      if (srAttachmentIndex !== -1) {
        queryClient.setQueryData([queryKey, srId], (data) => {
          const srAttachments = [...data];
          srAttachments[srAttachmentIndex].count++;
          return srAttachments;
        });
      } else {
        queryClient.setQueryData([queryKey, srId], (oldData) => [...oldData, { attachment: { ...attachmentData }, count: 1 }]);
      }
    })
  }

  const removeSrAttachment = (oldData, attachmentId, fileId) =>
    oldData.filter(({ attachment }) =>
      attachmentId === DUMMY_ATTACHMENT_ID
        ? attachment.metadata.fileId !== fileId
        : attachment.metadata.id !== attachmentId,
    );

  const updateSRAttachmentsAfterDelete = (attachmentId, fileId) => {
    queryClient.setQueryData([queryKey, srId], (oldData) => removeSrAttachment(oldData, attachmentId, fileId));
  };

  const invalidateSRAttachments = () => {
    queryClient.invalidateQueries({ queryKey: [queryKey] });
  };

  const createSrAttachment = async ({
    file,
    subResourceType,
    subResourceId = 0,
    visibility = true,
    onProgressChange,
    resourceType,
    resourceId,
    inline = false,
  }) => {
    const metadata = new AttachmentMetadata({
      source: SERVICE_REQUEST_SOURCE,
      userName: userAccount?.username,
      resourceId: resourceId || resourceId === 0 ? resourceId : srId,
      resourceType: resourceType || ResourceType.SR,
      subResourceId,
      subResourceType,
      visibility,
    });
    let createNewAttachmentApi;
    if (isAttachmentSizeLimited && !inline) {
      createNewAttachmentApi = createV2NewAttachment;
    } else {
      createNewAttachmentApi = createNewAttachment;
    }

    const data = await createNewAttachmentApi({
      payload: file,
      metadata,
      options: { onUploadProgress: onProgressChange },
    });
    return data;
  };

  const createSrMultipleAttachments = async ({
    files,
    subResourceType,
    subResourceId = 0,
    visibility = true,
    onProgressChange,
    resourceType,
    resourceId
  }) => {
    const metadata = new AttachmentMetadata({
      source: SERVICE_REQUEST_SOURCE,
      userName: userAccount?.username,
      resourceId: resourceId || resourceId === 0 ? resourceId : srId,
      resourceType: resourceType || ResourceType.SR,
      subResourceId,
      subResourceType,
      visibility,
    });

    const data = await createV2NewAttachments(
      {
        payload: files,
        metadata,
        options: { onUploadProgress: onProgressChange },
      }
    );
    return data;
  }

  return {
    srAttachments,
    createSrAttachment,
    createV2NewAttachment,
    createSrMultipleAttachments,
    updateSRAttachments,
    updateMultipleSrAttachments,
    updateSRAttachmentsAfterDelete,
    invalidateSRAttachments,
  };
};
