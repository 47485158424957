import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledMiddlePanel = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isRelatedItemsTab',
})(({ isRelatedItemsTab }) => ({
  display: 'flex',
  lineHeight: '20px',
  paddingLeft: '24px',
  paddingRight: '23px',
  marginRight: '0px',
  marginInlineEnd: '7px',
  height: isRelatedItemsTab && '100%',
}));
