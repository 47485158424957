import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    signInText: texts?.['spaces.login.signIn'],
    forgotPasswordText: texts?.['spaces.login.forgotPassword'],
    fillFieldsText: texts?.['spaces.login.fillFields'],
    passwordWillBeSentText: texts?.['spaces.login.passwordWillBeSent'],
    passwordSentText: texts?.['spaces.login.passwordSent'],
    submitText: texts?.['spaces.login.submit'],
    loginText: texts?.['spaces.login.login'],
    usernameText: texts?.['spaces.login.username'],
    passwordText: texts?.['spaces.login.password'],
    emailText: texts?.['spaces.login.email'],
    domainText: texts?.['spaces.login.domain'],
    chooseFileText: texts?.['spaces.attachments.choose'],
    dropText: texts?.['spaces.attachments.drop'],
    localUserTexts: texts?.['spaces.login.localUser'],
    isDomainFieldText: texts?.['spaces.login.isDomainField'],
    selectDomainText: texts?.['spaces.login.selectDomain'],
    rememberMeText: texts?.['spaces.login.rememberMe'],
    emailErrorText: texts?.['spaces.login.emailError'],
    requiredText: texts?.['spaces.login.required'],
    maxCharactersLimitReachedText: texts?.['spaces.login.maxCharactersLimitReached'],
    wrongUsernameText: texts?.['spaces.login.wrongUsername'],
    wrongPasswordText: texts?.['spaces.login.wrongPassword'],
    wrongDomainText: texts?.['spaces.login.wrongDomain'],
    checkMailText: texts?.['spaces.login.checkMail'],
    returnToSignInText: texts?.['spaces.login.returnToSignIn'],
    textText: texts?.['spaces.login.text'],
    loginLockoutMessage: texts?.['spaces.login.isOverFailedLoginsLimit'],
    loginAsGuest: texts?.['spaces.login.loginAsguest'],
    noUser: texts?.['spaces.login.noUser'],
    signUp: texts?.['spaces.login.signUp'],
    resetPassword: texts?.['spaces.login.resetPassword'],
    unlockAccount: texts?.['spaces.login.unlockAccount'],
    sysaid: texts?.['spaces.title'],
    itsmLink: texts?.['spaces.authentication.ITSMLink'],
    backToLogin: texts?.['spaces.login.backToLogin'],
    enterUsername: texts?.['spaces.login.enterUsername'],
    enterPassword: texts?.['spaces.login.enterPassword'],
    enterEmail: texts?.['spaces.login.enterEmail'],
    orText: texts?.['spaces.general.or'],
  };

  return componentTexts;
}
