import { memo, useContext, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import ConfigBody from './ConfigBody';
import useTexts from './useTexts';
import { selectColumnsOrder } from '../../slice';
import { COLUMN_MAX_LIMIT, coupledColumsInfo } from './constants';
import { StyledDrawer, StyledDrawerHead } from './styledDrawer';
import { ConfigOpenContext, ColItemsContext } from './context';
import { selectDirection } from '../../../../store/globalSlice';

const convertColumOrdersToSortItems = (columnsOrder, getColumnText) => {
  const coupledColums = coupledColumsInfo.childToParent;
  const items = Object.values(
    columnsOrder.reduce((result, item) => {
      const parentInfo = coupledColums[item.id];
      if (parentInfo) {
        const parentIdKey = `id${parentInfo}`;
        result[parentIdKey] = {
          ...result[parentIdKey],
          children: [...(result[parentIdKey]?.children ?? []), item],
        };
      } else {
        result[`id${item.id}`] = { ...item, sortableName: getColumnText(coupledColumsInfo.parentNames[item.id]) ?? item.displayName };
      }
      return result;
    }, {}),
  );
  return items;
};

const ConfigHead = memo(() => {
  const { headTitle, subtitle } = useTexts();
  const { setOpen } = useContext(ConfigOpenContext);
  const { items } = useContext(ColItemsContext);
  const isMaxLimitReached = items.length >= COLUMN_MAX_LIMIT;

  return (
    <StyledDrawerHead>
      <Box className="headLeft">
        <Box className="title">
          <span data-testid="cc-title" className="text">
            {headTitle || 'Column Configuration'}
          </span>
          <span className={`stat ${isMaxLimitReached && 'error'}`}>
            {items.length}/{COLUMN_MAX_LIMIT}
          </span>
        </Box>
        <Box className="subtitle">{subtitle}</Box>
      </Box>
      <Box className="headRight">
        <CloseIcon onClick={() => setOpen(false)} className="icon" />
      </Box>
    </StyledDrawerHead>
  );
});

const ConfigurationContainer = () => {
  const { getColumnText } = useTexts();
  const columnsOrder = useSelector(selectColumnsOrder);
  const [items, setItems] = useState(() => convertColumOrdersToSortItems(columnsOrder, getColumnText));
  const value = useMemo(() => ({ items, setItems }), [items]);
  return (
    <ColItemsContext.Provider value={value}>
      {useMemo(
        () => (
          <>
            <ConfigHead />
            <ConfigBody />
          </>
        ),
        [],
      )}
    </ColItemsContext.Provider>
  );
};

const ColumnsConfiguration = () => {
  const { open, setOpen } = useContext(ConfigOpenContext);
  const direction = useSelector(selectDirection);
  const anchor = direction === 'rtl' ? 'left' : 'right';
  return (
    <StyledDrawer
      anchor={anchor}
      elevation={0}
      open={Boolean(open) || false}
      onClose={() => {
        setOpen(false);
      }}
    >
      <ConfigurationContainer />
    </StyledDrawer>
  );
};

export default memo(ColumnsConfiguration);
