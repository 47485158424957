import { useRbTexts } from 'remote-state/applicationHooks';
import { formatTranslation } from '../../../../common/utils/translation';

export default function useTexts(srId) {
  const texts = useRbTexts();
  const componentTexts = {
    gotItText: texts?.['spaces.header.actions.gotIt'],
    deleteTicketText: texts?.['spaces.header.actions.deleteTicket'],
    deleteTicketCancelText: texts?.['spaces.header.actions.deleteTicket.cancel'],
    deleteTicketErrorText: texts?.['spaces.header.actions.deleteTicket.error'],
    deleteTicketErrorDescriptionText: () =>
      formatTranslation(texts?.['spaces.header.actions.deleteTicket.errorDescription'], [
        { placeHolder: '{srId}', value: srId },
      ]),
    deleteTicketMessageText: () =>
      formatTranslation(texts?.['spaces.header.actions.deleteTicket.wasDeletedMessage'], [
        { placeHolder: '{srId}', value: srId },
      ]),
    deleteTicketWarningMessageText: () =>
      formatTranslation(texts?.['spaces.header.actions.deleteTicket.warningMessage'], [
        { placeHolder: '{srId}', value: srId },
      ]),
    deleteTicketWarningDescriptionText: () =>
      formatTranslation(texts?.['spaces.header.actions.deleteTicket.warningDescription'], [
        { placeHolder: '{srId}', value: srId },
      ]),
    deleteTicketButtonText: texts?.['spaces.header.actions.deleteTicket.button'],
  };

  return componentTexts;
}
