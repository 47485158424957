import { styled } from '@mui/material/styles';
import { ListItem } from '@mui/material';

export const StyledUserInfoCardHeader = styled(ListItem)(({ theme }) => ({
  '.MuiListItemAvatar-root': {
    paddingBlock: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1.5),
    '& .MuiBox-root': {
      padding: theme.spacing(0.75),
    },
  },

  '.user-info-card-header-user-data': {
    maxWidth: '65%'
  },

  '.user-primary-text': {
    fontSize: '1.25rem',
    lineHeight: 1.2,
    paddingRight: theme.spacing(3),
    overflow: 'hidden',
    textAlign: 'left',
  },

  '.email-text': {
    textOverflow: 'ellipsis',
  },

  '.email-text:hover': {
    cursor: 'pointer'
  }
}));
