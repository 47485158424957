import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledMainTicketPanel = styled(Box, {
  shouldForwardProp: (prop) => !['isNavBarCollapsed'].includes(prop),
})(({ theme, isNavBarCollapsed }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  [theme.breakpoints.up(isNavBarCollapsed ? 1024 : 1210)]: {
    flexDirection: 'row',
  },
}));
