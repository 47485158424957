import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsSectionsExpanded, selectIsSectionsExpanded } from 'store/srSlice';
import { Accordion, Typography } from '@mui/material';
import { ReactComponent as AccordionToggleButton } from 'images/icons/accordionToggleButton.svg';
import useGetFieldPermissions from 'common/utils/hooks/useGetFieldPermissions';
import { StyledAccordionSummary, StyledAccordionDetails, StyledHeader, StyledInstructions } from './style';

export default function Section(props) {
  const dispatch = useDispatch();
  const { sectionTitle, instructions, children, onExpansion, initExpanded, section } = props;
  const [accordionExpanded, setAccordionExpanded] = useState(initExpanded);
  const isSectionsExpanded = useSelector(selectIsSectionsExpanded);
  const getSectionPermissionsRules = useGetFieldPermissions(section);

  useEffect(() => {
    setAccordionExpanded(initExpanded);
  }, [initExpanded]);

  useEffect(() => {
    if (isSectionsExpanded) {
      setAccordionExpanded(isSectionsExpanded);
      dispatch(setIsSectionsExpanded(null));
    }
  }, [dispatch, isSectionsExpanded]);

  const toggleExpanded = () => {
    if (onExpansion) {
      onExpansion(!accordionExpanded);
    }
    setAccordionExpanded((prevState) => !prevState);
  };

  if (getSectionPermissionsRules.disabled) {
    return null;
  }
  return (
    <Accordion
      id={props.id}
      expanded={accordionExpanded}
      disableGutters
      onChange={toggleExpanded}
      elevation={0}
      sx={{
        '&:before': {
          display: 'none',
        },
      }}
    >
      <StyledAccordionSummary expandIcon={<AccordionToggleButton className="expandIcon" />}>
        <Typography component="span">
          <StyledHeader className="section">{sectionTitle}</StyledHeader>
          {accordionExpanded && instructions && <StyledInstructions>{instructions}</StyledInstructions>}
        </Typography>
      </StyledAccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </Accordion>
  );
}
