import { useEffect, useState } from 'react';
import { useRouter } from '@tanstack/react-router';
import { APP_CONSTANTS, ROUTES_PATHS } from 'constants/index';
import { StyledExternalPagesWrapper } from './styles';
import GoBack from './go-back/index';
import IframeWrapper from './iframe-wrapper';
import TabsHeader from './tabs-header';
import { removeCookie, setCookie } from '../../common/utils/utils';

export const getResourceInfo = () => {
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);

  const decodedUrl = decodeURIComponent(queryString);
  const parts = decodedUrl.split('?');
  const jspParams = new URLSearchParams(parts[parts.length - 1]);
  const isSettingsPage = jspParams.get('showBack');
  const settingIds = JSON.parse(jspParams.get('settingIds'));
  const openedInIframe = JSON.parse(jspParams.get('openedInIframe'));
  const path = openedInIframe !== null ? `${params.get('url')}&openedInIframe=${openedInIframe}` : params.get('url');

  return {
    isSettingsPage,
    settingIds,
    path,
  };
};

export default function ExternalPagePanel() {
  const router = useRouter();
  const [resourceInfo, setResourceInfo] = useState(getResourceInfo());
  const show = Boolean(Number(resourceInfo.isSettingsPage));

  useEffect(() => {
    setCookie(APP_CONSTANTS.IN_SPACES_IFRAME_COOKIE, 'true');
    return () => {
      removeCookie(APP_CONSTANTS.IN_SPACES_IFRAME_COOKIE);
    };
  }, []);

  useEffect(() => {
    router.subscribe('onLoad', () => {
      setResourceInfo(getResourceInfo());
    })
  }, [router]);

  return (
    <StyledExternalPagesWrapper>
      <GoBack onClick={() => router.navigate({ to: ROUTES_PATHS.SETTINGS_URL })} show={show} />
      {show && <TabsHeader settingIds={resourceInfo.settingIds} />}
      <IframeWrapper url={resourceInfo.path} />
    </StyledExternalPagesWrapper>
  );
}
