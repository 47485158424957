import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    errorDetailsText: texts?.['spaces.errorDetails'],
    failureDetailsHeader: texts?.['spaces.failureDetails.header'] || 'Failure details',
    failureDetailsTitle: texts?.['spaces.failureDetails.title'] || 'The following items return an error',
    lockingError: texts?.['spaces.field.error.lockingError'] || 'Locking error',
    permissionError: texts?.['spaces.field.error.permissionError'] || 'Permission error',
    systemError: texts?.['spaces.field.error.systemError'] || 'System error',
    limitError: texts?.['spaces.field.error.limitError'] || 'Limit error',
    incident: texts?.['spaces.srType.incident'],
    request: texts?.['spaces.srType.request'],
    problem: texts?.['spaces.srType.problem'],
    change: texts?.['spaces.srType.change'],
  };

  return componentTexts;
}
