// trim form values of type string
export const trimFormValues = (formValues: Record<string, unknown>): Record<string, unknown> => {
  Object.keys(formValues).map((fieldName) => {
    if (typeof formValues[fieldName] === 'string') {
      formValues[fieldName] = (formValues[fieldName] as string).trim();
    }
    return formValues[fieldName];
  });
  return formValues;
};

export const capitalizeFirstLetter = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1);
