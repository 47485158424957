import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    searchStatusText: texts?.['spaces.status.dropdown.searchStatus'],
    emptyStatusPlaceholder: texts?.['spaces.select.status.empty.placeholder'] || 'Select Status',
    statusRequiredFieldsTooltipMessage: texts?.['spaces.status.required.fields.toaster-message'],
    mandatoryFieldError: texts?.['spaces.field.tooltip.error.mandatoryFieldError'] || 'Missing required field',
    unchangedStatusTitle:
      texts?.['spaces.relatedItems.popup.unchangedStatus.title'] || 'Cannot change Service Record status',
    unchangedStatusDecription:
      texts?.['spaces.relatedItems.popup.unchangedStatus.description'] ||
      'This Service Record inherits its status from the Parent Service Record #__srId__. Updating the status of the Parent will update the status of all the Child Service Records',
    unchangedStatusCancelBtn: texts?.['spaces.relatedItems.popup.unchangedStatus.okBtn'] || 'Got it',
    unchangedStatusOkBtn: texts?.['spaces.relatedItems.popup.unchangedStatus.cancelBtn'] || 'Go to Parent',
    parentStatusChangeTitle:
      texts?.['spaces.relatedItems.popup.parentStatusChange.title'] ||
      'Update the status of the Parent Service Record?',
    parentStatusChangeDescription:
      texts?.['spaces.relatedItems.popup.parentStatusChange.description'] ||
      'This Parent Service Record has __childSrCount__ Child Service Records that will inherent its status.',
    parentStatusChangeOkBtn: texts?.['spaces.relatedItems.popup.parentStatusChange.okBtn'] || 'Change all',
    parentStatusChangeCancelBtn: texts?.['spaces.relatedItems.popup.parentStatusChange.cancelBtn'] || 'Cancel',
    linkedIncidentsStatusChangeTitle:
      texts?.['spaces.relatedItems.popup.linkedIncidentsStatusChange.title'] || 'Heads up! __srType__ status update',
    linkedIncidentsStatusChangeDescription:
      texts?.['spaces.relatedItems.popup.linkedIncidentsStatusChange.description'] ||
      'Updating the status of this __srType__ will also update the status of __linkedIncidentsCount__ linked incidents to __updatedStatus__',
  };

  return componentTexts;
}
