import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from '@tanstack/react-router';
import { useDuplicateTemplate, useSaveTemplate } from 'remote-state/templateHooks';
import { setToasterMessage } from 'store/globalSlice';
import { selectIsPageValid, setPageValidations } from 'store/pageValidationSlice';
import { selectActiveUser } from 'store/userSlice';
import MenuItem from '@mui/material/MenuItem';
import { useUserInfo } from 'remote-state/userServiceHooks';
import { DropDownMenu } from 'common/components/dropDownMenu';
import MessagePrompt from 'common/components/messagePrompt';
import { PAGE_VALIDATION_CONSTANTS } from 'constants/index';
import { QUERIES_KEYS } from 'constant';
import TemplateDeleteButton from '../TemplateDeleteButton';
import useTexts from '../useTexts';
import { StyledTemplateButton } from '../StyledTemplateButton';
import { ReactComponent as DuplicateIcon } from '../../../images/icons/duplicate.svg';
import { canDeleteTemplate } from '../utils/utils';

export const CONSTANTS = {
  DUPLICATE_BUTTON_ID: 'duplicate-btn',
};

export default function TemplateDropDownMenu({ template }) {
  const dispatch = useDispatch();
  const router = useRouter();
  const userAccount = useSelector(selectActiveUser);
  const isPageValid = useSelector(selectIsPageValid);
  const { data: userPermissions } = useUserInfo(QUERIES_KEYS.CURRENT_USER_PERMISSIONS);
  const {
    duplicateText,
    duplicateSuccessText,
    duplicateErrorText,
    duplicateChangesSavedText,
    duplicateSaveChangesBtnText,
    duplicatePromptTitleText,
    duplicatePromptMessageText,
    duplicateCancelBtnText,
  } = useTexts();
  const [menuOpen, setMenuOpen] = useState(false);
  const [showUnsavedTemplatePrompt, setShowUnsavedTemplatePrompt] = useState(false);
  const templateId = router.latestLocation.search.id;
  const {
    mutate: duplicateTemplate,
    isLoading: isDuplicateLoading,
    data: duplicateData,
    status: duplicateStatus,
  } = useDuplicateTemplate();
  const { mutate: saveTemplate, status: saveStatus } = useSaveTemplate(templateId);
  const [saveTemplateStatus, setSaveTemplateStatus] = useState(saveStatus);

  useEffect(() => {
    setSaveTemplateStatus(saveStatus);
  }, [saveStatus]);

  const handleOpenMenu = useCallback((val) => {
    setMenuOpen(val);
  }, []);

  const handleDuplicate = () => {
    if (!isPageValid) {
      setShowUnsavedTemplatePrompt(true);
    } else {
      duplicateTemplate({ updateUser: userAccount?.username, templateId });
    }
    setMenuOpen(false);
  };

  useEffect(() => {
    if (duplicateStatus === 'success') {
      dispatch(setToasterMessage({ message: duplicateSuccessText }));
      router.navigate({ to: `/spaces/template?id=${duplicateData.id}` });
    } else if (duplicateData === 'error') {
      dispatch(setToasterMessage({ message: duplicateErrorText, type: 'error' }));
    }
  }, [dispatch, router, duplicateData, duplicateErrorText, duplicateStatus, duplicateSuccessText]);

  const handleSaveAndDuplicate = () => {
    saveTemplate({ updateUser: userAccount?.username, templateObject: template });
    setShowUnsavedTemplatePrompt(false);
  };

  useEffect(() => {
    if (saveTemplateStatus === 'success' && !isPageValid) {
      setSaveTemplateStatus(undefined);
      dispatch(
        setPageValidations({
          pageKey: '/spaces/template',
          component: `${PAGE_VALIDATION_CONSTANTS.TEMPLATE}`,
          validationDetails: {
            isValid: true,
          },
        }),
      );
      dispatch(setToasterMessage({ message: duplicateChangesSavedText }));
      duplicateTemplate({ updateUser: userAccount?.username, templateId });
    } else if (duplicateData === 'error') {
      dispatch(setToasterMessage({ message: duplicateErrorText, type: 'error' }));
    }
  }, [
    dispatch,
    duplicateChangesSavedText,
    duplicateData,
    duplicateErrorText,
    duplicateTemplate,
    isPageValid,
    saveTemplateStatus,
    templateId,
    userAccount,
  ]);

  ///
  const isNewTemplate = router.latestLocation.search.id === 'new';
  const isDuplicateDisabled = templateId === 'new' || isDuplicateLoading;
  const isDeleteDisabled = isNewTemplate || !canDeleteTemplate({ template, userPermissions });
  return (
    <>
      <DropDownMenu data-cy="template-actions" handleOpen={handleOpenMenu} open={menuOpen}>
        {!isDeleteDisabled && (
          <MenuItem disabled={isDeleteDisabled} data-cy="delete-template-action">
            <TemplateDeleteButton templateId={templateId} handleOpenMenu={handleOpenMenu} />
          </MenuItem>
        )}
        <MenuItem
          onClick={handleDuplicate}
          disabled={isDuplicateDisabled}
          data-cy="duplicate-template-action"
          data-testid={CONSTANTS.DUPLICATE_BUTTON_ID}
        >
          <StyledTemplateButton startIcon={<DuplicateIcon />} shouldhover={false} fullWidth>
            {duplicateText}
          </StyledTemplateButton>
        </MenuItem>
      </DropDownMenu>
      {showUnsavedTemplatePrompt && (
        <MessagePrompt
          open
          showCancelBtn
          title={duplicatePromptTitleText}
          btnOkText={duplicateSaveChangesBtnText}
          btnCancelText={duplicateCancelBtnText}
          onOkClick={handleSaveAndDuplicate}
          onClose={() => setShowUnsavedTemplatePrompt(false)}
        >
          {duplicatePromptMessageText}
        </MessagePrompt>
      )}
    </>
  );
}
