import { axiosInstance } from './axiosConfig';

export const resolveSolutionResolutionApi = (newSolutionResolution) =>
  axiosInstance.post('solutionResolution/resolved', newSolutionResolution).then((response) => response.data);

export const editSolutionResolutionApi = (newSolutionResolution) => {
    const onSuccess = newSolutionResolution.onSuccessEditSolutionResolution;
    const onError = newSolutionResolution.onErrorEditSolutionResolution;
    const showToasterMessage = newSolutionResolution.showToasterMessage;
    axiosInstance.patch('solutionResolution/resolved', newSolutionResolution.editedSolutionResolution)
        .then((response) => {
            onSuccess({ ...response.data, showToasterMessage });
            return response.data;
        })
        .catch(() => {
            onError({ showToasterMessage });
        });
};
