import { useEffect, useMemo } from 'react';
import { useInfiniteQuery, useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { getGroupsWithParams } from 'services/groups';
import {
  getResourceBundle,
  getUsersWithParams,
  getUsersBySearchText,
  saveProfilePicture,
  deleteProfilePicture,
  usePhotoLimits,
  getUser,
  getLoginObject,
  getUserMainAssetAndCi,
  getUserAttributes,
  saveUserAttributes,
} from 'services/userService';
import {
  getCalculatedRole,
  getCalculatedUserName,
  getRole,
  getUserLanguage,
  modifyUserDateInfo,
} from 'common/utils/userUtils';
import { QUERIES_KEYS } from 'constant';
import { USER_CONSTANTS } from 'constants/users';
import { APP_CONSTANTS, ROUTES_PATHS } from 'constants/index';
import { getCookie, buildLoginUrl, getLoginPathInfo } from 'common/utils/utils';
import { isLocalHost } from 'services/localhost';
import useMemoizedQueries from 'common/utils/hooks/useMemoizedQueries';
import { capitalizeFirstLetter } from 'features/navBar/meMenu/utils';
import { login, selectActiveUser, setUserDetails } from 'store/userSlice';
import { FORGOT_PASSWORD_SUBDIRECTORY } from '../constants/routes';
import { useTargetChannel } from './useTargetChannelHook';
// import { setTexts } from 'store/globalSlice';

export const useUserInfo = (selectKey, forceCall) => {
  const queryKey = [QUERIES_KEYS.CURRENT_USER];
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const sessionId = getCookie(APP_CONSTANTS.SESSION_ID);
  const isEnabled = !!sessionId || isLocalHost() || Boolean(forceCall);
  const targetChannel = useTargetChannel();
  const getUserInfoByKey = (userData) => {
    switch (selectKey) {
      case QUERIES_KEYS.CURRENT_USER_FROM_CORE:
        return modifyUserDateInfo(userData.coreUser);
      case QUERIES_KEYS.USER_DATA:
        return userData.sysaidUser;
      case QUERIES_KEYS.CURRENT_USER_PERMISSIONS:
        return userData.permissions;
      case QUERIES_KEYS.USER_CONF:
        return userData.userConf;
      case QUERIES_KEYS.PHOTO:
        return userData.photo || null;
      default:
        return userData;
    }
  };

  const { data, error, ...rest } = useQuery(queryKey, () => getUser(), {
    placeholderData: {},
    enabled: isEnabled,
    retryDelay: 3000,
    select: (userData) => getUserInfoByKey(userData),
  });

  const currentUser = useSelector(selectActiveUser);
  const sysaidUser = useMemo(
    () => (data?.sysaidUser ? { ...data.sysaidUser, profileImage: data.photo } : null),
    [data],
  );

  useEffect(() => {
    if (sysaidUser) {
      dispatch(setUserDetails(sysaidUser));
    }
  }, [sysaidUser, dispatch]);

  useEffect(() => {
    if (data && !isEmpty(data) && selectKey === QUERIES_KEYS.CURRENT_USER && !currentUser?.isAuth) {
      const { coreUser } = data;
      if (sysaidUser) {
        queryClient.setQueryData([QUERIES_KEYS.USER_DETAILS_BY_NAME, data.sysaidUser.userName], () => sysaidUser);
        queryClient.setQueryData([QUERIES_KEYS.USER_DETAILS_BY_ID, data.sysaidUser.id], () => sysaidUser);
        //If user logged in throught SSO then we need to set account id and target on session as well.
        localStorage.setItem(APP_CONSTANTS.ACCOUNT_ID_LOCAL_KEY, sysaidUser.accountId);
        if (targetChannel) {
          localStorage.setItem(APP_CONSTANTS.ACCOUNT_TARGET, targetChannel);
        }
      }
      const modifiedCoreUser = modifyUserDateInfo(coreUser);
      dispatch(
        login({
          username: modifiedCoreUser?.name,
          calculatedUserName: getCalculatedUserName(modifiedCoreUser),
          roles: [getRole(modifiedCoreUser)],
          language: getUserLanguage(modifiedCoreUser.info),
          isAuth: true,
          userId: Number(modifiedCoreUser.id),
          isAdmin: modifiedCoreUser.isAdmin,
          isSysAidAdmin: modifiedCoreUser.isSysAidAdmin,
          calculatedRole: getCalculatedRole(modifiedCoreUser),
          isUserLoading: false,
        }),
      );
    }
  }, [data, sysaidUser, selectKey, currentUser?.isAuth, dispatch, queryClient, targetChannel]);

  useEffect(() => {
    if (error) {
      console.error('error fetching user data, redirect to login page', error);
      //delete sessionId cookie
      document.cookie.split(';').forEach((c) => {
        document.cookie = c.replace(/^ +/, '').replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
      });
      const loginUrl = buildLoginUrl();
      if (
        !window.location.href.includes(ROUTES_PATHS.LOGIN_URL) &&
        window.location.pathname !== `${process.env.PUBLIC_URL}/${FORGOT_PASSWORD_SUBDIRECTORY}`
      )
        window.location.href = loginUrl;
    }
  }, [error]);

  const invalidUserInfo = () => {
    queryClient.invalidateQueries({ queryKey });
  };

  const updateQueryWithData = (updateData) => {
    queryClient.setQueryData(queryKey, (currentUser) => ({ ...currentUser, ...updateData }));
  };

  const timezone = data?.info?.find((atr) => atr.key === USER_CONSTANTS.TIMEZONE)?.valueCaption || null;
  const dateFormat = data?.info?.find((atr) => atr.key === USER_CONSTANTS.DATE_FORMAT)?.valueCaption || null;

  return {
    data,
    error,
    ...rest,
    invalidUserInfo,
    updateQueryWithData,
    timezone,
    dateFormat,
    isEnabled,
  };
};

export const useLoginObject = () => {
  const { previousPath , manualLogin } = getLoginPathInfo();

  let encodePreviousPath = previousPath;
  let cacheTimeValue = 1000

  if (previousPath && previousPath !== '/spaces') {
    encodePreviousPath = encodeURIComponent(previousPath);
  } else {
    cacheTimeValue = Infinity;
  }

  return useQuery([QUERIES_KEYS.LOGIN_OBJECT, encodePreviousPath], () => getLoginObject(encodePreviousPath, manualLogin), {
    placeholderData: {},
    retry: 3,
    retryDelay: 3000,
    cacheTime: cacheTimeValue,
    staleTime: cacheTimeValue,
  });
};

export const useResourceBundle = (options = {}) => {
  // const dispatch = useDispatch();

  const { data: loginObject } = useLoginObject();

  const { data: rb, ...rest } = useQuery(
    [QUERIES_KEYS.RESOURCE_BUNDLE],
    () => getResourceBundle(loginObject?.defaultLanguage),
    {
      placeholderData: {},
      enabled: !!loginObject?.defaultLanguage,
      select: (rb) => rb.resourceBundleMap,
      ...options,
    },
  );

  // useEffect(() => {
  //   if (rb?.resourceBundleMap) {
  //     dispatch(setTexts(rb.resourceBundleMap));
  //   }
  // }, [rb?.resourceBundleMap, dispatch]);

  return {
    data: rb,
    ...rest,
  };
};

//Currently not in use, placeholder for mention pagination support
export function useUserSearch({ isAdmin, calculatedUserName, userName, sort }) {
  const queryKey = ['userSearch', calculatedUserName || userName];
  return {
    ...useInfiniteQuery({
      queryKey,
      queryFn: ({ pageNumber = 0 }) =>
        getUsersWithParams({ isAdmin, calculatedUserName, userName, resultPageNumber: pageNumber, sort }),
      getNextPageParam: (currentPage) => {
        if (currentPage && !currentPage.lastPage) return currentPage.resultPageNumber + 1;
      },
      placeholderData: [],
    }),
  };
}

export function useRecipientQuery(recipientText) {
  const queries = useMemo(
    () => [
      {
        queryKey: ['userSearch', recipientText],
        queryFn: () => getUsersBySearchText(recipientText),
        placeholderData: [],
        enabled: !!recipientText,
      },
      {
        queryKey: ['groupSearch', recipientText],
        queryFn: () => getGroupsWithParams({ groupName: recipientText }),
        placeholderData: [],
        enabled: !!recipientText,
      },
    ],
    [recipientText],
  );

  const results = useMemoizedQueries(queries);

  const isLoading = useMemo(() => results.some((query) => query.isLoading), [results]);

  const recipientData = useMemo(
    () =>
      results.flatMap(({ data }) => {
        if (!data?.searchResults || data?.searchResults?.length === 0) return [];
        const newResults = data.searchResults.map((result) => {
          const name = result?.groupName || result?.calculatedUserName;
          const isGroup = !!result.groupName;
          return { name, type: isGroup ? 'group' : 'user', ...result };
        });
        return newResults;
      }),
    [results],
  );

  return { recipientData, isLoading };
}

export function useAssigneeQuery(queryText, groupId) {
  const usersData = useQuery({
    queryKey: ['assignedToSearch', { groupId, queryText }],
    queryFn: () => getUsersWithParams({ calculatedUserName: queryText, groupRefId: groupId }),
    placeholderData: [],
  });
  const groupsData = useQuery({
    queryKey: ['assignedGroupSearch', queryText],
    queryFn: () => getGroupsWithParams({ groupName: queryText, groupTypes: [0, 1] }),
    placeholderData: [],
  });

  return { users: usersData?.data?.searchResults || [], groups: groupsData?.data?.searchResults || [] };
}

export const useSaveProfilePictureQuery = () => {
  const saveProfilePictureMutation = useMutation((file) => saveProfilePicture({ file }));

  const saveProfilePictureHandler = async (file) => {
    await saveProfilePictureMutation.mutateAsync(file);
  };

  return {
    saveProfilePicture: saveProfilePictureHandler,
    isLoading: saveProfilePictureMutation.isLoading,
    isSuccess: saveProfilePictureMutation.isSuccess,
    isError: saveProfilePictureMutation.isError,
  };
};

export const useDeleteProfilePictureMutation = () => {
  const deleteProfilePictureMutation = useMutation(() => deleteProfilePicture());

  const deleteProfilePictureHandler = async () => {
    try {
      await deleteProfilePictureMutation.mutateAsync();
    } catch (error) {
      console.error(error);
    }
  };

  return {
    deleteProfilePicture: deleteProfilePictureHandler,
    isLoading: deleteProfilePictureMutation.isLoading,
    isSuccess: deleteProfilePictureMutation.isSuccess,
    isError: deleteProfilePictureMutation.isError,
  };
};

export const usePhotoLimitsQuery = () =>
  useQuery(['photoLimits'], usePhotoLimits, {
    placeholderData: {},
  });

export const useMainAssetAndCi = () =>
  useMutation({
    mutationFn: getUserMainAssetAndCi,
    onSuccess: (data) => data?.data || {},
  });

export const useMeMenuDetails = () => {
  const queryKey = 'meMenuDetails';
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData([QUERIES_KEYS.CURRENT_USER]);
  const firstDayOfWeek = capitalizeFirstLetter(
    data?.coreUser?.info?.find((item) => item.key === USER_CONSTANTS.FIRST_DAY_OF_WEEK)?.value,
  );
  const dateFormat = capitalizeFirstLetter(
    data?.coreUser?.info?.find((item) => item.key === USER_CONSTANTS.INPUT_DATE)?.value,
  );
  const outOfOffice = data?.userConf?.outOfOffice;
  const takeOverUser = data?.userConf?.takeOverUser;
  const currentUserDetails = { ...data?.sysaidUser, profileImage: data?.photo, firstDayOfWeek, dateFormat,
    outOfOffice, takeOverUser };
  const updateMeMenuDetailsQuery = (savedDetails) => {
    queryClient.setQueryData([queryKey], () => savedDetails);
  };

  return {
    meMenuDetails: queryClient.getQueryData([queryKey]) || currentUserDetails,
    updateMeMenuDetails: updateMeMenuDetailsQuery,
  };
};

export const useUserAttributes = (username, attributeName) => useQuery({
  queryKey: [QUERIES_KEYS.USER_ATTRIBUTES, username, attributeName],
  queryFn: () => getUserAttributes({ username, attributeName }),
  enabled: !!username && !!attributeName,
  cacheTime: 1000,
});

export const useSaveUserAttributes = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: saveUserAttributes,
    // here we do an optimistic update to improve UX
    onMutate: (data) =>
      queryClient.setQueryData([QUERIES_KEYS.USER_ATTRIBUTES, data.username, data.attributeName], data),
    onSuccess: (data) =>
      queryClient.setQueryData([QUERIES_KEYS.USER_ATTRIBUTES, data.username, data.attributeName], data),
  });
}
