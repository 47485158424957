import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  return {
    getColumnText: (displayKey) => texts?.[displayKey],
    enterTextPlaceholder: texts?.['spaces.commonTextField.placeholder.enterText'],
    enterTitlePlaceholder: texts?.['spaces.commonTextField.placeholder.enterTitle'],
    mandatoryFieldError: texts?.['spaces.field.tooltip.error.mandatoryFieldError'] || 'Missing required field',
  };
}
