import { useEffect, useState } from 'react';
import { useRouter } from '@tanstack/react-router';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getScreenCapturesBySrId, deleteScreenCaptureBySrId } from '../services/screenCapturesService';
import queryClient from './queryClient';

export const useScreenCaptures = () => {
  const router = useRouter();
  const srId = router.latestLocation.search.id;
  const [screenCaptures, setScreenCaptures] = useState([]);

  const { data } = useQuery({
    queryKey: ['screenCaptures', srId],
    queryFn: () => getScreenCapturesBySrId(srId),
    enabled: !!srId,
    cacheTime: 0,
    staleTime: 0,
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    setScreenCaptures(data);
  }, [data]);

  return { screenCaptures };
};

export const useDeleteScreenCapture = () => {
  const router = useRouter();
  const srId = router.latestLocation.search.id;

  return useMutation({
    mutationFn: (type) => deleteScreenCaptureBySrId(srId, type),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['screenCaptures', srId] });
    },
  });
};
