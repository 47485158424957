export const classesStyles = (theme) => ({
  icon: {},
  svgIcon: {
    width: '36px',
    height: '36px',
  },
  svgIconSmall: {
    width: '25px',
    height: '25px',
  },
  promptDisplay: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '12px',

    '& > div:first-child': {
      height: '36px',
    },
  },
  promptDisplayCreatedTicket: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '12px',

    '& > div': {
      height: '51px',
    },
  },
  promptDisplayArchivedTicket: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'unset!important',
    gap: '12px',
    '& > div': {
      height: '51px',
    },
    '& .message-wrapper': {
      display: 'flex',
      flexDirection: 'column',
      gap: '7px',
    },
    '& .buttons-wrapper': {
      display: 'flex',
      alignItems: 'center',
      gap: '13px',
    },
    '& .buttons-divider': {
      width: '1.5px',
      height: '1.5px',
      backgroundColor: theme?.palette?.text?.blue_user_name,
      borderRadius: '100%',
    },
    '& .btn-link': {
      padding: 0,
      color: theme?.palette?.text?.blue_user_name,
      textDecoration: 'none',
      fontFamily: theme?.fonts?.robotoRegular,
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '20px',
      border: 'none',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  okBtn: {
    margin: '0 0 auto 24px',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    width: '16px',
    height: '16px',
    padding: 0,
    '& svg': {
      width: '16px',
      height: '16px',
    },
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});
