import { StyledTooltip } from 'common/components/controls/Tooltip/Tooltip';

import { GroupTitleTypography, GroupMembersTypography, StyledGroupTitle } from './style';
import { ReactComponent as IconBack } from '../../../../../images/icons/icon_back.svg';
import useTexts from '../../useTexts';

export const GroupTitle = (props) => {
  const { groupName, members, onClickBack } = props;
  const { membersText } = useTexts();

  return (
    <StyledGroupTitle>
      <button className="btn" onClick={onClickBack}>
        <IconBack className="icon" />
      </button>
      <div className="text-container">
        <StyledTooltip placement="bottom" title={groupName} arrow>
          <GroupTitleTypography noWrap>{groupName}</GroupTitleTypography>
        </StyledTooltip>
        <GroupMembersTypography>
          {members} {membersText}
        </GroupMembersTypography>
      </div>
    </StyledGroupTitle>
  );
};
