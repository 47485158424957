import { styled } from '@mui/material/styles';
import { AccordionSummary, AccordionDetails, Box } from '@mui/material';

export const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  '&.MuiAccordionDetails-root': {
    padding: '26px 0px 0px 60px',
  },
}));

export const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  '&.MuiButtonBase-root': {
    flexDirection: 'row-reverse',
    display: 'flex',
    alignItems: 'flex-start',
    minHeight: '36px',
    padding: '6px 0px 6px 12px',
  },
  '& .MuiAccordionSummary-content': {
    margin: '0',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    padding: '0px 12px',
  },
}));

export const StyledHeader = styled(Box)(({ theme }) => ({
  fontSize: theme.fontSize.medium,
  lineHeight: '24px',
  color: theme.palette.text.title_txt,
}));

export const StyledInstructions = styled(Box)(({ theme }) => ({
  position: 'relative',
  paddingTop: '6px',
  fontSize: theme.fontSize.small,
  color: theme.palette.text.secondary_txt,
  whiteSpace: 'pre-line',
}));
