import { APP_CONSTANTS } from 'constants/app';
import ButtonBase from 'common/components/buttons/FormButton';
import { ReactComponent as EyeIcon } from '../../../images/icons/eye.svg';
import useTexts from '../useTexts';
import { StyledPreviewButtonWrapper } from './StyledBuilderPreviewButton';
import { mapFieldsToSectionsRows } from '../utils/mapFieldsToSectionsRows';
import { TICKETS_OPEN_URL } from '../../../constants';

export default function TemplateBuilderPreviewButton({ template }) {
  const { templatePreviewText } = useTexts();

  const onPreview = () => {
    const updatedTemplate = mapFieldsToSectionsRows(template);
    sessionStorage.setItem(APP_CONSTANTS.PREVIEW_TEMPLATE_OR_ID_KEY, JSON.stringify(updatedTemplate));
    window.open(TICKETS_OPEN_URL.SPACES('preview'), '_blank');
  };

  return (
    <StyledPreviewButtonWrapper data-testid="template-preview-button">
      <ButtonBase btnTheme="view" text={templatePreviewText} onClick={onPreview} icon={<EyeIcon />} hideText />
    </StyledPreviewButtonWrapper>
  );
}
