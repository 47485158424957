const UNASSIGNED_KEY = 'unassigned';
const UNASSIGNED_USERNAME = 'none';
const UNASSIGNED_TITLE = 'Unassigned';
const UNASSIGNED_ID = -9999;

export const QUEUE = {
  deselectedQuickFilter: {
    quickFilterDisplayName: '',
    quickFilterName: '',
  },
  defaultQuickFilter: {
    quickFilterDisplayName: 'initial_loading',
    quickFilterName: 'initial_loading',
  },
  initialSortColumn: { colId: 'id', sort: 'desc' },
  chipGap: 16,
  chipMaxWidth: 200,
  unassigned: {
    key: UNASSIGNED_KEY,
    title: UNASSIGNED_TITLE,
    userName: UNASSIGNED_USERNAME,
    isUnassigned: true,
    id: UNASSIGNED_ID,
    calculatedUserName: UNASSIGNED_USERNAME,
  },
};

export const SESSION_STORAGE = {
  gridConfiguration: 'gridConfiguration',
  isQuickFilterApplied: 'isQuickFilterApplied',
  customSortModel: 'customSortModel',
  customFilterModel: 'customFilterModel',
  serviceRequestId: 'serviceRequestId',
  columnsOrder: 'columnsOrder',
  activeView: 'activeView',
  defaultView: 'defaultView',
  categoryIsSelected: 'categoryIsSelected',
  isAssigneeModified: 'isAssigneeModified',
  edition: 'edition',
};

export const COMPANY_FIELD_KEY = 'requestUser.company';

export const VIEWS_KEYS = [
  {
    propKey: 'columnsOrder',
    propFieldKey: 'fieldName',
  },
  {
    propKey: 'customFilterModel',
    propFieldKey: 'field',
  },
  {
    propKey: 'customSortModel',
    propFieldKey: 'colId',
  },
];
