import { initToolbarButtonsPopup, showPopup, hidePopup } from './utils';

const initialResponsiveToolbar = ({ editor, templateName, pluginName }) => {
  const initPopup = () => {
    initToolbarButtonsPopup(editor, templateName, editor.opts.responsiveToolbarPopupButtons);
  };

  const showResponsiveToolbarPopup = () => {
    showPopup(editor, templateName, pluginName, initPopup);
  };

  const hideResponsiveToolbarPopup = () => {
    hidePopup(editor, templateName);
  };

  return {
    initPopup,
    showResponsiveToolbarPopup,
    hideResponsiveToolbarPopup,
  };
};

export { initialResponsiveToolbar };
