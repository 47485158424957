import { styled } from '@mui/system';

export const FilterContainer = styled('div')(({ theme }) => ({
  border: 'none',
  borderRadius: '10px',
  boxShadow: '0px 2px 8px rgb(118 130 150 / 35%)',
  padding: theme.spacing(1.5, 1),
  backgroundColor: theme.palette.view.main,
  width: '224px',
  '.MuiSvgIcon-root': {
    display: 'none',
  },
}));

export const RangeInputWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  backgroundColor: theme.palette.view.main,
  '.MuiTextField-root': {
    background: `${theme.palette.background.bg_hover_main} !important`,
    color: `${theme.palette.text.txt_default} !important`,
    padding: '0!important',
    '&:hover': {
      '.MuiInput-root': {
        background: `${theme.palette.background.bg_hover_main} !important`,
      },
      background: `${theme.palette.background.bg_hover_main} !important`,
      '.MuiInput-input': {
        background: `${theme.palette.background.bg_hover_main} !important`,
        '&::placeholder': {
          color: `${theme.palette.text.txt_default} !important`,
          opacity: 1,
        },
      },
    },
  },
  '.MuiInput-root': {
    height: '20px',
    padding: '0!important',
    background: `${theme.palette.background.bg_hover_main} !important`,
  },
  '.MuiInput-input': {
    background: `${theme.palette.background.bg_hover_main} !important`,
    color: `${theme.palette.text.txt_default} !important`,
    border: 'none',
    padding: '4px 8px!important',
    borderRadius: '0',
    '&::placeholder': {
      color: `${theme.palette.text.txt_default} !important`,
      opacity: 1,
    },
  },
}));
