// StatusIndicator.js
import { styled } from '@mui/material/styles';
import { ReactComponent as CompletedIndicatorIcon } from 'images/icons/checkmark-icon-thick.svg';
import { Tooltip } from 'common/components/sysaidLibrary/Tooltip';

import { Indicator } from '../Indicator';
import useTexts from '../../hooks/useTexts';

export const StatusIndicator = styled(({ completed, className }) => {
  const { enabledText, completedText } = useTexts();

  return (
    <Tooltip title={completed ? completedText : enabledText} arrow placement="top" forceDisplayOnHover>
      <Indicator
        icon={<CompletedIndicatorIcon />}
        className={className}
        completed={completed}
        title={completed ? 'completed-indicator' : 'enabled-indicator'}
        data-cy={`${completed ? 'completed' : 'enabled'}-indicator`}
      />
    </Tooltip>
  );
})(({ theme, completed }) => ({
  backgroundColor: completed ? theme.palette.generic.sysaid : 'white',
  border: completed ? 'none' : `1px solid ${theme.palette.generic.sysaid}`,
  svg: {
    filter: completed
      ? 'none'
      : 'brightness(0) saturate(100%) invert(60%) sepia(19%) saturate(544%) hue-rotate(109deg) brightness(92%) contrast(88%)',
  },
}));
