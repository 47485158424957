import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    createAttachment: {
      prompt: {
        errorDescription: (errorMessage) =>
          `${texts?.['spaces.attachments.create.errorDescription']} ${errorMessage}. ${texts?.['spaces.attachments.create.contactAdministrator']}`,
        fileSizeErrorDescription: (fileSize) =>
          `${texts?.['spaces.attachments.create.fileSizeErrorDescription']} ${fileSize} MB.`,
        mediaTypesErrorDescription: texts?.['spaces.attachments.create.mediaTypesErrorDescription'],
        uploadFailed: texts?.['spaces.attachments.create.uploadFailed'],
        unauthorizedUpload: texts?.['spaces.attachments.create.unauthorizedUpload'],
      },
      inline: {
        errorDescription: (errorMessage) =>
          `${texts?.['spaces.attachments.create.errorDescription']} ${errorMessage}. ${texts?.['spaces.attachments.create.contactAdministrator']}`,
        fileSizeErrorDescription: (fileSize) =>
          `${texts?.['spaces.attachments.create.fileSizeErrorDescription']} ${fileSize} MB.`,
        mediaTypesErrorDescription: texts?.['spaces.attachments.create.mediaTypesErrorDescription'],
        uploadFailed: texts?.['spaces.attachments.create.uploadFailed'],
        unauthorizedUpload: texts?.['spaces.attachments.create.unauthorizedUpload'],
      },
    },
  };

  return componentTexts;
}
