import { ClickAwayListener } from '@mui/base';
import { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Paper, Fade } from '@mui/material';
import PropTypes from 'prop-types';

import useTexts from 'common/components/assignee/useTexts';
import { selectActiveUser } from 'store/userSlice';
import { GroupDropdown } from '../GroupDropdown';
import { List } from './List';

import { StyledAssigneeDropdown, StyledHeaderProps, StyledNoResults, StyledGroupTitle, StyledDivider } from './style';

const customScrollProps = {
  style: { display: 'flex' },
  autoHeight: true,
  renderThumbVerticalStyle: { width: '4px', marginLeft: '-17px' },
  renderThumbHorizontalStyle: { height: 0 },
  renderTrackVerticalStyle: { width: '0px', padding: 0, right: '-7px' },
  renderViewStyle: { flex: '1' },
};

/**
 * Assignee Dropdown Component with custom scroll and virtualization
 */
export const AssigneeDropdown = (props) => {
  const {
    anchorEl,
    headerProps,
    handleSelect,
    users,
    groups,
    currentAssigned,
    dropdownWidth,
    assigneeItemHeight,
    handleClose,
    dropdownMaxItemsCount,
    dropdownMaxHeight,
    showGroupDropdown,
    handleGroupSelect,
    onClickBackFromGroupDropdown,
    selectedGroup,
    isRequired,
    searchText,
    ...rest
  } = props;

  const userAccount = useSelector(selectActiveUser);
  const { emptyResultsText, teamsText } = useTexts();

  useEffect(() => {
    const listener = (event) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };
    window.addEventListener('keydown', listener);

    return () => {
      window.removeEventListener('keydown', listener, true);
    };
  }, [handleClose]);

  const handleDisplayedItemsCount = useCallback(
    (options) => {
      if (options?.length > dropdownMaxItemsCount) return dropdownMaxItemsCount;
      return options?.length;
    },
    [dropdownMaxItemsCount],
  );

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <StyledAssigneeDropdown
        placement="bottom-start"
        {...rest}
        width={dropdownWidth}
        anchorEl={anchorEl}
        data-testid="assignee-dropdown"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <StyledHeaderProps>{headerProps}</StyledHeaderProps>
              <AutoSizer disableHeight style={{ width: dropdownWidth, padding: '0px 0px 8px 8px' }}>
                {() => {
                  if (showGroupDropdown) {
                    return (
                      <GroupDropdown
                        customScrollProps={customScrollProps}
                        dropdownWidth={dropdownWidth}
                        assigneeItemHeight={assigneeItemHeight}
                        selectedGroup={selectedGroup}
                        onClickBack={onClickBackFromGroupDropdown}
                        handleSelect={handleSelect}
                        currentAssigned={currentAssigned}
                        searchText={searchText}
                      />
                    );
                  }
                  if (users?.length) {
                    const usersWithoutLoggedInUser = users.filter(
                      (user) => user?.calculatedUserName !== userAccount?.calculatedUserName,
                    );
                    return (
                      <List
                        type="user"
                        dropdownMaxHeight={dropdownMaxHeight}
                        customScrollProps={customScrollProps}
                        assigneeItemHeight={assigneeItemHeight}
                        options={usersWithoutLoggedInUser}
                        currentAssigned={currentAssigned}
                        handleSelect={handleSelect}
                        dropdownWidth={dropdownWidth}
                        isRequired={isRequired}
                        dropdownDisplayedItemsCount={handleDisplayedItemsCount(usersWithoutLoggedInUser)}
                        searchText={searchText}
                      />
                    );
                  }
                }}
              </AutoSizer>
              {!showGroupDropdown && !!groups?.length && (
                <>
                  {!!users?.length && <StyledDivider />}
                  <StyledGroupTitle data-testid="groups-title">{teamsText}</StyledGroupTitle>
                  <AutoSizer disableHeight style={{ width: dropdownWidth, padding: '0px 0px 8px 8px' }}>
                    {() => (
                      <List
                        type="group"
                        dropdownMaxHeight={dropdownMaxHeight}
                        dropdownDisplayedItemsCount={handleDisplayedItemsCount(groups)}
                        customScrollProps={customScrollProps}
                        assigneeItemHeight={assigneeItemHeight}
                        options={groups}
                        currentAssigned={currentAssigned}
                        handleSelect={handleGroupSelect}
                        dropdownWidth={dropdownWidth}
                        isRequired={isRequired}
                      />
                    )}
                  </AutoSizer>
                </>
              )}
              {!(users?.length || groups?.length) && !showGroupDropdown && (
                <StyledNoResults>{emptyResultsText}</StyledNoResults>
              )}
            </Paper>
          </Fade>
        )}
      </StyledAssigneeDropdown>
    </ClickAwayListener>
  );
};

AssigneeDropdown.propTypes = {
  isRequired: PropTypes.bool,
  anchorEl: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  open: PropTypes.bool,
  users: PropTypes.array,
  groups: PropTypes.array,
  handleClose: PropTypes.func,
  handleSelect: PropTypes.func,
  currentAssigned: PropTypes.object,
  showGroupDropdown: PropTypes.bool,
  handleGroupSelect: PropTypes.func,
  selectedGroup: PropTypes.object,
  onClickBackFromGroupDropdown: PropTypes.func,
  assigneeItemHeight: PropTypes.number,
  dropdownMaxItemsCount: PropTypes.number,
  dropdownMaxHeight: PropTypes.number,
  headerProps: PropTypes.element,
  dropdownWidth: PropTypes.number,
};

AssigneeDropdown.defaultProps = {
  isRequired: PropTypes.false,
  anchorEl: null,
  open: false,
  users: [],
  groups: [],
  handleClose: () => {},
  handleSelect: () => {},
  currentAssigned: null,
  showGroupDropdown: false,
  handleGroupSelect: () => {},
  selectedGroup: null,
  onClickBackFromGroupDropdown: () => {},
  assigneeItemHeight: 46,
  dropdownMaxItemsCount: 25,
  dropdownMaxHeight: 3 * 46,
  headerProps: null,
  dropdownWidth: 224,
};
