import Tooltip from 'common/components/tooltip';
import { ReactComponent as HintIcon } from 'images/icons/hint.svg';

const titleStyle = {
  whiteSpace: 'pre-line',
};

const Hint = ({ hintText, className }) => (
  <Tooltip
    text={<HintIcon style={{ marginLeft: '1px' }} />}
    title={<div style={titleStyle}>{hintText}</div>}
    placement="top"
    textAlign="left"
    data-testid="tooltip-icon"
    className={className}
  />
);

export default Hint;
