import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GroupTitleTypography = styled(Typography)(({ theme }) => ({
  flexGrow: 0,
  fontSize: '0.875rem',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'left',
  color: theme.palette.text.txt_default,
  maxWidth: '170px',
}));

export const GroupMembersTypography = styled(Typography)(({ theme }) => ({
  flexGrow: 0,
  fontSize: '0.75rem',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'left',
  color: theme.palette.grey.bt_def_grey_reg,
}));

export const StyledGroupTitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexGrow: 0,
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(1.5, 1),
  width: '224px',
  backgroundColor: theme.palette.generic.primary,
  '&&': {
    '.text-container': {
      display: 'flex',
      flexGrow: 0,
      flexDirection: 'column',
    },
    '.btn': {
      padding: 0,
      border: 'none',
      font: 'inherit',
      color: 'inherit',
      backgroundColor: 'transparent',
      width: '16px',
      height: '16px',
      float: 'right',
    },
    '.icon': {
      cursor: 'pointer',
      width: '16px',
      height: '16px',
      color: theme.palette.grey.bt_def_grey_reg,
      objectFit: 'contain',
      transform: 'rotate(-180deg)',
    },
  },
}));
