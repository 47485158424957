import isEmpty from 'lodash/isEmpty';
import { CONSTANTS, permissionType } from '../constants';

export const castCategoryDefaultToNumber = (val) => (val === '-1' ? -1 : val);

export const getDefaultCategory = (category) => ({
  'category.firstLevelKey': castCategoryDefaultToNumber(category.primaryCategory),
  'category.secondLevelKey': castCategoryDefaultToNumber(category.secondaryCategory),
  'category.thirdLevelKey': castCategoryDefaultToNumber(category.thirdLevelCategory),
});

export const getCategoryFieldUpdates = (fieldName, manuallyChangedFields, srFieldsToUpdate) => {
  let valueToUpdate = null;
  let isValid = false;
  switch (fieldName) {
    case 'category.firstLevelKey':
      if (manuallyChangedFields?.primaryCategory?.value) {
        valueToUpdate = manuallyChangedFields.primaryCategory.value;
        isValid = true;
      }
      break;
    case 'category.secondLevelKey':
      if (manuallyChangedFields?.secondaryCategoty?.value) {
        valueToUpdate = manuallyChangedFields.secondaryCategoty.value;
        isValid = true;
      }
      break;
    case 'category.thirdLevelKey':
      if (srFieldsToUpdate?.updateObj?.thirdLevelCategory || manuallyChangedFields?.thirdLevelCategory?.value) {
        valueToUpdate =
          srFieldsToUpdate?.updateObj?.thirdLevelCategory || manuallyChangedFields?.thirdLevelCategory?.value;
        isValid = true;
      }
      break;
    default:
      break;
  }
  return { valueToUpdate, isValid };
};

export const getDefaultAssignees = (assignees) => ({
  assignee: assignees.assignee,
  assignedGroup: assignees.assignedGroup,
});

export const getPropertyPanelName = (componentType, componentId) => {
  if (componentType === CONSTANTS.FIELDS || componentType === CONSTANTS.HEADER) {
    return CONSTANTS.FIELDS;
  }
  if (componentType === CONSTANTS.SECTION && componentId !== CONSTANTS.SECTION_INSTRUCTIONS) {
    return CONSTANTS.SECTION;
  }
  return CONSTANTS.DEFAULT;
};

export const checkIsStatusOrPriorityField = (componentId) => componentId === 8 || componentId === 58;

export const getAllFormFields = (header, sections) => {
  const sectionsFields = sections?.flatMap((section) => section.fields) || [];
  const headerFields = header?.filter((field) => field.fieldId !== undefined) || [];
  return [...sectionsFields, ...headerFields];
};

export const getHeaderFieldData = (header, fieldId) => {
  const [field] = header?.filter((field) => field.fieldId === fieldId);
  return field;
};

export const getSectionFieldData = (sections, fieldId) => {
  const [field] = sections?.flatMap((section) => section?.fields?.filter((field) => field.fieldId === fieldId));
  return field;
};

export const getSectionData = (sections, sectionId) => {
  const [section] = sections.filter((section) => (section?.sectionId || section?.id) === sectionId);
  return section;
};

export const getSelectedStatusList = (requiredStatuses) =>
  (requiredStatuses?.length && requiredStatuses?.map((requiredStatus) => requiredStatus.status)) || [];

export const getUpdatedStatusList = (selectedStatusList) =>
  selectedStatusList?.map((selectedStatus) => ({ status: selectedStatus }));

export const getUpdatedGroupList = (selectedGroupList) =>
  selectedGroupList?.map((group) => ({ groupId: group, permissionType: permissionType.edit }));

export const getSelectedGroupList = (permissions) => {
  let selectedGroupList = [];
  permissions?.forEach((permission) => {
    if (permission?.permissionType === CONSTANTS.EDIT) {
      selectedGroupList = [...selectedGroupList, permission.groupId];
    }
  });
  return selectedGroupList;
};

export const getAllViewGroups = (allGroups) =>
  allGroups?.map((group) => ({ groupId: group.id, permissionType: permissionType.view }));

export const canEditTemplate = ({ template, userPermissions }) => {
  if (!template || isEmpty(template) || !userPermissions || isEmpty(userPermissions)) return true;
  return (
    (template.srType === 'incident' && userPermissions.canCreateModifyIncidentTemplates) ||
    (template.srType !== 'incident' && userPermissions.changeManager)
  );
};

export const canDeleteTemplate = ({ template, userPermissions }) => {
  if (!template || isEmpty(template) || !userPermissions || isEmpty(userPermissions)) return true;
  return (
    !template.default &&
    ((template.srType === 'incident' && userPermissions.canPurgeIncidentTemplates) ||
      (template.srType !== 'incident' && userPermissions.changeManager))
  );
};
