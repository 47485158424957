import { Box } from '@mui/material';
import { styled } from '@mui/styles';

export const StyledClearSelectControl = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingBottom: '4px',
  height: '34px',
  borderBottom: `1px solid ${theme.palette.grey.divider_grey_v1}`,
}));
