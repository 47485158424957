import { useRef, useEffect, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, Box } from '@mui/material';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from '@tanstack/react-router';
import { useLoginObject } from 'remote-state/userServiceHooks';
import { ROUTES_PATHS } from 'constants/index';
import { APP_CONSTANTS } from 'constants/app';
import { ReactComponent as GoBackIcon } from 'images/icons/backTo.svg';
import AutoComplete from 'common/components/autoComplete';
import { TextField } from 'common/components/sysaidLibrary/textField';
import { guestLogin } from 'services/userService';
import RememberForgotSection from '../rememberForgotSection';
import useTexts from '../useTexts';
import { StyledUserForm, customAutoCompleteStyle, StyledLoading } from './style';

// component need to be consolidated
export default function UserForm(props) {
  const {
    onSubmitForm,
    isDomainField = false,
    isForgotPasswordPage = false,
    onClickForgotPassword = () => {},
    formErrors = {},
    eyePasswordSrc = '',
    onClickPasswordEyeIcon = () => {},
    shouldDisplayCaptcha = true,
    resetFormErrors = () => {},
    isloginDisable,
    isLoading = false,
    inputType,
  } = props;
  const { data: loginObject } = useLoginObject();
  const { enableGuestMode, enableSignUp, enablePasswordServices, accountId, domainsList } = loginObject;
  const router = useRouter();
  const {
    orText,
    emailText,
    passwordText,
    domainText,
    localUserTexts,
    maxCharactersLimitReachedText,
    requiredText,
    emailErrorText,
    usernameText,
    submitText,
    signInText,
    noUser,
    loginAsGuest,
    signUp,
    resetPassword,
    unlockAccount,
    backToLogin,
  } = useTexts();
  const [hidePasswordEyeIcon, setHidePasswordEyeIcon] = useState(false);

  const loginSchema = Yup.object().shape({
    username: Yup.string()
      .trim()
      .max(200, maxCharactersLimitReachedText)
      .required(requiredText)
      .test('wrong-credentials', () => {
        if (Object.values(formErrors).some((error) => error !== '')) resetFormErrors(formErrors);
        return true;
      }),
    password: Yup.string()
      .trim()
      .max(200, maxCharactersLimitReachedText)
      .required(requiredText)
      .test('wrong-credentials', () => {
        if (Object.values(formErrors).some((error) => error !== '')) resetFormErrors(formErrors);
        return true;
      }),
    domain: isDomainField ? Yup.string().required(requiredText) : Yup.mixed().nullable(),
  });

  const forgotPasswordSchema = Yup.object().shape({
    username: Yup.string().trim().max(200, maxCharactersLimitReachedText).required(requiredText),
    email: Yup.string().trim().email(emailErrorText).required(requiredText),
  });

  const schema = isForgotPasswordPage ? forgotPasswordSchema : loginSchema;
  const {
    control,
    handleSubmit,
    setError,
    setValue,
    getFieldState,
    clearErrors,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      username: '',
      password: '',
      isDomainField: isDomainField ? true : null,
    },
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    if (formErrors.username) {
      setError('username', {
        type: '401',
        message: formErrors.username,
      });
    }
    if (formErrors.password) {
      setError('password', {
        type: '401',
        message: formErrors.password,
      });
    }
  }, [formErrors, setError]);

  useEffect(() => {
    if (isValid) clearErrors();
  }, [clearErrors, isValid]);

  useEffect(() => {
    if (
      (getFieldState('password').invalid && !isValid) ||
      (!getFieldState('password').isDirty && getFieldState('password').isTouched)
    ) {
      setHidePasswordEyeIcon(true);
    } else {
      setHidePasswordEyeIcon(false);
    }
  }, [getFieldState, isValid]);

  const recaptchaRef = useRef();

  const fetchToken = async () => {
    const token = await recaptchaRef.current.executeAsync();
    return token;
  };

  const { data, isFetched, refetch } = useQuery(['recaptchaToken'], fetchToken, {
    enabled: shouldDisplayCaptcha, // disable this query from automatically running
  });

  const onSubmit = async (values) => {
    if (!shouldDisplayCaptcha) onSubmitForm(values);
    else if (data && data.length > 0) {
      onSubmitForm(values);
    } else if (!isFetched) {
      const { data: token } = refetch();
      if (token && token.length > 0) {
        onSubmitForm(values);
      }
    }
  };

  const onClickResetUnlockAccount = () => {
    window.open(APP_CONSTANTS.PASSWORD_SERVICE_URL(accountId), '_blank');
  };
  const onClickBackLogin = () => {
    router.navigate({ to: ROUTES_PATHS.LOGIN_URL });
  };
  const handleGuestLogin = async () => {
    const res = await guestLogin();
    if (res) {
      localStorage.setItem('v2t', res?.token);
      localStorage.setItem('v2at', res?.accessToken);
      localStorage.setItem('v2rt', res?.refreshToken);
      window.location.href = APP_CONSTANTS.GUEST_LOGIN_URL;
    }
  };

  const handleSignUp = async () => {
    window.location.href = APP_CONSTANTS.SIGN_UP_URL(accountId);
  };
  const isError = formErrors?.username?.length > 0;
  const tooltipOffset = [-10, -5];
  const options = useMemo(() => {
    let arr = [];
    if (localUserTexts) {
      arr.push(localUserTexts);
    }
    if (domainsList?.length) {
      arr = arr.concat(domainsList.map((domain) => domain?.displayValue));
    }
    return arr;
  }, [localUserTexts, domainsList]);
  return (
    <StyledUserForm isError={isError}>
      {shouldDisplayCaptcha && (
        <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey="6LfbpAsiAAAAAHMKwnTIaO-wsG61M7LtlIK0JUCZ" />
      )}
      {isForgotPasswordPage ? (
        // FORGOT PASSWORD page
        <form id="login-form" onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="username-field" className="user-form-placeholder">
            <span>{usernameText}</span>
            <TextField
              id="username-field"
              name="username"
              className="left-side-form-field"
              autoFocus
              placeholder=""
              digitsLimit={200}
              control={control}
              data-testid="forgotPassword-username-field"
              adornmentTooltipOffset={tooltipOffset}
            />
          </label>
          <label htmlFor="email-field" className="user-form-placeholder">
            <span>{emailText}</span>
            <TextField
              id="email-field"
              name="email"
              type="email"
              className="left-side-form-field"
              autoFocus
              placeholder=""
              digitsLimit={200}
              control={control}
              data-testid="forgotPassword-email-field"
              adornmentTooltipOffset={tooltipOffset}
            />
          </label>
          <div role="presentation" className="back-to-login" onClick={onClickBackLogin}>
            <GoBackIcon />
            <span>{backToLogin}</span>
          </div>
          <button
            type="submit"
            id="left-side-submit-button"
            data-testid="forgotPassword-submit-button"
            disabled={!isValid}
            className={!isValid ? 'disabled' : ''}
          >
            {submitText}
          </button>
        </form>
      ) : (
        // LOGIN page
        <form id="login-form" onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="username-field" className="user-form-placeholder">
            <span>{usernameText}</span>
            <div className="username-input-wrapper">
              <TextField
                id="username-field"
                name="username"
                className="left-side-form-field username-field"
                autoFocus
                placeholder=""
                digitsLimit={200}
                control={control}
                data-testid="login-username-field"
                data-cy="login-username-field"
                adornmentTooltipOffset={tooltipOffset}
              />
            </div>
          </label>
          <label htmlFor="password-field" className="user-form-placeholder">
            <span>{passwordText}</span>
            <div className="password-input-wrapper">
              <TextField
                id="password-field"
                name="password"
                className="left-side-form-field password-field"
                type={inputType}
                placeholder=""
                digitsLimit={200}
                control={control}
                data-testid="login-password-field"
                data-cy="login-password-field"
                adornmentTooltipOffset={tooltipOffset}
              />
              <img
                aria-hidden="true"
                alt="toggle-password-img"
                src={eyePasswordSrc}
                className="toggle-password-img"
                onClick={onClickPasswordEyeIcon}
                placeholder=""
                data-testid="login-password-img"
                style={hidePasswordEyeIcon ? { display: 'none' } : {}}
              />
            </div>
          </label>
          {isDomainField && (
            <div>
              <label htmlFor="domain-field" className="user-form-placeholder">
                <span>{domainText}</span>
                <AutoComplete
                  options={options}
                  onChangeFormValues={(name, value) => setValue(name, value)}
                  fieldPlaceholder=""
                  fieldName="domain"
                  customAutoCompleteStyle={customAutoCompleteStyle}
                  fieldClassName="left-side-form-field domain-field"
                  control={control}
                />
              </label>
            </div>
          )}
          <RememberForgotSection onClickForgotPassword={onClickForgotPassword} />
          <button
            type="submit"
            id="left-side-submit-button"
            disabled={isloginDisable || !isValid || isLoading}
            className={isloginDisable || !isValid ? 'disabled' : ''}
            data-testid="login-submit-button"
            data-cy="login-submit-button"
          >
            <StyledLoading isLoading={isLoading}>
              <CircularProgress size={14} className="loadingIcon" />
              <Box>{signInText}</Box>
            </StyledLoading>
          </button>
          {(enableGuestMode || enableSignUp) && (
            <div className="login-navigation" role="navigation" data-testid="login-navigation-button">
              {noUser}
              {enableSignUp && (
                <a role="navigation" aria-hidden="true" onClick={handleSignUp} data-testid="login-signUp">
                  {signUp}
                </a>
              )}
              {enableGuestMode && enableSignUp && <span>{orText || 'or'}</span>}
              {enableGuestMode && (
                <a role="navigation" aria-hidden="true" onClick={handleGuestLogin} data-testid="login-guest-button">
                  {loginAsGuest}
                </a>
              )}
            </div>
          )}
          {enablePasswordServices && (
            <div
              className="login-navigation login-reset-password"
              role="navigation"
              aria-hidden="true"
              onClick={onClickResetUnlockAccount}
              data-testid="login-reset-password"
            >
              <a>{`${resetPassword}\\${unlockAccount}`}</a>
            </div>
          )}
        </form>
      )}
    </StyledUserForm>
  );
}

UserForm.propTypes = {
  onSubmitForm: PropTypes.func,
  isDomainField: PropTypes.bool,
  onClickForgotPassword: PropTypes.func,
  formErrors: PropTypes.object,
  inputType: PropTypes.string,
  eyePasswordSrc: PropTypes.string,
  onClickPasswordEyeIcon: PropTypes.func,
  resetFormErrors: PropTypes.func,
  shouldDisplayCaptcha: PropTypes.bool,
};
