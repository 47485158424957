import { useState } from 'react';
import { ReactComponent as FilterIcon } from 'images/icons/gh_filter.svg';
import { ReactComponent as SortIcon } from 'images/icons/sort-down-icon.svg';
import { FilterDropdown } from './filterDropdown';
import { useStyles, StyledFilterItem } from './headerSelectorStyles';

export const HeaderFiltersSelection = ({ fileTypes }) => {
  const styles = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedMainFilter, setSelectedMainFilter] = useState('');

  const handleFilterClick = (isItemSelected) => {
    if (!isOpen) {
      if (isItemSelected) {
        setSelectedMainFilter('Time');
      }

      setIsOpen(true);
    }
  };

  return (
    <div className={styles.root}>
      <StyledFilterItem onClick={() => handleFilterClick()}>
        <div className={styles.filterItem}>
          <FilterIcon />
          <p>All</p>
        </div>
      </StyledFilterItem>

      <StyledFilterItem onClick={() => handleFilterClick(true)}>
        <div className={styles.filterItem}>
          <p>Time</p>
          <SortIcon />
        </div>

        {isOpen && (
          <FilterDropdown
            setIsOpen={setIsOpen}
            fileTypes={fileTypes}
            selectedMainFilter={selectedMainFilter}
            setSelectedMainFilter={setSelectedMainFilter}
          />
        )}
      </StyledFilterItem>
    </div>
  );
};
