import { Box, styled } from '@mui/material';
import { TextareaAutosize } from '@mui/base';

export const StyledActivitiesTitle = styled(Box)(({ theme }) => ({
  fontSize: theme?.fontSize?.medium,
  color: theme?.palette?.text?.txt_default,
  marginBottom: '18px',
  padding: '0px 8px',
  lineHeight: '24px',
}));

export const StyledActivitiesWrapper = styled(Box)(({ theme }) => ({
  padding: '18px 24px 0',
  border: theme?.shape?.border?.border,
  borderRadius: '4px',

  ' .fr-view': {
    padding: '2px 8px',
  },

  '.fr-view': {
    '&:not(:focus)': {
      display: '-webkit-box',
      WebkitLineClamp: 3,
      MsLineClamp: 3,
      OLineClamp: 3,
      WebkitBoxOrient: 'vertical',
      MsBoxOrient: 'vertical',
      OBoxOrient: 'vertical',
      maxHeight: '68px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}));

export const StyledDescriptionField = styled(TextareaAutosize)(({ theme, isNewField }) => ({
  fontSize: theme?.fontSize?.small,
  color: theme?.palette?.text?.txt_default,
  marginBottom: '18px',
  lineHeight: '24px',
  height: '84px !important',
  font: 'inherit',
  outline: 'none',
  resize: 'none',
  padding: '6px 40px 6px 8px',
  background: isNewField ? theme?.palette?.background?.bg_new_input_text : 'none',
  border: 'none',
  borderRadius: '4px',

  '&:focus': {
    background: theme?.palette?.background?.bg_new_input_text,
  },

  '&::placeholder': {
    color: theme?.palette?.text?.secondary_txt,
  },
}));

export const StyledTotalTime = styled(Box)(({ theme, isNewField, isLongHours }) => ({
  fontSize: theme?.fontSize?.small,
  color: theme?.palette?.text?.txt_default,
  marginBottom: '18px',
  padding: '4px 8px',
  lineHeight: '24px',
  gap: '12px',
  font: 'inherit',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '4px',
  background: isNewField ? theme?.palette?.background?.bg_new_input_text : 'none',
  border: 'none',
  outline: 'none',

  '&:focus-within': {
    background: theme?.palette?.background?.bg_new_input_text,
  },

  '& > div': {
    display: 'flex',
    alignItems: 'baseline',
    '& > input': {
      background: 'transparent',
      outline: 'none',
      border: 'none',
      '-webkit-appearance': 'none',
      width: '20px',
    },
    'input[name=hours]': {
      ...(isLongHours && {
        width: '26px',
      }),
    },
  },

  'input::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  'input::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  'input[type = number]': {
    '-moz-appearance': 'textfield',
  },
}));

export const StyledActivityLog = styled(Box)(({ theme }) => ({
  position: 'relative',

  '.sections-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    gap: '3px',
    padding: '5px 8px',
    borderRadius: '5px',

    '&:hover': {
      backgroundColor: theme?.palette?.grey?.bt_alt_grey_reg,
      cursor: 'pointer',

      '.delete-activity': {
        display: 'flex',
      },
    },

    section: {
      '.truncated': {
        display: '-webkit-box',
        WebkitLineClamp: 2,
        MsLineClamp: 2,
        OLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        MsBoxOrient: 'vertical',
        OBoxOrient: 'vertical',
        overflow: 'hidden',
      },

      span: {
        '&:first-child': {
          fontFamily: theme?.fonts?.robotoMedium,
          marginRight: '5px',
        },
      },
    },

    '.delete-activity': {
      display: 'none',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      right: 0,
      top: '-27px',
      padding: '6px',
      boxShadow: '0px 0px 3px 2px rgba(0, 0, 0, 0.1)',
      borderRadius: '2px',
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: theme?.palette?.grey?.bt_alt_grey_reg,
      },
    },
  },

  '.truncate-btn-wrapper': {
    marginLeft: '8px',
  },
}));

export const StyledUserDropdownWrapper = styled(Box)(() => ({
  margin: '-15px 0 15px 10px',
}));
