import { Box, MenuItem, Popper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledAssigneeDropdown = styled(Popper, {
  shouldForwardProp: (prop) => prop !== 'width',
})(({ theme, width }) => ({
  '& .MuiPaper-root': {
    minWidth: `${width}px !important`,
    width: `${width}px`,
    boxShadow: '0px 2px 8px rgba(118, 130, 150, 0.35)',
    borderRadius: '6px',
  },
  '& .MuiMenuItem-root': {
    cursor: 'pointer',
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    color: theme.palette.text.title_txt,
    fontFamily: theme.fonts.robotoRegular,
    marginRight: '22px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: theme.palette.grey.bt_alt_grey_light,
    },
    '&:disabled': {
      color: theme.palette.grey.bt_def_grey_light,
    },
  },
  '&& .Mui-selected': {
    backgroundColor: theme.palette.grey.bt_alt_grey_light,
    '&:hover': {
      backgroundColor: theme.palette.grey.bt_alt_grey_light,
    },
  },
  '& .MuiTypography-root': {
    padding: '0 !important',
  },
  '& .MuiButton-root': {
    gap: 10,
  },
  '.unassigned-option, .logged-in-user-option, .assign-to-team-option': {
    marginRight: '13px',
  },
}));

export const StyledHeaderProps = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '12px 16px 8px 16px',
  gap: '8px',
}));

export const StyledNoResults = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '4px 12px 12px',
  fontSize: '0.875rem',
  color: theme.palette.text.title_txt,
  fontFamily: theme.fonts.robotoRegular,
}));

export const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'itemHeight',
})(({ theme, itemHeight }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '6px 8px',
  color: theme.palette.text.secondary_txt,
  height: `${itemHeight}px`,

  '& .MuiAvatar-root': {
    width: '26px !important',
    height: '26px !important',
  },
}));

export const StyledGroupTitle = styled('div')(({ theme }) => ({
  height: '20px',
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text.secondary_txt,
  fontSize: '0.75rem',
  padding: '4px 14px',
  fontFamily: theme.fonts.robotoRegular,
}));

export const StyledDivider = styled('div')(({ theme }) => ({
  height: '1px',
  backgroundColor: theme.palette.grey.divider_grey_v1,
  display: 'flex',
  margin: '0px 14px 4px 14px',
}));
