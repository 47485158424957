import { useRbTexts } from 'remote-state/applicationHooks';

export function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    addBtnText: texts?.['spaces.resolutionPanel.footer.message.upload.fromSRAttachments.AddBtn'],
    cancelBtnText: texts?.['spaces.resolutionPanel.footer.message.upload.fromSRAttachments.CancelBtn'],
    defaultSearchPlaceholder:
      texts?.['spaces.resolutionPanel.footer.message.upload.fromSRAttachments.searchAttachment'],
    selectAttachmentsTitleText:
      texts?.['spaces.resolutionPanel.footer.message.upload.fromSRAttachments.selectAttachments'],
  };

  return componentTexts;
}
