import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledCheckbox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .MuiCheckbox-root': {
    padding: '0px',
  },
  '& .assignee': {
    marginRight: '8px',
  },
}));
