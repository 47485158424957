import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledLeftSideUpperLogo = styled(Box)(({ theme }) => ({
  display: 'none',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '16%',
  backgroundColor: theme.palette.generic.sysaid,

  '@media screen and (max-width: 1024px)': {
    display: 'flex !important',
    borderRadius: '10px',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
}));
