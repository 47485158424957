import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const variantStyles = {
  primary: (theme) => ({
    backgroundColor: theme.palette.generic.sysaid,
    color: theme.palette.generic.primary,
    '&:hover, &&&&.Mui-focusVisible': {
      backgroundColor: theme.palette.sysaid_very_light.contrastText,
    },
    '&:disabled': {
      backgroundColor: theme.palette.generic.sysaid_mid,
      cursor: 'auto',
      color: theme.palette.generic.primary,
    },
  }),
  secondary: (theme) => ({
    backgroundColor: theme.palette.grey.bt_alt_grey_reg,
    color: theme.palette.generic.sysaid,
    '&:hover, &&&&.Mui-focusVisible': {
      backgroundColor: theme.palette.grey.bt_alt_grey_hover,
      color: theme.palette.sysaid_very_light.contrastText,
    },
    '&:disabled': {
      cursor: 'auto',
      backgroundColor: theme.palette.grey.bt_alt_grey_light,
      color: theme.palette.generic.sysaid_mid,
    },
  }),
};

const StyledButtonIgnoreProps = ['variant', 'iconPlacement', 'completed'];

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => !StyledButtonIgnoreProps.includes(prop),
})(({ theme, variant, iconPlacement }) => ({
  display: 'flex',
  flexDirection: iconPlacement === 'left' ? 'row !important' : 'row-reverse',
  height: '32px',
  fontSize: '14px',
  fontFamily: theme.fonts.robotoRegular,
  outline: 'none',
  border: 'none',
  padding: '4px 16px',
  gap: '4px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '60px',
  cursor: 'pointer',
  lineHeight: 'normal',
  textTransform: 'none',
  ...variantStyles[variant]?.(theme),

  '.btn-text': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

const loaderVariantStyles = {
  primary: (theme) => ({
    color: theme?.palette?.generic?.primary,
  }),
  secondary: (theme) => ({
    color: theme?.palette?.generic?.sysaid,
  }),
};

export const StyledTypeBasedCircularProgress = styled(Box)(({ theme, variant }) => ({
  '.Loading': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20px',
    height: '20px',
  },

  '&&& .MuiCircularProgress-root': {
    color: `${theme?.palette?.generic?.primary} !important`,
    ...loaderVariantStyles[variant]?.(theme),
  },
}));
