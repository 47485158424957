import { useCallback } from 'react';
import { SwitchToggle } from 'common/components/controls/Switch/SwitchToggle';
import MultiSelectField from 'common/components/controls/MultiSelectField';
import Hint from 'features/templateBuilder/TemplateSection/HintIcon';
import useTexts from 'features/templateBuilder/useTexts';
import { StyledFieldHeader, StyledFieldLabel } from 'common/components/controls/MultiSelectField/style';
import { StyledPropertyWrapper } from '../StyledTemplatePropertiesPanel';

export default function LimitEditProperty(props) {
  const {
    switchId,
    allGroups,
    selectedGroups,
    showLimitEdit,
    isSwitchDisabled,
    onSwitchChange,
    handleGroupChange,
    isSection,
  } = props;
  const {
    exceptionGroupsViewText,
    exceptionGroupsEditText,
    exceptionGroupsViewSectionTooltip,
    exceptionGroupsEditFieldTooltip,
    exceptionGroupsPlaceholder,
    limitEditTitle,
    limitViewTitle,
  } = useTexts();

  const handleSelectGroups = useCallback(
    (selectedGroupsList = []) => {
      handleGroupChange(selectedGroupsList);
    },
    [handleGroupChange],
  );

  const handleSwitchChange = (event) => {
    onSwitchChange(event);
  };

  return (
    <StyledPropertyWrapper data-testid="limit-edit">
      <SwitchToggle
        handleSwitchChange={handleSwitchChange}
        isChecked={showLimitEdit}
        switchText={isSection ? limitViewTitle : limitEditTitle}
        isTemplateBuilder
        isDisabled={isSwitchDisabled}
        switchId={switchId}
        classNames={{ labelClass: 'template-switch-toggle-label' }}
      />
      {showLimitEdit && (
        <>
          <StyledFieldHeader>
            <StyledFieldLabel>
              {isSection ? exceptionGroupsViewText : exceptionGroupsEditText}
              <Hint hintText={isSection ? exceptionGroupsViewSectionTooltip : exceptionGroupsEditFieldTooltip} />
            </StyledFieldLabel>
          </StyledFieldHeader>
          <MultiSelectField
            itemList={allGroups}
            selectedItemList={selectedGroups}
            handleSelectItems={handleSelectGroups}
            captionString="groupName"
            keyString="id"
            fieldPlaceholder={exceptionGroupsPlaceholder}
          />
        </>
      )}
    </StyledPropertyWrapper>
  );
}
