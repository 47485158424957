import { useSelector } from 'react-redux';
import { useRouter } from '@tanstack/react-router';
import { useHandleTicketLock } from 'remote-state/ticketServiceHooks';
import { FileInputLabel } from 'common/components/uploadFile/uploadButton/fileInputLabel';
import { activeUsername } from 'store/userSlice';
import PropTypes from 'prop-types';

export function AddAttachmentButton({
  handleUploadNewAttachment,
  handleUploadMultipleAttachments,
  btnComponent: Button,
  btnProps
}) {
  const username = useSelector(activeUsername);
  const router = useRouter();
  const srId = router.latestLocation?.search?.id;

  const { mutate: lockSR } = useHandleTicketLock(srId);

  const handleChange = ({ target }) => {
    const files = Object.values(target.files);
    if (files.length > 1) {
      handleUploadMultipleAttachments(files);
    } else {
      handleUploadNewAttachment(files[0]);
    }
    target.value = '';
  };

  const lockTicket = () => {
    lockSR({ username, srId, queueLocking: false });
  };

  return (
    <FileInputLabel
      inputBtnComponent={Button}
      btnProps={btnProps}
      handleClick={lockTicket}
      handleChange={handleChange}
    />
  );
}

AddAttachmentButton.propTypes = {
  handleUploadNewAttachment: PropTypes.func,
  handleUploadMultipleAttachments: PropTypes.func,
  btnComponent: PropTypes.object,
  btnProps: PropTypes.object
}