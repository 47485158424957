import { ROUTES_PATHS } from 'constants/index';

export const DOCS_ROOT_URL = 'https://documentation.sysaid.com/docs';
export const CLASSIC_ROUTE = ROUTES_PATHS.EXTERNAl_PAGES_URL;
export const DOCUMENTATION_RB_TOKEN = 'spaces.menu.help.documentation';

export const SPACES_DOCS_URL_MAP = {
  [ROUTES_PATHS.QUEUE]: '/queue',
  [ROUTES_PATHS.SETTINGS_URL]: '/spaces-settings',
  [ROUTES_PATHS.TEMPLATE_LIST_URL]: '/template-list',
  [ROUTES_PATHS.TEMPLATE_URL]: '/template-designer',
  [ROUTES_PATHS.TICKET_URL]: '/service-record-docs',
  [ROUTES_PATHS.WORKFLOW_DESIGNER]: '/workflow-designer',
};
const SETTINGS_PAGE_CLASSIC_URLS = [
  {
    path: '/Settings.jsp?selectedItem=CustomizeEntities.js',
    url: '/fields-customization',
  },
  {
    path: '/Settings.jsp?selectedItem=ChangeProblemSettings.jsp',
    url: 'sr-status-settings',
  },
  {
    path: '/Settings.jsp?selectedItem=RulesPriority.jsp',
    url: '/priorities',
  },
  {
    path: '/Settings.jsp?selectedItem=CustomLists.jsp',
    url: '/lists',
  },
  {
    path: '/Settings.jsp?selectedItem=CategorySettings.jsp',
    url: '/categories',
  },
  {
    path: '/Settings.jsp?selectedItem=ImportCategories.jsp',
    url: '/import-categories',
  },
  {
    path: '/Settings.jsp?selectedItem=RulesEmailList.jsp',
    url: '/email-rules?highlight=email%20rules',
  },
  {
    path: '/Settings.jsp?selectedItem=RoutingRulesList.jsp',
    url: '/routing-rules',
  },
  {
    path: '/Settings.jsp?selectedItem=EscalationList.jsp',
    url: '/escalation-rules',
  },
  {
    path: '/Settings.jsp?selectedItem=RemindersList.jsp',
    url: '/reminders',
  },
  {
    path: '/Settings.jsp?selectedItem=AgreementList.jsp',
    url: '/service-agreement',
  },
  {
    path: '/Settings.jsp?selectedItem=Measurements.jsp',
    url: '/measurements',
  },
  {
    path: '/Settings.jsp?selectedItem=MeasurementLists.jsp',
    url: '/measurement-lists',
  },
  {
    path: '/Settings.jsp?selectedItem=DueDateList.jsp',
    url: '/due-dates',
  },
  {
    path: '/Settings.jsp?selectedItem=HelpDeskSettings.jsp',
    url: '/general',
  },
  {
    path: '/Settings.jsp?selectedItem=AutomaticTexts.jsp',
    url: '/automatic-texts',
  },
  {
    path: '/ChatQueueList.jsp',
    url: '/chat-queues-list',
  },
  {
    path: '/Settings.jsp?selectedItem=SurveyQuestionsList.jsp',
    url: '/survey-introduction',
  },
  {
    path: '/Settings.jsp?selectedItem=UserPortalGeneralSettings.jsp',
    url: '/self-service-portal-general-settings',
  },
  {
    path: '/Settings.jsp?selectedItem=SelfServicePortalSettings.jsp',
    url: '/self-service-portal-settings',
  },
  {
    path: '/Settings.jsp?selectedItem=SREdit.jsp&id=0&end=yes&fromId=EndUser',
    url: '/incident-submission-form',
  },
  {
    path: '/Settings.jsp?selectedItem=SREdit.jsp&id=0&fromId=EndUserRequest',
    url: '/request-submission-form',
  },
  {
    path: '/Settings.jsp?selectedItem=Preferences.jsp&EndUserSettings=CustomizeForm',
    url: '/end-user-portal-settings',
  },
  {
    path: '/Settings.jsp?selectedItem=SCToolboxItemsList.jsp',
    url: '/toolbox-widget-list',
  },
  {
    path: '/Settings.jsp?selectedItem=SCBannersList.jsp',
    url: '/banners-list',
  },
  {
    path: '/Settings.jsp?selectedItem=News.jsp',
    url: '/edit-news',
  },
  {
    path: '/Settings.jsp?selectedItem=SCThemesList.jsp',
    url: '/themes-list',
  },
  {
    path: '/Settings.jsp?selectedItem=CustomNotifications.jsp',
    url: '/customize-notifications',
  },
  {
    path: '/Settings.jsp?selectedItem=ReoccurringNotification.jsp&reminders=YES',
    url: '/reminder-notifications',
  },
  {
    path: '/Settings.jsp?selectedItem=RFCNotification.jsp',
    url: '/workflows',
  },
  {
    path: '/Settings.jsp?selectedItem=AccountDefaults.jsp',
    url: '/account-defaults',
  },
  {
    path: '/Settings.jsp?selectedItem=CustomizeAppearance.jsp',
    url: '/appearance',
  },
  {
    path: '/Settings.jsp?selectedItem=WebForms.jsp',
    url: '/',
  },
  {
    path: '/UserManager.jsp',
    url: '/user-management-1',
  },
  {
    path: '/EndUserManager.jsp',
    url: '/user-management-1',
  },
  {
    path: '/UserGroups.jsp',
    url: '/user-management-1',
  },
  {
    path: '/CompanyList.jsp',
    url: '/import-companies',
  },
  {
    path: '/UserSelfService.jsp',
    url: '/password-services',
  },
  {
    path: '/Settings.jsp?selectedItem=UserSelfServiceSettings.jsp',
    url: '/settings-general',
  },
  {
    path: '/Settings.jsp?selectedItem=SecurityQuestions.jsp',
    url: '/security-questions',
  },
  {
    path: '/Settings.jsp?selectedItem=UserSelfServiceNotifications.jsp',
    url: '/settings-notifications',
  },
  {
    path: '/UserSelfServiceLog.jsp',
    url: '/audit-log',
  },
  {
    path: '/Settings.jsp?selectedItem=AccountDefaults.jsp',
    url: '/account-defaults',
  },
  {
    path: '/Settings.jsp?selectedItem=Translate.jsp',
    url: '/translation',
  },
  {
    path: '/Settings.jsp?selectedItem=OperatingTimes.jsp',
    url: '/operating-times',
  },
  {
    path: '/Settings.jsp?selectedItem=DashboardEdit.jsp',
    url: '/dashboard',
  },
  {
    path: '/Settings.jsp?selectedItem=TimersList.jsp',
    url: '/',
  },
  {
    path: '/Settings.jsp?selectedItem=AuditLog.jsp',
    url: '/event-logs',
  },
  {
    path: '/Settings.jsp?selectedItem=EmailList.jsp',
    url: '/email-integration-list',
  },
  {
    path: '/Settings.jsp?selectedItem=IntegrationSMS.jsp',
    url: '/text-messages',
  },
  {
    path: '/Settings.jsp?selectedItem=LdapList.jsp',
    url: '/ldap-integration',
  },
  {
    path: '/Settings.jsp?selectedItem=IntegrationExchange.jsp',
    url: '/calendar-sync',
  },
  {
    path: '/Settings.jsp?selectedItem=MarketPlace.jsp',
    url: '/marketplace',
  },
  {
    path: '/Settings.jsp?selectedItem=Integrations.jsp',
    url: '/integrations',
  },
  {
    path: '/Settings.jsp?selectedItem=AssetTypes.jsp',
    url: '/asset-types',
  },
  {
    path: '/Settings.jsp?selectedItem=ImportAssets.jsp',
    url: '/import-assets',
  },
  {
    path: '/Settings.jsp?selectedItem=PatchManagementSettings.jsp',
    url: '/patch-management-settings',
  },
  {
    path: '/Settings.jsp?selectedItem=PatchManagementPolicyList.jsp',
    url: '/patch-management-policies',
  },
  {
    path: '/Settings.jsp?selectedItem=RCSettings.jsp',
    url: '/remote-control-settings',
  },
  {
    path: '/Settings.jsp?selectedItem=CustomizedSnmpOids.jsp',
    url: '/snmp-oids-settings',
  },
  {
    path: '/Settings.jsp?selectedItem=UpdateAssetRegistrySettings.jsp',
    url: '/registry-keys',
  },
  {
    path: '/Settings.jsp?selectedItem=ReoccurringNotification.jsp&system=YES',
    url: '/notifications',
  },
  {
    path: '/Settings.jsp?selectedItem=SystemNotification.jsp',
    url: '/asset-management-events',
  },
  {
    path: '/Settings.jsp?selectedItem=VendorsList.jsp',
    url: '/vendor-management',
  },
  {
    path: '/Settings.jsp?selectedItem=NetworkDiscovery.jsp',
    url: '/network-discovery-guide-general',
  },
  {
    path: '/Settings.jsp?selectedItem=WMIScan.jsp',
    url: '/wmi-scan',
  },
  {
    path: '/Settings.jsp?selectedItem=Discovery.jsp',
    url: '/network-discovery-wizard',
  },
  {
    path: '/Settings.jsp?selectedItem=UpdateAssetData.jsp',
    url: '/update-asset-data',
  },
  {
    path: '/Settings.jsp?selectedItem=UpgradeAgentsVersion.jsp',
    url: '/upgrade-agents-version',
  },
  {
    path: '/Settings.jsp?selectedItem=RDSList.jsp',
    url: '/rds',
  },
  {
    path: '/Settings.jsp?selectedItem=DeployAgents.jsp',
    url: '/agent-deployment-plan',
  },
  {
    path: '/Settings.jsp?selectedItem=AgentSettingsList.jsp',
    url: '/agent-deployment-plan-form',
  },
  {
    path: '/Settings.jsp?selectedItem=CredentialsList.jsp',
    url: '/',
  },
  {
    path: '/Settings.jsp?selectedItem=Download.jsp',
    url: '/network-discovery-guide',
  },
  {
    path: '/Settings.jsp?selectedItem=DiscoveryLog.jsp',
    url: '/network-discovery-guide',
  },
  {
    path: '/Settings.jsp?selectedItem=MonitorSettings.jsp',
    url: '/monitor-settings',
  },
  {
    path: '/Settings.jsp?selectedItem=MonitorTemplates.jsp',
    url: '/workstationserver-templates',
  },
  {
    path: '/Settings.jsp?selectedItem=MonitorTemplates.jsp&type=3',
    url: '/workstation-server-templates',
  },
  {
    path: '/Settings.jsp?selectedItem=MonitorNotifications.jsp',
    url: '/notifications-2',
  },
  {
    path: '/Settings.jsp?selectedItem=CITypeList.jsp',
    url: '/ci-type-list',
  },
  {
    path: '/Settings.jsp?selectedItem=CISubTypes.jsp',
    url: '/ci-types',
  },
  {
    path: '/Settings.jsp?selectedItem=CIRelationTypeList.jsp',
    url: '/cmdb?highlight=CI%20Relation%20Types',
  },
  {
    path: '/Settings.jsp?selectedItem=CITemplateList.jsp',
    url: '/ci-types',
  },
  {
    path: '/Settings.jsp?selectedItem=CMDBImports.jsp',
    url: '/cmdb',
  },
  {
    path: '/Settings.jsp?selectedItem=ImportCIs.jsp',
    url: '/cmdb',
  },
  {
    path: '/Settings.jsp?selectedItem=CMDBSettings.jsp',
    url: '/cmdb',
  },
  {
    path: '/Settings.jsp?selectedItem=ImportAsset2CISettings.jsp',
    url: '/cmdb',
  },
  {
    path: '/Settings.jsp?selectedItem=ImportCIRelations.jsp',
    url: '/cmdb',
  },
];

const ASSETS_CLASSIC_URLS = [
  {
    path: '/AssetManagementDash.jsp',
    url: '/asset-management',
  },
  {
    path: '/SystemList.jsp',
    url: '/asset-list',
  },
  {
    path: '/System.jsp',
    url: '/asset-groups',
  },
  {
    path: '/SoftwareList.jsp',
    url: '/software-products',
  },
  {
    path: '/PatchManagementList.jsp',
    url: '/patch-management',
  },
  {
    path: '/CatalogList.jsp',
    url: '/catalog',
  },
  {
    path: '/AvailabilityLog.jsp',
    url: '/history',
  },
  {
    path: '/SupplierList.jsp',
    url: '/supplier-list',
  },
  {
    path: '/OnlineUsersList.jsp',
    url: '/online-users',
  },
  {
    path: '/Alerts.jsp',
    url: '/alerts',
  },
  {
    path: '/EventLog.jsp',
    url: '/event-log',
  },
  {
    path: '/LastPoll.jsp',
    url: '/last-poll',
  },
  {
    path: '/MonitorConfiguration.jsp',
    url: '/workstationsserversnetwork-devices',
  },
  {
    path: '/MonitorConfiguration.jsp?type=1',
    url: '/workstationsserversnetwork-devices',
  },
  {
    path: '/MonitorConfiguration.jsp?type=4',
    url: '/workstationsserversnetwork-devices',
  },
  {
    path: '/RemoteAccessMainPage.jsp',
    url: '/',
  },
  {
    path: '/RemoteAccessActiveSessions.jsp',
    url: '/',
  },
  {
    path: '/RemoteAccessibleUsers.jsp',
    url: '/',
  },
  {
    path: '/RemoteAccessibleAssets.jsp',
    url: '/',
  },
  {
    path: '/RemoteAccessLog.jsp',
    url: '/',
  },
  {
    path: '/CIList.jsp',
    url: '/ci-list',
  },
];

const ANALYTICS_CLASSIC_URLS = [
  {
    path: '/Home.jsp',
    url: '/introduction',
  },
  {
    path: '/Dashboard.jsp',
    url: '/dashboard',
  },
  {
    path: '/HelpMat.jsp',
    url: '/matrix',
  },
  {
    path: '/reports',
    url: '/customize-and-run-a-report-my-reports',
  },
  {
    path: '/Statistics.jsp',
    url: '/it-benchmarks',
  },
  {
    path: '/AnimatedSummary.jsp',
    url: '/animated-summary',
  },
  {
    path: '/BIDashboard.jsp',
    url: '/bi-analytics',
  },
  {
    path: '/SLAScreen.jsp',
    url: '/slaslm-guide',
  },
];
const TOOLS_CLASSIC_URLS = [
  {
    path: '/ProjectList.jsp',
    url: '/project-list',
  },
  {
    path: '/TaskList.jsp',
    url: '/task',
  },
  {
    path: '/SysAidCalendar.jsp',
    url: '/my-calendar',
  },
  {
    path: '/CalendarSettings.jsp',
    url: '/my-calendar-settings',
  },
  {
    path: '/ChatAdministratorConsole.jsp',
    url: '/',
  },
  {
    path: '/ChatClosedChatsList.jsp',
    url: '/chat-queues-list',
  },
];
export const CLASSIC_DOCS_URL_MAP = [
  ...SETTINGS_PAGE_CLASSIC_URLS,
  ...ASSETS_CLASSIC_URLS,
  ...ANALYTICS_CLASSIC_URLS,
  ...TOOLS_CLASSIC_URLS,
];
