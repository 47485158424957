import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveUser } from 'store/userSlice';
import { getWorkflowFieldAttributes, getWorkflows, updateActionItem } from './workflowService';
import { useAuditLog } from '../../auditLog/hooks';
import { setError } from '../../../../../store/errorSlice';

const ACTION_ITEM_USER_FIELDS = ['submitUser', 'updateUser', 'additionalUser', 'followUpUser', 'requestUser'];
const ASSIGNEE_FIELDS = ['assignedTo', 'assignedGroup'];
const isUserField = (fieldName) => ACTION_ITEM_USER_FIELDS.includes(fieldName);

const parseField = (field) => {
  const { value, type, fieldName } = field;
  const isUser = isUserField(fieldName);

  let parsedValue = value;
  if (type === 'float') {
    parsedValue = value === 0 ? parseFloat(value).toFixed(1) : value;
  } else if (type === 'number') {
    parsedValue = value === '0' ? 0 : value;
  } else if (type === 'select' && !ASSIGNEE_FIELDS.includes(fieldName) && (!value || value === '0')) {
    parsedValue = null;
  }

  return {
    ...field,
    value: parsedValue,
    keyExpr: isUser ? 'value' : 'id',
    requestParam: isUser ? 'usernames' : 'id',
  };
};

const attributeSelector = (originalData) => {
  const queriableFields = new Set();
  const phases = originalData.phases.map((phase) => ({
    ...phase,
    actionItems: phase.actionItems.map((actionItem) => {
      const formValues = {};
      const fields = actionItem.fields?.map((field) => {
        const parsedField = parseField(field);
        const { fieldName, type, value } = parsedField;

        if (!ASSIGNEE_FIELDS.includes(fieldName)) {
          formValues[fieldName] = value;
          if (type === 'select') {
            queriableFields.add(fieldName);
          }
        }

        return parsedField;
      });

      return {
        ...actionItem,
        fields,
        formValues,
      };
    }),
  }));

  return {
    ...originalData,
    phases,
    attributeFields: Array.from(queriableFields),
  };
};

function useWorkflowQuery({ srId, select, notifyOnChangeProps, enabled = true }) {
  return useQuery({
    queryKey: ['workflows', { srId }],
    queryFn: () => getWorkflows({ srId }),
    enabled: Boolean(!!srId && enabled && (typeof srId === 'number')),
    cacheTime: 0,
    staleTime: 1000 * 60 * 60,
    select,
    notifyOnChangeProps,
  });
}

const attributeQueryOptions = {
  all: () => ['attributes'],
  fieldName: (fieldName) => ({
    queryKey: [...attributeQueryOptions.all(), fieldName],
    queryFn: () => getWorkflowFieldAttributes(fieldName),
  }),
  withParams: (fieldName, paramKey, paramValue) => ({
    queryKey: [...attributeQueryOptions.fieldName(fieldName).queryKey, paramValue],
    queryFn: () => getWorkflowFieldAttributes(fieldName, { [paramKey]: paramValue }),
  }),
};

const getAttributeQueryOption = (options = {}) => {
  const { fieldName, paramKey, paramValue } = options;
  if (fieldName && paramValue) {
    const derivedParamKey = paramKey || (isUserField(fieldName) ? 'usernames' : 'id');
    return attributeQueryOptions.withParams(fieldName, derivedParamKey, paramValue);
  }
  if (fieldName) return attributeQueryOptions.fieldName(fieldName);
  return attributeQueryOptions.all();
};

const useWorkflowAttributeQuery = ({ fieldName, paramKey, paramValue, enabled }) => {
  const queryClient = useQueryClient();
  const queryOptions = getAttributeQueryOption({ fieldName, paramKey, paramValue });
  return useQuery({
    ...queryOptions,
    initialData: () => {
      if (!fieldName) return null;
      if (paramKey !== 'query') {
        const identifier = isUserField(fieldName) ? 'value' : 'id';
        const cachedData = queryClient.getQueryData(['attributes', fieldName]);
        const detail = cachedData?.values?.find((item) => item[identifier] === paramValue);
        return cachedData && detail ? { ...cachedData, values: [detail] } : undefined;
      }
      return undefined;
    },
    keepPreviousData: true,
    select: (data) => (!!paramValue && paramKey !== 'query' ? data?.values?.[0] || data?.values : data),
    notifyOnChangeProps: ['tracked'],
    enabled,
  });
};

const shouldFieldTriggerInvalidation = ({ validFields, fieldName }) => validFields.includes(fieldName);
const hasPermission = ({ updateField, assignee, workflowData, username }) => {
  const { fieldName, value } = updateField;
  const isPermissionBasedField = shouldFieldTriggerInvalidation({
    validFields: ['assignedTo', 'assignedGroup'],
    fieldName,
  });
  const {
    permissions: { hasViewOtherActionItemsPermission, hasCreateRFCPermission },
  } = workflowData;
  const isAssignedToActiveUserChanged = assignee === username && value !== username;
  const hasPermissionAndViewChanged = hasViewOtherActionItemsPermission && !isAssignedToActiveUserChanged;
  const hasPermissionOrIsNewAssignee = hasCreateRFCPermission && !(isAssignedToActiveUserChanged || value === username);

  return isPermissionBasedField && (!hasPermissionAndViewChanged || !hasPermissionOrIsNewAssignee);
};

const shouldInvalidateQueries = (updateField, actionItemToUpdate, workflowData, userAccount) => {
  const fieldsRequireQueryInvalidation = shouldFieldTriggerInvalidation({
    validFields: ['status'],
    fieldName: updateField.fieldName,
  });

  return (
    fieldsRequireQueryInvalidation ||
    hasPermission({ updateField, assignee: actionItemToUpdate.assignee, workflowData, username: userAccount?.username })
  );
};

function useSaveActionItem(srId) {
  const queryClient = useQueryClient();
  const { updateAuditLogs } = useAuditLog();
  const userAccount = useSelector(selectActiveUser);
  const dispatch = useDispatch();

  const updateField = (actionItem, fieldName, value) => {
    if (fieldName === 'assignedTo') {
      actionItem.assignee = value;
    } else if (fieldName === 'assignedGroup') {
      actionItem[fieldName] = value;
    }

    const fieldToUpdate = actionItem.fields.find((field) => field.fieldName === fieldName);
    if (fieldToUpdate) fieldToUpdate.value = value;
  };

  const mutationFn = ({ data, actionItemId, phaseId }) =>
    updateActionItem({
      id: srId,
      requestParams: {
        actionItemId,
        phaseId,
        field: JSON.stringify(data),
      },
    });

  return useMutation({
    mutationFn,
    onMutate: async ({ data, actionItemId, phaseId }) => {
      await queryClient.cancelQueries(['workflows', { srId }]);
      const previousWorkflowData = queryClient.getQueryData(['workflows', { srId }]);
      let requiresCacheInvalidation = false;

      queryClient.setQueryData(['workflows', { srId }], (oldWorkflowData) => {
        if (!oldWorkflowData || !oldWorkflowData.phases) return oldWorkflowData;

        const phaseToUpdate = oldWorkflowData.phases.find((phase) => phase.id === phaseId);
        if (!phaseToUpdate) return oldWorkflowData;

        const actionItemToUpdate = phaseToUpdate.actionItems.find((item) => item.id === actionItemId);
        if (!actionItemToUpdate) return oldWorkflowData;

        const updates = Array.isArray(data) ? data : [data];
        requiresCacheInvalidation = updates.some((update) =>
          shouldInvalidateQueries(update, actionItemToUpdate, previousWorkflowData, userAccount),
        );
        updates.forEach((update) => updateField(actionItemToUpdate, update.fieldName, update.value));
        return { ...oldWorkflowData };
      });
      return { previousWorkflowData, requiresCacheInvalidation };
    },
    onError: (error, variables, context) => {
      dispatch(setError(true));
      console.error('Workflow update failed; rolling data back. Error details:', error);
      queryClient.setQueryData(['workflows', { srId }], context.previousWorkflowData);
    },
    onSuccess: (data, variables, context) => {
      dispatch(setError(false));
      if (context.requiresCacheInvalidation) {
        queryClient.invalidateQueries(['workflows', { srId }]);
      }

      if (data) {
        updateAuditLogs({ log: data?.newAuditLogRecords });
      }
    },
  });
}

export { useWorkflowQuery, useSaveActionItem, useWorkflowAttributeQuery, attributeSelector, getAttributeQueryOption };
