import { useEffect, useState, useRef } from 'react';
import Tooltip from 'common/components/tooltip';
import { MenuItem } from '@mui/material';
import { useRouter } from '@tanstack/react-router';
import useTexts from './useTexts';
import { StyledTemplateNameWrapper, StyledTemplateNameLabel, StyledTemplateName } from './style';

function TemplateName({ template }) {
  const templateNameRef = useRef(null);
  const { templateNameLabel } = useTexts();
  const router = useRouter();

  const templateName = template?.templateName;

  const [isEllipsisActive, setIsEllipsisActive] = useState(false);

  const onClick = () => {
    router.navigate({ to: `/spaces/template?id=${template?.id}` });
  };

  useEffect(() => {
    if (templateNameRef?.current) {
      setIsEllipsisActive(templateNameRef.current.clientHeight < templateNameRef.current.scrollHeight);
    }
  }, [templateNameRef]);

  return (
    <MenuItem onClick={onClick}>
      <Tooltip
        placement="top"
        title={templateName}
        disableHoverListener={!isEllipsisActive}
        text={
          <StyledTemplateNameWrapper>
            <StyledTemplateNameLabel>{templateNameLabel}</StyledTemplateNameLabel>
            <StyledTemplateName ref={templateNameRef}>{templateName}</StyledTemplateName>
          </StyledTemplateNameWrapper>
        }
      />
    </MenuItem>
  );
}
export default TemplateName;
