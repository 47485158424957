import { useRef, forwardRef, useReducer, useCallback, useState, useMemo } from 'react';
import { Divider, CircularProgress } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { ProgressWrapper } from 'containers/App/style';
import { useSaveSettings } from 'remote-state/mySettingsHooks';
import i18n from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectDirection, setDirectionChanged } from '../../../store/globalSlice';
import { MY_SETTINGS_ACTIONS, ME_MENU_ACTIONS } from './constants';
import MeMenuHeader from './meMenuHeader';
import MeMenuBody from './meMenuBody';
import MeMenuFooter from './meMenuFooter';
import { StyledMeMenu } from './styles';
import { checkPasswordValid, checkPasswordMismatch, emailNotificationsFormat } from './utils';
import { validatePassword } from '../../../services/userService';

const settingsReducer = (state, action) => {
  switch (action.type) {
    case MY_SETTINGS_ACTIONS.VALIDATE_CURRENT_PASSWORD:
      return {
        ...state,
        isCurrentPasswordValid: action.payload,
      };
    case MY_SETTINGS_ACTIONS.UPDATE_CURRENT_PASSWORD:
      return {
        ...state,
        currentPassword: action.payload,
      };
    case MY_SETTINGS_ACTIONS.UPDATE_PASSWORD:
      return {
        ...state,
        password: action.payload,
        ...checkPasswordValid(action.payload),
        ...checkPasswordMismatch({ password: action.payload, confirmPassword: state.confirmPassword }),
      };
    case MY_SETTINGS_ACTIONS.UPDATE_CONFIRM_PASSWORD:
      return {
        ...state,
        confirmPassword: action.payload,
        ...checkPasswordMismatch({ password: state.password, confirmPassword: action.payload }),
      };
    case MY_SETTINGS_ACTIONS.UPDATE_SETTINGS:
      return {
        ...state,
        updatedSettings: {
          ...state.updatedSettings,
          [action.payload.fieldName]: action.payload.currentValue,
        },
      };
    default:
      return state;
  }
};

const MeMenu = forwardRef(({ user, menuState, updateMenuState, updateMeMenuDetails }, ref) => {
  const queryClient = useQueryClient();
  const dirtyFields = useRef({});
  const [currentTab, setCurrentTab] = useState(0);
  const direction = useSelector(selectDirection);
  const dispatch = useDispatch();
  const [settings, updateSettings] = useReducer(settingsReducer, {
    currentPassword: '',
    password: '',
    confirmPassword: '',
    isCurrentPasswordValid: true,
    isPasswordValid: true,
    isPasswordMismatch: false,
    updatedSettings: { ...user },
  });
  
  const directionChanged = useMemo(()=>{
    const newDirection = i18n.dir(settings?.updatedSettings?.language);
    return newDirection !== direction;
  },[direction, settings]);

  const { isLoading, mutate } = useSaveSettings(settings);

  const isDirty = (cleanValue, currentValue) => cleanValue !== currentValue;

  const handleChange = useCallback(
    (currentValue, fieldName) => {
      if (['currentPassword', 'password', 'confirmPassword'].includes(fieldName)) {
        updateSettings({ type: fieldName, payload: currentValue });
        dirtyFields.current = { ...dirtyFields.current, [fieldName]: isDirty('', currentValue) };
      } else {
        updateSettings({ type: MY_SETTINGS_ACTIONS.UPDATE_SETTINGS, payload: { currentValue, fieldName } });
        dirtyFields.current = { ...dirtyFields.current, [fieldName]: isDirty(user[fieldName] ?? '', currentValue) };
      }

      updateMenuState({
        type: ME_MENU_ACTIONS.MENU_DIRTY,
        payload: Object.values(dirtyFields.current).some((dirtyField) => dirtyField),
      });
    },
    [updateMenuState, user],
  );

  const handleBlur = useCallback(
    async (currentValue, fieldName) => {
      if (fieldName === 'currentPassword') {
        if (!currentValue) {
          updateSettings({ type: MY_SETTINGS_ACTIONS.VALIDATE_CURRENT_PASSWORD, payload: true });
          return;
        }

        const isCurrentPasswordValid = await validatePassword(user.userName, currentValue);

        updateSettings({ type: MY_SETTINGS_ACTIONS.VALIDATE_CURRENT_PASSWORD, payload: isCurrentPasswordValid });
      }

      updateMenuState({
        type: ME_MENU_ACTIONS.MENU_DIRTY,
        payload: Object.values(dirtyFields.current).some((dirtyField) => dirtyField),
      });
    },
    [user.userName, updateMenuState],
  );

  const closeMenu = useCallback(() => {
    updateMenuState({ type: ME_MENU_ACTIONS.CLOSE_MENU });
  }, [updateMenuState]);

  const handleSave = useCallback(() => {
    const updatedSettings = {
      ...settings.updatedSettings,
      password: settings.password || null,
      emailNotifications: emailNotificationsFormat(settings?.updatedSettings?.emailNotifications),
    };
    const updateCurrentUser = (currentUser) => ({
      ...currentUser,
      sysaidUser: {
        ...settings.updatedSettings,
        password: settings.password || null,
      },
    });

    mutate(updatedSettings, {
      onSuccess: async () => {
        queryClient.setQueryData(['currentUser'], (currentUser) => updateCurrentUser(currentUser));
        updateMeMenuDetails(updatedSettings);
        updateSettings({ type: MY_SETTINGS_ACTIONS.UPDATE_CURRENT_PASSWORD, payload: '' });
        updateSettings({ type: MY_SETTINGS_ACTIONS.UPDATE_PASSWORD, payload: '' });
        updateSettings({ type: MY_SETTINGS_ACTIONS.UPDATE_CONFIRM_PASSWORD, payload: '' });
        updateMenuState({ type: ME_MENU_ACTIONS.SAVE });
      },
    });
    if (directionChanged) dispatch(setDirectionChanged(true));
  }, [mutate, dispatch, queryClient, settings.password, settings.updatedSettings, updateMeMenuDetails, updateMenuState, directionChanged]);

  const canSaveForm = useCallback(() => {
    if (currentTab === 1) {
      if (!menuState.isMenuDirty) {
        return true;
      }

      return (
        menuState.isMenuDirty &&
        settings.currentPassword &&
        settings.password &&
        settings.confirmPassword &&
        settings.isCurrentPasswordValid &&
        settings.isPasswordValid &&
        !settings.isPasswordMismatch
      );
    }

    return menuState.isMenuDirty;
  }, [menuState.isMenuDirty, settings, currentTab]);

  return (
    <StyledMeMenu ref={ref} data-testid="me-menu">
      {isLoading && (
        <ProgressWrapper sx={{ position: 'fixed', width: '100%', zIndex: 1 }}>
          <CircularProgress />
        </ProgressWrapper>
      )}
      <MeMenuHeader
        data-testid="me-menu-header"
        userName={user?.calculatedUserName}
        settings={{ ...settings, profileImage: user?.profileImage }}
        handleChange={handleChange}
        closeMenu={closeMenu}
      />
      <Divider />
      <MeMenuBody
        data-testid="me-menu-body"
        settings={settings}
        handleChange={handleChange}
        handleBlur={handleBlur}
        handleTabChange={setCurrentTab}
      />
      <Divider />
      <MeMenuFooter
        data-testid="me-menu-footer"
        disabled={!canSaveForm()}
        handleSave={handleSave}
        handleClose={closeMenu}
      />
    </StyledMeMenu>
  );
});

export default MeMenu;
