import { Alert, AlertTitle } from '@mui/material';
import { StyleStatusMessage } from './style';

export default function StatusMessage(props) {
  const { message, AlertIcon } = props;
  return (
    <StyleStatusMessage>
      <Alert icon={<AlertIcon width="36px" height="36px" fontSize="inherit" />}>
        <AlertTitle>{message}</AlertTitle>
      </Alert>
    </StyleStatusMessage>
  );
}
