export const CONSTANTS = {
  MAX_FIELDS_PER_SECTION: 50,
  MAX_SECTIONS_PER_TEMPLATE: 20,
  SECTION_ID_PREFIX: 'section',
  FIELD_ID_PREFIX: 'field',
  PLACEHOLDER_TYPE_ID: 'placeholder',
  MAX_FIELDS_IN_FIELDS_LIST: 50,
  MAX_HINT_LENGTH: 250,
  EDIT: 'E',
  VIEW: 'V',
  SECTION_INSTRUCTIONS: 'sectionInstructions',
  HEADER: 1,
  SECTION: 2,
  FIELDS: 3,
  DEFAULT: 4,
  PROPERTY_TITLE: 'title',
  PROPERTY_NAME: 'name',
};

export const switchId = {
  REQUIRED_FIELD: 'REQUIRED_FIELD',
  HIDE_FIELD_CAPTION: 'HIDE_FIELD_CAPTION',
  HINT: 'HINT',
  LIMIT_EDIT: 'LIMIT_EDIT',
};

export const categoriesFieldName = {
  'category.firstLevelKey': true,
  'category.secondLevelKey': true,
  'category.thirdLevelKey': true,
};

export const categoriesFieldId = {
  59: true,
  60: true,
  61: true,
};

export const fieldProperties = {
  defaultValue: 'defaultValue',
  fieldId: 'fieldId',
  fieldName: 'fieldName',
  hideFieldCaption: 'hideFieldCaption',
  hint: 'hint',
  hintText: 'hintText',
  limitedPermissions: 'limitedPermissions',
  order: 'order',
  permissions: 'permissions',
  position: 'position',
  readOnly: 'readOnly',
  required: 'required',
  requiredStatuses: 'requiredStatuses',
  typeId: 'typeId',
};

export const sectionProperties = {
  fields: 'fields',
  id: 'id',
  instructions: 'instructions',
  limitedPermissions: 'limitedPermissions',
  name: 'name',
  nonSection: 'nonSection',
  sectionId: 'sectionId',
  sectionRows: 'sectionRows',
  ticketTemplateSectionPermissions: 'ticketTemplateSectionPermissions',
  viewOnly: 'viewOnly',
  viewOnlyGroups: 'viewOnlyGroups',
};

export const permissionType = {
  edit: 'E',
  view: 'V',
};

export const fieldNames = {
  requestUser: 'requestUser',
  userDepartment: 'requestUser.department',
  userManager: 'requestUser.userManagerName',
  userAgreement: 'requestUser.agreement',
};
