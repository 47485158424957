import useTexts from 'features/resolutionPanel/useTexts';

import { ReactComponent as ArrowDownIcon } from 'images/icons/arrowdown.svg';
import { StyledTruncatedTextButton } from './style';

const TruncatedTextButton = ({ onClick, isRichTextTruncated }) => {
  const { showLessText, showMoreText } = useTexts();
  return (
    <StyledTruncatedTextButton
      isRichTextTruncated={isRichTextTruncated}
      className="truncate-text-button"
      id="truncate-text-button"
      data-cy="truncate-text-button"
      onClick={onClick}
    >
      <div className="text">{isRichTextTruncated ? showMoreText : showLessText}</div>
      <ArrowDownIcon />
    </StyledTruncatedTextButton>
  );
};

export default TruncatedTextButton;
