import { useSelector } from 'react-redux';
import { SR_PANEL_CONSTANTS } from 'features/srPanel/consts';
import { selectSRStatus } from 'store/srSlice';
import { findLast } from 'lodash-es';
import { selectAuditLog } from '../../store/slice';
import { actionEnums, editorActionData } from '../../constants';
import { editorActionTypes } from '../../constants/editorActionTypes';
import { useAuditLog, useEditorActions } from '../../hooks';

export const useResolution = () => {
  const ticketAuditLogs = useSelector(selectAuditLog);
  const srStatus = useSelector(selectSRStatus);
  const { selectedFilterType } = useAuditLog();
  const { handleEditorChange } = useEditorActions();

  const resolutionAuditLog = findLast(
    ticketAuditLogs[selectedFilterType].list,
    (item) => item.logType === actionEnums.SOLUTION_RESOLUTION_CREATED,
  );
  const handleEditResolution = () => {
    if (srStatus.valueClass === SR_PANEL_CONSTANTS.CLOSED_CLASS) {
      const editorActionSettings = editorActionData[editorActionTypes.RESOLUTION];
      handleEditorChange(editorActionSettings.statusCaption, {
        [editorActionSettings.statusCaption]: srStatus.value,
      });
      handleEditorChange(editorActionSettings.status, { [editorActionSettings.status]: srStatus.valueKey });
    }
  };

  return {
    resolutionAuditLog,
    handleEditResolution,
  };
};
