import { Box, Menu } from '@mui/material';
import { styled } from '@mui/material/styles';

export const RichTextRenderer = styled(Box)(({ theme, isEmptyRequiredValue }) => ({
  width: '100%',
  '.MuiInput-input': {
    pointerEvents: 'none!important',
  },
  '&': !isEmptyRequiredValue && {
    '.inline-field .Mui-disabled': {
      cursor: 'pointer !important',
      borderRadius: theme.shape.border.borderRadius,
      '&:hover': {
        background: theme.palette.background.bg_hover_alt,
      },
    },
  },
}));

export const StyledRichtextWrapper = styled(Box)(({ theme }) => ({
  '.richtext-wrap': {
    backgroundColor: theme.palette.background.paper,
    padding: '4px 8px !important',
    width: '410px',
    maxWidth: '410px',
    minWidth: '410px',
    minHeight: '160px',
  },
  '.attachments-wrapper': {
    display: 'none !important',
  },
}));

export const StyledMenu = styled(Menu)(({ theme }) => ({
  '.isQueuePage': {
    borderRadius: '10px',
    boxShadow: 'unset!important',
    overflow: 'unset!important',
    '.editor-header': {
      display: 'none!important',
    },
    '.MuiGrid-root': {
      position: 'fixed',
      width: 'unset',
    },
    '.richtext-wrap': {
      border: `1px solid ${theme?.palette?.grey?.divider_grey_v3}`,
    },
    '.fr-popup': {
      zIndex: 999,
    },
    '.fr-counter-element': {
      fontSize: '12px',
    },
  },
  '.MuiPaper-root': {
    border: 'unset',
    borderRadius: '10px',
    minHeight: '0!important',
    minWidth: '0!important',
  },
  '.MuiList-root': {
    padding: '0',
  },
}));
