import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

// removes the arrows displayed for number input fields
const numberInputStyles = `
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  & input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const StyledNavSearch = styled('div')`
  margin: ${(props) => (props?.expanded ? '0 20px 26px' : '14px 14px 26px')};
  & .SearchField {
    box-sizing: border-box;
    margin: 0;
  }

  & .MuiButtonBase-root {
    padding: 0;
    margin: auto;
    height: 28px;
    &:hover {
      background: unset;
    }
    & span {
      height: 28px;
    }
  }
  ${numberInputStyles}
`;

export const StyledExpandedSearch = styled('div')`
height: 28px;

& .expanded-error {
  padding: 0px 12px 0px;
}
${numberInputStyles}
`;

export const StyledNoResultError = styled('div')`
  color: ${(props) => props?.theme.palette.labels.bt_red_reg};
  font-size: ${(props) => props?.theme.fontSize.small};
  display: flex;
  height: 20px;
  line-height: 20px


& .expanded-error {
  padding: 0px 12px 0px;
}

& .collapsed-error {
  padding: 0px 24px;
}

${numberInputStyles}
`;

export const StyledPaperSearch = styled(Paper)`
  background: ${(props) => props?.theme.palette.generic.primary};
  box-shadow: 0px 2px 8px ${(props) => props?.theme.palette.background.boxShadow};
  border-radius: 10px;
  height: 107px;

  & .title {
    font-family: Roboto-Regular;
    font-size: 14px;
    padding: 12px;
    color: ${(props) => props?.theme.palette.text.txt_default};
  }

  & .search {
    padding: 0 12px 0 12px;

    & .SearchField {
      box-sizing: border-box;
      margin: 0;
    }
  }

  ${numberInputStyles}
`;
