import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    createNewIssueText:
      texts?.['spaces.floatingTicketPanel.autoPopulate.createNewIssue'] || 'Create A New Service Record',
    autoPopulatePlaceholder:
      texts?.['spaces.floatingTicketPanel.autoPopulate.placeholder'] ||
      'Provide a short description of your issue and let our SysAid CoPilot save your time...',
    skipToManualText:
      texts?.['spaces.floatingTicketPanel.autoPopulate.buttons.skipToManual'] || 'I prefer manual labor',
    saveMeTimeText: texts?.['spaces.floatingTicketPanel.autoPopulate.buttons.saveMeTime'] || 'Auto fill for me',
    descriptionAsNoteTitle:
      texts?.['spaces.floatingTicketPanel.autoPopulate.descriptionAsNote.title'] ||
      'The below description was submitted to prompt Auto Population for this service record.',
    descriptionAsNotePromptTitle:
      texts?.['spaces.floatingTicketPanel.autoPopulate.descriptionAsNote.prompt.title'] ||
      `This template doesn't contain a description field. Your submitted description would be available in the Journey as a Note log!`,
  };

  return componentTexts;
}
