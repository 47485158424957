import { styled } from '@mui/material/styles';

import { Dialog, Paper, Button } from '@mui/material';

export const StyledUploadFromExistingAttachments = styled(Dialog)({
  '.MuiPaper-root': {
    padding: '3px',
    maxWidth: '100%',
    boxShadow: 'none',
    borderRadius: '10px',
  },
});

export const StyledUploadFromExistingAttachmentsWrapper = styled(Paper)(({ hasActiveFilters }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  width: '654px',
  height: hasActiveFilters ? '806' : '738px',
}));

export const StyledUploadFromExistingAttachmentsBodyWrapper = styled('div')(({ hasActiveFilters }) => ({
  display: 'grid',
  gridTemplateRows: hasActiveFilters ? '108px 540px' : '38px 540px',
}));

export const StyledThumbnailsFiltersHeaderWrapper = styled('div')({
  display: 'grid',
  gridTemplateRows: '38px 68px',
});
export const StyledThumbnailsFiltersHeader = styled('div')({});

export const StyledThumbnailsActiveFiltersHeader = styled('div')({});

export const StyledThumbnailsWrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: '166px',
  justifyContent: 'flex-start',
  gridTemplateColumns: 'repeat(3, 170px)',
  gap: '36px',

  height: '540px',
  overflowY: 'scroll',
  marginInline: '30px',
  paddingBlock: '12px',
  scrollbarColor: `${theme?.palette?.grey?.divider_grey_v1} transparent`,
  scrollbarWidth: 'thin',
  '::-webkit-scrollbar': {
    width: '4px',
  },

  '::-webkit-scrollbar-thumb': {
    borderRadius: '10px',
    background: theme?.palette?.grey?.divider_grey_v1,
  },

  '::-webkit-scrollbar-track': {
    marginTop: '2px',
    marginBottom: '2px',
  },
}));

export const StyledTitleWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  paddingTop: '12px',
  paddingInline: '34px',

  '.SearchField': {
    margin: 0,
    height: '32px',
    width: '220px',
  },

  '& p': {
    fontSize: '18px',
    color: theme.palette.text.title_txt,
    fontFamily: theme.fonts.robotoRegular,
  },
}));

export const StyledButtonsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '8px',

  paddingRight: '8px',
  backgorund: 'white',
  paddingBottom: '20px',
});

export const StyledCancelButton = styled(Button)(({ theme }) => ({
  height: '32px',
  boxShadow: 'none',
  borderRadius: '60px',
  color: theme.palette.generic.sysaid_default,
  backgroundColor: theme.palette.grey.bt_alt_grey_reg,

  '&:hover': {
    boxShadow: 'none',
    color: theme.palette.generic.sysaid_default,
    backgroundColor: theme.palette.grey.bt_alt_grey_reg,
  },
}));

export const StyledAddButton = styled(Button)(({ theme }) => ({
  height: '32px',
  boxShadow: 'none',
  borderRadius: '60px',
  color: theme.palette.grey.bt_alt_grey_reg,
  backgroundColor: theme.palette.generic.sysaid_default,

  '&:hover': {
    boxShadow: 'none',
    color: theme.palette.grey.bt_alt_grey_reg,
    backgroundColor: theme.palette.generic.sysaid_default,
  },

  '&.Mui-disabled': {
    color: theme.palette.grey.bt_alt_grey_reg,
    backgroundColor: theme.palette.generic.sysaid_mid,
  },
}));
