import { useRbTexts } from 'remote-state/applicationHooks';

export const useTexts = () => {
  const texts = useRbTexts();
  const componentTexts = {
    clear: texts?.['spaces.select.clear'],
    filterBy: texts?.['spaces.select.filterBy'],
    clearAll: texts?.['spaces.select.clearAll'],
    selectAll: texts?.['spaces.searchableSetSelect.selectAll'] || 'Select All',
    quickFilter: texts?.['spaces.select.quickFilter'],
    infoNoResults: texts?.['spaces.select.info.noResults'],
    infoNoValues: texts?.['spaces.select.info.noValues'],
    infoSearchPlaceholder: texts?.['spaces.select.info.searchPlaceholder'],
    categoryPriority: texts?.['spaces.select.priority'],
    categoryStatus: texts?.['spaces.select.status'],
    categoryUrgency: texts?.['spaces.select.urgency'],
    categoryMain: texts?.['spaces.select.main'],
    categoryImpact: texts?.['spaces.select.impact'],
    customDate: texts?.['spaces.select.customDate'],
    custom: texts?.['spaces.select.custom'],
    filterByDate: texts?.['spaces.select.filterByDate'],
    inRange: texts?.['spaces.select.inRange'],
    equalsTo: texts?.['spaces.select.equalsTo'],
    notEquals: texts?.['spaces.select.notEquals'],
    lessThan: texts?.['spaces.select.range.lessThan'],
    greaterThan: texts?.['spaces.select.range.greaterThan'],
    thisYear: texts?.['spaces.select.range.thisYear'],
    lastYear: texts?.['spaces.select.range.lastYear'],
    thisQuarter: texts?.['spaces.select.range.thisQuarter'],
    lastMonth: texts?.['spaces.select.range.lastMonth'],
    today: texts?.['spaces.header.quickFilter.displayKey.today'],
    yesterday: texts?.['spaces.header.quickFilter.displayKey.yesterday'],
    thisWeek: texts?.['spaces.header.quickFilter.displayKey.thisWeek'],
    thisMonth: texts?.['spaces.select.range.thisMonth'],
    last30Days: texts?.['spaces.select.range.last30Days'],
    categoryNoValue: texts?.['spaces.categories.noValue'],
    chooseCategory: texts?.['spaces.categories.chooseCategory'],
    mandatoryFieldError: texts?.['spaces.field.tooltip.error.mandatoryFieldError'] || 'Missing required field',
    expandSwitch:
      texts?.['spaces.resolutionPanel.journey.dataFilters.select.expandSwitch'] ||
      'Expand all messages & notes by default',
    defaultOption: texts?.['spaces.resolutionPanel.journey.dataFilters.select.default'] || 'Default',
    setAsDefault: texts?.['spaces.resolutionPanel.journey.dataFilters.select.setAsDefault'] || 'Set as Default',
  };

  return componentTexts;
};
