import { MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDeleteTicket = styled(MenuItem)(() => ({
  '& .delete-ticket': {
    border: 'none',
    backgroundColor: 'transparent',
    padding: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));
