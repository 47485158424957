import { styled, createTheme } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

export const theme = createTheme({
  palette: {
    grey: '#bec4ce',
    greyOnHover: '#abaeb2',
    low: '#83b0f4',
    lowOnHover: '#6092db',
    medium: '#f4c449',
    mediumOnHover: '#dbaa2c',
    critical: '#f76969',
    criticalOnHover: '#de4949',
    ended: '#4F4F4F',
  },
});

export const BorderLinearProgress = styled(LinearProgress)(({ theme, bgcolorindicator }) => ({
  height: 24,
  borderRadius: 12,
  ':hover': {
    backgroundColor: theme.palette[`${bgcolorindicator}OnHover`],
  },
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey,
    '&:hover': {
      backgroundColor: theme.palette.greyOnHover,
    },
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: theme.palette[bgcolorindicator],
    '&:hover': {
      backgroundColor: theme.palette[`${bgcolorindicator}OnHover`],
    },
  },
}));
