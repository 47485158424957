import useTexts from 'features/resolutionPanel/useTexts';
import { ReactComponent as SentToChildSrsIcon } from 'images/icons/sentToChildSrsIcon.svg';
import { StyledHeader, StyledWrapper, StyledTitle, StyledContent, StyledSentToChildSrs } from './style';
import { InlineArray } from './inlineArray';

export const MessageHeader = ({ logInformation, isRichTextTruncated }) => {
  const { sender, recipients, subject, cc, bcc, isSentToChildSrs } = logInformation; //TODO - when massage object saved completely, check naming of "isSentToChildSrs"
  const {
    sender: senderText,
    recipient: recipientText,
    subject: subjectText,
    cc: ccText,
    bcc: bccText,
    sentToChildSrs: sentToChildSrsText,
  } = useTexts();

  return (
    <StyledHeader isRichTextTruncated={isRichTextTruncated} data-cy="journey-message-header">
      <StyledWrapper data-cy="journey-message-sender">
        <StyledTitle>{senderText}:</StyledTitle>
        <StyledContent>{sender?.displayName || sender?.emailAddress}</StyledContent>
      </StyledWrapper>
      <StyledWrapper className="recipients-container" data-cy="journey-message-recipient">
        <StyledTitle className="recipients-title">{recipientText}:</StyledTitle>
        <StyledContent>
          <InlineArray values={recipients} shouldShowAll={!isRichTextTruncated} />
        </StyledContent>
      </StyledWrapper>
      {!isRichTextTruncated && (
        <>
          {!!cc?.length && (
            <StyledWrapper className="cc-container">
              <StyledTitle className="cc-title">{ccText}:</StyledTitle>
              <StyledContent>
                <InlineArray values={cc} shouldShowAll={!isRichTextTruncated} />
              </StyledContent>
            </StyledWrapper>
          )}
          {!!bcc?.length && (
            <StyledWrapper className="bcc-container">
              <StyledTitle className="bcc-title">{bccText}:</StyledTitle>
              <StyledContent>
                <InlineArray values={bcc} shouldShowAll={!isRichTextTruncated} />
              </StyledContent>
            </StyledWrapper>
          )}
        </>
      )}
      <StyledWrapper data-cy="journey-message-subject">
        <StyledTitle>{subjectText}:</StyledTitle>
        <StyledContent>{subject}</StyledContent>
      </StyledWrapper>
      {!isRichTextTruncated && isSentToChildSrs && (
        <StyledSentToChildSrs>
          <SentToChildSrsIcon className="icon" />
          <span>{sentToChildSrsText}</span>
        </StyledSentToChildSrs>
      )}
    </StyledHeader>
  );
};
