import { useEffect, useCallback } from 'react';
import FieldName from '../fieldName';
import { InlineFieldContainer } from '../../../../common/components/controls/InlineField/InlineFieldContainer';
import { CUSTOM_FIELD_PREFIX, CUSTOM_TEXT_FIELD_LIMIT, SR_PANEL_CONSTANTS } from '../../consts';

export default function TextField(props) {
  const {
    displayName,
    field,
    source,
    fullwidth,
    fieldValue,
    hasMoreDetails,
    url,
    urlParams,
    isTemplatePage,
    disabled,
    required,
    handleDirty,
    onFocus,
    handleSaveValue,
    updateFieldValidation,
    isNewField = false,
  } = props;

  const fieldName = field.fieldName;
  const fieldTypeId = field.templateFieldAttributes.ticketTemplateFieldType.id;

  const checkAndUpdateValidationStatus = useCallback(
    (value) => {
      const isValid = !(!value && required);

      if (updateFieldValidation) {
        updateFieldValidation(fieldName, required, isValid);
      }
      return isValid;
    },
    [required, fieldName, updateFieldValidation],
  );

  useEffect(() => {
    checkAndUpdateValidationStatus(fieldValue);
  }, [fieldValue, checkAndUpdateValidationStatus]);

  return (
    <>
      <FieldName
        url={url}
        name={displayName}
        required={required}
        urlParams={urlParams}
        fieldId={field?.fieldId}
        hasMoreDetails={hasMoreDetails}
        isTemplatePage={isTemplatePage}
        hintText={field?.hint && field?.hintText}
      />
      <div style={{ transform: 'translateX(-8px)' }}>
        <InlineFieldContainer
          source={source}
          onFocus={onFocus}
          text={fieldValue}
          disabled={disabled}
          limit={fieldTypeId === SR_PANEL_CONSTANTS.TEXT && fieldName.includes(CUSTOM_FIELD_PREFIX) ? CUSTOM_TEXT_FIELD_LIMIT : 0}
          required={required}
          isNewField={isNewField}
          fieldName={fieldName}
          fullwidth={fullwidth}
          handleDirty={handleDirty}
          isTemplatePage={isTemplatePage}
          handleSaveValue={handleSaveValue}
          multiline={fieldTypeId === SR_PANEL_CONSTANTS.LONG_TEXT}
          fieldTypeId={fieldTypeId}
        />
      </div>
    </>
  );
}
