import moment from 'moment';

import { actionEnums } from '../../constants';

export function convertToApiObj({ srId, srType, user, time, description }) {
  const fromTime = moment(time.startTime).toISOString();
  const toTime = moment(time.endTime).toISOString();
  const totalTime = moment
    .duration({ hours: time?.total?.hours, minutes: time?.total?.minutes })
    .asMilliseconds();

  return {
    srId,
    srType,
    isCoreUpdate: false,
    userName: user?.userName,
    fromTime,
    toTime,
    totalTime,
    description,
  };
}

export function isActivityValid({ user, time }) {
  return !!user?.userName && !!time?.startTime && (!!time?.total?.hours || !!time?.total?.minutes);
}

export function convertMillisecondsToHoursAndMinutes(milliseconds) {
  const minutes = moment.duration(milliseconds, 'milliseconds').asMinutes();
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = Math.ceil(minutes % 60);

  return { hours, minutes: remainingMinutes };
}

export function calculateTotalTime(startTime, endTime) {
  if (!(startTime && endTime)) {
    return { hours: 0, minutes: 0 };
  }

  const durationInMilliseconds = moment.duration(moment(endTime).diff(startTime)).asMilliseconds();
  const { hours, minutes } = convertMillisecondsToHoursAndMinutes(durationInMilliseconds);

  return { hours, minutes };
}

export function calculateEndTime(startTime, total) {
  if (!(startTime && total)) {
    return null;
  }

  return moment(startTime)
    .add(total.hours, 'hours')
    .add(total.minutes, 'minutes')
    .valueOf();
}

export function calculateAggregatedTotalTime(activities) {
  const totalTimeInMilliseconds = (activities?.list || []).reduce((acc, curr) => {
    if (curr.logType !== actionEnums.ACTIVITY_CREATED) {
      return acc;
    }

    return acc + Number(curr.logInformation?.totalTime || 0);
  }, 0);

  return convertMillisecondsToHoursAndMinutes(totalTimeInMilliseconds);
}

export function addLeadingZero(number) {
  return number < 10 ? `0${number}` : number;
}
