import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    title: texts?.['spaces.header.actions.openInNewTab'] || 'Open in new tab',
  };

  return componentTexts;
}
