import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FadeInBox = styled(Box)(() => ({
    animation: 'fadeIn 0s 2s',
  
    '@keyframes fadeIn': {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 0.6,
      },
    },
  }));