import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => !['btnTheme', 'hideText'].includes(prop),
})(({ theme, btnTheme, hideText = false }) => {
  const borderRadius = '60px';
  const padding = theme.spacing(0.5, 2);
  const minWidth = 'auto';
  const marginRight = '8px';
  const display = 'inline';

  return {
    borderRadius,
    fontSize: theme?.fontSize?.main,
    lineHeight: 1.7143,
    padding,
    textTransform: 'unset',
    fontFamily: theme.fonts.robotoRegular,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette[btnTheme].btn?.backgroundColor?.main,
    color: theme.palette[btnTheme].btn?.color?.main,

    '&:hover': {
      backgroundColor: theme.palette[btnTheme].btn?.backgroundColor?.hover,
      color: theme.palette[btnTheme].btn?.color?.hover,
      '&.add-workflow-btn': {
        backgroundColor: theme.palette.generic.sysaid_very_light,
      },
    },

    '&.Mui-disabled': {
      backgroundColor: theme.palette[btnTheme].btn?.backgroundColor?.disabled,
      color: theme.palette[btnTheme].btn?.color?.disabled,
      '&.add-workflow-btn': {
        pointerEvents: 'auto',
        cursor: 'pointer',
        backgroundColor: theme.palette[btnTheme].btn?.backgroundColor?.disabled,
        svg: {
          filter:
            'brightness(0) saturate(100%) invert(82%) sepia(12%) saturate(360%) hue-rotate(110deg) brightness(100%) contrast(85%)',
        },
      },
    },

    '& .MuiButton-startIcon': {
      marginLeft: 0,
      marginRight: hideText ? '4px' : marginRight,
    },

    '@media only screen and (max-width: 1440px)': {
      borderRadius: hideText ? '50%' : borderRadius,
      minWidth: hideText ? 0 : minWidth,
      padding: hideText ? '8px' : padding,
      '& .MuiButton-startIcon': {
        marginRight: hideText ? '0px' : marginRight,
      },
      '& .btn-text': {
        display: hideText ? 'none' : display,
      },
    },
  };
});
