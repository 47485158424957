import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const defaultNavItemTexts = {
    'spaces.menu.help.documentation': 'Documentation',
    'spaces.menu.help.resourceCenter': 'Resource Center',
    'spaces.menu.help.community': 'Ask the Community',
    'spaces.menu.help.cloudStatus': 'Cloud Status',
    'spaces.menu.help.submitSupportTicket': 'Support Portal',
    'spaces.menu.help.accountInformation': 'Account Info',
  };

  const texts = useRbTexts();
  const componentTexts = {
    newTicketText: texts?.['spaces.navigation-bar.new-ticket'],
    toasterMessageNewTicketStart: texts?.['spaces.new-ticket.toaster-message.start'],
    toasterMessageNewTicketEnd: texts?.['spaces.new-ticket.toaster-message.end'],
    searchPlaceholder: texts?.['spaces.navigation-bar.search.placeholder'],
    searchText: texts?.['spaces.search'],
    noResultMessage: texts?.['spaces.navigation-bar.search.noResultsFound'],
    maxFloatingPanelReached: texts?.['spaces.floatingTicketPanel.messages.maximumReached'],
    maxFloatingPanelReachedButton: texts?.['spaces.floatingTicketPanel.messages.maximumReached.buttonText'],
    aiChatbotButtonText: texts?.['spaces.navigation-bar.aiChatbot'],
    getNavItemTitle: (key) => texts?.[key] || defaultNavItemTexts?.[key],
    migrationFailedMessage:
      texts?.['spaces.migration.error'] ||
      'This ticket failed to be optimized for Spaces and is displayed in classic view.',
  };
  return componentTexts;
}
