import { useLogo } from 'remote-state/accountServiceHooks';
import { StyledCustomerLogo, StyledCustomerLogoWrapper } from './style';

const NavHeader = ({ navBarCollapsed }) => {
  const { data: customerLogoSrc } = useLogo();

  return (
    <StyledCustomerLogoWrapper data-testid="nav-header" hidden={navBarCollapsed}>
      <StyledCustomerLogo
        alt="customer-logo"
        src={customerLogoSrc}
        className="customer-logo"
        hidden={navBarCollapsed}
      />
    </StyledCustomerLogoWrapper>
  );
};

export default NavHeader;
