import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MORE_ATTACHMENTS_BTN_WIDTH = 56;
export const SMALL_ATTACHMENT_WIDTH = 102;
export const REGULAR_ATTACHMENT_WIDTH = 170;

export const StyledAttachments = styled(Box)(({ theme, className }) => ({
  className,
  ...theme.mixins.scrollbar,
  display: 'flex',
  flexFlow: 'wrap',
  columnGap: '12px',
  rowGap: '12px',
  overflow: 'visible',
  '.attachment-chip': {
    coursor: 'pointer',
    width: '190px',
  },
  '.more-attachments-btn': {
    background: theme.palette.grey.divider_grey_v2,
    borderRadius: theme.shape.border.borderRadius,
    minWidth: 'auto',
    width: `${MORE_ATTACHMENTS_BTN_WIDTH}px`,
    padding: '6px 2px',
    color: theme.palette.text.secondary_txt,
    fontSize: theme.fontSize.small,
    fontFamily: theme.fonts.robotoBold,
    '&:hover': {
      background: theme.palette.background.selected_and_hover,
    },
  },
  '&:hover': {
    background: 'inherit',
  },
}));

export const StyledEditorAttachments = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMinimizedSize',
})(({ isMinimizedSize }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '12px',
  maxWidth: `calc(${
    isMinimizedSize ? SMALL_ATTACHMENT_WIDTH : REGULAR_ATTACHMENT_WIDTH
  }px * 5 + 12px * 6 + ${MORE_ATTACHMENTS_BTN_WIDTH}px)`,
  '.attachment-chip': {
    width: '190px',
  },
  '.more-attachments-btn': {
    minWidth: `${MORE_ATTACHMENTS_BTN_WIDTH}px`,
  }
}));
