import { styled } from '@mui/styles';
import { Typography } from '@mui/material';

export const StyledLabelWithEllipsis = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isIcon',
})(({ isIcon }) => {
  if (isIcon) return;
  return {
    width: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  };
});
