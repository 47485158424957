import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isNavBarCollapsed: false,
  isNavBarHidden: false,
  isChatbotOpen: false,
};

export const mainPanelSlice = createSlice({
  name: 'mainPanel',
  initialState,
  reducers: {
    collapse: (state) => {
      state.isNavBarCollapsed = true;
    },
    expand: (state) => {
      state.isNavBarCollapsed = false;
    },
    toggle: (state) => {
      state.isNavBarCollapsed = !state.isNavBarCollapsed;
    },
    hideNavBar: (state, action) => {
      state.isNavBarHidden = action.payload;
    },
    openChatbot: (state, action) => {
      state.isChatbotOpen = action.payload;
    },
  },
});

export const { collapse, expand, toggle, hideNavBar, openChatbot } = mainPanelSlice.actions;

export const selectIsNavBarCollapsed = (state) => state.mainPanel.isNavBarCollapsed;
export const selectIsNavBarHidden = (state) => state.mainPanel.isNavBarHidden;
export const selectIsChatbotOpen = (state) => state.mainPanel.isChatbotOpen;

export default mainPanelSlice.reducer;
export const mainPanelSliceName = mainPanelSlice.name;
