import { styled } from '@mui/material/styles';

export const StyledTemplatePropertiesPanel = styled('div')(({ isDefaultPanel }) => {
  const padding = '24px';
  const height = '100%';
  const defaultPanelStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignIems: 'center',
    padding,
    height,
  };

  if (isDefaultPanel) {
    return defaultPanelStyle;
  }
  return {
    padding,
    height,
  };
});

export const StyledPropertyHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignIems: 'flex-start',
  marginBottom: '26px',
  fontSize: theme.fontSize?.medium,
  fontWeight: 400,
}));

export const StyledPropertyTitle = styled('span')(({ theme }) => ({
  lineHeight: '24px',
  color: theme.palette?.text.txt_default,
  fontSize: theme.fontSize?.medium,
  fontWeight: 400,
  marginBottom: '4px',
}));

export const StyledPropertyName = styled('span')(({ theme }) => ({
  fontSize: theme.fontSize?.main,
  lineHeight: '18px',
  color: theme.palette?.text.secondary_txt,
}));

export const StyledPropertyWrapper = styled('div')(({ theme }) => ({
  ' .template-switch-toggle-label': {
    color: theme.palette?.text?.txt_default,
  },
  marginBottom: '4px',
}));

export const StyledDefaultText = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  textAlign: 'center',
  fontSize: theme.fontSize?.main,
  color: theme.palette?.text?.disable_txt,
  lineHeight: '18px',
}));
