import { styled } from '@mui/material/styles';
import ListItem from '@mui/material/ListItem';

export const StyledTemplateFieldItem = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  '.field-item-icon': {
    flexGrow: 0,
    minWidth: 'auto',
    marginRight: '6px',
    svg: {
      width: '20px',
      height: '20px',
      '*': {
        stroke: theme.palette.icons.default,
        '&[fill]': {
          fill: theme.palette.icons.default,
        },
      },
    },
  },
  '.field-item-content': {
    color: theme?.palette?.text?.title_txt,
    background: theme.palette.generic.primary,
    border: theme.shape.border.border,
    borderRadius: theme.shape.border.borderRadius,
    alignItems: 'center',
    padding: '6px 10px',
    cursor: 'grab',
    '.drag-icon': {
      width: '16px',
      height: '16px',
      '&.inUse': {
        opacity: 0.3,
      },
    },
    '.MuiListItemText-root': {
      marginLeft: '12px',
      marginTop: '5px',
      marginBottom: '3px',
    },
    '.MuiListItemText-primary': {
      fontSize: theme.fontSize.main,
      lineHeight: 1,
    },
    '.check-icon': {
      marginLeft: 'auto',
      width: '10px',
      height: '10px',
      '*': {
        stroke: theme.palette.icons.default,
        '&[fill]': {
          fill: theme.palette.icons.default,
        },
      },
    },
    '&:hover': {
      background: 'none',
    },
    '&.Mui-disabled': {
      opacity: 1,
      color: theme.palette.generic.sysaid_light,
      borderColor: theme.palette.generic.sysaid_light,
    },
  },
  '&:hover': {
    '.field-item-content': {
      background: theme.palette.background.bg_hover_main,
      boxShadow: `inset 4px 0px 0${theme.palette.grey.divider_grey_v1}`,
    },
    '.field-item-icon svg *': {
      stroke: theme.palette.icons.default_hover,
      '&[fill]': {
        fill: theme.palette.icons.default_hover,
      },
    },
  },
  '.field-placeholder': {
    background: theme.palette.grey.bt_alt_grey_reg,
    border: `1px solid ${theme?.palette?.grey?.divider_grey_v1}`,
    boxShadow: 'inset 4px 8px 8px rgba(167, 176, 191, 0.15)',
    borderRadius: theme.shape.border.borderRadius,
    height: '36px',
    width: '100%',
  },
  '&.inUse': {
    '.field-item-icon svg *': {
      stroke: theme.palette.icons.field_used,
      '&[fill]': {
        fill: theme.palette.icons.field_used,
      },
    },
  },
}));
