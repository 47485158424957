import { ReactComponent as ServiceIcon } from 'images/icons/service.svg';
import { ReactComponent as BarcodeIcon } from 'images/icons/barcode.svg';
import { ReactComponent as ChartBarIcon } from 'images/icons/chart_bar.svg';
import { ReactComponent as WrenchIcon } from 'images/icons/tools_wrench.svg';
import { ReactComponent as LightBulbOnIcon } from 'images/icons/lightbulb_on.svg';
import { ReactComponent as GearIcon } from 'images/icons/administration.svg';
import { ReactComponent as BirdIcon } from 'images/icons/automations_center.svg';
import { ReactComponent as HelpIcon } from 'images/icons/help.svg';
import { ReactComponent as RoutingIcon } from 'images/icons/route.svg';
import { ReactComponent as EscalationIcon } from 'images/icons/moving.svg';
import { ReactComponent as EmailIcon } from 'images/icons/email.svg';
import { ReactComponent as ReminderIcon } from 'images/icons/reminders.svg';
import { ReactComponent as HelpGuidesIcon } from 'images/icons/help_guides.svg';
import { ReactComponent as SysaidCommunityIcon } from 'images/icons/sysaid_community.svg';
import { ReactComponent as CustomerSuccessIcon } from 'images/icons/customer_success.svg';
import { ReactComponent as HeadphonesIcon } from 'images/icons/headphones.svg';
import { ReactComponent as ContactSysaidIcon } from 'images/icons/contact_sysaid.svg';
import { ReactComponent as CloudIcon } from 'images/icons/cloud.svg';
import { ReactComponent as AccountInfoIcon } from 'images/icons/account_information.svg';
import { ReactComponent as Orchestration } from 'images/icons/orchestration.svg';
import { ReactComponent as ResourceCenterIcon } from 'images/icons/resource-center-icon.svg';

const navIcons = {
  155: <ServiceIcon />,
  1: <BarcodeIcon />,
  27: <ChartBarIcon />,
  39: <WrenchIcon />,
  50: <LightBulbOnIcon />,
  51: <GearIcon />,
  158: <BirdIcon />,
  159: <RoutingIcon />,
  160: <EmailIcon />,
  161: <EscalationIcon />,
  162: <ReminderIcon />,
  163: <Orchestration />,
  164: <HelpIcon />,
  165: <HelpGuidesIcon />,
  166: <SysaidCommunityIcon />,
  167: <CloudIcon />,
  168: <ContactSysaidIcon />,
  169: <AccountInfoIcon />,
  189: <ResourceCenterIcon/>,
  customerSuccessHub: <CustomerSuccessIcon />,
  support: <HeadphonesIcon />,
};

export default navIcons;
