import PropTypes from 'prop-types';
import NavToggle from '../../../../navToggle';
import NavSubListItem from '../navSubListItem';
import Tooltip from '../../../../../../common/components/tooltip';
import { useFeatureFlagQuery } from '../../../../../../remote-state/featureFlagsHooks';
import { FEATURE_FLAGS_KEYS } from '../../../../../../constants/featureFlags';

export default function NavSubListToggle({ icon, title, itemId, onClick, closeMenu }) {

  const { data: isMenuItemToggleEnabled } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.MENU_ITEM_TOGGLE_ENABLED,
    defaultValue: false,
  });

  return (
    <div>
      {isMenuItemToggleEnabled && <NavToggle className="toggle" onClick={() => onClick(itemId)} data-cy={`nav-toggle-item-${itemId}`}>
        {/*Do not update className as it used in NavItemMenu component!*/}
        <NavSubListItem className={`nav-toggle-item-${itemId}`} handleClose={closeMenu}>
          {icon && <span className="icon">{icon}</span>}
          <Tooltip isTruncatedText text={title} placement="top" className="menu-item" />
        </NavSubListItem>
      </NavToggle>}
    </div>
  );
}

NavSubListToggle.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  itemId: PropTypes.number,
  onClick: PropTypes.func,
  closeMenu: PropTypes.func,
};