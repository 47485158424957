import { useEffect, useCallback, useMemo, memo } from 'react';
import { debounce } from 'lodash-es';
import { useApplicationData } from 'remote-state/applicationHooks';
import SearchField from 'common/components/searchField';
import { searchByTitle } from 'common/utils/navBarUtils';
import { QUERIES_KEYS } from 'constant';
import { useSelector, useDispatch } from 'react-redux';
import { SETTINGS_MENU_COLORS, SETTINGS_MENU_ITEM_ID } from './constants';
import MenuItem from './menuItem';
import { selectSearchText, setSearchText, resetSearchText, selectMenuItems, setMenuItmes } from './slice';

import Title from './menuTitle';
import useTexts from './useTexts';
import {
  StyledSettingsWrapper,
  StyledMenuContainer,
  StyledHeader,
  StyledSearchWrapper,
  StyledSearchField,
  StyledMenuItemsContainer,
  HeaderTitle,
} from './styles';

const SettingsLobby = () => {
  const dispatch = useDispatch();
  const texts = useTexts();
  const { data: navElements } = useApplicationData(QUERIES_KEYS.MENU_ITEMS);
  const settingElements = useMemo(
    () => Object.values(navElements).flatMap((menu) => menu.filter((item) => item.id === SETTINGS_MENU_ITEM_ID))?.[0]?.subMenuList,
    [navElements],
  );
  const searchText = useSelector(selectSearchText);
  const menuList = useSelector(selectMenuItems);
  const { headerTitle, searchTitle } = texts;

  const handleSearch = useMemo(
    () =>
      debounce(({ searchText, settingElements }) => {
        const newTree = searchByTitle(settingElements, searchText, texts);
        dispatch(setMenuItmes(newTree));
      }, 1000),
    [dispatch, texts],
  );

  const onSearchTextChange = useCallback(
    (event) => {
      dispatch(setSearchText(event.target.value));
    },
    [dispatch],
  );

  const onSearchClear = useCallback(() => {
    dispatch(resetSearchText());
  }, [dispatch]);

  useEffect(() => {
    handleSearch({ searchText, settingElements });
  }, [searchText, handleSearch, settingElements]);

  return (
    <StyledSettingsWrapper>
      <StyledHeader>
        <HeaderTitle>{headerTitle}</HeaderTitle>
      </StyledHeader>

      <StyledMenuContainer>
        <StyledSearchWrapper>
          <StyledSearchField>
            <SearchField
              placeholderText={searchTitle}
              value={searchText}
              onChange={onSearchTextChange}
              onClear={onSearchClear}
              name="settings-search"
              autoFocus
            />
          </StyledSearchField>
        </StyledSearchWrapper>

        <StyledMenuItemsContainer>
          {menuList?.map((menuItem) => {
            const { id, subMenuList, rbToken } = menuItem;
            const highlightColor = SETTINGS_MENU_COLORS[id];

            return (
              <div key={`${id}-${rbToken}`} className="menu_items_wrapper">
                <Title
                  title={texts[rbToken] || ''}
                  highlightText={searchText}
                  highlightColor={highlightColor}
                  className="title-link-group"
                />
                <div className="menu-items">
                  {subMenuList.length > 0 &&
                    subMenuList.map((subMenuItem) => (
                      <MenuItem
                        key={`${subMenuItem.id}-menu-item`}
                        searchFilter={searchText}
                        menuItemId={id}
                        highlightColor={highlightColor}
                        {...subMenuItem}
                      />
                    ))}
                </div>
              </div>
            );
          })}
        </StyledMenuItemsContainer>
      </StyledMenuContainer>
    </StyledSettingsWrapper>
  );
};
export default memo(SettingsLobby);
