import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    descriptionPlaceholder: texts?.['spaces.template.fields.description.placeholder'],
  };

  return componentTexts;
}
