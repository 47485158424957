import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledActionBarWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
}));

export const ActionStyled = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0px 8px',
  gap: '8px',
}));

export const StyledActionBar = styled(ActionStyled)(({ theme }) => ({
  position: 'fixed',
  bottom: '50px',
  left: '50%',
  transform: 'translate(-50%)',
  fontFamily: theme.fonts.robotoRegular,
  minWidth: '307px',
  minHeight: '60px',
  background: theme.palette.grey.tooltip_bg,
  borderRadius: '4px',
  color: '#FFFFFF',
}));

export const StyledActions = styled(ActionStyled)(({ theme }) => ({
  alignSelf: 'stretch',
  border: `1px solid ${theme.palette.grey.divider_grey_v4}}`,
  borderTop: 'none',
}));

export const StyledAction = styled(Box)(({ theme, isCursorPointer }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '6px 8px',
  minWidth: '40px',
  minHeight: '52px',
  cursor: isCursorPointer && 'pointer',
  borderRadius: '4px',
  '&:hover': {
    background: isCursorPointer && theme.palette.grey.divider_grey_v4,
  },
  svg: {
    width: '24px',
  },
  '.assignee-btn-wrapper': {
    gap: 'unset',
  },
}));

export const StyledActionIcon = styled(Box)(({ theme }) => ({
  fontSize: theme.fontSize.big,
  height: '24px',
  textAlign: 'center',
}));

export const StyledActionLimit = styled(StyledActionIcon)(() => ({
  lineHeight: '24px',
}));

export const StyledActionTitle = styled(Box)(({ theme }) => ({
  fontSize: theme.fontSize.small,
  height: '16px',
  textAlign: 'center',
}));
