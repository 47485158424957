import MessagePrompt from 'common/components/messagePrompt';
import { ReactComponent as WarningIcon } from 'images/icons/WarningSign.svg';
import { ReactComponent as RefreshIcon } from 'images/icons/deploy-refresh-icon.svg'
import './ReloadPagePrompt.css';

import useTexts from './useTexts';

export default function ReloadPagePrompt({ open, onClick }) {
     const texts = useTexts();

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          onClick();
        }
      };

    const CustomRefreshOkButton = () => (
        <div
            onClick={onClick}
            className="custom-refresh-ok-button"
            onKeyDown={handleKeyDown}
            role="button"
            tabIndex={0}
        >
            <RefreshIcon className="icon" />
            {texts.modal.btnOkText}
        </div>
      );
    return (
      <MessagePrompt
        open={open}
        showCancelBtn={false}
        onClose={onClick}
        Icon={WarningIcon}
        title={texts.modal.title}
        onOkClick={onClick}
        btnOkText={<CustomRefreshOkButton />}
      >
      {texts.modal.description}
      </MessagePrompt>
    );
  }
  