const spacesToClassicRbMapping = {
  'spaces.template.fields.assetId': 'sr.computer.id',
  'spaces.template.fields.ciId': 'cmdb.main.ci',
  'spaces.template.fields.description': 'sr.description',
  'spaces.template.fields.workaround': 'sr.workaround',
  'spaces.template.fields.status': 'sr.status',
  'spaces.template.fields.urgency': 'sr.urgency',
  'spaces.template.fields.insertTime': 'sr.request.time',
  'spaces.template.fields.updateTime': 'sr.modify.time',
  'spaces.template.fields.closeTime': 'sr.close.time',
  'spaces.template.fields.updateUser': 'sr.modify.user',
  'spaces.template.fields.submitUser': 'sr.submit.user',
  'spaces.template.fields.requestUser': 'sr.request.user',
  'spaces.template.fields.requestUserManager': 'sr.requestUser.manager',
  'spaces.template.fields.emailAccount': 'sr.emailAccount',
  'spaces.template.fields.dueDate': 'sr.due.date',
  'spaces.template.fields.location': 'sr.location',
  'spaces.template.fields.escalation': 'sr.escalation',
  'spaces.template.fields.custList1': 'sr.cust_list1',
  'spaces.template.fields.custList2': 'sr.cust_list2',
  'spaces.template.fields.custText1': 'sr.cust_text1',
  'spaces.template.fields.custText2': 'sr.cust_text2',
  'spaces.template.fields.custNotes': 'sr.cust_notes',
  'spaces.template.fields.custInt1': 'sr.cust_int1',
  'spaces.template.fields.custInt2': 'sr.cust_int2',
  'spaces.template.fields.cc': 'sr.cc',
  'spaces.template.fields.projectId': 'SREdit.mainProject',
  'spaces.template.fields.taskId': 'SREdit.mainTask',
  'spaces.template.fields.srType': 'sr.type',
  'spaces.template.fields.custDate1': 'sr.cust_date1',
  'spaces.template.fields.custDate2': 'sr.cust_date2',
  'spaces.template.fields.source': 'sr.source',
  'spaces.template.fields.followupPlannedDate': 'sr.followup_planned_date',
  'spaces.template.fields.followupActualDate': 'sr.followup_actual_date',
  'spaces.template.fields.followupUser': 'sr.followup_user',
  'spaces.template.fields.followupText': 'sr.followup_text',
  'spaces.template.fields.successRating': 'sr.success_rating',
  'spaces.template.fields.reopenCounter': 'sr.reopen_counter',
  'spaces.template.fields.assignCounter': 'sr.assign_counter',
  'spaces.template.fields.maxSupportLevel': 'sr.max_support_level',
  'spaces.template.fields.currentSupportLevel': 'sr.current_support_level',
  'spaces.template.fields.agreement': 'sr.agreement',
  'spaces.template.fields.surveyStatus': 'sr.surveyStatus',
  'spaces.template.fields.impact': 'sr.impact',
  'spaces.template.fields.changeCategory': 'sr.change_category',
  'spaces.template.fields.company': 'user.company',
  'spaces.template.fields.requestUser.departmentCaption': 'user.department',
  'spaces.template.fields.isEscalated': 'sr.is_escalated',
  'spaces.template.fields.title': 'sr.title',
  'spaces.template.fields.priority': 'sr.priority',
  'spaces.template.fields.category.firstLevelKey': 'sr.category',
  'spaces.template.fields.category.secondLevelKey': 'sr.sub.category',
  'spaces.template.fields.category.thirdLevelKey': 'sr.third.level.category',
  'spaces.template.fields.assignee': 'sr.assigned.to',
  'spaces.template.fields.responsibleManager': 'sr.responsible.manager',
};

export default spacesToClassicRbMapping;
