import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledWrapper = styled(Box, {
  shouldForwardProp: (prop) => !['shouldShowAll'].includes(prop),
})(({ shouldShowAll }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '6px',
  overflow: shouldShowAll ? 'auto' : 'hidden',
  maxHeight: shouldShowAll ? '58px' : '20px',
  '& .remaining-values-wrapper': {
    alignItems: 'center',
  },
}));

export const StyledDisplayedValues = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  flexWrap: 'wrap',
  columnGap: '3px',
  position: 'relative',
}));

export const StyledCount = styled(Box)(({ theme }) => ({
  fontSize: '12px',
  lineHeight: '20px',
  color: theme.palette.text.title_txt,
}));
