import { styled } from '@mui/system';

export const IconStyle = styled('div')(({ theme }) => ({
  borderRadius: theme?.palette?.icons?.borderRadius,
  background: theme?.palette?.grey?.divider_grey_v1,
  display: 'flex',
  padding: '6px',
  marginRight: '8px',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  height: '28px',
  width: '28px',
}));
