import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    title: texts?.['spaces.generalError.prompt.title'],
    okBtnText: texts?.['spaces.generalError.prompt.okBtn'],
    cancelBtnText: texts?.['spaces.generalError.prompt.cancelBtn'],
    mainText: texts?.['spaces.generalError.prompt.details'],
  };

  return componentTexts;
}
