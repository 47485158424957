import { Box, AccordionActions } from '@mui/material';
import { styled } from '@mui/styles';

export const HeaderWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '24px',
});

export const HeaderDetails = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: 4,
  flex: '1 0 0',
});

export const HeaderTitle = styled('p')({
  display: 'flex',
  height: '20px',
  flexDirection: 'column',
  alignSelf: 'stretch',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: '.875rem',
  lineHeight: '1.25rem',
  margin: 0,
});
export const HeaderAssignee = styled('div')({
  display: 'flex',
  height: '22px',
  maxWidth: '300px',
  width: 'fit-content',
});

export const HeaderIndicators = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: 8,
});

export const StyledActionItemBody = styled('form')(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey.divider_grey_v1}`,
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  maxHeight: '250px',
}));

export const StyledAccordionActions = styled(AccordionActions)(({ theme }) => ({
  display: 'flex',
  borderTop: `1px solid ${theme.palette.grey.divider_grey_v1}`,
  padding: '16px 32px',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '10px',
}));

export const FieldContainer = styled(Box)({
  padding: '24px 30px 24px 60px',
});

export const FieldGrid = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '12px 90px',
  marginInlineEnd: '14px',
  '& .singleColumn': {
    flex: '1 0 calc(35% - 10px)',
    maxWidth: '260px',
  },
  '& .dualColumn': {
    flex: '0 0 100%',
  },
});
