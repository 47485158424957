import { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { QUERIES_KEYS } from 'constant';
import { useApplicationData } from 'remote-state/applicationHooks';
import FieldPropertiesPanel from './FieldPropertiesPanel';
import DefaultPropertiesPanel from './DefaultPropertiesPanel';
import SectionPropertiesPanel from './SectionPropertiesPanel';
import {
  StyledPropertyHeader,
  StyledPropertyName,
  StyledPropertyTitle,
  StyledTemplatePropertiesPanel,
} from './StyledTemplatePropertiesPanel';
import { selectActiveComponent } from '../activeComponentSlice';
import { CONSTANTS } from '../constants';
import { getHeaderFieldData, getSectionFieldData, getSectionData, getPropertyPanelName } from '../utils/utils';
import useComponentName from '../utils/hooks/useComponentName';
import useUpdateProperties from '../utils/hooks/useUpdateProperties';

function TemplatePropertiesPanel(props) {
  const { header, sections, updateProperties, srType } = props;
  const [allGroups, setAllGroups] = useState([]);
  const [componentData, setComponentData] = useState(null);
  const { getPropertyTitle, getPropertyName } = useComponentName(header, sections);
  const { data: groups, isFetched, isSuccess } = useApplicationData(QUERIES_KEYS.GROUPS);
  const { componentId, componentType } = useSelector(selectActiveComponent);
  const { handleUpdatePropertyPanel } = useUpdateProperties(updateProperties);
  const propertyPanelName = getPropertyPanelName(componentType, componentId);
  const isDefaultPanel = propertyPanelName === CONSTANTS.DEFAULT;

  useEffect(() => {
    if (isFetched && isSuccess) {
      setAllGroups(groups);
    }
  }, [isFetched, isSuccess, groups]);

  useEffect(() => {
    if (componentId && componentType) {
      if (componentType === CONSTANTS.FIELDS && sections?.length > 0) {
        setComponentData(getSectionFieldData(sections, componentId));
      } else if (componentType === CONSTANTS.SECTION && sections?.length > 0) {
        setComponentData(getSectionData(sections, componentId));
      } else if (componentType === CONSTANTS.HEADER && header?.length > 0) {
        setComponentData(getHeaderFieldData(header, componentId));
      }
    }
  }, [header, sections, componentId, componentType]);

  const handleUpdateProperties = useCallback(
    (updatedProperties) => {
      const dataOfComponentType = componentType === CONSTANTS.HEADER ? header : sections;
      handleUpdatePropertyPanel(componentId, componentType, updatedProperties, dataOfComponentType);
    },
    [componentId, componentType, header, sections, handleUpdatePropertyPanel],
  );

  const renderPropertyPanel = () => {
    if ((componentData?.fieldId || componentData?.sectionId || componentData?.id) !== componentId) {
      return <DefaultPropertiesPanel isDefaultText />;
    }
    const hasPropertyPanel = !componentData?.templateFieldAttributes?.alwaysReadOnly;
    if (propertyPanelName === CONSTANTS.FIELDS && hasPropertyPanel) {
      return (
        <FieldPropertiesPanel
          isHeaderField={componentType === CONSTANTS.HEADER}
          fieldData={componentData}
          allGroups={allGroups}
          handleUpdateProperties={handleUpdateProperties}
          componentId={componentId}
          srType={srType}
        />
      );
    }
    if (propertyPanelName === CONSTANTS.SECTION) {
      return (
        <SectionPropertiesPanel
          sectionData={componentData}
          allGroups={allGroups}
          handleUpdateProperties={handleUpdateProperties}
        />
      );
    }
    return <DefaultPropertiesPanel isDefaultText={false} />;
  };

  return (
    <StyledTemplatePropertiesPanel isDefaultPanel={isDefaultPanel}>
      {!isDefaultPanel && (
        <StyledPropertyHeader data-cy="field-properties-header">
          <StyledPropertyTitle>{getPropertyTitle()}</StyledPropertyTitle>
          <StyledPropertyName>{getPropertyName()}</StyledPropertyName>
        </StyledPropertyHeader>
      )}
      {renderPropertyPanel()}
    </StyledTemplatePropertiesPanel>
  );
}

export default memo(TemplatePropertiesPanel);
