import { useMutation } from '@tanstack/react-query';
import { QUERIES_KEYS } from 'constant';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApplicationData } from 'remote-state/applicationHooks';
import { markSuggestedCategoriesChosen } from 'services/sysaiService';
import {
  addPrimaryCategory,
  addSecondaryCategory,
  addThirdLevelCategory,
  selectSuggestedCategoryById,
  setIsOpened,
  setSuggestedCategorySelected,
} from 'store/categorySlice';

const getSelectedItemFromSuggestedCategory = ({ listKey, suggestedCategory }) => {
  let newSelectedItem;

  switch (listKey) {
    case 'primaryCategory': {
      const { firstLevelKey: id, primaryCategory: name } = suggestedCategory;
      newSelectedItem = { id, name };
      break;
    }
    case 'secondaryCategory': {
      const { secondLevelKey: id, secondaryCategory: name } = suggestedCategory;
      newSelectedItem = {
        id,
        name,
      };
      break;
    }
    case 'thirdLevelCategory': {
      const { thirdLevelKey: id, thirdLevelCategory: name } = suggestedCategory;
      newSelectedItem = {
        id,
        name,
      };
      break;
    }
    default:
    // Do nothing
  }

  return newSelectedItem;
};

const useHandleCategoryChangedToSuggestedInQueue = ({
  srId,
  setSelectedItem,
  listKey,
  handleUpdateSR,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const { data: isTripleCategory } = useApplicationData(QUERIES_KEYS.IS_EXTERNAL_MODE);
  const suggestedCategory = useSelector(selectSuggestedCategoryById(srId));

  const handleCategoryChangedToSuggestedInQueue = () => {
    const newSelectedItem = getSelectedItemFromSuggestedCategory({ listKey, suggestedCategory });
    const { firstLevelKey, secondLevelKey, thirdLevelKey } = suggestedCategory;

    if (newSelectedItem) {
      setSelectedItem(newSelectedItem);

      if (listKey === 'primaryCategory') {
        dispatch(addPrimaryCategory({ id: srId.toString(), value: firstLevelKey }));
        dispatch(addSecondaryCategory({ id: srId.toString(), value: secondLevelKey }));
        dispatch(setIsOpened(null));

        if (!isTripleCategory) {
          handleUpdateSR({
            primaryCategoryId: firstLevelKey,
            secondaryCategoryId: secondLevelKey,
          });
        } else {
          dispatch(addThirdLevelCategory({ id: srId.toString(), value: thirdLevelKey }));
          handleUpdateSR({
            primaryCategoryId: firstLevelKey,
            secondaryCategoryId: secondLevelKey,
            thirdLevelCategoryId: thirdLevelKey,
          });
        }
      }
      handleClose();
    }
  };

  return {
    handleCategoryChangedToSuggestedInQueue,
  };
};

export const useHandleSelectSuggestedCategories = ({ srId, queueHandlerOptions, panelHandlerOptions }) => {
  if (!(queueHandlerOptions || panelHandlerOptions)) {
    throw new Error(
      'useHandleSelectSuggestedCategories must receive either queueHandlerOptions or panelHandlerOptions',
    );
  }

  const dispatch = useDispatch();

  const suggestedCategory = useSelector(selectSuggestedCategoryById(srId));
  const { data: isTripleCategory } = useApplicationData(QUERIES_KEYS.IS_EXTERNAL_MODE);
  const { mutate: trackSuggestedCategoriesChosen } = useMutation(() => markSuggestedCategoriesChosen(srId));
  const { handleCategoryChangedToSuggestedInQueue } = useHandleCategoryChangedToSuggestedInQueue({
    srId,
    ...queueHandlerOptions,
  });

  const selectAiSuggestedCategories = useCallback(() => {
    dispatch(setSuggestedCategorySelected({ srId, isSelected: true }));
  }, [srId, dispatch]);

  useEffect(() => {
    if (suggestedCategory?.isSelected) {
      if (queueHandlerOptions) {
        const { listKey } = queueHandlerOptions;
        handleCategoryChangedToSuggestedInQueue();

        if (listKey === 'primaryCategory') {
          /*
            In the queue this runs 3 times(once for each category level,
            because the hook is used in each instance of the CategoryRender),
            so to track this once we have to filter only for one of the levels.
          */
          trackSuggestedCategoriesChosen();
        }
      } else {
        const { handleAllLevelChange } = panelHandlerOptions;
        handleAllLevelChange(suggestedCategory);
        trackSuggestedCategoriesChosen();
      }

      dispatch(setSuggestedCategorySelected({ srId, isSelected: false }));
    }
  }, [
    suggestedCategory,
    isTripleCategory,
    srId,
    dispatch,
    handleCategoryChangedToSuggestedInQueue,
    queueHandlerOptions,
    panelHandlerOptions,
    trackSuggestedCategoriesChosen,
  ]);

  return {
    selectAiSuggestedCategories,
  };
};
