import { memo } from 'react';
import PropTypes from 'prop-types';
import { InputLabel, OutlinedInput } from '@mui/material';
import { ReactComponent as SearchIcon } from '../../../images/icons/searchIcon.svg';
import { ReactComponent as ClearIcon } from '../../../images/icons/clearIcon.svg';
import { StyledSearchField, StyledInputAdornment } from './style';
import useTexts from './useTexts';

const SearchField = memo((props) => {
  //TODO: will this set the default value?
  const { defaultPlaceholderText } = useTexts();
  const {
    id,
    value,
    onChange,
    onKeyDown,
    onClear,
    placeholderText = defaultPlaceholderText,
    className = '',
    name,
    type = 'text',
    autoFocus,
    inputProps,
  } = props;

  const handleKeyPress = (event) => {
    if (type === 'number') {
      if (event.key >= 0 && event.keyCode <= 9) {
        return;
      }
      event.preventDefault();
    }
  };
  const testIdPrefix = id ? `${id}-` : '';
  const IconComponent = ({ value }) => {
    if (value) {
      return <ClearIcon data-testid={`${testIdPrefix}clear-icon`} onClick={onClear} />;
    }
    return <SearchIcon data-testid={`${testIdPrefix}search-icon`} />;
  };

  return (
    <StyledSearchField data-testid="root" className={`SearchField ${className}`} id={id} data-cy="search-field">
      <OutlinedInput
        autoFocus={autoFocus}
        type={type}
        placeholder={placeholderText}
        value={value}
        name={name}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onKeyPress={handleKeyPress}
        inputProps={inputProps}
        endAdornment={
          <StyledInputAdornment position="end">
            <IconComponent value={value} />
          </StyledInputAdornment>
        }
      >
        <InputLabel />
      </OutlinedInput>
    </StyledSearchField>
  );
});

export default SearchField;

SearchField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onClear: PropTypes.func,
  placeholderText: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
};
