import { useUserInfo } from 'remote-state/userServiceHooks';
import { USER_CONSTANTS } from 'constants/users';
import { QUERIES_KEYS } from 'constant';
import Tooltip from 'common/components/tooltip';
import BirdIcon from 'images/icons/bird_log_meta_data.svg';

import { getDate } from 'common/utils/dateTimeUtils';
import useTexts from '../../../useTexts';
import { isAutomationAuditLog } from '../../utils';
import { StyledLogMetaData } from './style';
import LogMetaUser from '../logUser';

export default function LogMetaData(props) {
  const { auditLog, srPanelCollapsed } = props;
  const { editedText } = useTexts();
  const { username, logTimestamp, logInformation, usernameId } = auditLog;

  const userInfo = useUserInfo(QUERIES_KEYS.CURRENT_USER_FROM_CORE);

  const isRichTextTruncated = auditLog?.isRichTextTruncated;
  const isRichTextTruncatedSecond = auditLog?.isRichTextTruncatedSecond;

  const { valueCaption: timezone } = userInfo?.data?.info?.find((atr) => atr.key === USER_CONSTANTS.TIMEZONE) || {};
  const { valueCaption: dateFormat } =
    userInfo?.data?.info?.find((atr) => atr.key === USER_CONSTANTS.DATE_FORMAT) || {};

  return (
    <StyledLogMetaData
      srPanelCollapsed={srPanelCollapsed}
      isRichTextTruncated={isRichTextTruncated}
      isRichTextTruncatedSecond={isRichTextTruncatedSecond}
      data-testid="log-meta-data"
    >
      {isAutomationAuditLog(auditLog) ? (
        <Tooltip
          title={logInformation?.updateMessage}
          text={
            <div className="bird-wrapper" data-testid="bird-wrapper">
              <img alt="bird" src={BirdIcon} className="bird-icon" />
              <span className="bird-title">{logInformation?.updateTaskName}</span>
            </div>
          }
          placement="top-start"
        />
      ) : (
        <LogMetaUser userName={username} userId={usernameId} logInformation={logInformation} />
      )}
      {!srPanelCollapsed && <span className="dot" />}
      <span className="date">
        {auditLog?.logInformation?.edited && <span className="is-edited">{editedText}</span>}
        {getDate(timezone, dateFormat, logTimestamp)}
      </span>
    </StyledLogMetaData>
  );
}
