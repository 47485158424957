import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useStyleHelpers } from '../dropdownSelect/hooks';

export const styleOverrides = {
  selectedBackgroundColor: '#F5F6FA',
  hoverBackgroundColor: '#F2F3F6',
};

const valueClassToStyleMap = {
  0: {
    backgroundColor: '#516C96',
    hover: {
      backgroundColor: '#344C73',
    },
  },
  1: {
    backgroundColor: '#787878',
    hover: {
      backgroundColor: '#4F4F4F',
    },
  },
};

export const customStyle = (valueClass) => ({
  color: '#ffffff',
  borderRadius: '4px',
  padding: '4px 12px',
  lineHeight: '16px',
  fontSize: '12px',
  fontFamily: 'Roboto-Bold',
  fontStyle: 'normal',
  letterSpacing: '0.0035px',
  backgroundColor: valueClassToStyleMap[valueClass]?.backgroundColor,
  largeScreenStyles: {
    'max-width': '126px;',
  },
  mediumScreenStyles: {
    'max-width': '126px;',
  },
  smallScreenStyles: {
    'max-width': '91px;',
  },
});

export const customHeaderStyles = ({ valueClass } = {}) => ({
  ...customStyle(valueClass),
  hover: valueClassToStyleMap[valueClass]?.hover,
});

export const Label = styled(Box, {
  shouldForwardProp: (prop) =>
    !['marginLeft', 'stylingVariant', 'color', 'isTemplatePage', 'isReadOnly', 'isEmpty'].includes(prop),
})(({ theme, stylingVariant, color, isReadOnly, marginLeft, isTemplatePage, isEmpty }) => {
  const { selectItemColor, selectItemBorderRadius, selectItemBgColor, selectHoveredItemBgColor } = useStyleHelpers({
    theme,
    stylingVariant,
    color,
  });

  const emptyStyles = {
    color: isTemplatePage ? theme.palette.grey.bt_def_grey_reg : theme.palette.labels.bt_red_reg,
    backgroundColor: '#FFFFFF',
    border: `1px solid ${isTemplatePage ? theme.palette.grey.bt_def_grey_reg : theme.palette.labels.bt_red_reg}`,
    padding: '3px 12px',
    '& .MuiTypography-root': {
      fontFamily: theme.fonts.robotoRegular,
      fontWeight: '400',
    },
    '&:hover': {
      backgroundColor: isTemplatePage ? theme.palette.labels.bt_black_light : theme.palette.labels.bt_red_light,
      color: isTemplatePage ? theme.palette.labels.bt_black_reg : theme.palette.labels.bt_red_hover,
      cursor: 'pointer',
    },
  };

  return {
    fontSize: '0.75rem',
    lineHeight: '16px',
    fontFamily: theme.fonts.robotoBold,
    padding: theme.spacing(0.5, 1.5),
    marginLeft: marginLeft || theme.spacing(3),
    borderRadius: selectItemBorderRadius(),
    color: selectItemColor(),
    backgroundColor: selectItemBgColor(),
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    span: {
      fontFamily: theme.fonts.robotoBold,
    },
    '&:hover': {
      cursor: isReadOnly ? 'default' : 'pointer',
      backgroundColor: selectHoveredItemBgColor(isReadOnly),
    },
    ...(isEmpty ? emptyStyles : {}),

    '@media only screen and (max-width: 1920px)': {
      maxWidth: '150px',
    },
    '@media only screen and (max-width: 1440px)': {
      maxWidth: '150px',
    },
    '@media only screen and (max-width: 1280px)': {
      maxWidth: '115px',
    },
  };
});
