import { memo } from 'react';
import { ReactComponent as TotalTimeIcon } from 'images/icons/total-time.svg';

import { StyledTotalTime } from '../style';

const TotalTime = ({ hours, minutes, isNewField, onChange }) => (
  <StyledTotalTime isNewField={isNewField} isLongHours={hours.length > 2}>
    <div>
      <input
        data-testid="total-hours"
        type="number"
        min={0}
        name="hours"
        value={hours}
        onChange={onChange}
      />
      <span>:</span>
      <input
        data-testid="total-minutes"
        type="number"
        min={0}
        max={59}
        name="minutes"
        value={minutes}
        onChange={onChange}
      />
    </div>
    <TotalTimeIcon data-testid="total-time-icon" />
  </StyledTotalTime>
);

export default memo(TotalTime);
