import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { FloatingTicketInstanceModes } from '../floatingTicketPanelManager/modes';
import { CONSTANTS } from '../utils/constants';

const { COLLAPSED_MIN_WIDTH, OPENED_MAX_WIDTH, RIGHT_BUFFER, DEFAULT_RIGHT_BUFFER } = CONSTANTS;
const StyledFloatingignoreParams = [
  'mode',
  'isOnScreen',
  'wantedExpandedOrOpenedWidth',
  'wantedCollapsedWidth',
  'right',
  'isRightArrowShown',
];
export const StyledFloatingTicketPanelInstance = styled(Box, {
  shouldForwardProp: (prop) => !StyledFloatingignoreParams.includes[prop],
})(({ theme, mode, isOnScreen, wantedExpandedOrOpenedWidth, wantedCollapsedWidth, right, isRightArrowShown }) => {
  let style = {
    transition: 'right 0.4s ease, top 0.4s ease, opacity 0.4s ease',
    position: 'fixed',
    minWidth: COLLAPSED_MIN_WIDTH,
    bottom: 0,
    opacity: isOnScreen ? 1 : 0,
    zIndex: 9999,
    backgroundColor: theme?.palette.generic?.primary,
    overflow: 'hidden',
    right: `calc(${right}px + ${isRightArrowShown ? RIGHT_BUFFER : DEFAULT_RIGHT_BUFFER}px)`,
    borderRadius: '10px 10px 0 0',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#DADCDE',
    boxShadow: '0px -10px 24px -4px rgba(16, 24, 40, 0.08), 0px -8px 8px -4px rgba(16, 24, 40, 0.03)',
  };

  switch (mode) {
    case FloatingTicketInstanceModes.COLLAPSED:
      style = {
        ...style,
        width: `${wantedCollapsedWidth}px`,
        minWidth: COLLAPSED_MIN_WIDTH,
        height: '48px',
        borderColor: '#4F4F4F',
      };
      break;

    case FloatingTicketInstanceModes.REGULAR_MODE_OPENED:
      style = {
        ...style,
        width: `${wantedExpandedOrOpenedWidth}px`,
        maxWidth: `${OPENED_MAX_WIDTH}px`,
        height: '73.16vh',
      };
      break;

    case FloatingTicketInstanceModes.EXPANDED:
      style = {
        ...style,
        height: '90%',
        position: 'fixed',
        left: '0',
        right: '0',
        top: '0',
        bottom: '0',
        margin: 'auto',
        zIndex: 1000,
        width: `${OPENED_MAX_WIDTH}px`,
        inset: '0px',
        borderRadius: '10px',
      };
      break;

    case FloatingTicketInstanceModes.AUTO_POPULATE:
      style = {
        ...style,
        height: '386px',
        width: `${wantedExpandedOrOpenedWidth}px`,
        maxWidth: `${OPENED_MAX_WIDTH}px`,
      };
      break;

    default:
      break;
  }

  return {
    backgroundColor: theme?.palette.generic?.primary,
    alignSelf: 'end',
    '.floatingPanelWrapper ': {
      display: 'flex',
      flexDirection: 'column',
      ...style,
      '.floatingPanelSrPanel': {
        '.expanded': {
          height: '100%',
        },
        flexGrow: 1,
      },
    },
  };
});

export const StyledFloatingTicketPanelInstanceBackground = styled(Box)(() => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 1,
}));
