import { Subject } from 'rxjs';
import { CONSTANTS } from './constants';

const subject$ = new Subject();
let interval;
let timeout;

const getIntervalTime = () => {
  const currentDate = new Date();
  const currentTime = currentDate.getTime();
  const startTime = currentDate.setSeconds(0, 0);
  const startTimer = CONSTANTS.MINUTE - (currentTime - startTime);

  timeout = setTimeout(() => {
    subject$.next(new Date());
    interval = setInterval(() => {
      subject$.next(new Date());
      if (timeout) {
        clearTimeout(timeout);
      }
    }, CONSTANTS.MINUTE);
  }, startTimer);
};

export const timerService = {
  isTimerActive: false,
  init: () => {
    timerService.isTimerActive = true;
    getIntervalTime();
  },
  register: () => subject$.asObservable(),
  clear: () => {
    if (interval) {
      clearInterval(interval);
      timerService.isTimerActive = false;
    }
  },
};
