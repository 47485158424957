export const PERFORMANCE_MEASURE = {
  QUEUE: {
    MOUNT: 'MOUNT',
    DATA_POPULATION: 'DATA_POPULATION',
    VIEW_CHANGE: 'VIEW_CHANGE',
    SORT_CHANGE: 'SORT_CHANGE',
    FILTER_CHANGE: 'FILTER_CHANGE',
    SR_TYPE_CHANGE: 'SR_TYPE_CHANGE',
    CREATED_TICKET_DISPLAY: 'CREATED_TICKET_DISPLAY',
    OPEN_EXISTING_TICKET: 'OPEN_EXISTING_TICKET',
    CREATE_TICKET_BTN: 'CREATE_TICKET_BTN',
  },
  TICKET: {
    OPEN_FORM: 'OPEN_FORM',
    BACK_BTN_CLICK: 'BACK_BTN_CLICK',
  },
  FLOATING_TICKET_PANEL: {
    POPULATE_FIELDS: 'POPULATE_FIELDS',
    SAVE: 'SAVE',
  },
  LOGIN: {
    START: 'START',
    END: 'END',
  },
  EVENTS: {
    QUEUE_LOADING_TIME: 'QUEUE_LOADING_TIME',
    QUEUE_LOAD_NEXT_PAGE: 'QUEUE_LOAD_NEXT_PAGE',
    OPEN_EXISTING_TICKET_FORM: 'OPEN_EXISTING_TICKET_FORM',
    NAVIGATE_FROM_TICKET_TO_QUEUE: 'NAVIGATE_FROM_TICKET_TO_QUEUE',
    QUEUE_VIEW_CHANGE: 'QUEUE_VIEW_CHANGE',
    QUEUE_FILTER_CHANGE: 'QUEUE_FILTER_CHANGE',
    QUEUE_SORT_CHANGE: 'QUEUE_SORT_CHANGE',
    QUEUE_SR_TYPE_CHANGE: 'QUEUE_SR_TYPE_CHANGE',
    POPULATE_NEW_TICKET_FIELDS: 'POPULATE_NEW_TICKET_FIELDS',
    CREATED_TICKET_ADDED_TO_QUEUE: 'CREATED_TICKET_ADDED_TO_QUEUE',
    LOGIN: 'LOGIN',
  },
};
