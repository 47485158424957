import { styled } from '@mui/material/styles';

export const StyledSRLockedHeader = styled('div')(() => ({
  '&.sr-lock-header.position-top': {
    top: '10px',
    left: 0,
    right: 0,
    zIndex: 5,
    margin: '0 auto',
    width: 'max-content',
    position: 'absolute',
  },
}));
