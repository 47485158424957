/* tslint:disable */
/* eslint-disable */
/**
 * Attachments APIs
 * This is the Open API specification for Attachments microservice API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: sysaid-rnd-spaces@sysaid.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
// Some imports not used depending on template conditions
// @ts-ignore
import { axiosInstance } from 'services/axiosConfig';

import { BaseAPI } from '../base';
import { AttachmentMetadata, ResponseError } from '../models';
import { ResourceType, FileSize, AttachmentPath } from '../constants';
import { handleParams } from './utils';
/**
 * AttachmentsApi - axios parameter creator
 * @export
 */
window.abortControllers = [];
export const AttachmentsApiAxiosParamCreator = () => {

  function buildFormData(localVarPath, metadata, uploadOptions) {

    // use dummy base URL string because the URL constructor only accepts absolute URLs.
    const localVarUrlObj = new URL(localVarPath, 'https://example.com');
    const localVarRequestOptions = { method: 'POST', ...uploadOptions };
    const localVarHeaderParameter = {};
    const localVarQueryParameter = {};
    const localVarFormParams = new FormData();

    if (metadata !== undefined) {
      localVarFormParams.append('metadata', JSON.stringify(metadata));
    }

    localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    const query = new URLSearchParams(localVarUrlObj.search);
    for (const key in localVarQueryParameter) {
      query.set(key, localVarQueryParameter[key]);
    }
    localVarUrlObj.search = new URLSearchParams(query).toString();
    localVarRequestOptions.headers = { ...localVarHeaderParameter };
    localVarRequestOptions.data = localVarFormParams;

    return {
      localVarUrlObj,
      localVarRequestOptions,
      localVarFormParams
    };
  }

  return {
    /**
     *
     * @summary Create a new attachment
     * @param {Blob} payload
     * @param {AttachmentMetadata} metadata
     * @param fileMaxSize
     * @param options
     * @throws {ResponseError}
     */
    createNewAttachmentForm: async (payload, metadata, fileMaxSize, options = {}) => {
      const abortController = new AbortController();
      window.abortControllers.push(abortController);
      const uploadOptions = { ...options, signal: abortController.signal };
      window.setAbort = () => {
        window.abortControllers.forEach((controller) => {
          controller.abort();
        });
        window.abortControllers = [];
      };
      // verify required parameter 'payload' is not null or undefined
      if (payload === null || payload === undefined) {
        throw new ResponseError('payload', {
          data: {
            error: 'Required parameter payload was null or undefined when calling createNewAttachmentForm',
          },
        });
      }
      // verify required parameter 'metadata' is not null or undefined
      if (metadata === null || metadata === undefined) {
        throw new ResponseError('metadata', {
          data: {
            error: 'Required parameter metadata was null or undefined when calling createNewAttachmentForm',
          },
        });
      }

      if (fileMaxSize === null || fileMaxSize === undefined) {
        throw new ResponseError('fileMaxSize', {
          data: {
            error: 'Required parameter fileMaxSize was null or undefined when calling createNewAttachmentForm',
          },
        });
      }

      if (payload.size > fileMaxSize * FileSize.MB) {
        throw new ResponseError('fileMaxSize', {
          status: 413,
        });
      }
      const localVarPath = AttachmentPath.DEFAULT;
      const {
        localVarFormParams,
        localVarUrlObj,
        localVarRequestOptions
      } = buildFormData(localVarPath, metadata, uploadOptions);
      localVarFormParams.append('payload', payload);

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    createNewAttachmentsForm: async (payload, metadata, fileMaxSize, options = {}) => {
      const abortController = new AbortController();
      window.abortControllers.push(abortController);
      const uploadOptions = { ...options, signal: abortController.signal };
      window.setAbort = () => {
        window.abortControllers.forEach((controller) => {
          controller.abort();
        });
        window.abortControllers = [];
      };
      // verify required parameter 'payload' is not null or undefined
      if (payload === null || payload === undefined || payload.length === 0) {
        throw new ResponseError('payload', {
          data: {
            error: 'Required parameter payload was null or undefined when calling createNewAttachmentsForm',
          },
        });
      }
      // verify required parameter 'metadata' is not null or undefined
      if (metadata === null || metadata === undefined) {
        throw new ResponseError('metadata', {
          data: {
            error: 'Required parameter metadata was null or undefined when calling createNewAttachmentsForm',
          },
        });
      }

      if (fileMaxSize === null || fileMaxSize === undefined) {
        throw new ResponseError('fileMaxSize', {
          data: {
            error: 'Required parameter fileMaxSize was null or undefined when calling createNewAttachmentForm',
          },
        });
      }

      for (let file in payload) {
        if (file.size > fileMaxSize * FileSize.MB) {
          throw new ResponseError('fileMaxSize', {
            status: 413,
          });
        }
      }

      const localVarPath = AttachmentPath.DEFAULT + 'multiple';
      const {
        localVarFormParams,
        localVarUrlObj,
        localVarRequestOptions
      } = buildFormData(localVarPath, metadata, uploadOptions);
      payload.forEach((file) => {
        localVarFormParams.append('payload', file);
      });

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    /**
     *
     * @summary Delete attachment by id
     * @param {string} attachmentId The attachment id
     * @param isDeleteDuplicate
     * @param subResourceType
     * @throws {ResponseError}
     */
    deleteAttachmentById: async (attachmentId, isDeleteDuplicate, subResourceType) => {
      // verify required parameter 'attachmentId' is not null or undefined
      if (attachmentId === null || attachmentId === undefined) {
        throw new ResponseError('attachmentId', {
          data: {
            error: 'Required parameter attachmentId was null or undefined when calling deleteAttachmentById.',
          },
        });
      }

      const localVarPath = `${AttachmentPath.V3}{attachment_id}${
        isDeleteDuplicate ? '?delete_duplicate=true' : ''
      }${
        subResourceType ? `${isDeleteDuplicate ? '&' : '?'}sub_resource_type=${subResourceType}` : ''
      }`.replace(`{${'attachment_id'}}`, encodeURIComponent(attachmentId));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      const localVarRequestOptions = { method: 'DELETE' };
      return handleParams(localVarUrlObj, localVarRequestOptions);
    },
    /**
     *
     * @summary Reassign attachments by id
     * @param {number[]} attachmentIds The attachment ids
     * @param {AttachmentMetadata} params The attachments parameters
     * @throws {ResponseError}
     */
    reassignTempAttachmentsById: async (attachmentIds, params) => {
      // verify required parameter 'attachmentIds' is not null or undefined or empty ids array
      if (attachmentIds === null || attachmentIds === undefined || !attachmentIds.length) {
        throw new ResponseError('attachmentIds', {
          data: {
            error:
              'Required parameter attachmentIds was null or undefined or array is empty when calling reassignTempAttachmentsById.',
          },
        });
      }
      // verify required parameter 'resourceId' is not null or undefined
      if (params.resourceId === null || params.resourceId === undefined) {
        throw new ResponseError('resourceId', {
          data: {
            error: 'Required parameter resourceId was null or undefined when calling reassignTempAttachmentsById',
          },
        });
      }
      // verify required parameter 'resourceType' is not null or undefined
      if (params.resourceType === null || params.resourceType === undefined) {
        throw new ResponseError('resourceType', {
          data: {
            error: 'Required parameter resourceType was null or undefined when calling reassignTempAttachmentsById',
          },
        });
      }
      // verify required parameter 'targetSubResourceId' is not null or undefined
      if (params.targetSubResourceId === null || params.targetSubResourceId === undefined) {
        throw new ResponseError('targetSubResourceId', {
          data: {
            error:
              'Required parameter targetSubResourceId was null or undefined when calling reassignTempAttachmentsById',
          },
        });
      }
      // verify required parameter 'targetSubResourceType' is not null or undefined
      if (params.targetSubResourceType === null || params.targetSubResourceType === undefined) {
        throw new ResponseError('targetSubResourceType', {
          data: {
            error:
              'Required parameter targetSubResourceType was null or undefined when calling reassignTempAttachmentsById',
          },
        });
      }

      const localVarPath = `/${AttachmentPath.DEFAULT}reassign_temp_attachments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      const localVarRequestOptions = { method: 'PUT' };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      localVarRequestOptions.headers = { ...localVarHeaderParameter };
      localVarRequestOptions.data = { attachmentIds, ...params };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get attachment by id
     * @param {number} attachmentId The attachment id
     * @throws {ResponseError}
     */
    findAttachmentById: async (attachmentId) => {
      // verify required parameter 'attachmentId' is not null or undefined
      if (attachmentId === null || attachmentId === undefined) {
        throw new ResponseError('attachmentId', {
          error: 'Required parameter attachmentId was null or undefined when calling findAttachmentById.',
        });
      }
      const localVarPath = `${AttachmentPath.V3}{attachment_id}`.replace(
        `{${'attachment_id'}}`,
        encodeURIComponent(String(attachmentId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      const localVarRequestOptions = { method: 'GET' };

      return handleParams(localVarUrlObj, localVarRequestOptions);
    },
    /**
     *
     * @summary Get attachments by resource type and resource id
     * @param {ResourceType} resourceType Attachment resource type
     * @param {number} resourceId Attachment resource id
     * @param limit
     * @param distinct
     * @throws {ResponseError}
     */
    getAttachmentsByResource: async (resourceType, resourceId, limit = null, distinct, subResourceType) => {
      // verify required parameter 'resourceType' is not null or undefined
      if (resourceType === null || resourceType === undefined) {
        throw new ResponseError('resourceType', {
          data: {
            error: 'Required parameter resourceType was null or undefined when calling getAttachmentsByResource.',
          },
        });
      }
      // verify required parameter 'resourceId' is not null or undefined
      if (resourceId === null || resourceId === undefined) {
        throw new ResponseError('resourceId', {
          data: {
            error: 'Required parameter resourceId was null or undefined when calling getAttachmentsByResource.',
          },
        });
      }
      const localVarPath = AttachmentPath.DEFAULT;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      const localVarRequestOptions = { method: 'GET' };
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};

      if (resourceType !== undefined) {
        localVarQueryParameter['resource_type'] = resourceType;
      }
      if (subResourceType !== undefined) {
        localVarQueryParameter['sub_resource_type_list'] = subResourceType;
      }

      if (resourceId !== undefined) {
        localVarQueryParameter['resource_id'] = resourceId;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (distinct) {
        localVarQueryParameter['distinct'] = true;
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      localVarRequestOptions.headers = localVarHeaderParameter;

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get attachment payload by attachment id
     * @param {number} attachmentId The attachment id
     * @param inline
     * @param subResourceType
     * @throws {ResponseError}
     */
    findPayloadByAttachmentId: async (attachmentId, inline, subResourceType) => {
      // verify required parameter 'attachmentId' is not null or undefined
      if (attachmentId === null || attachmentId === undefined) {
        throw new ResponseError('attachmentId', {
          error: 'Required parameter attachmentId was null or undefined when calling findPayloadByAttachmentId.',
        });
      }
      const localVarPath = `${AttachmentPath.V3}{attachment_id}/payload`.replace(
        `{${'attachment_id'}}`,
        encodeURIComponent(String(attachmentId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      const localVarRequestOptions = { method: 'GET' };
      const localVarQueryParameter = {};

      if (inline !== undefined) {
        localVarQueryParameter['inline'] = inline;
      }
      if (subResourceType !== undefined) {
        localVarQueryParameter['sub_resource_type'] = subResourceType;
      }

      return handleParams(localVarUrlObj, localVarRequestOptions, localVarQueryParameter);
    },
  };
};

/**
 * AttachmentsApi - functional programming interface
 * @export
 */
export const AttachmentsApiFp = () => {
  return {
    /**
     *
     * @summary Create a new attachment
     * @param {Blob} payload
     * @param {AttachmentMetadata} metadata
     * @param fileMaxSize
     * @param options
     * @throws {ResponseError}
     */
    async createNewAttachmentForm(payload, metadata, fileMaxSize, options) {
      const localVarAxiosArgs = await AttachmentsApiAxiosParamCreator().createNewAttachmentForm(
        payload,
        metadata,
        fileMaxSize,
        options,
      );
      return (axios = axiosInstance) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },

    async createNewAttachmentsForm(payload, metadata, fileMaxSize, options) {
      const localVarAxiosArgs = await AttachmentsApiAxiosParamCreator().createNewAttachmentsForm(
        payload,
        metadata,
        fileMaxSize,
        options,
      );
      return (axios = axiosInstance) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },

    /**
     *
     * @summary Delete attachment by id
     * @param {string} attachmentId The attachment id
     * @param isDeleteDuplicate
     * @throws {ResponseError}
     */
    async deleteAttachmentById(attachmentId, isDeleteDuplicate, subResourceType) {
      const localVarAxiosArgs = await AttachmentsApiAxiosParamCreator().deleteAttachmentById(
        attachmentId,
        isDeleteDuplicate,
        subResourceType,
      );
      return (axios = axiosInstance) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Reassign attachments by id
     * @param {number[]} attachmentIds The attachment ids
     * @param {AttachmentMetadata} params The attachments parameters
     * @throws {ResponseError}
     */
    async reassignTempAttachmentsById(attachmentIds, params) {
      const localVarAxiosArgs = await AttachmentsApiAxiosParamCreator().reassignTempAttachmentsById(
        attachmentIds,
        params,
      );
      return (axios = axiosInstance) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get attachment by id
     * @param {number} attachmentId The attachment id
     * @throws {ResponseError}
     */
    async findAttachmentById(attachmentId) {
      const localVarAxiosArgs = await AttachmentsApiAxiosParamCreator().findAttachmentById(attachmentId);
      return (axios = axiosInstance) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get attachments by resource type and resource id
     * @param {ResourceType} resourceType Attachment resource type
     * @param {number} resourceId Attachment resource id
     * @param {Limit} [limit] Number of attachments to be returned (default 20)
     * @param distinct
     * @throws {ResponseError}
     */
    async getAttachmentsByResource(resourceType, resourceId, limit = null, distinct, subResourceType) {
      const localVarAxiosArgs = await AttachmentsApiAxiosParamCreator().getAttachmentsByResource(
        resourceType,
        resourceId,
        limit,
        distinct,
        subResourceType,
      );
      return (axios = axiosInstance) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get attachment payload by attachment id
     * @param {number} attachmentId The attachment id
     * @param inline
     * @throws {ResponseError}
     */
    async findPayloadByAttachmentId(attachmentId, inline, subResourceType) {
      const localVarAxiosArgs = await AttachmentsApiAxiosParamCreator().findPayloadByAttachmentId(attachmentId, inline, subResourceType);
      return (axios = axiosInstance) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * AttachmentsApi - object-oriented interface
 * @export
 * @class AttachmentsApi
 * @extends {BaseAPI}
 */
export class AttachmentsApi extends BaseAPI {
  /**
   *
   * @summary Create a new attachment
   * @param {Blob} payload
   * @param {AttachmentMetadata} metadata
   * @param fileMaxSize
   * @param options
   * @throws {ResponseError}
   * @memberof AttachmentsApi
   */
  async createNewAttachmentForm({ payload, metadata, fileMaxSize, options }) {
    return AttachmentsApiFp()
      .createNewAttachmentForm(payload, metadata, fileMaxSize, options)
      .then((request) => request(this.axios))
      .then((response) => response.data);
  }

  async createNewAttachmentsForm({ payload, metadata, fileMaxSize, options }) {
    return AttachmentsApiFp()
      .createNewAttachmentsForm(payload, metadata, fileMaxSize, options)
      .then((request) => request(this.axios))
      .then((response) => response.data);
  }
  /**
   *
   * @summary Delete attachment by id
   * @param {string} attachmentId The attachment id
   * @param isDeleteDuplicate
   * @throws {ResponseError}
   * @memberof AttachmentsApi
   */
  async deleteAttachmentById({ attachmentId, isDeleteDuplicate, subResourceType }) {
    return AttachmentsApiFp()
      .deleteAttachmentById(attachmentId, isDeleteDuplicate, subResourceType)
      .then((request) => request(this.axios))
      .then((response) => response.data);
  }
  /**
   *
   * @summary Reassign attachments by id
   * @param {number[]} attachmentIds The attachment ids
   * @param {AttachmentMetadata} params The attachments parameters
   * @throws {ResponseError}
   * @memberof AttachmentsApi
   */
  async reassignTempAttachmentsById({ attachmentIds, param }) {
    return AttachmentsApiFp()
      .reassignTempAttachmentsById(attachmentIds, param)
      .then((request) => request(this.axios))
      .then((response) => response.data);
  }
  /**
   *
   * @summary Get attachment by id
   * @param {number} attachmentId The attachment id
   * @throws {ResponseError}
   * @memberof AttachmentsApi
   */
  async findAttachmentById({ attachmentId }) {
    return AttachmentsApiFp()
      .findAttachmentById(attachmentId)
      .then((request) => request(this.axios));
  }
  /**
   *
   * @summary Get attachments by resource type and resource id
   * @param {ResourceType} resourceType Attachment resource type
   * @param {number} resourceId Attachment resource id
   * @param {Limit} [limit] Number of attachments to be returned (default 20)
   * @param distinct
   * @throws {ResponseError}
   * @memberof AttachmentsApi
   */
  async getAttachmentsByResource({
    resourceId,
    resourceType = ResourceType.SR,
    limit = null,
    distinct,
    subResourceType,
  }) {
    return AttachmentsApiFp()
      .getAttachmentsByResource(resourceType, resourceId, limit, distinct, subResourceType)
      .then((request) => request(this.axios))
      .then((response) => response.data);
  }
  /**
   *
   * @summary Get attachment payload by attachment id
   * @param {number} attachmentId The attachment id
   * @param inline
   * @param subResourceType
   * @throws {ResponseError}
   * @memberof AttachmentsApi
   */
  async findPayloadByAttachmentId({ attachmentId, inline = false, subResourceType }) {
    return AttachmentsApiFp()
      .findPayloadByAttachmentId(attachmentId, inline, subResourceType)
      .then((request) => request(this.axios));
  }
}
