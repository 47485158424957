import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIsNavBarCollapsed, selectIsNavBarHidden } from 'features/loggedInRoutesPanel/slice';
import { CategoryProvider } from 'store/CategoryContext';
import { StyledFloatingTicketPanelInstance } from './style';
import { indexOnScreen, getWantedWidth, calculateRight, getVisiblePanelsOnScreenCount } from '../utils/utils';
import FloatingTicketPanel from '..';

export function FloatingTicketPanelInstance(props) {
  const {
    panel,
    index,
    startIndexToShow,
    openedTicketPanelIndex,
    expandedTicketPanelIndex,
    totalPanels,
    isRightArrowShown,
  } = props;
  const [navBarWidth, setNavBarWidth] = useState(0);
  const isNavBarCollapsed = useSelector(selectIsNavBarCollapsed);
  const isNavBarHidden = useSelector(selectIsNavBarHidden);

  useEffect(() => {
    if (!isNavBarHidden) {
      const navBarWidth = document.getElementById('nav-bar')?.offsetWidth;
      setNavBarWidth(navBarWidth);
    } else {
      setNavBarWidth(0);
    }
  }, [isNavBarHidden, isNavBarCollapsed]);

  const panelsOnScreen = getVisiblePanelsOnScreenCount(navBarWidth);

  const right = calculateRight(
    index,
    startIndexToShow,
    openedTicketPanelIndex,
    expandedTicketPanelIndex,
    panelsOnScreen,
    totalPanels,
    navBarWidth,
  );

  const { wantedCollapsedWidth, wantedExpandedOrOpenedWidth } = getWantedWidth(
    openedTicketPanelIndex,
    expandedTicketPanelIndex,
    totalPanels,
    navBarWidth,
  );

  const isOnScreen = indexOnScreen(index, panelsOnScreen);

  return (
    <StyledFloatingTicketPanelInstance
      mode={panel.mode}
      isOnScreen={isOnScreen}
      wantedCollapsedWidth={wantedCollapsedWidth}
      wantedExpandedOrOpenedWidth={wantedExpandedOrOpenedWidth}
      right={right}
      isRightArrowShown={isRightArrowShown}
      id={`floating_panel_${index}`}
      data-testid={`floating_panel_${index}`}
      data-cy={`floating_panel_${index}`}
    >
      <CategoryProvider>
        <FloatingTicketPanel panel={panel} panelWidth={wantedCollapsedWidth} className="floatingPanelWrapper" />
      </CategoryProvider>
    </StyledFloatingTicketPanelInstance>
  );
}
