import { AvatarGroup } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectActiveUser } from 'store/userSlice';
import { BasicAvatar } from 'common/components/avatars/basicAvatar';
import { DetailedAvatar } from 'common/components/avatars/detailedAvatar';
import User from 'common/components/user';
import { AvatarGroupWrapper } from 'common/components/avatars/avatarGroupWrapper';
import { AVATAR_CONSTANTS } from 'constants/index';
import { QUEUE } from 'features/queue/constants';
import { isNone } from 'common/utils/utils';
import { StyledAssigneeButton } from './style';
import { CONSTANTS } from '../constants';
import useTexts from '../useTexts';

export const AssigneeButton = (props) => {
  const { currentAssigned, bigAvatar, filteredAssignees, filteredGroups, disabled } = props;
  const userAccount = useSelector(selectActiveUser);
  const { loginUserText } = useTexts();

  const renderCurrentAssigned = () => (
    <AvatarGroup
      component={({ children }) => (
        <AvatarGroupWrapper size={bigAvatar ? AVATAR_CONSTANTS.SIZES.MEDIUM : AVATAR_CONSTANTS.SIZES.SMALL}>
          {children}
        </AvatarGroupWrapper>
      )}
    >
      {isNone(currentAssigned.group?.groupName) && isNone(currentAssigned.admin?.calculatedUserName) && (
        <BasicAvatar
          type={AVATAR_CONSTANTS.TYPES.UNASSIGNED}
          size={bigAvatar ? AVATAR_CONSTANTS.SIZES.MEDIUM : AVATAR_CONSTANTS.SIZES.SMALL}
          disableHover={disabled}
        />
      )}
      {!isNone(currentAssigned.admin?.calculatedUserName) && (
        <User userId={currentAssigned.admin?.id}>
          <BasicAvatar
            size={bigAvatar ? AVATAR_CONSTANTS.SIZES.MEDIUM : AVATAR_CONSTANTS.SIZES.SMALL}
            userName={currentAssigned?.admin?.calculatedUserName}
            profileImage={currentAssigned?.admin?.profileImage}
            disableHover={disabled}
          />
        </User>
      )}
      {!isNone(currentAssigned.group?.groupName) && (
        <User teamName={currentAssigned.group?.groupName}>
          <BasicAvatar
            type={AVATAR_CONSTANTS.TYPES.GROUP}
            size={bigAvatar ? AVATAR_CONSTANTS.SIZES.MEDIUM : AVATAR_CONSTANTS.SIZES.SMALL}
            disableHover={disabled}
          />
        </User>
      )}
    </AvatarGroup>
  );

  const renderFilteredAssignees = () => (
    <>
      {filteredAssignees?.length === 0 && filteredGroups?.length === 0 && (
        <BasicAvatar type={AVATAR_CONSTANTS.TYPES.UNASSIGNED} size={AVATAR_CONSTANTS.SIZES.BIG} />
      )}
      <AvatarGroup
        max={CONSTANTS.AVATAR_GROUP_MAX_ITEMS_TO_DISPLAY}
        component={({ children }) => (
          <AvatarGroupWrapper size={AVATAR_CONSTANTS.SIZES.BIG}>{children}</AvatarGroupWrapper>
        )}
      >
        {filteredAssignees?.map((assignee) => {
          let avatarType = AVATAR_CONSTANTS.TYPES.USER;
          if (assignee.isUnassigned || assignee.userName === QUEUE.unassigned.userName)
            avatarType = AVATAR_CONSTANTS.TYPES.UNASSIGNED;
          else if (assignee.groupName) avatarType = AVATAR_CONSTANTS.TYPES.GROUP;

          return (
            <DetailedAvatar
              key={assignee.userName}
              type={assignee.type || avatarType}
              size={AVATAR_CONSTANTS.SIZES.BIG}
              userName={assignee.calculatedUserName}
              previewName={assignee.calculatedUserName}
              profileImage={assignee.profileImage}
              userStyles={{ zIndex: 1 }}
            />
          );
        })}
        {filteredGroups?.map((group) => (
          <DetailedAvatar
            teamName={group.groupName}
            type={AVATAR_CONSTANTS.TYPES.GROUP}
            size={AVATAR_CONSTANTS.SIZES.BIG}
            key={group.groupName}
          />
        ))}
      </AvatarGroup>
      {filteredAssignees?.length === 1 &&
        filteredAssignees[0].calculatedUserName === userAccount?.calculatedUserName && (
          <div key="userAccountText" className="userAccountText">
            {loginUserText}
          </div>
        )}
    </>
  );

  return (
    <StyledAssigneeButton>{currentAssigned ? renderCurrentAssigned() : renderFilteredAssignees()}</StyledAssigneeButton>
  );
};
