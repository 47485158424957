import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  srDetails: {
    srId: -1,
    title: '',
  },
  isSummaryTooltipOpen: false,
};

const ticketSummarySlice = createSlice({
  name: 'ticketSummary',
  initialState,
  reducers: {
    setSrDetailsForSummary: (state, { payload }) => {
      state.srDetails = payload;
    },
    resetSrDetailsForSummary: (state) => {
      state.srDetails = { ...initialState.srDetails };
    },
    setIsSummaryTooltipOpen: (state, { payload }) => {
      state.isSummaryTooltipOpen = payload;
    },
  },
});

export const { setSrDetailsForSummary, resetSrDetailsForSummary, setIsSummaryTooltipOpen } = ticketSummarySlice.actions;

export const selectSummarySrId = (allSliceState) => allSliceState.ticketSummary.srId;

export const selectSummarySrTitle = (allSliceState) => allSliceState.ticketSummary.title;

export const selectTicketSummaryDetails = (allSliceState) => allSliceState.ticketSummary.srDetails;

export const selectIsSummaryTooltipOpen = (allSliceState) => allSliceState.ticketSummary.isSummaryTooltipOpen;

export default ticketSummarySlice.reducer;
export const ticketSummarySliceName = ticketSummarySlice.name;
