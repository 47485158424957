import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    noEmail: texts?.['spaces.resolutionPanel.journey.messages.noEmail'],
    showMore: texts?.['spaces.resolutionPanel.journey.messages.showMore'],
    groupLabel: {
      group: texts?.['spaces.header.current-assigned.teamsText'],
    },
  };

  return componentTexts;
}
