import { useCallback, useEffect, useRef, useState } from 'react';
import { useRouter } from '@tanstack/react-router';
import RichTextEditor from 'common/components/controls/RichTextEditor';
import { SubResourceType } from 'services/attachments';
import ResolutionHeader from './header';
import useTexts from './useTexts';
import ActionLineFooter from '../footer';
import { ShareSwitch } from '../footerElements/ShareWithRequestUser';
import {
  DefaultRichTextPlaceholder,
  StyledAddResolution,
  StyledSolutionResolutionWrapper,
  StyledResolutionWrapper,
  SolutionResolutionTitle,
  StyledLabel,
  StyledEndSpace,
  StyledSolutionWrapper,
  StyleFooter,
} from './style';
import { editorActionData } from '../../constants';
import { editorActionTypes } from '../../constants/editorActionTypes';

const SolutionResolution = ({
  data,
  texts,
  isNewEditor,
  lastSavedData,
  attachmentsList,
  handleEditorChange,
  isEditorDirty,
  onFileAttach,
  onImageUpload,
  activeUploads,
  onChangeActiveUploads,
  onAttachmentDeleted,
  shouldTriggerDeleteApi,
  editorActionSettings,
}) => {
  const router = useRouter();
  const [isSolutionFocused, setIsSolutionFocused] = useState(false);
  const [isResolutionFocused, setIsResolutionFocused] = useState(false);
  const [isResolutionVisible, setIsResolutionVisible] = useState(false);
  const [isUploadInProgress, setIsUploadInProgress] = useState(false);
  const solutionTextfieldRef = useRef(null);
  const resolutionTextfieldRef = useRef(null);
  const { addResolution, solution, resolution, resolve, resolveIncident } = useTexts();
  const srId = router.latestLocation.search.id;

  const commonProps = {
    isExpanded: true,
    isEditorDirty,
    onAttachmentDeleted,
    shouldTriggerDeleteApi,
    hasFullSize: false,
  };

  const showResolution = isResolutionVisible || data[editorActionSettings.resolutionTextKey];
  const showSolution = isSolutionFocused || data[editorActionSettings.solutionTextKey];
  const isReadOnlySolution = data[editorActionSettings.solutionTextKey] && !isSolutionFocused;
  const isReadOnlyResolution = data[editorActionSettings.resolutionTextKey] && !isResolutionVisible;
  const isShareWithReqUserChangedKey = 'isShareWithReqUserChanged';

  const handleChange = useCallback(
    (key, value) => {
      handleEditorChange(key, { [key]: value });
    },
    [handleEditorChange],
  );

  const handleSolutionFocus = (isFocus) => {
    if (!isUploadInProgress) {
      setIsSolutionFocused(isFocus);
    }
  };

  const handleResolutionFocus = (isFocus) => {
    if (!isUploadInProgress) {
      setIsResolutionFocused(isFocus);
      setIsResolutionVisible(isFocus);
    }
  };

  const handleChangeResolutionActiveUploads = (param) => {
    onChangeActiveUploads({ ...param, subResourceType: SubResourceType.RESOLUTION });
  };

  const handleChangeSolutionActiveUploads = (param) => {
    onChangeActiveUploads({ ...param, subResourceType: SubResourceType.SOLUTION });
  };

  const resolutionActiveUploads = activeUploads.filter(
    (upload) => upload.subResourceType === SubResourceType.RESOLUTION,
  );

  const solutionActiveUploads = activeUploads.filter((upload) => upload.subResourceType === SubResourceType.SOLUTION);

  const handleResolutionFileAttach = async (param) => {
    const attachment = await onFileAttach({ ...param, subResourceType: SubResourceType.RESOLUTION });
    return attachment;
  };

  const handleSolutionFileAttach = async (param) => {
    const attachment = await onFileAttach({ ...param, subResourceType: SubResourceType.SOLUTION });
    return attachment;
  };

  const handleResolutionImageUpload = async (param) => {
    const image = await onImageUpload({ ...param, subResourceType: SubResourceType.RESOLUTION });
    return image;
  };
  const handleSolutionImageUpload = async (param) => {
    const image = await onImageUpload({ ...param, subResourceType: SubResourceType.SOLUTION });
    return image;
  };

  const handleShareSwitch = async (key, value) => {
    const isEditing = lastSavedData.auditLogId;
    if (isEditing && !lastSavedData.sharedWithRequestUser) {
      handleChange(isShareWithReqUserChangedKey, true);
    }
    handleEditorChange(key, value);
  };

  const resolutionAttachmentsList = attachmentsList.filter(
    (upload) => upload.subResourceType === SubResourceType.RESOLUTION,
  );

  const solutionAttachmentsList = attachmentsList.filter(
    (upload) => upload.subResourceType === SubResourceType.SOLUTION,
  );

  const ShareWithRequestUserSwitch = (
    <StyleFooter>
      <ShareSwitch
        editorData={data}
        lastSavedData={lastSavedData}
        isNewEditor={isNewEditor}
        isEditorDirty={isEditorDirty}
        handleEditorChange={handleShareSwitch}
        editorType={editorActionTypes.RESOLUTION}
        className="share-solution"
        editorClassName={editorActionData[editorActionTypes.RESOLUTION]?.editorElementClassName}
        dataTestId="share-solution"
      />
    </StyleFooter>
  );

  useEffect(() => {
    if (isResolutionFocused ?? resolutionTextfieldRef.current) {
      resolutionTextfieldRef.current?.focus();
    }
  }, [isResolutionFocused]);

  useEffect(() => {
    if (showSolution ?? solutionTextfieldRef.current) {
      solutionTextfieldRef.current?.focus();
    }
  }, [showSolution]);

  return (
    <>
      <StyledSolutionResolutionWrapper
        data-testid={`${isNewEditor ? 'new' : 'edit'}-solution-resolution-box`}
        data-cy={`${isNewEditor ? 'new' : 'edit'}-solution-resolution-box`}
      >
        <SolutionResolutionTitle>{resolveIncident}</SolutionResolutionTitle>
        <ResolutionHeader data-testid="resolution-header" onChange={handleChange} {...data} />
        <StyledSolutionWrapper onClick={() => handleSolutionFocus(true)} data-cy="solution-editor-box">
          <StyledLabel isReadOnly={isReadOnlySolution}>
            {solution}
            <span className="required"> *</span>
          </StyledLabel>
          <RichTextEditor
            sourceId={srId}
            ref={solutionTextfieldRef}
            fieldName="solution-editor"
            dataTestId="solution-editor"
            isReadOnly={isReadOnlySolution}
            editorType={editorActionTypes.RESOLUTION}
            editorText={data[editorActionSettings.solutionTextKey]}
            attachmentsList={solutionAttachmentsList}
            placeholder={texts[editorActionSettings.solutionPlaceholder]}
            CustomFooter={!(isReadOnlySolution || !showSolution) && ShareWithRequestUserSwitch}
            onChange={(editorText) => handleChange(editorActionSettings.solutionTextKey, editorText)}
            handleClickAway={() => handleSolutionFocus(false)}
            onChangeActiveUploads={handleChangeSolutionActiveUploads}
            activeUploads={solutionActiveUploads}
            onFileAttach={handleSolutionFileAttach}
            onImageUpload={handleSolutionImageUpload}
            onChangeUploadImageProgress={setIsUploadInProgress}
            customDefaultUI={
              <DefaultRichTextPlaceholder
                className="default-solution"
                data-testid="default-solution"
                data-cy="default-solution"
                onClick={() => handleSolutionFocus(true)}
              >
                {texts[editorActionSettings.placeholder]}
              </DefaultRichTextPlaceholder>
            }
            shouldRenderDefaultUI={!showSolution}
            {...commonProps}
          />
          {(isReadOnlySolution || !showSolution) && ShareWithRequestUserSwitch}
        </StyledSolutionWrapper>

        {!showResolution && (
          <StyledAddResolution
            onClick={() => setIsResolutionVisible(true)}
            data-testid="add-resolution-button"
            data-cy="add-resolution-button"
          >
            <span className="plus">+</span> {addResolution}
          </StyledAddResolution>
        )}

        {showResolution && (
          <StyledResolutionWrapper
            onClick={() => {
              setIsResolutionFocused(true);
            }}
            showResolution={showResolution}
          >
            <StyledLabel isReadOnly={isReadOnlyResolution} data-testid="add-resolution-button">
              {resolution}
            </StyledLabel>

            <RichTextEditor
              dataTestId="resolution-editor"
              ref={resolutionTextfieldRef}
              editorType={editorActionTypes.RESOLUTION}
              sourceId={srId}
              isReadOnly={isReadOnlyResolution}
              editorText={data[editorActionSettings.resolutionTextKey]}
              attachmentsList={resolutionAttachmentsList}
              placeholder={texts[editorActionSettings.resolutionPlaceholder]}
              onChange={(editorText) => handleChange(editorActionSettings.resolutionTextKey, editorText)}
              handleClickAway={() => handleResolutionFocus(false)}
              onChangeActiveUploads={handleChangeResolutionActiveUploads}
              activeUploads={resolutionActiveUploads}
              onFileAttach={handleResolutionFileAttach}
              onImageUpload={handleResolutionImageUpload}
              customDefaultUI={
                <DefaultRichTextPlaceholder
                  className="default-solution"
                  data-testid="default-solution"
                  data-cy="default-solution"
                  onClick={() => handleResolutionFocus(true)}
                >
                  {texts[editorActionSettings.placeholder]}
                </DefaultRichTextPlaceholder>
              }
              shouldRenderDefaultUI={!isResolutionFocused}
              onChangeUploadImageProgress={setIsUploadInProgress}
              {...commonProps}
            />
          </StyledResolutionWrapper>
        )}
      </StyledSolutionResolutionWrapper>
      <ActionLineFooter customSaveText={resolve} />
      <StyledEndSpace />
    </>
  );
};

export default SolutionResolution;
