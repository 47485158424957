import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    getTextByKey: (key) => texts?.[key],
    changedText: texts?.['spaces.resolutionPanel.journey.changed'],
  };

  return { getTextByKey: componentTexts.getTextByKey, ...componentTexts };
}
