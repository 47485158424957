import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledSuggestedCategory = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'style',
})(({ theme, style }) => ({
  ...style, // This is an anti-pattern, but I'm displaying this inside a virtual scroll container which needs to apply style directly
  fontFamily: theme.fonts.robotoRegular,
  fontSize: theme.fontSize.small,
  '.header': {
    color: theme.palette.text.title_txt,
    fontFamily: theme.fonts.robotoBold,
    marginBottom: '0.25rem',
    display: 'flex',
    alignItems: 'center',

    '& .stars-icon': {
      marginRight: '0.5rem',
    },
  },
  '.description': {
    color: theme.palette.text.secondary_txt,
    lineHeight: '1rem',
  },
  '.suggested-category-container': {
    marginTop: '0.75rem',
  },
  '.offset-container': {
    marginLeft: '1rem',
    display: 'flex',
    '&.third-level': {
      marginLeft: '2.5rem',
    },
    '& .categoryItem': {
      marginTop: '1.375rem',
    },

    '& .child-relation': {
      flexShrink: 0,
    },
  },
  '&& .MuiPaper-root': {
    backgroundColor: theme.palette.background.bg_suggested_category,
    padding: 0,
    width: '204px',
    boxShadow: 'none',
    borderRadius: '6px',
  },
  '&& .MuiCardContent-root': {
    padding: '0.5rem 0.5rem',
    paddingBottom: '0.75rem', // This is split to overwrite a different style, do not put it in a shorthand
  },
}));
