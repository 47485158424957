import { ReactComponent as PhoneIcon } from 'images/icons/call.svg';
import { ReactComponent as ClockIcon } from 'images/icons/time.svg';
import { ReactComponent as LaptopIcon } from 'images/icons/laptop.svg';
import PropTypes from 'prop-types';
import CopyButton from 'common/components/copyButton';
import StyledUserInfoCardDetails from './styles';
import UserInfoCardDetail from './userInfoCardDetail';
import useTexts from '../useText';

const componentId = 'user-info-card-user-details';

const UserInfoCardDetails = ({ jobTitle, phoneNumber, localTime }) => {
  const { localTimeText, mobileText } = useTexts();

  return (
    <StyledUserInfoCardDetails disablePadding id={componentId}>
      {jobTitle && (
        <UserInfoCardDetail
          id="job-title"
          Icon={LaptopIcon}
          text={jobTitle}
        />
      )}
      {phoneNumber && (
        <UserInfoCardDetail
          id="phone-number"
          Icon={PhoneIcon}
          text={
            <span>
              <a className="phone-number-ref" href={`tel:${phoneNumber}`}>{phoneNumber}</a>
              <span> • {mobileText}</span>
            </span>
          }
          secondaryAction={<CopyButton text={phoneNumber} id="phone-number" />}
        />
      )}
      {localTime && <UserInfoCardDetail id="local-time" Icon={ClockIcon} text={`${localTimeText} - ${localTime}`} />}
    </StyledUserInfoCardDetails>
  );
};
export default UserInfoCardDetails;

UserInfoCardDetails.propTypes = {
  phoneNumber: PropTypes.string,
  localTime: PropTypes.string,
};
