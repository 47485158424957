import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledSummaryPopup = styled(Box)(({ theme }) => (
  {
    color: theme.palette.text.title_txt,
    fontFamily: theme.fonts.robotoRegular,

  '.header-container': {
    display: 'flex',
    color: theme.palette.text.txt_default,
    fontSize: theme.fontSize.main,
    alignItems: 'center',
    gap: '10px',
  },
  '.header-icon': {
    marginRight: '0.75rem',
    minWidth: '2.125rem',
  },
  '.content': {
    fontSize: theme.fontSize.main,
    margin: '0.75rem 0',
    display: 'flex',
    lineHeight: '1.25rem',
    minHeight: '1.5rem',
    alignItems: 'left',
    flexDirection: 'column',
    '&.loading': {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'left',
    },
    '.loading-stars-icon': {
      marginRight: '0.375rem',
    },
  },
  '.beta-chip': {
    marginLeft: '1.125rem',
  },
  '.feedback-controls-container': {
    color: theme.palette.text.secondary_txt,
    display: 'flex',
    gap: '0.25rem',
    fontStyle: 'italic',
    fontSize: theme.fontSize.small,
    alignItems: 'center',

    '& .thumbs-up-button': {
      marginLeft: 'auto',
    },

    '& .footer-text': {
      flexGrow: 1,
    },
  },
  '.sentiment-container': {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0.25rem',
    '.sentiment-label': {
      marginRight: '0.25rem',
      fontSize: theme.fontSize.small,
    },
  },
}));
