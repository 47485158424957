import { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const DataFiltersContext = createContext({});

export const DataFiltersProvider = (props) => {
  const { children, isSupportDefault, handleSetDefault, isAllMessagesExpanded, handleToggleExpandAllMessages } = props;
  const value = useMemo(
    () => ({ isSupportDefault, handleSetDefault, isAllMessagesExpanded, handleToggleExpandAllMessages }),
    [isSupportDefault, handleSetDefault, isAllMessagesExpanded, handleToggleExpandAllMessages],
  );

  return <DataFiltersContext.Provider value={value}>{children}</DataFiltersContext.Provider>
}

export const useJourneyDataFiltersContext = () => useContext(DataFiltersContext);

DataFiltersProvider.propTypes = {
  children: PropTypes.node,
  isSupportDefault: PropTypes.bool,
  handleSetDefault: PropTypes.func,
  isAllMessagesExpanded: PropTypes.bool,
  handleToggleExpandAllMessages: PropTypes.func,
}
