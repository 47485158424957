import { memo } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import * as FieldIcons from '../../../images/icons/fieldIcons';
import { StyledTemplateFieldItem } from './StyledTemplateFieldItem';
import { ReactComponent as DragIcon } from '../../../images/icons/gh_dragg.svg';
import { ReactComponent as CheckIcon } from '../../../images/icons/icon_checked.svg';
import { fieldsTypes } from '../../../common/utils/fieldsTypes';

const TemplateFieldItem = (props) => {
  const FIELD_STATES = {
    DEFAULT: 'default',
    IN_USE: 'inUse',
  };
  const { id, fieldType, fieldName, isFieldInUse, isDragging } = props;
  const fieldState = isFieldInUse ? FIELD_STATES.IN_USE : FIELD_STATES.DEFAULT;
  const iconName = fieldsTypes[fieldType];
  const Icon = FieldIcons[iconName];
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const dragIconClassName = fieldState === FIELD_STATES.IN_USE ? 'drag-icon inUse' : 'drag-icon';

  return (
    <StyledTemplateFieldItem className={fieldState}>
      {Icon && (
        <ListItemIcon className="field-item-icon">
          <Icon data-testid="field-icon" />
        </ListItemIcon>
      )}
      {!isDragging ? (
        <ListItemButton
          className="field-item-content"
          disableTouchRipple
          data-testid={`item-content-${id}`}
          style={style}
          ref={setNodeRef}
          disabled={fieldState === FIELD_STATES.IN_USE}
          {...listeners}
          {...attributes}
        >
          <DragIcon className={`${dragIconClassName}`} />
          <ListItemText primary={fieldName} data-testid="field-name" />
          {fieldState === FIELD_STATES.IN_USE && <CheckIcon className="check-icon" data-testid="inuse-icon" />}
        </ListItemButton>
      ) : (
        <div className="field-placeholder" />
      )}
    </StyledTemplateFieldItem>
  );
};

export default memo(TemplateFieldItem);
