const changeImagePreviewConfigs = (editor) => {
  const selectedImg = editor.image.get();
  const editorImages = editor.$el.find('img');
  const filesData = [];
  let selectedFileIndex = 0;
  let attachmentId = -1;
  for (const [key, image] of Object.entries(editorImages)) {
    if (image.src) {
      if (image.dataset?.attachmentid) {
        attachmentId = parseInt(image.dataset.attachmentid, 10);
      } else {
        const regexSplitAttachmentId = /\/attachments\/(\d+)\//;
        attachmentId = parseInt(image.src.match(regexSplitAttachmentId)[1], 10);
      }

      filesData.push({
        key,
        src: image.src,
        fileName: '',
        extension: '',
        removeParams: { img: image },
        attachmentId,
      });
    }
    if (selectedImg.attr('src') === image.src) {
      selectedFileIndex = parseInt(key, 10);
    }
  }

  return { open: true, selectedImageSrc: selectedImg.attr('src'), selectedFileIndex, filesData };
};

export { changeImagePreviewConfigs };
