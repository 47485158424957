import { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { useUserInfo } from 'remote-state/userServiceHooks';
import Toaster from 'common/components/toaster';
import ErrorLogs from 'common/components/toaster/errorLogs';
import { ReactComponent as CloseButton } from 'images/icons/bulkActions/close.svg';
import { QUERIES_KEYS } from 'constant';

import useTexts from './useTexts';
import {
  StyledActionBarWrapper,
  StyledActionBar,
  StyledActionTitle,
  StyledAction,
  StyledActionLimit,
  StyledActions,
} from './style';
import AssigneeAction from './actions/assignee';
import DeleteAction from './actions/delete';
import ArchiveAction from './actions/archive';
import { useSrArchivingEnabled } from '../../../../remote-state/accountSettingsServiceHooks';

const ActionBar = ({ selectedRows, onSetIsCacheRefreshNeeded, onUncheckRows, ticketsPerPage, isArchiveView }) => {
  const [actionType, setActionType] = useState();
  const [assigneeValue, setAssigneeValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [toasterMessage, setToasterMessage] = useState({ message: null, type: 'success' });

  const {
    data: { userPermissionBulkActions, canDeleteServiceRecordsAssignedTo, canModifyServiceRecordsAssignedTo },
  } = useUserInfo(QUERIES_KEYS.CURRENT_USER_PERMISSIONS);
  const isArchivingEnabled = useSrArchivingEnabled();

  const { recordsSelectedText, loadingTicketMessageText } = useTexts(selectedRows.length);

  const allowUpdate = userPermissionBulkActions?.includes('UPDATE') && canModifyServiceRecordsAssignedTo;
  const allowDelete = userPermissionBulkActions?.includes('DELETE') && canDeleteServiceRecordsAssignedTo;
  const allowArchive = isArchivingEnabled;

  const handleUncheckSuccessTickets = (tickets) => {
    selectedRows.forEach((row) => {
      const successTicket = tickets.find((ticket) => ticket.id === row.id && ticket.updateTicketResponse.success);
      if (successTicket) onUncheckRows([{ ...row }]);
    });
  };

  const handleActionClicked = ({ actionType, onClick = () => {} }) => {
    if (toasterMessage.message) {
      setToasterMessage({
        message: null,
      });
    }
    setActionType(actionType);
    onClick();
  };

  const actions = [
    {
      key: 'assignee',
      enabled: allowUpdate,
      Component: AssigneeAction,
      props: { onChangeAssigneeValue: setAssigneeValue },
    },
    {
      key: 'delete',
      enabled: allowDelete,
      Component: DeleteAction,
    },
    {
      key: 'archive',
      enabled: allowArchive,
      Component: ArchiveAction,
      props: { isArchiveView },
    },
  ];

  return (
    <StyledActionBarWrapper>
      {selectedRows.length > 0 && (
        <StyledActionBar data-testid="bulk-actions-wrapper">
          <StyledAction data-testid="bulk-selected-rows">
            <StyledActionLimit>
              {selectedRows.length}/{ticketsPerPage}
            </StyledActionLimit>
            <StyledActionTitle>{recordsSelectedText}</StyledActionTitle>
          </StyledAction>
          <StyledActions>
            {actions
              .filter((action) => action.enabled)
              .map(({ key, Component, props = {} }) => (
                <Component
                  key={key}
                  selectedRows={selectedRows}
                  onLoading={setIsLoading}
                  onActionClicked={handleActionClicked}
                  onUncheckSuccessTickets={handleUncheckSuccessTickets}
                  onSetIsCacheRefreshNeeded={onSetIsCacheRefreshNeeded}
                  onChangeToasterMessage={setToasterMessage}
                  dataset={{
                    'data-testid': `bulk-action-${key}`,
                  }}
                  {...props}
                />
              ))}
          </StyledActions>

          <StyledAction onClick={onUncheckRows} isCursorPointer data-testid="bulk-close-button">
            <CloseButton />
          </StyledAction>
        </StyledActionBar>
      )}
      {isLoading && (
        <Toaster message={loadingTicketMessageText({ actionType, assignee: assigneeValue })} type="loading" />
      )}
      <Toaster
        {...toasterMessage}
        errorDetails={
          toasterMessage?.errorDetails?.length ? <ErrorLogs failedTickets={toasterMessage.errorDetails} /> : null
        }
        onClose={setToasterMessage}
      />
    </StyledActionBarWrapper>
  );
};

ActionBar.propTypes = {
  selectedRows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  isArchiveView: PropTypes.bool,
  onSetIsCacheRefreshNeeded: PropTypes.func,
  onUncheckRows: PropTypes.func,
};

ActionBar.defaultProps = {
  selectedRows: [],
  isArchiveView: false,
  onSetIsCacheRefreshNeeded: () => {},
  onUncheckRows: () => {},
};

export default memo(ActionBar);
