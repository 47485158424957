import { styled } from '@mui/material/styles';
import { MenuItem, Box } from '@mui/material';
import { BasicAvatar } from 'common/components/avatars/basicAvatar';

export const StyledSenderInput = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})(({ theme, isOpen }) => {
  const openStyles = {
    backgroundColor: theme.palette.background.bg_hover_main,
    borderRadius: '.25rem',
  };
  return {
    minHeight: '28px',
    display: 'flex',
    alignItems: 'center',
    '&': {
      display: 'flex',
      gap: '.5rem .75rem',
      '&& .MuiInputBase-root': {
        ...(isOpen && openStyles),
        '&:hover': openStyles,
      },
      '&& .MuiSelect-icon': {
        right: '.58rem',
        transform: 'none',
        webkitTransform: 'none',
      },
      '& .MuiPaper-root': {
        top: '15.25rem',
      },
    },
    '& .MuiChip-root': {
      margin: '2px 0',
      backgroundColor: theme.palette.grey.bt_alt_grey_light,
      padding: '2px 8px 2px 0',
      height: '24px',
      gap: '.25rem',
      display: 'flex',
      '& .MuiAvatar-root': {
        marginLeft: '2px',
      },
      '& .MuiChip-label': {
        fontSize: '14px',
        lineHeight: '20px',
        paddingLeft: '.375rem',
        paddingRight: '0rem',
        height: '1.25rem',
        color: theme.palette.text.txt_default,
      },
    },
  };
});

export const StyledMenuItem = styled(MenuItem)(() => ({
  '&': {
    maxWidth: '18.75rem',
  },
}));

export const StyledMenuDetails = styled(Box)(({ theme }) => ({
  '&': {
    paddingLeft: '.375rem',
    paddingRight: '0rem',
    overflow: 'hidden',
    '> div': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& .name': {
      fontSize: '14px',
      lineHeight: '20px',
      color: theme.palette.text.title_txt,
    },
    '& .email': {
      color: theme.palette.text.secondary_txt,
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
}));

export const StyledBasicAvatar = styled(BasicAvatar)(({ theme }) => ({
  '&&': {
    color: 'white',
    fontSize: '.6875rem',
    letterSpacing: '1px',
    backgroundColor: theme.palette.blueLabels.bt_blue_chip_light,
  },
}));
