import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dirtyFieldsList: {},
  opa: false,
};

export const dirtyFieldsSlice = createSlice({
  name: 'dirtyFields',
  initialState,
  reducers: {
    updateIsDirtyStatus: (state, action) => {
      state.opa = true;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { srId, fieldName, value } = action.payload;

      state.dirtyFieldsList[srId] = state.dirtyFieldsList[srId] || {};
      state.dirtyFieldsList[srId][fieldName] = state.dirtyFieldsList[srId][fieldName] || {};
      state.dirtyFieldsList[srId][fieldName].value = value;
      state.dirtyFieldsList[srId][fieldName].isChangedManually = true;
    },
    resetIsDirtyStatus: (state) => {
      state.dirtyFieldsList = {};
    },
  },
});

// Selectors
export const selectFormData = (allSlicesState) => allSlicesState.dirtyFields?.dirtyFieldsList;

export const { updateIsDirtyStatus, resetIsDirtyStatus } = dirtyFieldsSlice.actions;
export default dirtyFieldsSlice.reducer;
export const dirtyFieldSliceName = dirtyFieldsSlice.name;
