import { useEffect, useState } from 'react';
import { useRouter } from '@tanstack/react-router';
import { useDeleteTemplate } from 'remote-state/templateHooks';
import { useDispatch, useSelector } from 'react-redux';
import { setToasterMessage } from 'store/globalSlice';
import { setPageValidations } from 'store/pageValidationSlice';
import { selectActiveUser } from 'store/userSlice';
import MessagePrompt from 'common/components/messagePrompt';
import usePendoTrackEvents from 'common/utils/hooks/usePendoTrackEvents';
import { PAGE_VALIDATION_CONSTANTS, PENDO_TRACK_EVENTS } from 'constants/index';
import useTexts from '../useTexts';
import { StyledTemplateButton } from '../StyledTemplateButton';
import { ReactComponent as TrashIcon } from '../../../images/icons/trash.svg';
import { TEMPLATES_MENU_ITEM_ID, TEMPLATES_SUB_MENU_ITEM_ID, TEMPLATES_SUB_MENU_LIST_ID } from '../../settings/constants';

export default function TemplateDeleteButton({ templateId, handleOpenMenu }) {
  const router = useRouter();
  const [showDeleteTemplatePrompt, setShowDeleteTemplatePrompt] = useState(false);
  const dispatch = useDispatch();
  const userAccount = useSelector(selectActiveUser);
  const { deleteTemplateText, deleteSuccessText, deleteErrorText, deletePromptMessageText, deleteAction } = useTexts();
  const { mutate: deleteTemplate, status: deleteStatus } = useDeleteTemplate(templateId);
  const pendoTrackEvents = usePendoTrackEvents();

  const handleDelete = (e) => {
    pendoTrackEvents(PENDO_TRACK_EVENTS.DELETE_TEMPLATE_WAS_CLICKED, {
      updateUser: userAccount?.username,
      templateId,
    });
    e.stopPropagation();
    deleteTemplate({ updateUser: userAccount?.username, templateId });
  };

  useEffect(() => {
    if (deleteStatus === 'success' || deleteStatus === 'error') {
      setShowDeleteTemplatePrompt(false);
      handleOpenMenu(false);
      if (deleteStatus === 'success') {
        dispatch(
          setPageValidations({
            pageKey: '/spaces/template',
            component: `${PAGE_VALIDATION_CONSTANTS.TEMPLATE}`,
            validationDetails: {
              isValid: true,
            },
          }),
        );
        dispatch(setToasterMessage({ message: deleteSuccessText }));
        if (router.latestLocation.href !== '/spaces/templates-list') {
            const settingIds = { menuItemId: TEMPLATES_MENU_ITEM_ID, subMenuListId: TEMPLATES_SUB_MENU_LIST_ID, subMenuItemId: TEMPLATES_SUB_MENU_ITEM_ID };
            const path = `/spaces/templates-list?settingIds=${encodeURIComponent(JSON.stringify(settingIds))}`;
            router.navigate({ to: path });
        }
      } else {
        dispatch(setToasterMessage({ message: deleteErrorText, type: 'error' }));
      }
    }
  }, [deleteStatus, deleteSuccessText, deleteErrorText, handleOpenMenu, router, dispatch]);
  return (
    <>
      <StyledTemplateButton
        startIcon={<TrashIcon />}
        shouldhover={false}
        onClick={(e) => {
          e.stopPropagation();
          setShowDeleteTemplatePrompt(true);
        }}
        fullWidth
      >
        {deleteTemplateText}
      </StyledTemplateButton>
      {showDeleteTemplatePrompt && (
        <MessagePrompt
          open
          showCancelBtn
          title={deleteTemplateText}
          btnOkText={deleteAction}
          onOkClick={handleDelete}
          onClose={() => {
            setShowDeleteTemplatePrompt(false);
          }}
        >
          {deletePromptMessageText}
        </MessagePrompt>
      )}
    </>
  );
}
