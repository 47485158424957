import AttachmentDropzone from 'common/components/attachmentDropzone';
import { FileInputLabel } from 'common/components/uploadFile/uploadButton/fileInputLabel';
import { StyledAttachmentFile, StyledAttachmentFileLabel } from './styles';
import useTexts from './useTexts';

export default function AttachmentFile({
  allowedTypes = [],
  maxFileSize,
  onChangeImageURL,
  onCropComplete,
  onUploadFileError,
}) {
  const texts = useTexts();

  const handleFileChange = (file) => {
    const arr = file?.type?.split('/') || [];
    const fileType = arr[1];

    if (!allowedTypes.includes(fileType)) {
      return onUploadFileError(413);
    }

    if (file?.size > maxFileSize) {
      return onUploadFileError(415);
    }

    const imageURL = URL.createObjectURL(file);
    onCropComplete(file);
    onChangeImageURL(imageURL);
  };

  const handleUnloadFile = (event) => {
    handleFileChange(event.target.files[0]);
    event.target.value = null;
  };

  return (
    <StyledAttachmentFile>
      <AttachmentDropzone
        displayDrag
        onAttachmentDrop={(files) => handleFileChange(files[0])}
        UploadButton={
          <>
            <FileInputLabel
              sx={{ marginTop: '10px' }}
              allowedTypes={allowedTypes}
              inputBtnComponent={() => <StyledAttachmentFileLabel>{texts.chooseImageText}</StyledAttachmentFileLabel>}
              handleChange={handleUnloadFile}
            />
            {texts.orText}
            <span>{texts.dragImageText}</span>
          </>
        }
      />
    </StyledAttachmentFile>
  );
}
