import { Box, styled } from '@mui/material';

export const TicketBodySelectButton = styled(Box, {
  shouldForwardProp: (prop) =>
    !['hasTextStyles', 'hasValues', 'disabled', 'isNewField', 'isError', 'isEmptyField', 'anchor'].includes(prop),
})(({ theme, hasTextStyles, hasValues, disabled, isNewField, isError, isEmptyField, anchor }) => {
  let textColor = hasTextStyles ? theme?.palette?.text?.txt_default : theme?.palette?.text?.secondary_txt;

  let backgroundColor = 'transparent';
  let hoveredBackground = theme?.palette?.background?.bg_hover_main;

  if (disabled) {
    hoveredBackground = 'transparent';
  }

  if (isNewField && isEmptyField && !disabled) {
    backgroundColor = theme?.palette?.background?.bg_new_input_text;
    hoveredBackground = theme?.palette?.background?.bg_hover_main;
  }

  if (!anchor && ((isError && !isNewField) || (isNewField && isEmptyField && isError))) {
    textColor = theme?.palette?.labels?.bt_red_reg;
    backgroundColor = theme?.palette?.labels?.bt_red_light;
    hoveredBackground = theme?.palette?.labels?.bt_red_mid;
  }

  const hoveredStyles = {
    '&:hover': {
      cursor: !disabled ? 'pointer' : 'default',
      backgroundColor: hoveredBackground,
      color: isError ? theme.palette.labels.bt_yellow_green : textColor,
      borderRadius: theme.shape.border.borderRadius,
      '.warning-icon *': {
        fill: theme?.palette?.labels?.bt_red_dark,
      },
    },
  };
  const expandedStyles = {
    backgroundColor: hoveredBackground,
    color: textColor,
    'span[data-error-tooltip=true],.warning-icon': {
      display: 'none',
    },
  };

  return {
    fontSize: theme.fontSize.main,
    position: 'relative',
    backgroundColor,
    height: theme.spacing(3.5),
    padding: theme.spacing(1),
    color: !hasValues ? theme.palette.text.disable_txt : textColor,
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    transform: `translateX(-${theme.spacing(1)})`,
    borderRadius: theme.shape.border.borderRadius,
    maxWidth: '224px',
    '.warning-icon': {
      position: 'absolute',
      right: '10px',
      top: '6px',
    },
    ...(anchor ? expandedStyles : hoveredStyles),
  };
});
