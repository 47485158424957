import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash-es';

import { resetActiveComponent, selectActiveComponent } from '../activeComponentSlice';

const useClickOutsidePropertiesPanel = (ref) => {
  const dispatch = useDispatch();
  const { componentId } = useSelector(selectActiveComponent);

  const validateDataset = (element) => !isEmpty(element.dataset) && element.dataset.ispropertiesfield;

  const isOutsidePopperMenuClick = (target, parent) =>
    (typeof target?.className === 'string' && (target?.className?.includes('MuiBackdrop-root') || target?.className?.includes('MuiPopover-root'))) ||
    (!document.getElementsByClassName('multi-select-dropdown') &&
      target?.className &&
      typeof target?.className === 'string' &&
      !target?.className?.includes('MuiMenuItem-root')) ||
    (typeof parent?.className === 'string' && parent?.className?.includes('MuiBackdrop-root'));

  const isInsidePopperMenuClick = (target, parent) =>
    (typeof target?.className === 'string' && target?.className?.includes('MuiPaper-root')) ||
    (document.getElementsByClassName('multi-select-dropdown') &&
      target?.className &&
      typeof target?.className === 'string' &&
      target?.className?.includes('MuiMenuItem-root')) ||
    (typeof parent?.className === 'string' && parent?.className?.includes('MuiPaper-root'));

  const onResetComponent = useCallback(
    (target) => {
      let isReset = true;
      let parent = null;
      while (parent !== document.documentElement) {
        if (isOutsidePopperMenuClick(target, parent) || isInsidePopperMenuClick(target, parent)) {
          isReset = false;
          break;
        }
        if (!parent) {
          if (validateDataset(target)) {
            isReset = false;
          }
          parent = target.parentElement;
        } else {
          if (validateDataset(parent)) {
            isReset = false;
          }
          parent = parent?.parentElement;
        }
        if (!isReset) break;
      }
      if (isReset) dispatch(resetActiveComponent());
    },
    [dispatch],
  );

  const handleClickOutside = useCallback(
    (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (componentId) {
          onResetComponent(event.target);
        }
      }
    },
    [componentId, onResetComponent, ref],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);
};

export default useClickOutsidePropertiesPanel;
