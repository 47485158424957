import { Box } from '@mui/material';
import { styled } from '@mui/styles';

export const StyledSearchBar = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'value' && prop !== 'size',
})(({ theme, value, size }) => ({
  display: 'flex',
  width: '100%',

  '&:hover': {
    '& .MuiTextField-root': {
      border: '1px solid #7D899D',
    },
    '& .MuiInput-input': { color: theme.palette.grey.bt_def_grey_reg },
    '& .MuiInputAdornment-root': {
      filter:
        !value &&
        'brightness(0) saturate(100%) invert(84%) sepia(17%) saturate(224%) hue-rotate(178deg) brightness(84%) contrast(83%)',
    },
    '& .MuiInputBase-input::placeholder': {
      color: `${theme.palette.grey.bt_def_grey_reg}`,
    },
  },

  '& .MuiTextField-root': {
    border: `1px solid ${theme.palette.grey.divider_grey_v1}`,
    borderRadius: '100px',
    width: '100%',
  },
  '& .MuiInputBase-root': {
    height: size === 'big' ? '40px' : '32px',
    padding: '8px 6px 8px 12px',
  },
  '& .MuiInput-input': {
    fontSize: '0.875rem',
    lineHeight: '1rem',
    fontFamily: theme.fonts.RobotoRegular,
    color: theme.palette.text.title_txt,
  },
  '& .MuiInputBase-input::placeholder': {
    color: theme.palette.grey.bt_def_grey_light,
    opacity: 1,
  },
  '& .MuiInputBase-input:hover::placeholder': {
    color: `${theme.palette.grey.bt_def_grey_reg}`,
  },

  '& .MuiInputAdornment-root': {
    cursor: value ? 'pointer' : 'inherit',
  },
}));
