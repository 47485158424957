import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTemplateBuilderHeader = styled(Box)(({ theme }) => ({
  margin: '0px 0 12px',
  marginLeft: '22px',
  '.header-back': {
    marginLeft: '-4px',
    display: 'flex',
    alignItems: 'center',
    height: '22px',
  },
  '.header-nav-menu': {
    display: 'flex',
    columnGap: '10px',
    paddingLeft: '4px',

    '& .text': {
      fontFamily: theme?.fonts?.robotoRegular,
      fontSize: theme?.fontSize?.main,
    },
  },
  '.header-back-btn': {
    margin: 0,
    minWidth: 'auto',
  },
  '.header-back-txt': {
    fontSize: theme.fontSize.small,
    marginLeft: '11px',
    color: theme.palette.text.title_txt,
    height: '20px',
    padding: '1px 0px',
  },
  '.template-header-bottom': {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: '1px',
    height: '32px',
    gap: '12px',
  },
  '.template-header-add-workflow': {
    display: 'flex',
    flex: 1,
    justifyContent: 'end',
    alignItems: 'center',
  },
  '.template-header-right': {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'end',
  },
  '.save-btn': {
    borderRadius: '60px',
    color: theme.palette.generic.primary,
    backgroundColor: theme.palette.generic.sysaid,
    padding: '4px 16px',
    height: '32px',
    fontSize: '14px',
    fontFamily: theme.fonts.robotoRegular,
    lineHeight: 1,
    marginRight: '12px',
    textTransform: 'none',
    '&.MuiButtonBase-root': {
      fontWeight: '400 !important',
    },
    ':hover': {
      backgroundColor: theme.palette.generic.sysaid,
    },
    ':disabled': {
      backgroundColor: theme.palette.text.disable_txt,
      cursor: 'default',
    },
  },
  '.save-icon': {
    marginRight: '3px',
    marginLeft: 0,

    '> svg': {
      fontSize: '18px',
    },
  },
}));

export const StyledNavMenuTextButton = styled('button')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  columnGap: '10px',
  border: 'none',
  cursor: 'pointer !important',
  background: 'none',
  alignSelf: 'flex-start',
  textAlign: 'start',
  height: '20px',
  width: 'max-content',
  padding: '0',

  '& .text': {
    fontFamily: theme?.fonts?.robotoRegular,
    fontSize: theme?.fontSize?.main,
    color: theme?.palette?.grey.bt_def_grey_reg,
    lineHeight: '20px',
  },
  '& svg': {
    transition: 'all 100ms ease-out',
    '& path': {
      fill: theme?.palette?.grey?.bt_def_grey_reg,
    },
  },
  '&:hover': {
    textDecoration: `underline solid ${theme?.palette.grey?.bt_def_grey_reg}`,
  },
}));
