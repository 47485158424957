import { axiosInstance } from './axiosConfig';

export function getBulkActionsFields() {
  return axiosInstance.get('bulkActions/fields').then((response) => response.data);
}

export function deleteTicketsByIds(srIds) {
  return axiosInstance.delete('bulkActions', { data: srIds }).then((response) => response.data);
}

export function changeFieldValuesByIds(payload) {
  return axiosInstance.patch('bulkActions', payload).then((response) => response.data);
}

export function archiveTickets({ payload }) {
  return axiosInstance.post('sr/bulkActions/archive', payload).then((response) => response.data);
}

