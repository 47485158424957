import { useEffect, useRef, useState } from 'react';
import { SwitchToggle } from 'common/components/controls/Switch/SwitchToggle';
import { CONSTANTS } from 'features/templateBuilder/constants';
import useTexts from 'features/templateBuilder/useTexts';
import usePreviousValue from 'common/utils/hooks/usePreviousValue';
import { StyledHintTextArea } from './style';
import { StyledPropertyWrapper } from '../StyledTemplatePropertiesPanel';

export default function HintProperty(props) {
  const { switchId, showHint, hintText = '', isSwitchDisabled, onSwitchChange, handleHintChange } = props;
  const [hint, setHint] = useState(hintText);
  const { hintTitle, hintPlaceholder } = useTexts();
  const MAX_HINT_LENGTH = CONSTANTS.MAX_HINT_LENGTH;
  const updateHintTimeout = useRef(null);
  const prevHint = usePreviousValue(hint);
  useEffect(() => {
    setHint(hintText);
  }, [hintText]);
  // TODO: @Alexg - After refactor of saving data to DB, remove timeout.
  useEffect(() => {
    if (updateHintTimeout.current) {
      clearTimeout(updateHintTimeout.current);
    }
    if (hint !== prevHint && hint !== hintText) {
      updateHintTimeout.current = setTimeout(() => {
        handleHintChange(hint);
      }, 1000);
    }
    return () => clearTimeout(updateHintTimeout.current);
  }, [hint, prevHint, hintText, handleHintChange]);

  const handleInputChange = (event) => {
    setHint(event.target.value);
  };

  const handleSwitchChange = (event) => {
    onSwitchChange(event);
  };

  return (
    <StyledPropertyWrapper data-testid="hint">
      <SwitchToggle
        handleSwitchChange={handleSwitchChange}
        isChecked={showHint}
        switchText={hintTitle}
        isTemplateBuilder
        isDisabled={isSwitchDisabled}
        switchId={switchId}
        classNames={{ labelClass: 'template-switch-toggle-label' }}
      />
      {showHint && (
        <StyledHintTextArea
          multiline
          fullWidth
          placeholder={hintPlaceholder}
          value={hint || ''}
          inputProps={{ maxLength: MAX_HINT_LENGTH }}
          helperText={`${(hint || '').length}/${MAX_HINT_LENGTH}`}
          onChange={handleInputChange}
        />
      )}
    </StyledPropertyWrapper>
  );
}
