import { ReactComponent as ClockIcon } from 'images/icons/clock-green.svg';
import { StyledPreviewPlaceholder } from './StyledPreviewPlaceholder';
import useTexts from './useTexts';

export default function PreviewPlaceholder() {
    const { resolutionPanelPreviewTitle, resolutionPanelPreviewText } = useTexts();
    return (
      <StyledPreviewPlaceholder data-testid="journey-preview">
        <ClockIcon />
        <div className="journey-preview-title">{resolutionPanelPreviewTitle}</div>
        <div className="journey-preview-text">{resolutionPanelPreviewText}</div>
      </StyledPreviewPlaceholder>
    );
}
