import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();

  const componentTexts = {
    linkNamePlaceholder: texts?.['spaces.templateBuilder.linkNamePlaceholder'],
    URLPlaceholder: texts?.['spaces.templateBuilder.URLPlaceholder'],
    addLink: texts?.['spaces.templateBuilder.addLink'],
    clearAll: texts?.['spaces.templateBuilder.clearAll'],
  };

  return componentTexts;
}
