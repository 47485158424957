import separatorArrow from 'images/icons/values-seperator-arrow.svg';
import { ReactComponent as CheckmarkIcon } from 'images/icons/checkmark-icon-thick.svg';
import { StatusIndicator } from 'common/components/sysaidLibrary/Indicator';
import useTexts from 'features/resolutionPanel/middlePanel/Workflows/hooks/useTexts';

import { StyledArrowDivider } from '../../fieldChange/style';
import { StyledActionItemStatusChange, StyledStatus } from './style';
import { actionEnums } from '../../../constants';

const indicatorCustomStyles = {
  indicator: {
    padding: '1px',
  },
  svg: {
    height: '14px',
    width: '17px',
  },
};

export default function ActionItemStatusChange({ logType }) {
  const { enableStatusText, completeText } = useTexts();
  const isStatusComplete = logType === actionEnums.ACTION_ITEM_STATUS_COMPLETED;
  const [oldStatus, newStatus] = isStatusComplete ? [enableStatusText, completeText] : [completeText, enableStatusText];

  return (
    <StyledActionItemStatusChange>
      <StyledStatus>
        <StatusIndicator completed={!isStatusComplete} icon={<CheckmarkIcon />} customStyles={indicatorCustomStyles} />
        {oldStatus}
      </StyledStatus>
      <StyledArrowDivider alt="change-arrow" src={separatorArrow} />
      <StyledStatus>
        <StatusIndicator completed={isStatusComplete} icon={<CheckmarkIcon />} customStyles={indicatorCustomStyles} />
        {newStatus}
      </StyledStatus>
    </StyledActionItemStatusChange>
  );
}
