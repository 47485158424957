import { useState } from 'react';
import { TextField } from 'common/components/sysaidLibrary/textField';
import { StyledWrapper } from './style';

export default function InputWithCounter(props) {
  const { inputKey, label, controller, max } = props;
  const [isFieldFocused, setIsFieldFocused] = useState(true);
  const shouldShowCount = isFieldFocused && !controller.getFieldState(inputKey).invalid;
  const currentValue = controller.watch(inputKey);
  const onInputBlur = () => {
    setIsFieldFocused(false);
    controller.trigger(inputKey);
  };
  return (
    <StyledWrapper>
      <TextField
        autoFocus
        digitsLimit={max}
        onChange={() => {
          controller.trigger(inputKey);
        }}
        onBlur={onInputBlur}
        onFocus={() => setIsFieldFocused(true)}
        label={label}
        name={inputKey}
        control={controller.control}
        inputIcon={shouldShowCount && `${currentValue.length}/${max}`}
      />
    </StyledWrapper>
  );
}
