import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();

  const componentTexts = {
    notSavedTitle: texts?.['spaces.changes.fieldsNotSavedModal.title'],
    forbiddenErrorTitle: texts?.['spaces.changes.forbiddenErrorTitle.title'],
    notSavedModalMessage: texts?.['spaces.changes.fieldsNotSavedModal.description'],
    notSavedByPermissionMessage: texts?.['spaces.changes.fieldsNotSavedModal.noPermission'],
    notSavedByForbiddenMessage: texts?.['spaces.changes.statusNotSavedModal.forbiddenMessage'],
    notSavedByRequiredFieldMessage: texts?.['spaces.changes.fieldsNotSavedModal.requiredField'],
    notSavedByRequiredFieldTitle: texts?.['spaces.changes.fieldsNotSavedModal.requiredFields.title'],
    forbiddenSaveOkBtnText: texts?.['spaces.changes.statusNotSavedModal.okBtnText'],
  };

  return componentTexts;
}
