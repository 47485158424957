import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledInheritanceInfoPanel = styled(Box)(({ theme }) => ({
  display: 'flex',
  lineHeight: '16px',
  maxWidth: 'fit-content',
  padding: '4px 12px',
  gap: '6px',
  fontFamily: 'Roboto-Regular',
  fontSize: '12px',
  color: theme?.palette?.text?.txt_default,
  marginTop: '22px',
  background: theme?.palette?.grey?.bt_alt_grey_light,

  '& svg': {
    flexShrink: 0,
  },
}));
