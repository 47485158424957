import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledPreviewPlaceholder = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '70%',
  '.journey-preview-title': {
    marginTop: '16px',
    fontSize: theme.fontSize.medium,
    color: theme.palette.text.txt_default,
    lineHeight: '24px',
  },
  '.journey-preview-text': {
    fontSize: theme.fontSize.main,
    color: theme.palette.grey.bt_def_grey_reg,
    lineHeight: '24px',
  },
}));
