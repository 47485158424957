import { useCallback, useEffect, useMemo, useState, useRef, memo, useContext } from 'react';
import {
  DndContext,
  DragOverlay,
  useSensors,
  useSensor,
  PointerSensor,
  MeasuringStrategy,
  pointerWithin,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import MessagePrompt from 'common/components/messagePrompt';
import { useLazyLoadFieldsList } from 'remote-state/templateHooks';
import { useRbTexts } from 'remote-state/applicationHooks';
import { PENDO_TRACK_EVENTS } from 'constants/pendoTrackEvents';
import usePendoTrackEvents from 'common/utils/hooks/usePendoTrackEvents';
import useDebounceSearch from 'common/utils/hooks/useDebounceSearch';
import useClickOutsidePropertiesPanel from './useClickOutsidePropetiesPanel';
import { StyledTemplateBuilderPanel } from './StyledTemplateBuilderPanel';
import TemplateFieldsPanel from '../TemplateFieldsPanel';
import TemplateForm from '../TemplateForm';
import DragOverlayField from '../DragOverlayField';
import DragOverlaySection from '../DragOverlaySection';
import useTexts from '../useTexts';
import { CONSTANTS } from '../constants';
import TemplatePropertiesPanel from '../TemplatePropertiesPanel';
import useColumnTexts from '../../queue/grid/customColumnHeader/useTexts';
import { TemplateBuilderContext } from './context';

const TemplatesRightPanel = memo(() => {
  const { sections, header, handleStateUpdate, srType } = useContext(TemplateBuilderContext);
  const propertiesPanelRef = useRef(null);
  useClickOutsidePropertiesPanel(propertiesPanelRef);

  return (
    <div className="template-right" ref={propertiesPanelRef}>
      <TemplatePropertiesPanel
        header={header}
        sections={sections}
        updateProperties={handleStateUpdate}
        srType={srType}
      />
    </div>
  );
});

function TemplateBuilderPanel() {
  const texts = useRbTexts();
  const { templateId, setDragging, sections, sectionsOrder, handleStateUpdate, fieldsMap } =
    useContext(TemplateBuilderContext);
  const { maxFieldsLimitTitle, maxFieldsLimitText, placeholderFieldText } = useTexts();
  const { getColumnText } = useColumnTexts();

  const [availableFieldsItems, setAvailableFieldsItems] = useState([]);
  //TODO: Use one state instead with enum. Would be easier and proper approach. After use switch instead of if else statements to determine which kind of items are dragged . Also makes easier adding other draggable items to be implemented in a future.
  const [draggableField, setDraggableField] = useState();
  const [draggableSection, setDraggableSection] = useState();
  const [fieldPlaceholderIndex, setFieldPlaceholderIndex] = useState();
  const [droppableSectionIndex, setDroppableSectionIndex] = useState(null);
  const [prevSectionIndex, setPrevSectionIndex] = useState(null);
  const [showFieldsErrorModal, setShowFieldsErrorModal] = useState(false);
  const [searchValue, setSearchValue] = useState();

  const onSearchChange = useCallback((e) => setSearchValue(e.target.value || ''), []);
  const onSearchClear = useCallback(() => setSearchValue(''), []);

  const debouncedValue = useDebounceSearch(searchValue, 300);

  const { data, hasNextPage, fetchNextPage, isSuccess, isFetching } = useLazyLoadFieldsList(debouncedValue);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 1,
        tolerance: 0,
      },
    }),
  );

  const pendoTrackEvents = usePendoTrackEvents();

  useEffect(() => {
    if (fieldsMap && isSuccess && !isFetching) {
      setAvailableFieldsItems(() => {
        const availableFields = data?.pages?.flatMap((page) => page.currentList);
        return availableFields.map((field) => {
          const displayName = getColumnText(field?.displayName) || field?.displayName;
          if (fieldsMap.has(field?.id)) {
            return { ...field, displayName, inUse: true };
          }
          return { ...field, displayName, inUse: false };
        });
      });
    }
  }, [fieldsMap, data?.pages, isSuccess, isFetching, getColumnText]);

  const updateAvailableFieldsDefaultValue = useCallback(
    (fieldName, fieldValue) => {
      setAvailableFieldsItems((prev) =>
        prev.map((field) => {
          if (field.fieldName === fieldName) return { ...field, defaultValue: fieldValue };
          return field;
        }),
      );
    },
    [setAvailableFieldsItems],
  );

  const getDraggableSection = useCallback(
    (id) => {
      const sectionId = id.split(`${CONSTANTS.SECTION_ID_PREFIX}-`)[1];
      const draggableSectionIndex = sections.findIndex((section) => String(section.id) === sectionId);
      const draggableSection = { ...sections[draggableSectionIndex], order: draggableSectionIndex };
      setDraggableSection(draggableSection);
    },
    [sections],
  );

  const getDraggableAvailableField = useCallback(
    (id) => {
      const draggableId = id.split('-')[1];
      setDragging(draggableId);
      const draggableFieldIndex = availableFieldsItems.findIndex((field) => String(field.id) === draggableId);
      const fieldData = availableFieldsItems[draggableFieldIndex];
      const stretchedFieldsTypes = {
        longtext: 2,
        multiselect: 7,
        link: 9,
        specialLink: 12,
      };
      const isPositionStretch = Object.values(stretchedFieldsTypes).includes(fieldData.ticketTemplateFieldType.id);
      setDraggableField({
        fieldId: fieldData.id,
        fieldName: fieldData.fieldName,
        displayName: fieldData.displayName,
        defaultValue: fieldData.defaultValue || null,
        readOnly: fieldData.alwaysReadOnly || false,
        customColumn: fieldData.customColumn,
        hideFieldCaption: false,
        required: false,
        hint: false,
        hintText: null,
        limitedPermissions: false,
        requiredStatuses: [],
        permissions: [],
        headerField: false,
        templateFieldAttributes: fieldData,
        availableFieldIndex: draggableFieldIndex,
        position: isPositionStretch ? 'STRETCH' : '',
      });
    },
    [availableFieldsItems, setDragging],
  );

  const getDraggableSectionField = useCallback(
    (event) => {
      const { id } = event.active;
      const sectionId = event.active?.data?.current?.sortable?.containerId.split(`${CONSTANTS.SECTION_ID_PREFIX}-`)[1];
      const sectionIndex = sectionsOrder[sectionId];
      const draggableFieldIndex = sections[sectionIndex].fields.findIndex((field) => String(field.fieldName) === id);
      const draggableFieldData = sections[sectionIndex].fields[draggableFieldIndex];
      const displayName = getColumnText(draggableFieldData.displayName)
        ? draggableFieldData.displayName
        : texts[`spaces.template.fields.${draggableFieldData.fieldName}`];
      setDraggableField({
        ...draggableFieldData,
        displayName,
        availableFieldIndex: null,
        initSectionIndex: sectionIndex,
        initFieldIndex: draggableFieldIndex,
      });
    },
    [getColumnText, sections, sectionsOrder, texts],
  );

  const removePlaceholderField = useCallback(() => {
    const previousFields = sections[prevSectionIndex]?.fields;
    if (Array.isArray(previousFields)) {
      const fieldsWithoutPlaceholder = previousFields.filter(
        (field) => field.templateFieldAttributes.ticketTemplateFieldType.id !== CONSTANTS.PLACEHOLDER_TYPE_ID,
      );
      sections[prevSectionIndex].fields = fieldsWithoutPlaceholder;
      handleStateUpdate([{ sections }]);
      setFieldPlaceholderIndex(undefined);
    }
  }, [handleStateUpdate, prevSectionIndex, sections]);

  const addFieldToSection = useCallback(
    (field, overIndex = fieldPlaceholderIndex, currentSectionIndex = droppableSectionIndex) => {
      if (
        overIndex == null ||
        overIndex === undefined ||
        currentSectionIndex == null ||
        currentSectionIndex === undefined
      ) {
        return;
      }
      if (sections[currentSectionIndex].fields.length === CONSTANTS.MAX_FIELDS_PER_SECTION) {
        setShowFieldsErrorModal(true);
        return;
      }

      const newsections = [...sections];
      if (prevSectionIndex !== null && prevSectionIndex !== currentSectionIndex) removePlaceholderField();
      newsections[currentSectionIndex].fields = newsections[currentSectionIndex].fields.filter(
        (field) => field.templateFieldAttributes.ticketTemplateFieldType.id !== CONSTANTS.PLACEHOLDER_TYPE_ID,
      );
      if (newsections[currentSectionIndex].fields.length === 0) {
        newsections[currentSectionIndex].fields.splice(0, 0, field);
      } else {
        newsections[currentSectionIndex].fields.splice(overIndex, 0, field);
      }
      if (newsections[currentSectionIndex].fields.length <= CONSTANTS.MAX_FIELDS_PER_SECTION) {
        handleStateUpdate([{ sections: newsections }]);
        if (field.customColumn) {
          pendoTrackEvents(PENDO_TRACK_EVENTS.CUSTOM_COLUMN_ADD_TO_TEMPLATE, {
            templateId,
            fieldName: field.fieldName,
          });
        }
      }
      if (prevSectionIndex !== currentSectionIndex) setPrevSectionIndex(currentSectionIndex);
    },
    [
      droppableSectionIndex,
      fieldPlaceholderIndex,
      handleStateUpdate,
      prevSectionIndex,
      sections,
      removePlaceholderField,
      pendoTrackEvents,
      templateId,
    ],
  );

  const removeFieldFromSection = useCallback(
    (currentSectionIndex) => {
      const { fields } = sections[currentSectionIndex];
      const filteredFields = fields.filter((field) => field.fieldId !== draggableField.fieldId);
      sections[currentSectionIndex].fields = filteredFields;
      handleStateUpdate([{ sections }]);
      if (draggableField.customColumn) {
        pendoTrackEvents(PENDO_TRACK_EVENTS.CUSTOM_COLUMN_REMOVE_FROM_TEMPLATE, {
          templateId,
          fieldName: draggableField.fieldName,
        });
      }
    },
    [draggableField, handleStateUpdate, sections, pendoTrackEvents, templateId],
  );

  const updateSectionsOrder = useCallback(
    (overIndex) => {
      let newsections = [...sections];
      let newSectionIndex = overIndex;
      if (overIndex >= newsections.length) {
        newSectionIndex = newsections.length - 1;
      }
      newsections = arrayMove(sections, draggableSection.order, newSectionIndex);
      handleStateUpdate([{ sections: newsections }]);
      setDraggableSection((prev) => ({ ...prev, order: newSectionIndex }));
    },
    [draggableSection?.order, handleStateUpdate, sections],
  );

  const handleFieldDragOver = useCallback(
    (overIndex, overContainer, activeContainer) => {
      const isFieldFromSection = draggableField.availableFieldIndex === null;
      const sectionId = overContainer.split(`${CONSTANTS.SECTION_ID_PREFIX}-`)[1];
      const activeSectionId = activeContainer?.split(`${CONSTANTS.SECTION_ID_PREFIX}-`)[1];
      const currentSectionIndex = sectionId ? sectionsOrder[sectionId] : overIndex;
      if (isFieldFromSection) {
        removeFieldFromSection(activeSectionId ? sectionsOrder[activeSectionId] : currentSectionIndex);
        if (prevSectionIndex !== null && prevSectionIndex !== currentSectionIndex) removePlaceholderField();
      }
      setDroppableSectionIndex(currentSectionIndex);
      const { fields } = sections[currentSectionIndex];
      if (overContainer === 'template-form' && fields.length !== 0) return;
      setFieldPlaceholderIndex((prev) => {
        if (overIndex === undefined) return prev;
        return overIndex;
      });
      const fieldPlaceholder = {
        id: CONSTANTS.PLACEHOLDER_TYPE_ID,
        displayName: placeholderFieldText,
        fieldName: 'dropHere',
        position: draggableField.position,
        templateFieldAttributes: {
          id: CONSTANTS.PLACEHOLDER_TYPE_ID,
          displayName: placeholderFieldText,
          fieldName: 'dropHere',
          ticketTemplateFieldType: {
            id: CONSTANTS.PLACEHOLDER_TYPE_ID,
          },
        },
      };
      if (fields.length <= CONSTANTS.MAX_FIELDS_PER_SECTION) {
        addFieldToSection(fieldPlaceholder, overIndex, currentSectionIndex);
      }
    },
    [
      addFieldToSection,
      draggableField,
      placeholderFieldText,
      prevSectionIndex,
      removeFieldFromSection,
      removePlaceholderField,
      sections,
      sectionsOrder,
    ],
  );

  const handleDragStart = useCallback(
    (event) => {
      const { id } = event.active;
      const draggablePrefix = id.split('-')[0];
      if (draggablePrefix === CONSTANTS.SECTION_ID_PREFIX) {
        getDraggableSection(id);
      } else if (draggablePrefix === CONSTANTS.FIELD_ID_PREFIX) {
        getDraggableAvailableField(id);
      } else {
        getDraggableSectionField(event);
      }
    },
    [getDraggableAvailableField, getDraggableSection, getDraggableSectionField],
  );

  const handleDragOver = useCallback(
    (event) => {
      const { active, over } = event;
      if (!active || !over) {
        if (fieldPlaceholderIndex !== undefined) removePlaceholderField();
        return;
      }
      const overIndex = over.data.current?.sortable?.index;
      const overContainer = over?.data?.current?.sortable?.containerId;
      const activeContainer = active?.data?.current?.sortable?.containerId;

      if (draggableSection) {
        if (overContainer !== 'template-form') return;
        if (!over.id.startsWith(`${CONSTANTS.SECTION_ID_PREFIX}-`)) return;
        if (draggableSection.order !== overIndex) updateSectionsOrder(overIndex);
        return;
      }

      const isValidFieldDropTarget =
        overContainer &&
        overContainer !== 'fields-panel' &&
        overIndex >= 0 &&
        active.id !== overContainer &&
        active.id !== over.id;

      if (!isValidFieldDropTarget) {
        if (fieldPlaceholderIndex !== undefined) removePlaceholderField();
        return;
      }

      if (draggableField && over.id !== CONSTANTS.PLACEHOLDER_TYPE_ID) {
        handleFieldDragOver(overIndex, overContainer, activeContainer);
      }
    },
    [
      draggableField,
      draggableSection,
      fieldPlaceholderIndex,
      handleFieldDragOver,
      removePlaceholderField,
      updateSectionsOrder,
    ],
  );
  const handleDragEnd = useCallback(
    ({ over, active }) => {
      setDragging(null);
      //Because of we have multiple return points and insted of adding each time setState to cleanup we can wrap it in try catch and use finally where we do all required cleanup.
      try {
        if (draggableSection) {
          if (!over) return;
          const tempSectionsOrder = { ...sectionsOrder };
          const overIndex = over.data.current?.sortable?.index;
          const sectionId = active.id.split(`${CONSTANTS.SECTION_ID_PREFIX}-`)[1];
          const sectionOldIndex = sections.findIndex((section) => String(section.id) === sectionId);

          let newSections = arrayMove(sections, sectionOldIndex, overIndex);
          newSections = newSections?.map((section, index) => {
            tempSectionsOrder[section.id] = index;
            return { ...section, order: index };
          });
          handleStateUpdate([{ sections: newSections }, { sectionsOrder: tempSectionsOrder }]);
        } else if (draggableField) {
          if (over?.id === draggableField?.fieldName) return;
          if (
            !over ||
            over.data.current.sortable.containerId === 'fields-panel' ||
            fieldPlaceholderIndex === undefined
          ) {
            addFieldToSection(draggableField, draggableField.initFieldIndex, draggableField.initSectionIndex);
            return;
          }
          addFieldToSection(draggableField);
          if (draggableField.availableFieldIndex !== null) {
            setAvailableFieldsItems((prev) => {
              const tempAvailableFields = [...prev];
              tempAvailableFields[draggableField.availableFieldIndex].inUse = true;
              return tempAvailableFields;
            });
          }
        }
      } finally {
        setDraggableField(() => null);
        setDraggableSection(() => null);
        setFieldPlaceholderIndex(null);
        setDroppableSectionIndex(null);
      }
    },
    [
      addFieldToSection,
      draggableField,
      draggableSection,
      fieldPlaceholderIndex,
      handleStateUpdate,
      sections,
      sectionsOrder,
      setDragging,
    ],
  );

  const dragOverlay = useMemo(() => {
    if (draggableField) {
      return (
        <DragOverlayField
          data-testid="draggable-field"
          id={draggableField.id}
          fieldName={draggableField.displayName}
          isBig={draggableField?.position === 'STRETCH'}
        />
      );
    }
    if (draggableSection) {
      return (
        <DragOverlaySection
          id={draggableSection.id}
          sectionName={draggableSection.name}
          modifiers={restrictToParentElement}
        />
      );
    }
    return null;
  }, [draggableField, draggableSection]);

  const handleOkClick = () => {
    setShowFieldsErrorModal(false);
  };

  return (
    <>
      <DndContext
        collisionDetection={pointerWithin}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        sensors={sensors}
        measuring={{
          droppable: {
            strategy: MeasuringStrategy.WhileDragging,
          },
        }}
      >
        <StyledTemplateBuilderPanel>
          <div className="template-left">
            <TemplateFieldsPanel
              data-testid="draggable-field"
              fields={availableFieldsItems}
              fetchNextPage={fetchNextPage}
              dataLength={CONSTANTS.MAX_FIELDS_IN_FIELDS_LIST}
              hasNextPage={hasNextPage}
              searchValue={searchValue}
              isFetching={isFetching}
              onSearchChange={onSearchChange}
              onSearchClear={onSearchClear}
            />
          </div>
          <div className="template-middle">
            <TemplateForm updateAvailableFieldsDefaultValue={updateAvailableFieldsDefaultValue} />
          </div>
          <TemplatesRightPanel />
        </StyledTemplateBuilderPanel>
        <DragOverlay>{dragOverlay}</DragOverlay>
      </DndContext>
      {showFieldsErrorModal && (
        <MessagePrompt data-testid="message-prompt-fields" open title={maxFieldsLimitTitle} onOkClick={handleOkClick}>
          {maxFieldsLimitText}
        </MessagePrompt>
      )}
    </>
  );
}

const TemplateBuilderPanelContextWrapper = (props) => {
  const [isDragging, setDragging] = useState(false);
  const { templateId, header, sections, sectionsOrder, srType, handleStateUpdate, fieldsMap, dependantFields } = props;

  const value = useMemo(
    () => ({
      templateId,
      isDragging,
      setDragging,
      header,
      sections,
      sectionsOrder,
      srType,
      handleStateUpdate,
      fieldsMap,
      dependantFields,
    }),
    [templateId, isDragging, header, sections, sectionsOrder, srType, handleStateUpdate, fieldsMap, dependantFields],
  );
  return (
    <TemplateBuilderContext.Provider value={value}>
      {useMemo(
        () => (
          <TemplateBuilderPanel />
        ),
        [],
      )}
    </TemplateBuilderContext.Provider>
  );
};

export default TemplateBuilderPanelContextWrapper;
