import { useRef, useState, useEffect, useCallback, useMemo, useLayoutEffect } from 'react';
import { StyledMultiSelectLabel } from 'common/components/controls/MultiSelectField/style';
import Tooltip from 'common/components/tooltip';
import { StyledChip } from 'features/queue/gridFilters/style';
import { StyledChipContainer, StyledListWrapper } from './style';

export default function MultiSelectChipList({ keyString, captionString, itemList, placeholder, isError }) {
  const listRef = useRef(null);
  const chipContainerRef = useRef();

  const [listHeight, setListHeight] = useState(0);
  const [splitIndex, setSplitIndex] = useState(-1);
  const [shouldRecalculateChipList, setShouldRecalculateChipList] = useState(false);

  useEffect(() => {
    setListHeight(listRef?.current?.offsetHeight);
  }, [itemList]);

  const handleResize = useCallback(() => {
    setTimeout(() => {
      setSplitIndex(-1);
      setShouldRecalculateChipList(true);
    }, 1000);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const calcSplitIndex = useCallback(() => {
    const containerRight = chipContainerRef.current.getBoundingClientRect().right;
    const index = Array.from(chipContainerRef.current.childNodes).findIndex(
      (node) =>
        node.getBoundingClientRect().right > containerRight ||
        node.classList.contains('queue-multi-select-dropdown-chip'),
    );
    setSplitIndex(index);
  }, []);

  useLayoutEffect(() => {
    calcSplitIndex();
  }, [itemList, calcSplitIndex]);

  useLayoutEffect(() => {
    if (shouldRecalculateChipList) {
      calcSplitIndex();
      setShouldRecalculateChipList(false);
    }
  }, [shouldRecalculateChipList, calcSplitIndex]);

  const { visibleChips, dropdownChips } = useMemo(
    () =>
      splitIndex > -1
        ? { visibleChips: itemList?.slice(0, splitIndex), dropdownChips: itemList?.slice(splitIndex) }
        : { visibleChips: itemList },
    [itemList, splitIndex],
  );

  return (
    <StyledListWrapper data-testid="multi-select-chip-wrapper" ref={listRef} listHeight={listHeight}>
      {!visibleChips?.length && (
        <StyledMultiSelectLabel className="empty-multi-select-chip-placeholder" isError={isError}>
          {placeholder}
        </StyledMultiSelectLabel>
      )}
      <StyledChipContainer ref={chipContainerRef}>
        {Array.isArray(visibleChips) &&
          visibleChips.map((item) => (
            <StyledChip
              variant="outlined"
              key={item[keyString]}
              className="queue-multi-select-visible-chip"
              data-testid="multi-select-chip"
              label={
                <Tooltip
                  placement="top"
                  isTruncatedText
                  title={item[captionString]}
                  text={item[captionString]}
                  style={{ fontSize: '0.875rem', lineHeight: 1.2858 }}
                />
              }
            />
          ))}
        {dropdownChips?.length > 0 && (
          <StyledChip
            className="queue-multi-select-dropdown-chip"
            data-testid="multi-select-chip"
            key={dropdownChips?.length}
            variant="outlined"
            label={
              <Tooltip
                placement="top"
                isTruncatedText
                title="op"
                text={`+ ${dropdownChips?.length}`}
                style={{ fontSize: '0.875rem', lineHeight: 1.2858 }}
              />
            }
          />
        )}
      </StyledChipContainer>
    </StyledListWrapper>
  );
}
