import { useRouter } from '@tanstack/react-router';
import { getWorkflowDesignerUrl } from 'services/templateService';
import ButtonBase from 'common/components/buttons/FormButton';
import { Tooltip } from 'common/components/sysaidLibrary/Tooltip';
import { ReactComponent as AddWorkflowIcon } from 'images/icons/add-workflow-btn.svg';
import { SR_TYPES } from 'features/TicketPanel/constants';
import { ReactComponent as ArrowRight } from 'images/icons/arrow-right.svg';
import { StyledNavMenuTextButton, StyledTemplateBuilderHeader } from './StyledTemplateBuilderHeader';
import TemplateName from '../TemplateName';
import TemplateMetadata from '../TemplateMetadata';
import TemplateButtons from '../TemplateButtons';
import TemplateDropDownMenu from '../TemplateDropDownMenu';
import TemplateSettingMenu from '../TemplateSettingMenu';
import useTexts from '../useTexts';
import TemplateBuilderPreviewButton from '../TemplateBuilderPreviewButton';
import { StyledPreviewButtonWrapper as AddWorkflowButtonWrapper } from '../TemplateBuilderPreviewButton/StyledBuilderPreviewButton';
import { ROUTES_PATHS } from '../../../constants';
import { TEMPLATES_MENU_ITEM_ID, TEMPLATES_SUB_MENU_ITEM_ID, TEMPLATES_SUB_MENU_LIST_ID } from '../../settings/constants';

export default function TemplateBuilderHeader(props) {
  const router = useRouter();
  const { handleStateUpdate, template } = props;
  const { id, templateName, srType, createdBy, dateCreated, visibleInSelfServicePortal, visibleToAutomationPanel } =
    template;
  const { templatesListText, settingsText, addWorkflowDisableTooltip, addWorkflowText } = useTexts();

  const handleBackToTemplateList = () => {
    const settingIds = { menuItemId: TEMPLATES_MENU_ITEM_ID, subMenuListId: TEMPLATES_SUB_MENU_LIST_ID, subMenuItemId: TEMPLATES_SUB_MENU_ITEM_ID };
    const path = `/spaces/templates-list?settingIds=${encodeURIComponent(JSON.stringify(settingIds))}`;
    router.navigate({ to: path });
  };

  const handleBackToSettingPage = () => {
    router.navigate({ to: ROUTES_PATHS.SETTINGS_URL });
  };

  const templateNameProps = {
    templateName,
    srType,
    updateTemplateName: handleStateUpdate,
  };

  const templateMetadataProps = {
    dateCreated,
    createdBy,
    srType,
  };

  const templateSettingMenuProps = {
    srType,
    visibleInSSP: visibleInSelfServicePortal,
    visibleToAutomationPanel,
    handleChange: handleStateUpdate,
  };

  const handleAddWorkflow = async () => {
    const workflowDesignerUrl = await getWorkflowDesignerUrl(id);
    if (workflowDesignerUrl) {
      const newTab = window.open(workflowDesignerUrl, '_blank');
      newTab.focus();
    }
  };

  return (
    <StyledTemplateBuilderHeader>
      <div className="header-nav-menu">
        <StyledNavMenuTextButton onClick={handleBackToSettingPage}>
          <div className="text">{settingsText || 'Settings'}</div>
          <ArrowRight />
        </StyledNavMenuTextButton>
        <StyledNavMenuTextButton onClick={handleBackToTemplateList}>
          <div className="text">{templatesListText}</div>
          <ArrowRight />
        </StyledNavMenuTextButton>
        <div className="text">{templateNameProps.templateName}</div>
      </div>
      <TemplateName {...templateNameProps} />
      <div className="template-header-bottom">
        <TemplateMetadata {...templateMetadataProps} />
        {srType !== SR_TYPES.incident && (
          <div className="template-header-add-workflow">
            <AddWorkflowButtonWrapper data-testid="template-add-workflow-button">
              <Tooltip placement="bottom" title={addWorkflowDisableTooltip} arrow forceDisplayOnHover={!id}>
                <ButtonBase
                  btnTheme="view"
                  text={addWorkflowText}
                  icon={<AddWorkflowIcon />}
                  hideText
                  className="add-workflow-btn"
                  onClick={handleAddWorkflow}
                  disabled={!id}
                />
              </Tooltip>
            </AddWorkflowButtonWrapper>
          </div>
        )}
        <div className="template-header-right">
          <TemplateBuilderPreviewButton template={template} />
          <TemplateButtons template={template} handleStateUpdate={handleStateUpdate} />
          <TemplateSettingMenu {...templateSettingMenuProps} />
          <TemplateDropDownMenu template={template} />
        </div>
      </div>
    </StyledTemplateBuilderHeader>
  );
}
