import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { actionEnums, HOVER_EFFECTS } from '../../constants';

export const StyleReadonlyEditorWrapper = styled(Box)(() => ({
  '& .truncate-text-button': {
    marginLeft: '8px',
  },
}));

export const StyledAuditLogEditor = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'isRichTextTruncated' &&
    prop !== 'logType' &&
    prop !== 'showKebabMenu' &&
    prop !== 'hasQuote' &&
    prop !== 'isHovered',
})(({ theme, isRichTextTruncated, isHovered, logType }) => {
  const wrapperBackground = () => {
    if (logType === actionEnums.SOLUTION_RESOLUTION_CREATED) {
      return 'transparent';
    }
    if (isHovered) {
      return theme.palette.grey.bt_alt_grey_reg;
    }
    return theme.palette.background.action;
  };

  return {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    gap: '10px',
    justifyContent: 'space-between',
    position: 'relative',

    '& .kebab-menu': {
      position: 'absolute',
      right: '-16px',
      top: '0px',
      textAlign: 'center',
      '& .kebab-menu-icon': {
        width: '16px',
      },
    },
    'p , ul': {
      margin: 0,
    },
    'a , button': {
      cursor: 'pointer',
    },
    '.wrapper-no-menu': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      margin: '0px',
      padding: '6px 8px',
      borderRadius: '4px',
      backgroundColor: wrapperBackground(),

      '&:hover': {
        backgroundColor: theme.palette.grey.bt_alt_grey_reg,
      },
    },

    '.first-content-wrap': {
      '& .MuiGrid-root .MuiBox-root .fr-view': {
        textDecoration: logType === actionEnums.NOTE_DELETE ? 'line-through' : 'none',
        color: logType === actionEnums.NOTE_DELETE ? `${theme.palette.text.title_txt} !important` : 'inherit',
      },
      '& .attachment-chip': {
        opacity: logType === actionEnums.NOTE_DELETE ? 0.6 : 1,
      },
    },

    '.second-content-wrap': {
      '& .MuiGrid-root .MuiBox-root .fr-view': {
        textDecoration: logType === actionEnums.NOTE_DELETE ? 'line-through' : 'none',
        color: logType === actionEnums.NOTE_DELETE ? `${theme.palette.text.title_txt} !important` : 'inherit',
      },
      '& .attachment-chip': {
        opacity: logType === actionEnums.NOTE_DELETE ? 0.6 : 1,
      },
    },

    '.first-content-wrap .readonly-editor,.second-content-wrap .readonly-editor': {
      display: '-webkit-box',
      overflow: 'hidden',
      WebkitBoxOrient: 'vertical',
      MsBoxOrient: 'vertical',
      OBoxOrient: 'vertical',
      WebkitLineClamp: isRichTextTruncated ? '2' : 'none',
      MsLineClamp: isRichTextTruncated ? '2' : 'none',
      OLineClamp: isRichTextTruncated ? '2' : 'none',
    },

    '.header-body-content': logType !== actionEnums.SOLUTION_RESOLUTION_CREATED && {
      backgroundClip: 'padding-box',
      borderRadius: '4px',
      width: 'auto',
      '& .first-content-wrap .attachments-wrapper, .second-content-wrap .attachments-wrapper': {
        marginTop: '8px',
      },
      '&:hover': {
        cursor: HOVER_EFFECTS.includes(logType) ? 'pointer' : 'default',
      },
    },
    '.footer-wrap': {
      display: 'flex',
      gap: '12px',
      marginTop: '6px',
      justifyContent: logType === actionEnums.SOLUTION_RESOLUTION_CREATED ? 'flex-end' : 'space-between',
      alignItems: 'center',
      wordBreak: ' initial',
      '& > div': {
        padding: '0',
      },
    },
  };
});

export const StyledWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  width: '100%',
  height: '100%',

  '& .truncate-text-button': {
    marginTop: '-2px',
    padding: '0px 8px',
  },
}));

export const StyledResolutionWrapper = styled(Box)(() => ({
  backgroundClip: 'padding-box',
  width: '100%',
  borderRadius: '4px',
  padding: '0px 8px',

  '& .resolution-field': {
    marginTop: '4px',
  },

  '& .audit-log-editor': {
    marginLeft: '-8px',
    width: 'calc(100% + 8px)',
  },
  '& .audit-log-editor .wrapper-no-menu': {
    background: 'transparent',
    padding: '0px 8px',
  },
}));

export const StyledEditable = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  '.kebab-menu-icon': {
    width: '20px',
  },
  '& .soulutionWrapperCont': {
    paddingTop: '4px',
    paddingRight: '0px',
  },
  ':hover .soulutionWrapperCont': {
    cursor: 'pointer',
    backgroundColor: theme.palette.grey.bt_alt_grey_reg,
  },
  '.caption-text': {
    display: 'flex',
    whiteSpace: 'break-spaces',
    color: theme.palette.text.journey_title,
    fontSize: theme.fontSize.main,
    lineHeight: '20px',
    fontFamily: theme.fonts.robotoMedium,
    '&.solution': {
      marginTop: '2px',
    },
  },
  '.value-text': {
    display: 'flex',
    whiteSpace: 'break-spaces',
    color: theme.palette.text.journey_title,
    fontSize: theme.fontSize.main,
    lineHeight: '1.28',
    fontFamily: theme.fonts.robotoRegular,
  },
  '.caption-style': {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '2px',
    marginBottom: '2px',
    '& > span': {
      lineHeight: '20px',
    },
  },
}));
