import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTexts } from '../../hooks';
import { StyledMenuItem, StyledSelect } from './CustomDateSelect.styles';

export const CustomDateSelect = ({ handleSelectChange, filterType }) => {
  const { equalsTo, inRange } = useTexts();

  const handleChange = (event) => {
    handleSelectChange(event.target.value);
  };
  return (
    <StyledSelect
      MenuProps={{
        disablePortal: true,
      }}
      id="custom-date-select"
      value={filterType}
      onChange={handleChange}
      IconComponent={KeyboardArrowDownIcon}
    >
      <StyledMenuItem value={1}>{equalsTo}</StyledMenuItem>
      <StyledMenuItem value={2}>{inRange}</StyledMenuItem>
    </StyledSelect>
  );
};
