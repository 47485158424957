import axios from 'axios';

const apiPath = '/spaces/api/';
const baseURL = `${window.location.origin}${apiPath}`;
const axiosInstance = axios.create({
  baseURL,
  timeout: 1000000,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (err) => {
    const { config } = err;

    if (!config || !config.retry) {
      return Promise.reject(err);
    }
    config.retry -= 1;
    const delayRetryRequest = new Promise((resolve) => {
      setTimeout(() => {
        console.log('retry the request', config.url);
        resolve();
      }, config.retryDelay || 1000);
    });
    return delayRetryRequest.then(() => axiosInstance(config));
  },
);
export { axiosInstance, baseURL };
