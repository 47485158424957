import { useCallback, useEffect, useState } from 'react';
import { sectionProperties, switchId } from '../constants';
import { getAllViewGroups, getSelectedGroupList, getUpdatedGroupList } from '../utils/utils';
import LimitEditProperty from './LimitEditProperty';

export default function SectionPropertiesPanel(props) {
  const { sectionData, allGroups, handleUpdateProperties } = props;
  const [showLimitEdit, setShowLimitEdit] = useState(sectionData?.limitedPermissions || false);
  const [selectedGroups, setSelectedGroups] = useState([]);

  useEffect(() => {
    if (sectionData?.limitedPermissions) {
      // If limited permission property is true and no groups are selected, setting all groups as 'view'.
      if (!sectionData?.ticketTemplateSectionPermissions?.length) {
        const updatedProperties = {
          [sectionProperties.ticketTemplateSectionPermissions]: getAllViewGroups(allGroups),
          [sectionProperties.limitedPermissions]: sectionData?.limitedPermissions,
        };
        handleUpdateProperties(updatedProperties);
      } else {
        setSelectedGroups(getSelectedGroupList(sectionData?.ticketTemplateSectionPermissions));
      }
    }
    setShowLimitEdit(sectionData?.limitedPermissions);
  }, [allGroups, showLimitEdit, handleUpdateProperties, sectionData]);

  const handleGroupChange = useCallback(
    (selectedGroupList, checked = showLimitEdit) => {
      const updatedProperties = {
        [sectionProperties.limitedPermissions]: checked,
        [sectionProperties.ticketTemplateSectionPermissions]: selectedGroupList.length
          ? getUpdatedGroupList(selectedGroupList)
          : [],
      };
      handleUpdateProperties(updatedProperties);
    },
    [handleUpdateProperties, showLimitEdit],
  );

  const handleSwitchChange = (event) => {
    const {
      target: { checked },
    } = event;
    setShowLimitEdit(checked);
    handleGroupChange([], checked);
  };

  return (
    <>
      <LimitEditProperty
        switchId={switchId.LIMIT_EDIT}
        showLimitEdit={showLimitEdit}
        onSwitchChange={handleSwitchChange}
        isSwitchDisabled={false}
        handleGroupChange={handleGroupChange}
        selectedGroups={selectedGroups}
        allGroups={allGroups}
        isSection
      />
    </>
  );
}
