import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';

export const StyledTemplateButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'shouldhover',
})(({ theme, shouldhover = true }) => ({
  padding: 0,
  textTransform: 'none',
  color: '#4F4F4F',
  fontSize: theme.fontSize.main,
  lineHeight: 1,
  '&:hover': {
    background: shouldhover ? null : 'none',
  },
}));
export const StyledTemplateButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  background: theme.palette.generic.primary,
  padding: '26px 53px 7px 44px',
  '@media only screen and (max-width: 1024px)': {
    padding: '26px 25px 7px 24px',
  },
}));
