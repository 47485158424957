import { Box, LinearProgress } from '@mui/material';
import { useRbTexts } from 'remote-state/applicationHooks';
import { ReactComponent as CloseIcon } from 'images/icons/close.svg';
import { ReactComponent as RetryIcon } from 'images/icons/retry.svg';

import Tooltip from 'common/components/tooltip';
import { StyledUploadProgressBar } from './style';

const MAX_VALUE = 100;

export function UploadProgressBar({ id, fileName, error, value, onClose, isMinimizedSize, onRetry }) {
  const texts = useRbTexts();
  const retryText = texts?.['spaces.attachments.create.retry'];
  const handleClose = () => {
    onClose(id);
  };

  return (
    <>
      <StyledUploadProgressBar
        className="progress-bar-wrapper"
        error={!!error}
        key={id}
        isMinimizedSize={isMinimizedSize}
      >
        <Box className="upload-file-wrapper">
          <div className="upload-file-name">
            <Tooltip text={error || fileName} isTruncatedText />
          </div>
          <LinearProgress variant="determinate" value={error ? MAX_VALUE : value || 0} />
          {!!error && !!onRetry ? (
            <button className="retry" onClick={onRetry}>
              <RetryIcon />
              {retryText}
            </button>
          ) : null}
        </Box>
        {!!error && !!onClose ? (
          <div className="svg">
            <CloseIcon onClick={handleClose} />
          </div>
        ) : null}
      </StyledUploadProgressBar>
    </>
  );
}
