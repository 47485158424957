import { TICKETS_OPEN_URL } from 'constants/index';
import { StyledOpenInNewTabTicket } from './style';
import useTexts from './useTexts';

function OpenInNewTab(props) {
  const { handleCloseParent, onClickParent, srId } = props;
  const { title } = useTexts();

  const openInNewTab = () => {
    const url = TICKETS_OPEN_URL.SPACES(srId);
    window.open(url, '_blank', 'noreferrer');
    handleCloseParent();
    onClickParent();
  }

  return (
    <StyledOpenInNewTabTicket onClick={openInNewTab} data-testid="open-in-new-tab">
      <button className="open_in_new_tab-ticket">
        <span className="open_in_new_tab-ticket-span">
          {title}
        </span>
      </button>
    </StyledOpenInNewTabTicket>
  );
}
export default OpenInNewTab;
