import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pages: {},
};

export const pageValidationSlice = createSlice({
  name: 'pageValidation',
  initialState,

  reducers: {
    setPageValidations: (state, action) => {
      const { component, validationDetails, pageKey } = action.payload;
      const key = pageKey || window.location.pathname;
      if (!state.pages[key]) {
        state.pages[key] = {
          components: {
            [component]: validationDetails,
          },
        };
      } else {
        state.pages[key].components[component] = validationDetails;
      }

      state.pages[key].isValid = !Object.values(state.pages[key].components).some((component) => !component.isValid);
    },
    clearPageValidation: () => initialState,
  },
});

export const { setPageValidations, clearPageValidation } = pageValidationSlice.actions;

export const selectPageValidations = (state) => state.pageValidation.pages[window.location.pathname];
export const selectIsPageValid = (state) => state.pageValidation.pages[window.location.pathname]?.isValid ?? true;

export default pageValidationSlice.reducer;
export const pageValidationSliceName = pageValidationSlice.name;
