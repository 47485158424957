import { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Autocomplete, TextField } from '@mui/material';
import { useRecipientQuery } from 'remote-state/userServiceHooks';
import { debounce, isEmpty } from 'lodash-es';
import { AVATAR_CONSTANTS } from 'constants/avatar';
import Tooltip from 'common/components/tooltip';
import { ReactComponent as ChipDeleteIcon } from 'images/icons/chipDelete.svg';
import { useEditorActions } from 'features/resolutionPanel/middlePanel/auditLog/hooks';
import { updateActionLineData } from 'features/resolutionPanel/middlePanel/auditLog/store/slice';
import {
  StyledRecipientInput,
  StyledBasicAvatar,
  StyledChip,
  StyledUserOption,
  StyledUserOptionWrapper,
  StyledOptionText,
  ShowMoreWrapper,
  GroupHeader,
  GroupItems,
  RECIPIENTS_GAPS,
} from './style';
import useTexts from './useTexts';
import { useEnableExternalEmailAddressContent } from '../../../hooks/messageHooks';

const RecipientInput = ({ name, selected, isFocused, copyRecipientCount }) => {
  const dispatch = useDispatch();
  const [queryText, setQueryText] = useState('');
  const { recipientData } = useRecipientQuery(queryText);
  const { handleEditorChange } = useEditorActions();
  const { noEmail, showMore, groupLabel } = useTexts();
  const [clear, setClear] = useState(false);
  const autocompleteRef = useRef(null);
  const inputRef = useRef(null);
  const [limitTags, setLimitTags] = useState(0);
  const handleTextChange = useMemo(() => debounce((e) => setQueryText(e.target.value), 300), []);
  const ghostArray = !isFocused && copyRecipientCount ? new Array(3).fill({}) : [];
  const { data: isEnableExternalEmail } = useEnableExternalEmailAddressContent();
  function isRegisteredSysAidUserEmail(email) {
    if (!isEnableExternalEmail) {
      const foundEmail = recipientData.find((user) => user.emailAddress === email);
      return foundEmail;
    }
    return true;
  }
  function isValidEmail(email) {
    /*
        This test is simple so it works. ValidateJS currently has a major loophole in the email test
        Other implementations block out emails that are valid despite not following RFC 5322
        Bottom line: Particularly as it can be disabled, JS cannot be relied upon for validation. This is for UX ONLY
    */
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
    return emailRegex.test(email);
  }

  const handleSelectionChange = (e, value, reason, { option: targetItem, type }) => {
    let newRecipient;
    setClear(true);
    setQueryText('');
    const isNewEmail = typeof targetItem === 'string';
    if (isNewEmail) {
      newRecipient = { name: targetItem, emailAddress: targetItem, type: 'email' };
    }
    if (e.code === 'Enter' && reason === 'removeOption') return;
    if (e.code === 'Enter' && isNewEmail && recipientData.length) return;
    switch (reason) {
      case 'removeOption':
        if (!isNewEmail && !isValidEmail(targetItem)) {
          handleEditorChange('invalidRecipients', {
            list: 'invalidRecipients',
            listItem: targetItem,
            operation: 'remove',
          });
        }
        handleEditorChange(name, { list: name, listItem: value, operation: 'overwrite' });
        break;
      case 'createOption':
      case 'selectOption':
      case 'blur':
      default:
        if (isNewEmail && (!isValidEmail(targetItem) || !isRegisteredSysAidUserEmail(targetItem)) && type !== 'group') {
          newRecipient.error = true;
          handleEditorChange('invalidRecipients', {
            list: 'invalidRecipients',
            listItem: newRecipient,
          });
        }
        handleEditorChange(name, {
          list: name,
          listItem: newRecipient || targetItem,
        });
    }
  };

  const onChangeTextField = (event) => {
    if (inputRef.current.clientWidth < inputRef.current.scrollWidth) {
      inputRef.current.style.flexBasis = `${inputRef.current.scrollWidth}px`;
    }
    handleTextChange(event);
    setClear(false);
  };

  const onBlurTextField = () => {
    dispatch(updateActionLineData({ [`is${name[0].toUpperCase() + name.slice(1)}Focused`]: false }));
    inputRef.current.style.flexBasis = '0px';
  };

  const updateLimitTags = useCallback(() => {
    let limit = 0;
    if (autocompleteRef.current) {
      const chipsWrapper = autocompleteRef.current.querySelector('.MuiAutocomplete-inputRoot');
      const autocompleteItems = Array.from(chipsWrapper.children);
      const chips = autocompleteItems.filter((item) => item.nodeName === 'SPAN' && !item.className);
      const displayedChips = [];
      let wrapperWidth = chipsWrapper.clientWidth - RECIPIENTS_GAPS.WRAPPER_PADDING_LEFT;
      chips?.forEach((chip) => {
        const chipWidth = chip.clientWidth;
        if (wrapperWidth > 0 && wrapperWidth - chipWidth > 0) {
          wrapperWidth = wrapperWidth - chipWidth - RECIPIENTS_GAPS.WRAPPER_COLUMN_GAP;
          limit += 1;
          displayedChips.push(chip);
        }
      });
      if (chips && chips.length >= limit && displayedChips.length !== chips.length) {
        displayedChips
          .slice()
          .reverse()
          .forEach((chip) => {
            const chipWidth = chip.clientWidth;
            if (wrapperWidth < RECIPIENTS_GAPS.SHOW_MORE_WIDTH + RECIPIENTS_GAPS.WRAPPER_COLUMN_GAP) {
              limit -= 1;
              wrapperWidth = wrapperWidth + chipWidth + RECIPIENTS_GAPS.WRAPPER_COLUMN_GAP;
            }
          });
      }
    }
    setLimitTags(limit);
  }, []);

  useEffect(() => {
    updateLimitTags();
  }, [selected, updateLimitTags]);

  useEffect(() => {
    if (inputRef?.current && isFocused) {
      inputRef.current.focus();
    }
  }, [inputRef, isFocused]);

  const popperOffsetLeft = (inputRef?.current?.offsetLeft || 0) + (autocompleteRef?.current?.offsetLeft || 0);
  const popperOffsetTop =
    (inputRef?.current?.offsetTop || 0) +
    (autocompleteRef?.current?.offsetTop || 0) +
    (inputRef?.current?.clientHeight || 28);

  return (
    <StyledRecipientInput
      data-testid="message-recipient"
      data-cy="message-recipient"
      popperOffset={{
        left: popperOffsetLeft,
        top: popperOffsetTop,
      }}
      isFocused={isFocused}
      onClick={() => {
        if (!isFocused) {
          dispatch(updateActionLineData({ [`is${name[0].toUpperCase() + name.slice(1)}Focused`]: true }));
        }
      }}
    >
      <Autocomplete
        data-testid="autocomplete-recipient"
        data-cy="autocomplete-recipient"
        open={!!queryText}
        ref={autocompleteRef}
        multiple
        value={selected?.concat(ghostArray)}
        filterOptions={(x) => x}
        freeSolo
        disableClearable
        handleHomeEndKeys
        autoSelect
        autoHighlight
        limitTags={limitTags || 2}
        getLimitTagsText={() => <ShowMoreWrapper className="show-more-chip">{showMore}</ShowMoreWrapper>}
        onInputChange={(event, newInputValue) => {
          if (newInputValue.endsWith(',')) {
            event.target.blur();
            event.target.focus();
          }
        }}
        clearOnBlur={clear}
        disablePortal
        onChange={handleSelectionChange}
        groupBy={(option) => option.type}
        renderGroup={(params) => (
          <li
            key={params.key}
            data-testid="recipient-list"
            data-cy="recipient-list"
          >
            <GroupHeader>{groupLabel[params.group]}</GroupHeader>
            <GroupItems>{params.children}</GroupItems>
          </li>
        )}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
        isOptionEqualToValue={(option, value) => {
          const optionName = typeof option === 'string' ? option : option.name;
          const valueName = typeof value === 'string' ? value : value.name;
          return optionName === valueName;
        }}
        renderOption={(_, option) => {
          const { emailAddress, name, groupName, type, id } = option;
          let textBeforeMatch = '';
          let matchedText = emailAddress;
          let textAfterMatch = '';
          let nameBeforeMatch = name;
          let matchedName = '';
          let nameAfterMatch = '';
          if (emailAddress) {
            const emailIndex = emailAddress.indexOf(queryText);
            textBeforeMatch = emailAddress.slice(0, emailIndex);
            matchedText = emailAddress.slice(emailIndex, emailIndex + queryText.length);
            textAfterMatch = emailAddress.slice(emailIndex + queryText.length);
          }
          const nameIndex = name.toLowerCase().indexOf(queryText.toLowerCase());
          if (name && nameIndex >= 0) {
            nameBeforeMatch = name.slice(0, nameIndex);
            matchedName = name.slice(nameIndex, nameIndex + queryText.length);
            nameAfterMatch = name.slice(nameIndex + queryText.length);
          }
          if (selected.some((el) => el.name === name)) return;
          return (
            <StyledUserOptionWrapper
              data-testid={`recipient-${id}`}
              data-cy={`recipient-${id}`}
              key={option.id}
              onClick={(e) => {
                if (emailAddress) {
                  handleSelectionChange(e, emailAddress, 'createOption', {
                    option: { name, emailAddress, type, id },
                  });
                } else {
                  handleSelectionChange(e, groupName, 'createOption', { option: { name, groupName, type, id } });
                }
              }}
            >
              <StyledBasicAvatar
                type={option.type}
                disableHover
                size={AVATAR_CONSTANTS.SIZES.SMALL}
                userName={name || emailAddress}
                profileImage={option?.profileImage}
              />
              <StyledUserOption>
                <Tooltip
                  title={name}
                  arrow
                  isTruncatedText
                  placement="top"
                  text={
                    <StyledOptionText className="name">
                      {nameBeforeMatch}
                      <span className="matched-text">{matchedName}</span>
                      {nameAfterMatch}
                    </StyledOptionText>
                  }
                />
                <Tooltip
                  title={emailAddress || groupName}
                  arrow
                  isTruncatedText
                  placement="top"
                  text={
                    <StyledOptionText className="email-address">
                      {textBeforeMatch}
                      <span className="matched-text">{matchedText}</span>
                      {textAfterMatch}
                    </StyledOptionText>
                  }
                />
              </StyledUserOption>
            </StyledUserOptionWrapper>
          );
        }}
        options={recipientData}
        renderTags={(values, getTagProps) =>
          values.map((option, index) => {
            if (isEmpty(option)) return;
            return (
              <Tooltip
                key={option.id}
                arrow
                placement="top"
                title={!option?.error ? option.emailAddress || option.name : noEmail}
                text={
                  <StyledChip
                    data-testid="chip"
                    {...getTagProps({ index })}
                    label={option.name || option.emailAddress}
                    deleteIcon={<ChipDeleteIcon />}
                    {...(option?.error
                      ? { errorMode: true }
                      : {
                          avatar: (
                            <StyledBasicAvatar
                              type={option.type}
                              disableHover
                              size={AVATAR_CONSTANTS.SIZES.SMALL}
                              userName={option.emailAddress || option.name}
                              profileImage={option?.profileImage}
                            />
                          ),
                        })}
                  />
                }
              />
            );
          })
        }
        onKeyDown={(e) => {
          if (e.key === 'Enter' && recipientData.length) {
            const { name, id, type } = recipientData[0];
            const option = { name, id, type };
            if (recipientData[0].emailAddress) {
              option.emailAddress = recipientData[0].emailAddress;
              handleSelectionChange(e, option.emailAddress, 'createOption', {
                option,
              });
            } else {
              option.groupName = recipientData[0].groupName;
              handleSelectionChange(e, option.groupName, 'createOption', {
                option,
              });
            }
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={onChangeTextField}
            variant="standard"
            onFocus={() =>
              dispatch(updateActionLineData({ [`is${name[0].toUpperCase() + name.slice(1)}Focused`]: true }))
            }
            onBlur={onBlurTextField}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              inputRef,
            }}
          />
        )}
      />
    </StyledRecipientInput>
  );
};

export default RecipientInput;
