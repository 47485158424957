import MultiSelectChipList from 'common/components/controls/MultiSelectField/MultiSelectChipList';
import useTexts from 'features/resolutionPanel/middlePanel/relatedItems/hooks/useTexts';

import { actionEnums } from '../../../constants';
import { StyledRelatedItemsEvent } from './style';

export const RelatedItemsEvent = ({ auditLog }) => {
  const { getSrType } = useTexts();

  const extractRelatedItmesArray = (logInformation) => {
    switch (auditLog?.logType) {
      case actionEnums.RELATED_ITEM_SR_CREATED: {
        const relationship = Object.keys(logInformation)[0];
        const linkedItems = Array.isArray(logInformation?.[relationship])
          ? logInformation[relationship].map((item) => ({
              srId: item?.id,
              srType: getSrType(item?.value?.toLowerCase()) || item?.value,
              chipCaption: `${getSrType(item?.value?.toLowerCase()) || item?.value} #${item?.id}`,
            }))
          : [];
        return linkedItems;
      }
      case actionEnums.RELATED_ITEM_SR_DELETED: {
        return Object.entries(logInformation)?.map(([srId, srType]) => ({
          srId,
          srType: getSrType(srType?.toLowerCase()) || srType,
          chipCaption: `${getSrType(srType?.toLowerCase()) || srType} #${srId}`,
        }));
      }
      case actionEnums.RELATED_ITEM_ASSET_CREATED: {
        const relationship = Object.keys(logInformation)[0];
        const linkedItems = Array.isArray(logInformation?.[relationship])
          ? logInformation[relationship].map((item) => ({
              id: item?.id,
              value: item?.value,
              chipCaption: item?.value,
            }))
          : [];
        return linkedItems;
      }
      case actionEnums.RELATED_ITEM_ASSET_DELETED: {
        return Object.entries(logInformation)?.map(([assetId, assetName]) => ({
          assetId,
          assetName,
          chipCaption: assetName,
        }));
      }
      case actionEnums.RELATED_ITEM_CI_CREATED: {
        const relationship = Object.keys(logInformation)[0];
        const linkedItems = Array.isArray(logInformation?.[relationship])
          ? logInformation[relationship].map((item) => ({
              id: item?.id,
              value: item?.value,
              chipCaption: item?.value,
            }))
          : [];
        return linkedItems;
      }
      case actionEnums.RELATED_ITEM_CI_DELETED: {
        return Object.entries(logInformation)?.map(([ciId, ciName]) => ({
          ciId,
          ciName,
          chipCaption: ciName,
        }));
      }
      default:
        return [];
    }
  };

  const handleKeyCaption = () => {
    switch (auditLog?.logType) {
      case actionEnums.RELATED_ITEM_SR_CREATED:
      case actionEnums.RELATED_ITEM_SR_DELETED: {
        return 'srId';
      }
      case actionEnums.RELATED_ITEM_ASSET_CREATED:
      case actionEnums.RELATED_ITEM_ASSET_DELETED:
      case actionEnums.RELATED_ITEM_CI_CREATED:
      case actionEnums.RELATED_ITEM_CI_DELETED: {
        return 'value';
      }
      default:
        return 'srId';
    }
  };

  const relatedItems = extractRelatedItmesArray(auditLog?.logInformation);
  const key = handleKeyCaption(auditLog?.logType);

  return (
    <StyledRelatedItemsEvent>
      {relatedItems?.length && (
        <MultiSelectChipList
          itemList={relatedItems}
          selectedItemList={relatedItems?.map((item) => item?.[key])}
          readOnly
          captionString="chipCaption"
          keyString={key}
          withTruncatedChips
          skipValidation
          noPadding
        />
      )}
    </StyledRelatedItemsEvent>
  );
};
