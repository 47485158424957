import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    getColumnText: (displayKey) => texts?.[displayKey],
    clearText: texts?.['spaces.select.clearAll'],
    allStatuses: texts?.['spaces.filters.allStatuses'],
    statusFilterTitle: texts?.['spaces.filters.status.title'],
    allAssignees: texts?.['spaces.filters.allAssignees'],
    selectAll: texts?.['spaces.searchableSetSelect.selectAll'],
    loginUserText: texts?.['spaces.header.current-assigned.loginUserText'],
    defaultSearchPlaceholder: texts?.['spaces.header.current-assigned.defaultSearchPlaceholder'],
    filterByText: texts?.['spaces.filters.filterBy'],
    unassignedText: texts?.['spaces.header.current-assigned.unassigned'],
    fromText: texts?.['spaces.datePicker.range.from'],
    toText: texts?.['spaces.datePicker.range.to'],
    clearAllText: texts?.['spaces.filters.clearAll'],
    noValue: texts?.['spaces.categories.noValue'],
    searchTextPlaceholder: `${texts?.['spaces.select.info.searchPlaceholder']}...`,
    queueSearchTextHint: texts?.['spaces.filters.queueSearchTextHint'] || 'Search in: ID, Title, Description, Notes.',
  };

  return componentTexts;
}
