import { useRbTexts } from 'remote-state/applicationHooks';

const useTexts = () => {
  const texts = useRbTexts();
  const componentTexts = {
    title: texts?.['spaces.resolutionPanel.journey.activity.title'] ?? 'Activity',
    responsibleUser: texts?.['spaces.resolutionPanel.journey.activity.responsibleUser'] ?? 'Responsible User',
    startTime: texts?.['spaces.resolutionPanel.journey.activity.startTime'] ?? 'Start Time',
    endTime: texts?.['spaces.resolutionPanel.journey.activity.endTime'] ?? 'End Time',
    totalTime: texts?.['spaces.resolutionPanel.journey.activity.totalTime'] ?? 'Total Time',
    total: texts?.['spaces.resolutionPanel.journey.activity.total'] ?? 'Total',
    description: texts?.['spaces.resolutionPanel.journey.activity.description'] ?? 'Description',
    placeholder: texts?.['spaces.resolutionPanel.journey.activity.placeholder'] ?? 'Write your description here...',
    setTime: texts?.['spaces.resolutionPanel.journey.activity.setTime'] ?? 'Set Time',
    submit: texts?.['spaces.login.submit'] ?? 'Submit',
  };

  return componentTexts;
};

export default useTexts;
