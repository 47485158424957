import { useSelector } from 'react-redux';
import { useMemo, useRef } from 'react';

import { selectShowErrorModal } from '../../../../features/queue/slice';

import { StyledDropDownDivMenu, StyledDropDownMenu } from './MenuContainer.styles';

export const MenuContainer = ({
  anchor,
  handleClose,
  children,
  withTitle,
  MenuProps,
  isWideDropdown,
  isHeaderHidden,
}) => {
  const showErrorModal = useSelector(selectShowErrorModal);
  const dropDownRef = useRef(null);

  const isBottomPosition = useMemo(() => {
    if (anchor) {
      return window.innerHeight - anchor?.getBoundingClientRect().top <= 350; // 350 Max drop-down height
    }
    return false;
  }, [anchor]);

  if (anchor === undefined) {
    return (
      <StyledDropDownDivMenu withTitle={withTitle} isWideDropdown={isWideDropdown}>
        {children}
      </StyledDropDownDivMenu>
    );
  }

  return (
    <StyledDropDownMenu
      ref={dropDownRef}
      id="drop-down-popover"
      anchorEl={anchor}
      isHeaderHidden={isHeaderHidden}
      open={Boolean(anchor) && !showErrorModal}
      onClose={handleClose}
      isWideDropdown={isWideDropdown}
      anchorOrigin={{ vertical: isBottomPosition ? 'top' : 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: isBottomPosition ? 'bottom' : 'top', horizontal: 'left' }}
      {...MenuProps}
    >
      {children}
    </StyledDropDownMenu>
  );
};
