import { initToolbarButtonsPopup, showPopup, hidePopup } from './utils';

const initialDirectionTool = ({ editor, templateName, pluginName }) => {
  const initPopup = () => {
    initToolbarButtonsPopup(editor, templateName, editor.opts.directionToolPopupButtons);
  };

  const showDirectionToolPopup = () => {
    showPopup(editor, templateName, pluginName, initPopup);
  };

  const hideDirectionToolPopup = () => {
    hidePopup(editor, templateName);
  };

  return {
    initPopup,
    showDirectionToolPopup,
    hideDirectionToolPopup,
  };
};

export { initialDirectionTool };
