import { Accordion } from 'common/components/accordion';
import { styled } from '@mui/material/styles';

export const StyledAccordion = styled(Accordion)(({ theme }) => {
  const borderColor = theme.palette.grey.divider_grey_v1;
  return {
    position: 'relative',
    background: 'transparent',
    paddingInlineStart: '16px',
    '&& .MuiAccordionDetails-root': {
      marginLeft: '40px',
      border: `1px solid ${borderColor}`,
      borderBottomLeftRadius: '10px',
      borderBottomRightRadius: '10px',
      padding: 0,
      borderTop: 0,
    },
    '&.MuiPaper-root': {
      '&.action-item': {
        '&:before': {
          opacity: 0,
        },
        '&:after': {
          content: '""',
          position: 'absolute',
          display: 'flex',
          height: '100%',
          top: '56px',
          left: '32px',
          borderLeft: `1px solid ${borderColor}`,
          opacity: 1,
        },
        '&:last-child:before': {
          content: '""',
          position: 'absolute',
          display: 'flex',
          width: '16px',
          top: '56px',
          left: '17px',
          height: '21px',
          borderRight: `1px solid ${borderColor}`,
          borderBottom: `1px solid ${borderColor}`,
          borderBottomRightRadius: '10px',
          background: 'transparent',
          opacity: 1,
        },
        '&:last-child:after': {
          content: '""',
          position: 'absolute',
          display: 'flex',
          width: '17px',
          top: '76px',
          left: 0,
          height: '22px',
          borderLeft: `1px solid ${borderColor}`,
          borderTop: `1px solid ${borderColor}`,
          borderTopLeftRadius: '10px',
          background: 'transparent',
          opacity: 1,
        },
        '&.MuiAccordion-root': {
          paddingBlock: '4px',
          paddingInlineEnd: '14px',
          '.MuiAccordionSummary-root': {
            padding: '0px',
            margin: '0px',
            paddingBlock: '0px',

            '&.Mui-expanded': {
              minHeight: 'auto',
            },
            '&.Mui-disabled': {
              opacity: 1,
            },
          },
          '& .Mui-focusVisible': {
            backgroundColor: 'transparent',
          },
          '.MuiAccordionSummary-content': {
            border: `1px solid ${borderColor}`,
            padding: '0 32px',
            borderRadius: '6px',
            height: '73px',
          },

          '&.Mui-disabled': {
            backgroundColor: 'transparent',
            '.MuiAccordionSummary-content': {
              background: theme?.palette?.background?.pages_background,
            },
          },
          '&:not(.Mui-expanded) .MuiAccordionSummary-content:hover': {
            background: theme.palette.grey.bt_alt_grey_light,
          },
          '&.Mui-expanded': {
            margin: 0,
            '&:last-child:before': {
              top: '14%',
              height: '85%',
            },
            '&:last-child:after': {
              top: '98.8%',
            },
            '.MuiAccordionSummary-expandIconWrapper': {
              transform: 'rotate(0deg)',
            },
            '.MuiAccordionSummary-content': {
              borderBottom: 0,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            },
          },
        },
      },
    },
  };
});
