import { ButtonUnstyled } from '@mui/core';
import { styled } from '@mui/material/styles';

export const StyledAssigneeItem = styled(ButtonUnstyled)(({ theme }) => ({
  all: 'unset',
  flexGrow: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '184px',
  '&&': {
    '.text': {
      flexGrow: 0,
      fontSize: '14px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.43,
      letterSpacing: 'normal',
      textAlign: 'left',
      color: theme.palette.text.title_txt,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: 'inline-block',
      whiteSpace: 'nowrap',
      maxWidth: '122px',
      padding: '0px 8px 0px 6px',
    },
    '.btn': {
      all: 'unset',
      marginLeft: 'auto',
      float: 'right',
      cursor: 'pointer',
    },
    '.icon': {
      marginLeft: 'auto',
      float: 'right',
      width: '16px',
      height: '16px',
      color: theme.palette.generic.sysaid,
      objectFit: 'contain',
    },
  },
}));
