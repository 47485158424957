import Froalaeditor from 'froala-editor';
import { icons } from './icons';

Froalaeditor.DefineIconTemplate('custom', '<img src=[SRC] alt="" class="fr-toolbar-icon"/>');

Froalaeditor.ICON_DEFAULT_TEMPLATE = 'custom';

icons.forEach((item) => {
  Froalaeditor.DefineIcon(item.name, {
    NAME: item.name,
    SRC: item.icon,
  });
});
