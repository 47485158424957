import { HeaderWrapper, HeaderTitle, HeaderIndicators, HeaderDetails, HeaderAssignee } from './style';

export default function ActionItemHeader({ title, indicators, assignee }) {
  return (
    <HeaderWrapper data-cy="ai-header">
      <HeaderDetails>
        <HeaderTitle data-testid="action-item-title">{title}</HeaderTitle>
        {assignee && <HeaderAssignee>{assignee}</HeaderAssignee>}
      </HeaderDetails>
      <HeaderIndicators>{indicators}</HeaderIndicators>
    </HeaderWrapper>
  );
}
