import { useEffect, useState, useRef } from 'react';
import { debounce } from 'lodash-es';

import { StyledWrapper, StyledCount, StyledDisplayedValues } from './style';

export const InlineArray = ({ values, shouldShowAll = false }) => {
  const countRef = useRef(null);
  const valuesContainerRef = useRef();
  const [hiddenValuesLength, setHiddenValuesLength] = useState(0);
  const [shouldRecalculateValuesList, setShouldRecalculateValuesList] = useState(false);

  const listener = debounce(() => {
    setShouldRecalculateValuesList(true);
  }, 300);

  useEffect(() => {
    window.addEventListener('resize', listener, false);
    return () => {
      document.removeEventListener('resize', listener);
    };
  }, [listener]);

  useEffect(() => {
    setShouldRecalculateValuesList(true);
  }, [valuesContainerRef, values]);

  useEffect(() => {
    const container = valuesContainerRef.current;
    if (shouldRecalculateValuesList && container) {
      const childElements = Array.from(container.children);
      const overflowedItems = childElements.filter(
        (element) => element.offsetTop + element.clientHeight > container.clientHeight,
      );
      setHiddenValuesLength(overflowedItems.length);
      setShouldRecalculateValuesList(false);
    }
  }, [shouldRecalculateValuesList, shouldShowAll, values]);

  const displayValue = ({ value, index, hasSeparator }) => {
    let displayedValue;
    if (value.type === 'user') {
      displayedValue = value.name || value.calculatedUserName;
    } else {
      displayedValue = value.name || value.groupName;
    }
    if (hasSeparator) {
      return displayedValue?.concat(index === values.length - hiddenValuesLength - 1 ? '' : ',');
    }
    return displayedValue;
  };

  return (
    <StyledWrapper shouldShowAll={shouldShowAll}>
      <StyledDisplayedValues className="inline-array-values" ref={valuesContainerRef}>
        {!!values?.length &&
          values.map((value, index) => (
            <span key={value.id}>{displayValue({ value, index, hasSeparator: true })}</span>
          ))}
      </StyledDisplayedValues>
      {!shouldShowAll && !!hiddenValuesLength && (
        <div className="remaining-values-wrapper" ref={countRef} role="none">
          <StyledCount>{`+${hiddenValuesLength}`}</StyledCount>
        </div>
      )}
    </StyledWrapper>
  );
};
