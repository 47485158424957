import { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from 'react';
import { DialogTitle, DialogContent, DialogActions, DialogProps } from '@mui/material';
import ButtonBase from 'common/components/buttons/FormButton';
import { StyledDialog } from './styled';

interface Action {
  label: string;
  onClick: () => void;
  btnTheme?: string;
  disabled?: boolean;
  icon?: any;
}

interface CustomDialogProps extends Omit<DialogProps, 'title'> {
  title: string;
  actions: Action[];
}

interface CustomDialogMethods {
  open: () => void;
  close: () => void;
}

const CustomDialog: ForwardRefRenderFunction<CustomDialogMethods, CustomDialogProps> = (
  { title, children, actions, ...rest },
  ref,
) => {
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
    },
    close: () => {
      setOpen(false);
    },
  }));

  if (!open) return null;

  return (
    /* eslint-disable react/jsx-filename-extension */
    <StyledDialog {...rest} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ padding: '0px 20px' }}>{children}</DialogContent>
      <DialogActions sx={{ padding: '20px' }}>
        {actions.map((button, index) => {
          const { onClick, label, disabled = false, btnTheme = 'secondary', icon = null, ...rest } = button;
          return (
            <ButtonBase
              icon={icon}
              disabled={disabled}
              btnTheme={btnTheme}
              key={index}
              onClick={onClick}
              text={label}
              {...rest}
            />
          );
        })}
      </DialogActions>
    </StyledDialog>
  );
};

export default forwardRef(CustomDialog);
