import { useCallback, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { isNone } from 'common/utils/utils';
import { selectActiveUser } from 'store/userSlice';
import { WORFKLOWS_CONSTANTS } from '../../../constants';

const getAssigneeInfo = (fields, fieldName, loggedInUser, actionItem) => {
  const actionItemDisabledTypes = WORFKLOWS_CONSTANTS.ACTION_ITEM_DISABLED_TYPES;

  const field = fields?.find((f) => f.fieldName === fieldName);
  const assignedToCurrentUser = field?.value === loggedInUser;
  const hasPermissionIssue =
    actionItem.disabled?.reason === actionItemDisabledTypes.INSUFFICIENT_PERMISSIONS && !assignedToCurrentUser;

  return {
    exists: !!field,
    required: field?.required ?? false,
    value: field?.value,
    assignedToCurrentUser,
    permissionGranted: !hasPermissionIssue,
  };
};

const validityReducer = (state, action) => {
  switch (action.type) {
    case 'SET_VALIDITY':
      return { ...state, [action.payload.id]: action.payload.isValid };
    default:
      return state;
  }
};

function isAssigneeReadOnly(actionItem) {
  return actionItem.fields?.some(
    (field) =>
      (field.fieldName === WORFKLOWS_CONSTANTS.FIELDS.ASSIGNED_TO.NAME ||
        field.fieldName === WORFKLOWS_CONSTANTS.FIELDS.ASSIGNED_GROUP.NAME) &&
      field.readOnly === true,
  );
}

const useFormValidation = ({ actionItem, isViewer }) => {
  const [actionItemValidity, dispatchValidity] = useReducer(validityReducer, {});
  const userAccount = useSelector(selectActiveUser);

  const handleFormErrors = useCallback(({ isValid, actionItemId }) => {
    dispatchValidity({ type: 'SET_VALIDITY', payload: { id: actionItemId, isValid } });
  }, []);

  const assignedToInfo = getAssigneeInfo(actionItem.fields, 'assignedTo', userAccount?.username, actionItem);
  const assignedGroupInfo = getAssigneeInfo(actionItem.fields, 'assignedGroup', userAccount?.username, actionItem);

  const hasAssigneeField = (assignedToInfo.exists || assignedGroupInfo.exists) && assignedToInfo.permissionGranted;

  const isAssigneeRequired = assignedToInfo.required || assignedGroupInfo.required;
  const assigneesReadOnly = isAssigneeReadOnly(actionItem);

  const isAssigneeValid = isAssigneeRequired ? !isNone(actionItem.assignedGroup) || !isNone(actionItem.assignee) : true;
  const isActionItemValid = actionItemValidity[actionItem.id];

  const isGloballyDisabled = isViewer || actionItem.readOnly || actionItem.disabled.value;
  const isInvalid = !actionItem.completed && (!isActionItemValid || !isAssigneeValid);

  const isButtonDisabled = isGloballyDisabled || isInvalid;
  const showError = !isGloballyDisabled && isInvalid;
  const isFormEditable = !isGloballyDisabled && !actionItem.completed && !actionItem.readOnly;

  return {
    handleFormErrors,
    hasAssigneeField,
    isAssigneeRequired,
    isAssigneeValid,
    showError,
    isFormEditable,
    isButtonDisabled,
    assigneesReadOnly,
  };
};

export default useFormValidation;
