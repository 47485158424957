import { memo } from 'react';
import Tooltip from 'common/components/tooltip';
import NavLink from 'features/navBar/navLink';
import { CLASSIC_ROUTE, DOCS_ROOT_URL, SPACES_DOCS_URL_MAP, CLASSIC_DOCS_URL_MAP } from './constants';
import NavSubListItem from '../navSubListItem';
import { getResourceInfo } from '../../../../../externalPage';

const getDocsUrl = () => {
  const { path: documentationPath } = getResourceInfo();
  const currentLocation = window.location.pathname;
  //If current page is iframe that show classic page then we need to find doc url accoring to query param
  if (currentLocation === CLASSIC_ROUTE) {
    return `${DOCS_ROOT_URL}${CLASSIC_DOCS_URL_MAP.find((doc) => documentationPath.includes(doc.path))?.url ?? ''}`;
  }
  return `${DOCS_ROOT_URL}${SPACES_DOCS_URL_MAP[currentLocation] ?? ''}`;
};

function DocumentationNavLink({ icon, title, closeMenu }) {
  const url = getDocsUrl();

  return (
    <NavLink className="link" to={url} isOpenInNewTab>
      <NavSubListItem handleClose={closeMenu}>
        {icon && <span className="icon">{icon}</span>}
        <Tooltip isTruncatedText text={title} placement="top" className="menu-item" />
      </NavSubListItem>
    </NavLink>
  );
}
export default memo(DocumentationNavLink);
