import { Accordion } from 'common/components/accordion';
import { styled } from '@mui/material/styles';

export const StyledAccordion = styled(Accordion, {
  shouldForwardProps: (prop) => prop !== 'expanded',
})(({ theme }) => {
  const borderColor = theme.palette.grey.divider_grey_v1;
  return {
    position: 'relative',
    background: 'transparent',
    '&.MuiPaper-root': {
      '&.phase': {
        '&.MuiAccordion-root': {
          '&:before': {
            content: '""',
            position: 'absolute',
            top: '44px',
            left: '15.5px',
            display: 'flex',
            width: 0,
            height: '100%',
            borderLeft: `1px solid ${borderColor}`,
            borderBottom: `1px solid ${borderColor}`,
            background: 'transparent',
          },
          '.MuiAccordionSummary-root': {
            padding: '0px',
            margin: '0px',
            paddingBlock: '7px',
            paddingInlineEnd: '8px',
            '&.Mui-expanded': {
              minHeight: 'auto',
            },
          },
          '.MuiAccordionSummary-content': {
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            gap: '12px',
            margin: 0,
            padding: 0,
            paddingInlineEnd: '8px',
          },
          '&.Mui-expanded': {
            margin: 0,
            '.MuiAccordionSummary-expandIconWrapper': {
              transform: 'rotate(90deg)',
            },
            '&:before': {
              opacity: 1,
              borderBottomLeftRadius: '10px',
              borderTop: 'none',
              borderRight: 'none',
              width: '18px',
              height: '18px',
            },
            ':after': {
              content: '""',
              opacity: 1,
              position: 'absolute',
              borderTopRightRadius: '10px',
              borderTop: `1px solid ${borderColor}`,
              borderRight: `1px solid ${borderColor}`,
              width: '16px',
              height: '22px',
              top: '61.25px',
              left: '33.5px',
            },
          },
        },
        '&:not(.Mui-expanded)': {
          '&:last-of-type:before': {
            border: 'none',
          },
        },
        '&:last-of-type .action-item:last-child:before': {
          border: 'none',
        },
        '&:last-of-type .action-item:last-child:after': {
          border: 'none',
        },
      },
    },

    '.MuiButtonBase-root': {
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      gap: '8px',
    },

    '.MuiAccordionSummary-content': {
      display: 'grid',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: 0,
      marginTop: '8px',
      '&.Mui-expanded': {
        margin: 0,
        marginTop: '8px',
      },
    },

    '.MuiAccordionDetails-root': {
      padding: '0px 0px 0px 16px',
    },
  };
});
