import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

export const StyledHeader = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFloatingTicketPanel' && prop !== 'isRelatedItemsSelectedTab',
})(({ theme, isFloatingTicketPanel, isRelatedItemsSelectedTab }) => ({
  overflowX: isFloatingTicketPanel && 'hidden',
  backgroundColor: isFloatingTicketPanel
    ? theme?.palette.grey?.pages_background
    : theme?.palette.background?.pages_background,
  paddingTop: isFloatingTicketPanel ? '0' : '2px',
  margin: !isFloatingTicketPanel && '0 0 10px 6px',
  display: 'flex',
  flexDirection: 'column',
  '& .sr-id': {
    flexGrow: 0,
    fontSize: theme.fontSize.small,
    fontFamily: theme.fonts.robotoRegular,
    textAlign: 'left',
    lineHeight: '20px',
    color: 'rgba(48, 48, 48, 0.85)',
  },

  '& .sr-lock-header': {
    zIndex: 5,
    left: '0',
    right: '0',
    margin: '0 auto',
    width: 'max-content',
  },

  '& .next-or-prev-sr': {
    height: '14px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '8px',
    padding: 0,
    marginLeft: 'auto',
  },

  '& .next-or-prev-sr span': {
    fontSize: '12px',
  },

  '& .next-or-prev-sr button': {
    backgroundColor: theme.palette.grey.scroll,
    borderRadius: '4px',
    width: '24px',
    height: '24px',
    textAlign: 'center',
    border: 'none',
    outline: 'none',
    ':hover': {
      backgroundColor: theme.palette.grey.divider_grey_v1,
      cursor: 'pointer',
    },
  },

  '& .next-or-prev-sr button[disabled]': {
    backgroundColor: theme.palette.grey.scroll,
    ':hover': {
      cursor: 'not-allowed',
    },
  },

  '& .next-or-prev-sr svg': {
    top: '25%',
    left: '25%',
  },

  '& .incident-details-header': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    position: 'relative',
    gap: '4px',
    alignItems: 'flex-end',
  },

  '.header-footer-left': {
    ...(
      isFloatingTicketPanel 
      ? { maxWidth: '50%', }  
      : { flex: '0 0 41%', }
    ),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    position: 'relative',
    alignItems: 'flex-end',
  },
  '.linked-sr-indicator-wrapper': {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    height: '20px',
    color: 'white',
    background: theme?.palette?.grey?.bt_def_grey_reg,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '6px',
    borderRadius: '2px',
    cursor: isRelatedItemsSelectedTab ? 'default' : 'pointer',
    marginLeft: '12px',
    ':hover': {
      background: !isRelatedItemsSelectedTab && theme?.palette?.grey?.bt_def_grey_hover,
    },
  },
  '.link-type': {
    fontSize: '12px',
    lineHeight: '16px',
  },

  '& .input-title > input': {
    padding: '4px 8px',
    fontFamily: theme.fonts.robotoRegular,
  },

  '& .input-title > * .input-title-validation > * .input-title-new > *': {
    height: '24px',
    fontSize: '20px',
    textAlign: 'left',
    color: theme.palette.text.autocompleteSecondary,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    lineHeight: '24px',
    maxWidth: '730px',
    minWidth: '400px',
  },

  '& .input-title-validation > *::placeholder': {
    color: theme.palette.labels.bt_red_reg,
    borderBottom: `1px solid ${theme.palette.labels.bt_red_reg}`,
  },

  '& .input-title-new > *': {
    backgroundColor: theme.palette.generic.primary,
    color: theme.palette.text.secondary_txt,
  },

  '& .input-title-validation > *:focus .input-title-new > *:focus': {
    outline: 'none',
  },

  '& .input-title > *:focus': {
    outline: 'none',
    backgroundColor: theme.palette.hover.avatar,
    borderBottom: `1px solid ${theme.palette.text.autocompleteSecondary}`,
  },

  '& .header-footer-div': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
    margin: '0 25px 0 0',
    padding: 0,
    gap: '24px',
    p: {
      fontSize: '12px',
      lineHeight: '16px',
    },
    '&.floating-ticket': {
      marginRight: '8px',
      width: '100%',
      alignItems: 'center',
      flexShrink: 'inherit',
      flexWrap: 'nowrap',
      overflow: 'hidden',
      gap: '8px',
    },
    '& > div.floating-ticket': {
      maxWidth: '23%',
    },
    '& span.floating-ticket': {
      display: 'none',
    },

    '@media only screen and (max-width: 1024px)': {
      width: '50%',
      '& .categories > div': {
        width: 'calc(100% / 3 - 8px)',
      },
    },
  },

  '& .header-footer': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '28px',
  },

  '& .background-medium': {
    backgroundColor: theme.palette.labels.bt_yellow_reg,
  },

  '& .background-high': {
    backgroundColor: theme.palette.labels.bt_red_reg,
  },
  '& .background-low': {
    backgroundColor: theme?.palette?.blueLabels?.bt_light_blue_reg,
  },

  '& .btn-prev-next': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3px',
    height: '22px',
    width: '22px',
  },

  '& .btn-prev-next:hover': {
    cursor: 'pointer',
    backgroundColor: theme.palette.background.grey_hover,
    borderRadius: '4px',
    '& path': {
      fill: theme.palette.text.title_txt,
    },
  },

  '& .footer-right': {
    ...(!isFloatingTicketPanel && { flex: '0 0 59%', }),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    gap: '24px',
    width: '-webkit-fill-available',
    '& .assignee-label-wrap': {
      maxWidth: isFloatingTicketPanel && 'calc(100% - 162px)',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'center',
    },

    '@media only screen and (min-width: 1621px)': {
      maxWidth: isFloatingTicketPanel ? '50%' : '59%',
      '& .assignee-label-wrap': {
        maxWidth: isFloatingTicketPanel ? 'calc(100% - 162px)' : '80%',
      },
    },
    '@media only screen and (max-width: 1620px)': {
      maxWidth: isFloatingTicketPanel ? '50%' : '60%',
      '& .assignee-label-wrap': {
        maxWidth: isFloatingTicketPanel ? 'calc(100% - 162px)' : 'calc(100% - 170px)',
      },
    },
    '@media only screen and (max-width: 1280px)': {
      '& .assignee-label-wrap': {
        maxWidth: isFloatingTicketPanel ? 'calc(100% - 127px)' : 'calc(100% - 200px)',
      },
    },
    '& .assignees': {
      height: 'auto',

      '& .assignee-open-popup-button': {
        maxWidth: '100%',
      },
      '& .current-assignee': {
        maxWidth: 'calc(100% - 32px)',
        width: '100%',
      },
    },
    '&.floating-ticket': {
      gap: '12px',
      'span.floating-ticket': {
        display: 'none',
      },
    },
  },

  '& .share': {
    width: '25px',
    height: '25px',
    margin: '1px 20px 0 21px',
  },

  '& .middle-header': {
    marginBottom: isFloatingTicketPanel ? '12px' : '16px',
    display: 'flex',
    flexDirection: 'row',
    maxWidth: isFloatingTicketPanel ? '100%' : 'calc(100% - 80px)',
    input: {
      fontFamily: theme.fonts.robotoLight,
      fontSize: '20px',
      lineHeight: '24px',
      maxHeight: '32px',
      height: '32px',
      paddingLeft: '4px !important',
    },
  },

  '& .status': {},
}));

export const LabelWrap = styled(Box)(() => ({
  '&>.MuiBox-root': {
    marginLeft: '0px',
  },
}));

export const StyledFlexibleContent = styled(Box)(() => ({
  maxWidth: '100%',
  boxSizing: 'initial',
}));

export const StyledStatusActionsContainer = styled(Box)(() => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  display: 'flex',
  gap: '17px',
}));

export const HeaderItemLabel = styled('span')(({ theme }) => ({
  fontSize: theme?.fontSize?.small,
  color: theme.palette.text.secondary_txt,
  display: 'flex',
  lineHeight: '14px',
  marginBottom: '4px',
  padding: '2px 6px',
  '&.assignee-label': {
    marginBottom: '2px',
  },
}));

export const StyledRightButtons = styled(Box)(() => ({
  display: 'flex',
  alignSelf: 'stretch',
  alignItems: 'flex-end',
  paddingTop: '16px',
  gap: '10px',
}));

export const InlineFieldContainerWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isFloatingTicketPanel',
})(({ isFloatingTicketPanel }) => ({
  div: {
    width: isFloatingTicketPanel && '100%',
  },
}));

export const headerActionsMenuStyles = {
  ul: {
    width: '298px',
    li: {
      borderRadius: '4px',
      padding: '8px',
      margin: '0 8px',
      '&:not(:last-child)': {
        marginBottom: '4px',
      },
    },
    '& .MuiDivider-root': {
      borderColor: '#EDEFF1',
    },
  },
};
