import { useCallback, useEffect, useState, useMemo } from 'react';

import { actionEnums, RICH_TEXT_TYPES } from 'features/resolutionPanel/middlePanel/auditLog/constants/actionEnums';
import { useSelector } from 'react-redux';
import { useRouterState } from '@tanstack/react-router';
import { ROUTES_PATHS } from 'constants/index';
import { selectActionLine } from '../../../store/slice';

const REGULAR_FIELDS = [
  actionEnums.FIELD_REGULAR,
  actionEnums.FIELD_DESCRIPTION,
  actionEnums.ACTION_ITEM_FIELD_CHANGE,
  actionEnums.ACTION_ITEM_STATUS_COMPLETED,
  actionEnums.ACTION_ITEM_STATUS_DISABLED,
  actionEnums.ACTION_ITEM_STATUS_ENABLED,
];

export const useDisplayTextTruncation = (props) => {
  const { divRef, logType, textTruncateEnabled, maxRowsAmount = 2, isRichText = false } = props;
  const [showTruncationButton, setShowTruncationButton] = useState(false);
  const { activeIndex, isExpanded } = useSelector(selectActionLine);
  const routerState = useRouterState();
  const { pathname } = routerState.location;

  const htmlDivRefElement = divRef?.current;
  const htmlElementsLineHeigth = htmlDivRefElement ? window?.getComputedStyle(htmlDivRefElement).lineHeight : 0;
  const containers = htmlDivRefElement?.querySelectorAll('.fr-view');
  const isTemplatePage = pathname === ROUTES_PATHS.TEMPLATE_URL;

  const maxHeight = useMemo(
    () =>
      (containers?.length ? [...containers] : []).reduce(
        (acc, container) => {
          // check if the field is a rich text field and if the text exceed maxRowsAmount lines -> truncate the text and display truncating button
          if (isRichText && containers) acc = Math.max(container?.scrollHeight || 0, acc);

          return acc;
        },
        isRichText ? 0 : htmlDivRefElement?.offsetHeight,
      ),
    [containers, isRichText, htmlDivRefElement],
  );

  const toggleDisplayTextTruncatingButton = useCallback(() => {
    const potentialRowsAmount = maxHeight / parseInt(htmlElementsLineHeigth, 10);
    setShowTruncationButton(potentialRowsAmount > maxRowsAmount);
  }, [maxHeight, htmlElementsLineHeigth, maxRowsAmount]);

  const toggleDisplayTextTruncatingDefaultType = useCallback(() => {
    if (htmlDivRefElement) {
      let shouldShowed = false;
      const container = htmlDivRefElement?.querySelector('.fr-view');
      if (container === null) return;
      if (container?.classList?.contains('fr-element')) return; // default action line, log hasn't been rendered yet
      if (container?.querySelectorAll('li')?.length > maxRowsAmount) {
        setShowTruncationButton(true);
        return;
      }
      const containerParagraphs = container.querySelectorAll('p');
      let rows = 0;
      // iterate over the paragraphs and calculate the number of rows for each paragraph(offsetHeight/lineHeight)
      containerParagraphs.forEach((paragraph) => {
        const maxLineHeight = parseInt(window.getComputedStyle(paragraph).fontSize, 10);
        if (paragraph.innerHTML.includes('<img')) {
          const image = paragraph.querySelector('.fr-fil') || { scrollHeight: 0 };
          rows += Math.floor((paragraph.scrollHeight - image.scrollHeight) / maxLineHeight) || 1;
        } else {
          rows += Math.floor(paragraph.scrollHeight / maxLineHeight) || 1;
        }
      });
      if ([actionEnums.MESSAGE_SENT, actionEnums.MESSAGE_RECEIVED].includes(logType)) {
        const remainingValuesWrapper = htmlDivRefElement?.querySelectorAll('.remaining-values-wrapper');
        const inlineArrayValues = htmlDivRefElement?.querySelectorAll('.inline-array-values');
        inlineArrayValues.forEach((inlineArray) => {
          const maxLineHeight = 20;
          shouldShowed =
            Math.floor(inlineArray.scrollHeight / maxLineHeight) > 1 ||
            inlineArray.offsetWidth !== inlineArray.scrollWidth ||
            !!remainingValuesWrapper.length;
        });
      }
      setShowTruncationButton(shouldShowed || rows > maxRowsAmount);
    }
  }, [maxRowsAmount, htmlDivRefElement, logType]);

  useEffect(() => {
    if (!isTemplatePage) {
      if (REGULAR_FIELDS.includes(logType) || textTruncateEnabled) {
        toggleDisplayTextTruncatingButton();
      } else if (RICH_TEXT_TYPES.includes(logType) && activeIndex === -1 && !isExpanded) {
        toggleDisplayTextTruncatingDefaultType();
      }
    }
  }, [
    logType,
    isExpanded,
    activeIndex,
    toggleDisplayTextTruncatingButton,
    toggleDisplayTextTruncatingDefaultType,
    textTruncateEnabled,
    isTemplatePage,
  ]);

  return {
    showTruncationButton,
    setShowTruncationButton,
  };
};
