import { isEqual } from 'lodash-es';

const updateState = (payload, state) => {
  const { list, listItem, operation, ...data } = payload;
  let newState = {};
  if (list) {
    switch (operation) {
      case 'remove':
        newState[list] = state[list].filter((item) => !isEqual(item, listItem));
        break;
      case 'overwrite':
        newState[list] = listItem;
        break;
      default:
        newState[list] = [...(state[list] || []), listItem];
        break;
    }
  }
  newState = { ...newState, ...data };
  return newState;
};

export { updateState };
