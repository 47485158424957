const initToolbarButtonsPopup = (editor, templateName, popupButtonsEditor) => {
  let popupButtons = '';

  // Create the list of buttons.
  if (popupButtonsEditor.length > 1) {
    popupButtons += '<div class="fr-buttons fr-buttons-column">';
    popupButtons += editor.button.buildList(popupButtonsEditor);
    popupButtons += '</div>';
  }

  const template = {
    buttons: popupButtons,
  };

  const popup = editor.popups.create(templateName, template);
  return popup;
};

const showPopup = (editor, templateName, pluginName, initPopup) => {
  let popup = editor.popups.get(templateName);

  if (!popup) popup = initPopup();

  editor.popups.setContainer(templateName, editor.$tb);

  const button = editor.$tb.find(`.fr-command[data-cmd="${pluginName}"]`);

  // Set the popup's position.
  const left = button.offset().left;
  const top = button.offset().top + (editor.opts.toolbarBottom ? 10 : button.outerHeight() - 10);

  editor.popups.show(templateName, left, top, button.outerHeight());
};

const hidePopup = (editor, templateName) => {
  editor.popups.hide(templateName);
};

export { initToolbarButtonsPopup, showPopup, hidePopup };
