import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts(srId) {
  const texts = useRbTexts();
  const componentTexts = {
    archiveTicketButtonText: texts?.['spaces.header.actions.archiveTicket.archive.button'] || 'Archive',
    archiveTicketConfirmText: texts?.['spaces.header.actions.archiveTicket.archive.confirm'] || 'Archive',
    archiveTicketCancelText: texts?.['spaces.header.actions.archiveTicket.archive.cancel'] || 'Cancel',
    archiveTicketWarningMessageTitle: texts?.['spaces.header.actions.archiveTicket.warningMessage.archive.title'] ||
      `Archive Service Record #${srId}?`,
    archiveTicketWarningMessageText: texts?.['spaces.header.actions.archiveTicket.warningMessage.archive.text'] ||
      'This Service Record will be removed from active queue. You can find it in the "Archived" queue view.',
    archiveTicketMessageText: texts?.['spaces.header.actions.archiveTicket.archive.message'] ||
      `Service Record #${srId} archived`,
    archiveTicketErrorMessageText: texts?.['spaces.header.actions.archiveTicket.archive.message.error'] ||
      `Couldn't archive Service Record. Please try again`,
    restoreTicketButtonText: texts?.['spaces.header.actions.archiveTicket.restore.button'] || 'Restore',
    restoreTicketConfirmText: texts?.['spaces.header.actions.archiveTicket.restore.confirm'] || 'Restore',
    restoreTicketWarningMessageTitle: texts?.['spaces.header.actions.archiveTicket.warningMessage.restore.title'] ||
      `Restore Service Record #${srId}?`,
    restoreTicketWarningMessageText: texts?.['spaces.header.actions.archiveTicket.warningMessage.restore.text'] ||
      'This Service Record will be removed from "Archived" view. You can find it in the active queue view.',
    restoreTicketMessageText: texts?.['spaces.header.actions.archiveTicket.restore.message'] ||
      `Service Record #${srId} restored`,
    restoreTicketErrorMessageText: texts?.['spaces.header.actions.archiveTicket.restore.message.error'] ||
      `Couldn't restore Service Record. Please try again`,
    copyURL: texts?.['spaces.header.actions.copyURL'] || 'Copy URL',
  };

  return componentTexts;
}
