import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledResolutionPanelContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  alignItems: 'center',

  '.resolution-panel-no-footer': {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 68px)',
    width: '100%',
    boxSizing: 'border-box',
  },
});
