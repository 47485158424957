const UNASSIGNED_KEY = 'unassigned';
const UNASSIGNED_USERNAME = 'spaces.header.current-assigned.unassigned';
const UNASSIGNED_TITLE = 'spaces.header.current-assigned.unassigned';
const UNASSIGNED_ID = -9999;

export const ASSIGNEE_CONSTANTS = {
  ASSIGNEE_ITEM_HEIGHT: 46,
  DROPDOWN_SCROLL_THRESHOLD: 5,
  DROPDOWN_GROUPS_AND_ADMINS_SCROLL_THRESHOLD: 3,
  DROPDOWN_GROUPS_AND_ADMINS_MAX_ITEMS: 50,
  DROPDOWN_MAX_ITEMS: 100,
  UNASSIGNED: {
    key: UNASSIGNED_KEY,
    title: UNASSIGNED_TITLE,
    userName: UNASSIGNED_USERNAME,
    isUnassigned: true,
    id: UNASSIGNED_ID,
    calculatedUserName: UNASSIGNED_USERNAME,
  },
};
