import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const shouldForwardProp = (prop) => prop !== 'completed';

export const StyledIndicator = styled(Box, { shouldForwardProp })(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '5px',
  gap: '8px',
  borderRadius: '50px',
  width: '26px',
  height: '26px',
  pointerEvents: 'auto',
  cursor: 'pointer',
}));
