export const MY_SETTINGS_ACTIONS = {
  UPDATE_CURRENT_PASSWORD: 'currentPassword',
  UPDATE_PASSWORD: 'password',
  UPDATE_CONFIRM_PASSWORD: 'confirmPassword',
  UPDATE_SETTINGS: 'updatedSettings',
  UPDATE_DIRTY_FIELDS: 'dirtyFields',
  VALIDATE_CURRENT_PASSWORD: 'validateCurrentPassword',
  VALIDATE_PASSWORD: 'validatePassword',
  VALIDATE_PASSWORD_MATCH: 'validatePasswordMatch',
};

export const ME_MENU_ACTIONS = {
  CLOSE_MESSAGE_PROMPT: 'closeMessagePrompt',
  CLOSE_MENU: 'closeMenu',
  MENU_DIRTY: 'menuDirty',
  TOGGLE: 'toggle',
  SAVE: 'save',
  RESET: 'reset',
};
