import Tooltip from 'common/components/tooltip';
import NavLink from 'features/navBar/navLink';
import NavSubListItem from '../navSubListItem';

export default function NavSubListLink({ icon, title, url, isOpenInNewTab, closeMenu }) {
  return (
    <NavLink className="link" to={url} isOpenInNewTab={isOpenInNewTab}>
      <NavSubListItem handleClose={closeMenu}>
        {icon && <span className="icon">{icon}</span>}
        <Tooltip isTruncatedText text={title} placement="top" className="menu-item" />
      </NavSubListItem>
    </NavLink>
  );
}
