import { DatePicker } from 'common/components/sysaidLibrary/DatePicker';
import { SelectField } from 'common/components/sysaidLibrary/SelectField';
import { TextField } from 'common/components/sysaidLibrary/textField';
import { WORFKLOWS_CONSTANTS } from 'features/resolutionPanel/middlePanel/Workflows/constants';
import * as yup from 'yup';

import MultiSelectListAI from 'common/components/sysaidLibrary/MultiSelectFieldAI';
import { AttachmentsWrapper } from '../components/sysaidLibrary/Attachments';

const textFieldValidator = (field, requiredMessage) =>
  field.required ? yup.string().nullable().required(requiredMessage) : yup.string().nullable();

const numberFieldValidator = (field, requiredMessage) =>
  field.required
    ? yup
        .number()
        .integer()
        .transform((value, originalValue) =>
          String(originalValue).trim() === '' || Number.isNaN(value) ? null : value,
        )
        .nullable()
        .required(requiredMessage)
    : yup
        .number()
        .integer()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable();

const floatFieldValidator = (field, requiredMessage) =>
  field.required
    ? yup
        .number()
        .transform((value, originalValue) => {
          if (originalValue === '') return null;
          if (typeof originalValue === 'string' && !Number.isNaN(Number(originalValue))) {
            return parseFloat(originalValue);
          }
          return !Number.isNaN(Number(value)) || value === 0 ? value : null;
        })
        .nullable()
        .required(requiredMessage)
    : yup.number().nullable();

const dateOrTimestampValidator = (field, requiredMessage) => {
  const validator = yup.mixed().test('is-valid-date-or-timestamp', 'Invalid date or timestamp', (value) => {
    if (typeof value === 'string') {
      return !Number.isNaN(Number(Date.parse(value)));
    }
    if (typeof value === 'number') {
      return !Number.isNaN(Number(new Date(value).getTime()));
    }
    return value === null;
  });

  return field.required ? validator.nullable().required(requiredMessage) : validator.nullable();
};

const selectValidator = (field, requiredMessage) =>
  field.required ? yup.mixed().required(requiredMessage).nullable() : yup.mixed().notRequired().nullable();

export const multiSelectValidator = (field, requiredMessage) =>
  field.required
    ? yup
        .string()
        .nullable()
        .test('not-0-or-empty', requiredMessage, (value) => value !== null && value !== '0' && value !== '')
        .required(requiredMessage)
    : yup.string().nullable();

export const createSchema = (fieldsConfig, requiredMessage) =>
  yup.object().shape(
    fieldsConfig?.reduce((schemaShape, field) => {
      switch (field.type) {
        case 'text':
        case 'longtext':
          schemaShape[field.fieldName] = textFieldValidator(field, requiredMessage);
          break;
        case 'select':
          if (['assignedTo', 'assignedGroup'].includes(field.fieldName)) return schemaShape;
          schemaShape[field.fieldName] = selectValidator(field, requiredMessage);
          break;
        case 'multiSelect':
          schemaShape[field.fieldName] = multiSelectValidator(field, requiredMessage);
          break;
        case 'number':
          schemaShape[field.fieldName] = numberFieldValidator(field, requiredMessage);
          break;
        case 'float':
          schemaShape[field.fieldName] = floatFieldValidator(field, requiredMessage);
          break;
        case 'date':
        case 'dateAndTime':
          schemaShape[field.fieldName] = dateOrTimestampValidator(field, requiredMessage);
          break;
        default:
          break;
      }
      return schemaShape;
    }, {}),
  );

export const fieldTypeComponentsMapper = ({ type, keyExpr, requestParam, actionItemId }) => {
  switch (type) {
    case 'text':
    case 'longtext':
      return {
        component: TextField,
        props: {
          rootClass: type === 'longtext' ? 'dualColumn' : 'singleColumn',
          multiline: type === 'longtext',
        },
      };
    case 'number':
    case 'float':
      return {
        component: TextField,
        props: {
          rootClass: 'singleColumn',
          type: 'number',
          digitsLimit: WORFKLOWS_CONSTANTS.TEXT_FIELD_DIGITS_LIMIT,
        },
      };
    case 'select':
      return {
        component: SelectField,
        props: {
          keyExpr,
          requestParam,
        },
      };
    case 'multiSelect':
      return {
        component: MultiSelectListAI,
        props: {
          keyExpr,
          requestParam,
        },
      };
    case 'attachment':
      return {
        component: AttachmentsWrapper,
        props: {
          keyExpr,
          requestParam,
          actionItemId,
        },
      };

    case 'date':
    case 'dateAndTime':
      return {
        component: DatePicker,
        props: { showTimePicker: type === 'dateAndTime' },
      };
    default:
      return {};
  }
};
