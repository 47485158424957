import { useSelector } from 'react-redux';
import MessagePrompt from 'common/components/messagePrompt';
import useTexts from 'features/resolutionPanel/useTexts';
import { selectActionLine } from 'features/resolutionPanel/middlePanel/auditLog/store/slice';
import { ReactComponent as WarningIcon } from 'images/icons/WarningSign.svg';
import { ReactComponent as ErrorIcon } from 'images/icons/ErrorSign.svg';

import { StyledActionLinePrompt } from './style';
import { promptTypes } from '../../constants';

const ActionLinePrompt = (props) => {
  const { promptType, onOkClick, onCancelClick, message = null, btnTexts = null } = props;
  const { type: editorType } = useSelector(selectActionLine);
  const texts = useTexts();

  const propsByPromptType = {
    [promptTypes.EXIT_PAGE]: {
      icon: WarningIcon,
      promptIcon: WarningIcon,
      showCancelBtn: true,
    },
    [promptTypes.ERROR]: {
      icon: ErrorIcon,
      promptIcon: WarningIcon,
      showCancelBtn: false,
    },
    [promptTypes.CANCEL]: {
      icon: WarningIcon,
      promptIcon: WarningIcon,
      showCancelBtn: true,
    },
  };

  const activeProps = {
    onOkClick,
    onClose: onCancelClick,
    Icon: propsByPromptType[promptType].promptIcon,
    showCancelBtn: propsByPromptType[promptType].showCancelBtn,
    title: texts.modal[editorType][promptType].title(message),
    btnOkText: texts.modal[editorType][promptType].btnOkText(btnTexts),
    btnCancelText: texts.modal.btnCancelText(btnTexts),
  };

  return (
    <StyledActionLinePrompt>
      <MessagePrompt open {...activeProps}>
        {texts.modal[editorType][promptType].description(message)}
      </MessagePrompt>
    </StyledActionLinePrompt>
  );
};
export default ActionLinePrompt;
