import { useState, memo, useCallback, useMemo } from 'react';
import { InlineFieldContainer } from 'common/components/controls/InlineField/InlineFieldContainer';
import { TitleField } from 'common/components/controls/TitleField/index';
import { useDispatch } from 'react-redux';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { CSS } from '@dnd-kit/utilities';
import { ReactComponent as ToggleIcon } from 'images/icons/accordionToggleButton.svg';
import { ReactComponent as DragIcon } from 'images/icons/gh_dragg.svg';
import { useSortable } from '@dnd-kit/sortable';
import CustomIconButton from 'common/components/buttons/IconButton/index';
import { useRbTexts } from 'remote-state/applicationHooks';
import { ReactComponent as CloseIcon } from 'images/icons/close.svg';
import { StyledTemplateSection } from './StyledTemplateSection';
import TemplateSectionInstructions from '../TemplateSectionInstructions';
import useTexts from '../useTexts';
import SectionFieldsArea from './SectionFieldsArea';
import { activeComponentTypeEnum, setActiveComponent } from '../activeComponentSlice';

function TemplateSection(props) {
  const {
    id,
    name = '',
    isExpanded = true,
    instructions,
    updateSection,
    updateSectionByValuesArray,
    fields,
    sectionRows,
    onDeleteSection,
    sectionId,
    handleChangeFieldValue,
  } = props;
  const dispatch = useDispatch();
  const texts = useRbTexts();
  const [expanded, setExpanded] = useState(isExpanded);
  const [sectionInstructions, setSectionInstructions] = useState(instructions || '');
  const { deleteSectionText, sectionTitlePlaceholder } = useTexts();

  const handleExpandClick = useCallback(() => {
    setExpanded((prev) => !prev);
  }, []);
  const handleUpdateSection = useCallback(
    (value, property) => {
      updateSection(id, value, property);
    },
    [updateSection, id],
  );
  const handleUpdateSectionByValues = useCallback(
    (values) => {
      updateSectionByValuesArray(id, values);
    },
    [updateSectionByValuesArray, id],
  );
  const handleUpdateInstructions = useCallback(
    (value) => {
      setSectionInstructions(value);

      if ((instructions === null && value !== '') || (instructions !== null && instructions !== value)) {
        handleUpdateSection(value, 'instructions');
      }
    },
    [instructions, handleUpdateSection],
  );

  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
    id: `section-${id}`,
  });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const handleActiveComponent = useCallback(() => {
    dispatch(setActiveComponent({ componentType: activeComponentTypeEnum.SECTION, componentId: sectionId || id }));
  }, [dispatch, sectionId, id]);

  const handleSaveValue = useCallback(
    (value) => {
      handleUpdateSection(value.title, 'name');
    },
    [handleUpdateSection],
  );

  const dataset = useMemo(
    () => ({
      'data-ispropertiesfield': true,
    }),
    [],
  );

  const handleDeleteSection = useCallback(() => {
    onDeleteSection(id);
  }, [onDeleteSection, id]);

  return (
    <>
      <StyledTemplateSection style={style} ref={setNodeRef} data-cy="section-container" {...attributes}>
        <div className="section-header">
          <div className="drag-icon-box">
            <DragIcon
              onMouseDown={handleActiveComponent}
              className="drag-icon"
              ref={setActivatorNodeRef}
              data-ispropertiesfield="true"
              {...listeners}
            />
          </div>
          <IconButton
            data-testid="section-collapse-button"
            disableRipple
            onClick={handleExpandClick}
            className={!expanded ? 'icon-collapse' : ''}
          >
            <ToggleIcon />
          </IconButton>
          <InlineFieldContainer
            limit={250}
            dataset={dataset}
            fieldName="title"
            variant="subTitle"
            className="section-title"
            text={texts[name] || name}
            onFocus={handleActiveComponent}
            handleSaveValue={handleSaveValue}
            CustomFieldComponent={TitleField}
            placeholder={sectionTitlePlaceholder}
          />
          <CustomIconButton
            onClick={handleDeleteSection}
            btnTheme="smallIconButtonText"
            className="delete-section-button"
            text={deleteSectionText}
            icon={<CloseIcon style={{ width: '14px', height: '14px' }} />}
            data-testid="delete-section-button"
          />
        </div>
        <Collapse in={expanded} timeout="auto" unmountOnExit className="collapse-box">
          <TemplateSectionInstructions
            updateSectionInstructions={handleUpdateInstructions}
            value={sectionInstructions}
          />
          <SectionFieldsArea
            id={id}
            fields={fields}
            handleChangeFieldValue={handleChangeFieldValue}
            sectionRows={sectionRows}
            handleUpdateSectionByValues={handleUpdateSectionByValues}
          />
        </Collapse>
      </StyledTemplateSection>
    </>
  );
}

export default memo(TemplateSection);
