import { useQuery } from '@tanstack/react-query';
import { getTemplatesListTotalSize } from 'services/templateService';
import { useCategoryDrivenEnabled, useSetCategoryDrivenEnabled } from 'remote-state/accountSettingsServiceHooks';
import { getColumnFiltersForRequest } from 'common/components/grid/filters/helpers';
import { SwitchToggle } from 'common/components/controls/Switch/SwitchToggle';
import { StyledTemplateListPageHeader } from './style';
import NewTemplateButton from '../NewTemplateButton';
import useTexts from '../useTexts';
import { SourcePageEnum } from '../sourcePageEnum';

const TemplateListPageHeader = ({ updateRef, columnFilters, columnsMap }) => {
  const { listPageTitleText, categoryDrivenTemplatesText, categoryDrivenTemplatesTooltipText } =
    useTexts();
  const columnFiltersToSend = getColumnFiltersForRequest(columnFilters, columnsMap);
  const { data: templatesListTotalSize } = useQuery({
    queryKey: ['templatesListTotalSize', columnFiltersToSend],
    queryFn: () =>
      getTemplatesListTotalSize({ columnFilters: columnFiltersToSend, sourcePage: SourcePageEnum.TEMPLATES_PAGE }),
  });
  const isCategoryDrivenEnabled = useCategoryDrivenEnabled()?.data?.data;
  const { mutate: setCategoryDrivenEnabled } = useSetCategoryDrivenEnabled();
  const handleToggleCategoryDrivenEnabled = () => {
    setCategoryDrivenEnabled(!isCategoryDrivenEnabled);
  };
  return (
    <StyledTemplateListPageHeader>
      <div className="list-page-header-left">
        <div className="header-bottom">
          <span className="header-title">{listPageTitleText}</span>
          {templatesListTotalSize && (
            <span className="list-size" data-testid="template-list-size">
              {templatesListTotalSize}
            </span>
          )}
        </div>
        <div className="category-driven-templates">
          <SwitchToggle
            handleSwitchChange={handleToggleCategoryDrivenEnabled}
            isChecked={isCategoryDrivenEnabled}
            switchText={categoryDrivenTemplatesText}
            dataTestId="switch-toggle"
            hintText={categoryDrivenTemplatesTooltipText}
            classNames={{ labelClass: 'category-driven-switch-label' }}
          />
        </div>
      </div>
      <div className="list-page-header-right">
        <div className="list-search-wrapper" ref={(el) => updateRef(el)} />
        <NewTemplateButton />
      </div>
    </StyledTemplateListPageHeader>
  );
};

export default TemplateListPageHeader;
