import { useQuery, useQueryClient } from '@tanstack/react-query';
import { checkAttachmentExistence } from '../services/attachmentService';

const options = {
  staleTime: 1000 * 60 * 15,
};

export const useAttachmentAvailability = (url) => {
  const queryClient = useQueryClient();
  const queryKey = ['attachmentAvailability', url];

  const attachmentAvailability = useQuery({
    queryKey,
    queryFn: () => !url ? false : checkAttachmentExistence(url),
    ...options,
  });

  const updateAttachmentAvailability = (isAvailable) => {
    queryClient.setQueryData(queryKey, isAvailable);
  };

  return { ...attachmentAvailability, updateAttachmentAvailability };
};
