import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectActiveComponent } from 'features/templateBuilder/activeComponentSlice';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as Plus } from 'images/icons/attachments/plus.svg';
import { ReactComponent as LinkedItem } from 'images/icons/linkIcon.svg';
import FieldName from 'features/srPanel/templateFieldsGrid/fieldName';
import {
  StyledLinkComponentHeader,
  StyledLinksComponent,
  StyledLinksWrapper,
  StyledEmptyLink,
  StyledEmptyLinkText,
} from './StyledLinks';
import LinkItemComponent from './LinkItemComponent';
import Tooltip from '../tooltip';
import { formatDate } from './helper';

export default function Links({
  field,
  fieldValue,
  displayName,
  required,
  disabled,
  hasMoreDetails,
  url,
  urlParams,
  onLinksChange,
  onFocus,
  isTemplatePage,
  handleDirty,
  updateFieldValidation,
}) {
  const [error, setError] = useState(true);
  const { componentId } = useSelector(selectActiveComponent);
  const [links, setLinks] = useState([]);

  const checkAndUpdateValidationStatus = useCallback(
    (value) => {
      let isValid = true;
      if ((!value.length || value?.some((list) => !list.linkName || !list.linkUrl)) && required) {
        isValid = false;
        setError(true);
      }
      setError(false);
      if (updateFieldValidation) {
        updateFieldValidation(field.fieldName, required, isValid);
      }
      return isValid;
    },
    [required, field.fieldName, updateFieldValidation],
  );

  useEffect(() => {
    if (fieldValue) {
      if (typeof fieldValue === 'string') {
        setLinks(JSON.parse(fieldValue));
      } else {
        setLinks(fieldValue);
      }
    } else if (field.defaultValue) {
      setLinks(JSON.parse(field.defaultValue));
    }
  }, [fieldValue, field.defaultValue]);

  useEffect(() => {
    checkAndUpdateValidationStatus(links);
  }, [links, checkAndUpdateValidationStatus]);

  const changeCurrentLink = (val, id, action) => {
    let newLinksArr = [];
    if (action === 'delete') {
      newLinksArr = links?.filter((link) => id !== link.id);
    } else {
      newLinksArr = links?.map((link) => {
        if (link.id === id) {
          return { ...link, ...val };
        }
        return link;
      });
    }
    if (handleDirty) {
      handleDirty({ [field.fieldName]: JSON.stringify(newLinksArr) });
    }
    setLinks(newLinksArr);
    onLinksChange(newLinksArr);
  };

  const addNewLinkArea = () => {
    if (disabled) {
      return false;
    }
    const id = uuidv4();
    setLinks((prevState) => [
      ...prevState,
      {
        id,
        linkName: '',
        linkUrl: '',
        createdTime: formatDate(new Date()),
      },
    ]);
    onLinksChange(links);
  };

  return (
    <>
      <StyledLinkComponentHeader>
        <FieldName
          name={displayName}
          fieldId={field?.fieldId}
          required={required}
          hasMoreDetails={hasMoreDetails}
          url={url}
          urlParams={urlParams}
          isTemplatePage={isTemplatePage}
          hintText={field?.hint && field?.hintText}
        />
        <Tooltip
          title="Add link"
          placement="top"
          text={<Plus style={{ cursor: 'pointer' }} className="add-icon" onClick={addNewLinkArea} color="disabled" />}
        />
      </StyledLinkComponentHeader>
      <StyledLinksComponent className="link-field" error={error} active={componentId === field.fieldId}>
        {links.length ? (
          <StyledLinksWrapper>
            {links?.map((link) => (
              <LinkItemComponent
                isActiveField={componentId === field.fieldId}
                isTemplatePage={isTemplatePage}
                disabled={disabled}
                required={required}
                key={link.id}
                link={link}
                error={error}
                changeCurrentLink={changeCurrentLink}
                onFocus={onFocus}
              />
            ))}
          </StyledLinksWrapper>
        ) : (
          <StyledEmptyLink onClick={addNewLinkArea}>
            <LinkedItem />
            <StyledEmptyLinkText>Add link</StyledEmptyLinkText>
          </StyledEmptyLink>
        )}
      </StyledLinksComponent>
    </>
  );
}
