import { getMailAction } from 'features/resolutionPanel/middlePanel/Workflows/api/workflowService';
import { redirect } from '@tanstack/react-router';

export const RedirectValue = {
  SERVICE_PORTAL(val) {
    return val.includes('servicePortal');
  },
  TICKET_SPACES(val) {
    return val.includes('ticket');
  },

  STATUS: '500',
};

function urlParams() {
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');
  const phaseId = urlParams.get('phaseId');
  const actionItemId = urlParams.get('action_item_id');
  const mailaction = urlParams.get('mailaction');
  const expire = urlParams.get('expire');
  const secureID = urlParams.get('secureID');
  return { id, phaseId, actionItemId, mailaction, expire, secureID };
}

export async function loaderTicketMailAction() {
  const { id, phaseId, actionItemId, mailaction, expire, secureID } = urlParams();
  if (mailaction !== null || (expire !== null && secureID !== null)) {
    try {
      const res = await getMailAction(id, phaseId, actionItemId, mailaction, expire, secureID);
      if (!res.updated) {
        throw new Error('failed to update the service request via mail action');
      } else {
        return res;
      }
    } catch (error) {
      return { updated: RedirectValue.STATUS };
    }
  }
}

function pathToTicket(path) {
  return redirect({
    to: `${path}`,
    throw: false,
  });
}

export async function redirectToTicketOrServicePortal() {
  const { mailaction, expire, secureID } = urlParams();
  if (mailaction !== null || (expire !== null && secureID !== null)) {
    const redirectToTicket = await loaderTicketMailAction();
    if (
      RedirectValue.TICKET_SPACES(redirectToTicket.updated)
    ) {
      return pathToTicket(`${redirectToTicket.updated}`);
    }
    if (RedirectValue.SERVICE_PORTAL(redirectToTicket.updated)) {
      window.location.href = `${redirectToTicket.updated}`;
    }
  }
}
