import { coupledColumsInfo } from './constants';

const coupledColumns = coupledColumsInfo.childToParent;

export const findChildColumsIds = (parent) => {
  const otherChilds = [];
  Object.entries(coupledColumns).forEach(([key, val]) => {
    if (val === parent.id) {
      otherChilds.push(key);
    }
  });
  return otherChilds;
};
export const getAvialableFields = (data, existingColums, getColumnText) => {
  const availableFields = data?.pages?.flatMap((page) => page?.currentList);
  const mappedColumOrder = new Map(availableFields.map((obj) => [obj.id, obj]));
  const includedElemsIds = [];
  const filtered = availableFields?.reduce((result, item) => {
    let newItem = { ...item };
    if (!existingColums.includes(item.id) && !includedElemsIds.includes(item.id)) {
      //Check if added column has other coupled items for example categories. If yes then we need add its parent and siblings in a proper way
      const hasRelations =
        (coupledColumns[newItem.id] && 'CHILD') || (Object.values(coupledColumns).includes(newItem.id) && 'PARENT');
      if (hasRelations) {
        switch (hasRelations) {
          case 'CHILD':
            {
              const parentItem = mappedColumOrder.get(coupledColumns[newItem.id]);
              if (parentItem) {
                const otherChilds = findChildColumsIds(parentItem);

                newItem = {
                  ...{
                    ...parentItem,
                    sortableName: getColumnText(coupledColumsInfo.parentNames[parentItem.id]) ?? item.displayName,
                  },
                  children: otherChilds.map((colId) => {
                    const id = Number(colId);
                    includedElemsIds.push(id);
                    return mappedColumOrder.get(id);
                  }),
                };
              }
            }
            break;

          case 'PARENT':
            {
              const parentItem = newItem;
              const otherChilds = findChildColumsIds(parentItem);
              newItem = {
                ...{
                  ...parentItem,
                  sortableName: getColumnText(coupledColumsInfo.parentNames[parentItem.id]) ?? item.displayName,
                },
                children: otherChilds.map((colId) => {
                  const id = Number(colId);
                  includedElemsIds.push(id);
                  return mappedColumOrder.get(id);
                }),
              };
            }
            break;

          default:
            break;
        }
      }
      includedElemsIds.push(newItem.id);

      result.push({ ...newItem, sortableName: newItem.sortableName ?? newItem.displayName });
    }
    return result;
  }, []);
  return filtered;
};

export const getColumLi = (itemName, inputValue) => {
  let firstPart = '';
  let matchPart = '';
  let secondPart = '';

  const index = itemName.toLowerCase().indexOf(inputValue.toLowerCase());
  if (index !== -1) {
    firstPart = itemName.slice(0, index);
    matchPart = itemName.slice(index, index + inputValue.length);
    secondPart = itemName.slice(index + inputValue.length);
  } else {
    firstPart = itemName;
  }
  return { firstPart, matchPart, secondPart };
};
