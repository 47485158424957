import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from '@tanstack/react-router';
import { ClickAwayListener, IconButton, Popper } from '@mui/material';
import { setError } from 'store/globalSlice';
import { ERROR_TYPES } from 'constants/common';
import SearchField from 'common/components/searchField';
import Tooltip from 'common/components/tooltip';
import { ReactComponent as SearchIcon } from 'images/icons/searchIcon.svg';
import { getSrById } from 'services/ticketService';
import useTexts from '../useTexts';
import { StyledExpandedSearch, StyledNavSearch, StyledNoResultError, StyledPaperSearch } from './style';
import { TICKETS_OPEN_URL } from '../../../constants';
import ExternalModal from '../../externalModal';
import { useMigrationFailedHandler } from '../../../remote-state/ticketServiceHooks';

const NavSearch = ({ expanded }) => {
  const { classicSRUrl, handleMigrationFailed, handleModalClose } = useMigrationFailedHandler();
  const router = useRouter();
  const dispatch = useDispatch();
  const [anchorE1, setAnchorE1] = useState(null);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [searchString, setSearchString] = useState('');
  const { searchPlaceholder, noResultMessage, searchText } = useTexts();

  const toggleSearchMenu = () => {
    setOpen((prev) => !prev);
    setErrorMessage('');
    setSearchString('');
  };

  const handleClick = (event) => {
    setAnchorE1(event.currentTarget);
    toggleSearchMenu();
  };

  const handleSearch = useCallback(() => {
    getSrById(searchString)
      .then((sr) => {
        if (sr.id) {
          router.navigate({ to: TICKETS_OPEN_URL.SPACES(sr?.id) });
          setSearchString('');
          if (!expanded) {
            toggleSearchMenu();
          }
        } else {
          setErrorMessage(`${noResultMessage}`);
        }
      })
      .catch((error) => {
        if (error.response.status === 422) {
          handleMigrationFailed(searchString);
        } else if (error.response.status === 403) {
          dispatch(setError(ERROR_TYPES.NO_VIEW_PERMISSIONS_TICKET));
        } else {
          setErrorMessage(`${noResultMessage}`);
        }
      });
  }, [searchString, expanded, dispatch, noResultMessage, router, handleMigrationFailed]);

  const onSearchChange = useCallback(({ target }) => {
    setSearchString(target.value);
    setErrorMessage('');
  }, []);

  const onSearchClear = useCallback(() => {
    setSearchString('');
  }, []);

  const onKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        handleSearch();
      }
    },
    [handleSearch],
  );

  const navSearch = (
    <SearchField
      placeholderText={searchPlaceholder}
      handleSearch={handleSearch}
      value={searchString}
      onKeyDown={onKeyDown}
      onChange={onSearchChange}
      onClear={onSearchClear}
      type="number"
      autoFocus={!expanded}
      inputProps={{
        'data-testid': 'menu-search-input',
      }}
    />
  );

  return (
    <StyledNavSearch className="nav-search" expanded={expanded}>
      {expanded ? (
        <>
          <StyledExpandedSearch>
            {navSearch}
            <StyledNoResultError className="expanded-error">{errorMessage}</StyledNoResultError>
          </StyledExpandedSearch>
        </>
      ) : (
        <>
          <Popper open={open} anchorEl={anchorE1} placement="right-start" sx={{ marginLeft: '14px!important' }}>
            <ClickAwayListener onClickAway={toggleSearchMenu}>
              <StyledPaperSearch>
                <div className="title">{searchText}</div>
                <div className="search">{navSearch}</div>
                <StyledNoResultError>
                  <span className="collapsed-error">{errorMessage}</span>
                </StyledNoResultError>
              </StyledPaperSearch>
            </ClickAwayListener>
          </Popper>
          <IconButton data-cy="search-button" disableRipple onClick={handleClick}>
            {open ? (
              <SearchIcon />
            ) : (
              <Tooltip
                title={searchPlaceholder}
                data-testid="menu-search-icon"
                text={<SearchIcon />}
                placement="right"
                disableInteractive={open}
              />
            )}
          </IconButton>
        </>
      )}
      <ExternalModal url={classicSRUrl} open={!!classicSRUrl} onClose={handleModalClose} />
    </StyledNavSearch>
  );
};

export default NavSearch;
