import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

export const StyledFloatingTicketPanelBackdrop = styled(Box)(() => ({
  position: 'fixed',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  display: 'flex',
  alignItems: 'end',
  backgroundColor: '#00000059',
  backdropFilter: 'blur(1.7px)',
  zIndex: 999,
}));
