import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    title: texts?.['spaces.resolutionPanel.deescalationPrompt.title'],
    message: texts?.['spaces.resolutionPanel.deescalationPrompt.description'],
    okBtnTitle: texts?.['spaces.prompt.yes'],
    cancelBtnTitle: texts?.['spaces.prompt.no'],
  };

  return componentTexts;
}
