import { useCallback, useEffect, useState } from 'react';

export const useOpenListIfReady = ({ isListReady, isListOpen, openList }) => {
  const [isRequestOpen, setIsRequestOpen] = useState(false);

  useEffect(() => {
    if (isRequestOpen && isListReady && !isListOpen) {
      openList();
      setIsRequestOpen(false);
    }
  }, [isRequestOpen, isListReady, isListOpen, openList]);

  const requestOpen = useCallback(() => {
    setIsRequestOpen(true);
  }, []);

  return {
    requestOpen,
  };
};
