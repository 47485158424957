import { GroupTitle } from './groupTitle';
import { DrillDownList } from '../assigneeList/drillDownList';

export const GroupDetails = (props) => {
  const {
    groupName,
    numberOfMembers,
    memberList,
    currentAssigned,
    onClickBack,
    handleChange,
    showAssignToTeam,
    filteredAssignees,
    isGridFilter,
  } = props;
  return (
    <div>
      <GroupTitle groupName={groupName} members={numberOfMembers} onClickBack={onClickBack} />
      <DrillDownList
        memberList={memberList}
        groupName={groupName}
        handleChange={handleChange}
        currentAssigned={currentAssigned}
        showAssignToTeam={showAssignToTeam}
        filteredAssignees={filteredAssignees}
        isGridFilter={isGridFilter}
      />
    </div>
  );
};
