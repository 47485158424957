import { useRef, useEffect } from 'react';
import { openWindow } from 'common/utils/utils';

const OpenWindowLink = ({ url, children }) => {
  const newWindowRef = useRef(null);

  const handleClick = (e) => {
    e.preventDefault();
    const newWindow = openWindow(url);
    newWindowRef.current = newWindow;
  };

  useEffect(() => {
    const handleLoad = () => {
      if (newWindowRef.current) {
        const newWindow = newWindowRef.current;
        const { documentElement } = newWindow.document;
        const width = documentElement.scrollWidth + 20;
        const height = documentElement.scrollHeight + 20;
        newWindow.resizeTo(width, height);
      }
    };

    if (newWindowRef.current) {
      newWindowRef.current.addEventListener('load', handleLoad);
    }

    return () => {
      if (newWindowRef.current) {
        newWindowRef.current.removeEventListener('load', handleLoad);
      }
    };
  }, []);

  return (
    <a href={url} target="_blank" rel="noopener noreferrer" onClick={handleClick}>
      {children}
    </a>
  );
};

export default OpenWindowLink;
