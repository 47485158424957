import { useSelector } from 'react-redux';
import { useRouter } from '@tanstack/react-router';
import { useTicketLockStatus, useTicketPermissions } from 'remote-state/ticketServiceHooks';
import { selectActiveUser } from 'store/userSlice';
import DataFilters from 'features/resolutionPanel/dataFilters';
import Footer from 'features/resolutionPanel/footer';
import { MIDDLE_PANEL_TAB_NAMES } from 'features/resolutionPanel/middlePanel/constants';
import { useTabs } from 'features/resolutionPanel/middlePanel/auditLog/hooks/useTabs';
import { StyledTicketTabs, StyledTabContainer } from './style';

const TicketTabs = (props) => {
  const { children, showTicketAsTab, srType, scrollToAuditLogRecord, selectedTabIndex } = props;
  const router = useRouter();
  const srId = router.latestLocation.search.id;
  const { data: hasEditPermissions } = useTicketPermissions('edit', srId);
  const userAccount = useSelector(selectActiveUser);
  const { data: lockingDetails } = useTicketLockStatus(srId);
  const isTicketLocked = lockingDetails?.isLocked && lockingDetails?.lockingUser !== userAccount?.username;
  const { tabs: ticketTabs } = useTabs({ srId });
  const relatedItemsTabIndex = ticketTabs?.findIndex((tab) => tab.name === MIDDLE_PANEL_TAB_NAMES?.RELATED_ITEMS) || 0;
  const attachmentsTabIndex = ticketTabs?.findIndex((tab) => tab.name === MIDDLE_PANEL_TAB_NAMES?.ATTACHMENTS) || 0;
  const hideFooter = relatedItemsTabIndex === selectedTabIndex || attachmentsTabIndex === selectedTabIndex;
  const showFooter = !isTicketLocked && hasEditPermissions && !hideFooter;

  return (
    <StyledTicketTabs>
      <StyledTabContainer>
        <DataFilters srId={srId} srType={srType} showTicketAsTab={showTicketAsTab} />
        {children}
      </StyledTabContainer>
      {showFooter && (
        <Footer scrollToAuditLogRecord={scrollToAuditLogRecord} srId={srId} showTicketAsTab={showTicketAsTab} />
      )}
    </StyledTicketTabs>
  );
};

export default TicketTabs;
