import { styled } from '@mui/material/styles';

export const StyledTemplateForm = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  paddingBottom: '5px',
  '.newSectionBtn': {
    width: 'fit-content',
    alignSelf: 'end',
    marginRight: '-6px',
  },
  '.template-form': {
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: 'calc(100% - 160px)',
    marginRight: '7px',
    ...theme.customScrollbar,
  },
}));
