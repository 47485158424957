import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import PropTypes from 'prop-types';

const TableView = ({ texts, headersTextKeys, columnsKeys, data, stickyHeader }) => (
  <Paper className="table-paper" sx={{ width: '100%', overflow: 'hidden' }}>
    <TableContainer>
      <Table stickyHeader={stickyHeader}>
        <TableHead>
          <TableRow>
            {headersTextKeys.map(({ key, value }) => (
              <TableCell key={key}>{texts[value]}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, rowIndex) => (
            <TableRow key={row.id}>
              {columnsKeys.map(({ key, value }) => (
                <TableCell
                  data-testId={`${rowIndex}-table-cell-${value}`}
                  data-cy={`${rowIndex}-table-cell-${value}`}
                  key={key}
                >
                  {row[value]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Paper>
);

TableView.propTypes = {
  texts: PropTypes.object,
  headersTextKeys: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number,
      value: PropTypes.string,
    }),
  ),
  columnsKeys: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number,
      value: PropTypes.string,
    }),
  ),
  data: PropTypes.array,
  stickyHeader: PropTypes.bool,
};

TableView.defaultProps = {
  texts: {},
  headersTextKeys: [],
  columnsKeys: [],
  data: [],
  stickyHeader: true,
};
export default TableView;
