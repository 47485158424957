import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSuggestedCategoryById } from 'store/categorySlice';
import { VIRTUALIZED_SCROLL_CONTEXT_DEFAULT_VALUE } from 'common/components/dropdownSelect/VirtualizedScroll/VirtualizedScrollContext';
import { CONSTANTS as DROPDOWN_CONSTANTS } from 'common/components/dropdownSelect/constants';
import { useApplicationData } from 'remote-state/applicationHooks';
import { useGetAIConfigData } from 'remote-state/aiConfigDataHook';
import { QUERIES_KEYS } from 'constant';
import { getItemSizeForSuggestedCategoryCreator, rowFnForSuggestedCategoryCreator } from './dropdownUtils';
import { useHandleSelectSuggestedCategories } from './useHandleSelectSuggestedCategories';

export const useIsCurrentCategoriesMatchSuggested = ({ srId, currentCategory }) => {
  const [isCurrentCategoryMatchingSuggested, setIsCurrentCategoryMatchingSuggested] = useState(true);

  const { data: isThirdLevelEnabled } = useApplicationData(QUERIES_KEYS.IS_EXTERNAL_MODE);
  const { primaryId, secondaryId, thirdLevelId } = currentCategory;

  const suggestedCategory = useSelector(selectSuggestedCategoryById(srId));

  useEffect(() => {
    /* 
      When switching from ticket to the queue page, current category has null since the redux state of categories is reset,
      this causes a flash of the ai icon if a suggested category exists and the current matches it(even though the redux state doesn't reflect it yet).
      Additionally when switching from queue to ticket page they are being set to NaN hence the NaN check.
    */
    let currentNonNull = typeof primaryId === 'number' && !Number.isNaN(primaryId) && typeof secondaryId === 'number' && !Number.isNaN(secondaryId);

    if (isThirdLevelEnabled) {
      currentNonNull = currentNonNull && typeof thirdLevelId === 'number' && !Number.isNaN(thirdLevelId);
    }

    if (suggestedCategory && currentNonNull) {
      const { firstLevelKey, secondLevelKey, thirdLevelKey } = suggestedCategory;
      let isMatching = firstLevelKey === primaryId && secondLevelKey === secondaryId;

      if (isThirdLevelEnabled) {
        isMatching = isMatching && thirdLevelKey === thirdLevelId;
      }

      setIsCurrentCategoryMatchingSuggested(isMatching);
    }
  }, [primaryId, secondaryId, thirdLevelId, suggestedCategory, isThirdLevelEnabled]);

  return isCurrentCategoryMatchingSuggested;
};

export const useSuggestedCategoryDropdownControl = ({
  srId,
  currentCategory = {},
  selectSuggestedCategoryProps = {},
}) => {
  const listRef = useRef(null);
  const {
    data: { [QUERIES_KEYS.IS_EXTERNAL_MODE]: isThirdLevelCategoryEnabled, [QUERIES_KEYS.IS_AI_ENABLED]: isAiEnabled },
  } = useApplicationData();
  const suggestedCategory = useSelector(selectSuggestedCategoryById(srId));
  const currentMatchSuggested = useIsCurrentCategoriesMatchSuggested({
    srId,
    currentCategory,
  });
  const { selectAiSuggestedCategories } = useHandleSelectSuggestedCategories(selectSuggestedCategoryProps);
  const { data: isAISuggestedCategory } = useGetAIConfigData(QUERIES_KEYS.IS_AI_SUGGESTED_CATEGORY);
  const isSuggestedCategoryShown = isAISuggestedCategory && suggestedCategory && !currentMatchSuggested && isAiEnabled;

  const indexOffset = isSuggestedCategoryShown ? 2 : 0;

  const getItemSizeWithSuggestedCategory = useCallback(
    (index) =>
      getItemSizeForSuggestedCategoryCreator({
        isThirdLevelCategoryEnabled,
      })(index),
    [isThirdLevelCategoryEnabled],
  );

  const RowRendererWithSuggestedCategory = useCallback(
    (rowRendererProps) =>
      rowFnForSuggestedCategoryCreator({
        srId,
        indexOffset,
        suggestedCategory,
        selectAiSuggestedCategories,
      })(rowRendererProps),
    [srId, indexOffset, suggestedCategory, selectAiSuggestedCategories],
  );

  const virtualizedScrollContextValueForSuggestedCategoryMemo = useMemo(
    () => ({
      getItemSize: getItemSizeWithSuggestedCategory,
      rowRenderer: RowRendererWithSuggestedCategory,
      listRef,
    }),
    [getItemSizeWithSuggestedCategory, RowRendererWithSuggestedCategory],
  );

  const virtualizedScrollContextValue = useMemo(() => {
    let contextValue = VIRTUALIZED_SCROLL_CONTEXT_DEFAULT_VALUE;

    if (isSuggestedCategoryShown) {
      contextValue = virtualizedScrollContextValueForSuggestedCategoryMemo;
    }

    return contextValue;
  }, [isSuggestedCategoryShown, virtualizedScrollContextValueForSuggestedCategoryMemo]);

  useEffect(() => {
    if (isSuggestedCategoryShown) {
      listRef.current?.resetAfterIndex(0); //Recalculate row offsets if suggested category needs to re-appear after categories have been cleared
    }
  }, [isSuggestedCategoryShown]);

  const selectOptionsWrapperClassName = isSuggestedCategoryShown ? 'has-suggested-category' : '';

  const dropdownHeightExtension = isSuggestedCategoryShown
    ? DROPDOWN_CONSTANTS.DROPDOWN_SUGGESTED_CATEGORY_EXTENSION
    : 0;

  return {
    virtualizedScrollContextValue,
    indexOffset,
    selectOptionsWrapperClassName,
    dropdownHeightExtension,
    isSuggestedCategoryShown,
  };
};
