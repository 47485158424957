import { axiosInstance } from './axiosConfig';

export function getTimezonesList() {
  return axiosInstance.get('/list/zones').then((response) => response.data);
}

export function getLocalesList() {
  return axiosInstance.get('/list/locales').then((response) => response.data);
}

export function saveMySettings(updatedSettings) {
  return axiosInstance.post('/user', updatedSettings).then((response) => response.data);
}
