import { StyledUserAvatar } from './style';

export const UserAvatar = ({ userName, profileImage, customStyles, className }) => (
  <StyledUserAvatar
    id="user-avatar"
    data-testid="user-avatar"
    data-value={userName}
    src={profileImage ? `data:image/jpeg;base64,${profileImage}` : null}
    sx={customStyles}
    className={className}
    alt={userName}
  >
    {!profileImage && userName?.slice(0, 1)?.toUpperCase()}
  </StyledUserAvatar>
);
