import { useMemo } from 'react';
import { Box } from '@mui/material';
import { ReactComponent as NoteIcon } from 'images/icons/note-added.svg';
import { ReactComponent as MessageIcon } from 'images/icons/message-footer-item.svg';
import { ReactComponent as ActivitiesIcon } from 'images/icons/activities-footer-item.svg';
import { ReactComponent as ResolutionIcon } from 'images/icons/resolution-footer-item.svg';
import { editorActionData, FILTER_ENUMS } from 'features/resolutionPanel/middlePanel/auditLog/constants';
import { editorActionTypes } from 'features/resolutionPanel/middlePanel/auditLog/constants/editorActionTypes';
import { FEATURE_FLAGS, FEATURE_FLAGS_KEYS } from 'constants/featureFlags';
import { useFeatureFlagQuery } from 'remote-state/featureFlagsHooks';
import { useAuditLog } from '../middlePanel/auditLog/hooks';
import { useTabs } from '../middlePanel/auditLog/hooks/useTabs';
import { StyledFooter } from './style';
import { FooterItem } from './footerItem';
import { MIDDLE_PANEL_TAB_NAMES } from '../middlePanel/constants';

const allFooterItems = [
  {
    name: 'note',
    defaultLabel: 'Note',
    type: editorActionTypes.NOTE,
    translatedLabel: 'spaces.resolutionPanel.footer.note',
    svg: <NoteIcon />,
    targetTab: MIDDLE_PANEL_TAB_NAMES.JOURNEY,
  },
  {
    name: 'message',
    defaultLabel: 'Message',
    type: editorActionTypes.MESSAGE,
    translatedLabel: 'spaces.resolutionPanel.footer.message',
    svg: <MessageIcon />,
    targetTab: MIDDLE_PANEL_TAB_NAMES.JOURNEY,
  },
  {
    name: FILTER_ENUMS.ACTIVITIES,
    defaultLabel: 'Activities',
    type: editorActionTypes.ACTIVITY,
    translatedLabel: 'spaces.resolutionPanel.footer.activities',
    svg: <ActivitiesIcon />,
    targetTab: MIDDLE_PANEL_TAB_NAMES.JOURNEY,
  },
  {
    name: 'resolution',
    defaultLabel: 'Resolution',
    type: editorActionTypes.RESOLUTION,
    translatedLabel: 'spaces.resolutionPanel.footer.resolution',
    svg: <ResolutionIcon />,
    targetTab: MIDDLE_PANEL_TAB_NAMES.JOURNEY,
  },
];

const Footer = ({ srId, scrollToAuditLogRecord = () => {}, showTicketAsTab }) => {
  const { handleActiveTab } = useAuditLog();
  const { tabs, isLoading } = useTabs({ srId, showTicketAsTab });
  const { data: isSpacesActivitiesMVPEnabled } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.SPACES_ACTIVITIES_MVP,
    defaultValue: false,
  });

  const footerItemsForDisplay = useMemo(
    () =>
      !isLoading
        ? allFooterItems
            .filter((item) => {
              if (FEATURE_FLAGS.HIDE_MESSAGES_CONTENT) return item.name !== 'message';
              if (!isSpacesActivitiesMVPEnabled) return item.name !== FILTER_ENUMS.ACTIVITIES;
              return true;
            })
            .map((item) => ({
              ...item,
              targetTabIndex: tabs.findIndex((tab) => tab.name === item.targetTab),
            }))
            .filter((item) => item.targetTabIndex !== -1) // filter out tabs that don't exist
        : [],
    [isLoading, isSpacesActivitiesMVPEnabled, tabs],
  );

  return (
    <StyledFooter data-testid="footer-list" data-cy="footer-list">
      {footerItemsForDisplay.map(({ name, svg, translatedLabel, defaultLabel, type, targetTabIndex }) => (
        <Box key={name} onClick={() => handleActiveTab(null, targetTabIndex, name)}>
          <FooterItem
            name={name}
            svg={svg}
            translatedLabel={translatedLabel}
            defaultLabel={defaultLabel}
            type={type}
            srId={srId}
            editorClassName={editorActionData[type]?.editorElementClassName || 'editor-element'}
            scrollToAuditLogRecord={scrollToAuditLogRecord}
            showTicketAsTab={showTicketAsTab}
          />
        </Box>
      ))}
    </StyledFooter>
  );
};

export default Footer;
