import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    privacySettingLabel: texts?.['spaces.header.queueViews.privacySetting.radioGroup.label'] || 'Privacy Setting',
    privateLabel: texts?.['spaces.header.queueViews.private'] || 'Private',
    publicLabel: texts?.['spaces.header.queueViews.public'] || 'Public',
    privacySettingGroupsLabel: texts?.['spaces.header.queueViews.privacySetting.groupsSelect.label'] || 'Visible to',
    privacySettingGroupsPlaceholder:
      texts?.['spaces.header.queueViews.privacySetting.groupsSelect.placeholder'] || 'All Groups',
  };

  return componentTexts;
}
