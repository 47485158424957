import { get } from 'lodash-es';
import { SR_TYPE_CODE_MAP } from 'features/TicketPanel/constants';

import { StyledDetailsTitle, AmountOfRows, StyledLogsWrapper, StyledDetailsHeader, LogList } from './style';
import useTexts from '../useTexts';

const ErrorLogs = ({ failedTickets }) => {
  const texts = useTexts();

  const aggregateObjects = (objects, key) => {
    const aggregatedObjects = {};

    objects.forEach((object) => {
      const messageType = get(object, key);
      if (!aggregatedObjects[messageType]) {
        aggregatedObjects[messageType] = [];
      }
      aggregatedObjects[messageType].push(object);
    });

    return aggregatedObjects;
  };

  const aggregatedLogs = aggregateObjects(failedTickets, 'updateTicketResponse.message');

  const joinTickets = (tickets) => tickets.map((ticket) => `#${ticket.id}`).join(', ');

  const getTicketsText = (tickets) => {
    const aggregatedTickets = aggregateObjects(tickets, 'srType');

    return Object.entries(aggregatedTickets)
      .map(([srType, tickets]) => `${texts[SR_TYPE_CODE_MAP[srType]]} ${joinTickets(tickets)}`)
      .join(', ');
  };

  return (
    <div>
      <StyledDetailsHeader>
        <StyledDetailsTitle>{texts.failureDetailsHeader}</StyledDetailsTitle>
        <AmountOfRows>{failedTickets.length}</AmountOfRows>
      </StyledDetailsHeader>
      <StyledLogsWrapper>
        <span>{texts.failureDetailsTitle}:</span>
        <LogList>
          {Object.entries(aggregatedLogs).map(([message, tickets], index) => (
            <li key={index}>
              {getTicketsText(tickets)} - {texts[message]}
            </li>
          ))}
        </LogList>
      </StyledLogsWrapper>
    </div>
  );
};

export default ErrorLogs;
