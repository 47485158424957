import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ClickAwayListener } from '@mui/base';
import Checkbox from '@mui/material/Checkbox';

import { ReactComponent as RightChevron } from 'images/icons/chevronRight.svg';
import { ReactComponent as LeftChevron } from 'images/icons/chevronLeftBlack.svg';

import { useStyles, StyledMainItem } from './filterDropdownStyles';
import { addColumnFilter } from '../../../features/resolutionPanel/middlePanel/auditLog/store/slice';

export const FilterDropdown = ({ fileTypes, setIsOpen, selectedMainFilter, setSelectedMainFilter }) => {
  const [selectedSubFilter, setSelectedSubFilter] = useState('');
  const [selectedFileTypes, setSelectedFileTypes] = useState([]);

  const constants = { defaultRangeTitles: ['Today', 'Yesterday', 'This Week', 'This Month', 'Last 30 Days'] };

  const getDateRangeValues = useCallback(
    (rangeTitle = selectedSubFilter) => {
      const oneMinuteInMs = 60 * 1000;
      const daysThisMonth = new Date().getDate();
      const sevenDaysInMs = 7 * 24 * 60 * 60 * 1000;
      const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
      const monthInMs = daysThisMonth * 24 * 60 * 60 * 1000;
      const begginingOfCurrentMonthInMs = new Date().setDate(1);
      const endOfTodaysDateInMs = new Date().setHours(23, 59, 59, 999);
      const begginingOfTodaysDateInMs = new Date().setHours(0, 0, 0, 0);

      switch (rangeTitle) {
        case 'Today':
          return { startDate: begginingOfTodaysDateInMs, endDate: endOfTodaysDateInMs };
        case 'Yesterday':
          return {
            startDate: begginingOfTodaysDateInMs - 24 * 60 * 60 * 1000,
            endDate: begginingOfTodaysDateInMs - oneMinuteInMs,
          };
        case 'This Week':
          return { startDate: begginingOfTodaysDateInMs - sevenDaysInMs, endDate: endOfTodaysDateInMs };
        case 'This Month':
          return { startDate: begginingOfCurrentMonthInMs - monthInMs, endDate: endOfTodaysDateInMs };
        case 'Last 30 Days':
          return { startDate: begginingOfTodaysDateInMs - thirtyDaysInMs, endDate: endOfTodaysDateInMs };
        default:
          return { startDate: begginingOfTodaysDateInMs };
      }
    },
    [selectedSubFilter],
  );

  const styles = useStyles();
  const dispatch = useDispatch();

  const handleLeftChevronClick = () => {
    setSelectedMainFilter('');
  };

  const handleMainFilterSelect = (event) => {
    setSelectedMainFilter(event.target.innerText);
  };

  const handleSubFilterSelect = (event) => {
    setSelectedSubFilter(event.target.innerText);
  };

  const handleTypeSubFilterSelect = (isChecked, label) => {
    const newSelectedFileTypes = [...selectedFileTypes];
    if (isChecked) {
      newSelectedFileTypes.push(label);
    } else {
      const occurenceIndex = newSelectedFileTypes.findIndex((elm) => elm === label);
      newSelectedFileTypes.splice(occurenceIndex, 1);
    }
    setSelectedFileTypes(newSelectedFileTypes);
  };

  const mainItem = (label, handleClick, showChevron = false, showCheckbox = false) => (
    <StyledMainItem onClick={!showCheckbox && handleClick}>
      <div className={styles.subfiltersWrapper}>
        {showCheckbox && <Checkbox onChange={(e) => handleTypeSubFilterSelect(e?.target?.checked, label)} />}

        <p>{label}</p>
      </div>

      {showChevron && <RightChevron />}
    </StyledMainItem>
  );

  useEffect(() => {
    if (selectedMainFilter && (selectedSubFilter || selectedFileTypes?.length > 0)) {
      let name;
      let values;
      let displayKeys;

      if (selectedMainFilter === 'Time') {
        name = selectedSubFilter;
        values = getDateRangeValues();
        displayKeys = selectedSubFilter;
      } else {
        name = selectedFileTypes;
        values = selectedFileTypes;
        displayKeys = selectedFileTypes;
      }
      dispatch(
        addColumnFilter({
          filter: {
            field: selectedMainFilter,
            values,
            name,
            displayKeys,
          },
          field: selectedMainFilter,
        }),
      );

      if (selectedMainFilter === 'Time') {
        setIsOpen(false);
      }
    }
  }, [dispatch, getDateRangeValues, selectedSubFilter, selectedFileTypes, selectedMainFilter, setIsOpen]);

  const mainFilters = () => (
    <>
      {mainItem('Type', handleMainFilterSelect, true)}
      {mainItem('Time', handleMainFilterSelect, true)}
    </>
  );

  const subFilters = () => {
    if (selectedMainFilter === 'Time') {
      return (
        <div className={styles.subFilters}>
          {constants.defaultRangeTitles.map((title) => mainItem(title, handleSubFilterSelect))}
        </div>
      );
    }
    return (
      <div className={styles.subFilters}>
        {fileTypes.map((type) => mainItem(type, handleSubFilterSelect, false, true))}
      </div>
    );
  };

  const handleClickAway = () => {
    setIsOpen(false);
    setSelectedFileTypes([]);
    setSelectedSubFilter('');
    setSelectedMainFilter('');
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={styles.root}>
        <div className={styles.header}>
          {selectedMainFilter && <LeftChevron onClick={handleLeftChevronClick} />}

          <h3>Filters</h3>
        </div>

        {!selectedMainFilter && mainFilters()}
        {selectedMainFilter && subFilters()}
      </div>
    </ClickAwayListener>
  );
};
