import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    templatesListTitle: texts?.['spaces.floatingTicketPanel.header.templatesListTitle'],
    templatesListSearchPlaceholder: texts?.['spaces.floatingTicketPanel.header.templatesListSearchPlaceholder'],
    emptyTicketTitle: texts?.['spaces.floatingTicketPanel.header.emptyTicketTitle'],
    headerTypeText: texts?.['spaces.floatingTicketPanel.header.type'],
    template: texts?.['spaces.floatingTicketPanel.header.template'],
    messageCreatedText: texts?.['spaces.floatingTicketPanel.messages.ticketCreated'],
    failedToCreateTicket: texts?.['spaces.floatingTicketPanel.messages.failedToCreateTicket'],
    areYouSureTitle: texts?.['spaces.floatingTicketPanel.messages.areYouSureTitle'],
    areYouSureBody: texts?.['spaces.floatingTicketPanel.messages.areYouSureBody'] || 'Are you sure that you want to discard this service record?',
    areYouSureOk: texts?.['spaces.floatingTicketPanel.messages.areYouSureOk'],
    areYouSureCancel: texts?.['spaces.floatingTicketPanel.messages.areYouSureCancel'] || 'Continue editing',
    cancelButtonText: texts?.['spaces.floatingTicketPanel.buttons.cancel'] || 'Cancel',
    createButtonText: texts?.['spaces.floatingTicketPanel.buttons.create'] || 'Create',
    addAttachmentText: texts?.['spaces.floatingTicketPanel.buttons.addAttachment'] || 'Add Attachment',
    incidentText: texts?.['spaces.srType.incident'],
    problemText: texts?.['spaces.srType.problem'],
    requestText: texts?.['spaces.srType.request'],
    changeText: texts?.['spaces.srType.change'],
    viewSR: texts?.['spaces.floatingTicketPanel.messages.viewSR'],
    copyURL: texts?.['spaces.header.actions.copyURL'] || 'Copy URL',
    copiedURL: texts?.['spaces.header.actions.copiedURL'] || 'Copied',
    discardChangesPrompt:
      texts?.['spaces.floatingTicketPanel.autoPopulate.prompt.discardChanges'] ||
      'Are you sure you want to abort mission? Skipping to manual would discard your description.',
    discardChangesPromptOkBtn:
      texts?.['spaces.floatingTicketPanel.autoPopulate.prompt.discardChanges.ok'] || 'Abort mission',
    discardChangesPromptCancelBtn:
      texts?.['spaces.floatingTicketPanel.autoPopulate.prompt.discardChanges.cancel'] || 'Auto fill for me',
  };

  return componentTexts;
}
