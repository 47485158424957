import { useEffect, useState } from 'react';

import { ReactComponent as EmptyRelatedItemsIcon } from 'images/icons/empty-related-items.svg';
import usePendoTrackEvents from 'common/utils/hooks/usePendoTrackEvents';
import { PENDO_TRACK_EVENTS } from 'constants';
import CircularProgress from 'common/components/circularProgress';
import { ProgressWrapper } from 'containers/App/style';
import { useFeatureFlagQuery } from 'remote-state/featureFlagsHooks';
import { FEATURE_FLAGS_KEYS } from 'constants/featureFlags';
import LinkItemBtn from './linkItemBtn';
import useTexts from './hooks/useTexts';
import LinkItemProcess from './linkItemProcess';
import {
  StyledRelatedItems,
  StyledCreateRelatedItemsDescription,
  StyledCreateRelatedItemsTitle,
  StyledEmptyRelatedItems,
} from './style';
import { RelatedItemsTables } from './relatedItemsTables';
import { useGetSrRelatedItems } from './hooks/useRelatedItems';
import { MAX_LINKED_ITEMS, SCROLL_POSITION_KEY_PREFIX } from './constants';
import { StyledLinkBtn } from './linkItemProcess/style';

const initialState = { relationship: null, items: [] };

const RelatedItems = ({ srId, srType }) => {
  const { data: relatedItems, isLoading } = useGetSrRelatedItems(srId);
  const {
    componentTexts: { emptyRelatedItemsTitle, emptyRelatedItemsDescription, linkProcessOkBtn },
  } = useTexts();
  const pendoTrackEvents = usePendoTrackEvents();
  const [isLinkingItemInProcess, setIsLinkingItemInProcess] = useState(false);
  const [relatedItemsToLink, setRelatedItemsToLink] = useState(initialState);
  const { data: isRelatedAssetAndCiFFOn } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.RELATED_ITEMS_SPACES_MVP_SR_TO_ASSET_AND_CI,
  });

  // remove tables scrolling positions on unmount
  useEffect(
    () => () => {
      const prefix = `${srId}_`;
      const positions = JSON.parse(localStorage.getItem(SCROLL_POSITION_KEY_PREFIX)) || {};
      for (const itemKey in positions) {
        if (itemKey.startsWith(prefix)) {
          delete positions[itemKey];
        }
      }
      localStorage.setItem(SCROLL_POSITION_KEY_PREFIX, JSON.stringify(positions));
    },
    [srId],
  );

  const toggleIsLinkingItemInProcess = (isLinking) => {
    setIsLinkingItemInProcess(isLinking);
  };

  const resetState = () => {
    setRelatedItemsToLink(initialState);
  };

  const handleFinishLinkProcess = () => {
    setRelatedItemsToLink(initialState);
    toggleIsLinkingItemInProcess(false);
  };

  const handleChangeRelatedItems = ({ type, payload }) => {
    setRelatedItemsToLink((prevState) => ({
      ...prevState,
      [type]: payload,
    }));
  };

  const handleLinkInitialItems = () => {
    toggleIsLinkingItemInProcess(true);
    pendoTrackEvents(PENDO_TRACK_EVENTS.First_link_click_in_related_items_empty_state_tab, {});
  };

  return (
    <StyledRelatedItems hasNoRelatedItems={relatedItems?.count === 0 && !isLinkingItemInProcess}>
      {isLoading ? (
        <ProgressWrapper sx={{ width: '100%', zIndex: 1 }}>
          <CircularProgress size="2rem" />
        </ProgressWrapper>
      ) : (
        <>
          {isLinkingItemInProcess && (
            <LinkItemProcess
              relatedItemsToLink={relatedItemsToLink}
              handleChangeRelatedItems={handleChangeRelatedItems}
              handleFinishLinkProcess={handleFinishLinkProcess}
              srId={srId}
              resetState={resetState}
              srRelatedItemsCount={relatedItems?.count}
              srRelatedItems={relatedItems}
            />
          )}
          {relatedItems?.count > 0 && !isLinkingItemInProcess && (
            <LinkItemBtn
              onClick={() => toggleIsLinkingItemInProcess(true)}
              disabled={relatedItems?.count >= MAX_LINKED_ITEMS}
            />
          )}
          {relatedItems?.count === 0 && !isLinkingItemInProcess && (
            <StyledEmptyRelatedItems>
              <EmptyRelatedItemsIcon />
              <StyledCreateRelatedItemsTitle>{emptyRelatedItemsTitle}</StyledCreateRelatedItemsTitle>
              <StyledCreateRelatedItemsDescription
                dangerouslySetInnerHTML={{
                  __html: emptyRelatedItemsDescription.replace(/__br__/g, '<br />'),
                }}
              />
              <StyledLinkBtn data-cy="link-first-item-btn" onClick={handleLinkInitialItems}>
                {linkProcessOkBtn}
              </StyledLinkBtn>
            </StyledEmptyRelatedItems>
          )}
          {relatedItems?.count > 0 && (
            <RelatedItemsTables
              relatedItems={relatedItems}
              srId={srId}
              srType={srType}
              isRelatedAssetAndCiFFOn={isRelatedAssetAndCiFFOn}
            />
          )}
        </>
      )}
    </StyledRelatedItems>
  );
};

export default RelatedItems;
