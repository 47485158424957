import { categoriesFieldId, CONSTANTS } from 'features/templateBuilder/constants';

const useUpdateProperties = (updateProperties) => {
  const handleUpdatePropertyPanel = (componentId, componentType, updatedProperties, dataOfComponentType) => {
    if (componentType === CONSTANTS.HEADER) {
      updateProperties([
        {
          header: dataOfComponentType?.map((field) => {
            let updatedField = { ...field };
            const fieldIdStr = String(field.fieldId);
            const componentIdStr = String(componentId);
            if (field.fieldId === componentId || (categoriesFieldId[componentIdStr] && categoriesFieldId[fieldIdStr])) {
              const updatedPropertyKeys = Object.keys(updatedProperties);
              updatedPropertyKeys.forEach((propertyKey) => {
                updatedField = { ...updatedField, [propertyKey]: updatedProperties[propertyKey] };
              });
            }
            return updatedField;
          }),
        },
      ]);
    } else if (componentType === CONSTANTS.FIELDS) {
      updateProperties([
        {
          sections: dataOfComponentType?.map((section) => {
            const updatedSection = { ...section };
            const updatedFieldList = section.fields.map((field) => {
              let updatedField = { ...field };
              if (field.fieldId === componentId) {
                const updatedPropertyKeys = Object.keys(updatedProperties);
                updatedPropertyKeys.forEach((propertyKey) => {
                  updatedField = { ...updatedField, [propertyKey]: updatedProperties[propertyKey] };
                });
              }
              return updatedField;
            });
            updatedSection.fields = updatedFieldList;
            return updatedSection;
          }),
        },
      ]);
    } else if (componentType === CONSTANTS.SECTION) {
      updateProperties([
        {
          sections: dataOfComponentType?.map((section) => {
            let updatedSection = { ...section };
            if ((section?.sectionId || section?.id) === componentId) {
              const updatedPropertyKeys = Object.keys(updatedProperties);
              updatedPropertyKeys.forEach((propertyKey) => {
                updatedSection = { ...updatedSection, [propertyKey]: updatedProperties[propertyKey] };
              });
            }
            return updatedSection;
          }),
        },
      ]);
    }
  };

  return { handleUpdatePropertyPanel };
};

export default useUpdateProperties;
