import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledQueue = styled(Box, {
  shouldForwardProp: (prop) => !['isPanelsOpen'].includes(prop),
})(({ isPanelsOpen }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 'calc(100vw - 100px)',
  paddingBottom: isPanelsOpen ? '25px' : '0px',
  '.header': {
    flexShrink: 0,
  },

  '.content': {
    height: '100%',
  },
}));

export const StyledContainer = styled(Box)(() => ({
  marginBottom: '16px',
}));

export const StyledCircularProgress = styled(Box, {
  shouldForwardProp: (prop) => prop,
})(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  '& .MuiCircularProgress-root': {
    color: theme.palette.generic.grey_600,
  },
  '&.floating-ticket': {
    '& .MuiCircularProgress-root': {
      color: theme?.palette?.background?.bg_loading_progressBar,
    },
  },
}));
