import { useRbTexts } from 'remote-state/applicationHooks';

export function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    uploadFromComputerText: texts?.['spaces.resolutionPanel.footer.message.upload.fromComputer'],
    uploadFromSRAttachmentsText: texts?.['spaces.resolutionPanel.footer.message.upload.fromSRAttachments'],
  };

  return componentTexts;
}
