import { styled } from '@mui/material/styles';

export const StyledContainer = styled('div')(({ theme, isActionItem }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: isActionItem ? '12px' : '36px',
  marginTop: '25px',
  fontFamily: theme?.fonts?.robotoRegular,
  fontSize: theme?.fontSize?.small,
  textAlign: 'left',

  span: {
    textAlign: 'left',
  },
  '& .more-attachments-btn': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    backgroundColor: theme.palette.grey.divider_grey_v2,
    color: theme.palette.grey.divider_grey_v5,
    fontWeight: '700',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: isActionItem && theme.palette.background.bg_hover_alt,
    },
  },
}));
