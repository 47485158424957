import { useState, useEffect, useCallback, useMemo } from 'react';
import Tooltip from 'common/components/tooltip';
import { QUERIES_KEYS } from 'constant';
import { useSR } from 'remote-state/ticketServiceHooks';
import { getFieldAttributes } from 'services/ticketService';
import { useApplicationData } from 'remote-state/applicationHooks';
import { useGetListValueById } from 'common/utils/hooks/useGetListValueById';
import { DialogContentText } from '@mui/material';
import { HeaderItemLabel, LabelWrap } from 'features/header/style';
import { DropdownSelect, itemClassNames, useDropdownControl } from '../dropdownSelect';
import { Label, messagePromptStyle } from './style';
import MessagePrompt from '../messagePrompt';
import { PromptTextField } from '../messagePrompt/contextTypes/TextField';
import useTexts from './useTexts';
import { BarLoader } from '../../../features/queue/grid/defaultCellRender/SkeletonLoader/BarLoader';

function Priority({
  isTemplatePage,
  fieldId,
  priority,
  required,
  priorityList,
  onChange,
  disabled,
  onFocus,
  handleDirty,
  dataset = {},
  dataTestid = 'priority',
  updateFieldValidation,
  className = '',
}) {
  const handleFocus = useCallback(() => {
    onFocus(fieldId);
  }, [onFocus, fieldId]);

  const { anchor, handleOpen, handleClose } = useDropdownControl(disabled, handleFocus);
  const {
    popupTitle,
    popupDescription,
    popupInputPlaceholder,
    popupOkBtnText,
    priorityText,
    emptyPriorityPlaceholder,
    mandatoryFieldError,
  } = useTexts();

  const {
    sr: { data: sr },
  } = useSR();

  const [showPrompt, setShowPrompt] = useState(false);
  const [priorityUpdateReason, setPriorityUpdateReason] = useState('');
  const [updatedValue, setUpdatedValue] = useState();

  const { data: selectedPriorityData, loaded } = useGetListValueById({
    listKey: fieldId,
    list: priorityList,
    id: priority,
  });

  const {
    data: { manualPriorityChangesEnabled },
  } = useApplicationData(QUERIES_KEYS.GENERAL_SETTINGS);

  const checkAndUpdateValidationStatus = useCallback(
    (value) => {
      let isValid = true;
      if (!value && required) {
        isValid = false;
      }

      if (updateFieldValidation && !isTemplatePage) {
        updateFieldValidation('priority', required, isValid);
      }
      return isValid;
    },
    [required, isTemplatePage, updateFieldValidation],
  );

  useEffect(() => {
    checkAndUpdateValidationStatus(selectedPriorityData?.value || null);
  }, [checkAndUpdateValidationStatus, selectedPriorityData]);

  const handlePriorityReasonUpdate = (value) => {
    setPriorityUpdateReason(value);
  };

  const handleChange = useCallback(
    (val) => {
      const identifier = val.id || val.valueKey;
      checkAndUpdateValidationStatus(identifier);

      if (handleDirty) {
        handleDirty({ priority: Number(identifier) });
      }

      if (identifier !== priority) {
        if (manualPriorityChangesEnabled && typeof sr?.id !== 'undefined' && sr?.id !== null) {
          setShowPrompt(true);
          setUpdatedValue(val);
        } else {
          onChange({ priority: Number(identifier) });
        }
      }
      handleClose();
    },
    [
      checkAndUpdateValidationStatus,
      handleDirty,
      manualPriorityChangesEnabled,
      priority,
      sr?.id,
      onChange,
      handleClose,
    ],
  );

  const handleClear = useCallback(() => {
    checkAndUpdateValidationStatus(null);
    onChange({ priority: null });
  }, [onChange, checkAndUpdateValidationStatus]);

  const handleOkClickInPrompt = () => {
    if (priorityUpdateReason) {
      const identifier = updatedValue.id || updatedValue.valueKey;
      onChange({ priority: Number(identifier) }, priorityUpdateReason);
    }
    setPriorityUpdateReason('');
    setShowPrompt(false);
  };

  const handleClosePrompt = () => {
    setPriorityUpdateReason('');
    setShowPrompt(false);
  };

  const onOpen = (event) => {
    handleOpen(event);
  };

  const backendQueryConfig = useMemo(
    () => ({
      fetchingPromise: (query) => getFieldAttributes(fieldId, { query }),
    }),
    [fieldId],
  );

  const selection = useMemo(
    () => (selectedPriorityData?.id || selectedPriorityData?.id === 0 ? [selectedPriorityData?.id] : []),
    [selectedPriorityData?.id],
  );

  return (
    <>
      <LabelWrap className={className}>
        <HeaderItemLabel className={className}>{priorityText}</HeaderItemLabel>
        {!loaded ? (
          <BarLoader width={80} showBorder />
        ) : (
          <Label
            isTemplatePage={isTemplatePage}
            stylingVariant={itemClassNames.priority}
            color={selectedPriorityData?.valueClass}
            onClick={onOpen}
            isReadOnly={disabled}
            data-testid={dataTestid}
            isEmpty={!selectedPriorityData}
            {...dataset}
          >
            <Tooltip
              isError={!priority && !isTemplatePage}
              title={!priority && !isTemplatePage && mandatoryFieldError}
              text={selectedPriorityData?.value || emptyPriorityPlaceholder}
              forceShowOnHover={!priority}
              isTruncatedText
              placement="top"
              style={{ fontFamily: 'Roboto-Bold', lineHeight: '16px' }}
            />
          </Label>
        )}
      </LabelWrap>
      <DropdownSelect
        fieldName="priority"
        options={priorityList}
        handleChange={handleChange}
        handleClose={handleClose}
        stylingVariant={itemClassNames.priority}
        anchor={anchor}
        selection={selection}
        handleOpen={handleOpen}
        backendQueryConfig={backendQueryConfig}
        handleClearValue={handleClear}
        isActionsHidden={!isTemplatePage}
      />
      {showPrompt && (
        <MessagePrompt
          open
          onOkClick={handleOkClickInPrompt}
          showCancelBtn
          showIcon={false}
          isOkButtonDisable={priorityUpdateReason.length === 0}
          onClose={handleClosePrompt}
          title={popupTitle(updatedValue.valueCaption)}
          btnOkText={popupOkBtnText}
          style={messagePromptStyle}
        >
          <DialogContentText>
            {popupDescription}
            <span className="mandatory-asterisk">*</span>
          </DialogContentText>
          <PromptTextField placeholder={popupInputPlaceholder} onChange={handlePriorityReasonUpdate} />
        </MessagePrompt>
      )}
    </>
  );
}
export default Priority;
