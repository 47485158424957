import { useFormatMilliseconds } from 'common/utils/hooks/useFormatMilliseconds';
import Tooltip from '../tooltip';
import { StyledTimerField } from './style';

export function Timer(props) {
  const { disabled, placeholder, milliseconds } = props;

  const timerRemainingTime = useFormatMilliseconds(milliseconds);
  const placeholderText = disabled ? '--' : placeholder;
  return (
    <StyledTimerField disabled={disabled}>
      {!milliseconds && placeholderText}
      <Tooltip arrow placement="top" text={timerRemainingTime} title={timerRemainingTime} isTruncatedText/>
    </StyledTimerField>
  );
}
