import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from '@tanstack/react-router';
import { selectActiveUser } from 'store/userSlice';
import { setToasterMessage } from 'store/globalSlice';
import { setPageValidations, selectIsPageValid } from 'store/pageValidationSlice';
import { useSaveTemplate } from 'remote-state/templateHooks';
import usePreviousValue from 'common/utils/hooks/usePreviousValue';
import { StyledTooltip } from 'common/components/controls/Tooltip/Tooltip';
import MessagePrompt from 'common/components/messagePrompt';
import ButtonBase from 'common/components/buttons/FormButton';
import { PAGE_VALIDATION_CONSTANTS } from 'constants/index';
import useTexts from '../useTexts';
import { mapFieldsToSectionsRows } from '../utils/mapFieldsToSectionsRows';
import { ReactComponent as SaveIcon } from '../../../images/icons/save.svg';

export default function TemplateButtons(props) {
  const { template, handleStateUpdate } = props;
  const dispatch = useDispatch();
  const router = useRouter();
  const userAccount = useSelector(selectActiveUser);
  const isPageValid = useSelector(selectIsPageValid);
  const [showUniqueNamePrompt, setShowUniqueNamePrompt] = useState(false);
  const {
    saveTemplateText,
    saveTemplateTooltipText,
    saveTemplateSuccessText,
    saveTemplateFailureText,
    uniqueNameErrorTitle,
    uniqueNameErrorText,
    uniqueNameButtonOkText,
  } = useTexts();
  const templateId = router?.latestLocation?.search.id;
  const { mutate: saveTemplate, isLoading, data, error, status, reset } = useSaveTemplate(templateId);

  const templatePageValidation = useCallback(() => {
    dispatch(
      setPageValidations({
        pageKey: '/spaces/template',
        component: `${PAGE_VALIDATION_CONSTANTS.TEMPLATE}`,
        validationDetails: {
          isValid: true,
        },
      }),
    );
  }, [dispatch]);
  const handleSave = () => {
    if (!templateId || templateId === 'new') {
      const nowDate = new Date();
      const dateCreated = nowDate.toISOString();
      handleStateUpdate([{ dateCreated }, { createdBy: userAccount?.username }]);
    }
    templatePageValidation();
    const updatedTemplate = mapFieldsToSectionsRows(template);
    saveTemplate({ updateUser: userAccount?.username, templateObject: updatedTemplate });
  };
  const isNewTemplate = !templateId || templateId === 'new';
  const prevStatus = usePreviousValue(status);

  if (prevStatus !== status) {
    if (status === 'success') {
      templatePageValidation();
      dispatch(setToasterMessage({ message: saveTemplateSuccessText }));
      if (isNewTemplate) {
        router.navigate({ to: `/spaces/template?id=${data.id}` });
      }
      reset();
    } else if (status === 'error') {
      if (error?.response?.data?.message?.toLowerCase().includes('template name already taken')) {
        setShowUniqueNamePrompt(true);
      } else {
        dispatch(setToasterMessage({ message: saveTemplateFailureText, type: 'error' }));
      }
      reset();
    }
  }

  const handleUniqueNameOkClick = () => {
    setShowUniqueNamePrompt(false);
  };

  const isButtonDisabled =
    (!isNewTemplate && isPageValid) || isLoading || template?.sections?.length === 0 || !template.templateName;
  return (
    <>
      <StyledTooltip placement="top" title={saveTemplateTooltipText} arrow>
        <ButtonBase
          style={{ marginRight: '8px' }}
          btnTheme="primary"
          text={saveTemplateText}
          disabled={isButtonDisabled}
          onClick={handleSave}
          icon={<SaveIcon />}
          hideText
          data-testid="save-template-btn"
        />
      </StyledTooltip>
      {showUniqueNamePrompt && (
        <>
          <MessagePrompt
            open
            title={uniqueNameErrorTitle}
            btnOkText={uniqueNameButtonOkText}
            onOkClick={handleUniqueNameOkClick}
          >
            {uniqueNameErrorText}
          </MessagePrompt>
        </>
      )}
    </>
  );
}
