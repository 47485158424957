import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const StyledLabelWithEllipsis = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'placeholder' || prop !== 'text',
})(({ placeholder, text, theme }) => {
  let color = !text && ['Select', '--'].includes(placeholder) && theme?.palette?.text?.secondary_txt;
  if (text === '--') {
    color = theme?.palette?.text?.secondary_txt;
  }

  return {
    fontSize: '.75rem',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textAlign: 'center',
    display: 'block',
    color: `${color} !important`,
  };
});
