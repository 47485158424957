import { useState, useRef, useEffect } from 'react';
import { Popper } from '@mui/material';

import NavItem from '..';
import NavSubList from '../navSubList';

export default function NavItemMenu({
  id,
  classNames,
  title,
  icon,
  menu,
  expanded,
  isOpen,
  onToggle,
  disableTooltip = false,
}) {
  const [marginTop, setMarginTop] = useState(0);
  const anchorRef = useRef(null);

  const toggleMenu = () => {
    onToggle(id);
  };

  useEffect(() => {
    if (window.innerHeight <= 1025) {
      setMarginTop(-50);
    } else {
      setMarginTop(0);
    }
  }, []);

  const closeMenu = (event) => {
    if (event?.target?.classList?.contains('ok-btn')) {
      return;
    }
    if (event?.currentTarget?.classList?.contains('nav-toggle-item-189')) {
      // nav-toggle-item-189 it is a 'Resource Center' button in menu. The menu should not
      // be closed after the click on this button.
      // The className comes from NavSubListToggle component, do not update it.
      return;
    }

    onToggle(null);
  };

  return (
    <>
      <NavItem
        classNames={classNames}
        title={title}
        icon={icon}
        ref={anchorRef}
        isActive={isOpen}
        handleClick={toggleMenu}
        expanded={expanded}
        disableTooltip={disableTooltip}
      />
      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        placement="right-start"
        sx={{ marginTop: `${marginTop}px!important` }}
      >
        <NavSubList expanded={expanded} title={title} sublist={menu} closeMenu={closeMenu} ref={anchorRef.current} />
      </Popper>
    </>
  );
}
