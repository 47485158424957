import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setToasterMessage } from 'store/globalSlice';
import MessagePrompt from 'common/components/messagePrompt';
import usePendoTrackEvents from 'common/utils/hooks/usePendoTrackEvents';
import { PENDO_TRACK_EVENTS, TICKET_EVENTS } from '../../../../constants';
import { StyledArchiveTicket } from './style';
import useTexts from './useTexts';
import { useArchiveSR } from '../../../../remote-state/ticketServiceHooks';
import { useAuditLog } from '../../../resolutionPanel/middlePanel/auditLog/hooks';

const TOASTER_SHOW_TIME = 10000; // 10s

function ArchiveTicket(props) {
  const { handleCloseParent, srId, customSx, isArchived } = props;
  const {
    archiveTicketButtonText,
    archiveTicketConfirmText,
    archiveTicketCancelText,
    archiveTicketWarningMessageTitle,
    archiveTicketWarningMessageText,
    archiveTicketMessageText,
    archiveTicketErrorMessageText,
    restoreTicketButtonText,
    restoreTicketWarningMessageTitle,
    restoreTicketWarningMessageText,
    restoreTicketConfirmText,
    restoreTicketMessageText,
    restoreTicketErrorMessageText,
    copyURL,
  } = useTexts(srId);

  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const pendoTrackEvents = usePendoTrackEvents();
  const { getTicketAuditLogs } = useAuditLog();
  const { archiveSR } = useArchiveSR(getTicketAuditLogs);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    handleCloseParent();
    setOpen(false);
    pendoTrackEvents(PENDO_TRACK_EVENTS.ARCHIVE_CANCELLED_FROM_POPUP, { srId });
  };

  const handleError = () => {
    setOpen(false);
  };

  const archivedTicketMessage = `
    <div class="message-wrapper">
      ${isArchived ? restoreTicketMessageText : archiveTicketMessageText}
      <div class="buttons-wrapper">
        <button class="btn-link" data-testid="copyLink">
          ${copyURL}
        </button>
      </div>
    </div>
  `;

  const handleArchive = async () => {
    try {
      handleClickClose();
      await archiveSR({ srId, archive: !isArchived });
      dispatch(setToasterMessage({
        message: archivedTicketMessage,
        showFor: TOASTER_SHOW_TIME,
        onClickFunctionName: TICKET_EVENTS.TICKET_ARCHIVED,
        id: srId,
      }));
      pendoTrackEvents(PENDO_TRACK_EVENTS.ARCHIVE_DONE_FROM_POPUP, { srId });
    } catch {
      handleError();
      dispatch(setToasterMessage({
        message: isArchived ? restoreTicketErrorMessageText : archiveTicketErrorMessageText,
        showFor: TOASTER_SHOW_TIME,
        type: 'error',
      }));
    }
  };

  return (
    <StyledArchiveTicket sx={customSx} onClick={handleClickOpen}>
      <button className="archive-ticket">
        <span className="archive-ticket-span">{isArchived ? restoreTicketButtonText : archiveTicketButtonText}</span>
      </button>
      <MessagePrompt
        open={open}
        title={isArchived ? restoreTicketWarningMessageTitle : archiveTicketWarningMessageTitle}
        children={isArchived ? restoreTicketWarningMessageText : archiveTicketWarningMessageText}
        btnOkText={isArchived ? restoreTicketConfirmText : archiveTicketConfirmText}
        btnCancelText={archiveTicketCancelText}
        onOkClick={handleArchive}
        showCancelBtn
        showIcon={false}
        onClose={handleClickClose}
      />
    </StyledArchiveTicket>
  );
}
export default ArchiveTicket;
