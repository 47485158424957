import React, { createContext, useContext, useReducer } from 'react';

const CategoryContext = createContext();
const initialState = {
  primaryCategories: [],
  secondaryCategories: [],
  thirdLevelCategories: [],
  isOpened: null,
  selectedPrimaryCategory: undefined,
  selectedSecondaryCategory: undefined,
  selectedThirdLevelCategory: undefined,
  suggestedCategoriesBySrId: {},
};

export const actionTypes = {
  REMOVE_PRIMARY_CATEGORY: 'REMOVE_PRIMARY_CATEGORY',
  ADD_PRIMARY_CATEGORY: 'ADD_PRIMARY_CATEGORY',
  ADD_SECONDARY_CATEGORY: 'ADD_SECONDARY_CATEGORY',
  ADD_THIRD_LEVEL_CATEGORY: 'ADD_THIRD_LEVEL_CATEGORY',
  SET_IS_OPENED: 'SET_IS_OPENED',
  SET_SELECTED_PRIMARY_CATEGORY: 'SET_SELECTED_PRIMARY_CATEGORY',
  SET_SELECTED_SECONDARY_CATEGORY: 'SET_SELECTED_SECONDARY_CATEGORY',
  SET_SELECTED_THIRD_LEVEL_CATEGORY: 'SET_SELECTED_THIRD_LEVEL_CATEGORY',
};
const categoryReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_PRIMARY_CATEGORY: {
      return {
        ...state,
        selectedPrimaryCategory: action.payload,
      };
    }

    case actionTypes.SET_SELECTED_SECONDARY_CATEGORY: {
      return {
        ...state,
        selectedSecondaryCategory: action.payload,
      };
    }

    case actionTypes.SET_SELECTED_THIRD_LEVEL_CATEGORY: {
      return {
        ...state,
        selectedThirdLevelCategory: action.payload,
      };
    }

    case actionTypes.REMOVE_PRIMARY_CATEGORY: {
      const { id, value } = action.payload;

      if (state.primaryCategories?.findIndex((category) => category.id === id && category.value === value) >= 0) {
        return state;
      }

      const index = state.primaryCategories?.findIndex((category) => category.id === id);

      if (index >= 0) {
        const newPrimaryCategories = [...state.primaryCategories];
        newPrimaryCategories.splice(index, 1);

        const newSecondaryCategories = state.secondaryCategories.filter((category) => category.id !== id);
        const newThirdLevelCategories = state.thirdLevelCategories.filter((category) => category.id !== id);

        return {
          ...state,
          primaryCategories: newPrimaryCategories,
          secondaryCategories: newSecondaryCategories,
          thirdLevelCategories: newThirdLevelCategories,
        };
      }

      return state;
    }
    case actionTypes.ADD_PRIMARY_CATEGORY:
      // Logic for adding primary category
      return {
        ...state,
        primaryCategories: [action.payload, ...state.primaryCategories],
      };

    case actionTypes.ADD_SECONDARY_CATEGORY:
      // Logic for adding secondary category
      return {
        ...state,
        secondaryCategories: [action.payload, ...state.secondaryCategories],
      };

    case actionTypes.ADD_THIRD_LEVEL_CATEGORY:
      // Logic for adding third level category
      return {
        ...state,
        thirdLevelCategories: [action.payload, ...state.thirdLevelCategories],
      };

    case actionTypes.SET_IS_OPENED:
      return {
        ...state,
        isOpened: action.payload,
      };

    default:
      return state;
  }
};
export const CategoryProvider = ({ children }) => {
  const [state, dispatch] = useReducer(categoryReducer, initialState);

  return <CategoryContext.Provider value={{ state, dispatch }}>{children}</CategoryContext.Provider>;
};
export const useCategory = () => {
  const context = useContext(CategoryContext);
  if (!context) {
    throw new Error('useCategory must be used within a CategoryProvider');
  }
  return context;
};
