import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  fieldSrList: {},
  forceShowError: false,
  forceFloatingTicketShowError: false,
  isNewSrFieldsValid: true,
  srStatus: {},
};

export const fieldValidationsSlice = createSlice({
  name: 'fieldValidations',
  initialState,
  reducers: {
    updateFieldValidationStatus: (state, action) => {
      const { srId, fieldName, required, isValid } = action.payload;

      if (!srId) return;

      // Initialize fieldSrList for srId and fieldName if they don't exist
      if (!state.fieldSrList[srId]) {
        state.fieldSrList[srId] = {};
      }

      if (!state.fieldSrList[srId][fieldName]) {
        state.fieldSrList[srId][fieldName] = {};
      }

      // Update field validation status
      state.fieldSrList[srId][fieldName].required = required;
      state.fieldSrList[srId][fieldName].isValid = isValid;

      // Check validation status for 'new' srId
      if (srId === 'new') {
        state.isNewSrFieldsValid = Object.values(state.fieldSrList[srId]).every((field) => field.isValid !== false);
      }
    },
    updateSrStatusValue: (state, action) => {
      const { srId, srStatusValue } = action.payload;
      // state.srStatus = {};
      state.srStatus[srId] = srStatusValue;
    },
    resetFieldValidationStatus: (state, action) => {
      const { fieldSrList, resetNew } = action.payload ?? {};
      if (fieldSrList) {
        state.fieldSrList = { ...state.fieldSrList, ...fieldSrList };
        if (fieldSrList?.new) {
          state.isNewSrFieldsValid = Object.values(fieldSrList?.new).every((field) => field.isValid !== false);
        }
      }
      if (resetNew) {
        state.fieldSrList = { new: {} };
        state.isNewSrFieldsValid = true;
      } else {
        state.fieldSrList = { new: state.fieldSrList.new };
      }
    },
    setForceShowError: (state, action) => {
      state.forceShowError = action.payload;
    }, 
    setForceFloatingTicketShowError: (state, action) => {
      state.forceFloatingTicketShowError = action.payload;
    },
  },
});
export const selectFieldValidations = (allSliceState) => allSliceState.fieldValidations;
export const selectForceShowValidationError = (allSlicesState) => allSlicesState.fieldValidations.forceShowError;
export const selectForceFloatingTicketShowValidationError = (allSlicesState) => allSlicesState.fieldValidations.forceFloatingTicketShowError;
export const selectFieldSrList = (allSlicesState) => allSlicesState.fieldValidations.fieldSrList;
export const selectIsNewSrFieldsValid = (allSlicesState) => allSlicesState.fieldValidations.isNewSrFieldsValid;
export const selectSrStatusValue = (allSlicesState) => allSlicesState.fieldValidations.srStatus;

export const { updateFieldValidationStatus, updateSrStatusValue, resetFieldValidationStatus, setForceShowError, setForceFloatingTicketShowError } =
  fieldValidationsSlice.actions;
export default fieldValidationsSlice.reducer;
export const fieldValidationsSliceName = fieldValidationsSlice.name;
