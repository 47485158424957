import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { editorActionTypes } from '../../../constants/editorActionTypes';

export const StyledQuote = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'type' && prop !== 'isSavedQuote',
})(({ theme, type, isSavedQuote }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: '4px 12px',
  borderRadius: '4px',
  maxHeight: '28px',
  gap: '8px',
  cursor: 'pointer',
  backgroundColor: type === editorActionTypes.MESSAGE ? theme.palette.background.bg_hover_main : '#ECF6FF',
  marginLeft: isSavedQuote ? '-6px' : 0,
  width: 'fit-content',
  maxWidth: isSavedQuote ? 'min(78%, 687px)' : '687px',
  marginTop: type === editorActionTypes.MESSAGE ? '7px' : '0',

  '&:hover': {
    backgroundColor: type === editorActionTypes.MESSAGE ? theme.palette.background.bg_hover_alt : '#ECF6FF',
  },
}));

export const StyledSubject = styled('span')(({ theme }) => ({
  color: theme.palette.text.txt_default,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  flexShrink: 0,
  maxWidth: '100%',
}));

export const StyledContent = styled('span')(({ theme }) => ({
  color: theme.palette.text.secondary_txt,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  flexShrink: 1,
}));
