import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledSortIcon = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  //align-items  -cross axes
  alignItems: 'center',
  //justify-content - main axes
  justifyContent: 'flex-start',
}));
