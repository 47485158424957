import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    popupTitle: (value) => `${texts?.['spaces.prompt.changePriority.title']} ${value}?`,
    popupDescription: texts?.['spaces.prompt.changePriority.description'],
    searchPriorityText: texts?.['spaces.priority.searchPriority'],
    popupInputPlaceholder: texts?.['spaces.priority.popupInputPlaceholder'],
    popupOkBtnText: texts?.['spaces.prompt.changeBtn'],
    priorityText: texts?.['spaces.header.quickFilter.fieldName.priority'],
    mandatoryFieldError: texts?.['spaces.field.tooltip.error.mandatoryFieldError'] || 'Missing required field',
    emptyStatusPlaceholder: texts?.['spaces.select.status.empty.placeholder'] || 'Select Status',
    emptyPriorityPlaceholder: texts?.['spaces.select.priority.empty.placeholder'] || 'Select Priority',
  };

  return componentTexts;
}
