import { memo } from 'react';
import { Box } from '@mui/material';

import Label from './Label';

const LabelWrapper = ({ label, required, width = 'auto', children }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', width }}>
    <Label required={required} text={label} />
    {children}
  </Box>
);

export default memo(LabelWrapper);
