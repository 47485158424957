import { useState, useCallback } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { DropDownMenu } from 'common/components/dropDownMenu';
import { SR_TYPES } from 'features/TicketPanel/constants';
import { ReactComponent as Settings } from 'images/icons/settings.svg';
import { StyledTemplateCheckboxItem } from '../StyledTemplateCheckboxItem';
import useTexts from '../useTexts';

export default function TemplateSettingMenu(props) {
  const { srType, visibleInSSP, visibleToAutomationPanel, handleChange } = props;
  const handleSettingChanges = (property, e) => {
    handleChange([{ [property]: e.target.checked }]);
  };
  const [menuOpen, setMenuOpen] = useState(false);
  const { visibleInSspText, visibleToAutomationPanelText } = useTexts();
  const handleOpenMenu = useCallback((val) => {
    setMenuOpen(val);
  }, []);

  const isVisibleInSSPAvailable = srType === SR_TYPES.incident || srType === SR_TYPES.request;
  const isVisibleToAutomationPanelAvailable = srType !== SR_TYPES.incident;

  if (!isVisibleInSSPAvailable && !isVisibleToAutomationPanelAvailable) return false;

  const settingMenuItems = [
    {
      checked: visibleInSSP,
      label: visibleInSspText,
      property: 'visibleInSelfServicePortal',
      display: isVisibleInSSPAvailable,
    },
    {
      checked: visibleToAutomationPanel,
      label: visibleToAutomationPanelText,
      property: 'visibleToAutomationPanel',
      display: isVisibleToAutomationPanelAvailable,
    },
  ];

  return (
    <DropDownMenu handleOpen={handleOpenMenu} open={menuOpen} icon={<Settings />} data-cy="template-settings">
      {settingMenuItems.map((item) => {
        if (!item.display) return;
        return (
          <MenuItem data-testid="setting-menu-item" data-cy={`setting-menu-item-${item.property}`} key={item.label}>
            <StyledTemplateCheckboxItem
              checked={item.checked}
              label={item.label}
              handleChange={(event) => handleSettingChanges(item.property, event)}
            />
          </MenuItem>
        );
      })}
    </DropDownMenu>
  );
}
