import { forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Paper, Fade } from '@mui/material';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import CustomScrollBar from 'common/components/customScroll';
import { StyledPopper, StyledHeaderProps, StyledNoResults, StyledMenuItem } from './style';
import { Tooltip } from '../Tooltip';

const customScrollProps = {
  style: { display: 'flex' },
  autoHeight: true,
  autoHeightMax: 230,
  renderThumbVerticalStyle: { width: '4px', marginLeft: '-17px' },
  renderThumbHorizontalStyle: { height: 0 },
  renderTrackVerticalStyle: { width: '0px', padding: 0, right: '-7px' },
  renderViewStyle: { flex: '1' },
};

/**
 * Select Component with custom scroll and virtualization
 * @param {*} ref
 * @param {*} open - REQUIRED - indicating if the dropdown is open(passed in rest)
 * @param {*} anchorEl - used to set the position of the dropdown
 * @param {*} headerProps - dropdown header elements
 * @param {*} options - array of options
 * @param {*} value - dropdown value
 * @param {*} width - dropdown width
 * @param {*} menuItemHeight - item height, default set to 46
 * @param {*} maxDisplayedMenuItems - max item count to display, default set to 100
 * @param {*} handleSelect - callback function invokes when selecting an item from the dropdown
 * @param {*} handleClose - callback function to close the menu
 * @param {*} keyExpr - key to target the selected (sent/received) value
 * @param {*} displayExpr - key to target display value/caption
 */
export const Select = forwardRef(
  (
    {
      anchorEl,
      headerProps,
      handleSelect,
      options,
      value,
      width,
      menuItemHeight,
      handleClose,
      maxDisplayedMenuItems,
      displayExpr,
      keyExpr,
      fieldName,
      ...rest
    },
    forwardedRef,
  ) => {
    useEffect(() => {
      const listener = (event) => {
        if (event.key === 'Escape') {
          handleClose();
        }
      };
      window.addEventListener('keydown', listener);

      return () => {
        window.removeEventListener('keydown', listener, true);
      };
    }, [handleClose]);

    const handleItemClick = ({ oldValue, newValue, displayValue, fieldName }) => {
      handleSelect({ oldValue, newValue, displayValue, fieldName });
    };

    return (
      <StyledPopper
        placement="bottom-start"
        {...rest}
        width={width}
        data-testid="common-select"
        anchorEl={anchorEl}
        ref={forwardedRef}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <StyledHeaderProps>{headerProps}</StyledHeaderProps>
              {options?.length ? (
                <AutoSizer disableHeight style={{ width, padding: '0px 0px 8px 8px' }}>
                  {() => (
                    <CustomScrollBar {...customScrollProps}>
                      <FixedSizeList
                        height={
                          menuItemHeight *
                          (options?.length > maxDisplayedMenuItems ? maxDisplayedMenuItems : options.length)
                        }
                        itemCount={options?.length > maxDisplayedMenuItems ? maxDisplayedMenuItems : options.length}
                        itemSize={menuItemHeight}
                        width={width}
                      >
                        {({ index, style }) => {
                          const item = options[index];
                          return (
                            <StyledMenuItem
                              key={item.id}
                              style={style}
                              onClick={() =>
                                handleItemClick({
                                  oldValue: value,
                                  newValue: item[keyExpr],
                                  displayValue: item[displayExpr],
                                  fieldName,
                                })
                              }
                              selected={item[keyExpr] === value}
                            >
                              <Tooltip arrow title={item[displayExpr]}>
                                {item[displayExpr]}
                              </Tooltip>
                            </StyledMenuItem>
                          );
                        }}
                      </FixedSizeList>
                    </CustomScrollBar>
                  )}
                </AutoSizer>
              ) : (
                <StyledNoResults>No Results</StyledNoResults>
              )}
            </Paper>
          </Fade>
        )}
      </StyledPopper>
    );
  },
);

Select.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  headerProps: PropTypes.node,
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.number,
  menuItemHeight: PropTypes.number,
  maxDisplayedMenuItems: PropTypes.number,
  keyExpr: PropTypes.string,
  displayExpr: PropTypes.string,
  handleSelect: PropTypes.func,
  handleClose: PropTypes.func,
};

Select.defaultProps = {
  anchorEl: null,
  headerProps: null,
  options: [],
  value: '',
  width: 224,
  menuItemHeight: 46,
  maxDisplayedMenuItems: 100,
  displayExpr: 'value',
  keyExpr: 'id',
  handleSelect: () => {},
  handleClose: () => {},
};
