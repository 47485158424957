import { useState, useCallback } from 'react';
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary } from '@mui/material';

export function Accordion({ id, initialOpen, className, closeIcon, openIcon, summary, children, ...props }) {
  const [expanded, setExpanded] = useState(initialOpen);

  const toggleAccordion = useCallback(() => {
    setExpanded((prevValue) => !prevValue);
  }, []);

  const currentIcon = openIcon && expanded ? openIcon : closeIcon;

  return (
    <MuiAccordion
      id={id}
      className={className}
      aria-label={className}
      onChange={toggleAccordion}
      expanded={expanded}
      {...props}
    >
      <AccordionSummary expandIcon={currentIcon} expanded={expanded} aria-controls="panel1bh-content">
        {summary}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MuiAccordion>
  );
}
