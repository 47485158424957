import { Card, CardContent } from '@mui/material';
import mediumYellowStarsIcon from 'images/icons/generativeAi/yellow_stars_24x24.gif';
import { ReactComponent as ChildRelationImage } from 'images/icons/generativeAi/categories/child_relation_line.svg';
import { useApplicationData } from 'remote-state/applicationHooks';
import { QUERIES_KEYS } from 'constant';
import { PENDO_TRACK_EVENTS } from 'constants/index';
import { useCallback, useState } from 'react';
import usePendoTrackEvents from 'common/utils/hooks/usePendoTrackEvents';
import { useTexts } from './useTexts';
import { StyledSuggestedCategory } from './StyledSuggestedCategory';
import { CategoryChip } from './categoryChip';

export const SuggestedCategory = ({ srId, suggestedCategory, selectAiSuggestedCategories, style }) => {
  const { data: isThirdLevelCategoryEnabled } = useApplicationData(QUERIES_KEYS.IS_EXTERNAL_MODE);
  const { primaryCategory, secondaryCategory, thirdLevelCategory } = suggestedCategory;
  const texts = useTexts();
  const [chipsHovered, setChipsHovered] = useState([false, false, false]);

  const pendoTrackEvents = usePendoTrackEvents();

  const onMouseEnterChip = (index) => () => {
    const newChipsHovered = [...chipsHovered];
    newChipsHovered[index] = true;
    setChipsHovered(newChipsHovered);
  };

  const onMouseLeaveChip = (index) => () => {
    const newChipsHovered = [...chipsHovered];
    newChipsHovered[index] = false;
    setChipsHovered(newChipsHovered);
  };

  const isForceHover = useCallback(() => chipsHovered.some((isHovered) => isHovered), [chipsHovered]);
  const handleCategoryChipClick = () => {
    pendoTrackEvents(PENDO_TRACK_EVENTS.SUGGESTED_CATEGORY_SELECTED, { srId });
    selectAiSuggestedCategories();
  };
  return (
    <StyledSuggestedCategory style={style}>
      <Card data-testid="suggested-categories-card">
        <CardContent>
          <div className="header">
            <img className="stars-icon" src={mediumYellowStarsIcon} alt="" />
            {texts.generativeAiSuggested}
          </div>
          <div className="description">{texts.clickingThisWillAutoFill}</div>
          <div className="suggested-category-container">
            <CategoryChip
              onClick={handleCategoryChipClick}
              categoryName={primaryCategory}
              onMouseEnter={onMouseEnterChip(0)}
              onMouseLeave={onMouseLeaveChip(0)}
              forceHover={isForceHover()}
            />
            <div className="offset-container">
              <ChildRelationImage className="child-relation" />
              <CategoryChip
                onClick={handleCategoryChipClick}
                categoryName={secondaryCategory}
                onMouseEnter={onMouseEnterChip(1)}
                onMouseLeave={onMouseLeaveChip(1)}
                forceHover={isForceHover()}
              />
            </div>
            {isThirdLevelCategoryEnabled && (
              <div className="offset-container third-level">
                <ChildRelationImage className="child-relation" />
                <CategoryChip
                  onClick={handleCategoryChipClick}
                  categoryName={thirdLevelCategory}
                  onMouseEnter={onMouseEnterChip(2)}
                  onMouseLeave={onMouseLeaveChip(2)}
                  forceHover={isForceHover()}
                />
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </StyledSuggestedCategory>
  );
};
