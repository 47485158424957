import { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { addColumnFilter, removeColumnFilter } from 'features/queue/slice';
import { useApplicationData } from 'remote-state/applicationHooks';
import { QUERIES_KEYS } from 'constant';
import { BASIC_EDITION } from 'features/settings/constants';
import { useFieldAttributes, useSrTypeFilterCounters } from 'remote-state/ticketServiceHooks';
import { PERFORMANCE_MEASURE } from 'constants/performanceMeasure';
import { createPerformanceMarkWithData } from 'common/utils/performanceManager';
import { FIELD_TYPE } from 'common/components/grid/constants';
import { APP_CONSTANTS } from 'constants/index';
import { SR_TYPE_FIELD_ID } from '../grid/constants';
import { StyledToggleButtonGroup } from './style';
import { ToggleButtonsWrapper } from './toggleButtons';
import { IncidentsLabel } from './incidentsLabel';
import useTexts from './useTexts';

export const QuickFiltersSelector = () => {
  const dispatch = useDispatch();
  const [quickFilters, setQuickFilters] = useState();

  const selectSRTypeFieldIdRef = SR_TYPE_FIELD_ID;
  const { data: SrTypeFilterCounters } = useSrTypeFilterCounters();
  const { data: edition } = useApplicationData(QUERIES_KEYS.EDITION);
  const isItsmEdition = edition !== BASIC_EDITION;
  const fieldAttributesInput = useMemo(() => [{ fieldId: selectSRTypeFieldIdRef }], [selectSRTypeFieldIdRef]);
  const filters = useFieldAttributes(fieldAttributesInput)?.[0]?.data?.values;
  const { allText } = useTexts();

  const handleTypeFilterClick = (filter) => {
    createPerformanceMarkWithData(PERFORMANCE_MEASURE.QUEUE.SR_TYPE_CHANGE, {
      typeId: filter?.id,
      typeName: filter?.value,
    });

    sessionStorage.setItem(APP_CONSTANTS.SELECTED_FILTER, JSON.stringify({ 
      displayName: filter?.valueToDisplay || filter?.value?.charAt(0)?.toUpperCase() + filter?.value?.slice(1),
      id: filter?.idForFilter || filter?.id,
      value: [filter?.value]
    }));

    if (filter?.value === 'All') {
      dispatch(removeColumnFilter({ field: FIELD_TYPE.srType, isQuickFilter: true }));
    } else {
      dispatch(
        addColumnFilter({
          filter: {
            name: 'Type',
            type: 'equals',
            field: FIELD_TYPE.srType,
            fieldId: selectSRTypeFieldIdRef,
            values: [filter?.idForFilter || filter?.id],
            displayKeys: [filter?.value],
            fieldType: { typeName: 'SELECT', id: selectSRTypeFieldIdRef },
            isQuickFilter: true,
          },
          field: FIELD_TYPE.srType,
          isQuickFilter: true,
        }),
      );
    }
  };

  useEffect(() => {
    const isContainingAll = filters?.find((filter) => filter.value === 'all');
    if (!isContainingAll && filters) {
      const allQF = {
        value: 'All',
        valueForFilter: 'All',
        valueToDisplay: allText,
      };
      setQuickFilters([allQF, ...filters]);
    }

    const selectedFilter = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.SELECTED_FILTER));

    if (selectedFilter) {
      if (selectedFilter?.value[0] === 'All') {
        dispatch(removeColumnFilter({ field: FIELD_TYPE.srType, isQuickFilter: true }));
      } else {
        dispatch(
          addColumnFilter({
            filter: {
              name: 'Type',
              type: 'equals',
              field: FIELD_TYPE.srType,
              fieldId: selectSRTypeFieldIdRef,
              values: [selectedFilter?.id],
              displayKeys: selectedFilter?.value,
              fieldType: { typeName: 'SELECT', id: selectSRTypeFieldIdRef },
              isQuickFilter: true,
            },
            field: FIELD_TYPE.srType,
            isQuickFilter: true,
          }),
        ); 
      }
    } else {
      dispatch(removeColumnFilter({ field: FIELD_TYPE.srType, isQuickFilter: true }));
    }
  }, [filters, allText, dispatch, selectSRTypeFieldIdRef]);

  return (
    <StyledToggleButtonGroup exclusive aria-label="Quick Filters" value="Quick Filter">
      {quickFilters && isItsmEdition && SrTypeFilterCounters && (
        <ToggleButtonsWrapper
          quickFilters={quickFilters}
          handleTypeFilterClick={handleTypeFilterClick}
          SrTypeFilterCounters={SrTypeFilterCounters}
        />
      )}
      {quickFilters && !isItsmEdition && SrTypeFilterCounters && (
        <IncidentsLabel SrTypeFilterCounters={SrTypeFilterCounters} />
      )}
    </StyledToggleButtonGroup>
  );
};
