import { ReactComponent as WarningIcon } from 'images/icons/WarningSign.svg';
import MessagePrompt from 'common/components/messagePrompt';
import useTexts from './useTexts';

export default function DeleteViewModal({ open, onClick, onClose }) {
  const { deleteBtnText, popupBodyText, popupCancelBtnText, popupTitle } = useTexts();
  return (
    <MessagePrompt
      open={open}
      showCancelBtn
      strippedDesign
      onClose={onClose}
      Icon={WarningIcon}
      title={popupTitle}
      onOkClick={onClick}
      btnOkText={deleteBtnText}
      btnCancelText={popupCancelBtnText}
    >
      {popupBodyText}
    </MessagePrompt>
  );
}
