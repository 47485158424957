import { createTheme } from '@mui/material';

export const theme = (styleOverrides) =>
  createTheme({
    components: {
      MuiDialog: {
        styleOverrides: {
          container: {
            // backdropFilter: 'blur(10px)',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            minHeight: '0',
            padding: '12px',
            borderRadius: '4px',
            boxShadow: '0 4px 12px rgba(118, 130, 150, 0.25)',
            backgroundColor: '#fff',
            overflowY: 'hidden',
            maxWidth: '630px !important',
            alignItems: 'center',
            flexDirection: 'row !important',
            top: styleOverrides?.top ? styleOverrides?.top : 'calc(-50% + 18px + 30px)',
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgb(255 255 255 / 0%)',
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            padding: '0 !important',
            fontFamily: 'Roboto-Regular',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '20px',
            color: '#4F4F4F',
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: 0,
            overflowY: 'inherit !important',
          },
        },
      },
      MuiDialogContentText: {
        styleOverrides: {
          root: {
            width: 'auto',
            margin: '10px 0 0',
            fontFamily: 'Roboto-Regular',
            fontSize: '14px',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '1.43',
            letterSpacing: 'normal',
            textAlign: 'left',
            color: 'rgba(48, 48, 48, 0.65)',
            height: 'auto',
            whiteSpace: 'pre-wrap',
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
    },
  });
