import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setToasterMessage } from 'store/globalSlice';
import { setActiveView, selectActiveView, selectIsViewsEdited, selectOriginalView } from 'features/queue/slice';
import { useDeleteQueueView, useUpdateQueueView, useCreateQueueView } from 'remote-state/queueViewsHooks';
import SaveViewAsModal from 'common/components/queueViews/saveViewAsModal';
import DeleteViewModal from 'common/components/queueViews/deleteViewModal';
import { convertFromClientField } from 'common/utils/fieldUtils';
import useTexts from '../useTexts';
import { StyledViewConfiguration } from './style';
import { ViewList } from './viewList';
import { ViewListAction } from './viewListAction';
import ViewActionButtons from './ViewActionButtons';

export const ViewConfiguration = () => {
  const [isSaveAsPopUpOpen, setIsSaveAsPopUpOpen] = useState(false);
  const [isDetailsPopUpOpen, setIsDetailsPopUpOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { mutate: deleteQueueView } = useDeleteQueueView();
  const { mutate: createView } = useCreateQueueView();
  const { mutate: updateView } = useUpdateQueueView();
  const activeView = useSelector(selectActiveView);
  const isViewEdited = useSelector(selectIsViewsEdited);
  const [showUniqueNameErrorText, setShowUniqueNameErrorText] = useState(false);
  const CONFLICT_ERROR_CODE = 409;

  const originalView = useSelector(selectOriginalView);

  const { queueViewsMessageSaveSuccess, queueViewsMessageDeletedSuccessfully } = useTexts();
  const dispatch = useDispatch();

  const onErrorCallback = useCallback(
    (error) => {
      if (error.response) {
        if (error.response.status === CONFLICT_ERROR_CODE) {
          setShowUniqueNameErrorText(true);
        } else {
          setIsDetailsPopUpOpen(false);
          dispatch(
            setToasterMessage({
              message: error.response.data.message,
            }),
          );
        }
      } else {
        setIsDetailsPopUpOpen(false);
        dispatch(
          setToasterMessage({
            message: error.response.data.message,
          }),
        );
      }
    },
    [dispatch],
  );

  const onSaveClicked = ({ viewName, visibility, visibleForGroupIds }) => {
    const createObj = {
      ...activeView,
      name: viewName,
      visibility,
      visibleForGroupIds,
      columnsConfig: {
        sorts: activeView?.columnsConfig.customSortModel,
        filters: activeView?.columnsConfig.customFilterModel,
        columnConfiguration: activeView?.columnsConfig.columnsOrder?.map((col) => convertFromClientField(col)),
        isQuickFilterApplied: activeView?.columnsConfig.isQuickFilterApplied,
      },
    };

    delete createObj.id;
    delete createObj.createdDate;
    delete createObj.modifiedDate;
    setShowUniqueNameErrorText(false);

    createView(createObj, {
      onSuccess: () => {
        setIsSaveAsPopUpOpen(false);
        dispatch(
          setToasterMessage({
            message: queueViewsMessageSaveSuccess,
          }),
        );
      },

      onError: onErrorCallback,
    });
  };

  const onSaveDetailsClicked = ({ viewName, visibility, visibleForGroupIds }) => {
    const updateObj = {
      ...activeView,
      ...originalView,
      name: viewName,
      visibility,
      visibleForGroupIds,
      columnsConfig: {
        sorts: originalView?.columnsConfig.customSortModel,
        filters: originalView?.columnsConfig.customFilterModel,
        columnConfiguration: originalView?.columnsConfig.columnsOrder?.map((col) => convertFromClientField(col)),
        isQuickFilterApplied: activeView?.columnsConfig.isQuickFilterApplied,
      },
    };

    delete updateObj.createdDate;
    delete updateObj.modifiedDate;
    setShowUniqueNameErrorText(false);
    updateView(updateObj, {
      onSuccess: () => {
        setIsDetailsPopUpOpen(false);
        dispatch(
          setToasterMessage({
            message: queueViewsMessageSaveSuccess,
          }),
        );
      },

      onError: onErrorCallback,
    });
  };

  const handleDeleteView = () => {
    deleteQueueView(activeView.id, {
      onSuccess: () =>
        dispatch(
          setToasterMessage({
            message: queueViewsMessageDeletedSuccessfully,
          }),
        ),
    });

    setIsDeleteModalOpen(false);
  };

  const handleCloseModals = () => {
    setShowUniqueNameErrorText(false);
    setIsDeleteModalOpen(false);
    setIsSaveAsPopUpOpen(false);
    setIsDetailsPopUpOpen(false);
  };

  const handleViewChange = useCallback(
    (value) => {
      dispatch(setActiveView(value));
    },
    [dispatch],
  );

  return (
    <StyledViewConfiguration data-testid="view-configuration">
      <DeleteViewModal open={isDeleteModalOpen} onClick={handleDeleteView} onClose={handleCloseModals} />
      {(isSaveAsPopUpOpen || isDetailsPopUpOpen) && (
        <SaveViewAsModal
          onClose={handleCloseModals}
          onSaveClicked={onSaveClicked}
          onSaveDetailsClicked={onSaveDetailsClicked}
          view={activeView}
          isDetailsPopUpOpen={isDetailsPopUpOpen}
          open={isSaveAsPopUpOpen || isDetailsPopUpOpen}
          uniqueNameError={showUniqueNameErrorText}
          setShowUniqueNameErrorText={setShowUniqueNameErrorText}
        />
      )}
      <ViewList onChange={handleViewChange} isViewEdited={isViewEdited} />
      {isViewEdited && (
        <ViewActionButtons onErrorCallback={onErrorCallback} setIsSaveAsPopUpOpen={() => setIsSaveAsPopUpOpen(true)} />
      )}
      <ViewListAction setIsDetailsPopUpOpen={setIsDetailsPopUpOpen} setIsDeleteModalOpen={setIsDeleteModalOpen} />
    </StyledViewConfiguration>
  );
};
