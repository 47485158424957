export const APP_CONSTANTS = {
  API_URL_BASE: 'http://localhost:80',
  ACCOUNT_ID_LOCAL_KEY: 'account_id',
  ACCOUNT_TARGET: 'target',
  IS_QA_MODE: 'is_qa_mode',
  PREVIEW_TEMPLATE_OR_ID_KEY: 'previewTemplateOrId',
  PASSWORD_SERVICE_URL: (accountId) => `/EndUserSelfService.jsp?accountid=${accountId}&noframe=YES`,
  GUEST_LOGIN_URL: '/guestLogin',
  SIGN_UP_URL: (accountId) => `/SignUp.jsp?accountid=${accountId}`,
  THEME: 'spaces-theme',
  DIRECTION: 'dir',
  SESSION_ID: 'JSESSIONID',
  SERVER_ID: 'SERVERID',
  IN_SPACES_IFRAME_COOKIE: 'inSpacesIframe',
  IN_SPACES_SWITCH_FEEDBACK_SHOWN_COOKIE: 'inSpacesSwitchFeedback',
  IS_MENU_EXPANDED: 'isMenuExpanded',
  IS_CLASSIC_REDIRECTED: 'isClassicRedirected',
  SELECTED_FILTER: 'selectedFilter',
  DONT_SHOW_AGAIN: 'dontShowAgain',
};
