import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useStyleHelpers } from '../dropdownSelect/hooks';

export const messagePromptStyle = {
  MuiPaper: {
    minWidth: '414px',
    borderRadius: '4px',
    gap: '24px',
  },
  MuiDialogTitle: {
    color: '#4F4F4F',
    fontFamily: 'Roboto-Medium',
    lineHeight: '24px',
    fontSize: '18px',
    fontStyle: 'normal',
  },
  MuiTypography: {
    fontFamily: 'Roboto-Regular',
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: '18px',
    padding: '2px 0px !important',
  },
  MuiDialogContentText: {
    fontWeight: '400',
    margin: '0',
  },
  MuiFormHelperText: {
    fontFamily: 'Roboto-Regular',
  },
};

const classNameToColorMap = {
  regular: {
    0: '#83b0f4',
    1: '#f4c449',
    2: '#f76969',
  },
  hover: {
    0: '#6092db',
    1: '#dbaa2c',
    2: '#de4949',
  },
};

export const customStyle = (valueClass) => ({
  color: '#fff',
  backgroundColor: classNameToColorMap.regular[valueClass],
  maxWidth: '75px',
});

export const customHeaderStyles = ({ valueClass } = {}) => ({
  ...customStyle(valueClass),
  hover: {
    backgroundColor: classNameToColorMap.hover[valueClass],
    color: '#fff',
    fontFamily: 'Roboto-Regular',
  },
});

export const Label = styled(Box, {
  shouldForwardProp: (prop) => !['stylingVariant', 'isEmpty', 'color', 'isReadOnly', 'isTemplatePage'].includes(prop),
})(({ theme, stylingVariant, color, isReadOnly, isTemplatePage, isEmpty }) => {
  const { selectItemColor, selectItemBorderRadius, selectItemBgColor, selectHoveredItemBgColor } = useStyleHelpers({
    theme,
    stylingVariant,
    color,
  });

  const emptyStyles = {
    color: isTemplatePage ? theme.palette.grey.bt_def_grey_reg : theme.palette.labels.bt_red_reg,
    backgroundColor: '#FFFFFF',
    border: `1px solid ${isTemplatePage ? theme.palette.grey.bt_def_grey_reg : theme.palette.labels.bt_red_reg}`,
    padding: '3px 12px',
    '& .MuiTypography-root': {
      fontFamily: theme.fonts.robotoRegular,
      fontWeight: '400',
    },
    '&:hover': {
      backgroundColor: isTemplatePage ? theme.palette.labels.bt_black_light : theme.palette.labels.bt_red_light,
      color: isTemplatePage ? theme.palette.labels.bt_black_reg : theme.palette.labels.bt_red_hover,
      cursor: 'pointer',
    },
  };

  return {
    display: 'flex',
    fontSize: '0.75rem',
    fontFamily: theme.fonts.robotoBold,
    letterSpacing: '0.035em',
    padding: theme.spacing(0.5, 1.5),
    borderRadius: selectItemBorderRadius(),
    color: selectItemColor(),
    backgroundColor: selectItemBgColor(),
    alignItems: 'center',
    boxSizing: 'border-box',
    '& .MuiTypography-root': {
      alignSelf: 'center',
    },
    '&:hover': {
      cursor: !isReadOnly ? 'pointer' : 'default',
      backgroundColor: selectHoveredItemBgColor(isReadOnly),
    },
    ...(isEmpty ? emptyStyles : {}),

    '@media only screen and (max-width: 1920px)': {
      maxWidth: isTemplatePage ? 'initial' : '115px',
    },
  };
});
