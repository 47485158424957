import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { setToasterMessage } from 'store/globalSlice';
import { deleteSrById } from 'services/ticketService';
import MessagePrompt from 'common/components/messagePrompt';
import { FEATURE_FLAGS_KEYS } from 'constants/featureFlags';
import { useFeatureFlagQuery } from 'remote-state/featureFlagsHooks';
import usePendoTrackEvents from 'common/utils/hooks/usePendoTrackEvents';
import { PENDO_TRACK_EVENTS } from '../../../../constants';
import { StyledDeleteTicket } from './style';
import useTexts from './useTexts';

function DeleteTicket(props) {
  const { handleCloseParent, onClickParent, srId, customSx } = props;
  const queryClient = useQueryClient();
  const {
    deleteTicketText,
    deleteTicketMessageText,
    deleteTicketCancelText,
    deleteTicketErrorText,
    deleteTicketWarningMessageText,
    deleteTicketErrorDescriptionText,
    deleteTicketButtonText,
    gotItText,
  } = useTexts(srId);
  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();

  const { data: isWebsocketSyncFFOn } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.REAL_TIME_UPDATES,
    defaultValue: false,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
    handleCloseParent();
  };

  const handleError = () => {
    setOpen(false);
    setIsError(true);
  };

  const pendoTrackEvents = usePendoTrackEvents();

  const handleDelete = async () => {
    try {
      await deleteSrById(srId);
      setOpen(false);
      dispatch(setToasterMessage({ message: deleteTicketMessageText() }));
      onClickParent();
      if (!isWebsocketSyncFFOn) {
        queryClient.invalidateQueries({ queryKey: ['queueData'] });
      }
      // dispatch(setIsRefreshNeeded(true));
      pendoTrackEvents(PENDO_TRACK_EVENTS.DELETE_TICKT_WAS_CLICKED, { srId });
      handleClickClose();
    } catch {
      console.log('can`t delete');
      handleError();
    }
  };

  return (
    <StyledDeleteTicket sx={customSx} onClick={handleClickOpen}>
      {/* Check in what current layout it is now, and according to that the appropriate class. */}
      <button className="delete-ticket">
        <span className="delete-ticket-span">{deleteTicketButtonText}</span>
      </button>
      <MessagePrompt
        open={open}
        title={deleteTicketWarningMessageText()}
        btnOkText={deleteTicketText}
        btnCancelText={deleteTicketCancelText}
        onOkClick={handleDelete}
        showCancelBtn
        onClose={handleClickClose}
        isOkButtonDisable={!srId || Number.isNaN(+srId)}
      />
      <MessagePrompt
        open={isError}
        title={deleteTicketErrorText}
        btnOkText={gotItText}
        onClose={handleClickClose}
        onOkClick={handleClickClose}
      >
        <div>{deleteTicketErrorDescriptionText()}</div>
      </MessagePrompt>
    </StyledDeleteTicket>
  );
}
export default DeleteTicket;
