import PropTypes from 'prop-types';
import { StyledTitle } from './styles';

const Title = ({ title, highlightText, highlightColor, className, onClick, id }) => {
  const splitTitle = title.split(new RegExp(`(${highlightText})`, 'i'));

  return (
    <StyledTitle
      data-testid={title.replaceAll(' ', '_')}
      id={id}
      className={className}
      highlightColor={highlightColor}
      onClick={onClick}
    >
      {splitTitle.map((titleChunk, index) => (
        <span
          className={titleChunk.localeCompare(highlightText, undefined, { sensitivity: 'base' }) === 0 && 'highlight'}
          key={`${titleChunk}-${index}`}
        >
          {titleChunk}
        </span>
      ))}
    </StyledTitle>
  );
};

Title.propTypes = {
  title: PropTypes.string,
  highlightText: PropTypes.string,
  highlightColor: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

Title.defaultProps = {
  title: '',
  highlightText: '',
};

export default Title;
