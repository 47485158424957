import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from '@tanstack/react-router';
import { v4 as uuidv4 } from 'uuid';
import { useTicketAttachments } from 'remote-state/attachments';
import { addUpload, removeUpload, removeUploads, updateUpload } from 'store/attachmentsSlice';
import { setToasterMessage } from 'store/globalSlice';
import { setPageValidations } from 'store/pageValidationSlice';
import { SubResourceType } from 'services/attachments';
import { PAGE_VALIDATION_CONSTANTS, ROUTES_PATHS } from 'constants/index';
import useAttachmentsTexts from 'remote-state/attachments/useTexts';
import useTexts from './useTexts';
import { useTabs } from '../../auditLog/hooks/useTabs';
import { MIDDLE_PANEL_TAB_NAMES } from '../../constants';
import { useAuditLog } from '../../auditLog/hooks';
import { ActiveUploadsEnum } from './activeUploadsEnum';

export const useUploadAttachments = ({ showTicketAsTab, actionItemId }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const {
    createSrAttachment,
    createSrMultipleAttachments,
    updateSRAttachments,
    updateMultipleSrAttachments
  } = useTicketAttachments();
  const { successfulUploadTexts, modalTitleText, modalDescriptionText, modalBtnOkText, modalBtnCancelText } =
    useTexts();
  const { uploadFailed } = useAttachmentsTexts();
  const srId = router.latestLocation?.search?.id;
  const activeUploadsRef = useRef([]);
  const { getTicketAuditLogs } = useAuditLog();
  const { selectedTabName } = useTabs({ srId, showTicketAsTab });
  const reportValidation = (isValid) => {
    dispatch(
      setPageValidations({
        pageKey: ROUTES_PATHS.TICKET_URL,
        component: `${PAGE_VALIDATION_CONSTANTS.ATTACHMENTS}`,
        validationDetails: {
          isValid,
          promptTexts: !isValid && {
            title: modalTitleText,
            description: modalDescriptionText,
            btnOkText: modalBtnOkText,
            btnCancelText: modalBtnCancelText,
          },
        },
      }),
    );
  };
  const handleActiveUploads = ({ type, payload }) => {
    switch (type) {
      case ActiveUploadsEnum.ADD_UPLOAD:
        dispatch(addUpload(payload));
        break;
      case ActiveUploadsEnum.REMOVE_UPLOAD:
        dispatch(removeUpload(payload));
        break;
      case ActiveUploadsEnum.REMOVE_UPLOADS:
        dispatch(removeUploads(payload));
        break;
      case ActiveUploadsEnum.UPDATE_UPLOAD:
        dispatch(updateUpload(payload));
        break;
      default:
        break;
    }
    reportValidation(false);
  };

  const handleUploadProgress = (id, progressEvent) => {
    handleActiveUploads({
      type: ActiveUploadsEnum.UPDATE_UPLOAD,
      payload: {
        id,
        progress: Math.round((progressEvent.loaded * 100) / progressEvent.total),
      },
    });
  };

  const handleUploadNewAttachment = (file) => {
    const id = uuidv4();
    handleActiveUploads({ type: ActiveUploadsEnum.ADD_UPLOAD, payload: { fileName: file.name, id, actionItemId } });
    activeUploadsRef.current.push(id);
    createSrAttachment({
      file,
      subResourceType: actionItemId ? SubResourceType.ACTION_ITEM : SubResourceType.SR,
      subResourceId: actionItemId || srId,
      onProgressChange: (progressEvent) => handleUploadProgress?.(id, progressEvent),
      id,
    })
      .then((data) => {
        updateSRAttachments(data);
        handleActiveUploads({ type: ActiveUploadsEnum.REMOVE_UPLOAD, payload: { id } });
        dispatch(setToasterMessage({ message: successfulUploadTexts || 'Files successfully uploaded' }));
        if (selectedTabName === MIDDLE_PANEL_TAB_NAMES.JOURNEY) getTicketAuditLogs();
      })
      .catch((error) => {
        handleActiveUploads({ type: ActiveUploadsEnum.UPDATE_UPLOAD, payload: { id, error: error.response.inline } });
        dispatch(setToasterMessage({ message: error.response.prompt || uploadFailed, type: 'error' }));
      })
      .finally(() => {
        activeUploadsRef.current = activeUploadsRef.current.filter((uploadId) => uploadId !== id);
        if (activeUploadsRef.current.length === 0) reportValidation(true);
      });
  };

  const handleUploadMultipleAttachments = (files) => {
    const activeUploads = [];
    files.forEach(file => {
      const id = uuidv4();
      handleActiveUploads({ type: ActiveUploadsEnum.ADD_UPLOAD, payload: { fileName: file.name, id, actionItemId } });
      activeUploadsRef.current.push(id);
      activeUploads.push(id);
    });
    createSrMultipleAttachments({
      files,
      subResourceType: actionItemId ? SubResourceType.ACTION_ITEM : SubResourceType.SR,
      subResourceId: actionItemId || srId,
    })
      .then((data) => {
        updateMultipleSrAttachments(data);
        handleActiveUploads({ type: ActiveUploadsEnum.REMOVE_UPLOADS, payload: { ids: activeUploads } });
        dispatch(setToasterMessage({ message: successfulUploadTexts || 'Files successfully uploaded' }));
        if (selectedTabName === MIDDLE_PANEL_TAB_NAMES.JOURNEY) getTicketAuditLogs();
      })
      .finally(() => {
        activeUploadsRef.current = activeUploadsRef.current.filter((uploadId) => !activeUploads.includes(uploadId));
        if (activeUploadsRef.current.length === 0) {
          reportValidation(true);
        }
      });
  }

  return { handleUploadNewAttachment, handleUploadMultipleAttachments, handleActiveUploads };
};
