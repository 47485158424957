import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const Indicator = ({ icon, className, customStyles, ...otherProps }) => (
  <Box className={className} {...otherProps}>
    {icon}
  </Box>
);

const commonStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '5px',
  gap: '8px',
  width: '16px',
  height: '16px',
  maxWidth: '16px',
  maxHeight: '16px',
  borderRadius: '50px',
  flex: 'none',
  order: 0,
  flexGrow: 0,
};

const shouldForwardProp = (prop) => prop !== 'completed' && prop !== 'customStyles';

export const StatusIndicator = styled(Indicator, { shouldForwardProp })(({ theme, completed, customStyles }) => ({
  ...commonStyles,
  backgroundColor: completed ? theme.palette.generic.sysaid : 'white',
  border: completed ? 'none' : `1px solid ${theme.palette.generic.sysaid}`,
  svg: {
    filter: completed
      ? 'none'
      : 'brightness(0) saturate(100%) invert(60%) sepia(19%) saturate(544%) hue-rotate(109deg) brightness(92%) contrast(88%)',
    ...(customStyles?.svg || {}),
  },
  ...(customStyles?.indicator || {}),
}));
