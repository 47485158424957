import { createTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

export const theme = createTheme({
  palette: {
    generic: {
      primary: '#fff',
      secondary: '#000',
      grey_600: '#808080',
      grey_100: '#f9f9fa',
      red: 'red',
      sysaid: '#699D8C',
      sysaid_hover: '#49806D',
      sysaid_mid: '#B2D1C7',
      sysaid_light: '#D0E7DF',
      sysaid_very_light: '#F2FAF7',
    },
    blueLabels: {
      bt_cyan_reg: '#C9EDF9',
      bt_cyan_hover: '#9FD0E0',
      bt_light_blue_reg: '#83B0F4',
      bt_light_blue_hover: '#6092DB',
      bt_blue_chip_light: '#95A9C9',
      bt_blue_chip_reg: '#7088AF',
      bt_status_reg: '#516C96',
      bt_status_hover: '#344C73',
    },
    labels: {
      bt_red_light: '#FFF2F2',
      bt_red_mid: '#FFD9D9',
      bt_red_reg: '#F76969',
      bt_red_hover: '#DE4949',
      bt_orange_light: '#FFF8F2',
      bt_orange_mid: '#FFD5B7',
      bt_orange_reg: '#FF964A',
      bt_orange_hover: '#E5792C',
      bt_yellow_reg: '#F4C449',
      bt_yellow_hover: '#DBAA2C',
      bt_black_light: '#F3F3F3',
      bt_black_reg: '#323436',
    },
    grey: {
      bt_def_grey_reg: '#7D899D',
      bt_def_grey_light: '#BEC4CE',
      bt_def_grey_hover: '#616F85',
      bt_alt_grey_reg: '#F0F1F5',
      bt_alt_grey_light: '#F5F6FA',
      bt_alt_grey_hover: '#E1E2E5',
      divider_grey_v1: '#DADCDE',
      divider_grey_v2: '#EDEFF1',
      divider_grey_v3: '#CCCCCC',
      divider_grey_v4: '#626263',
      tooltip_bg: '#494949',
      scroll: '#E6E6E6',
      chip_grey: '#939599',
    },
    text: {
      txt_default: '#303030',
      title_txt: '#4F4F4F',
      secondary_txt: '#787878',
      disable_txt: '#BEC4CE',
      disabled: '#ABAEB2',
      autocomplete: 'rgba(48, 48, 48, 0.65)',
      autocompleteSecondary: 'rgba(48, 48, 48, 0.85)',
      inactive: '#699d8c',
      inactive_secondary: '#e9f1ee',
      checkbox: '#34495e',
      bluegrey: '#c7ccd4',
      blue_user_name: '#3578DB',
    },
    icons: {
      icons_in_journey: '#344868',
      icons_grey_v1: '#7D899D',
      bt_def_grey_reg: '#A4A8B0',
      icons_grey_v3: '#777C85',
    },
    background: {
      bg_hover_alt: '#F0F1F5',
      bg_hover_main: '#E6E8F0',
      selected: '#F5F6FA',
      action: '#F5FAFF',
      pages_background: '#EBF0F5',
      lightGrey: 'F0EFF0',
      boxShadow: 'rgba(118, 130, 150, 0.35)',
    },
    border: {
      autocomplete: '#969696',
    },
    hover: {
      avatar: 'rgba(48, 48, 48, 0.12)',
    },
  },
  customScrollbar: {
    scrollBehavior: 'smooth',
    backgroundColor: 'transparent',
    scrollbarColor: '#DADCDE transparent',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '4px',
      maxHeight: '160px',
      height: '100%',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#DADCDE',
      borderRadius: '5px',
    },
  },
  components: {
    MuiPickerStaticWrapper: {
      styleOverrides: {
        root: {
          width: '245px',
          position: 'static !important',
        },
      },
    },
    MuiYearPicker: {
      styleOverrides: {
        root: {
          fontSize: '12px !important',
          fontFamily: 'Roboto-Regular !important',
          color: '#4f4f4f !important',
          justifyContent: 'space-between',
          '& .PrivatePickersYear-yearButton': {
            fontSize: '12px !important',
            fontFamily: 'Roboto-Regular !important',
            '&.Mui-selected': {
              backgroundColor: '#699d8c',
              '&:hover,:focus': {
                backgroundColor: '#699d8c',
              },
            },
            '&:hover': {
              backgroundColor: '#e9f1ee',
            },
          },
          scrollBehavior: 'smooth',
          backgroundColor: 'transparent',
          scrollbarColor: '#DADCDE transparent',
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar': {
            width: '4px',
            maxHeight: '160px',
            height: '100%',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#DADCDE',
            borderRadius: '5px',
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiPickersDay-root.Mui-disabled': {
            fontSize: '12px !important',
            fontFamily: 'Roboto-Regular !important',
          },
          '&.MuiPickersDay-root.Mui-selected': {
            backgroundColor: '#699d8c',
            '&:hover,:focus': {
              backgroundColor: '#699d8c',
            },
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          width: '28px',
          height: '28px',
          padding: '2px !important',
          margin: '0px 2px',
          boxSizing: 'border-box',
          color: '#4f4f4f',
          fontSize: '12px !important',
          fontFamily: 'Roboto-Regular !important',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto-Regular !important',
          fontSize: '12px !important',
          color: '#4f4f4f !important',
          width: '30px !important',
          height: '32px !important',
          margin: '0px 1px !important',
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          width: '245px',
          maxHeight: '264px',
          marginLeft: '0px',
          overflowY: 'hidden',
          '& > :first-of-type': {
            marginBottom: '10px',
            marginTop: '0px',
            padding: '0px',
            width: '100%',
          },
          '& .PrivatePickersSlideTransition-root': {
            minHeight: '182px',
          },
          '& .PrivatePickersFadeTransitionGroup-root': {
            fontFamily: 'Roboto-Bold !important',
            fontSize: '14px !important',
            color: '#4f4f4f',
            maxHeight: '214px',
          },
          '& .MuiPickersCalendarHeader-label': {
            whiteSpace: 'pre',
          },
          '& .MuiPickersFadeTransitionGroup-root': {
            maxHeight: '204px',
            '& > :first-of-type': {
              marginRight: '4px',
              '& .MuiYearPicker-root': {
                maxHeight: '204px',
              },
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
          boxShadow: '0px 2px 8px rgba(118, 130, 150, 0.35)',
          padding: '24px',
          '& > :first-of-type': {
            maxWidth: '398px',
            minWidth: 'auto',
            overflow: 'hidden',
          },
          '& > :first-of-type > :first-of-type > :first-of-type': {
            width: '256px',
          },
          '&.MuiDialog-paper': {
            maxWidth: '366px',
            flexDirection: 'column',
            borderRadius: '10px',
          },
        },
      },
    },
  },
});

export const StyledTimeContainer = styled(Box)(() => ({
  display: 'flex',
  padding: '10px 0',
  borderLeft: `1px solid ${theme.palette.grey}`,
  boxSizing: 'border-box',
  maxHeight: '264px',
}));

export const StyledPickerWrapper = styled(Box)(() => ({
  display: 'flex',
}));

export const StyledHoursConatiner = styled(Box)(() => ({
  marginRight: '5px',
  width: '100%',
  boxSizing: 'border-box',
}));

export const StyledMinutesConteiner = styled(Box)(() => ({
  paddingRight: '5px',
}));
