import { useRbTexts } from 'remote-state/applicationHooks';
import { formatTranslation } from '../../../common/utils/translation';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    typeHere: (label) =>
      formatTranslation(texts?.['spaces.template.fields.text.typeHere'], [{ placeHolder: '{label}', value: label }]),
    hyphens: texts?.['spaces.template.fields.text.hyphens'],
    none: texts?.['spaces.template.fields.text.none'],
    descriptionPlaceholder: texts?.['spaces.template.fields.description.placeholder'],
    fieldDeletedFailed: texts?.['spaces.templateBuilder.fieldDeletedFailed'],
    fieldDeletedSuccessfully: texts?.['spaces.templateBuilder.fieldDeletedSuccessfully'],
    deleteFieldWarning: texts?.['spaces.templateBuilder.deleteFieldWarning'],
    removeField: texts?.['spaces.templateBuilder.removeField'],
    deleteAction: texts?.['spaces.templateBuilder.deleteAction'],
    moreDetails: texts?.['spaces.templateBuilder.moreDetails'],
    clear: texts?.['spaces.datePicker.dueDate.clear'],
    remove: texts?.['spaces.templateBuilder.remove'],
    dontRemove: texts?.['spaces.templateBuilder.dontRemove'],
    mandatoryFieldError: texts?.['spaces.field.tooltip.error.mandatoryFieldError'] || 'Missing required field',
  };

  return componentTexts;
}
