import { Box, Button, Menu, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledRelationshipItemDropdowns = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: '100%',
  marginTop: '18px',
  gap: '10px',
}));

export const StyledDropdown = styled(Box, {
  shouldForwardProp: (prop) => !['isDirty'].includes(prop),
})(({ theme, isDirty, isFullWidth }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: isFullWidth && '100%',
  maxWidth: isFullWidth && 'calc(100% - 140px)',

  '.multi-select-field': {
    cursor: 'pointer',
    padding: '3px 4px 3px 0px',
    border: 'none',
    background: isDirty ? 'none' : theme?.palette?.background?.bg_new_input_text,
    '.multi-select-chip': {
      height: '22px !important',
    },
  },
  '.field-name-title': {
    padding: '2px 0px 2px 8px',
  },
  '.plus-button': {
    top: '5px',
    svg: {
      height: '18px',
      width: '18px',
    },
  },
}));

const handleBackgroundColor = ({ isOpen, hasValue, theme }) => {
  if (hasValue) return theme?.palette?.generic?.primary;
  return isOpen
    ? `${theme?.palette?.background?.bg_hover_main}!important`
    : `${theme?.palette?.background?.bg_new_input_text}!important`;
};

export const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => !['isOpen', 'hasValue'].includes(prop),
})(({ theme, isOpen, hasValue }) => ({
  width: 'auto',
  backgroundColor: handleBackgroundColor({ isOpen, hasValue, theme }),
  borderRadius: '4px',
  '& .MuiOutlinedInput-root': {
    fontFamily: theme?.fonts?.robotoRegular,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiOutlinedInput-input': {
      cursor: 'pointer',
      width: '134px',
      fontSize: '.875rem',
      height: 'unset',
      lineHeight: 1.4286,
      minHeight: 'unset',
      padding: theme?.spacing(0.5, 1),
      color: theme?.palette?.text?.txt_default,
      '&::placeholder': {
        opacity: 1,
        color: '#000',
      },
    },
    '&.MuiInputBase-fullWidth': {
      '& .MuiOutlinedInput-input': {
        width: '100%',
      },
    },
    '& .MuiPopover-root': {
      position: 'absolute',
      transform: 'translate(0, 100%)',
      '& .MuiPaper-root': {
        position: 'static',
      },
    },
  },
  '& .MuiFormHelperText-root': {
    margin: 0,
  },
}));

export const StyledDropDownMenu = styled(Menu)(({ theme }) => ({
  '.MuiPaper-root': {
    padding: '8px',
    borderRadius: '6px',
    maxHeight: '250px',
    boxShadow: `0px 2px 8px ${theme.palette.background.boxShadow}`,
    height: 'fit-content',
    width: '150px',
  },
  ul: {
    padding: 0,
    height: '100%',
  },
  li: {
    padding: '0',
    '>*': {
      padding: '12px 16px',
      width: '100%',
      justifyContent: 'flex-start',
    },
    '&:hover': {
      display: 'flex',
      alignItems: 'flex-start',
      gap: '10px',
      padding: '0',
      alignSelf: 'stretch',
      borderRadius: '6px',
      backgroundColor: theme.palette.grey.bt_alt_grey_light,
    },
  },
}));

export const StyledDropdownItem = styled(Button)(({ theme }) => ({
  padding: 0,
  textTransform: 'none',
  color: theme.palette.text.title_txt,
  fontFamily: 'Roboto-Regular',
  fontSize: theme.fontSize.main,
  lineHeight: 1,
}));
