import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledAttachmentDropzone = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isDragAccept' && prop !== 'isDragReject' && prop !== 'isFocused',
})(({ theme, isDragAccept, isActionItem }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  '> .attachments-zone': {
    display: isDragAccept ? 'flex' : 'none',
    position: 'absolute',
    zIndex: 5,
    width: isActionItem ? '100%' : '98%',
    height: isActionItem ? '100%' : '98%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: `2px dashed ${theme.palette.grey.bt_def_grey_light}`,
    backgroundColor: 'rgba(256,256,256,0.8)',
    margin: isActionItem ? '0' : '1%',
    borderRadius: '10px',
    fontSize: theme?.fontSize?.medium,
    color: theme.palette.text.secondary_txt,
    '> img': {
      marginBottom: '20px',
    },
  },
}));
