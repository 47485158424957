import { useQuery } from '@tanstack/react-query';
import { useRouter } from '@tanstack/react-router';
import { getEnableExternalEmailAddress, getMessagesInitialData } from 'services/messagesServices';
import { editorActionTypes } from 'features/resolutionPanel/middlePanel/auditLog/constants/editorActionTypes';
import { getIsExistingSr } from 'common/utils/srUtils';

export function useInitialContent(editorType, srId) {
  const router = useRouter();
  const messagesInitialData = useQuery(['messagesInitialData', srId], () => getMessagesInitialData(srId), {
    staleTime: 1000 * 60 * 60,
    placeholderData: {},
    enabled: getIsExistingSr(srId) && router.latestLocation.pathname !== '/spaces/template',
  });
  return editorType === editorActionTypes.MESSAGE ? messagesInitialData : null;
}
export function useEnableExternalEmailAddressContent() {
  return useQuery(['enableExternalEmailAddress'], () => getEnableExternalEmailAddress(), {
    staleTime: 1000 * 60 * 60,
    placeholderData: false,
    enabled: Boolean(true),
  });
}
