import { useState, useEffect } from 'react';
import { APP_CONSTANTS } from 'constants/index';

let fetched = false;
export const useTargetChannel = () => {
  const [targetChannel, setTargetChannel] = useState(localStorage.getItem(APP_CONSTANTS.ACCOUNT_TARGET));

  useEffect(() => {
    async function fetchTargetChannel() {
      if (fetched) return;
      try {
        fetched = true;
        const response = await fetch('/spaces/about.json');
        const json = await response.json();
        if (json.target) {
          setTargetChannel(json.target);
          localStorage.setItem(APP_CONSTANTS.ACCOUNT_TARGET, json.target);
        }
      } catch (error) {
        console.error('Error fetching target channel:', error);
      }
    }

    fetchTargetChannel();
  }, []);

  return targetChannel;
};