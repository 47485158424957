import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { editorActionTypes } from '../../constants/editorActionTypes';

export const StyledTitleImg = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isPlusIcon' && prop !== 'editorType',
})(({ theme, isPlusIcon, editorType }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '32px',
  height: '32px',
  zIndex: 2,
  borderRadius: '50%',
  backgroundColor: theme.palette.generic.sysaid_light,
  marginTop: isPlusIcon ? `${editorType === editorActionTypes.RESOLUTION ? 10 : 5}px` : 0,

  '.title-img': {
    display: 'flex',
    padding: '9px',
    aspectRatio: 'auto',
    boxSizing: 'content-box',
  },
  '.open-ticket-icon': {
    padding: '0px 8px 0px 9px',
    width: '15px',
    height: '13px',
  },
  '.close-ticket-icon': {
    padding: '0px 9px',
    width: '14px',
    height: '13px',
  },
  '.view-ticket-icon': {
    padding: '8px',
    width: '16px',
    height: '16px',
  },
  '.activity-icon': {
    width: '18px',
  },
}));
