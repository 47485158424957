import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledUserForm = styled(Box, { shouldForwardProp: (prop) => prop !== 'isError' })(
  ({ theme, isError }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    '#login-form': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      rowGap: '36px',
      'label > span': {
        paddingLeft: '8px',
      },
    },
    '.username-input-wrapper': {
      position: 'relative',
      width: '100%',
    },
    '.warning': {
      position: 'absolute',
      right: '10px',
      top: '5px',
    },
    input: {
      backgroundColor: isError
        ? `${theme.palette.labels.bt_red_light} !important`
        : `${theme.palette.background.bg_new_input_text} !important`,
    },
    'input:hover': {
      backgroundColor: theme?.palette?.background?.bg_hover_main,
    },
    '.forgot-password-warning': {
      position: 'absolute',
      right: '10px',
      top: '25px',
    },
    '.left-side-form-field::placeholder': {
      color: theme.palette.labels.bt_red_reg,
      opacity: 1,
    },
    '.user-form-placeholder': {
      fontSize: theme?.fontSize?.main,
      color: theme.palette.text.autocomplete,
      border: 'none',
      padding: 0,
      outline: 'none',
      width: '100%',
      position: 'relative',
    },
    '.left-side-form-field': {
      width: '100%',
      '.MuiInputBase-root': {
        width: '100%',
        border: 'none',
      },
      'input.MuiInput-input': {
        width: '99%',
        borderRadius: '4px',
        border: 'none',
        outline: 'none',
        color: theme.palette.text.autocompleteSecondary,
        fontSize: theme?.fontSize?.main,
        padding: '4px 28px 4px 8px',
      },
    },
    '.form-field-error': {
      fontSize: theme?.fontSize?.small,
      color: theme.palette.labels.bt_red_reg,
      height: '20px',
    },
    '.back-to-login': {
      width: 'fit-content',
      color: 'rgba(105, 157, 140, 1)',
      fontSize: theme?.fontSize?.main,
      fontStyle: 'normal',
      fontWeight: '400',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    '.second': {
      marginBottom: '12px',
    },

    '.input-wrapper': {
      display: 'flex',
      position: 'relative',
      width: '100%',
    },
    'input:-webkit-autofill': {
      webkitTextFillColor: `${theme.palette.text.autocomplete} !important`,
      '-webkit-box-shadow': `0 0 0px 1000px ${isError ? '#FFF2F2' : '#EBF5FF'} inset`,
    },
    '.password-input-wrapper': {
      display: 'flex',
      width: '100%',
      position: 'relative',
      flexDirection: 'column',
      '.invalid-field + .toggle-password-img': {
        display: 'none',
      },
    },
    '.toggle-password-img': {
      display: isError ? 'none' : '',
      position: 'absolute',
      width: '16px',
      height: '16px',
      right: '10px',
      bottom: '6px',
      cursor: 'pointer',
      zIndex: 1,
      '&:hover': {
        width: '22px',
        height: '22px',
        right: '7px',
        bottom: '3px',
        border: `solid 3px ${theme.palette.grey.divider_grey_v1}`,
        borderRadius: '4px',
        boxShadow: `inset 0px 0px 0px 10px ${theme.palette.grey.divider_grey_v1}`,
        '+ input': {
          backgroundColor: theme?.palette?.background?.bg_hover_main,
        },
      },
    },
    '.domain-wrapper': {
      width: '100%',
      height: 'auto',
    },
    '#domain-field': {
      marginTop: '13px',
    },
    '.last': {
      margin: '12px 0',
    },
    '.password-field': {
      // padding: '1px 22px 1px 2px',
    },
    '.error': {
      backgroundColor: `${theme?.palette?.labels.bt_red_light}!important`,
    },
    '.MuiInputAdornment-root': {
      position: 'absolute',
      right: 0,
    },
    '.MuiAutocomplete-inputRoot': {
      padding: '0!important',
      '.MuiInputBase-input': {
        padding: '5px 4px!important',
        borderRadius: '4px',
      },
    },
    '.login-navigation': {
      color: theme.palette.text.title_txt,
      textAlign: 'center',
      lineHeight: 1.5,
      marginTop: '-12px',
      fontSize: theme?.fontSize?.main,
      '&.login-reset-password': {
        marginTop: '-18px',
      },
      a: {
        color: theme.palette.generic.sysaid,
        cursor: 'pointer',
        margin: '0 5px',
      },
      span: {
        padding: '0 !important',
      },
    },

    '@media screen and (max-width: 1024px)': {
      '.second': {
        marginBottom: '5px !important',
      },
      '.domain-wrapper': {
        marginBottom: '5px !important',
      },
      '.form-field-error': {
        marginBottom: '5px !important',
      },
    },
  }),
);
export const StyledLoading = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isLoading',
})(({ theme, isLoading }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '.loadingIcon': {
    display: isLoading ? 'block' : 'none',
    marginRight: '10px',
    svg: {
      color: theme?.palette?.generic?.primary,
    },
  },
}));
export const customAutoCompleteStyle = {
  inputFontSize: 14,
  inputColor: 'rgba(48, 48, 48, 0.65)',
  dropdownContainerMaxHeight: 100,
  dropdownColor: 'rgba(48, 48, 48, 0.65)',
  dropdownOptionFontSize: 14,
  borderBottom: 'unset',
};
