import Tooltip from 'common/components/tooltip';

import NavSubListItem from '../../navSubListItem';

export default function NavSubListMenuTitle({ title, icon }) {
  return (
    <NavSubListItem>
      {icon && <span className="icon">{icon}</span>}
      <Tooltip isTruncatedText text={title} placement="top" className="menu-title" />
    </NavSubListItem>
  );
}
