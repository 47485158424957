import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();

  const componentTexts = {
    idFilterPlaceholder: texts?.['spaces.filters.id.placeholder'],
  };

  return componentTexts;
}
