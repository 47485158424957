import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    copyLinkText: texts?.['spaces.share.copyLink'],
    copiedLinkText: texts?.['spaces.share.copiedLink'],
  };

  return componentTexts;
}
