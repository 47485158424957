import { useMemo } from 'react';
import { QUERIES_KEYS } from 'constant';
import { useUserInfo } from 'remote-state/userServiceHooks';

// Here we map the permissionTypes with field props. We use the index of permissionTypes to identify his priority in the bottom
const rolePermissionsMap = {
  E: {
    disabled: false,
  },
  V: {
    disabled: true,
  },
  default: {
    disabled: true,
  },
};
const userGroupsKey = 'user_groups';

export const getPermissionsRules = ({ isLimited, userGroups, permissions }) => {
  if (isLimited && Array.isArray(permissions) && permissions.length > 0) {
    if (userGroups.length > 0) {
      const userGroupsIds = userGroups.map((group) => group.id);
      const rolePermissionsMapKeys = Object.keys(rolePermissionsMap);
      //We need to loop permissons and find if the user has any of them. If its then we need to find not only the mathces one but the more prioritized one.
      //For example if user is member of two groups that one them has edit access we need to return more priviliged one.
      const permissionTypes = permissions.reduce((result, permission) => {
        if (userGroupsIds.includes(permission.groupId)) {
          const permissionType = permission.permissionType;
          if (result) {
            if (rolePermissionsMapKeys.indexOf(result) > rolePermissionsMapKeys.indexOf(permissionType)) {
              result = permissionType;
            }
          } else {
            result = permissionType;
          }
        }
        return result;
      }, '');
      return rolePermissionsMap[permissionTypes] ?? rolePermissionsMap.default;
    }
    return rolePermissionsMap.default;
  }
  return {};
};

export const useGetFieldPermissions = (component) => {
  const isLimited = component?.limitedPermissions;
  const permissions = component?.permissions || component?.ticketTemplateSectionPermissions;
  const { data: userInfo } = useUserInfo(QUERIES_KEYS.CURRENT_USER_FROM_CORE);
  const infoList = userInfo?.info;
  const userGroups = useMemo(() => infoList?.find((item) => item.key === userGroupsKey)?.value ?? [], [infoList]);

  const permissionsRules = useMemo(
    () => getPermissionsRules({ isLimited, permissions, userGroups }),
    [isLimited, permissions, userGroups],
  );
  return permissionsRules;
};

export default useGetFieldPermissions;
