import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledFilterIcon = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  height: '16px',
}));
