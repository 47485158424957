import { List, ListItemAvatar, ListItemText } from '@mui/material';
import { BasicAvatar } from 'common/components/avatars/basicAvatar';
import Tooltip from 'common/components/tooltip';
import { AVATAR_CONSTANTS } from 'constants/index';
import PropTypes from 'prop-types';
import { StyledUserInfoCardHeader } from './styles';
import CopyButton from '../../copyButton';
import UserInfoCardDetail from '../userInfoCardDetails/userInfoCardDetail';

const componentId = 'user-info-card-header';

const UserInfoCardHeader = ({ profileImage, displayName, email }) => (
  <StyledUserInfoCardHeader disablePadding id={componentId}>
    <ListItemAvatar id={`${componentId}-avatar`}>
      <BasicAvatar size={AVATAR_CONSTANTS.SIZES.XL} profileImage={profileImage} userName={displayName} disableHover />
    </ListItemAvatar>
    <List className={`${componentId}-user-data`}>
      <ListItemText
        id={`${componentId}-name`}
        primary={<Tooltip className="user-primary-text" text={displayName} />}
      />
      <UserInfoCardDetail
          id="email"
          text={Boolean(email) && <Tooltip className="email-text" text={email} isTruncatedText placement="top" />}
          secondaryAction={<CopyButton text={email} id="email" />}
      />
    </List>
  </StyledUserInfoCardHeader>
);
export default UserInfoCardHeader;

UserInfoCardHeader.propTypes = {
  profileImage: PropTypes.array,
  displayName: PropTypes.string,
  email: PropTypes.string,
};