import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import checkedOnIcon from 'images/icons/checkedOn.svg';

export const StyledRememberForgotSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',

  '#remeber-forgot-section': {
    width: '100%',
  },
  '.login-util': {
    display: 'flex',
    flex: 1,
    fontSize: '0.875rem',
    alignItems: 'center',
  },
  '.login-forgot-password-button': {
    color: theme.palette.generic.sysaid,
    cursor: 'pointer',
    justifyContent: 'flex-end',
  },
  '.checkbox': {
    display: 'flex',
    cursor: 'pointer',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '.checkbox > span': {
    color: theme.palette.text.checkbox,
  },
  '.checkbox > input': {
    height: '16px',
    width: '16px',
    webkitAppearance: 'none',
    mozAppearance: 'none',
    oAppearance: 'none',
    appearance: 'none',
    outline: 'none',
    transitionDuration: '0.3s',
    cursor: 'pointer',
    borderRadius: '3px',
    border: `solid 1px ${theme.palette.grey.bt_def_grey_reg}`,
    backgroundColor: 'white',
    margin: 0,
  },
  '.checkbox > input:checked + span::before': {
    content: `url(${checkedOnIcon})`,
    position: 'absolute',
    top: '2px',
    left: 0,
  },
  '.checkbox > input:active': {
    border: `2px solid ${theme.palette.text.checkbox}`,
    lineHeight: 1,
    marginTop: '3px',
  },
  '.remember-me': {
    color: theme.palette.text.autocompleteSecondary,
  },
  '@media screen and (max-width: 1024px)': {
    marginTop: '5px',
    '.checkbox > input:checked + span::before': {},
    '@media screen and (max-width: 400px)': {
      '.checkbox > input:checked + span::before': {},
    },
  },
}));
