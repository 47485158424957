import { IconButton } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ReactComponent as CopyIcon } from 'images/icons/copy.svg';

const CopyButton = ({ text, id }) => (
  <CopyToClipboard text={text}>
    <IconButton data-testid="copy" id={`copy-button${id ? `-${id}` : ''}`}>
      <CopyIcon />
    </IconButton>
  </CopyToClipboard>
);

export default CopyButton;
