import { styled } from '@mui/material';

export const StyledAttachmentsField = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '0px',
  flex: '1 0 calc(35% - 10px)',
  width: '100%',
  maxWidth: '100%',
  '& div': { margin: 0 },
  '& .title': {
    fontSize: '12px',
    color: '#787878',
    fontFamily: 'Roboto-Regular',
    marginLeft: '8px',
  },
}));
