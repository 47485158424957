import { createSlice } from '@reduxjs/toolkit';

export const activeComponentTypeEnum = {
  HEADER: 1,
  SECTION: 2,
  FIELDS: 3,
};

const initialState = {
  componentId: null,
  componentType: null,
};

export const activeComponentSlice = createSlice({
  name: 'activeComponent',
  initialState,

  reducers: {
    setActiveComponent: (state, { payload: { componentId, componentType } }) => {
      state.componentId = componentId;
      state.componentType = componentType;
    },
    resetActiveComponent: (state) => {
      state.componentId = null;
      state.componentType = null;
    },
  },
});

export const { setActiveComponent, resetActiveComponent } = activeComponentSlice.actions;

export const selectActiveComponent = (state) => state.activeComponent;

export default activeComponentSlice.reducer;
export const activeComponentSliceName = activeComponentSlice.name;
