import { useEffect, useRef, useMemo, useState, forwardRef, useImperativeHandle } from 'react';
import ReadOnlyEditor from 'react-froala-wysiwyg/FroalaEditorView';
import PropTypes from 'prop-types';
import usePreviousValue from 'common/utils/hooks/usePreviousValue';
import { editorActionData } from 'features/resolutionPanel/middlePanel/auditLog/constants';
import { HAS_HEADER } from 'features/resolutionPanel/constants';
import { useAuditLog } from 'features/resolutionPanel/middlePanel/auditLog/hooks';
import { RICH_TEXT_EDITOR_TYPES, DEFAULT_CONFIG } from './constants';
import { useInitialContent } from './hooks/messageHooks';
import useAttachmentControls from './hooks/useAttachmentControls';
import Attachments from './attachments';
import RichTextEditor from './RichTextEditor';
import { StyledGridContainer, StyledRichTextEditorWrapper } from './styles';

import 'tributejs/dist/tribute.css';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

const RichTextWrapper = forwardRef((props, ref) => {
  const {
    sourceId,
    type,
    disabled,
    editorText,
    attachmentsList,
    isReadOnly,
    shouldRenderDefaultUI,
    customDefaultUI,
    shouldTriggerDeleteApi,
    activeUploads,
    onChangeActiveUploads,
    isExpanded,
    isFullSize,
    isRichTextTruncated,
    onImageUpload,
    onFileAttach,
    onAttachmentDeleted,
    onChangeUploadImageProgress,
    onChangeUploadFileProgress,
    editorType,
    deleteAttachmentEnabled,
    dataTestId,
    editorElementsClassName,
    shouldShowAttachments = true,
  } = props;

  const configMap = {
    [RICH_TEXT_EDITOR_TYPES.TEXT]: {
      toolbarButtons: ['insertLink', 'insertImage', 'mention', 'insertFile'],
    },
    [RICH_TEXT_EDITOR_TYPES.EMAIL]: {
      toolbarButtons: ['insertLink', 'insertImage', 'mention', 'insertFile', 'cannedResponses'],
    },
    [RICH_TEXT_EDITOR_TYPES.TABLE]: {
      toolbarButtons: ['insertLink', 'insertImage', 'mention'],
    },
    [RICH_TEXT_EDITOR_TYPES.TEMPLATE]: {
      toolbarButtons: ['insertLink', 'mention'],
    },
  };

  const { toolbarButtons = [] } = configMap[type];
  const isAttachmentsEnabled = ['insertImage', 'insertFile'].some((feature) => toolbarButtons.includes(feature));
  const editorElementClassName = editorActionData[editorType]?.editorElementClassName || 'editor-element';
  const isTemplatePage = type === RICH_TEXT_EDITOR_TYPES.TEMPLATE;

  const editorWrapRef = useRef();
  const richTextEditorRef = useRef(null);
  const editorRef = useRef(null);

  const { getTicketAuditLogs } = useAuditLog();

  useImperativeHandle(ref, () => ({
    focus: () => {
      richTextEditorRef.current?.focus();
    },
  }));

  const [imagePreviewConfig, setImagePreviewConfig] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [editorId, setEditorId] = useState('');
  const [isUploadFromMenuOpened, setIsUploadFromMenuOpened] = useState(false);
  const [uploadFromMenuId, setUploadFromMenuId] = useState(null);
  const [isUploadFormAttachmentsMenuOpened, setIsUploadFormAttachmentsMenuOpened] = useState(false);

  const onChangeErrorMessage = (errorMessage) => {
    setErrorMessage(errorMessage);
  };

  const { handlePasteAttachment, handleAttachmentDrop, handleImageUpload, handleNewAttachment, handleUploadRetry } =
    useAttachmentControls({
      onChangeActiveUploads,
      onChangeUploadImageProgress,
      onChangeErrorMessage,
      onChangeUploadFileProgress,
      onImageUpload,
      onFileAttach,
      editorRef,
    });

  useEffect(() => {
    getTicketAuditLogs();
  }, [getTicketAuditLogs, isExpanded]);

  const height = useMemo(() => {
    if (isExpanded) {
      return isFullSize ? '300px' : '120px';
    }
    return editorText ? 'auto' : '24px';
  }, [isExpanded, isFullSize, editorText]);

  const initialContent = useInitialContent(editorType, sourceId);
  const signature = initialContent?.data?.emailSignature;
  const prevSignature = usePreviousValue(signature);
  let editorContent = editorText;
  if (signature) {
    const getSignatureEl = (currentSignature) => `<div class='email-signature'>${currentSignature}</div>`;
    if (signature !== prevSignature && editorText.includes('email-signature')) {
      editorContent.replace(getSignatureEl(prevSignature), getSignatureEl(signature));
    } else {
      editorContent += getSignatureEl(signature);
    }
  }

  const readOnlyEditor = isReadOnly ? (
    <StyledRichTextEditorWrapper className="readonly-editor">
      <ReadOnlyEditor
        editorRef={editorRef}
        model={editorContent}
        config={{
          key: process.env.REACT_APP_FROALA_API_KEY,
          ...DEFAULT_CONFIG,
        }}
      />
      <div>{customDefaultUI}</div>
    </StyledRichTextEditorWrapper>
  ) : null;

  const richTextEditor = (
    <StyledRichTextEditorWrapper shouldRenderDefaultUI={shouldRenderDefaultUI} className="rich-text-editor-wrapper">
      <RichTextEditor
        {...props}
        ref={richTextEditorRef}
        toolbarButtons={toolbarButtons}
        isAttachmentsEnabled={isAttachmentsEnabled}
        editorWrapRef={editorWrapRef}
        editorRef={editorRef}
        editorText={editorContent}
        setEditorId={setEditorId}
        editorElementsClassName={editorElementsClassName}
        onAttachmentDrop={handleAttachmentDrop}
        onChangeImagePreviewsConfig={setImagePreviewConfig}
        isUploadFromMenuOpened={isUploadFromMenuOpened}
        setIsUploadFromMenuOpened={setIsUploadFromMenuOpened}
        isUploadFormAttachmentsMenuOpened={isUploadFormAttachmentsMenuOpened}
        setIsUploadFormAttachmentsMenuOpened={setIsUploadFormAttachmentsMenuOpened}
        setUploadFromMenuId={setUploadFromMenuId}
        handlePasteAttachment={handlePasteAttachment}
      />
      {customDefaultUI}
    </StyledRichTextEditorWrapper>
  );

  const attachmentsWrapper = shouldShowAttachments && isAttachmentsEnabled && (
    // Attachment of richtext
    <div className={`${editorElementClassName} attachments-wrapper`}>
      <Attachments
        isExpanded={isExpanded}
        editorRef={editorRef}
        imagePreviewConfig={imagePreviewConfig}
        onChangeImagePreviewsConfig={setImagePreviewConfig}
        attachments={attachmentsList}
        onAttachmentDeleted={onAttachmentDeleted}
        activeUploads={activeUploads}
        onChangeActiveUploads={onChangeActiveUploads}
        isReadOnly={Boolean(isReadOnly)}
        onImageUpload={handleImageUpload}
        onFileAttach={handleNewAttachment}
        errorMessage={errorMessage}
        onChangeErrorMessage={onChangeErrorMessage}
        onUploadRetry={handleUploadRetry}
        isUploadFromMenuOpened={isUploadFromMenuOpened}
        shouldTriggerDeleteApi={shouldTriggerDeleteApi}
        setIsUploadFromMenuOpened={setIsUploadFromMenuOpened}
        setIsUploadFormAttachmentsMenuOpened={setIsUploadFormAttachmentsMenuOpened}
        deleteAttachmentEnabled={deleteAttachmentEnabled}
        uploadFromMenuId={uploadFromMenuId}
        setUploadFromMenuId={setUploadFromMenuId}
        isMinimizedSize
      />
    </div>
  );

  return (
    <StyledGridContainer
      isTemplatePage={isTemplatePage}
      isExpanded={isExpanded}
      height={height}
      maximized={isFullSize.toString()}
      disabled={disabled}
      ref={editorWrapRef}
      isScrollable={attachmentsList?.length + activeUploads?.length > 3}
      hasAttachments={!!attachmentsList?.length}
      isReadOnly={isReadOnly}
      isRichTextTruncated={isRichTextTruncated}
      editorContent={editorContent}
      hasHeader={HAS_HEADER.includes(editorType)}
      id={`richtext-wrap-${editorId}`}
      data-testid={dataTestId || `richtext-wrap-${editorRef?.current?.id}`}
      data-cy={dataTestId || `richtext-wrap-${editorRef?.current?.id}`}
    >
      {readOnlyEditor || richTextEditor}
      {attachmentsWrapper}
    </StyledGridContainer>
  );
});

RichTextWrapper.propTypes = {
  type: PropTypes.number,
  charCounterMax: PropTypes.number,
  showToolbar: PropTypes.bool,
  disabled: PropTypes.bool,
  editorText: PropTypes.string,
  placeholder: PropTypes.string,
  isReadOnly: PropTypes.bool,
  CustomFooter: PropTypes.element,
  attachmentsList: PropTypes.array,
  isUploadImageProgress: PropTypes.bool,
  isUploadFileProgress: PropTypes.bool,
  onAttachmentDeleted: PropTypes.func,
  activeUploads: PropTypes.array,
  onChangeActiveUploads: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  handleClickAway: PropTypes.func,
  handleExpanded: PropTypes.func,
  toggleFullSize: PropTypes.func,
  onImageUpload: PropTypes.func,
  onFileAttach: PropTypes.func,
  onChangeUploadImageProgress: PropTypes.func,
  onChangeUploadFileProgress: PropTypes.func,
  isExpanded: PropTypes.bool,
  isFullSize: PropTypes.bool,
  isRichTextTruncated: PropTypes.bool,
  hasFullSize: PropTypes.bool,
  shouldTriggerDeleteApi: PropTypes.bool,
  shouldRenderDefaultUI: PropTypes.bool,
  customDefaultUI: PropTypes.element,
  dataTestId: PropTypes.string,
};

RichTextWrapper.defaultProps = {
  type: 1,
  charCounterMax: DEFAULT_CONFIG.defaultCharCounterMax,
  showToolbar: true,
  isExpanded: false,
  isFullSize: false,
  hasFullSize: true,
  disabled: false,
  isReadOnly: false,
  shouldTriggerDeleteApi: true,
  shouldRenderDefaultUI: false,
  isUploadImageProgress: false,
  isUploadFileProgress: false,
  customDefaultUI: null,
  editorText: '',
  placeholder: '',
  dataTestId: '',
  CustomFooter: null,
  attachmentsList: [],
  activeUploads: [],
  onAttachmentDeleted: () => {},
  onChangeActiveUploads: () => {},
  onBlur: () => {},
  onFocus: () => {},
  onChange: () => {},
  handleExpanded: () => {},
  handleClickAway: () => {},
  toggleFullSize: () => {},
  onImageUpload: () => {},
  onFileAttach: () => {},
  onChangeUploadImageProgress: () => {},
  onChangeUploadFileProgress: () => {},
};

export default RichTextWrapper;
