import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledAuditLogNotification = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  transform: 'translate(0px, -8px)',
  a: {
    textDecoration: 'none',
    width: 'fit-content',
  },
}));

export const StyleTitle = styled(Box)(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '750px',
  padding: '4px 8px',
}));

export const StyleSubTitle = styled('span')(({ theme }) => ({
  fontFamily: theme.fonts.robotoMedium,
  color: theme.palette.text.journey_title,
}));

export const StyleExternalLink = styled(Box)(({ theme }) => ({
  marginTop: '2px',
  display: 'flex',
  gap: '4px',
  alignItems: 'center',
  color: theme.palette.text.secondary_txt,
  fontSize: theme.fontSize.small,
  padding: '2px 8px',
  borderRadius: theme.shape.border.borderRadius,
  '&:hover': {
    background: theme.palette.background.bg_hover_main,
  },
}));

export const ExternalLinkText = styled('span')(({ theme }) => ({
  color: theme.palette.text.secondary_txt,
  fontFamily: theme.fonts.robotoRegular,
  fontSize: theme.fontSize.small,
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px',
}));

export const StyleSubject = styled(Box)(() => ({
  padding: '4px 8px',
}));
