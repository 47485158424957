import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== 'shouldhover',
})(({ theme, shouldhover = true }) => ({
  margin: '0 0 0 -2px',
  '&:hover': {
    background: shouldhover ? null : 'none',
  },
  '.MuiCheckbox-root': {
    padding: '0',
    marginRight: '10px',
    color: theme.palette.grey.bt_def_grey_reg,
    '&.Mui-checked': {
      color: theme.palette.grey.bt_def_grey_reg,
    },
  },
  '.MuiTypography-root': {
    color: theme.palette.text.title_txt,
    fontSize: theme.fontSize.main,
  },
}));

export const StyledTemplateCheckboxItem = (props) => {
  const { checked, handleChange, label } = props;
  return <StyledFormControlLabel label={label} control={<Checkbox checked={checked} onChange={handleChange} />} />;
};
