import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledWrapper = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  height: '100vh',
  backgroundColor: '#EBF0F5',
  position: 'fixed',
}));

export const ProgressWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
}));
