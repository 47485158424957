import { useMemo } from 'react';
import { ReactComponent as FilterIcon } from 'images/icons/gh_filter.svg';
import endOfRowPic from 'images/photos/end_of_row.png';
import useTexts from './useTexts';
import { StyledEndOfLoading, StyledContainer, StyledEndOfLoadingWithNoData, StyledContainerWithNoData, StyledTitle, StyledSecondaryTitle, StyledImg, StyledGridWithDataTitle } from './style';
import { GRID } from '../constants';

const EndResultsMessage = (props) => {
  const isMoreThanThousand = useMemo(
    () => props.context.countTotal > GRID.MAX_ROWS_ALLOWED_NUM,
    [props.context.countTotal],
  );

  const { refine, noResultsTitle, noResultsSecondaryTitle } = useTexts();

  return (
    <>
      {isMoreThanThousand ?
        <StyledEndOfLoading>
          <StyledContainer>
            <FilterIcon />
            <StyledGridWithDataTitle>{refine}</StyledGridWithDataTitle>
          </StyledContainer>
        </StyledEndOfLoading>
        :
        <StyledEndOfLoadingWithNoData id="grid_no_data_container">
          <StyledContainerWithNoData>
            <StyledImg src={endOfRowPic} alt="endOfRow" />
            <StyledTitle>{noResultsTitle}</StyledTitle>
            <StyledSecondaryTitle>{noResultsSecondaryTitle}</StyledSecondaryTitle>
          </StyledContainerWithNoData>
        </StyledEndOfLoadingWithNoData>
        }
    </>
  );
};

export default EndResultsMessage;
