import { styled } from '@mui/material/styles';
import { SMALL_ATTACHMENT_WIDTH, REGULAR_ATTACHMENT_WIDTH } from 'features/attachments/attachmentsList/style';

export const StyledContainer = styled('div')(({ theme, visible, isActionItem, isMinimizedSize }) => ({
  // eslint-disable-next-line no-nested-ternary
  width: isActionItem ? '190px' : isMinimizedSize ? `${SMALL_ATTACHMENT_WIDTH}px` : `${REGULAR_ATTACHMENT_WIDTH}px`,
  display: isActionItem && 'flex',
  height: isActionItem && '36px',
  position: isActionItem && 'relative',
  alignItems: isActionItem && 'center',
  justifyContent: isActionItem && 'space-between',
  backgroundColor: isActionItem && '#EDEFF1',
  borderRadius: '4px',
  '&:hover': {
    backgroundColor: isActionItem && '#E6E8F0',
  },

  '.metadata-overlay-wrapper': {
    display: visible ? 'flex' : 'none',
  },

  '.attach-thumbnail-text': {
    color: theme.palette.text.secondary_txt,
    marginTop: '8px',
  },
  '.attach-fileUrl-text': {
    color: theme.palette.text.secondary_txt,
    fontWeight: 400,
    paddingLeft: '6px',
    flex: isActionItem && '1',
  },
  '.attachment-uploader-thumbnail > div': {
    position: isActionItem && 'unset',
  },
}));

const getBackgroundColorByFileType = ({ theme, fileType }) => {
  switch (fileType) {
    case 'pdf': {
      return theme?.palette?.background?.pdf_icon;
    }
    case 'word': {
      return theme?.palette?.background?.word_icon;
    }
    case 'xls': {
      return theme?.palette?.background?.xls_icon;
    }
    case 'ppt': {
      return theme?.palette?.background?.ppt_icon;
    }
    case 'csv': {
      return theme?.palette?.background?.csv_icon;
    }
    default: {
      return theme?.palette?.background?.default_file_icon;
    }
  }
};

const ignoredProps = ['fileUrl', 'fileType', 'isImage', 'isDefault', 'isActionItem', 'isMinimizedSize'];

export const StyledThumbnail = styled('div', {
  shouldForwardProp: (prop) => !ignoredProps.includes(prop),
})(({ fileUrl, fileType, isImage, isDefault, isActionItem, theme, isMinimizedSize }) => ({
  position: 'relative',
  ...(!isActionItem && { height: '120px' }),
  ...(isMinimizedSize && { height: '72px' }),
  backgroundImage: `url(${fileUrl})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: isImage && !isDefault ? 'cover,70px' : '70px',
  background: isActionItem && 'none',
  backgroundColor: isActionItem ? '#EDEFF1' : getBackgroundColorByFileType({ theme, fileType }),
  borderRadius: '6px',
  marginBottom: '4px',
  padding: isActionItem && '6px 0 6px 6px',
  cursor: 'pointer',
  display: 'flex',
  '& > .attach-fileUrl-text': {
    flex: isActionItem && '1',
  },
}));

export const StyledFileTypeLogo = styled('div', { shouldForwardProp: (prop) => prop !== 'isActionItem' })(
  ({ isActionItem }) => ({
    position: 'absolute',
    left: '6px',
    right: '140px',
    top: '6px',
    bottom: '90.9',
    height: '24.08px',
    width: '24px',
    background: '#5380C0',
    borderRadius: '6px',
    ...(isActionItem && {
      img: {
        width: '100%',
      },
    }),
  }),
);

export const StyledAttachmentUsageCount = styled('div')(({ theme, isImage, isActionItem }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: !isActionItem && 'absolute',
  background: theme.palette.grey.bt_def_grey_reg,
  borderRadius: '50%',
  left: isImage ? '35px' : '6px',
  top: '6px',
  color: 'white',
  fontSize: '11px',
  fontFamily: 'Roboto-Bold',
  padding: '8px',
  height: '24px',
  width: '24px',
}));

export const StyledVideoContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'visible',
})(({ theme, visible }) => ({
  width: '170px',
  position: 'relative',

  '.metadata-overlay-wrapper': {
    display: visible ? 'flex' : 'none',
  },

  '.attach-thumbnail-text': {
    color: theme?.palette?.text?.secondary_txt,
    marginTop: '8px',
  },

  '.attachment-uploader-thumbnail': {
    background: 'unset',
  },

  video: {
    position: 'absolute',
    width: '100%',
    height: '120px',
    objectFit: 'fill',
    borderRadius: '6px',
  },
}));
