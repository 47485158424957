import { useCallback } from 'react';
import MultiSelectField from 'common/components/controls/MultiSelectField';
import { SwitchToggle } from 'common/components/controls/Switch/SwitchToggle';
import useTexts from 'features/templateBuilder/useTexts';
import { StyledFieldHeader, StyledFieldLabel } from 'common/components/controls/MultiSelectField/style';
import { StyledPropertyWrapper } from '../StyledTemplatePropertiesPanel';

const STATUS_FIELD_ID = 8;

export default function RequiredFieldProperty(props) {
  const {
    switchId,
    allStatuses,
    selectedStatuses,
    showRequiredField,
    isSwitchDisabled,
    onSwitchChange,
    handleStatusChange,
    srType,
  } = props;
  const { requiredFieldTitle, requiredFieldText, requiredFieldPlaceholder } = useTexts();

  const handleSelectStatuses = useCallback(
    (selectedStatuses = []) => {
      handleStatusChange(selectedStatuses);
    },
    [handleStatusChange],
  );

  const handleSwitchChange = (event) => {
    onSwitchChange(event);
  };

  const fieldPlaceholder =
    showRequiredField && Array.isArray(selectedStatuses) && !selectedStatuses.length ? requiredFieldPlaceholder : '';

  return (
    <StyledPropertyWrapper data-testid="required-field">
      <SwitchToggle
        handleSwitchChange={handleSwitchChange}
        isChecked={showRequiredField}
        switchText={requiredFieldTitle}
        isTemplateBuilder
        isDisabled={isSwitchDisabled}
        switchId={switchId}
        classNames={{ labelClass: 'template-switch-toggle-label' }}
      />
      {showRequiredField && (
        <>
          <StyledFieldHeader>
            <StyledFieldLabel>{requiredFieldText}</StyledFieldLabel>
          </StyledFieldHeader>
          <MultiSelectField
            itemList={allStatuses}
            selectedItemList={selectedStatuses}
            handleSelectItems={handleSelectStatuses}
            captionString="value"
            keyString="id"
            fieldPlaceholder={fieldPlaceholder}
            srType={srType}
            fieldId={STATUS_FIELD_ID}
          />
        </>
      )}
    </StyledPropertyWrapper>
  );
}
