import useTexts from 'features/resolutionPanel/useTexts';
import { useSelector } from 'react-redux';
import { useRouter } from '@tanstack/react-router';
import PropTypes from 'prop-types';
import Attachments from '../../../../features/resolutionPanel/middlePanel/attachments';
import AttachmentDropzone from '../../attachmentDropzone';
import { useUploadAttachments } from '../../../../features/resolutionPanel/middlePanel/attachments/hooks/useUploadAttachments';
import { StyledAttachmentsField } from './style';
import { useTicketLockStatus } from '../../../../remote-state/ticketServiceHooks';
import { selectActiveUser } from '../../../../store/userSlice';

export const AttachmentsWrapper = ({ actionItemId, readOnly, disabled }) => {
  const { handleUploadNewAttachment } = useUploadAttachments({ actionItemId });
  const handleAttachmentDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => handleUploadNewAttachment(file));
  };
  const router = useRouter();
  const srId = router.latestLocation.search?.id;
  const userAccount = useSelector(selectActiveUser);
  const { data: lockingDetails } = useTicketLockStatus(srId);
  const isTicketLocked =
    (lockingDetails?.isLocked && lockingDetails?.lockingUser !== userAccount?.username) || readOnly || disabled;

  const { attachmentsTab } = useTexts();
  return (
    <StyledAttachmentsField>
      <AttachmentDropzone isActionItem onAttachmentDrop={handleAttachmentDrop} dropzoneDisabled={isTicketLocked}>
        <span className="title">{attachmentsTab}</span>
        <Attachments
          isActionItem
          actionItemId={actionItemId}
          isExpanded
          readOnly={readOnly}
          isTicketLocked={isTicketLocked}
        />
      </AttachmentDropzone>
    </StyledAttachmentsField>
  );
};

AttachmentsWrapper.propTypes = {
  actionItemId: PropTypes.number,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};
