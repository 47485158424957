import { styled } from '@mui/system';

export const StyledWrapper = styled('div')(({ theme }) => ({
  '&& .MuiInputBase-root': {
    width: '100%',
  },
  '&& .MuiInputBase-input.MuiInput-input': {
    color: theme?.palette?.text?.txt_default,
  },

  'div input': {
    paddingRight: '40px',
  },
}));
