import { AttachmentChip } from './AttachmentChip';

export const AttachmentChipList = ({
  attachments,
  onAttachmentDeleted,
  isReadOnly,
  isDeleted,
  isDownloaded,
  shouldTriggerDeleteApi,
  deleteAttachmentEnabled,
  onDisplayPreview,
  journeyThumbnails = [],
  isMinimizedSize
}) =>
  attachments.map(
    (attachmentDetails) =>
      !(!isReadOnly && attachmentDetails.deleted) && (
        <AttachmentChip
          {...attachmentDetails}
          isMinimizedSize={isMinimizedSize}
          attachmentId={attachmentDetails.id}
          fileId={attachmentDetails.fileId || attachmentDetails.id}
          subResourceType={attachmentDetails.subResourceType}
          isDeleted={attachmentDetails.deleted || isDeleted}
          isDownloaded={isDownloaded}
          onAttachmentDeleted={onAttachmentDeleted}
          key={attachmentDetails.id}
          isReadOnly={isReadOnly}
          shouldTriggerDeleteApi={shouldTriggerDeleteApi}
          deleteAttachmentEnabled={deleteAttachmentEnabled}
          onDisplayPreview={onDisplayPreview}
          journeyThumbnails={journeyThumbnails}
          attachments={attachments}
        />
      ),
  );
