import { useState } from 'react';
import { useSelector } from 'react-redux';
import useTexts from 'features/resolutionPanel/useTexts';
import ButtonBase from 'common/components/buttons/FormButton';
import { editorActionData } from '../../constants';
import { editorActionTypes } from '../../constants/editorActionTypes';
import { selectActionLine, selectHasActiveUploads } from '../../store/slice';
import { useEditorActions } from '../../hooks';
import { StyledFooter } from './style';

const ActionLineFooter = ({ customSaveText }) => {
  const { cancel, save: defaultSaveText, send } = useTexts();
  const [saveClicked, setSavedClicked] = useState(false);
  const { type, data, isEditorDirty, isNewEditor, lastSavedData } = useSelector(selectActionLine);
  const isUploadActive = useSelector(selectHasActiveUploads);
  const { saveEditorDataByType, clearEditorData, isEditorValid, handleEditorChange } = useEditorActions();
  const editorActionSettings = editorActionData[type];
  const isSaveDisabled = !isEditorDirty || !isEditorValid() || isUploadActive || saveClicked;
  const handleOkClicked = async (event) => {
    setSavedClicked(true);
    await saveEditorDataByType(event);
    setSavedClicked(false);
  };
  const commonProps = {
    'data-testid': 'action-line-save-button',
    'data-cy': 'action-line-save-button',
    disabled: isSaveDisabled,
    onClick: handleOkClicked,
  };
  return (
    <StyledFooter>
      <div
        className={`elements-footer ${editorActionSettings.editorElementClassName || 'editor-element'}`}
        data-testid="action-line-footer"
      >
        {editorActionSettings?.footerElements?.map((typeRender) => (
          <typeRender.Component
            key={typeRender.id}
            editorData={data}
            lastSavedData={lastSavedData}
            isNewEditor={isNewEditor}
            isEditorDirty={isEditorDirty}
            handleEditorChange={handleEditorChange}
            editorClassName={editorActionSettings.editorElementClassName}
            editorType={type}
            dataTestId={`${typeRender.id}-${type}`}
          />
        ))}
      </div>
      <div className="elements-footer">
        <button
          className={`cancel-btn ${editorActionSettings.editorElementClassName || 'editor-element'}`}
          onClick={() => clearEditorData({ isFromFooterCancelButton: true })}
        >
          {cancel}
        </button>
        {editorActionTypes.MESSAGE === type ? (
          <ButtonBase {...commonProps} btnTheme="primary" text={send} />
        ) : (
          <button {...commonProps} className="ok-btn">
            {customSaveText || defaultSaveText}
          </button>
        )}
      </div>
    </StyledFooter>
  );
};

export default ActionLineFooter;
