import { styled } from '@mui/material/styles';
import { ReactComponent as ChevronDownSvg } from 'images/icons/chevron_down.svg';
import { FloatingTicketInstanceModes } from '../floatingTicketPanelManager/modes';

export const StyledFloatingHeader = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  position: 'relative',
  marginBottom: '12px',
  height: '32px',
  gap: '26px',
}));

export const StyledCollapsedHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme?.palette?.text?.title_txt,
  color: theme?.palette?.generic?.primary,
  padding: '0 20px',
  '& svg': {
    color: theme?.palette?.switchToggle?.unchecked,
  },
  height: '48px',
  zIndex: 10,
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
}));

export const StyledEmptyTitle = styled('div')(({ theme, width }) => ({
  color: theme?.palette?.generic?.primary,
  width: `${width}px`,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  position: 'absolute',
}));

export const StyledFieldsWrapper = styled('div')(() => ({
  display: 'flex',
}));

export const StyledTemplateWrapper = styled('div')(({ width }) => ({
  display: 'flex',
  width,
  maxWidth: '228px',
}));

export const StyledFieldKey = styled('div')(({ theme }) => ({
  color: theme?.palette?.text?.secondary_txt,
  fontSize: theme?.fontSize?.main,
  marginRight: 11,
}));

export const StyledFieldValue = styled('div')(({ theme }) => ({
  color: theme?.palette?.text?.txt_default,
  fontSize: theme?.fontSize?.main,
}));

export const StyledFieldDropdownValue = styled(StyledFieldValue)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  overflow: 'hidden',
}));

export const StyledActions = styled('div', {
  shouldForwardProp: (prop) => prop !== 'theme',
})(({ theme, mode }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  maxWidth: 96,
  position: mode === FloatingTicketInstanceModes.COLLAPSED ? 'absolute' : 'static',
  right: 0,
  marginRight: mode === FloatingTicketInstanceModes.COLLAPSED ? '10px' : undefined,
  marginLeft: mode === FloatingTicketInstanceModes.COLLAPSED ? 0 : 'auto',

  '& .icon-wrapper': {
    minWidth: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'end',
    cursor: 'pointer',
  },
  '& [data-testid="minimize"]': {
    alignItems: mode === FloatingTicketInstanceModes.COLLAPSED ? 'start' : 'end',
  },
  '& path': {
    stroke:
      mode === FloatingTicketInstanceModes.COLLAPSED
        ? theme?.palette?.generic?.primary
        : theme?.palette?.grey?.bt_def_grey_reg,
  },
}));

export const ChevronIcon = styled(ChevronDownSvg, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ open }) => ({
  transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
  display: 'flex',
  flexShrink: 0,
  marginLeft: '4px',
}));
