import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    day: texts?.['spaces.datePicker.dueDate.day'],
    hour: texts?.['spaces.datePicker.dueDate.hour'],
    minute: texts?.['spaces.datePicker.dueDate.minute'],
    setDate: texts?.['spaces.datePicker.dueDate.setDate'],
  };

  return componentTexts;
}
