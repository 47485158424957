import MUICircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import { StyledCircularProgress } from './style';
import theme from '../../../features/theme/themes/lightTheme';

const MUICircularProgressStyled = styled(MUICircularProgress)(({ isGradient }) => ({
  circle: {
    ...(isGradient && { stroke: 'url(#linearColors)' }),
  },
}));

export default function CircularProgress(props) {
  const { className = '', size = '1rem', isGradient = false } = props;

  return (
    <StyledCircularProgress className={`Loading ${className}`}>
      {isGradient && (
        <svg width="0" height="0">
          <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
            <stop offset="30%" stopColor={theme.palette.generic.sysaid} />
            <stop offset="80%" stopColor={theme.palette.generic.sysaid_light} />
          </linearGradient>
        </svg>
      )}
      {/* TODO: make size change according to container size */}
      <MUICircularProgressStyled size={size} {...props} isGradient={isGradient} />
    </StyledCircularProgress>
  );
}
