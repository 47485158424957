import { StyledHeader, StyledTitle } from './style';

export const Header = ({ title, Icon, dataTestId }) => (
  <StyledHeader data-testid={dataTestId}>
    <StyledTitle>
      <Icon />
      <span>{title}</span>
    </StyledTitle>
  </StyledHeader>
);
