import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    searchText: texts?.['spaces.search'],
    searchAllColumnsText: texts?.['spaces.searchAllColumns'],
  };

  return componentTexts;
}
