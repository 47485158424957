import { FixedSizeList } from 'react-window';
import { useAssigneeQuery } from 'remote-state/userServiceHooks';
import { AssigneeItem } from 'common/components/assigneeItem';
import CustomScrollBar from 'common/components/customScroll';
import useTexts from 'common/components/assignee/useTexts';
import { StyledMenuItem } from '../AssigneeDropdown/style';
import { ReactComponent as IconBack } from '../../../../../images/icons/icon_back.svg';
import { ASSIGNEE_CONSTANTS } from '../constants';

import { StyledGroupDetailsWrapper, StyledGroupDropdown } from './style';

export const GroupDropdown = ({
  customScrollProps,
  dropdownWidth,
  assigneeItemHeight,
  selectedGroup,
  onClickBack,
  handleSelect,
  currentAssigned,
  searchText,
}) => {
  const { membersText, assignToTeam } = useTexts();
  const { users } = useAssigneeQuery(searchText, selectedGroup?.id);
  const dropdownDisplayedItemsCount =
    users?.length > ASSIGNEE_CONSTANTS.DROPDOWN_MAX_ITEMS ? ASSIGNEE_CONSTANTS.DROPDOWN_MAX_ITEMS : users?.length;

  const isSelected = (calculatedUserName) =>
    selectedGroup?.groupName === currentAssigned?.group?.groupName &&
    calculatedUserName === currentAssigned?.user?.calculatedUserName;

  const renderAssignToTeamOption = () => (
    <StyledMenuItem
      itemHeight={assigneeItemHeight}
      key={selectedGroup?.id}
      value={selectedGroup?.groupName}
      onClick={() => handleSelect({ group: selectedGroup })}
      className="assign-to-team-option"
    >
      <AssigneeItem text={assignToTeam} type="group" />
    </StyledMenuItem>
  );

  return (
    <StyledGroupDropdown>
      <StyledGroupDetailsWrapper>
        <IconBack onClick={onClickBack} />
        <div className="group-details">
          <span className="group-name">{selectedGroup?.groupName}</span>
          <span className="group-members-count">
            {users?.length || 0} {membersText}
          </span>
        </div>
      </StyledGroupDetailsWrapper>
      <CustomScrollBar
        autoHeightMax={assigneeItemHeight * ASSIGNEE_CONSTANTS.DROPDOWN_SCROLL_THRESHOLD}
        {...customScrollProps}
      >
        {renderAssignToTeamOption()}
        <FixedSizeList
          height={assigneeItemHeight * dropdownDisplayedItemsCount}
          itemCount={dropdownDisplayedItemsCount}
          itemSize={assigneeItemHeight}
          width={dropdownWidth}
        >
          {({ index }) => {
            const userGroup = users[index] || {};
            const { id, calculatedUserName } = userGroup;
            return (
              <StyledMenuItem
                itemHeight={assigneeItemHeight}
                key={id}
                onClick={() => handleSelect({ user: userGroup, group: selectedGroup })}
                selected={isSelected(calculatedUserName)}
              >
                <AssigneeItem
                  text={calculatedUserName}
                  profileImage={calculatedUserName?.profileImage}
                  isSelected={isSelected(calculatedUserName)}
                />
              </StyledMenuItem>
            );
          }}
        </FixedSizeList>
      </CustomScrollBar>
    </StyledGroupDropdown>
  );
};

GroupDropdown.propTypes = {};

GroupDropdown.defaultProps = {
  dropdownHeightMax: 230,
  assigneeItemHeight: 46,
  maxDisplayedMenuItems: 25,
};
