import Tooltip from 'common/components/tooltip';
import { ReactComponent as NoSortIcon } from '../../../../../images/icons/gh_sort .svg';
import { ReactComponent as UpIcon } from '../../../../../images/icons/gh_up.svg';
import { ReactComponent as DownIcon } from '../../../../../images/icons/gh_down.svg';
import { StyledSortIcon } from './style';
import useTexts from '../useTexts';

const SortIcon = ({ sort, onClick: onClickProp, className = '', disabled = false }) => {
  const handleClick = (e) => {
    if (onClickProp) {
      onClickProp(e);
    }
  };

  const getSortIcon = () => {
    switch (sort) {
      case 'asc':
        return <UpIcon data-testid="up-icon" />;
      case 'desc':
        return <DownIcon data-testid="down-icon" />;
      default:
        return <NoSortIcon data-testid="noSortIcon" className="noSortIcon" />;
    }
  };
  const { filterCustomFieldExceeded } = useTexts();

  const sortIcon = <StyledSortIcon data-testid="sortIcon" onClick={!disabled ? handleClick : undefined} className={`SortIcon ${className}`}>
    {getSortIcon()}
  </StyledSortIcon>;

  return (
    <Tooltip
      text={sortIcon}
      title={filterCustomFieldExceeded}
      disableHoverListener={!disabled}
    />

  );
};
export default SortIcon;
