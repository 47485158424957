import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    successfulUploadTexts: texts?.['spaces.resolutionPanel.attachments.toaster.success'],
    modalTitleText: texts?.['spaces.resolutionPanel.attachments.modal.title'],
    modalDescriptionText: texts?.['spaces.resolutionPanel.attachments.modal.description'],
    modalBtnOkText: texts?.['spaces.requiredFieldsModal.OkText'],
    modalBtnCancelText: texts?.['spaces.requiredFieldsModal.CancelText'],
  };

  return componentTexts;
}
