import { QuickFiltersSelector } from '../quickFiltersSelector/quickFiltersSelector';
import { StyledGridConfigurationHeader } from './style';
import { ViewConfiguration } from './viewConfiguration';

export default function GridConfigurationHeader({ className }) {
  return (
    <StyledGridConfigurationHeader className={className} id="grid-header">
      <ViewConfiguration />

      <QuickFiltersSelector />
    </StyledGridConfigurationHeader>
  );
}
