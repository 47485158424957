import { Button, Tabs, Box, styled } from '@mui/material';

export const StyledDataFilters = styled(Box)(({ theme }) => ({
  width: '100%',
  fontSize: '14px',
  fontFamily: theme.fonts.robotoRegular,
  padding: '0px 24px 0px 24px',

  '#tabs-wrapper': {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    '&:after': {
      position: 'absolute',
      content: '""',
      height: '1px',
      width: '100%',
      backgroundColor: theme.palette.grey.divider_grey_v1,
      bottom: '0',
    },

    '#tabs-listcontainer': {
      display: 'flex',
      flexGrow: '1',
      alignItems: 'flex-end',
      paddingTop: '4px',
      '.hide': {
        display: 'none',
      },
      '.MuiButtonBase-root': {
        textTransform: 'none',
        color: theme.palette.text.secondary_txt,
        fontFamily: theme.fonts.robotoRegular,
        padding: '4px 8px 6px 8px',
        lineHeight: '20px',
        height: 'auto',
        width: 'auto',
        minHeight: 'auto',
        '&.selected-tab': {
          color: `${theme.palette.text.txt_default} !important`,
        },
      },
    },
    '#tabs-filters': {
      marginLeft: 'auto',
    },
  },
}));
export const StyledTabs = styled(Tabs)(({ theme }) => ({
  height: 'auto',
  minHeight: 'auto',
  '.MuiTabs-flexContainer': {
    gap: '24px',
  },
  '.MuiTabs-indicator': {
    background: theme.palette.generic.sysaid,
    borderRadius: '10px',
    zIndex: '2',
  },
}));
export const JourneyButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'dropdownOpened',
})(({ theme, dropdownOpened }) => ({
  '&.MuiButtonBase-root': {
    marginRight: '0',
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: theme.fonts.robotoRegular,
    padding: '6px 10px 8px',
    borderRadius: '4px',
    textTransform: 'none',
    backgroundColor: dropdownOpened ? theme.palette.background.bg_hover_main : 'transparent',
    color: theme.palette.text.txt_default,

    '& svg path': {
      fill: theme.palette.grey.bt_def_grey_reg,
    },
    '& span': {
      lineHeight: '20px',
    },
    '&:hover': {
      backgroundColor: theme.palette.background.bg_hover_main,
    },
  },
  '& .MuiButton-endIcon': {
    margin: '0px 0px 0px 8px',
  },
}));
