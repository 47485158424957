import { isEmpty } from 'lodash-es';
import { SubResourceType } from 'services/attachments';

const convertToApiObj = (resolutionObj, isUpdate = false, activeIndex = null) => {
  const {
    srId,
    srType,
    author,
    closureInformation,
    closureInformationCaption,
    assignee,
    status,
    statusCaption,
    articleName,
    linkToKnowledgeBase,
    isLinkToKnowledgeBase,
    shareWithReqUser,
    isShareWithReqUserChanged = false,
    resolutionText,
    solutionText,
    attachments,
    inlineImages,
    solution,
    resolution,
    auditLogId,
  } = resolutionObj;
  let solutionAttachments = attachments?.filter(
    (attachment) => attachment.subResourceType === SubResourceType.SOLUTION,
  );
  let solutionImages = inlineImages?.filter((inlineImage) => inlineImage.subResourceType === SubResourceType.SOLUTION);
  let resolutionAttachments = attachments?.filter(
    (attachment) => attachment.subResourceType === SubResourceType.RESOLUTION,
  );
  let resolutionImages = inlineImages?.filter(
    (inlineImage) => inlineImage.subResourceType === SubResourceType.RESOLUTION,
  );

  // convert from api obj, in case of delete attachment from the view
  const hasSolutionAttachments = solution && solutionAttachments.length > 0;
  const hasResolutionAttachments = resolution && resolutionAttachments.length > 0;
  if (hasSolutionAttachments) {
    resolutionAttachments = resolution?.attachments;
    resolutionImages = resolution?.inlineImages;
  } else if (hasResolutionAttachments) {
    solutionAttachments = solution?.attachments;
    solutionImages = solution?.inlineImages;
  }

  const solutionTextFinal = solutionText || solution?.text;
  const articleNameFinal = articleName || solution?.articleName;
  const linkToKnowledgeBaseFinal = linkToKnowledgeBase || solution?.linkToKnowledgeBase;
  const isLinkToKnowledgeBaseFinal = isLinkToKnowledgeBase || solution?.isLinkToKnowledgeBase || false;
  const resolutionTextFinal = resolutionText || resolution?.text;
  const hasResolutionAttachmentsLen = resolutionAttachments?.length > 0 || resolution?.attachments?.length > 0;
  const hasResolutionImagesLen = resolutionImages?.length > 0 || resolution?.inlineImages?.length > 0;

  let solutionResolution = {
    closureInformation,
    assignee,
    status,
    srId,
    srType,
    author,
    shareWithReqUser,
    isShareWithReqUserChanged,
    isResolution: false,
    closureInformationCaption,
    statusCaption,
    solution: {
      text: solutionTextFinal,
      articleName: articleNameFinal,
      linkToKnowledgeBase: linkToKnowledgeBaseFinal,
      isLinkToKnowledgeBase: isLinkToKnowledgeBaseFinal,
      attachments: solutionAttachments,
      inlineImages: solutionImages,
    },
  };

  if (resolutionTextFinal || hasResolutionAttachmentsLen || hasResolutionImagesLen) {
    solutionResolution = {
      ...solutionResolution,
      isResolution: true,
      resolution: {
        text: resolutionText || resolution?.text,
        attachments: resolutionAttachments,
        inlineImages: resolutionImages,
      },
    };
  }

  if (isUpdate) {
    solutionResolution = {
      ...solutionResolution,
      auditLogId: activeIndex,
    };
  }

  const isDeleteAttachmentFromView = solution || resolution;
  if (isDeleteAttachmentFromView) {
    solutionResolution = {
      ...solutionResolution,
      auditLogId,
    };
  }

  return solutionResolution;
};

const inlineImagesReduxObj = (solutionResolutionImages, resolutionImages) => {
  let inlineImagesReduxObj = [];
  if (solutionResolutionImages && resolutionImages)
    inlineImagesReduxObj = [...solutionResolutionImages, ...resolutionImages];
  if (solutionResolutionImages) inlineImagesReduxObj = [...solutionResolutionImages];
  if (resolutionImages) inlineImagesReduxObj = [...resolutionImages];

  return inlineImagesReduxObj;
};

const convertToReduxObj = (apiObj) => {
  const {
    closureInformation,
    assignee,
    status,
    author,
    shareWithReqUser,
    isShareWithReqUserChanged,
    closureInformationCaption,
    statusCaption,
    isResolution,
    isDisabledJourneyLog,
    solution,
    resolution,
    manualStatusChanged,
  } = apiObj;

  let solutionResolution = {
    closureInformation,
    closureInformationCaption,
    assignee,
    status,
    statusCaption,
    manualStatusChanged,
    author,
    articleName: solution?.articleName,
    linkToKnowledgeBase: solution?.linkToKnowledgeBase,
    isLinkToKnowledgeBase: solution?.isLinkToKnowledgeBase,
    shareWithReqUser,
    isShareWithReqUserChanged,
    isDisabledJourneyLog,
    solutionText: solution?.text,
    resolutionText: '',
    attachments: solution?.attachments,
    inlineImages: solution?.inlineImages,
  };

  if (isResolution) {
    solutionResolution = {
      ...solutionResolution,
      attachments: [...solutionResolution.attachments, ...resolution.attachments],
      inlineImages: inlineImagesReduxObj(solutionResolution.inlineImages, resolution.inlineImages),
      resolutionText: resolution.text,
    };
  }
  return solutionResolution;
};

const isValidResolution = (resolution) => {
  const { solution, solutionText, isValidSrField, status, closureInformation } = resolution;
  return !!((solutionText || solution?.text) && isValidSrField && status && closureInformation);
};

const isSRFieldsValid = (fieldSrList, srId, assignee) => {
  const srFields = fieldSrList[srId];
  if (!isEmpty(srFields)) {
    const requiredInvalidFields = Object.keys(srFields).filter((fieldName) => {
      const field = srFields[fieldName];
      return field.required && !field.isValid;
    });

    if (requiredInvalidFields.length) {
      if (requiredInvalidFields.length === 1 && requiredInvalidFields.find((key) => key === 'assignee') && assignee) {
        return true;
      }
      return false;
    }
  }
  return true;
};
export { convertToApiObj, convertToReduxObj, isValidResolution, isSRFieldsValid };
