import { useMemo } from 'react';
import { useRouter } from '@tanstack/react-router';

import Tooltip from 'common/components/tooltip';
import { ReactComponent as UnlinkItemIcon } from 'images/icons/link-item.svg';
import { SR_TYPE_CODE_MAP } from 'features/TicketPanel/constants';
import { useFieldAttributes } from 'remote-state/ticketServiceHooks';

import { LOCATION_FIELD_ATTRIBUTES, RELATIONSHIP_ENUMS } from '../constants';
import useTexts from '../hooks/useTexts';

export const useTableData = () => {
  const router = useRouter();
  const srId = router.latestLocation.search.id;
  const {
    data: { values: locationList },
  } = useFieldAttributes(LOCATION_FIELD_ATTRIBUTES, false, srId)?.[0] || { data: { values: [] } };
  const { componentTexts, getRelationshipText } = useTexts();

  const srTableColumns = useMemo(
    () => [
      {
        accessorKey: 'relationship',
        header: (
          <Tooltip
            text={componentTexts?.RelationshipText}
            isTruncatedText
            placement="top"
            style={{ fontFamily: 'Roboto-Bold' }}
          />
        ),
        cell: (info) => (
          <Tooltip
            text={getRelationshipText(RELATIONSHIP_ENUMS[info.getValue()])}
            isTruncatedText
            placement="top"
            style={{ fontFamily: 'Roboto-Regular', lineHeight: '16px' }}
          />
        ),
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: 'id',
        header: componentTexts?.idText,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: 'srType',
        header: (
          <Tooltip
            text={componentTexts?.typeText}
            isTruncatedText
            placement="top"
            style={{ fontFamily: 'Roboto-Bold' }}
          />
        ),
        cell: (info) => {
          const srTypeValue = SR_TYPE_CODE_MAP[info.getValue()];
          const srTypeCaption = String(srTypeValue).charAt(0).toUpperCase() + String(srTypeValue).slice(1);
          return (
            <Tooltip
              text={srTypeCaption}
              isTruncatedText
              placement="top"
              style={{ fontFamily: 'Roboto-Regular', lineHeight: '16px' }}
            />
          );
        },
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'title',
        header: (
          <Tooltip
            text={componentTexts?.titleText}
            isTruncatedText
            placement="top"
            style={{ fontFamily: 'Roboto-Bold' }}
          />
        ),
        cell: (info) => (
          <div className="related-items-title">
            <Tooltip
              text={info.getValue()}
              isTruncatedText
              placement="top"
              style={{ fontFamily: 'Roboto-Regular', lineHeight: '16px' }}
            />
          </div>
        ),
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: 'unlinkItem',
        id: 'unlinkItem',
        header: '',
        cell: () => <UnlinkItemIcon className="unlink-item-icon" />,
        enableSorting: false,
        enableColumnFilter: false,
      },
    ],
    [
      componentTexts?.RelationshipText,
      componentTexts?.idText,
      componentTexts?.typeText,
      componentTexts?.titleText,
      getRelationshipText,
    ],
  );

  const assetTableColumns = useMemo(
    () => [
      {
        accessorKey: 'relationship',
        header: (
          <Tooltip
            text={componentTexts?.RelationshipText}
            isTruncatedText
            placement="top"
            style={{ fontFamily: 'Roboto-Bold' }}
          />
        ),
        cell: (info) => (
          <Tooltip
            text={getRelationshipText(RELATIONSHIP_ENUMS[info.getValue()])}
            isTruncatedText
            placement="top"
            style={{ fontFamily: 'Roboto-Regular', lineHeight: '16px' }}
          />
        ),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'serialNumber',
        header: (
          <Tooltip
            text={componentTexts?.serialNumberText}
            isTruncatedText
            placement="top"
            style={{ fontFamily: 'Roboto-Bold' }}
          />
        ),
        cell: (info) => (
          <Tooltip
            text={info.getValue()}
            isTruncatedText
            placement="top"
            style={{ fontFamily: 'Roboto-Regular', lineHeight: '16px' }}
          />
        ),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'assetType',
        header: (
          <Tooltip
            text={componentTexts?.typeText}
            isTruncatedText
            placement="top"
            style={{ fontFamily: 'Roboto-Bold' }}
          />
        ),
        cell: (info) => (
          <Tooltip
            text={info.getValue()}
            isTruncatedText
            placement="top"
            style={{ fontFamily: 'Roboto-Regular', lineHeight: '16px' }}
          />
        ),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'itemName',
        header: (
          <Tooltip
            text={componentTexts?.itemNameText}
            isTruncatedText
            placement="top"
            style={{ fontFamily: 'Roboto-Bold' }}
          />
        ),
        cell: (info) => (
          <Tooltip
            text={info.getValue()}
            isTruncatedText
            placement="top"
            style={{ fontFamily: 'Roboto-Regular', lineHeight: '16px' }}
          />
        ),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'location',
        header: (
          <Tooltip
            text={componentTexts?.locationText}
            isTruncatedText
            placement="top"
            style={{ fontFamily: 'Roboto-Bold' }}
          />
        ),
        cell: (info) => {
          const locationCaption =
            locationList?.find((location) => location.id === parseInt(info.getValue(), 10))?.value || '';
          return (
            <Tooltip
              text={locationCaption}
              isTruncatedText
              placement="top"
              style={{ fontFamily: 'Roboto-Regular', lineHeight: '16px' }}
            />
          );
        },
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'owner',
        header: (
          <Tooltip
            text={componentTexts?.ownerText}
            isTruncatedText
            placement="top"
            style={{ fontFamily: 'Roboto-Bold' }}
          />
        ),
        cell: (info) => (
          <Tooltip
            text={info.getValue()}
            isTruncatedText
            placement="top"
            style={{ fontFamily: 'Roboto-Regular', lineHeight: '16px' }}
          />
        ),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'unlinkItem',
        id: 'unlinkItem',
        header: '',
        cell: () => <UnlinkItemIcon className="unlink-item-icon" />,
        enableSorting: false,
        enableColumnFilter: false,
      },
    ],
    [
      componentTexts?.RelationshipText,
      componentTexts?.itemNameText,
      componentTexts?.locationText,
      componentTexts?.ownerText,
      componentTexts?.serialNumberText,
      componentTexts?.typeText,
      getRelationshipText,
      locationList,
    ],
  );

  const ciTableColumns = useMemo(
    () => [
      {
        accessorKey: 'relationship',
        header: (
          <Tooltip
            text={componentTexts?.RelationshipText}
            isTruncatedText
            placement="top"
            style={{ fontFamily: 'Roboto-Bold' }}
          />
        ),
        cell: (info) => (
          <Tooltip
            text={getRelationshipText(RELATIONSHIP_ENUMS[info.getValue()])}
            isTruncatedText
            placement="top"
            style={{ fontFamily: 'Roboto-Regular', lineHeight: '16px' }}
          />
        ),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'id',
        header: componentTexts?.idText,
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'ciType',
        header: (
          <Tooltip
            text={componentTexts?.typeText}
            isTruncatedText
            placement="top"
            style={{ fontFamily: 'Roboto-Bold' }}
          />
        ),
        cell: (info) => (
          <Tooltip
            text={info.getValue()}
            isTruncatedText
            placement="top"
            style={{ fontFamily: 'Roboto-Regular', lineHeight: '16px' }}
          />
        ),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'ciName',
        header: (
          <Tooltip
            text={componentTexts?.itemNameText}
            isTruncatedText
            placement="top"
            style={{ fontFamily: 'Roboto-Bold' }}
          />
        ),
        cell: (info) => (
          <Tooltip
            text={info.getValue()}
            isTruncatedText
            placement="top"
            style={{ fontFamily: 'Roboto-Regular', lineHeight: '16px' }}
          />
        ),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'location',
        header: (
          <Tooltip
            text={componentTexts?.locationText}
            isTruncatedText
            placement="top"
            style={{ fontFamily: 'Roboto-Bold' }}
          />
        ),
        cell: (info) => {
          const locationCaption =
            locationList?.find((location) => location.id === parseInt(info.getValue(), 10))?.value || '';
          return (
            <Tooltip
              text={locationCaption}
              isTruncatedText
              placement="top"
              style={{ fontFamily: 'Roboto-Regular', lineHeight: '16px' }}
            />
          );
        },
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'owner',
        header: (
          <Tooltip
            text={componentTexts?.ownerText}
            isTruncatedText
            placement="top"
            style={{ fontFamily: 'Roboto-Bold' }}
          />
        ),
        cell: (info) => (
          <Tooltip
            text={info.getValue()}
            isTruncatedText
            placement="top"
            style={{ fontFamily: 'Roboto-Regular', lineHeight: '16px' }}
          />
        ),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'unlinkItem',
        id: 'unlinkItem',
        header: '',
        cell: () => <UnlinkItemIcon className="unlink-item-icon" />,
        enableSorting: false,
        enableColumnFilter: false,
      },
    ],
    [
      componentTexts?.RelationshipText,
      componentTexts?.itemNameText,
      componentTexts?.locationText,
      componentTexts?.ownerText,
      componentTexts?.idText,
      componentTexts?.typeText,
      getRelationshipText,
      locationList,
    ],
  );

  return { srTableColumns, assetTableColumns, ciTableColumns };
};
