import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const LogList = styled('ul')(() => ({
  margin: '0',
  paddingInlineStart: '24px',
  maxHeight: '100px',
  overflowY: 'scroll',
}));

export const StyledDetailsHeader = styled(Box)(() => ({
  display: 'flex',
  columnGap: '8px',
  alignItems: 'center',
  marginBottom: '4px',
}));

export const StyledDetailsTitle = styled(Box)(({ theme }) => ({
  color: theme.palette.text.title_txt,
  fontFamily: theme.fonts.robotoMedium,
  fontSize: theme.fontSize.main,
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '24px',
}));

export const AmountOfRows = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.grey.divider_grey_v2,
  borderRadius: '14px',
  width: '20px',
  height: '20px',
  color: theme.palette.text.txt_default,
  fontFamily: theme.fonts.robotoRegular,
  fontSize: theme.fontSize.small,
  fontStyle: 'normal',
  fontWeight: '400',
  textAlign: 'center',
  lineHeight: '16px',
}));

export const StyledLogsWrapper = styled(Box)(({ theme }) => ({
  color: theme.palette.text.title_txt,
  fontFamily: theme.fonts.robotoRegular,
  fontSize: theme.fontSize.main,
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px',
}));
