import { useCallback } from 'react';
import { useRouter } from '@tanstack/react-router';
import { useSelector } from 'react-redux';
import { selectActiveUser } from 'store/userSlice';
import { APP_CONSTANTS } from 'constants/app';

export default function usePendoTrackEvents() {
  const currentUser = useSelector(selectActiveUser);
  const accountId = localStorage.getItem(APP_CONSTANTS.ACCOUNT_ID_LOCAL_KEY);
  const router = useRouter();
  const srId = router.latestLocation.search.id;
  const pendoTrackEvents = useCallback(
    (name, eventData) => {
      window.pendo?.track(name, {
        srId,
        currentUser,
        accountId,
        ...eventData,
      });
    },
    [accountId, currentUser, srId],
  );

  return pendoTrackEvents;
}
