import { memo, useCallback, useMemo } from 'react';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { useDroppable } from '@dnd-kit/core';
import List from '@mui/material/List';
import TemplateFieldsGrid from 'features/srPanel/templateFieldsGrid';
import { SectionFieldsAreaWrapper } from './StyledTemplateSection';

function SectionFieldsArea(props) {
  const { id, sectionRows, handleUpdateSectionByValues, handleChangeFieldValue } = props;

  const fields = useMemo(() =>
    (props.fields || []).filter((field) => typeof field.fieldName === 'string'),
    [props.fields],
  );

  const { setNodeRef } = useDroppable({ id: `section-${id}` });
  const updateField = useCallback(
    (val) => {
      handleChangeFieldValue(val, id);
    },
    [id, handleChangeFieldValue],
  );
  return (
    <SortableContext
      id={`section-${id}`}
      items={fields?.map((field) => field.fieldName)}
      strategy={rectSortingStrategy}
    >
      <SectionFieldsAreaWrapper>
        <List className="section-fields-wrapper" ref={setNodeRef}>
          <TemplateFieldsGrid
            isTemplatePage
            fields={fields}
            sectionRows={sectionRows}
            onFieldChange={updateField}
            handleUpdateSectionByValues={handleUpdateSectionByValues}
          />
        </List>
      </SectionFieldsAreaWrapper>
    </SortableContext>
  );
}

export default memo(SectionFieldsArea);
