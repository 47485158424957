import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter, Link } from '@tanstack/react-router';
import { datadogRum } from '@datadog/browser-rum';
import PropTypes from 'prop-types';
import { useApplicationData } from 'remote-state/applicationHooks';
import { setShouldDisplayLockoutMessage, toggleIsUserLoading } from 'store/userSlice';
import eyePassword from 'images/icons/eye-password.svg';
import eyePasswordReveal from 'images/icons/eye-reveal-password.svg';
import sysaidLogoGreen from 'images/photos/sysaid-logo-green.png';
import { useUserInfo } from 'remote-state/userServiceHooks';
import { postValidateUserRequest } from 'services/userService';
import { clearStorage } from 'features/Logout/utils';
import { APP_CONSTANTS, ROUTES_PATHS } from 'constants/index';
import { getLoginPathInfo } from 'common/utils/utils';
import { ReactComponent as ErrorSign } from 'images/icons/ErrorSign.svg';
import { StyledITSMLink } from 'features/LoginSession/style';
import { QUERIES_KEYS } from 'constant';
import usePerformanceMeasure from 'common/utils/hooks/usePerformanceMeasure';
import { PERFORMANCE_MEASURE } from 'constants/performanceMeasure';
import { useTargetChannel } from 'remote-state/useTargetChannelHook';
import StatusMessage from '../../../../common/components/statusMessage';
import useTexts from '../../useTexts';
import LeftSideUpperLogo from '../../leftSideUpperLogo';
import RightPanel from '../../rightPanel';
import UserForm from '../../userForm';
import { trimFormValues, capitalizeFirstLetter } from '../../utils';
import { StyledLoginPage } from './style';
import { CONSTANTS } from '../../constants';

export const LoginPage = ({ customization, isDomainField, isQAMode }) => {
  const {
    passwordText,
    textText,
    signInText,
    wrongDomainText,
    wrongPasswordText,
    wrongUsernameText,
    loginLockoutMessage,
    itsmLink,
    sysaid,
    localUserTexts,
  } = useTexts();
  const dispatch = useDispatch();
  const router = useRouter();
  const [formErrors, setFormErrors] = useState({
    username: '',
    password: '',
    ...(isDomainField && { domain: '' }), // assign new key-value(key="domain", value="") only if form has domain field
  });
  const [isPasswordRevealed, setIsPasswordRevealed] = useState(false);
  const [inputType, setInputType] = useState('password');
  const [eyePasswordSrc, setEyePasswordSrc] = useState(eyePassword);
  const [loading, setLoading] = useState(false);
  const shouldDisplayLockoutMessage = useSelector((state) => state.user.shouldDisplayLockoutMessage);
  const { performanceMeasureEventReport } = usePerformanceMeasure();

  const onClickForgotPassword = () => {
    router.navigate({ to: ROUTES_PATHS.FORGOT_PASSWORD_URL, state: { customization } });
  };

  const targetChannel = useTargetChannel();

  const onClickPasswordEyeIcon = () => {
    setIsPasswordRevealed(!isPasswordRevealed);
    setInputType(!isPasswordRevealed ? textText : passwordText.toLowerCase());
    setEyePasswordSrc(!isPasswordRevealed ? eyePasswordReveal : eyePassword);
  };

  const buildUserNameWithDomain = (username, domain) => {
    if (domain && username.indexOf(`${domain}\\`) !== -1) {
      return username;
    }
    if (username.indexOf('.\\') !== -1) {
      return username;
    }
    if (domain && domain === localUserTexts) {
      return `.\\${username}`;
    }

    if (domain && domain !== localUserTexts) {
      return `${domain}\\${username}`;
    }
    return username;
  };

  function getEmailAddress(data) {
    const userInfo = data?.user?.info;
    if (userInfo && Array.isArray(userInfo)) {
      const emailInfo = userInfo.find(item => item.key === 'email_address');
      return emailInfo?.value;
    }
    return undefined;
  }

  function setDataDogUserContext(data) {
    const emailAddress = getEmailAddress(data)
    if (emailAddress) {
      datadogRum?.setUser({
        email: emailAddress,
      });
      console.log('Setting user context for RUM');
    } else {
      console.log('Email address is empty or undefined');
    }
  }
  const validateUser = async (formValues) => {
    const { username, password, domain } = formValues;
    const loginStartTime = performance.now();

    try {
      const fullUserName = buildUserNameWithDomain(username, domain);
      const res = await postValidateUserRequest(fullUserName, password);
      if (res?.status === 200)                                                                                                  {
        setDataDogUserContext(res.data);
        const loginEndTime = performance.now();
        const duration = loginEndTime - loginStartTime;
        performanceMeasureEventReport(PERFORMANCE_MEASURE.EVENTS.LOGIN, {
          tenantId: res.data?.account,
          duration,
        });
        if (res.data) {
          localStorage.setItem(APP_CONSTANTS.ACCOUNT_ID_LOCAL_KEY, res.data?.account);
          localStorage.setItem(APP_CONSTANTS.IS_QA_MODE, isQAMode);
          if (targetChannel) {
            localStorage.setItem(APP_CONSTANTS.ACCOUNT_TARGET, targetChannel);
          }
        }
        const { previousPath } = getLoginPathInfo();
        dispatch(toggleIsUserLoading(false));
        window.location.href = previousPath;
      } else if (res?.status === 401) {
        dispatch(setShouldDisplayLockoutMessage(res?.data === 'Too many bad login attempts'));
        setTimeout(() => {
          dispatch(setShouldDisplayLockoutMessage(false));
        }, CONSTANTS.TIMEOUT_TO_DISPLAY_ERROR_MESSAGE);
        const formFieldsErrorsCopy = { ...formErrors };
        Object.keys(formFieldsErrorsCopy).forEach((key) => {
          const fullKey = 'wrong'.concat(capitalizeFirstLetter(key));
          switch (fullKey) {
            case 'wrongUsername':
              formFieldsErrorsCopy[key] = wrongUsernameText;
              break;
            case 'wrongDomain':
              formFieldsErrorsCopy[key] = wrongDomainText;
              break;
            case 'wrongPassword':
              formFieldsErrorsCopy[key] = wrongPasswordText;
              break;
            default:
              throw Error(`Key ${fullKey} is not supported`);
          }
        });
        setFormErrors(formFieldsErrorsCopy);
      }
    } catch (err) {
      console.log('Error while trying to validate user: ', err.message);
    }
  };

  const onSubmitForm = async (formValues) => {
    setLoading(true);
    clearStorage();
    localStorage.setItem(APP_CONSTANTS.IS_MENU_EXPANDED, 'true');
    if (Object.prototype.hasOwnProperty.call(formValues, 'isDomainField')) {
      delete formValues.isDomainField;
    }
    trimFormValues(formValues);
    await validateUser(formValues);
    setLoading(false);
  };

  const resetFormErrors = (errors) => {
    const newFormErrors = { ...errors };
    for (const fieldName in newFormErrors) {
      newFormErrors[fieldName] = '';
    }
    setFormErrors(newFormErrors);
  };

  const externalLogin = document.cookie.split(';').filter((cookie) => cookie.includes('EXTERNAL_LOGIN'));
  useUserInfo(QUERIES_KEYS.CURRENT_USER, externalLogin.length);
  useApplicationData(QUERIES_KEYS.APPLICATION_DATA, externalLogin.length);

  return (
    <StyledLoginPage isDomainField={isDomainField}>
      <div id="login-wrapper">
        <div id="left-side-container">
          {shouldDisplayLockoutMessage && <StatusMessage AlertIcon={ErrorSign} message={loginLockoutMessage} />}
          <LeftSideUpperLogo />
          <div id="left-side-wrapper">
            <div className="left-side-logo">
              <img alt="sysaid-logo" src={sysaidLogoGreen} />
            </div>
            <div id="left-side-header">{signInText}</div>
            <UserForm
              isDomainField={isDomainField}
              onSubmitForm={onSubmitForm}
              onClickForgotPassword={onClickForgotPassword}
              resetFormErrors={resetFormErrors}
              formErrors={formErrors}
              inputType={inputType}
              eyePasswordSrc={eyePasswordSrc}
              onClickPasswordEyeIcon={onClickPasswordEyeIcon}
              shouldDisplayCaptcha={!isQAMode}
              isloginDisable={shouldDisplayLockoutMessage}
              isLoading={loading}
            />
          </div>
          <StyledITSMLink>
            {itsmLink}{' '}
            <Link to={CONSTANTS.ITSM_LINK} target="_blank" rel="noreferrer noopener">
              {sysaid}
            </Link>
          </StyledITSMLink>
        </div>
        <RightPanel />
      </div>
    </StyledLoginPage>
  );
};

LoginPage.propTypes = {
  customization: PropTypes.object,
  isDomainField: PropTypes.bool,
};
