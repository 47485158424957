import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useRouter } from '@tanstack/react-router';
import LoadingOverlayMessage from 'features/queue/grid/loadingOverlayMessage';
import usePreviousValue from 'common/utils/hooks/usePreviousValue';
import { StyledIframeContainer, StyledLoader, StyledLoaderWrapper } from '../styles';
import { ROUTES_PATHS } from '../../../constants';
import { installAddon } from '../../../services/settingsService';
import { SUB_MENU_ITEMS } from '../../../constants/common';

export function IframeWrapper({ url }) {
  const router = useRouter();
  const [iframeLoaded, setIframeLoaded] = useState(true);
  const urlRef = useRef(url);
  const iframeRef = useRef();
  const prevURL = usePreviousValue(url);

  if (prevURL && prevURL !== url) {
    iframeRef.current.contentWindow.location.replace(url);
  }

  const pageHandler = useCallback(
    (event) => {
      const isEmptyPage = event.data.isEmptyPage;
      const url = event.data.url;
      // Check if the message contains the isEmptyPage flag
      if (event.data && isEmptyPage) {
        // If isEmptyPage is true, redirect to the previous page from the history
        if (isEmptyPage) {
          router.navigate({ to: '/spaces/settings' });
          return;
        }
      }
      if (url) {
        let state = router?.state?.resolvedLocation?.search?.settingIds;
        state = { ...state, subMenuItemId: SUB_MENU_ITEMS.MY_APPS };
        const urlToResource = '/Settings.jsp?selectedItem=Integrations.jsp&openedInIframe=1';

        installAddon(url).then((res) => {
          if (res.status === 200) {
            router.navigate({
              to: `${ROUTES_PATHS.EXTERNAl_PAGES_URL}?url=${encodeURIComponent(
                urlToResource,
              )}&settingIds=${encodeURIComponent(JSON.stringify(state))}&showBack=1`,
            });
          }
        });
      }
    },
    [router],
  );

  useEffect(() => {
    // Add an event listener to listen for messages from the iframe
    window.addEventListener('message', pageHandler);
    return () => {
      window.removeEventListener('message', pageHandler);
    };
  }, [pageHandler]);

  const handleIframeLoad = () => setIframeLoaded(true);

  return (
    <StyledIframeContainer>
      <StyledLoaderWrapper className="loader" isLoaded={iframeLoaded}>
        <StyledLoader>
          <LoadingOverlayMessage className="exteranl-resource-loading" />
        </StyledLoader>
      </StyledLoaderWrapper>

      <iframe
        ref={iframeRef}
        src={urlRef.current}
        onLoad={handleIframeLoad}
        style={{
          transition: 'opacity 0.4s ease',
          width: '100%',
          height: '100%',
          position: 'relative',
          zIndex: iframeLoaded ? 100 : -1,
        }}
        data-testid="external-resource-iframe"
        title="external-resource-iframe"
      />
    </StyledIframeContainer>
  );
}

export default memo(IframeWrapper);
