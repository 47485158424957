import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    incident: texts?.['spaces.quickFilters.incident'],
    allText: texts?.['spaces.quickFilters.all'],
  };

  return componentTexts;
}
