import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';

export const StyledAuditLog = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isLoadMoreButtonDisplayed',
})(({ theme, isLoadMoreButtonDisplayed }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  fontFamily: theme.fonts.robotoRegular,
  color: theme.palette.text.secondary_txt,
  fontSize: '14px',
  padding: '0',
  'div[class^="infinite-scroll-component"]': {
    overflow: 'visible !important',
  },
  '.scrollable-div': {
    height: '100%',
    '.infinite-scroll-component': {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    },
  },
  '.load-more-audit-logs-button': {
    display: 'flex',
    width: '99px',
    height: '28px',
    padding: '4px 16px',
    border: 'none',
    borderRadius: '60px',
    marginTop: '17px',
    marginBottom: '13px',
    fontSize: '14px',
    color: theme.palette.text.secondary_txt,
    fontFamily: theme.fonts.robotoRegular,
    cursor: 'pointer',
  },
  '.editor-log-type': {},
  '.break-line': {
    gridTemplateRows: '37px',
    gap: 0,
  },
  '.audit-log-action-line': {
    display: 'flex',
    flexDirection: 'row',
    position: 'sticky',
    zIndex: '2',
    bottom: 0,
    padding: '20px 0',
    backgroundColor: 'white',
    gap: '12px',
    '&.MESSAGE': {
      padding: '13px 0 12px',
    },

    '& .action-line-shadow': {
      position: 'absolute',
      height: '6px',
      width: '100%',
      borderRadius: '100%',
      backgroundColor: '#E0E0E0',
      boxShadow: '0px -3px 4px 0px rgba(177, 177, 177, 0.25)',
      zIndex: '1',
      top: '0',
      opacity: '0',
      transition: 'opacity 100ms ease-in-out',

      '&.active': {
        opacity: '1',
      },

      ':after': {
        display: 'block',
        content: '""',
        height: '6px',
        width: '100%',
        top: '0',
        backgroundColor: '#FFF',
      },
    },
  },
  '.first-row:before': {
    height: '0',
  },
  '.first-row:after': {
    height: 'calc(100% - 32px)',
    bottom: '-4px',
  },
  '.last-row:after': {
    height: '0',
  },
  '.first-row': {},
  '.last-row': {
    marginBottom: 0,
  },
  '.capitalize': {
    textTransform: 'capitalize',
  },
  [theme.breakpoints.down(1024)]: {
    marginTop: isLoadMoreButtonDisplayed ? '27px' : '0px',
  },
}));

export const StyledAuditLogRow = styled('div', {
  shouldForwardProp: (prop) => prop !== 'fullWidth' && prop !== 'isEditMode',
})(({ theme, isEditMode }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: '0px 24px',
  padding: '13px 0px 0px',
  flexGrow: '1',
  '&:before': {
    content: "''",
    position: 'absolute',
    left: '15px',
    width: '1px',
    top: '-4px',
    height: '17px',
    backgroundColor: theme.palette.generic.sysaid_light,
  },
  '&:after': {
    content: "''",
    position: 'absolute',
    left: '15px',
    width: '1px',
    bottom: '-4px',
    height: 'calc(100% - 40px)',
    backgroundColor: theme.palette.generic.sysaid_light,
  },
  '&:has(.solution-resolution-created):before': isEditMode && {
    height: '31px',
  },
  '&:has(.solution-resolution-created):after': isEditMode && {
    height: 'calc(100% - 54px)',
  },
}));

export const LogValue = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isEditorAction' && prop !== 'isShiftedAction',
})(({ isShiftedAction, isEditorAction }) => ({
  width: isEditorAction ? '100%' : 'auto',
  display: 'flex',
  columnGap: '16px',
  flexWrap: 'wrap',
  alignItems: isShiftedAction ? 'flex-start' : 'center',
  flexDirection: isShiftedAction ? 'column' : 'row',
}));

export const AuditLogInner = styled('div', {
  shouldForwardProp: (prop) => prop !== 'fullWidth' && prop !== 'isEditMode' && prop !== 'isEditorAction',
})(({ isEditorAction, isEditMode }) => ({
  display: 'flex',
  columnGap: isEditorAction && !isEditMode ? '4px' : '12px',
  flexWrap: 'nowrap',
  width: isEditMode ? '100%' : 'calc(100% - 24px - 180px)', // 100% - gap - metadata
  paddingBottom: '13px',

  '& .log-title:has(.solution-resolution-created)': isEditMode && {
    marginTop: '14px',
  },
}));

export const StyledLoading = styled(Box)(({ theme }) => ({
  padding: '24px 0px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '6px',
  div: {
    color: theme.palette.text.secondary_txt,
    fontSize: '14px',
  },
}));

export const StyledPriorityReason = styled('div')(({ theme }) => ({
  color: theme?.palette?.text?.title_txt,
  fontSize: theme?.fontSize?.main,
  paddingTop: '6px',
  flexBasis: '100%',

  'span:first-child': {
    marginRight: '5px',
    fontFamily: theme?.fonts?.robotoMedium,
  },
}));
