import { axiosInstance } from './axiosConfig';

export function getMessagesInitialData(srId) {
  return axiosInstance.get(`messages/initial_data/${srId}`).then((response) => response.data);
}

export const sendSrMessage = (newMessage) =>
  axiosInstance.post('messages/sendmessage', newMessage).then((response) => response.data);

export function getEnableExternalEmailAddress() {
  return axiosInstance.get('messages/getEnableExternalEmailAddress').then((response) => response.data);
}
