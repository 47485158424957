const customStyles = (item, theme, isCategory) => {
  if (Object.prototype.hasOwnProperty.call(item, 'valueClass')) return undefined;
  if (isCategory)
    return {
      backgroundColor: theme.palette.blueLabels.bt_cyan_reg,
      color: theme.palette.background.autocompleteSecondary,
      fontFamily: 'Roboto-Regular',
      borderRadius: '16px',
      padding: '4px 8px',
      fontSize: '12px',
    };

  return {
    backgroundColor: 'rgba(0,0,0,0)',
    fontFamily: 'Roboto-Regular',
    padding: '0',
    fontSize: '14px',
    color: theme.palette.text.title_txt,
    lineHeight: '20px',
    width: '224px',
  };
};

export const convertValue = (item, theme, isCategory) => ({
  ...item,
  value: item.value || item.userName,
  valueKey: item.id || item.id === 0 ? item.id : item.valueKey,
  valueCaption: item.valueCaption || item.calculatedUserName || item.value || '',
  valueKeyForFilter: item.idForFilter || (item.id || item.id === 0 ? item.id : item.valueKey),
  customStyle: theme ? customStyles(item, theme, isCategory) : undefined,
});

export const convertValues = (values, theme, isCategory) => {
  if (!Array.isArray(values) || !values) return [];
  return values.map((item) => convertValue(item, theme, isCategory));
};
