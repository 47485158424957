import Checkbox from 'common/components/checkbox';

import useTexts from 'features/resolutionPanel/useTexts';
import { StyledAttachmentLinkCheckboxWrapper, StyledAttachmentLinkCheckboxLabel } from './Styled';

export const AddAttachmentLinkCheckbox = (props) => {
  const { attachmentLinkText } = useTexts();

  const { editorData, handleEditorChange, editorClassName = 'editor-element', dataTestId = '' } = props;

  const isChecked = editorData?.isAttachmentLinksChecked;

  const handleChange = () => {
    handleEditorChange('isAttachmentLinkChecked', { isAttachmentLinkChecked: !isChecked });
  };

  return (
    <StyledAttachmentLinkCheckboxWrapper className={editorClassName} data-testid={dataTestId}>
      <Checkbox checked={isChecked} onChange={handleChange} />

      <StyledAttachmentLinkCheckboxLabel>{attachmentLinkText}</StyledAttachmentLinkCheckboxLabel>
    </StyledAttachmentLinkCheckboxWrapper>
  );
};
