import { useState } from 'react';
import { TextField } from '@mui/material';
import CONSTANTS from './constants';
import { TextFieldWrapper } from './style';

export const PromptTextField = ({ placeholder, onChange = () => {} }) => {
  const [currentValue, setCurrentValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [textAdded, setTextAdded] = useState(false);

  const handleChange = (value) => {
    const truncatedText = value.substring(0, CONSTANTS.MAXIMUM_NUMBER_OF_CHARACTERS);
    setCurrentValue(truncatedText);
    onChange(truncatedText);
  };

  const handleFocus = () => {
    setIsFocused(true);
    setTextAdded(false);
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (currentValue.length > 0) {
      setTextAdded(true);
    }
  };

  return (
    <TextFieldWrapper isFocused={isFocused} textAdded={textAdded}>
      <TextField
        className="text-field"
        margin="dense"
        id="name"
        type="text"
        fullWidth
        variant="standard"
        placeholder={!isFocused && placeholder}
        multiline
        rows={3}
        value={currentValue}
        defaultValue={currentValue}
        onChange={(event) => handleChange(event.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        helperText={isFocused && `${currentValue.length}/${CONSTANTS.MAXIMUM_NUMBER_OF_CHARACTERS}`}
        InputProps={{ disableUnderline: true }}
      />
    </TextFieldWrapper>
  );
};
