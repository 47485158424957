import { endOfDay, startOfDay } from 'date-fns';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { COLUMN_FILTER_TYPES } from 'features/queue/grid/constants';
import useTexts from './useTexts';
import DatePicker from '../datePicker';
import { selectFilterModel } from '../../../features/queue/slice';

export default function DateRangePicker(props) {
  const { handleRangeDateFilter, field } = props;
  const { fromText, toText } = useTexts();

  const filterModel = useSelector(selectFilterModel);

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [isStartTimeError, setIsStartTimeError] = useState(false);
  const [isEndTimeError, setIsEndTimeError] = useState(false);
  const selectedFilterModel = filterModel?.find((filter) => filter.field === field);
  const prevStartTime = selectedFilterModel && selectedFilterModel?.values.length > 0 && selectedFilterModel?.values[0];
  const prevEndTime = selectedFilterModel && selectedFilterModel?.values.length > 0 && selectedFilterModel?.values[1];

  useEffect(() => {
    if (selectedFilterModel?.type === COLUMN_FILTER_TYPES.inRange) {
      setStartTime(prevStartTime);
      setEndTime(prevEndTime);
    } else {
      setStartTime(null);
      setEndTime(null);
    }
  }, [selectedFilterModel?.type, prevStartTime, prevEndTime]);

  const checkIsDateRangeValid = (start, end) => {
    // If starTime is greater than endTime set the errors to true for both of them
    if (start && end && end < start) {
      setIsStartTimeError(true);
      setIsEndTimeError(true);
      // If no startTime was selected at the first time set the error to true
    } else if (!start && isStartTimeError) {
      setIsStartTimeError(true);
      setIsEndTimeError(false);
      // If no endTime was selected at the first time set the error to true
    } else if (!end && isEndTimeError) {
      setIsEndTimeError(true);
      setIsStartTimeError(false);
    } else {
      // If endTime is greater than startTime and they are not empty set the errors to false
      setIsStartTimeError(false);
      setIsEndTimeError(false);
    }
  };

  const startTimeHandler = (newSelectedDateMill) => {
    if (newSelectedDateMill !== startTime) {
      setStartTime(newSelectedDateMill);
      checkIsDateRangeValid(newSelectedDateMill, endTime);
      const endOfDayEndTime = endTime && Date.parse(endOfDay(endTime));
      const startOfDayStartTime = Date.parse(startOfDay(newSelectedDateMill));
      if (handleRangeDateFilter && newSelectedDateMill && endTime && newSelectedDateMill <= endTime) {
        handleRangeDateFilter([startOfDayStartTime, endOfDayEndTime]);
      }
      if (handleRangeDateFilter && !newSelectedDateMill && !endTime && prevStartTime) {
        handleRangeDateFilter([null, null]);
      }
    }
  };

  const endTimeHandler = (newSelectedDateMill) => {
    if (newSelectedDateMill !== endTime) {
      setEndTime(newSelectedDateMill);
      checkIsDateRangeValid(startTime, newSelectedDateMill);
      const startOfDayStartTime = startTime && Date.parse(startOfDay(startTime));
      const endOfDayEndTime = Date.parse(endOfDay(newSelectedDateMill));
      if (handleRangeDateFilter && newSelectedDateMill && startTime && newSelectedDateMill >= startTime) {
        handleRangeDateFilter([startOfDayStartTime, endOfDayEndTime]);
      }
      if (handleRangeDateFilter && !newSelectedDateMill && !startTime && prevEndTime) {
        handleRangeDateFilter([null, null]);
      }
    }
  };

  const setDefaultEndTime = () => new Date().getTime();

  return (
    <div style={{ marginTop: '12px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <DatePicker
        disablePortal
        date={startTime}
        isCustomDateFilter
        placeholder={fromText}
        isNewField={!startTime}
        error={isStartTimeError}
        defaultDate={setDefaultEndTime()}
        onChange={startTimeHandler}
      />
      <div style={{ marginTop: '12px' }}> </div>
      <DatePicker
        isNewField
        disablePortal
        date={endTime}
        isCustomDateFilter
        placeholder={toText}
        error={isEndTimeError}
        defaultDate={setDefaultEndTime()}
        onChange={endTimeHandler}
      />
    </div>
  );
}
