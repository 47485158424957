import { axiosInstance } from './axiosConfig';

export const createSrActivity = (activity) => axiosInstance
  .post('activities', activity)
  .then((response) => response.data);

export const deleteSRActivity = ({ srId, activityId, auditLogId }) =>
  axiosInstance
    .delete(`activities/${srId}/${activityId}?auditLogId=${auditLogId}`)
    .then((response) => response.data);
