const MAX_VIEW_NAME_LENGTH = 250;
const VIEW_NAME_KEY = 'viewName';
const VISIBILITY_KEY = 'visibility';
const VISIBILITY_FOR_GROUPS_KEY = 'visibleForGroupIds';
const PUBLIC_VISIBILITY_VALUE = 'PUBLIC';
const PRIVATE_VISIBILITY_VALUE = 'PRIVATE';

export {
  MAX_VIEW_NAME_LENGTH,
  VIEW_NAME_KEY,
  VISIBILITY_KEY,
  VISIBILITY_FOR_GROUPS_KEY,
  PUBLIC_VISIBILITY_VALUE,
  PRIVATE_VISIBILITY_VALUE,
};
