import { ResourceType, SubResourceType } from '../constants';
/**
 *
 * @export
 * @interface AttachmentMetadata
 */
export class AttachmentMetadata {
  /**
   *
   * @type {number}
   * @memberof AttachmentMetadata
   */
  id = 0;

  /**
   *
   * @type {boolean}
   * @memberof AttachmentMetadata
   */
  visibility = false;

  /**
   *
   * @type {string}
   * @memberof AttachmentMetadata
   */
  originalName = '';

  /**
   *
   * @type {string}
   * @memberof AttachmentMetadata
   */
  extension = '';

  /**
   *
   * @type {string}
   * @memberof AttachmentMetadata
   */
  source = '';

  /**
   *
   * @type {Date}
   * @memberof AttachmentMetadata
   */
  createdDate = null;

  /**
   *
   * @type {string}
   * @memberof AttachmentMetadata
   */
  userName;

  /**
   *
   * @type {string}
   * @memberof AttachmentMetadata
   */
  chatSessionId = '';

  /**
   *
   * @type {number}
   * @memberof AttachmentMetadata
   */
  resourceId = 0;

  /**
   *
   * @type {ResourceType}
   * @memberof AttachmentMetadata
   */
  resourceType = ResourceType.SR;

  /**
   *
   * @type {number}
   * @memberof AttachmentMetadata
   */
  subResourceId = 0;

  /**
   *
   * @type {SubResourceType}
   * @memberof AttachmentMetadata
   */
  subResourceType = SubResourceType.SR;

  constructor(param = {}) {
    this.id = param.id;
    this.visibility = param.visibility;
    this.originalName = param.originalName;
    this.extension = param.extension;
    this.source = param.source;
    this.createdDate = param.createdDate;
    this.userName = param.userName;
    this.chatSessionId = param.chatSessionId;
    this.resourceId = param.resourceId;
    this.subResourceType = param.subResourceType;
    this.resourceType = param.resourceType || ResourceType.SR;
    this.subResourceId = param.subResourceId || 0;
  }
}
