import { MenuList } from '@mui/material';
import navIcons from 'features/navBar/navIcons';

import useTexts from 'features/navBar/useTexts';
import NavSubListLink from '../navSubListLink';
import NavSubListMenuTitle from './navSubListMenuTitle';

export default function NavSubListMenu({ menu, closeMenu }) {
  const { getNavItemTitle } = useTexts();

  const menuTitle = getNavItemTitle(menu.rbToken);
  return (
    <MenuList className="sublist">
      <NavSubListMenuTitle title={menuTitle} />
      {menu.subMenuList.map(({ id, rbToken, url, isOpenInNewTab }) => {
        const title = getNavItemTitle(rbToken);
        const icon = navIcons[id];

        return (
          <NavSubListLink
            key={id}
            icon={icon}
            title={title}
            url={url}
            isOpenInNewTab={isOpenInNewTab}
            closeMenu={closeMenu}
          />
        );
      })}
    </MenuList>
  );
}
