import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  left: 0,
  bottom: '-1px',
  position: 'sticky',
  width: '100%',
  color: 'black',
  textAlign: 'center',
  // height: '68px',
  marginTop: 'auto',
  zIndex: 1,
  padding: '16px 48px',
  gap: '24px',
  backgroundColor: theme.palette.background.action,

  '.footer-item': {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    outline: 'none',
    border: 'none',
    background: 'transparent',
    borderRadius: '4px',
    padding: '4px 6px',
    height: '36px',
  },
  '.footer-item:hover': {
    backgroundColor: theme.palette.background.bg_hover_main,
  },
  '.item-label': {
    fontSize: '14px',
    marginLeft: '5px',
    color: theme.palette.text.resolutionPanelFooter,
  },
}));
