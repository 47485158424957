import { useQueries } from '@tanstack/react-query';
import useArrayMemo from './useArrayMemo';

//A solution for the useQueries new Array instance on each render issue
export default function useMemoizedQueries(queriesInput) {
  const queriesToMemo = useQueries({
    queries: queriesInput,
  });
  return useArrayMemo(queriesToMemo.map((query) => query));
}
