import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledActionItemFileInputLabel = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'inputBtnComponent',
})(({ isActionItem, isTicketLocked, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0,
  border: `1px solid ${theme.palette.grey.divider_grey_v1}`,
  borderRadius: '4px',
  height: '70px',
  width: '100%',
  transition: 'background-color 0.4s ease',
  textAlign: 'center',
  pointerEvents: isTicketLocked && 'none',
  opacity: isTicketLocked ? 0.7 : 1,
  backgroundColor: isTicketLocked && theme.palette.background.bg_hover_main,
  '&:hover': {
    backgroundColor: isActionItem && theme.palette.background.bg_hover_main,
  },
  '.MuiInputLabel-root': {
    cursor: 'pointer',
    fontSize: theme.fontSize.main,
    fontWeight: 400,
    lineHeight: 0,
  },
  '.text-wrapper': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: 0,
    '& u': { marginRight: '4px' },
  },
  svg: {
    width: '16px',
    height: '16px',
    marginRight: '4px',
  },
}));

export const StyledFileInputLabel = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'inputBtnComponent',
})(({ isTicketLocked, theme }) => ({
  '.MuiInputLabel-root': {
    display: 'flex',
    padding: '52px 66px',
    border: '1px dashed #BEC4CE',
    borderRadius: '10px',
    cursor: 'pointer',
    pointerEvents: isTicketLocked && 'none',
    opacity: isTicketLocked ? 0.7 : 1,
    backgroundColor: isTicketLocked && theme.palette.background.bg_hover_main,
  },
}));
