import { useRef } from 'react';
import { useSelector } from 'react-redux';

import useHover from 'common/utils/hooks/useHover';

import { selectActionLine } from 'features/resolutionPanel/middlePanel/auditLog/store/slice';
import ActionLine from 'features/resolutionPanel/middlePanel/auditLog/actionLine';
import useTexts from 'features/resolutionPanel/useTexts';

import { useDisplayTextTruncation } from '../fieldChange/hooks/useDisplayTextTruncation';
import { ReadOnlyEditor } from './readOnlyEditor';
import { StyledEditable, StyledResolutionWrapper } from './style';
import { SolutionResolutionFooter } from './customFooters/solutionResolutionFooter';
import { SolutionResolutionFieldView } from './solutionResolutionFieldView';
import { KebabMenu } from './kebabMenu';
import { useEditorActions } from '../../hooks';
import TruncatedTextButton from './truncatedTextButton';

export const EditableSolutionResolution = (props) => {
  const {
    isEdited,
    auditLogId,
    toggleAuditLogsProperty,
    logInformation,
    solutionValue,
    resolutionValue,
    logType,
    isRichTextTruncated,
    editorType,
    isTicketLocked,
    hasEditPermissions,
  } = props;

  const solutionWrapRef = useRef(null);
  const resolutionWrapRef = useRef(null);
  const [hoveredRef, isHovered] = useHover(false);
  const { isNewEditor, activeIndex, isEditorDirty } = useSelector(selectActionLine);
  const { handleActionLineOperation, toggleRichTextEdited } = useEditorActions();
  const { showTruncationButton: showSolutionTruncationButton } = useDisplayTextTruncation({
    divRef: solutionWrapRef,
    logType,
  });
  const { showTruncationButton: showResolutionTruncationButton } = useDisplayTextTruncation({
    divRef: resolutionWrapRef,
    logType,
  });
  const texts = useTexts();

  const handleCancel = () => {
    toggleAuditLogsProperty({ auditLogIds: [auditLogId], property: 'isEdited' });
  };

  const handleClickEdit = ({ shouldResetData }) => {
    if (shouldResetData) {
      toggleRichTextEdited({ auditLogId });
    }
  };

  const isResolution = (resolution) => !!(resolution?.text || resolution?.attachments || resolution?.inlineImages);

  return (
    <div ref={hoveredRef}>
      {(isEditorDirty && activeIndex === auditLogId) || (isEdited && !isNewEditor && activeIndex === auditLogId) ? (
        <ActionLine handleCancel={handleCancel} auditLogId={auditLogId} editorType={editorType} />
      ) : (
        <StyledEditable data-testid="resolution-journey-editor" data-cy="resolution-journey-editor">
          <StyledResolutionWrapper className="soulutionWrapperCont">
            <SolutionResolutionFieldView
              captionText={texts?.resolutionStatus}
              valueText={logInformation?.statusCaption}
            />

            <SolutionResolutionFieldView
              captionText={texts?.closureInformation}
              valueText={logInformation?.closureInformationCaption}
            />

            {logInformation?.assignee && (
              <div data-testid="assignee-field">
                <SolutionResolutionFieldView captionText={texts?.assignee} valueText={logInformation?.assignee} />
              </div>
            )}

            <span className="caption-text solution">{texts?.solution} </span>

            <ReadOnlyEditor
              {...props}
              value={solutionValue}
              isRichTextTruncated={isRichTextTruncated}
              ref={solutionWrapRef}
              showKebabMenu={false}
            />

            {isResolution(logInformation?.resolution) && (
              <div className="resolution-field" data-testid="resolution-field">
                <span className="caption-text">{texts?.resolution} </span>

                <ReadOnlyEditor
                  {...props}
                  value={resolutionValue}
                  isRichTextTruncated={isRichTextTruncated}
                  ref={resolutionWrapRef}
                  isFirst={false}
                  showKebabMenu={false}
                />
              </div>
            )}
            <SolutionResolutionFooter shareWithReqUser={logInformation?.shareWithReqUser} />
            {(showSolutionTruncationButton || showResolutionTruncationButton) && (
              <TruncatedTextButton
                isRichTextTruncated={isRichTextTruncated}
                onClick={() => {
                  toggleAuditLogsProperty({ auditLogIds: [auditLogId], property: 'isRichTextTruncated' });
                }}
              />
            )}
          </StyledResolutionWrapper>

          {!isEditorDirty && hasEditPermissions && (
            <div data-testid="kebab-menu">
              <KebabMenu
                isTicketLocked={isTicketLocked}
                editorType={editorType}
                logType={logType}
                isHovered={isHovered}
                handleKebabMenuAction={(actionName) =>
                  handleActionLineOperation({
                    type: editorType,
                    actionName,
                    logInformation,
                    auditLogId,
                    handleClickEdit,
                  })
                }
              />
            </div>
          )}
        </StyledEditable>
      )}
    </div>
  );
};
