export const getCroppedImage = (imageURL, croppedAreaPixels) =>
  new Promise((resolve) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const image = new Image();

    image.src = imageURL;
    image.onload = () => {
      canvas.width = croppedAreaPixels.width;
      canvas.height = croppedAreaPixels.height;

      ctx.drawImage(
        image,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
        0,
        0,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
      );

      canvas.toBlob((blob) => {
        resolve(blob);
      });
    };
  });

export const extractFileNameFromURL = (url) => {
  const parts = url.split('/');
  const fileName = parts[parts.length - 1];
  return fileName;
};

export const convertBlobToFile = (blob, fileName) => {
  const file = new File([blob], fileName, {
    type: blob.type,
    lastModified: Date.now(),
  });

  Object.defineProperty(file, 'size', {
    value: blob.size,
    writable: false,
  });

  return file;
};
