import { useCallback, useEffect } from 'react';

export function useClickAway(ref, onClickAway, eventType = 'mousedown') {
  const handleClickOutside = useCallback(
    (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickAway();
      }
    },
    [onClickAway, ref],
  );
  useEffect(() => {
    document.addEventListener(eventType, handleClickOutside);

    return () => {
      document.removeEventListener(eventType, handleClickOutside);
    };
  }, [eventType, handleClickOutside]);
}

export default useClickAway;
