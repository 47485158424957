import EditIcon from 'images/icons/editIcon.svg';
import DeleteIcon from 'images/icons/deleteIcon.svg';

import { ShareSwitch, AddAttachmentLinkCheckbox } from '../actionLine/footerElements';
import { editorActionTypes } from './editorActionTypes';

const editorActionData = {
  [editorActionTypes.NOTE]: {
    placeholder: 'richTextEditorPlaceholder',
    editorTextKey: 'noteText',
    editorElementClassName: 'note-editor-element',
    cancelParams: {
      messageText: '',
    },
    footerElements: [{ id: 'ShareWithRequestUser', Component: ShareSwitch }],
    kebabMenuActions: [
      {
        name: 'edit',
        defaultCaption: 'Edit',
        translatedCaption: 'spaces.resolutionPanel.journey.notes.edit',
        icon: EditIcon,
      },
      {
        name: 'delete',
        defaultCaption: 'Delete',
        translatedCaption: 'spaces.header.actions.deleteTicket',
        icon: DeleteIcon,
      },
    ],
  },
  [editorActionTypes.MESSAGE]: {
    placeholder: '',
    editorTextKey: 'messageText',
    editorElementClassName: 'message-editor-element',
    footerElements: [{ id: 'AddAttachmentLink', Component: AddAttachmentLinkCheckbox }],
    maxEditorCharsForward: 10000,
  },
  [editorActionTypes.RICH_TEXT_FIELD]: {
    placeholder: '',
    editorElementClassName: 'rich-text-field-editor-element',
  },
  [editorActionTypes.RESOLUTION]: {
    resolutionPlaceholder: 'resolutionPlaceholder',
    solutionPlaceholder: 'solutionPlaceholder',
    resolutionTextKey: 'resolutionText',
    solutionTextKey: 'solutionText',
    editorElementClassName: 'resolution-editor-element',
    statusCaption: 'statusCaption',
    status: 'status',
    closureInformation: 'closureInformation',
    closureInformationCaption: 'closureInformationCaption',
    footerElements: [],
    kebabMenuActions: [
      {
        name: 'edit',
        defaultCaption: 'Edit',
        translatedCaption: 'spaces.resolutionPanel.journey.notes.edit',
        icon: EditIcon,
      },
    ],
  },
  [editorActionTypes.ACTIVITY]: {
    activitiesTextKey: 'activitiesText',
  },
};

export { editorActionTypes, editorActionData };
