import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

export const StyledActionWrapper = styled(Box)(({ theme }) => ({
    li: {
        '&:hover': {
            background: theme.palette.bt_alt_grey_light.main,
            height: 'auto',
            borderRadius: 0,
            padding: 0,
        },
    },
}));

export const StyledMenuItem = styled(MenuItem, {
    shouldForwardProp: (prop) => prop !== 'shouldhover',
  })(() => ({
      '&:hover': {
        padding: '0 !important',
        height: 'auto !important',
      },
  }));

export const StyledDivider = styled('hr')(({ theme }) => ({
    backgroundColor: theme?.palette?.grey?.divider_grey_v1,
    width: 'calc(100% - 20px)',
    height: '1px',
    padding: '0 !important',
    margin: '16px auto 8px 8px',
    borderRadius: '1px',
    border: 'none',
}));

export const StyledActionButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'shouldhover',
})(({ theme, shouldhover = true }) => ({
    padding: 0,
    textTransform: 'none',
    color: 'inherit',
    fontSize: theme.fontSize.main,
    '&:hover': {
        background: shouldhover ? null : 'none',
    },
    '&.Mui-disabled': {
        svg: {
            opacity: 0.25,
        },
    },
}));
