import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

export const StyledSubjectInput = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  gap: '5px',
  '.sr-id': {
    color: theme.palette.text.disabled,
    flexShrink: 0,
  },
  '&&& .MuiInputBase-root': {
    width: '100%',
  },
  '&& .MuiInput-input': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));
