const BASE_PATH = '/spaces';
export const FORGOT_PASSWORD_SUBDIRECTORY = 'forgot-password';
export const LOGIN_SUBDIRECTORY = 'login';
export const ROUTES_PATHS = {
  BASE_PATH,
  QUEUE: `${BASE_PATH}/`,
  LOGIN_URL: `${BASE_PATH}/${LOGIN_SUBDIRECTORY}`,
  LOGOUT_URL: `${BASE_PATH}/logout`,
  TEMPLATE_URL: `${BASE_PATH}/template`,
  TEMPLATE_LIST_URL: `${BASE_PATH}/templates-list`,
  SETTINGS_URL: `${BASE_PATH}/settings`,
  TICKET_URL: `${BASE_PATH}/ticket`,
  FORGOT_PASSWORD_URL: `${BASE_PATH}/${FORGOT_PASSWORD_SUBDIRECTORY}`,
  CHECKMAIL_URL: `${BASE_PATH}/check-mail`,
  EXTERNAl_PAGES_URL: `${BASE_PATH}/classic`,
  PAGE404_URL: `${BASE_PATH}/404`,
  _URL: `${BASE_PATH}/template`,
  SERVICE_PORTAL_URL: '/servicePortal',
  WORKFLOW_DESIGNER: '/workflowDesigner',
  KEEP_ALIVE: '/KeepAlive.jsp',
};
