import { useCallback, useEffect, useMemo, useState } from 'react';
import { Stack } from '@mui/material';

import { InlineFieldContainer } from 'common/components/controls/InlineField/InlineFieldContainer';
import { StyledDropDownTitle } from 'common/components/dropdownSelect/dropDownHeader/style';

import { FilterContainer, RangeInputWrapper } from '../style';
import { IdSelect } from './IdSelect';
import useTexts from './useTexts';

export const FilterByNumber = (props) => {
  const { filterTitle, handleSearch, selectedFilter } = props;
  const [searchValue, setSearchValue] = useState('');
  const { idFilterPlaceholder } = useTexts();
  const [filterType, setFilterType] = useState(selectedFilter?.type || 'equals');

  useEffect(() => {
    if (selectedFilter?.values?.[0]) {
      setSearchValue(selectedFilter.values[0]);
    } else {
      setSearchValue('');
    }
  }, [selectedFilter]);

  const handleSelectChange = (value) => {
    setFilterType(value);
    if (searchValue) {
      handleSearch({ target: { value: searchValue, filterType: value, type: value } });
    }
  };

  const handleSaveValue = useCallback(
    ({ value }) => {
      handleSearch({
        target: { value, type: filterType, filterType },
      });
    },
    [handleSearch, filterType],
  );

  const hoverEffect = useMemo(() => ({ propertyName: 'cursor', value: 'text', priority: 'important' }), []);

  return (
    <FilterContainer>
      <Stack>
        {filterTitle && (
          <div style={{ padding: '0 8px' }}>
            <StyledDropDownTitle>{filterTitle}</StyledDropDownTitle>
          </div>
        )}
        <IdSelect handleSelectChange={handleSelectChange} filterType={filterType} />
        <RangeInputWrapper className="input">
          <InlineFieldContainer
            fullwidth
            limit={10}
            type="number"
            fieldName="value"
            delayedHover={500}
            text={searchValue}
            hoverEffect={hoverEffect}
            placeholder={idFilterPlaceholder}
            handleSaveValue={handleSaveValue}
          />
        </RangeInputWrapper>
      </Stack>
    </FilterContainer>
  );
};
