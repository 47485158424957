import { memo } from 'react';
import { DropdownSelectProvider } from './context';
import { DropdownSelectGroup } from './DropdownSelectGroup';
import { MenuContainer } from './MenuContainer';

export const DropdownSelect = memo(
  ({
    id,
    anchor,
    disabled,
    noDivider,
    /*
    This is an array of all dropdown items.
  */
    options,
    handleClose,
    handleChange,
    handleClean,
    /*
    This is a string value which swithes background color and border radius for dropdown items
    more information you can find in this file:
    src\common\components\dropdownSelect\hooks\useStyleHelpers.js
  */
    stylingVariant,
    isMultiple,
    hasHierarchy,
    sortBy,
    sortType,
    title,
    searchPlaceholder,
    /*
    This is an array of selected items in dropdown
  */
    selection,
    keyString = 'valueKey',
    captionString = 'valueCaption',
    classString = 'valueClass',
    styleString = 'customStyle',
    isDateType,
    showHomeIcon = false,
    fieldName,
    isCategory,
    cleanQuickFilter,
    backendQueryConfig,
    autoInputFocus,
    onClickAway,
    isClickedAway,
    handleClearValue,
    additionalWrapperClassName = '',
    indexOffset = 0,
    dropdownHeightExtension = 0,
    MenuProps,
    isWideDropdown,
    isActionsHidden,
    valueClassesNames,
    isHeaderHidden,
    isGroupedHierarchy = false,
    isSelectAllHidden,
    columns = null,
    listLabel,
  }) => (
    <DropdownSelectProvider
      initialOptions={options}
      handleChange={handleChange}
      handleClean={handleClean}
      stylingVariant={stylingVariant}
      isMultiple={isMultiple}
      hasHierarchy={hasHierarchy}
      isGroupedHierarchy={isGroupedHierarchy}
      isWideDropdown={isWideDropdown}
      title={title}
      selection={selection}
      keyString={keyString}
      captionString={captionString}
      classString={classString}
      styleString={styleString}
      isDateType={isDateType}
      isCategory={isCategory}
      cleanQuickFilter={cleanQuickFilter}
      backendQueryConfig={backendQueryConfig}
      sortBy={sortBy}
      sortType={sortType}
      isListVisible={Boolean(anchor)}
      additionalWrapperClassName={additionalWrapperClassName}
      indexOffset={indexOffset}
      showHomeIcon={showHomeIcon}
      valueClassesNames={valueClassesNames}
      isLeftCheckbox
    >
      <MenuContainer
        anchor={anchor}
        handleClose={handleClose}
        withTitle={Boolean(title)}
        disabled={disabled}
        onClickAway={onClickAway}
        id={id}
        MenuProps={MenuProps}
        isWideDropdown={isWideDropdown}
        isHeaderHidden={isHeaderHidden}
      >
        <DropdownSelectGroup
          noDivider={noDivider}
          isGroupedHierarchy={isGroupedHierarchy}
          autoInputFocus={autoInputFocus}
          searchPlaceholder={searchPlaceholder}
          handleClearValue={handleClearValue}
          fieldName={fieldName}
          isClickedAway={isClickedAway}
          withTitle={Boolean(title)}
          id={id}
          dropdownHeightExtension={dropdownHeightExtension}
          isActionsHidden={isActionsHidden}
          isHeaderHidden={isHeaderHidden}
          isSelectAllHidden={isSelectAllHidden}
          columns={columns}
          listLabel={listLabel}
        />
      </MenuContainer>
    </DropdownSelectProvider>
  ),
);
