import { axiosInstance } from './axiosConfig';

export function allowSelfAssign() {
  return axiosInstance.get('isAssignedToMeEnable').then((response) => response.data);
}

export function shouldHideAccessToClassic() {
  return axiosInstance.get('account/shouldHideAccessToClassic').then((response) => response.data);
}

export function getCategoryDrivenEnabled() {
  return axiosInstance.get('account/isCategoryDrivenTemplatesEnabled');
}

export function setCategoryDrivenEnabled(isEnabled) {
  return axiosInstance.post(`account/setCategoryDrivenTemplates?enabled=${isEnabled}`);
}

export function getIsAiEnabled() {
  return axiosInstance.get('account/isAiEnabled').then((response) => response.data);
}

export function getStatusSettings() {
  return axiosInstance.get('status_settings').then((response) => response.data);
}
