import useTexts from 'features/resolutionPanel/useTexts';
import { StyledFooter } from './style';

export const SolutionResolutionFooter = (props) => {
    const { shareWithReqUser: shareWithRequestUser } = props;
    const { sharedText, internalText } = useTexts();

    const isSharedWithRequestUser = () => (shareWithRequestUser ? sharedText : internalText);

    return (
      <StyledFooter shareWithRequestUser={shareWithRequestUser}>
        <div className="log-type">
          <div className="log-type-indicator" />
          {isSharedWithRequestUser()}
        </div>
      </StyledFooter>
    );
};
