import {getKeepALive} from '../services/auth';
import {AUTH_CONSTANTS} from './auth';

const keepAlive = async ( intervalId) => {
    try {
        const response = await getKeepALive();

        // Handle SERVERID cookie if it exists
        const serverIdCookie = response.cookies ? response.cookies['SERVERID'] : null;
        if (serverIdCookie) {
            document.cookie = `SERVERID=${serverIdCookie}; path=/;`;
        }
    } catch (error) {
        console.error(error);
        clearInterval(intervalId);
    }
};


export default function startAppSession() {

    // Start the keep-alive interval
    const intervalId = setInterval(() => keepAlive( intervalId),
        AUTH_CONSTANTS.INTERVAL_TIME);
}

export { keepAlive, startAppSession };