export const WORFKLOWS_CONSTANTS = {
  ACTION_ITEM_COMPLETE_VALUE: 2,
  ACTION_ITEM_ENABLE_VALUE: 0,
  ACTION_ITEM_DISABLED_TYPES: {
    DEPENDENCY: 'dependency',
    INSUFFICIENT_PERMISSIONS: 'insufficientPermissions',
  },
  FIELDS: {
    STATUS: { ID: 5, NAME: 'status' },
    ASSIGNED_TO: { ID: 6, NAME: 'assignedTo' },
    ASSIGNED_GROUP: { ID: 55, NAME: 'assignedGroup' },
  },
  EMPTY_FIELD_VALUES: ['', null, undefined, 0],
  TEXT_FIELD_DIGITS_LIMIT: 10,
};
