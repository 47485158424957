import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    chooseDate: texts?.['spaces.datePicker.dueDate.chooseDate'],
  };

  return componentTexts;
}
