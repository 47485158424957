import { useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from 'common/components/tooltip';
import { ReactComponent as FilterIcon } from 'images/icons/gh_filter.svg';
import { ReactComponent as CloseChipIcon } from 'images/icons/chipDelete.svg';
import {
  StyledStack,
  StyledChip,
  StyledClearAll,
  StyledGridFilters,
  StyledChipContainer,
  StyleFilterIconContiner,
  StyledDropdownContainer,
} from '../../../features/queue/gridFilters/style';
import { selectActiveFilters, removeAllFilters, removeFilter } from '../../../features/resolutionPanel/middlePanel/auditLog/store/slice';

export const HeaderActiveFilters = () => {
  const dispatch = useDispatch();
  const chipContainerRef = useRef();
  const activeFilters = useSelector(selectActiveFilters);

  const handleDelete = useCallback(
    (chip) => () => {
      dispatch(removeFilter({ field: chip.field }));
    },
    [dispatch],
  );

  const handleClearAll = useCallback(() => {
    dispatch(removeAllFilters());
  }, [dispatch]);

  return (
    <StyledGridFilters>
      <StyledStack direction="row" spacing={1}>
        <StyleFilterIconContiner>
          <FilterIcon />
        </StyleFilterIconContiner>
        <StyledChipContainer ref={chipContainerRef}>
          {activeFilters?.map((chip) => (
            <StyledChip
              id={`chip_${chip?.field}`}
              key={chip?.field}
              label={
                <Tooltip
                  placement="top"
                  isTruncatedText
                  title={`${chip?.field}: ${chip?.filter?.name}`}
                  text={`${chip?.field}: ${chip?.filter?.name}`}
                  style={{ fontSize: '0.875rem', lineHeight: 1.2858 }}
                />
              }
              variant="outlined"
              onDelete={handleDelete(chip)}
              deleteIcon={<CloseChipIcon />}
            />
          ))}
        </StyledChipContainer>
        <StyledDropdownContainer>
          <StyledClearAll onClick={() => handleClearAll()}>Clear All</StyledClearAll>
        </StyledDropdownContainer>
      </StyledStack>
    </StyledGridFilters>
  );
};
