import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { useLoadedSrIds } from 'remote-state/ticketServiceHooks';
import { DisplayPage } from 'common/utils/displayPage';
import { QUERIES_KEYS } from 'constant';
import { selectColumnsOrder } from 'features/queue/slice';
import { useApplicationData } from 'remote-state/applicationHooks';
import { useGetAIConfigData } from 'remote-state/aiConfigDataHook';
import { getSuggestedCategoriesBySrIds } from 'services/sysaiService';
import { setSuggestedCategoriesById } from 'store/categorySlice';

export const useGetSuggestedCategories = (displayPage = DisplayPage.Queue) => {
  const columnOrder = useSelector(selectColumnsOrder);
  const { data: loadedSrIds } = useLoadedSrIds();
  const { data: isAISuggestedCategory } = useGetAIConfigData(QUERIES_KEYS.IS_AI_SUGGESTED_CATEGORY);

  const { data: isAiEnabled } = useApplicationData(QUERIES_KEYS.IS_AI_ENABLED);
  const dispatch = useDispatch();
  const { mutateAsync } = useMutation(getSuggestedCategoriesBySrIds);

  useEffect(() => {
    let shouldLoadSuggestedCategories = loadedSrIds?.length > 0 && isAiEnabled && isAISuggestedCategory;

    if (displayPage === DisplayPage.Queue) {
      shouldLoadSuggestedCategories =
        shouldLoadSuggestedCategories && columnOrder?.find(({ fieldName }) => fieldName === 'primaryCategory');
    }

    if (shouldLoadSuggestedCategories) {
      const updateSuggestedCategories = async () => {
        const suggestedCategoriesByIdRes = await mutateAsync(loadedSrIds);
        if (suggestedCategoriesByIdRes.status === 200) {
          const srIdSuggestedCategoriesPairs = suggestedCategoriesByIdRes.data;

          dispatch(setSuggestedCategoriesById(srIdSuggestedCategoriesPairs));
        }
      };

      updateSuggestedCategories();
    }
  }, [loadedSrIds, dispatch, mutateAsync, columnOrder, isAiEnabled, displayPage, isAISuggestedCategory]);
};
