import { useCallback } from 'react';

import ActionLineFooter from '../footer';
import useTexts from './useTexts';
import TimeManager from './components/TimeManager';
import UserDropdown from './components/UserDropdown';
import { StyledActivitiesWrapper, StyledActivitiesTitle, StyledDescriptionField } from './style';
import LabelWrapper from './components/LabelWrapper';
import { useSR } from '../../../../../../remote-state/ticketServiceHooks';

const Activities = ({ data, onChange }) => {
  const texts = useTexts();
  const { sr } = useSR();

  const handleChange = useCallback(
    (key, value) => {
      onChange(key, { [key]: value });
    },
    [onChange],
  );

  return (
    <>
      <StyledActivitiesWrapper>
        <StyledActivitiesTitle>{texts.title}</StyledActivitiesTitle>
        <LabelWrapper label={texts.responsibleUser} required>
          <UserDropdown
            selectedUser={data.user}
            srType={sr?.data?.srType}
            onChange={(user) => handleChange('user', user)}
          />
        </LabelWrapper>
        <TimeManager onChange={(time) => handleChange('time', time)} />
        <LabelWrapper label={texts.description}>
          <StyledDescriptionField
            isNewField={!data?.description}
            placeholder={texts.placeholder}
            onBlur={(event) => handleChange('description', event.target.value)}
          />
        </LabelWrapper>
      </StyledActivitiesWrapper>
      <ActionLineFooter customSaveText={texts.submit} />
    </>
  );
};

export default Activities;
