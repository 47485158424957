const journeyConstants = {
  CI_ID: 'ciId',
  ASSET_ID: 'assetId',
  STATUS: 'status',
  PRIORITY: 'priority',
  DYNAMIC_LIST: 'dynamicList',
  MAX_JOURNEY_ITEMS: 200,
};

export { journeyConstants };
