import { Box } from '@mui/material';
import { useCallback, useState } from 'react';
import moment from 'moment-timezone';

import DatePicker from 'common/components/datePicker';
import useTexts from '../useTexts';
import { calculateEndTime, calculateTotalTime } from '../utils';
import TotalTime from './TotalTime';
import LabelWrapper from './LabelWrapper';
import useDateTimeInfo from '../../../../../../../common/utils/hooks/useDateTimeInfo';

function formatTime(time) {
  return `${String(time).padStart(2, '0')}`;
}

const TimeManager = ({ onChange }) => {
  const texts = useTexts();
  const [startTime, setStartTime] = useState(moment().valueOf());
  const [endTime, setEndTime] = useState(null);
  const [total, setTotal] = useState({ hours: 0, minutes: 0 });
  const { timezone } = useDateTimeInfo();

  const handleStartTimeChange = useCallback((newStartTime) => {
    if (endTime && moment(newStartTime).isAfter(moment(endTime))) {
      newStartTime = endTime;
    }

    const total = calculateTotalTime(newStartTime, endTime);

    setStartTime(newStartTime);
    setTotal(total);
    onChange({ startTime: newStartTime, endTime, total });
  }, [endTime, onChange]);

  const handleEndTimeChange = useCallback((newEndTime) => {
    if (startTime && moment(newEndTime).isBefore(moment(startTime))) {
      newEndTime = startTime;
    }

    const total = calculateTotalTime(startTime, newEndTime);

    setEndTime(newEndTime);
    setTotal(total);
    onChange({ startTime, endTime: newEndTime, total });
  }, [startTime, onChange]);

  const handleTotalChange = useCallback((event) => {
    const { name, value } = event.target;
    let parsedValue = parseInt(value, 10);

    if (name === 'minutes' && parsedValue > 59) {
      parsedValue = 59;
    }

    const newTotal = { ...total, [name]: parsedValue };
    const endTime = calculateEndTime(startTime, newTotal);

    setTotal(newTotal);
    setEndTime(endTime);
    onChange({ startTime, endTime, total: newTotal });
  }, [startTime, total, onChange]);

  const setDefaultEndDate = useCallback(() => {
    const currentTime = new Date();
    const todayMidnight = currentTime.setHours(0, 0, 0, 0);
    const selectedDateStr = moment(todayMidnight)?.format('MM/DD/yyy hh:mm a');
    const selectedMomentStr = moment(selectedDateStr, ['MM/DD/yyy hh:mm a']).format('yyyy-MM-DD HH:mm');
    return moment.tz(selectedMomentStr, timezone);
  }, [timezone]);

  return (
    <Box sx={{ display: 'flex', gap: '12px' }}>
      <LabelWrapper required label={texts.startTime} width="200px">
        <DatePicker
          placeholder={texts.setTime}
          date={startTime}
          onChange={handleStartTimeChange}
          isNewField={!startTime}
          disablePortal
        />
      </LabelWrapper>
      <LabelWrapper label={texts.endTime} width="200px">
        <DatePicker
          placeholder={texts.setTime}
          date={endTime}
          defaultDate={setDefaultEndDate()}
          onChange={handleEndTimeChange}
          isNewField={!endTime}
          disablePortal
        />
      </LabelWrapper>
      <LabelWrapper required label={texts.totalTime}>
        <TotalTime
          hours={formatTime(total.hours)}
          minutes={formatTime(total.minutes)}
          isNewField={!(total.hours || total.minutes)}
          onChange={handleTotalChange}
        />
      </LabelWrapper>
    </Box>
  );
};

export default TimeManager;
