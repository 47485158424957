const passwordValidator = {
  lengthValidator: /.{8,}/g,
  uppercaseValidator: /(?=.*[A-Z]).*/g,
  lowercaseValidator: /(?=.*[a-z]).*/g,
  specialCharValidator: /(?=.*[~!@#$%^&*()_-])/g,
  numberValidator: /(?=.*\d).*/g,
};

const resetValidators = (validator) => {
  Object.values(validator).forEach((regex) => {
    regex.lastIndex = 0;
  });
};

const PASSWORD_COMPLEXITY_THRESHOLD = 3;

export const checkPasswordValid = (password) => {
  // if password is falsey (''), we don't want to show an error status
  // and the form should be savable without adding a new password
  if (!password) {
    return {
      isLengthValid: false,
      hasUpperCase: false,
      hasLowerCase: false,
      hasSpecialChar: false,
      hasNumber: false,
      hasMinRequirements: false,
      isPasswordValid: true,
    };
  }

  resetValidators(passwordValidator);

  const isLengthValid = passwordValidator.lengthValidator.test(password);
  const hasUpperCase = passwordValidator.uppercaseValidator.test(password);
  const hasLowerCase = passwordValidator.lowercaseValidator.test(password);
  const hasSpecialChar = passwordValidator.specialCharValidator.test(password);
  const hasNumber = passwordValidator.numberValidator.test(password);
  const hasMinRequirements = [hasUpperCase, hasLowerCase, hasSpecialChar, hasNumber]
        .filter((criteria) => criteria).length >= PASSWORD_COMPLEXITY_THRESHOLD;

  return {
    isLengthValid,
    hasUpperCase,
    hasLowerCase,
    hasSpecialChar,
    hasNumber,
    hasMinRequirements,
    isPasswordValid: isLengthValid && hasMinRequirements,
  };
};

export const checkPasswordMismatch = ({ password, confirmPassword }) => ({
  isPasswordMismatch: !!password && !!confirmPassword && password !== confirmPassword,
});

export const capitalizeFirstLetter = (str) => {
  if (!str) {
    return '';
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const emailNotificationsFormat = (emailNotifications) =>
  emailNotifications === 'Y' || emailNotifications === true;
