import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useChannel } from '@ably-labs/react-hooks';
import { APP_CONSTANTS } from 'constants/app';
import { setToasterMessage } from 'store/globalSlice';
import { SR_PREVIEW_MODE_KEY } from 'features/TicketPanel/constants';
import { useAuditLog } from 'features/resolutionPanel/middlePanel/auditLog/hooks';
import useTextsTicketPanel from 'features/TicketPanel/useTexts';
import { FEATURE_FLAGS_KEYS } from 'constants/featureFlags';
import { useFeatureFlagQuery } from 'remote-state/featureFlagsHooks';
import { decryptData, generateSecretKey } from 'common/utils/decrypt';
import { CHANNEL_MESSAGE_TYPES } from 'constants/common';
import { useDispatch } from 'react-redux';
import { useRouter } from '@tanstack/react-router';

const accountId = localStorage.getItem(APP_CONSTANTS.ACCOUNT_ID_LOCAL_KEY);
const QUEUE_CHANNEL_NAME = accountId;
const ALLOWED_CHANNEL_MESSAGES = [
  CHANNEL_MESSAGE_TYPES.UPDATED,
  CHANNEL_MESSAGE_TYPES.DELETE_TICKET,
  CHANNEL_MESSAGE_TYPES.LOCK_TICKET,
  CHANNEL_MESSAGE_TYPES.UNLOCK_TICKET,
  // CHANNEL_MESSAGE_TYPES.UPDATED_ACTION_ITEM,
];

export const useTicketWebSocketDataSync = ({ insertTime }) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const router = useRouter();
  const textsTicketPanel = useTextsTicketPanel();
  const { updateAuditLogs } = useAuditLog();
  const { data: isWebsocketSyncFFOn } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.REAL_TIME_UPDATES,
    defaultValue: false,
  });
  const [queueUpdates, setQueueUpdates] = useState({ isEnabled: isWebsocketSyncFFOn, updates: [] });

  const unlockTicket = useCallback(
    ({ id }) => {
      queryClient.setQueryData(['ticketLockStatus', id], () => ({
        isLocked: false,
        lockingUser: null,
      }));
    },
    [queryClient],
  );

  const lockTicket = useCallback(
    ({ data }) => {
      queryClient.setQueryData(['ticketLockStatus', data.srId], () => ({
        isLocked: true,
        lockingUser: data?.userName,
      }));
    },
    [queryClient],
  );

  const isCurrentTicket = (incomingId) => {
    const currentSrId = router.latestLocation?.search?.id;
    //This function is a local patch due to unreliable scoping issues
    if (!currentSrId) return;
    if (currentSrId === 'new') return;
    if (currentSrId === SR_PREVIEW_MODE_KEY) return;
    if (Number(currentSrId) !== incomingId) return;
    return true;
  };

  const [RealtimeChannel] = useChannel(QUEUE_CHANNEL_NAME, ({ name, data }) => {
    console.log('Websocket: useChannel event commited', { name, data });
    if (ALLOWED_CHANNEL_MESSAGES.includes(name) && isCurrentTicket(data.srId)) {
      setQueueUpdates((prev) => ({ ...prev, updates: [...prev.updates, { name, data }] }));
    }
  });

  const handleSocketEvents = useCallback(
    ({ data, name, id }) => {
      try {
        switch (name) {
          case CHANNEL_MESSAGE_TYPES.UPDATED:
            if (isWebsocketSyncFFOn) {
              const secretKey = generateSecretKey({ id, insertTime });
              const decryptedData = decryptData({ encryptedData: data.encryptedData, secretKey });
              queryClient.setQueryData(['srId', id], (oldTicket) => {
                let value;
                try {
                  value = JSON.parse(decryptedData.changedFieldValue);
                } catch (err) {
                  value = decryptedData.changedFieldValue;
                }
                const updatedField =
                  decryptedData.changedFieldName !== 'assignee' ? { [decryptedData.changedFieldName]: value } : value;
                const updatedTicket = {
                  ...oldTicket,
                  ...updatedField,
                };
                // updateAuditLogs({ log: decryptedData.auditLogs });
                if (decryptedData.isCoreUpdate) {
                  dispatch(setToasterMessage({ message: textsTicketPanel.incomingMessageToaster }));
                }
                return updatedTicket;
              });
            }
            break;

          case CHANNEL_MESSAGE_TYPES.UPDATED_ACTION_ITEM:
            queryClient.invalidateQueries(['workflows', { srId: id }]);
            updateAuditLogs({ log: data?.auditLogs });

            break;

          case CHANNEL_MESSAGE_TYPES.LOCK_TICKET:
            lockTicket({ data });
            break;

          case CHANNEL_MESSAGE_TYPES.UNLOCK_TICKET:
            unlockTicket({ id });
            break;

          case CHANNEL_MESSAGE_TYPES.DELETE_TICKET:
            router.navigate({ to: '/spaces/404' });

            break;

          default:
            console.warn('WebsocketError: Couldnt find appropiate action for given Websocket event', name, data);
            break;
        }
      } catch (error) {
        console.error('WebsocketError: Couldnt obtain given Websocket event', {
          error,
          name,
          data,
          insertTime,
          secret: generateSecretKey({ id, insertTime }),
        });
      }
    },
    [
      dispatch,
      queryClient,
      lockTicket,
      router,
      textsTicketPanel.incomingMessageToaster,
      unlockTicket,
      insertTime,
      updateAuditLogs,
      isWebsocketSyncFFOn,
    ],
  );

  useEffect(() => {
    let isNotUmounted = true;
    const updateCache = async (updatesList) => {
      await Promise.all([
        ...updatesList.map(({ data, name }) => handleSocketEvents({ name, data, id: data?.srId })),
      ]).then(() => {
        if (isNotUmounted) {
          setQueueUpdates((prev) => ({ ...prev, updates: [] }));
        }
      });
    };
    if (Array.isArray(queueUpdates.updates) && queueUpdates.updates.length > 0) {
      updateCache(queueUpdates.updates);
    }
    return () => {
      isNotUmounted = false;
    };
  }, [queueUpdates.updates, handleSocketEvents]);

  // useEffect(() => {
  //   setQueueUpdates((prev) => ({ ...prev, isEnabled: isWebsocketSyncFFOn }));
  // }, [isWebsocketSyncFFOn]);

  useEffect(() => {
    if (RealtimeChannel.state === 'failed') {
      console.warn('Websocket: connection is failed');
    }
  }, [RealtimeChannel.state]);
};

export default useTicketWebSocketDataSync;
