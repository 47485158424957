import { useState, useRef, useEffect } from 'react';

const useHover = () => {
    const [value, setValue] = useState(false);
    const ref = useRef(null);
    const timeoutId = useRef(null);

    const handleMouseOver = () => {
        clearTimeout(timeoutId.current);
        setValue(true);
    };

    const handleMouseOut = () => {
        timeoutId.current = setTimeout(() => {
            setValue(false);
        }, 100);
    };

    useEffect(
        () => {
            const node = ref.current;
            if (node) {
                node.addEventListener('mouseover', handleMouseOver);
                node.addEventListener('mouseleave', handleMouseOut);
                return () => {
                    node.removeEventListener('mouseover', handleMouseOver);
                    node.removeEventListener('mouseleave', handleMouseOut);
                    clearTimeout(timeoutId.current);
                };
            }
        },
        [], // Recall only if ref changes
    );

    return [ref, value];
};

export default useHover;
