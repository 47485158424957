import { useCallback, useEffect, useState } from 'react';
import SearchField from 'common/components/searchField';
import { FilterContainer } from '../style';

export const FilterByText = (props) => {
  const { handleSearch, selectedFilter, clearSearch, searchPlaceholderText } = props;
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (selectedFilter?.values[0]) {
      setSearchValue(selectedFilter?.values[0]);
    } else {
      setSearchValue('');
    }
  }, [selectedFilter]);

  const handleSaveValue = useCallback(
    (e) => {
      setSearchValue(e.target.value);
      handleSearch(e);
    },
    [handleSearch],
  );

  return (
    <FilterContainer>
      <SearchField
        value={searchValue}
        placeholderText={searchPlaceholderText}
        onChange={handleSaveValue}
        onClear={clearSearch}
        autoFocus
      />
    </FilterContainer>
  );
};
