import { createRootRoute, createRoute } from '@tanstack/react-router';
import ExitPrompt from 'common/components/exitPrompt';
import { ErrorBoundary } from 'common/components/errorBoundary';
import ErrorPage from 'features/errorPage';
import { LoginSession } from 'features/LoginSession';
import { CheckMailPage } from 'features/LoginSession/pages/checkMailPage';
import { ForgotPasswordPage } from 'features/LoginSession/pages/forgotPasswordPage';
import Logout from 'features/Logout';
import MainPanel from 'features/mainPanel';
import Queue from 'features/queue';
import Settings from 'features/settings';
import TemplateBuilder from 'features/templateBuilder';
import TemplateList from 'features/templateList';
import TicketPanel from 'features/TicketPanel';
import ExternalPagePanel from 'features/externalPage';
import LoggedInRoutesPanel from 'features/loggedInRoutesPanel';
import PendingComponentLoader from 'common/components/pendingComponentLoader';
import { ROUTES_PATHS } from 'constants/index';
import { redirectToTicketOrServicePortal as isRedirectToTicket } from 'features/TicketPanel/ticketloader';

export const routeConfig = () => {
  const rootRoute = createRootRoute();

  const mainPanelRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: '/',
    exact: true,
    shouldReload: false,
    component: () => (
      <ExitPrompt>
        <MainPanel />
      </ExitPrompt>
    ),
    pendingComponent: PendingComponentLoader,
  });

  mainPanelRoute.addChildren([
    createRoute({
      getParentRoute: () => mainPanelRoute,
      path: ROUTES_PATHS.LOGIN_URL,
      shouldReload: false,
      component: () => (
        <ErrorBoundary>
          <LoginSession />
        </ErrorBoundary>
      ),
    }),
    createRoute({
      getParentRoute: () => mainPanelRoute,
      shouldReload: false,
      path: ROUTES_PATHS.FORGOT_PASSWORD_URL,
      component: () => (
        <ErrorBoundary>
          <ForgotPasswordPage />
        </ErrorBoundary>
      ),
    }),
    createRoute({
      getParentRoute: () => mainPanelRoute,
      path: ROUTES_PATHS.CHECKMAIL_URL,
      shouldReload: false,
      component: () => (
        <ErrorBoundary>
          <CheckMailPage />
        </ErrorBoundary>
      ),
    }),
    createRoute({
      getParentRoute: () => mainPanelRoute,
      path: ROUTES_PATHS.PAGE404_URL,
      shouldReload: false,
      component: () => (
        <ErrorBoundary>
          <ErrorPage />
        </ErrorBoundary>
      ),
    }),

    createRoute({
      getParentRoute: () => mainPanelRoute,
      path: ROUTES_PATHS.LOGOUT_URL,
      shouldReload: false,
      component: () => (
        <LoggedInRoutesPanel>
          <Logout />
        </LoggedInRoutesPanel>
      ),
    }),
    createRoute({
      getParentRoute: () => mainPanelRoute,
      path: ROUTES_PATHS.TEMPLATE_URL,
      shouldReload: false,
      component: () => <TemplateBuilder />,
    }),
    createRoute({
      getParentRoute: () => mainPanelRoute,
      path: ROUTES_PATHS.TEMPLATE_LIST_URL,
      shouldReload: false,
      component: () => (
        <LoggedInRoutesPanel>
          <TemplateList />
        </LoggedInRoutesPanel>
      ),
    }),

    createRoute({
      getParentRoute: () => mainPanelRoute,
      path: ROUTES_PATHS.SETTINGS_URL,
      shouldReload: false,
      component: () => (
        <LoggedInRoutesPanel>
          <Settings />
        </LoggedInRoutesPanel>
      ),
    }),

    createRoute({
      getParentRoute: () => mainPanelRoute,
      path: ROUTES_PATHS.TICKET_URL,
      shouldReload: false,
      component: () => (
        <LoggedInRoutesPanel>
          <TicketPanel />
        </LoggedInRoutesPanel>
      ),
      loader: isRedirectToTicket,
    }),

    createRoute({
      getParentRoute: () => mainPanelRoute,
      path: ROUTES_PATHS.EXTERNAl_PAGES_URL,
      shouldReload: false,
      component: () => (
        <LoggedInRoutesPanel>
          <ExternalPagePanel />
        </LoggedInRoutesPanel>
      ),
    }),
    createRoute({
      getParentRoute: () => mainPanelRoute,
      path: ROUTES_PATHS.BASE_PATH,
      component: () => (
        <LoggedInRoutesPanel>
          <Queue />
        </LoggedInRoutesPanel>
      ),
    }),
  ]);

  rootRoute.addChildren([mainPanelRoute]);

  return rootRoute;
};
