import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const StyledFloatingTicketWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  backgroundColor: theme?.palette.generic?.primary,
  '& .assignee-tooltip-wrapper': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export const StyledFloatingHeaderWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isAutoPopulateDisplayed',
})(({ theme, isAutoPopulateDisplayed }) => ({
  height: isAutoPopulateDisplayed && '100%',
  backgroundColor: '#FFF',
  borderTopLeftRadius: theme?.shape?.border?.borderRadius,
  borderTopRightRadius: theme?.shape?.border?.borderRadius,
  position: 'relative',
  padding: '15px 24px 24px',
  display: 'flex',
  flexDirection: 'column',
}));

const loaderDefaultStyle = {
  position: 'absolute',
  zIndex: 10,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const StyledLoader = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isAutoPopulateSaving',
})(({ theme, isAutoPopulateSaving }) => ({
  ...loaderDefaultStyle,
  top: 0,
  marginTop: isAutoPopulateSaving ? '80px' : '153px',
  marginBottom: '80px',
  backgroundColor: theme?.palette.generic?.primary,
}));

export const StyledLoaderHeader = styled('div')(() => ({
  ...loaderDefaultStyle,
  top: 0,
  height: '153px',
  backgroundColor: '#FFF',
  borderRadius: '10px 10px 0 0',
}));

export const StyledLoaderFooter = styled('div')(({ theme }) => ({
  ...loaderDefaultStyle,
  height: '80px',
  borderTop: `1px solid ${theme?.palette.grey?.bt_alt_grey_hover}`,
  backgroundColor: theme?.palette.generic?.primary,
}));

export const useStyles = makeStyles(
  (theme) => ({
    messageWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '7px',
    },
    buttonsWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: '13px',
    },
    buttonsDivider: {
      width: '1.5px',
      height: '1.5px',
      backgroundColor: theme?.palette?.text?.blue_user_name,
      borderRadius: '100%',
    },
    buttonLink: {
      padding: 0,
      color: theme?.palette?.text?.blue_user_name,
      textDecoration: 'none',
      fontFamily: theme?.fonts?.robotoRegular,
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '20px',
      border: 'none',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
);