import { styled } from '@mui/material/styles';
import { Dialog } from '@mui/material';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.2)' },
  '& .MuiPaper-root': { boxShadow: 'none' },
  '& .MuiDialogTitle-root': {
    fontSize: theme?.fontSize?.big,
    lineHeight: 1.4286,
    color: theme.palette?.text?.txt_default,
    fontFamily: theme.fonts?.robotoRegular,
  },
}));
