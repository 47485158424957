import { useState } from 'react';
import Cropper from 'react-easy-crop';
import PropTypes from 'prop-types';
import { convertBlobToFile, extractFileNameFromURL, getCroppedImage } from './cropUtils';

const ImageCropper = ({ imageURL, cropShape, onCropComplete }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const handleCropComplete = async (_, croppedAreaPixels) => {
    const croppedImageBlob = await getCroppedImage(imageURL, croppedAreaPixels);
    const fileName = extractFileNameFromURL(imageURL);
    const file = convertBlobToFile(croppedImageBlob, fileName);
    onCropComplete(file);
  };

  const cropperStyle = {
    containerStyle: {
      background: 'white',
    },
  };

  return (
    <Cropper
      image={imageURL}
      crop={crop}
      zoom={zoom}
      aspect={1}
      onCropChange={setCrop}
      onZoomChange={setZoom}
      onCropComplete={handleCropComplete}
      cropShape={cropShape}
      style={cropperStyle}
      data-testid="image-cropper"
    />
  );
};

ImageCropper.propTypes = {
  imageURL: PropTypes.string.isRequired,
  cropShape: PropTypes.string,
  onCropComplete: PropTypes.func,
};

ImageCropper.defaultProps = {
  cropShape: 'round',
  onCropComplete: () => {},
};

export default ImageCropper;
