import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from '@tanstack/react-router';
import { selectActiveUser } from 'store/userSlice';
import { getLoginPathInfo } from 'common/utils/utils';
import { useLoginObject } from 'remote-state/userServiceHooks';
import PendingComponentLoader from 'common/components/pendingComponentLoader';
import { ROUTES_PATHS } from 'constants/routes';

import { LoginPage } from './pages/loginPage';
import { StyledLoginSession } from './style';

export const LoginSession = () => {
  const router = useRouter();
  const currentUser = useSelector(selectActiveUser);
  const { data: loginObject, isInitialLoading, isFetching } = useLoginObject();
  const { previousPath, manualLogin } = getLoginPathInfo();
  useEffect(() => {
    let navigationPath = previousPath;
    const from = router.latestLocation.search?.from;
    const external = router.latestLocation.search?.external || from?.startsWith(ROUTES_PATHS.SERVICE_PORTAL_URL);
    const currentUrl = router.latestLocation.href;
    if (from) {
      navigationPath = from;
    } else if (previousPath === ROUTES_PATHS.BASE_PATH && currentUrl.indexOf('login') < 0) {
      navigationPath = currentUrl;
    }
    if (navigationPath === '/') {
      navigationPath = '/spaces';
    }
    if (currentUser?.isAuth) {
      if (external) {
        window.location.href = navigationPath;
      } else {
        router.navigate({ to: navigationPath, replace: true });
      }
    }
  }, [currentUser?.isAuth, previousPath, router]);

  const renderRelevantPage = () => {
    const { externalUrl: externalLoginUrl } = loginObject;
    const externalLogin = document.cookie.split(';').filter((cookie) => cookie.includes('EXTERNAL_LOGIN'));
    if (loginObject && (!loginObject?.isExternalUrl || manualLogin === 'true' || externalLogin?.length > 0)) {
      return (
        <LoginPage
          customization={loginObject?.customization}
          isDomainField={loginObject?.isDomainField}
          isQAMode={loginObject?.isQAMode}
        />
      ); // our login page
    }
    if (loginObject?.isExternalUrl) {
      window.location.href = externalLoginUrl;
      return;
    }
    return <div>No Page Loaded</div>;
  };

  return (
    <>
      {!currentUser?.isAuth && (
        <>
          {(isInitialLoading || isFetching) ? (
            <PendingComponentLoader />
          ) : (
            <StyledLoginSession>
              {loginObject ? (
                <div className="login-page">{renderRelevantPage()}</div>
              ) : (
                <div>Error while trying to fetch login page</div>
              )}
            </StyledLoginSession>
          )}
        </>
      )}
    </>
  );
};
