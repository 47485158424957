import { useState } from 'react';
import { useDispatch } from 'react-redux';

import MessagePrompt from 'common/components/messagePrompt';
import { ReactComponent as ArchiveButton } from 'images/icons/bulkActions/archive.svg';
import { ReactComponent as RestoreButton } from 'images/icons/bulkActions/restore.svg';
import { useArchiveTickets } from 'remote-state/bulkActionsHooks';
import useTexts from '../useTexts';
import { StyledActionIcon, StyledActionTitle, StyledAction } from '../style';
import { actionTypes } from '../constants';
import { setToasterMessage } from '../../../../../store/globalSlice';
import { PENDO_TRACK_EVENTS, TICKET_EVENTS, TICKETS_OPEN_URL } from '../../../../../constants';
import usePendoTrackEvents from '../../../../../common/utils/hooks/usePendoTrackEvents';

const TOASTER_SHOW_TIME = 10000 // 10s;

const ArchiveAction = ({
  selectedRows,
  isArchiveView,
  onActionClicked,
  onUncheckSuccessTickets,
  dataset,
}) => {
  const [showArchivePrompt, setShowArchivePrompt] = useState(false);
  const lastSelectedSrId = selectedRows[selectedRows.length - 1]?.id;
  const { archiveTickets } = useArchiveTickets();
  const dispatch = useDispatch();
  const pendoTrackEvents = usePendoTrackEvents();
  const {
    archiveTicketText,
    archiveTicketConfirmText,
    archiveTicketWarningMessageTitle,
    archiveTicketWarningMessageText,
    archiveTicketCancelText,
    archiveTicketErrorMessageText,
    archiveTicketMessageSuccessText,
    archiveTicketMessagePartialSuccessText,
    restoreTicketText,
    restoreTicketConfirmText,
    restoreTicketWarningMessageTitle,
    restoreTicketWarningMessageText,
    restoreTicketErrorMessageText,
    restoreTicketMessageSuccessText,
    restoreTicketMessagePartialSuccessText,
    copyURL,
    viewSR,
  } = useTexts(selectedRows.length);
  const archiveMessagePromptTitle = archiveTicketWarningMessageTitle({ lastSelectedSrId });
  const restoreMessagePromptTitle = restoreTicketWarningMessageTitle({ lastSelectedSrId });

  const handleArchiveClicked = () => {
    setShowArchivePrompt(true);
  };

  const handleCloseArchivePrompt = () => {
    setShowArchivePrompt(false);
    pendoTrackEvents(PENDO_TRACK_EVENTS.ARCHIVE_CANCELLED_FROM_POPUP, {});
  };

  const archivedTicketMessage = (srId, message) => `
    <div class="message-wrapper">
      ${message}
      <div class="buttons-wrapper">
        <a href="${TICKETS_OPEN_URL.SPACES(srId)}" data-testid="viewSR" class="btn-link">
          ${viewSR}
        </a>
        <div class="buttons-divider"></div>
        <button class="btn-link" data-testid="copyLink">
          ${copyURL}
        </button>
      </div>
    </div>
  `;

  const showSuccessToaster = (numOfSuccessTickets, tickets) => {
    const singleSrId = tickets[0]?.id;
    let message = isArchiveView ? restoreTicketMessageSuccessText({ numOfSuccessTickets }) :
      archiveTicketMessageSuccessText({ numOfSuccessTickets });

    if (numOfSuccessTickets === 1) {
      message = archivedTicketMessage(singleSrId, message);
    }

    onUncheckSuccessTickets(tickets);

    dispatch(setToasterMessage({
      message,
      showFor: TOASTER_SHOW_TIME,
      onClickFunctionName: numOfSuccessTickets === 1 && TICKET_EVENTS.TICKET_ARCHIVED,
      id: singleSrId,
    }));
  };

  const showPartialSuccessToaster = (numOfSuccessTickets, totalNumOfTickets, tickets) => {
    onUncheckSuccessTickets(tickets);

    dispatch(setToasterMessage({
      message: isArchiveView ?
        restoreTicketMessagePartialSuccessText({ numOfSuccessTickets, totalNumOfTickets }) :
        archiveTicketMessagePartialSuccessText({ numOfSuccessTickets, totalNumOfTickets }),
      showFor: TOASTER_SHOW_TIME,
      type: 'warning',
    }));
  };

  const showErrorToaster = () => {
    dispatch(setToasterMessage({
      message: isArchiveView ? restoreTicketErrorMessageText : archiveTicketErrorMessageText,
      showFor: TOASTER_SHOW_TIME,
      type: 'error',
    }));
  };

  const handleArchiveSelectedTickets = async () => {
    try {
      handleCloseArchivePrompt();

      const archiveSrIDs = selectedRows.map(({ id }) => id);
      const payload = {
        archive: !isArchiveView,
        archiveSrIDs,
      }

      const { numOfSuccessTickets, numOfFailedTickets, tickets } = await archiveTickets({ payload });
      const totalNumOfTickets = numOfSuccessTickets + numOfFailedTickets;
      const isSuccess = numOfSuccessTickets !== 0 && numOfFailedTickets === 0;
      const isPartialSuccess = numOfSuccessTickets !== 0 && numOfFailedTickets !== 0;

      if (isSuccess) {
        showSuccessToaster(numOfSuccessTickets, tickets);
      } else if (isPartialSuccess) {
        showPartialSuccessToaster(numOfSuccessTickets, totalNumOfTickets, tickets);
      } else {
        showErrorToaster();
      }

      pendoTrackEvents(PENDO_TRACK_EVENTS.ARCHIVE_DONE_FROM_POPUP, { archiveSrIDs });
    } catch {
      showErrorToaster();
    }
  };

  return (
    <>
      <StyledAction
        isCursorPointer
        onClick={() => onActionClicked({ actionType: actionTypes.ARCHIVE, onClick: handleArchiveClicked })}
        {...dataset}
      >
        <StyledActionIcon>
          {isArchiveView ? <RestoreButton /> : <ArchiveButton/>}
        </StyledActionIcon>
        <StyledActionTitle>{isArchiveView ? restoreTicketText : archiveTicketText}</StyledActionTitle>
      </StyledAction>
      <MessagePrompt
        open={showArchivePrompt}
        title={isArchiveView ? restoreMessagePromptTitle : archiveMessagePromptTitle}
        children={isArchiveView ? restoreTicketWarningMessageText : archiveTicketWarningMessageText}
        btnOkText={isArchiveView ? restoreTicketConfirmText : archiveTicketConfirmText}
        btnCancelText={archiveTicketCancelText}
        onOkClick={handleArchiveSelectedTickets}
        showCancelBtn
        showIcon={false}
        onClose={handleCloseArchivePrompt}
      />
    </>
  );
};

export default ArchiveAction;
