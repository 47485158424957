import { useCallback, useMemo, useRef, useState } from 'react';
import { getFieldAttributes } from 'services/ticketService';
import { ReactComponent as WarningIcon } from 'images/icons/warning_icon.svg';
import { StyledMultiSelectFieldInner } from 'common/components/controls/MultiSelectField/style';
import { DropdownSelect, itemClassNames } from 'common/components/dropdownSelect';
import { Popover, ClickAwayListener } from '@mui/material';
import Tooltip from 'common/components/tooltip';
import { convertFromClientFieldId } from 'common/utils/fieldUtils';
import MultiSelectChipList from './MultiSelectChipList';
import { MultiSelectFieldWrapper, StyledMultiSelectField } from './style';

export default function MultiSelectField({
  itemList,
  selectedItemList,
  handleSelectItems,
  captionString,
  keyString,
  fieldPlaceholder,
  fieldId,
  srType,
  customColumn,
  disabled,
  updateField,
  srId,
  sortBy,
  required,
  tooltipTitle,
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const globalRef = useRef(null);
  const multiSelectRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const mappedItems = useMemo(() => {
    const mapList = new Map();
    if (Array.isArray(itemList)) {
      itemList.forEach((item) => mapList.set(String(item[keyString]), item));
    }
    return mapList;
  }, [itemList, keyString]);

  const chipList = useMemo(() => {
    if (Array.isArray(selectedItemList)) {
      return selectedItemList?.reduce((result, item) => {
        if (mappedItems.has(String(item))) {
          result.push(mappedItems.get(String(item)));
        }
        return result;
      }, []);
    }
    return [];
  }, [mappedItems, selectedItemList]);

  const isBottomPosition = useMemo(() => {
    if (anchorEl) {
      return window.innerHeight - anchorEl?.getBoundingClientRect().top <= 390; // 390 Max drop-down height
    }
    return false;
  }, [anchorEl]);

  const handleOpenDropdown = () => {
    const element = document.getElementsByClassName(`multi-select-field-wrapper-${srId}`)[0];
    if (!disabled && !isDropdownOpen) {
      setIsDropdownOpen(true);
      setAnchorEl(element);
    }
  };

  const handleCloseDropdown = () => {
    setIsDropdownOpen(false);
    setAnchorEl(null);

    if (isDropdownOpen) {
      updateField();
    }
  };

  const handleChange = useCallback(
    (selectedValues, optionsMap) => {
      handleSelectItems(selectedValues, optionsMap);
    },
    [handleSelectItems],
  );

  const handleDeleteChip = (id) => {
    const filteredItems = selectedItemList.filter((item) => item !== String(id));
    handleSelectItems(filteredItems);
  };

  const backendQueryConfig = useMemo(
    () => ({
      fetchingPromise: (query) => getFieldAttributes(convertFromClientFieldId(fieldId, customColumn), { query, srType, customColumn }),
    }),
    [fieldId, customColumn, srType],
  );
  const isEmptyRequiredValue = required && (!chipList || !chipList?.length);

  return (
    <ClickAwayListener onClickAway={handleCloseDropdown}>
      <MultiSelectFieldWrapper
        onClick={handleOpenDropdown}
        className={`multi-select-field-wrapper-${srId}`}
        ref={globalRef}
        isDropdownOpen={isDropdownOpen}
        disabled={disabled}
        error={isEmptyRequiredValue}
      >
        <StyledMultiSelectField className={`multi-select-field ${anchorEl ? 'expanded' : ''}`} ref={multiSelectRef}>
          <StyledMultiSelectFieldInner>
            <MultiSelectChipList
              handleDelete={handleDeleteChip}
              isDropdownOpen={isDropdownOpen}
              keyString={keyString}
              captionString={captionString}
              itemList={chipList}
              placeholder={fieldPlaceholder}
              isError={isEmptyRequiredValue}
              readOnly
              disabled
            />
            {isEmptyRequiredValue && (
              <Tooltip
                isError
                title={tooltipTitle}
                placement="top"
                disableHoverListener={!isEmptyRequiredValue || !tooltipTitle}
                text={<WarningIcon className="warning-icon" />}
              />
            )}
          </StyledMultiSelectFieldInner>
          <Popover
            anchorEl={anchorEl}
            open={isDropdownOpen}
            onClose={handleCloseDropdown}
            anchorOrigin={{ vertical: isBottomPosition ? 'top' : 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: isBottomPosition ? 'bottom' : 'top', horizontal: 'left' }}
          >
            <DropdownSelect
              autoInputFocus
              options={itemList}
              handleChange={handleChange}
              stylingVariant={itemClassNames.reporter}
              captionString={captionString}
              keyString={keyString}
              isMultiple
              selection={selectedItemList}
              sortBy={sortBy}
              backendQueryConfig={backendQueryConfig}
            />
          </Popover>
        </StyledMultiSelectField>
      </MultiSelectFieldWrapper>
    </ClickAwayListener>
  );
}
