import isEmpty from 'lodash/isEmpty';
import { useRef, useEffect } from 'react';

export const COLUMN_WIDTHS_LS_KEY = 'agGridColumnWidths';

const useCacheColumnsWidth = (): { handleColumnResized: (event) => void } => {
  const columnWidthsRef = useRef({});
  useEffect(() => {
    const saveColumnWidthsToLocalStorage = () => {
      if (!isEmpty(columnWidthsRef.current)) {
        const prevValue = JSON.parse(localStorage.getItem(COLUMN_WIDTHS_LS_KEY) || '{}');
        const updatedWidths = {
          ...prevValue,
          ...columnWidthsRef.current,
        };
        localStorage.setItem(COLUMN_WIDTHS_LS_KEY, JSON.stringify(updatedWidths));
      }
    };

    window.addEventListener('beforeunload', saveColumnWidthsToLocalStorage);

    return () => {
      saveColumnWidthsToLocalStorage();
      window.removeEventListener('beforeunload', saveColumnWidthsToLocalStorage);
    };
  }, []);

  const handleColumnResized = (event) => {
    if (event.finished) {
      const colId = event.column.getColId();
      const newWidth = event.column.getActualWidth();

      columnWidthsRef.current[colId] = newWidth;
    }
  };

  return { handleColumnResized };
};

export default useCacheColumnsWidth;
