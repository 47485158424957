import { localeIncludes } from './utils';

function searchByTitle(menuList, searchText, texts) {
  const result = [];

  for (const menuItem of menuList) {
    const title = texts[menuItem.rbToken];
    if (title && localeIncludes(title, searchText, { sensitivity: 'base' })) {
      result.push(menuItem);
    } else if (menuItem.subMenuList.length > 0) {
      const subMenuResult = searchByTitle(menuItem.subMenuList, searchText, texts);
      if (subMenuResult.length > 0) {
        result.push({
          ...menuItem,
          subMenuList: subMenuResult,
        });
      }
    }
  }

  return result;
}

export { searchByTitle };
