export const AUTH_CONSTANTS = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  KEEP_ALIVE: 'KEEP_ALIVE',
  INIT_USER_SETTINGS: 'INIT_USER_SETTINGS',
  SESSION_STORAGE_ID: 'sessionStorageID',
  INTERVAL_TIME: 900 * 1000,
  JSESSIONID: 'JSESSIONID',
  LOCALE: 'locale',
  ADMIN: 'admin',
  END_USER: 'endUser',
  CALCULATED_USER_NAME: 'calculated_user_name',
};
