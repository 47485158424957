import { useCallback } from 'react';
import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts(srType) {
  const texts = useRbTexts();
  const componentTexts = {
    srType: texts?.[`spaces.header.srType_${srType}`],
    emptyRelatedItemsTitle: texts?.['spaces.relatedItems.noItems.title'] || 'No related items yet',
    emptyRelatedItemsDescription:
      texts?.['spaces.relatedItems.noItems.body'] ||
      'You can link this service record to the following entities:__br__Service record, Asset, and CI',
    linkAnItem: texts?.['spaces.relatedItems.btn.linkAnItem'] || 'Link new',
    linkText: texts?.['spaces.relatedItems.btn.linkThisItem'] || 'Link',
    cancelText: texts?.['spaces.relatedItems.btn.nevermind'] || 'Cancel',
    linkProcessSubTitle:
      texts?.['spaces.relatedItems.linkProcess.subTitle'] || 'Link your required entity and define its relationship',
    linkProcessOkBtn: texts?.['spaces.relatedItems.linkProcess.button.ok'] || 'Link',
    linkProcessCancelBtn: texts?.['spaces.relatedItems.linkProcess.button.ok'] || 'Cancel',
    srText: texts?.['spaces.relatedItems.linkProcess.type.sr'] || 'Service Record',
    ciText: texts?.['spaces.relatedItems.linkProcess.type.ci'] || 'CI',
    assetText: texts?.['spaces.relatedItems.linkProcess.type.asset'] || 'Asset',
    childText: texts?.['spaces.relatedItems.indicator.child'] || 'Child',
    parentText: texts?.['spaces.relatedItems.indicator.parent'] || 'Parent',
    parentOfText: texts?.['spaces.relatedItems.linkProcess.relationship.parent'] || 'Parent of',
    childOfText: texts?.['spaces.relatedItems.linkProcess.relationship.child'] || 'Child of',
    causedByText: texts?.['spaces.relatedItems.linkProcess.relationship.causedBy'] || 'Caused by',
    blockedByText: texts?.['spaces.relatedItems.linkProcess.relationship.blockedBy'] || 'Blocked by',
    blocksText: texts?.['spaces.relatedItems.linkProcess.relationship.blocks'] || 'Blocks',
    mergedFromText: texts?.['spaces.relatedItems.linkProcess.relationship.mergedFrom'] || 'Merged from',
    mergedToText: texts?.['spaces.relatedItems.linkProcess.relationship.mergedTo'] || 'Merged to',
    causesText: texts?.['spaces.relatedItems.linkProcess.relationship.causes'] || 'Causes',
    relatesToText: texts?.['spaces.relatedItems.linkProcess.relationship.relatesTo'] || 'Relates to',
    relationshipText: texts?.['spaces.relatedItems.linkProcess.relationship'] || 'Relationship',
    itemText: texts?.['spaces.relatedItems.linkProcess.item'] || 'Item',
    unlinkSrPromptTitle: texts?.['spaces.relatedItems.unlink.prompt.title'] || 'Remove the link to __srType__ __id__?',
    unlinkAssetCiPromptTitle:
      texts?.['spaces.relatedItems.unlinkAssetCi.prompt.title'] || 'Remove the link to this __entityType__?',
    unlinkPromptDescription:
      texts?.['spaces.relatedItems.unlink.prompt.description'] || 'You can add it again later if you need to',
    unlinkPromptCancelBtn: texts?.['spaces.relatedItems.unlink.prompt.cancel'] || 'Cancel',
    unlinkPromptOkBtn: texts?.['spaces.relatedItems.unlink.prompt.ok'] || 'Unlink',
    cancelLinkPromptTitle: texts?.['spaces.relatedItems.cancelLink.prompt.title'] || 'Are you sure..?',
    srTableTitle: texts?.['spaces.relatedItems.table.sr.title'] || 'Related Service Records',
    assetTableTitle: texts?.['spaces.relatedItems.table.asset.title'] || 'Related Assets',
    ciTableTitle: texts?.['spaces.relatedItems.table.ci.title'] || 'Related CIs',
    RelationshipText: texts?.['spaces.relatedItems.table.column.relationship'] || 'Relationship',
    idText: texts?.['spaces.relatedItems.table.column.id'] || 'ID',
    titleText: texts?.['spaces.relatedItems.table.column.title'] || 'Title',
    typeText: texts?.['spaces.relatedItems.table.column.type'] || 'Type',
    serialNumberText: texts?.['spaces.relatedItems.table.column.serialNumber'] || 'Serial Number',
    locationText: texts?.['spaces.relatedItems.table.column.location'] || 'Location',
    ownerText: texts?.['spaces.relatedItems.table.column.owner'] || 'Owner',
    itemNameText: texts?.['spaces.relatedItems.table.column.itemName'] || 'Item Name',
    recentItemsText: texts?.['spaces.relatedItems.recentItems'] || 'Recent Items',
    selectText: texts?.['spaces.template.fields.dropdown.select'] || 'Select',
    searchSrPlaceholder:
      texts?.['spaces.relatedItems.linkProcess.items.searchPlaceholder'] ||
      'Search in ID, Title, Description, Notes...',
    searchAssetPlaceholder:
      texts?.['spaces.relatedItems.linkProcess.items.asset.searchPlaceholder'] ||
      'Search in Name, Description, IP Address, Serial Number...',
    searchCiPlaceholder:
      texts?.['spaces.relatedItems.linkProcess.items.ci.searchPlaceholder'] ||
      'Search in Category, CI Name, Serial Number...',
    limitReachedTooltip:
      texts?.['spaces.relatedItems.tooltip.limitReached'] ||
      'You’ve reached your limit:__br__No further items can be linked at this time.',
    limitReachedToasterMessage:
      texts?.['spaces.relatedItems.toaster.limitReached'] ||
      '__limitReached!__ A Service Record can only have up to 50 linked items.',
    limitReached: texts?.['spaces.relatedItems.limitReached'] || 'Limit reached!',
    moreLimitReachedToasterMessage:
      texts?.['spaces.relatedItems.moreLimitReached'] ||
      '__limitReached!__ A Service Record can only have up to 100 linked items.',
    newRelationshipToasterMessage:
      texts?.['spaces.relatedItems.toaster.newRelationship'] ||
      'Congrats on using a new relationship type, please note this would only be visible in your Spaces interface __moreInfo__',
    inheritanceTooltip:
      texts?.['spaces.relatedItems.inheritanceTooltip'] ||
      '__payAttention__ Child Service Records will inherit the status of the Parent Service Record. Once the Parent Service Record is resolved, all Child Service Records will inherit its Resolution Status and Solution Description.',
    payAttentionText: texts?.['spaces.relatedItems.inheritanceTooltip.payAttention'] || 'Pay attention!',
    moreInfoText: texts?.['spaces.relatedItems.moreInfo'] || 'More info',
  };

  const getItemTypeText = useCallback(
    (itemType) => texts?.[`spaces.relatedItems.linkProcess.type.${itemType}`] || itemType,
    [texts],
  );

  const getSrType = (srType) => texts?.[`spaces.header.srType_${srType}`];

  const getRelationshipText = useCallback(
    (relationship) => texts?.[`spaces.relatedItems.linkProcess.relationship.${relationship}`] || relationship,
    [texts],
  );

  return { componentTexts, getItemTypeText, getRelationshipText, getSrType };
}
