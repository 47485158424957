import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyleHeader = styled(Box)(() => ({
  display: 'flex',
  columnGap: '44px',
  rowGap: '18px',
  flexWrap: 'wrap',
  alignItems: 'flex-end',
  marginBottom: '14px',
}));

export const StyledAssignee = styled(Box)(({ theme }) => ({
  display: 'flex',
  columnGap: '6px',
  alignItems: 'center',
  position: 'relative',
  bottom: '3px',
  minWidth: '110px',
  padding: '2px 0',
  color: theme.palette.text.secondary_txt,
  lineHeight: '18px',
}));

export const StyledDropDowns = styled(Box)(() => ({
  display: 'flex',
  gap: '12px',
  flexWrap: 'wrap',
}));

export const DropdownSelectWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const DefaultRichTextPlaceholder = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: ' 84px',
  borderRadius: '4px',
  padding: ' 6px 12px 6px 8px',
  background: theme.palette.background.bg_new_input_text,
  marginBottom: '6px',
  cursor: 'text',
}));

export const StyledSelectedOption = styled(Box)(({ theme, isEmpty }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: '224px',
  height: '28px',
  border: 'none',
  background: isEmpty ? theme.palette.background.bg_new_input_text : 'transparent',
  borderRadius: '4px',
  textAlign: 'start',
  padding: '4px 8px',
  fontFamily: isEmpty && theme.fonts.robotoBold,
  cursor: 'pointer',
  '&:hover': {
    background: theme.palette.background.bg_hover_main,
  },
}));

export const StyledLabel = styled(Box)(({ theme }) => ({
  fontSize: theme.fontSize.small,
  padding: '2px 8px',
  '.required': {
    color: theme.palette.labels.bt_red_reg,
  },
}));

export const StyledAddResolution = styled('button')(({ theme }) => ({
  cursor: 'pointer',
  fontSize: theme.fontSize.main,
  fontWeight: '400',
  lineHeight: '20px',
  color: theme.palette.generic.sysaid_default,
  marginTop: '36px',
  border: 'none',
  background: 'transparent',
  padding: '2px 6px',

  '& .plus': {
    marginRight: '4px',
  },

  '&:hover': {
    backgroundColor: theme.palette.background.pages_background,
  },
}));

export const StyledSolutionResolutionWrapper = styled(Box)(({ theme }) => ({
  padding: '18px 24px',
  border: theme.shape.border.border,
  borderRadius: '4px',

  ' .fr-view': {
    padding: '2px 8px',
  },

  '.fr-view': {
    '&:not(:focus)': {
      display: '-webkit-box',
      WebkitLineClamp: 3,
      MsLineClamp: 3,
      OLineClamp: 3,
      WebkitBoxOrient: 'vertical',
      MsBoxOrient: 'vertical',
      OBoxOrient: 'vertical',
      maxHeight: '68px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}));

export const StyledSolutionWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledResolutionWrapper = styled(Box)(({ showResolution }) => ({
  marginTop: showResolution && '36px',
}));

export const SolutionResolutionTitle = styled(Box)(({ theme }) => ({
  fontSize: theme.fontSize.medium,
  color: theme.palette.text.txt_default,
  marginBottom: '18px',
  padding: '0px 8px',
  lineHeight: '24px',
}));

export const StyledEndSpace = styled(Box)(() => ({
  height: '5px',
}));

export const StyleFooter = styled(Box)(() => ({
  marginTop: '8px',
  padding: '0px 8px',
}));
