import { axiosInstance } from 'services/axiosConfig';

export function getWorkflows({ srId }) {
  if (srId == null || srId === '') return Promise.reject(new Error('srId is required'));
  return axiosInstance.get(`workflow/${srId}`).then((response) => response.data);
}

export function updateActionItem(data) {
  if (!data || !data.id || !data?.requestParams.actionItemId) return;
  return axiosInstance.patch('sr', data).then((response) => response.data);
}

export function getWorkflowFieldAttributes(fieldName, filters = {}) {
  if (!fieldName) return Promise.reject(new Error('FieldName is required'));
  const params = new URLSearchParams();

  Object.entries(filters).forEach(([key, value]) => {
    const formattedValue = Array.isArray(value) ? value.join(',') : value;
    params.append(key, formattedValue);
  });
  return axiosInstance.get(`action_item/attributes/${fieldName}`, { params }).then((response) => response.data);
}

export function getMailAction(id, phaseId, actionItemId, mailaction, expire, secureID) {
  return axiosInstance.get('patchsr/ticket', {
    params: {
      id,
      phaseId,
      action_item_id: actionItemId,
      mailaction,
      expire,
      secureID
    },
  }).then((response) => (response.data));
}
