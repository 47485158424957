export const CONSTANTS = {
  FILTER_TYPE_DATE: 1,
  FILTER_TYPE_DATE_RANGE: 2,
  MIN_SELECT_ITEMS_NUMBER_SEARCH: 10,
  MIN_SELECT_ITEMS_NUMBER_SCROLL: 5,
  DROPDOWN_ITEM_HEIGHT: 46,
  SUGGESTED_CATEGORIES_CARD_HEIGHT: 208,
  SUGGESTED_CATEGORIES_CARD_WITHOUT_THIRD_LEVEL_HEGIHT: 162,
  CHOOSE_CATEGORY_HEIGHT: 36,
  DROPDOWN_SUGGESTED_CATEGORY_EXTENSION: 165,
};
