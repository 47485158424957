import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledProgressBar = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'error',
})(({ isActionItem }) => ({
  display: isActionItem ? 'flex' : 'grid',
  flexWrap: 'nowrap',
  gridTemplateAreas: `'.'
  'progressBar'
  'error'`,
  gridTemplateRows: '1fr max-content 1fr',
  alignItems: 'center',
  justifyContent: isActionItem ? 'flex-start' : 'center',
  height: isActionItem ? '36px' : '120px',
  width: isActionItem ? '100%' : '170px',
  flexDirection: isActionItem ? 'row' : 'column',
  rowGap: '5px',

  '.error-wrapper': {
    alignSelf: 'start',
  },
  '& .progress-bar-wrapper': {
    width: '170px',

    '.upload-file-wrapper': {
      maxWidth: '142px',
      minWidth: '142px',
      width: '142px',
    },
  },
  '& .progress-bar-error-wrapper': {
    width: isActionItem ? 'auto' : '150px',
    height: isActionItem && 'auto',
    marginLeft: isActionItem && '12px',
  },
}));
