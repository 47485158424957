import { useRouter } from '@tanstack/react-router';
import { useQueryClient } from '@tanstack/react-query';
import { debounce } from 'lodash';
import { getIsExistingSr } from 'common/utils/srUtils';
import { ReactComponent as PrevIcon } from 'images/icons/prev.svg';
import { ReactComponent as NextIcon } from 'images/icons/next.svg';
import { useSrById } from 'remote-state/ticketServiceHooks';

function NextOrPrevSR({ srId }) {
  const queryClient = useQueryClient();
  const router = useRouter();
  const isExistingSR = getIsExistingSr(srId);
  const queryCache = queryClient.getQueryCache();

  let loadedSrIds;
  let index;
  let isNextDisable;
  let isPrevDisable;
  let dataUpdatedAt = 0;

  const queueDataCachedQueries = Object.entries(queryCache.queriesMap)
    .filter(([key]) => key.includes('"queueData"'))
    .map(([, query]) => query);

  queueDataCachedQueries?.forEach((query) => {
    const srIdsList = (query?.state?.data?.serviceRequests || []).map((item) => item[0].value) || [];
    const tempIndex = srIdsList.findIndex((item) => Number(item) === srId);
    if (tempIndex !== -1 && query.state.dataUpdatedAt > dataUpdatedAt) {
      index = tempIndex;
      dataUpdatedAt = query.state.dataUpdatedAt;
      loadedSrIds = srIdsList;
      isNextDisable = index === loadedSrIds.length - 1;
      isPrevDisable = index === 0;
    }
  });
  const { srType } = useSrById(srId, index === -1)?.data ?? {};
  useSrById(loadedSrIds?.length && !isNextDisable && loadedSrIds[index + 1])
  useSrById(loadedSrIds?.length && !isPrevDisable && loadedSrIds[index - 1])

  const handleClickPrev = debounce(() => {
    router.navigate({
      search: { id: loadedSrIds[index - 1] },
    });
  }, 1000);

  const handleClickNext = debounce(() => {
    router.navigate({
      search: { id: loadedSrIds[index + 1] },
    });
  }, 1000);

  return (
    <>
      {Boolean(loadedSrIds?.length) && isExistingSR && (index !== -1 || !Number.isNaN(srType)) && (
        <div className="next-or-prev-sr">
          <button onClick={handleClickPrev} disabled={isPrevDisable} data-testid="prev-sr">
            <PrevIcon />
          </button>
          <button onClick={handleClickNext} disabled={isNextDisable} data-testid="next-sr">
            <NextIcon />
          </button>
        </div>
      )}
    </>
  );
}

export default NextOrPrevSR;
