import { StyledIndicator } from './style';

export const Indicator = ({ icon, ...props }) => {
  const handleClick = (event) => {
    event.stopPropagation();
  };
  return (
    <StyledIndicator onClick={handleClick} {...props}>
      {icon}
    </StyledIndicator>
  );
};
