const checkPermissions = (userInfo, logInformation) => {
  const { author, noteAuthor, endUser: isAuthorEndUser } = logInformation;
  const { name: loggedInUserName, isSysAidAdmin } = userInfo;
  if (
    (author !== undefined && author === loggedInUserName) ||
    (noteAuthor !== undefined && noteAuthor === loggedInUserName)
  )
    return true;

  return !isAuthorEndUser && isSysAidAdmin;
};

export { checkPermissions };
