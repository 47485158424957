import { ReactComponent as VeryNegativeIcon } from 'images/icons/generativeAi/sentiment/very_negative.svg';
import { ReactComponent as NegativeIcon } from 'images/icons/generativeAi/sentiment/negative.svg';
import { ReactComponent as NeutralIcon } from 'images/icons/generativeAi/sentiment/neutral.svg';
import { ReactComponent as PositiveIcon } from 'images/icons/generativeAi/sentiment/positive.svg';
import { StyledChip } from '../StyledChip/StyledChip';
import { useTexts } from '../useTexts';
import { SentimentEnum } from './SentimentEnum';

const Sentiments = {
    [SentimentEnum.VERY_NEGATIVE]: {
        color: 'bt_red_light',
        icon: VeryNegativeIcon,
    },
    [SentimentEnum.NEGATIVE]: {
        color: 'bt_orange_light',
        icon: NegativeIcon,
    },
    [SentimentEnum.NEUTRAL]: {
        color: 'bt_yellow_light',
        icon: NeutralIcon,
    },
    [SentimentEnum.POSITIVE]: {
        color: 'sysaid_very_light',
        icon: PositiveIcon,
    },
};

export const Sentiment = ({ sentiment }) => {
    const sentimentConfig = Sentiments[sentiment] || Sentiments[SentimentEnum.NEUTRAL];
    const { sentiments: sentimentTexts } = useTexts();
    const label = sentimentTexts[sentiment] || sentimentTexts[SentimentEnum.NEUTRAL];

    return <StyledChip icon={<sentimentConfig.icon />} color={sentimentConfig.color} label={label} />;
};
