import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledGridConfigurationHeader = styled(Box)(() => ({
  marginTop: '6px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
}));

export const StyledQueueTitle = styled(Box)(({ theme }) => ({
  justifySelf: 'flex-start',
  marginRight: 'auto',
  fontSize: '28px',
  lineHeight: '24px',
  color: theme.palette.text.txt_default,
  fontFamily: theme.fonts.robotoLight,
}));
