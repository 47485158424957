import { styled } from '@mui/system';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

export const StyledWrapper = styled(FormControl)(() => ({
  width: '100%',
  marginTop: '24px',

  '&& .MuiInputBase-root.MuiOutlinedInput-root': {
    paddingRight: '14px',
  },
}));

export const StyledLabel = styled('span')(({ theme }) => ({
  color: theme.palette.text.secondary_txt,
  fontFamily: theme.fonts.robotoRegular,
  fontSize: theme.fontSize.small,
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '14px',

  '&.privacy': {
    padding: '2px 8px',
    marginBottom: '8px',
  },
  '&.groups': {
    padding: '0 8px',
    marginTop: '12px',
    marginBottom: '4px',
  },
}));

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: '0',
  marginRight: '8px',
  padding: '2px 6px',

  '& .MuiTypography-root': {
    marginLeft: '2px',
    color: theme.palette.text.title_txt,
    fontFamily: theme.fonts.robotoRegular,
    fontSize: theme.fontSize.main,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
  },
}));

export const StyledRadioGroup = styled(RadioGroup)(() => ({}));

export const StyledRadio = styled(Radio)(() => ({
  width: '20px',
  height: '20px',
}));
