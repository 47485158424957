import { ReactComponent as InfoIcon } from 'images/icons/info.svg';

import useTexts from '../hooks/useTexts';
import { StyledInheritanceInfoPanel } from './style';

export const InheritanceInfoPanel = () => {
  const {
    componentTexts: { inheritanceTooltip, payAttentionText },
  } = useTexts();

  const text = inheritanceTooltip.replace('__payAttention__', `<b>${payAttentionText}</b>`);

  return (
    <StyledInheritanceInfoPanel>
      <InfoIcon />
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </StyledInheritanceInfoPanel>
  );
};
