import { styled } from '@mui/material';

export const StyledDefaultButton = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isDefault',
})(({ theme, isDefault }) => ({
  fontSize: theme?.fontSize?.small,
  fontFamily: theme?.fonts?.robotoRegular,
  visibility: isDefault ? 'visible' : 'hidden',

  '.default-label': {
    color: theme?.palette?.text?.secondary_txt,
  },

  '.set-default-label': {
    color: theme?.palette?.generic?.sysaid_dark_green,
    fontWeight: 600,
  },
}));
