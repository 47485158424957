import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from '@tanstack/react-router';
import { useTicketPermissions } from 'remote-state/ticketServiceHooks';
import useTexts from 'features/resolutionPanel/useTexts';
import { ReactComponent as ReplyIcon } from 'images/icons/replyIcon.svg';
import { ReactComponent as OutgoingEmailIcon } from 'images/icons/outgoingEmailIcon.svg';
import { ReactComponent as IncomingEmailIcon } from 'images/icons/incomingEmailIcon.svg';
import { ReactComponent as ForwardIcon } from 'images/icons/forward.svg';
import { forwardMessageBody } from 'common/components/controls/RichTextEditor/utils';
import { useUserInfo } from 'remote-state/userServiceHooks';
import { USER_CONSTANTS } from 'constants/users';
import { FEATURE_FLAGS } from 'constants/featureFlags';
import { QUERIES_KEYS } from 'constant';

import { actionEnums } from '../../../constants';
import { editorActionTypes } from '../../../constants/editorActionTypes';
import { StyledFooter, StyledWrapper } from './style';
import { useEditorActions } from '../../../hooks';
import { selectActionLine, updateActionLineData } from '../../../store/slice';

export const MessageFooter = ({ logInformation, logType, auditLogId, timestamp }) => {
  const router = useRouter();
  const srId = router.latestLocation.search.id;
  const { reply, outgoingEmail, replyAll, incomingEmail, forward, subjectFormat } = useTexts();
  const { handleEditorFocus } = useEditorActions();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const userInfo = useUserInfo(QUERIES_KEYS.CURRENT_USER_FROM_CORE);
  const { valueCaption: timezone } = userInfo?.info?.find((atr) => atr.key === USER_CONSTANTS.TIMEZONE) || {};

  const { isEditorDirty } = useSelector(selectActionLine);
  const { cc = [], recipients = [], bcc = [], messageText, subject, sender, messageId, attachments } = logInformation;
  const showReplyAll = recipients?.length + cc?.length + bcc?.length > 1;
  const { data: hasEditPermissions } = useTicketPermissions('edit', srId === 'new' ? null : srId);
  const { title: srTitle } = queryClient.getQueryData(['srId', srId]) || {};

  const subjectFormatText = subjectFormat(srTitle, srId);
  const indexOfTitle = subjectFormatText.indexOf(srTitle);
  const srIdPrefix = subjectFormatText.slice(0, indexOfTitle);

  const retrievePlainText = () => {
    const froalaHtmlText = document.createElement('span');
    froalaHtmlText.innerHTML = messageText;
    return froalaHtmlText.textContent || froalaHtmlText.innerText || '';
  };

  const modifySubject = ({ isReply }) => {
    const operationPrefix = isReply ? 'RE' : 'FW';
    return `${operationPrefix}:  ${subject?.replace(srIdPrefix, '')}`;
  };

  const onReplyClick = ({ isReplyAll }) => {
    const quotedRecord = {
      auditLogId,
      subject,
      text: retrievePlainText(),
      type: editorActionTypes.MESSAGE,
      id: messageId,
      operation: 'REPLY',
    };
    const replySubject = modifySubject({ isReply: true });
    const params = {
      isQuoted: true,
      quotedRecord,
      sender: recipients,
      recipients: [{ ...sender, name: sender.displayName, userName: sender.displayName, type: 'user' }],
      ...(isReplyAll && { cc: [...recipients, ...cc, ...bcc] }),
      subject: replySubject,
      completeSubject: srIdPrefix + replySubject,
    };
    if (isEditorDirty) {
      handleEditorFocus({
        isNewEditor: true,
        type: editorActionTypes.MESSAGE,
        params,
      });
    } else {
      handleEditorFocus({
        isNewEditor: true,
        type: editorActionTypes.MESSAGE,
      });
      dispatch(updateActionLineData(params));
    }
  };

  const onForwardClick = () => {
    const quotedRecord = {
      auditLogId,
      subject,
      text: retrievePlainText(),
      type: editorActionTypes.MESSAGE,
      id: messageId,
      operation: 'FORWARD',
    };
    const forwardSubject = modifySubject({ isReply: false });
    const forwardBody = forwardMessageBody(sender, messageText, timestamp, timezone);
    const params = {
      isQuoted: true,
      quotedRecord,
      sender: recipients,
      subject: forwardSubject,
      completeSubject: srIdPrefix + forwardSubject,
      attachments,
      messageText: forwardBody,
    };
    if (isEditorDirty) {
      handleEditorFocus({
        isNewEditor: true,
        type: editorActionTypes.MESSAGE,
        params,
      });
    } else {
      handleEditorFocus({
        isNewEditor: true,
        type: editorActionTypes.MESSAGE,
      });
      dispatch(updateActionLineData(params));
    }
  };
  return (
    <StyledFooter isMessage>
      <StyledWrapper>
        {logType === actionEnums.MESSAGE_SENT ? (
          <>
            <OutgoingEmailIcon data-testid="outgoing-email-icon" />
            {outgoingEmail}
          </>
        ) : (
          <>
            <IncomingEmailIcon data-testid="incoming-email-icon" />
            {incomingEmail}
          </>
        )}
      </StyledWrapper>
      {!FEATURE_FLAGS.HIDE_MESSAGES_CONTENT && hasEditPermissions && (
        <>
          <StyledWrapper onClick={onReplyClick}>
            <ReplyIcon />
            <span className="message-footer-action-text" data-testid="reply-button" data-cy="reply-button">
              {reply}
            </span>
          </StyledWrapper>
          {showReplyAll && (
            <StyledWrapper onClick={() => onReplyClick({ isReplyAll: true })}>
              <ReplyIcon />
              <span className="message-footer-action-text" data-testid="reply-all-button" data-cy="reply-all-button">
                {replyAll}
              </span>
            </StyledWrapper>
          )}
          <StyledWrapper onClick={onForwardClick}>
            <span className="message-footer-action-text" data-testid="forward-button" data-cy="forward-button">
              {forward}
            </span>
            <ForwardIcon />
          </StyledWrapper>
        </>
      )}
    </StyledFooter>
  );
};
