import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getLatestAssets,
  getLatestCis,
  getRelatedItemsTypes,
  getSrLinkedIncidentsCount,
  getSrRelatedItems,
  linkSrRelatedAssetItem,
  linkSrRelatedCiItem,
  linkSrRelatedSrItem,
  searchAssets,
  searchCis,
  unlinkSrRelatedItem,
} from 'services/relatedItemsService';
import { CONSTANTS } from 'features/header/constants';
import { useStatusSettings } from 'remote-state/accountSettingsServiceHooks';
import { postSearchSrRequest } from 'services/ticketService';
import {
  RELATIONSHIP_TYPES,
  MAX_LINKED_ITEMS,
  ITEM_TYPES,
  SR_RELATED_ITEMS,
  RECENT_ASSETS,
  RECENT_CIS,
  PARENT_RELATIONSHIPS,
  SR_LINKED_INCIDENTS_COUNT,
} from '../constants';
import useTexts from './useTexts';

export const useGetSrRelatedItems = (srId) => {
  const { data: srRelatedItems, isFetching } = useQuery([SR_RELATED_ITEMS, srId], () => getSrRelatedItems(srId), {
    staleTime: 60 * 1000,
    cacheTime: 0,
    placeholderData: [],
    enabled: !!srId,
  });
  return { data: srRelatedItems || [], isLoading: isFetching };
};

export const useUnlinkRelatedItem = (srId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: unlinkSrRelatedItem,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [SR_RELATED_ITEMS, srId] });
      return data;
    },
  });
};

export const useLinkSrRelatedSrItem = (srId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: linkSrRelatedSrItem,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [SR_RELATED_ITEMS, srId] });
    },
  });
};

export const useLinkSrRelatedAssetItem = (srId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: linkSrRelatedAssetItem,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [SR_RELATED_ITEMS, srId] });
    },
  });
};

export const useLinkSrRelatedCiItem = (srId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: linkSrRelatedCiItem,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [SR_RELATED_ITEMS, srId] });
    },
  });
};

export const useInitialData = () => {
  const { data, isFetching } = useQuery(['relatedItemsTypes'], () => getRelatedItemsTypes(), {
    staleTime: 60 * 1000,
    cacheTime: 0,
    placeholderData: [],
  });
  return { data, isLoading: isFetching };
};

export const useIsSrChildOrParent = (srId) => {
  const {
    componentTexts: { parentText, childText },
  } = useTexts();
  const { data: relatedItems } = useGetSrRelatedItems(srId);
  const parentType = RELATIONSHIP_TYPES.PARENT;
  const childType = RELATIONSHIP_TYPES.CHILD;
  const result = relatedItems?.serviceRequests?.find(
    (item) => item.relationship === parentType || item.relationship === childType,
  );
  if (!result) return { isSrChildOrParent: false, relationship: null };
  const relationship = result.relationship === parentType ? parentText : childText;
  let childSrsCount = 0;
  if (result?.relationship === parentType) {
    childSrsCount = relatedItems?.serviceRequests?.filter((item) => item.relationship === parentType)?.length;
  }
  return {
    isSrChildOrParent: true,
    relationshipText: relationship,
    relationship: result.relationship,
    ...(result.relationship === childType && { parentId: result.id }),
    ...(result.relationship === parentType && { childSrsCount }),
  };
};

export const useRelatedSrItemsDropdownColumns = () => [
  {
    header: 'ID',
    valueKey: 'id',
    width: 100,
  },
  {
    header: 'SR Type',
    valueKey: 'srTypeString',
    width: 100,
  },
  {
    header: 'Title',
    valueKey: 'value',
    width: 200,
  },
];

export const useRelatedAssetItemsDropdownColumns = () => [
  {
    header: 'Serial Number',
    valueKey: 'serialNumber',
    width: 120,
  },
  {
    header: 'Asset Type',
    valueKey: 'assetType',
    width: 70,
  },
  {
    header: 'Owner',
    valueKey: 'owner',
    width: 140,
  },
  {
    header: 'Location',
    valueKey: 'location',
    width: 100,
  },
  {
    header: 'Asset Name',
    valueKey: 'value',
    width: 200,
  },
];

export const useRelatedCiItemsDropdownColumns = () => [
  {
    header: 'ID',
    valueKey: 'id',
    width: 100,
  },
  {
    header: 'CI Type',
    valueKey: 'ciType',
    width: 70,
  },
  {
    header: 'Owner',
    valueKey: 'owner',
    width: 140,
  },
  {
    header: 'Location',
    valueKey: 'location',
    width: 100,
  },
  {
    header: 'CI Name',
    valueKey: 'value',
    width: 200,
  },
];

export const useSrCustomBackendQueryConfig = async ({ query, srId, queryClient }) => {
  const searchSrRequest = {
    from: 0,
    limit: MAX_LINKED_ITEMS,
    columnConfiguration: [
      {
        fieldName: 'id',
        displayName: 'spaces.template.fields.id',
        alwaysReadOnly: true,
        id: 2,
        sysaidEntity: null,
        customColumn: false,
        ticketTemplateFieldType: {
          id: 3,
          typeName: 'number',
        },
      },
      {
        fieldName: 'title',
        displayName: 'spaces.template.fields.title',
        alwaysReadOnly: false,
        id: 57,
        sysaidEntity: null,
        customColumn: false,
        ticketTemplateFieldType: {
          id: 1,
          typeName: 'text',
        },
      },
      {
        fieldName: 'srType',
        displayName: 'spaces.template.fields.srType',
        alwaysReadOnly: true,
        id: 32,
        sysaidEntity: null,
        customColumn: false,
        ticketTemplateFieldType: {
          id: 6,
          typeName: 'select',
        },
      },
    ],
    sorts: [],
    filters: [
      {
        type: 'search',
        values: [query],
      },
    ],
  };
  const data = await postSearchSrRequest(searchSrRequest);
  const recentlyCreatedSrs = queryClient.getQueryData(['recentlyCreatedSrs', srId]) || [];
  const srRelatedItems = queryClient.getQueryData([SR_RELATED_ITEMS, srId]) || [];
  const recentlyCreatedSrIds = recentlyCreatedSrs?.map((sr) => sr.id);
  const srRelatedItemsIds = srRelatedItems?.serviceRequests?.map((sr) => sr.id);
  if (data?.serviceRequests?.length) {
    const extractedValues = data?.serviceRequests
      ?.filter((sr) => {
        const id = sr.find((item) => item.field === 'id')?.value;
        return id !== srId && !recentlyCreatedSrIds?.includes(id) && !srRelatedItemsIds?.includes(id);
      })
      ?.map((sr) => {
        const id = sr.find((item) => item.field === 'id')?.value;
        const title = sr.find((item) => item.field === 'title')?.value;
        const srType = sr.find((item) => item.field === 'srType')?.value;
        return {
          id,
          srType,
          title,
          valueKey: id,
        };
      });
    queryClient.setQueryData(['recentlyCreatedSrs', srId], [...recentlyCreatedSrs, ...extractedValues]);
    return { values: extractedValues };
  }
  return [];
};

export const useAssetCustomBackendQueryConfig = async ({ query, queryClient, srId }) => {
  const assets = await searchAssets(query);
  const recentAssets = queryClient.getQueryData([RECENT_ASSETS, srId]) || [];
  const srRelatedItems = queryClient.getQueryData([SR_RELATED_ITEMS, srId]) || [];
  const recentAssetsIds = recentAssets?.map((asset) => asset.serialNumber);
  const assetRelatedItemsIds = srRelatedItems?.assets?.map((asset) => asset.serialNumber);
  if (assets?.length) {
    const values = assets
      ?.filter(
        (asset) =>
          !recentAssetsIds?.includes(asset?.serialNumber) && !assetRelatedItemsIds?.includes(asset?.serialNumber),
      )
      ?.map((asset) => ({
        ...asset,
        id: asset?.serialNumber,
        chipCaption: asset?.assetName,
        valueKey: asset?.serialNumber,
        value: asset?.assetName,
        valueCaption: `${asset?.serialNumber} ${asset?.assetName}`, // being used to filter the multi-select
      }));
    const recentAssets = queryClient.getQueryData([RECENT_ASSETS, srId]) || [];
    queryClient.setQueryData([RECENT_ASSETS, srId], [...recentAssets, ...values]);
    return { values };
  }
  return [];
};

export const useCiCustomBackendQueryConfig = async ({ query, queryClient, srId }) => {
  const cis = await searchCis(query);
  const recentCis = queryClient.getQueryData([RECENT_CIS, srId]) || [];
  const srRelatedItems = queryClient.getQueryData([SR_RELATED_ITEMS, srId]) || [];
  const recentCisIds = recentCis?.map((ci) => ci.id);
  const ciRelatedItemsIds = srRelatedItems?.cis?.map((ci) => ci.id);
  if (cis?.length) {
    const values = cis
      ?.filter((ci) => !recentCisIds?.includes(ci?.id) && !ciRelatedItemsIds?.includes(ci?.id))
      ?.map((ci) => ({
        ...ci,
        id: ci?.id,
        chipCaption: ci?.ciName,
        valueKey: ci?.id,
        value: ci?.ciName,
        valueCaption: `${ci?.id} ${ci?.ciName}`, // being used to filter the multi-select
      }));
    const recentCis = queryClient.getQueryData([RECENT_CIS, srId]) || [];
    queryClient.setQueryData([RECENT_CIS, srId], [...recentCis, ...values]);
    return { values };
  }
  return [];
};

const buildAssetsDataForDropdown = (data, locationList) => {
  const newData = data?.map((item) => ({
    serialNumber: item?.serialNumber,
    id: item?.serialNumber,
    assetType: item?.assetType,
    owner: item?.owner,
    location: locationList?.find((location) => location.id === parseInt(item?.location, 10))?.value || '',
    assetName: item?.assetName,
    value: item?.assetName,
    itemName: item?.assetName,
    chipCaption: item?.assetName,
    valueKey: item?.serialNumber,
    valueCaption: `${item?.serialNumber} ${item?.assetName}`, // being used to filter the multi-select
  }));
  return newData;
};

export function useGetRecentAssets(srId, itemType, locationList) {
  const [hasFetched, setHasFetched] = useState(false);
  const { data, refetch } = useQuery([RECENT_ASSETS, srId], () => getLatestAssets(srId), {
    placeholderData: [],
    select: (data) => buildAssetsDataForDropdown(data, locationList),
    staleTime: 60 * 1000,
    cacheTime: 0,
    enabled: itemType === ITEM_TYPES.ASSET.id,
  });

  useEffect(() => {
    if (itemType === ITEM_TYPES.ASSET.id && !hasFetched) {
      refetch().then(() => setHasFetched(true));
    }
  }, [itemType, refetch, hasFetched]);

  return { data };
}

const buildCisDataForDropdown = (data, locationList) => {
  const newData = data?.map((item) => ({
    id: item?.id,
    ciType: item?.ciType,
    owner: item?.owner,
    location: locationList?.find((location) => location.id === parseInt(item?.location, 10))?.value || '',
    ciName: item?.ciName,
    value: item?.ciName,
    chipCaption: item?.ciName,
    valueKey: item?.id,
    valueCaption: `${item?.id} ${item?.ciName}`, // being used to filter the multi-select
  }));
  return newData;
};

export function useGetRecentCis(srId, itemType, locationList) {
  const [hasFetched, setHasFetched] = useState(false);
  const { data, refetch } = useQuery([RECENT_CIS, srId], () => getLatestCis(srId), {
    placeholderData: [],
    select: (data) => buildCisDataForDropdown(data, locationList),
    staleTime: 60 * 1000,
    cacheTime: 0,
    enabled: itemType === ITEM_TYPES.CI.id,
  });

  useEffect(() => {
    if (itemType === ITEM_TYPES.CI.id && !hasFetched) {
      refetch().then(() => setHasFetched(true));
    }
  }, [itemType, refetch, hasFetched]);

  return { data };
}

export function useHasLinkedIncidents(srId, srType) {
  const { data: relatedItems } = useGetSrRelatedItems(srId);
  const { data: statusSettings } = useStatusSettings();
  if (srType === CONSTANTS.INCIDENT_TYPE) return { hasLinkedIncidents: false, statusSettings: [] };
  const linkedIncidentsWithoutParentRelationships = relatedItems?.serviceRequests?.filter(
    (linkedSr) => linkedSr.srType === CONSTANTS.INCIDENT_TYPE && !PARENT_RELATIONSHIPS.includes(linkedSr.relationship),
  );
  return { linkedIncidentsCount: linkedIncidentsWithoutParentRelationships?.length, statusSettings };
}

export const useGetSrLinkedIncidentsCount = (srId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: getSrLinkedIncidentsCount,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [SR_LINKED_INCIDENTS_COUNT, srId] });
    },
  });
};
