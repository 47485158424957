import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledHeader = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  gap: '12px',
  alignItems: 'center',

  '.accordion-toggle-btn': {
    cursor: 'pointer',
  },
}));

export const StyledTitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '2px',
  alignItems: 'center',
  color: theme?.palette?.text?.title_txt,
  fontSize: '16px',
  lineHeight: '24px',
}));
