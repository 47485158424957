import { memo } from 'react';

import { StyledCounter } from './stlye';
import { addLeadingZero } from '../../../middlePanel/auditLog/actionLine/activities/utils';

const ActivitiesCounter = ({ hours, minutes }) => (
  <StyledCounter>{hours}:{addLeadingZero(minutes)}</StyledCounter>
);

export default memo(ActivitiesCounter);
