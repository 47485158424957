import { useRbTexts } from 'remote-state/applicationHooks';
import { formatTranslation } from '../../../../common/utils/translation';

export default function useTexts(srId) {
    const texts = useRbTexts();
    const componentTexts = {
        convertToRequestText: texts?.['spaces.header.actions.convertTicket.convertIncidentToRequest'] || 'Convert to Request',
        convertToIncidentText: texts?.['spaces.header.actions.convertTicket.convertRequestToIncident'] || 'Convert to Incident',
        incidentTemplatesTitle: texts?.['spaces.header.actions.convertTicket.incidentTemplatesTitle'] || 'Incident Template:',
        requestTemplatesTitle: texts?.['spaces.header.actions.convertTicket.requestTemplatesTitle'] || 'Request Template:',
        conversionWarningText: texts?.['spaces.header.actions.convertTicket.warning'] || 'SR Conversions can\'t be reversed',
        workflowWarningText: texts?.['spaces.header.actions.convertTicket.workflowWarning'] || 'Workflow activity won\'t be saved',
        cancelBtnText: texts?.['spaces.header.actions.convertTicket.cancelBtnText'] || 'Go back',
        convertBtnText: texts?.['spaces.header.actions.convertTicket.convertBtnText'] || 'Convert',
        viewSrText: texts?.['spaces.header.actions.convertTicket.view'] || 'View',

        convertIncidentToRequestTitle: () =>
            formatTranslation(texts?.['spaces.header.actions.convertTicket.convertIncidentToRequestTitle'] || 'Convert Incident {srId} to Request', [
                { placeHolder: '{srId}', value: srId },
            ]),
        convertRequestToIncidentTitle: () =>
            formatTranslation(texts?.['spaces.header.actions.convertTicket.convertRequestToIncidentTitle'] || 'Convert Request {srId} to Incident', [
                { placeHolder: '{srId}', value: srId },
            ]),
        wasConvertedMessage: () =>
            formatTranslation(texts?.['spaces.header.actions.convertTicket.wasConvertedMessage'] || 'Conversion succeeded', [
                { placeHolder: '{srId}', value: srId },
            ]),
        errorInConvertedMessage: () =>
            formatTranslation(texts?.['spaces.header.actions.convertTicket.errorInConvertedMessage'] || 'Conversion failed. Try again later or contact support', [
                { placeHolder: '{srId}', value: srId },
            ]),
    };

    return componentTexts;
}
