import { axiosInstance } from './axiosConfig';

export const createSrNote = (newNote) => axiosInstance.post('notes', newNote).then((response) => response.data);

export const editSrNote = (note) => {
  const onSuccess = note.onSuccessEditNote;
  const onError = note.onErrorEditNote;
  const promptType = note.promptType;
  axiosInstance
    .patch('notes', note.requestData)
    .then((response) => {
      onSuccess({ ...response.data });
      return response.data;
    })
    .catch(() => {
      onError(promptType);
    });
};

export const deleteSRNote = ({ srId, noteId, auditLogId }) =>
  axiosInstance
    .delete(`notes/${srId}/${noteId}${auditLogId ? `?auditLogId=${auditLogId}` : ''}`)
    .then((response) => response.data);

export const updateDefaultShareWithReqUser = (shouldShareWithRequestUser = false) => {
  const queryParams = new URLSearchParams();
  queryParams.append('shouldShareWithRequestUser', shouldShareWithRequestUser);
  return axiosInstance
    .put(`user/updateDefaultNoteSharing?${queryParams.toString()}`, shouldShareWithRequestUser, {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(shouldShareWithRequestUser),
    })
    .then((response) => response.data);
};
