import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledAssignee = styled(Box, { shouldForwardProp: (prop) => prop !== 'isRequired' })(
  ({ theme, isRequired }) => ({
    display: 'flex',
    width: '100%',
    '& .MuiBox-root': {
      '&:hover': {
        '& #unassigned-avatar': {
          backgroundColor: isRequired && theme.palette.labels.bt_red_mid,
          path: {
            fill: isRequired && theme.palette.labels.bt_red_hover,
          },
        },
      },
    },
    '& .MuiAvatar-root': {
      '& .MuiTypography-root': {
        fontSize: '.875rem',
        lineHeight: '1.125rem',
        color: theme.palette.text.secondary_txt,
      },
      '&#unassigned-avatar': {
        borderColor: isRequired && theme.palette.labels.bt_red_reg,
        backgroundColor: isRequired && theme.palette.labels.bt_red_light,
        outline: '2px solid white',
        outlineOffset: '-3px',
        svg: {
          height: '0.75rem',
          width: '0.75rem',
        },
        path: {
          fill: isRequired && theme.palette.labels.bt_red_reg,
        },
        '&:hover': {
          filter: isRequired && 'brightness(100%)',
        },
        '&:hover::after': {
          content: isRequired && 'none',
        },
      },
    },
  }),
);
