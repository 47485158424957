import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledInactiveAttachmentChip = styled(Box)(({
  theme, isDeleted
}) => ({
  display: 'flex',
  alignItems: 'center',
  height: '36px',
  borderRadius: '4px',
  width: '190px',
  padding: '8px',
  gap: '10px',
  color: theme?.palette?.text?.secondary_txt,
  backgroundColor: theme?.palette?.grey?.divider_grey_v2,

  ...(isDeleted && {
    opacity: '0.5'
  }),

  '.attachment-icon': {
    position: 'static'
  },

  '.attachment-filename': {
    fontWeight: '700',
    textWrap: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));
