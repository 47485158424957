import { Box } from '@mui/material';
import PropTypes from 'prop-types';

export default function NavToggle({ onClick, children, ...rest }) {

  return (<Box onClick={onClick} {...rest} sx={{ display: 'inline' }}>
      {children}
    </Box>
  );
}

NavToggle.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.element,
};