import { styled } from '@mui/material/styles';
import { List } from '@mui/material';

const StyledNavList = styled(List, { shouldForwardProp: (prop) => prop !== 'expanded' })(({ theme, expanded }) => ({
  padding: '8px 0',

  a: {
    textDecoration: 'none',
  },

  '& .MuiListItemButton-root': {
    padding: 0,
    backgroundColor: 'unset',
    '& .MuiListItemText-primary': {
      maxWidth: '150px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },

  '& .MuiListItem-root': {
    '&:hover': {
      backgroundColor: theme.palette.grey.bt_alt_grey_light,
      borderLeft: `4px solid ${theme.palette.generic.sysaid}`,
    },

    '& .MuiListItemButton-root': {
      marginRight: expanded && '-20px',

      '&:hover': {
        marginLeft: '-4px',
        borderRadius: !expanded && '0 4px 4px 0',
      },

      '& .MuiListItemIcon-root': {
        minWidth: '24px',
        padding: expanded ? '12px 12px 12px 24px' : '12px 16px',
      },

      '& .MuiListItemText-root': {
        margin: 0,
        padding: '14px 0',
      },

      '& .MuiTypography-root': {
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: theme.palette.text.title_txt,
        letterSpacing: 0,
      },
    },

    width: expanded && '220px',
    borderRadius: expanded && '0px 30px 30px 0px',
  },
  '& .active': {
    backgroundColor: theme.palette.grey.bt_alt_grey_light,
    borderLeft: `4px solid ${theme.palette.generic.sysaid}`,
    '&:hover': {
      backgroundColor: theme.palette.background.selected_and_hover,
      borderLeft: `4px solid ${theme.palette.generic.sysaid}`,
    },
    '& .MuiListItemButton-root': {
      marginLeft: '-4px',
    },
  },

  '&.userOptions .user': {
    '& .MuiListItemIcon-root': {
      padding: '6px 6px 6px 18px',
    },

    '& .MuiListItemText-root': {
      paddingTop: '10px',
      paddingBottom: '10px',

      '& .MuiTypography-root': {
        lineHeight: '16px',
      },
    },
  },
  '&.userOptions .user .MuiListItemIcon-root': {
    padding: !expanded && '6px 10px',
  },

  '& :not(.user) > .MuiListItemButton-root .MuiListItemIcon-root span': {
    height: '24px',
  },
}));

export default StyledNavList;
