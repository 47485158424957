import { useRef, useState, useLayoutEffect, useMemo, useCallback, useEffect } from 'react';
import Tooltip from 'common/components/tooltip';
import { StyledChip } from 'features/queue/gridFilters/style';
import { ReactComponent as CloseChipIcon } from 'images/icons/chipDelete.svg';
import { ReactComponent as WarningIcon } from 'images/icons/warning_icon.svg';
import { ReactComponent as PlusAttach } from 'images/icons/plus-attach.svg';
import { debounce } from 'lodash';
import { StyledListWrapper, StyledMultiSelectLabel, StyledPlusWrapper } from './style';

const ITEM_WIDTH = 110;
const MORE_BTN_WIDTH = 140;

export default function MultiSelectChipList({
  keyString,
  itemList,
  captionString,
  handleOpen,
  isError,
  handleDelete,
  placeholder,
  parentHeight,
  isDropdownOpen,
  disabled,
  readOnly = false,
  withTruncatedChips = false,
  isWorkFlowMultiList = false,
  skipValidation,
  noPadding,
  noChipFixedWidth,
  chipCaptionKey,
}) {
  const chipCaption = chipCaptionKey || captionString;
  const [listHeight, setListHeight] = useState(0);
  const [isTruncated, setIsTruncated] = useState(false);
  const [visibleCount, setVisibleCount] = useState(itemList?.length);
  const listContainerRef = useRef(null);
  const moreItemsCount = itemList?.length - visibleCount;

  const calculateVisibleChips = useMemo(
    () =>
      debounce(() => {
        const offsetWidth = listContainerRef?.current?.offsetWidth;
        let elementsCount = Math.floor((offsetWidth - MORE_BTN_WIDTH) / ITEM_WIDTH);
        if (elementsCount <= 0) elementsCount = 1;
        setVisibleCount(elementsCount);
        setIsTruncated(itemList?.length > elementsCount);
      }, 300),
    [listContainerRef, itemList],
  );

  useLayoutEffect(() => {
    setListHeight(listContainerRef?.current?.offsetHeight);
    if (withTruncatedChips) calculateVisibleChips();
  }, [withTruncatedChips, calculateVisibleChips]);

  useEffect(() => {
    window.addEventListener('resize', calculateVisibleChips);
    return () => window.removeEventListener('resize', calculateVisibleChips);
  }, [calculateVisibleChips]);

  const truncatedTooltipText = useMemo(
    () =>
      isTruncated &&
      itemList
        ?.slice(visibleCount, itemList?.length)
        ?.map((e) => e?.[chipCaption])
        ?.join(', '),
    [itemList, visibleCount, chipCaption, isTruncated],
  );

  const renderList = useCallback(
    () =>
      itemList
        ?.slice(0, visibleCount)
        .map((item) => (
          <StyledChip
            className="multi-select-chip"
            data-testid="multi-select-chip"
            disableCursor={disabled}
            readOnly={readOnly}
            isWorkFlowMultiList={isWorkFlowMultiList}
            key={item?.[keyString]}
            variant="outlined"
            deleteIcon={<CloseChipIcon />}
            onDelete={() => handleDelete(item?.[keyString])}
            noChipFixedWidth={noPadding || noChipFixedWidth}
            label={
              <Tooltip
                placement="top"
                isTruncatedText
                title={item?.[chipCaption]}
                text={item?.[chipCaption]}
                style={{ fontSize: '0.875rem', lineHeight: 1.2858, color: '#303030' }}
              />
            }
          />
        )),
    [
      itemList,
      isWorkFlowMultiList,
      keyString,
      chipCaption,
      disabled,
      handleDelete,
      readOnly,
      visibleCount,
      noPadding,
      noChipFixedWidth,
    ],
  );

  return (
    <StyledListWrapper
      className="multi-select-list-wrapper"
      onClick={handleOpen}
      ref={listContainerRef}
      listHeight={listHeight}
      isDropdownOpen={isDropdownOpen}
      noWrap={withTruncatedChips}
      noPadding={noPadding}
      skipValidation={skipValidation}
    >
      {!itemList?.length && (
        <StyledMultiSelectLabel className="empty-multi-select-chip-placeholder">{placeholder}</StyledMultiSelectLabel>
      )}
      {renderList()}
      {moreItemsCount > 0 && (
        <StyledChip
          className="multi-select-chip truncated-items"
          data-testid="multi-select-truncation-chip"
          variant="outlined"
          label={
            <Tooltip
              placement="top"
              title={truncatedTooltipText}
              text={`+${moreItemsCount}`}
              style={{ fontSize: '0.875rem', lineHeight: 1.2858, color: '#303030' }}
            />
          }
        />
      )}
      {!isDropdownOpen && !skipValidation && (
        <StyledPlusWrapper
          className="plus-button"
          listHeight={parentHeight || listHeight || 0}
          disabled={disabled}
          readOnly={readOnly}
        >
          {isError && !skipValidation ? (
            <WarningIcon className="warning-icon" />
          ) : (
            <PlusAttach data-testid="multiselect-plus-btn" />
          )}
        </StyledPlusWrapper>
      )}
    </StyledListWrapper>
  );
}
