import { styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import { Box } from '@mui/system';

export const StyledSearchField = styled(Box)(({ theme }) => ({
  zIndex: 1,
  backgroundColor: theme.palette.generic.primary,
  borderRadius: '20px',
  border: theme.shape.border.border_2,
  margin: 'auto',
  marginBottom: '8px',
  width: '100%',
  marginTop: '8px',
  fontFamily: theme.fonts.robotoRegular,

  '& .MuiInputBase-input': {
    paddingLeft: '12px',
  },

  '& .MuiInputBase-input::placeholder': {
    fontFamily: theme.fonts.robotoRegular,
    opacity: '1',
    color: theme.palette.grey.bt_def_grey_light,
  },

  '& .MuiInputBase-input[placeholder]': {
    textOverflow: 'ellipsis',
  },

  '&:hover': {
    borderColor: theme.palette.grey.bt_def_grey_reg,
    '& .MuiInputBase-input::placeholder': {
      color: theme.palette.grey.bt_def_grey_reg,
    },
  },
  '& .MuiOutlinedInput-root': {
    height: '30px',
    maxWidth: '100%',
    width: '100%',
  },
  // affects both placeholder text and typed text
  '& .MuiInputBase-root': {
    fontSize: '12px',
    fontFamily: theme.fonts.robotoRegular,
    '& .MuiInputAdornment-root': {
      marginLeft: '0!important',
    },
  },
  //styles on fieldset element
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: '0px',
  },
  '& fieldset[class^="PrivateNotchedOutline-root-"], & fieldset[class*="PrivateNotchedOutline-root-"]': {
    borderWidth: 0,
  },

  '& .MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
}));

export const StyledInputAdornment = styled(InputAdornment)(() => ({
  marginRight: '-10px',
  '&:hover': {
    cursor: 'pointer',
  },
}));
