import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

export const StyledHintTextArea = styled(TextField)(({ theme }) => ({
  marginTop: '8px',
  height: '82px',
  '.MuiFormControl-root': {
    border: theme.shape.border.border,
  },
  '.MuiInputBase-root': {
    overflow: 'hidden',
    padding: '0px 4px 8px 6px',
    lineHeight: '1.5rem',
    '.MuiInputBase-input': {
      paddingRight: '4px',
      marginTop: '4px',
      height: '70px!important',
      overflowY: 'scroll !important',
      lineHeight: '24px',
      color: theme.palette.text.secondary_txt,
      ...theme.customScrollbar,
    },
    '&.MuiOutlinedInput-root': {
      '& fieldset': {
        border: theme.shape.border.border,
      },
    },
  },

  '.MuiFormHelperText-root': {
    position: 'absolute',
    display: 'inline-flex',
    alignSelf: 'end',
    height: '18px',
    bottom: '5px',
    right: '6px',
    margin: '0 2px',
    padding: '2px',
    backgroundColor: theme?.palette?.generic?.primary,
    fontSize: theme?.fontSize?.small,
    color: theme?.palette?.text?.disable_txt,
  },
}));
