import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { theme } from 'common/components/datePickerPopup/style';

export const StyledFileManagerContainer = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  position: 'fixed',
  top: 0,
  right: 0,
  zIndex: theme.zIndex.drawer + 1,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

export const StyledCarouselContainer = styled(Box)(() => ({
  flex: 1,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '.carousel-wrapper': {
    height: '85%',
    width: '85%',
    overflow: 'visible',

    '> div *': {
      height: '99%',
      width: '99%',
    },
  },

  'div:has(img), div:has(video)': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  '.nav-button-wrapper > .MuiIconButton-root': {
    height: 'auto',
    width: '40px',
  },
}));

export const StyledCarouseItem = styled(Box, {
  shouldForwardProp: (prop) => !['visible','width','height', 'fileExtension'].includes(prop),
})(({ visible, fileExtension ,width, height, theme }) => ({
  flexDirection: 'column',
  width: `${width}px !important`,
  height: `${height}px !important`,
  opacity: !visible ? '0' : '',
  overflow: fileExtension === 'pdf' && 'auto',
  backgroundColor: fileExtension === 'text' && 'white',
  '&&.no-preview-item': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  'div.carouse-item-title': {
    color: theme.palette.generic.primary,
    fontSize: theme.fontSize.medium,
    height: 'auto',
    marginTop: '10px',
    textAlign: 'center',
    position: 'relative',
    zIndex: '9',
  },
  '.document-container': {
    flexDirection: 'column',
  },
}));

export const StyledImage = styled('img', {
  visible: (prop) => prop !== 'visible' && prop !== 'zoomAmount' && prop !== 'imageSrc',
})(({ zoomAmount, visible, maxWidth, maxHeight }) => ({
  opacity: !visible ? '0' : '',
  objectFit: 'contain',
  margin: 'none',
  maxWidth,
  maxHeight,
  transform: `scale(${zoomAmount})`,
  transition: 'transform 0.8s',
}));

export const StyledEditFileContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '15px',
  margin: '20px 20px 0;',
  zIndex: '9',
  '.edit-file-button': {
    background: '#FFFFFF',
    width: '30px',
    height: '30px',
    padding: '8px',
    borderRadius: '6px',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',

    ':hover': {
      background: theme.palette.background.bg_hover_alt,
    },
  },
}));

export const StyledZoomContainer = styled(Box)(() => ({
  '&&&&': {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    top: '94%',
    left: 'calc(50% - 51px)',
    margin: 'auto',
    background: '#000000',
    opacity: 0.5,
    height: 'auto',
    zIndex: 9999,
    padding: '8px 18px',
    gap: '18px',
    borderRadius: '5px',

    '.zoom-icon': {
      padding: '2px',
      cursor: 'pointer',
    },
  },
}));

export const StyledNoPreviewContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.generic.primary,
  maxWidth: '490px',
  maxHeight: '430px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '0 100px',
  gap: '20px',
  borderRadius: '10px',
  '> svg.no-preview-icon': {
    height: 'auto',
  },
  '> div.no-preview-text': {
    height: 'auto',
    fontSize: theme.fontSize.medium,
    color: theme.palette.text.secondary_txt,
  },
}));

export const StyledCircularProgress = styled(Box)(() => ({
  '.Loading': {
    position: 'fixed',
    height: '100%',
    width: '100%',
  },

  '&&& .MuiCircularProgress-root': {
    color: '#fff',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

export const StyledVideo = styled('video', {
  shouldForwardProp: (prop) => prop !== 'zoomAmount',
})(({ zoomAmount }) => ({
  objectFit: 'contain',
  margin: 'auto',
  transform: `scale(${zoomAmount})`,
  transition: 'transform 0.8s',
}));
