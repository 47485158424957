/* eslint-disable */
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import Tooltip from 'common/components/tooltip';
import { useTheme } from '@mui/styles';
import { selectActiveUser } from 'store/userSlice';
import { useTicketLockStatus, useUpdateSR } from 'remote-state/ticketServiceHooks';
import { isRequiredField } from 'common/utils/fieldUtils';
import usePendoTrackEvents from 'common/utils/hooks/usePendoTrackEvents';
import { selectActiveView } from 'features/queue/slice';
import { PENDO_TRACK_EVENTS } from 'constants/pendoTrackEvents';
import CellComponent from './CellComponent';
import { BarLoader, UserLoader } from './SkeletonLoader';
import useTexts from '../useTexts';
import useValidateSrLockBeforeUpdate from '../hooks/useValidateSrLockBeforeUpdate';
import { StyledIsNotTemplate } from './style';
import useGetFieldPermissions from '../../../../common/utils/hooks/useGetFieldPermissions';

const CustomLoader = ({ fieldTypeName }) => {
  if (fieldTypeName === 'ASSIGNEE') return <UserLoader />;
  return <BarLoader />;
};

export const DefaultCellRender = (props) => {
  const { column, data, rowIndex, api } = props;
  const activeView = useSelector(selectActiveView);
  const srId = useMemo(() => (data?.length && data?.find((fields) => fields.field === 'id')?.value) || null, [data]);
  const theme = useTheme();
  const { notAvailableField, notAvailableFieldText } = useTexts();
  const { checkAndUpdateSr } = useUpdateSR();
  const { data: lockingDetails } = useTicketLockStatus(srId);
  const userAccount = useSelector(selectActiveUser);
  const username = userAccount?.username;
  const handleSaveSrTimeout = useRef();

  const isLocked = lockingDetails?.isLocked;
  const lockingUser = lockingDetails?.lockingUser;
  const checkLockedUser = isLocked && username !== lockingUser;
  const checkAvailableTicket = isLocked && username === lockingUser;
  const hoverEffectText = useMemo(() => ({ propertyName: 'cursor', value: 'text', priority: 'important' }), []);
  const hoverEffectPointer = useMemo(() => ({ propertyName: 'cursor', value: 'pointer', priority: 'important' }), []);
  const activeField = useMemo(
    () => (data?.length && data?.find((fields) => fields.field === column.colDef.field)) || null,
    [data, column],
  );
  const activeFieldConfig = activeView?.columnsConfig?.columnConfiguration?.find(
    (config) => config.id === column.colDef.fieldId,
  );
  const srStatusValue = useMemo(
    () => (Array.isArray(data) ? data?.find((fields) => fields.field === 'status')?.value : undefined),
    [data],
  );
  const required = isRequiredField(srStatusValue, activeField?.required, activeField?.requiredForStatuses);
  const fieldPermission = useGetFieldPermissions(activeField);
  const fieldNotInTemplate = activeField && !activeField?.inTemplate;
  const disableField = fieldNotInTemplate || activeField?.readOnly || checkLockedUser || fieldPermission?.disabled;

  const pendoTrackEvents = usePendoTrackEvents();

  const handleSaveSR = useCallback(
    async (object) => {
      const objectToUpdate = { id: srId, requestParams: object, queueUpdate: true };
      await checkAndUpdateSr(objectToUpdate);
      // global.queueAbortController?.abort(); // cancel on going requests and delay future requests while grid is not in realtime mode
      // queryClient.invalidateQueries({ queryKey: ['queueData'] });
    },
    [srId, checkAndUpdateSr],
  );

  const { handleUpdateSR } = useValidateSrLockBeforeUpdate({ srId, handleSaveSR });

  const handleInlineFieldChange = useCallback(
    (newObject) => {
      handleUpdateSR(newObject);
      const fieldName = activeFieldConfig?.fieldName;
      const fieldValue = activeField?.richText ? newObject.richTextFields : newObject[Object.keys(newObject)[0]];
      if (activeFieldConfig?.customColumn) {
        pendoTrackEvents(PENDO_TRACK_EVENTS.CUSTOM_COLUMN_POPULATED, {
          location: 'queue',
          srId,
          fieldName,
          fieldValue,
        });
      }
    },
    [handleUpdateSR, pendoTrackEvents, activeField, activeFieldConfig, srId],
  );

  const handleFieldChange = useCallback(
    async (newValue) => {
      const object = { [column.colId]: newValue };
      handleInlineFieldChange(object);
    },
    [handleInlineFieldChange, column.colId],
  );

  useEffect(
    () => () => {
      clearTimeout(handleSaveSrTimeout.current);
    },
    [],
  );

  // Temporary solution for displaying due date field on the queue page.
  // TODO: Fix the rendering condition of the DueDate component, after BE will add a new field.
  const fieldTypeName = useMemo(() => {
    let name = column.colDef.fieldTypeName;
    if (name === 'DATE' && column.colId === 'dueDate') {
      name = 'TIME_REMAINING';
    }
    if (name === 'DATE' && column.colDef.sysaidEntity === 'timers') {
      name = 'TIMER';
    }
    return name;
  }, [column]);

   if (!data?.length) return <CustomLoader fieldTypeName={fieldTypeName} />;

  if (!activeField?.inTemplate && fieldTypeName === 'OPEN_TICKET') {
    return (
      <Tooltip
        title={notAvailableFieldText}
        text={<StyledIsNotTemplate>{notAvailableField}</StyledIsNotTemplate>}
        placement="top"
        style={{
          fontSize: theme.fontSize.main,
          lineHeight: 1.429,
          color: theme.palette.text.txt_default,
        }}
      />
    );
  }

  return (
    <CellComponent
      fieldTypeName={fieldTypeName}
      column={column}
      srId={srId}
      activeField={activeField}
      hoverEffectText={hoverEffectText}
      handleFieldChange={handleFieldChange}
      handleInlineFieldChange={handleInlineFieldChange}
      disableField={disableField}
      notInTemplate={fieldNotInTemplate}
      rowIndex={rowIndex}
      username={username}
      checkAvailableTicket={checkAvailableTicket}
      hoverEffectPointer={hoverEffectPointer}
      data={data}
      nodeId={props?.node?.id}
      // required={required}
      required={false}
      srStatusValue={srStatusValue}
      gridApi={api}
    />
  );
};

export default DefaultCellRender;
