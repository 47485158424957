export const fieldsTypes = {
  1: 'text',
  2: 'longtext',
  3: 'number',
  4: 'date',
  5: 'time_remaining',
  6: 'select',
  7: 'multiselect',
  8: 'attachments',
  9: 'link',
  10: 'decimalnumber',
  11: 'datetime',
  12: 'specialLink',
};
