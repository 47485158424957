import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    incidentDisplayValue: texts?.['spaces.resolutionPanel.incident'],
    requestDisplayValue: texts?.['spaces.resolutionPanel.request'],
    unknownSrType: texts?.['spaces.resolutionPanel.unknownSrType'],
    notSavedModalMessage: texts?.['spaces.changes.fieldsNotSavedModal.description'],
    notSavedByPermissionMessage: texts?.['spaces.changes.fieldsNotSavedModal.noPermission'],
    incomingMessageToaster: texts?.['spaces.resolutionPanel.journey.message.incoming.toaster'],
    previewMode: texts?.['spaces.ticketPanel.previewMode'],
    modal: {
      title: texts?.['spaces.requiredFieldsModal.title'] || 'You left a required field empty',
      description: texts?.['spaces.requiredFieldsModal.description'],
      btnOkText: texts?.['spaces.requiredFieldsModal.OkText'] || 'Leave empty',
      btnCancelText: texts?.['spaces.requiredFieldsModal.CancelText'] || 'Continue editing',
    },
    viewPermissionsTitle: texts?.['spaces.permissions.viewPermissions.serviceRecord.title'],
    viewPermissionsText: texts?.['spaces.permissions.viewPermissions.serviceRecord.text'],
  };

  return componentTexts;
}
