import { MenuItem, Select, styled } from '@mui/material';

export const StyledSelect = styled(Select)(({ theme }) => ({
  fontFamily: 'Roboto-Regular',
  fontSize: theme.spacing(1.75),
  margin: '0',
  '& .MuiSelect-select': {
    paddingLeft: theme.spacing(1),
  },
  '& .MuiList-root': {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '1.2rem',
    color: theme.palette.grey.bt_def_grey_reg,
    display: 'block!important',
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '16px',
  height: '46px !important',
  borderRadius: '6px',

  '&:hover': {
    backgroundColor: theme.palette.grey.bt_alt_grey_light,
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.grey.bt_alt_grey_light,
    '&:hover': {
      backgroundColor: theme.palette.background.pages_background,
    },
  },
}));
