import moment from 'moment-timezone';
import { useEffect, useState, useCallback } from 'react';
import { Popover, TextField, ThemeProvider } from '@mui/material';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enLocale from 'date-fns/locale/en-GB';
import { useUserInfo } from 'remote-state/userServiceHooks';
import { get12HourTimeFormat, getTwoDigits } from 'common/utils/dateTimeUtils';
import { QUERIES_KEYS } from 'constant';
import { theme, StyledPickerWrapper } from './style';
import CustomTimePicker from '../customTimePicker';
import DatePickerActions from '../datePickerActions';
import { CONSTANTS } from '../datePicker/constants';
import useDateTimeInfo from '../../utils/hooks/useDateTimeInfo';

export default function DatePickerPopup(props) {
  const { onClosePopup, date, open, coord, disablePortal, defaultDate, clearValueHandler, isOnlyDateFormat } = props;
  const userInfo = useUserInfo(QUERIES_KEYS.CURRENT_USER_FROM_CORE);

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedHour, setSelectedHour] = useState(0);
  const [selectedMinute, setSelectedMinute] = useState(0);
  const [currentTimeFormat, setCurrentTimeFormat] = useState(null);

  const shouldRenderOnTop = window.innerHeight - coord.bottom - 344 < 10;
  const firstDayOfTheWeek =
    userInfo?.data?.info?.find((item) => item.key === 'firstDayOfWeek')?.value === 'monday' ? 1 : 0;

  const [coords, setCoords] = useState({ top: 0, left: 0 });
  const { timezone, dateFormat, is12HourTimeFormat } = useDateTimeInfo();

  const getDateByTimezoneAndFormat = useCallback(
    (date) => {
      if (date) {
        if (date && dateFormat) {
          const dateTimezone = moment(date).tz(timezone);
          const year = dateTimezone.year();
          const month = dateTimezone.month() + 1;
          const day = dateTimezone.date();
          const hour = dateTimezone.hour();
          const minute = dateTimezone.minute();
          setSelectedDate(new Date(`${month}-${day}-${year}`));
          setSelectedHour(hour);
          setSelectedMinute(minute);
        }
        const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return Number(moment(date).tz(defaultTimezone)?.format(CONSTANTS.BACKEND_AMERICAN_DATE_TIME_FORMAT));
      }
    },
    [timezone, dateFormat],
  );

  useEffect(() => {
    if (!shouldRenderOnTop) {
      setCoords({ top: coord.top + 30, left: coord.left });
    } else {
      setCoords({ top: coord.top - 344, left: coord.left });
    }
  }, [coord, shouldRenderOnTop]);

  useEffect(() => {
    if (date) {
      getDateByTimezoneAndFormat(date);
    } else if (!date) {
      getDateByTimezoneAndFormat(defaultDate);
    }
  }, [date, defaultDate, getDateByTimezoneAndFormat]);

  useEffect(() => {
    if (dateFormat === CONSTANTS.BACKEND_AMERICAN_DATE_TIME_FORMAT) {
      if (selectedHour >= 12 && is12HourTimeFormat()) {
        setCurrentTimeFormat('pm');
      } else {
        setCurrentTimeFormat('am');
      }
    }
  }, [selectedDate, dateFormat, selectedHour, is12HourTimeFormat]);

  const closeHandler = (event, reason) => {
    if (reason === 'escapeKeyDown' || reason === 'backdropClick' || !selectedDate) {
      onClosePopup(date);
    } else {
      // get the date and time the user selected
      const fullYear = selectedDate.getFullYear();
      const fullMonth = getTwoDigits(selectedDate.getMonth() + 1);
      const fullDay = getTwoDigits(selectedDate.getDate());
      const fullHour = getTwoDigits(selectedHour);
      const fullMinute = getTwoDigits(selectedMinute);
      // created date time string from the date time the user selected in order to apply the user's timezone
      const selectedDateStr = `${fullYear}-${fullMonth}-${fullDay} ${fullHour}:${fullMinute}`;
      // create a moment object using the selected date time from the user and user's timezone
      const newDate = moment.tz(selectedDateStr, timezone);
      // get the milliseconds represantation from the selected date time according to the user's timezone
      const newDateMilliseconds = newDate.valueOf();
      onClosePopup(newDateMilliseconds);
    }
  };

  const actionHandler = (isToSaveDate) => {
    if (!isToSaveDate) {
      onClosePopup(date);
    } else {
      closeHandler();
    }
  };

  const renderTextField = (params) => <TextField {...params} />;

  const handleSaveDate = (newDate) => {
    setSelectedDate(newDate);
  };

  const convertHours = () => {
    if (is12HourTimeFormat()) {
      return get12HourTimeFormat(selectedHour);
    }
    return selectedHour;
  };

  const onSelectHour = (hour) => {
    if (currentTimeFormat === 'pm' && hour !== '12') {
      setSelectedHour(Number(hour) + 12);
    } else if (currentTimeFormat === 'am' && hour === '12') {
      setSelectedHour(0);
    } else {
      setSelectedHour(Number(hour));
    }
  };

  const onSelectMinute = (minute) => {
    setSelectedMinute(Number(minute));
  };

  const handleNewTimeFormat = (newFormatValue) => {
    if (currentTimeFormat === 'am' && newFormatValue === 'pm') {
      setSelectedHour(selectedHour + 12);
    } else if (currentTimeFormat === 'pm' && newFormatValue === 'am') {
      setSelectedHour(selectedHour - 12);
    }
    setCurrentTimeFormat(newFormatValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <Popover
        disablePortal={disablePortal}
        id="datepicker-menu"
        anchorReference="anchorPosition"
        anchorPosition={coords}
        open={open}
        onClose={closeHandler}
        sx={{ '.MuiPaper-root': { display: 'flex', flexDirection: 'column' } }}
      >
        <StyledPickerWrapper>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={{ ...enLocale, options: { weekStartsOn: firstDayOfTheWeek, firstWeekContainsDate: 1 } }}
          >
            <StaticDatePicker
              data-testid="date-picker"
              displayStaticWrapperAs="desktop"
              openTo="day"
              value={selectedDate}
              onChange={handleSaveDate}
              renderInput={renderTextField}
            />
          </LocalizationProvider>
          {!isOnlyDateFormat && (
            <CustomTimePicker
              currentTimeFormat={currentTimeFormat}
              onSelectNewTimeFormat={handleNewTimeFormat}
              hour={convertHours()}
              minute={selectedMinute}
              onSelectHour={onSelectHour}
              onSelectMinute={onSelectMinute}
              is12HourTimeFormat={is12HourTimeFormat()}
            />
          )}
        </StyledPickerWrapper>
        <DatePickerActions onAction={actionHandler} clearValueHandler={clearValueHandler} />
      </Popover>
    </ThemeProvider>
  );
}
