import { InputAdornment, TextField } from '@mui/material';
import PropTypes from 'prop-types';

import { StyledSearchBar } from './style';

export const SearchBar = ({
  placeholder,
  searchIcon,
  clearIcon,
  handleChange,
  value,
  handleClear,
  size,
  autoFocus,
}) => (
  <StyledSearchBar value={value} size={size}>
    <TextField
      variant="standard"
      placeholder={placeholder}
      autoFocus={autoFocus}
      InputProps={{
        disableUnderline: true,
        autoComplete: 'off',
        endAdornment: (
          <InputAdornment position="end" onClick={handleClear}>
            {value ? clearIcon : searchIcon}
          </InputAdornment>
        ),
      }}
      value={value}
      onChange={handleChange}
    />
  </StyledSearchBar>
);

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  searchIcon: PropTypes.element,
  clearIcon: PropTypes.element,
  handleChange: PropTypes.func,
  value: PropTypes.string,
  handleClear: PropTypes.func,
  size: PropTypes.oneOf(['medium', 'big']),
};

SearchBar.defaultProps = {
  placeholder: 'Search...',
  searchIcon: null,
  clearIcon: null,
  handleChange: () => {},
  value: '',
  handleClear: () => {},
  size: 'medium',
};
