import { useEffect, useReducer, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Popper } from '@mui/material';
import { ClickAwayListener } from '@mui/base';

import { DetailedAvatar } from 'common/components/avatars/detailedAvatar';
import MessagePrompt from 'common/components/messagePrompt';
import { useUserInfo, useMeMenuDetails } from 'remote-state/userServiceHooks';
import { QUERIES_KEYS } from 'constant';
import { AVATAR_CONSTANTS, PAGE_VALIDATION_CONSTANTS } from 'constants/index';
import MeMenu from 'features/navBar/meMenu';
import { ME_MENU_ACTIONS } from 'features/navBar/meMenu/constants';
import useTexts from 'features/navBar/meMenu/useTexts';
import { ReactComponent as WarningIcon } from 'images/icons/WarningSign.svg';
import { setPageValidations } from 'store/pageValidationSlice';
import NavItem from '..';
import { StyledBackdrop } from './styles';

const navItemUserReducer = (state, action) => {
  switch (action.type) {
    case ME_MENU_ACTIONS.CLOSE_MESSAGE_PROMPT:
      return {
        ...state,
        isMessagePromptOpen: false,
      };
    case ME_MENU_ACTIONS.MENU_DIRTY:
      return {
        ...state,
        isMenuDirty: action.payload,
      };
    case ME_MENU_ACTIONS.CLOSE_MENU:
      return {
        ...state,
        isMessagePromptOpen: state.isMenuDirty,
        isMenuOpen: state.isMenuDirty,
      };
    case ME_MENU_ACTIONS.TOGGLE:
      return {
        ...state,
        isMessagePromptOpen: state.isMenuDirty,
        isMenuOpen: state.isMenuDirty ? true : !state.isMenuOpen,
      };
    case ME_MENU_ACTIONS.SAVE:
      return {
        ...state,
        isMenuDirty: false,
      };
    case ME_MENU_ACTIONS.RESET:
      return {
        ...state,
        isMenuOpen: false,
        isMessagePromptOpen: false,
        isMenuDirty: false,
      };
    default:
      return state;
  }
};

export default function NavItemUser({ expanded }) {
  const dispatch = useDispatch();
  const [menuState, updateMenuState] = useReducer(navItemUserReducer, {
    isMenuOpen: false,
    isMessagePromptOpen: false,
    isMenuDirty: false,
  });
  const { promptTitle, promptDescription, promptOkBtnText, promptCancelBtnText } = useTexts();

  useEffect(() => {
    dispatch(
      setPageValidations({
        component: `${PAGE_VALIDATION_CONSTANTS.ME_MENU}`,
        validationDetails: {
          isValid: !menuState.isMenuDirty,
          promptTexts: menuState.isMenuDirty && {
            title: promptTitle,
            description: promptDescription,
            btnOkText: promptOkBtnText,
            btnCancelText: promptCancelBtnText,
          },
        },
      }),
    );
  }, [menuState.isMenuDirty, dispatch, promptTitle, promptDescription, promptOkBtnText, promptCancelBtnText]);

  const { meMenuDetails, updateMeMenuDetails, isLoading } = useMeMenuDetails();
  const { data: profileImage } = useUserInfo(QUERIES_KEYS.PHOTO);
  const anchorRef = useRef(null);
  const [height, setHeight] = useState(window.innerHeight);

  const discardChanges = () => {
    updateMenuState({ type: ME_MENU_ACTIONS.RESET });
  };
  useEffect(() => {
    const setInnerHeight = () => setHeight(window.innerHeight);
    window.addEventListener('resize', setInnerHeight);
    return () => window.removeEventListener('resize', setInnerHeight);
  }, []);
  // is called on okay click in the pageValidation prompt
  if (!window.closeMeMenu) window.closeMeMenu = discardChanges;

  const shouldIgnoreClickAway = (target) => {
    const targetClasses = [...target.classList];

    return (
      // clicking on the portaled select input component within the menu
      // when clicking nav item links
      // we need to ignore this in order to not get a double prompt (clickAway + pageValidation)
      target.localName === 'body' ||
      // clicking on assign input component within the menu
      Boolean(target.closest('.assignee-menu')) ||
      // clicking on the list items in the select dropdown
      Boolean(target.closest('[id="select-menu-list"]')) ||
      // clicking away from the select dropdown
      targetClasses.includes('MuiBackdrop-invisible') ||
      // interacting with the prompt
      Boolean(target.closest('.MuiDialog-root')) ||
      (menuState.isMenuDirty &&
        (Boolean(target.closest('.nav-item-link')) || Boolean(target.closest('#open-new-ticket'))))
    );
  };

  const handleClickAway = (e) => {
    if (shouldIgnoreClickAway(e.target)) return;

    updateMenuState({ type: ME_MENU_ACTIONS.CLOSE_MENU });
  };
  const userAvatar = (
    <DetailedAvatar
      size={AVATAR_CONSTANTS.SIZES.BIG}
      userName={meMenuDetails?.userName}
      previewName={meMenuDetails?.calculatedUserName}
      profileImage={profileImage}
      popper={{ sx: { paddingLeft: '36px' }, placement: 'bottom-end' }}
    />
  );
  return (
    !isLoading && (
      <>
        <NavItem
          classNames="user"
          id="user"
          title={meMenuDetails?.calculatedUserName}
          icon={userAvatar}
          expanded={expanded}
          isActive={menuState.isMenuOpen}
          handleClick={() => updateMenuState({ type: ME_MENU_ACTIONS.TOGGLE })}
          disableTooltip
          ref={anchorRef}
        />
        <StyledBackdrop open={menuState.isMenuOpen} expanded={expanded} />
        <Popper
          open={menuState.isMenuOpen}
          anchorEl={anchorRef.current}
          placement="right"
          sx={height > 960 ? { marginTop: '-30px !important' } : { marginTop: 'unset!important', overflow: 'hidden' }}
        >
          {() => (
            <ClickAwayListener onClickAway={handleClickAway}>
              <MeMenu
                user={meMenuDetails}
                menuState={menuState}
                updateMenuState={updateMenuState}
                updateMeMenuDetails={updateMeMenuDetails}
                ref={anchorRef.current}
              />
            </ClickAwayListener>
          )}
        </Popper>
        <MessagePrompt
          open={menuState.isMessagePromptOpen}
          Icon={WarningIcon}
          title={promptTitle}
          onOkClick={discardChanges}
          btnOkText={promptOkBtnText}
          showCancelBtn
          onClose={() => updateMenuState({ type: ME_MENU_ACTIONS.CLOSE_MESSAGE_PROMPT })}
          btnCancelText={promptCancelBtnText}
        >
          {promptDescription}
        </MessagePrompt>
      </>
    )
  );
}
