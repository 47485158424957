import { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDuplicateTemplate } from 'remote-state/templateHooks';
import { setToasterMessage } from 'store/globalSlice';
import { selectActiveUser } from 'store/userSlice';
import MenuItem from '@mui/material/MenuItem';
import { useUserInfo } from 'remote-state/userServiceHooks';
import { DropDownMenu } from 'common/components/dropDownMenu';
import { ReactComponent as PreviewIcon } from 'images/icons/preview.svg';
import { ReactComponent as DuplicateIcon } from 'images/icons/duplicate.svg';
import { APP_CONSTANTS } from 'constants/app';
import { canDeleteTemplate } from 'features/templateBuilder/utils/utils';
import { QUERIES_KEYS } from 'constant';
import usePendoTrackEvents from 'common/utils/hooks/usePendoTrackEvents';
import TemplateSetAsDefaultButton from '../../templateBuilder/TemplateSetAsDefaultButton';
import TemplateDeleteButton from '../../templateBuilder/TemplateDeleteButton';
import { StyledTemplateButton } from '../../templateBuilder/StyledTemplateButton';
import useTexts from '../../templateBuilder/useTexts';
import { TICKETS_OPEN_URL, PENDO_TRACK_EVENTS } from '../../../constants';

export default function TemplateListActions({ templateObject, templateList }) {
  const dispatch = useDispatch();
  const userAccount = useSelector(selectActiveUser);
  const { data: userPermissions } = useUserInfo(QUERIES_KEYS.CURRENT_USER_PERMISSIONS);
  const [menuOpen, setMenuOpen] = useState(false);
  const { duplicateText, duplicateSuccessText, duplicateErrorText, templatePreviewText } = useTexts();
  const { mutate: duplicateTemplate, status: duplicateStatus } = useDuplicateTemplate();
  const pendoTrackEvents = usePendoTrackEvents();
  const templateId = templateObject?.id;
  const handleOpenMenu = useCallback((val) => {
    setMenuOpen(val);
  }, []);

  const defaultTemplate = useMemo(
    () => templateList.find((option) => option.default && option.srType === templateObject.srType),
    [templateObject, templateList],
  );

  const handleDuplicate = (e) => {
    pendoTrackEvents(PENDO_TRACK_EVENTS.DOPLICATE_TEMPLATE_WAS_CLICKED, {
      updateUser: userAccount?.username,
      templateId,
    });
    e.preventDefault();
    e.stopPropagation();
    duplicateTemplate({ updateUser: userAccount?.username, templateId });
    setMenuOpen(false);
  };
  const handlePreview = (e) => {
    e.preventDefault();
    e.stopPropagation();
    sessionStorage.setItem(APP_CONSTANTS.PREVIEW_TEMPLATE_OR_ID_KEY, templateId);
    window.open(TICKETS_OPEN_URL.SPACES('preview'), '_blank');
  };

  useEffect(() => {
    if (duplicateStatus === 'success') {
      dispatch(setToasterMessage({ message: duplicateSuccessText }));
    }
    if (duplicateStatus === 'error') {
      dispatch(setToasterMessage({ message: duplicateErrorText, type: 'error' }));
    }
  }, [dispatch, duplicateStatus, duplicateSuccessText, duplicateErrorText]);

  const isDuplicateDisabled = duplicateStatus === 'loading';
  return (
    <div className="list-actions">
      <StyledTemplateButton
        data-testid="template-preview-button"
        className="template-preview-button"
        startIcon={<PreviewIcon />}
        onClick={handlePreview}
        shouldhover={false}
      >
        {templatePreviewText}
      </StyledTemplateButton>
      <DropDownMenu data-cy={`template-actions-${templateId}`} handleOpen={handleOpenMenu} open={menuOpen}>
        {!templateObject.default && (
          <MenuItem data-cy={`set-as-default-template-action-${templateId}`}>
            <TemplateSetAsDefaultButton
              newTemplate={templateObject}
              oldTemplate={defaultTemplate}
              handleOpenMenu={handleOpenMenu}
            />
          </MenuItem>
        )}
        <MenuItem data-cy="duplicate-template-action">
          <StyledTemplateButton
            onClick={handleDuplicate}
            disabled={isDuplicateDisabled}
            startIcon={<DuplicateIcon />}
            shouldhover={false}
            fullWidth
          >
            {duplicateText}
          </StyledTemplateButton>
        </MenuItem>
        {canDeleteTemplate({ template: templateObject, userPermissions }) && (
          <MenuItem data-cy="delete-template-action">
            <TemplateDeleteButton templateId={templateId} handleOpenMenu={handleOpenMenu} />
          </MenuItem>
        )}
      </DropDownMenu>
    </div>
  );
}
