import { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import useClickAway from 'common/utils/hooks/useClickAway';
import { useRbTexts } from 'remote-state/applicationHooks';
import { ReactComponent as KebabMenuIcon } from 'images/icons/kebabMenu.svg';
import { StyledKebabMenu, StyledMenu, StyledActionItem } from './style';

export const KebabMenu = ({ actionList, isVisible, handleKebabMenuAction }) => {
  const texts = useRbTexts();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const kebabMenuRef = useRef();

  useEffect(() => {
    if (!isVisible) {
      setIsMenuOpen(false);
    }
  }, [isVisible]);

  useClickAway(kebabMenuRef, () => {
    setIsMenuOpen(false);
  });

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const handleClickAction = (actionName) => {
    setIsMenuOpen(false);
    handleKebabMenuAction?.(actionName);
  };

  return (
    <StyledKebabMenu isVisible={isVisible} ref={kebabMenuRef} isOpen={isMenuOpen}>
      <Box className="kebab-wrapper" onClick={toggleMenu} data-testid="kebab-menu-button">
        <KebabMenuIcon className="kebab-icon" />
        {isMenuOpen && (
          <StyledMenu>
            {!!actionList?.length &&
              actionList?.map((action) => (
                <StyledActionItem key={action.name} onClick={() => handleClickAction(action.name)}>
                  {action.icon && <img src={action.icon} alt={action.name} className="menu-action-img" />}
                  <span>{texts[action.translatedCaption] || action.defaultCaption}</span>
                </StyledActionItem>
              ))}
          </StyledMenu>
        )}
      </Box>
    </StyledKebabMenu>
  );
};
