import { styled as muistyled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledAssigneeButton = muistyled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',

  '& .userAccountText': {
    margin: '8px 0 0px 4px',
    fontSize: '14px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: theme.palette.text.secondary_txt,
  },
}));
