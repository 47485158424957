import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledSearchFiled = styled(Box)(() => ({
  margin: '0px 24px 0px 0px',
  width: '240px',
  '.queue-searchbox': {
    '.MuiOutlinedInput-root': {
      width: '100%',
      maxWidth: '240px',
      height: '32px',
    },
  },
}));
