import sysaidLogo from 'images/photos/sysaid-logo-white.png';
import { StyledLeftSideUpperLogo } from './style';

export default function LeftSideUpperLogo() {
  return (
    <StyledLeftSideUpperLogo>
      <img id="sysaid-logo-top" alt="sysaid-logo-top" src={sysaidLogo} />
    </StyledLeftSideUpperLogo>
  );
}
