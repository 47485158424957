import { useRef } from 'react';
import { ReactComponent as FilterIconSvg } from 'images/icons/gh_filter.svg';
import Tooltip from 'common/components/tooltip';
import { StyledFilterIcon } from './style';
import useTexts from '../useTexts';

const FilterIcon = ({ onClick: onClickProp, className = '', disabled = false }) => {
  const buttonRef = useRef(null);
  const handleClick = (e) => {
    if (onClickProp) {
      onClickProp(buttonRef.current, e);
    }
  };

  const { filterCustomFieldExceeded } = useTexts();
  const filterIcon = <StyledFilterIcon onClick={!disabled ? handleClick : undefined} data-testid="filter-icon" className={`FilterIcon ${className}`}>
    <FilterIconSvg ref={buttonRef} />
  </StyledFilterIcon>;

  return (
    <Tooltip
      text={filterIcon}
      title={filterCustomFieldExceeded}
      disableHoverListener={!disabled}
    />
  );
};
export default FilterIcon;
