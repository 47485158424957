import { v4 as uuidv4 } from 'uuid';

export const headersTextKeys = [
  {
    key: uuidv4(),
    value: 'historyTimestampText',
  },
  {
    key: uuidv4(),
    value: 'historyUserText',
  },
  {
    key: uuidv4(),
    value: 'historyChangeText',
  },
  {
    key: uuidv4(),
    value: 'historyDescriptionText',
  },
];

export const columnsKeys = [
  {
    key: uuidv4(),
    value: 'logTime',
  },
  {
    key: uuidv4(),
    value: 'userName',
  },
  {
    key: uuidv4(),
    value: 'logType',
  },
  {
    key: uuidv4(),
    value: 'logDescription',
  },
];
