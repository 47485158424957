import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledPreviewModeMessageWrapper = styled(Box)(() => ({
  zIndex: 5,
  position: 'fixed',
  left: '50%',
  top: '20px',
  transform: 'translateX(-50%)',
  '& svg': {
    width: '36px',
    height: '36px',
  },
}));
