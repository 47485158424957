import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {
    username: null,
    roles: [],
    language: 'en',
    isAuth: false,
    calculatedUserName: null,
    userId: -1,
    isAdmin: false,
    isSysAidAdmin: false,
    calculatedRole: '',
    isUserLoading: true,
  },
  details: null,
  shouldDisplayLockoutMessage: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,

  reducers: {
    login: (state, action) => {
      state.user = action.payload;
    },
    logout: (state) => {
      state.user = null;
    },
    setUserDetails: (state, action) => {
      state.details = action.payload;
    },
    setShouldDisplayLockoutMessage: (state, action) => {
      state.shouldDisplayLockoutMessage = action.payload;
    },
    setLoginUrlObject: (state, action) => {
      state.loginUrlObject = action.payload;
    },
    toggleIsUserLoading: (state, action) => {
      state.user.isUserLoading = action.payload;
    },
  },
});

export const { login, logout, setUserDetails, setShouldDisplayLockoutMessage, setLoginUrlObject, toggleIsUserLoading } =
  userSlice.actions;

export const selectActiveUser = (state) => state.user?.user;
export const details = (state) => state.user?.details;
export const activeUsername = (state) => state.user?.user?.username;
export const shouldDisplayLockoutMessage = (state) => state.shouldDisplayLockoutMessage;

export default userSlice.reducer;
export const userSliceName = userSlice.name;
