import { styled } from '@mui/material/styles';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  List,
  ListItem,
} from '@mui/material';

import { BasicAvatar } from 'common/components/avatars/basicAvatar';
import Backdrop from 'common/components/backdrop';
import { FileInputLabel } from 'common/components/uploadFile/uploadButton/fileInputLabel';
import XButton from 'common/components/xButton';
import ButtonBase from 'common/components/buttons/FormButton';

export const StyledXButton = styled(XButton)(() => ({
  ' .MuiIconButton-root': {
    padding: 0,
  },

  svg: {
    height: '20px',
    width: '20px',
  },
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '.MuiPaper-root': {
    padding: theme.spacing(3),
    width: '460px',
  },
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  color: theme.palette.text.title_txt,
  fontSize: '1.125rem',
  lineHeight: 1.333,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: 0,
  paddingBottom: theme.spacing(1),
}));

export const StyledDialogContentText = styled(DialogContentText)(({ theme }) => ({
  color: theme.palette.text.secondary_txt,
  fontSize: '.875rem',
  lineHeight: 1.7143,
  padding: 0,
  marginBottom: theme.spacing(1.5),
}));

export const StyledDialogContent = styled(DialogContent)(() => ({
  padding: 0,

  '.reactEasyCrop_Container': {
    width: '328px',
    height: '314px',
    position: 'relative',
    margin: 'auto',
  },
}));

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: 0,
  marginTop: theme.spacing(4.5),
}));

export const StyledList = styled(List)(() => ({
  listStyleType: 'disc',

  '& .MuiListItem-root': {
    padding: 0,
  },
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: 'list-item',
  marginInlineStart: theme.spacing(3),
  width: `calc(100% - ${theme.spacing(3)})`,

  '&.subheader': {
    margin: 0,
  },
  '.MuiTypography-root': {
    fontSize: theme.fontSize.main,
    lineHeight: 1.75,
  },
}));

export const StyledBackdrop = styled(Backdrop)(() => ({}));

export const StyledUserAvatar = styled(BasicAvatar)(() => ({
  '&&': {
    height: '240px',
    width: '240px',
    border: 'none',
    boxShadow: 'none',
    fontSize: '12rem',
  },
}));

export const StyledUploadButton = styled(FileInputLabel)(() => ({
  margin: '0 100px',
  width: '240px',
  height: '240px',
  borderRadius: '50%',

  '.MuiInputBase-root': {
    display: 'inherit',
  },
}));

export const StyledButton = styled(ButtonBase)(({ theme }) => ({
  '&.btn-primary path': {
    stroke: theme.palette.generic.primary,
  },

  '&.btn-secondary path': {
    stroke: theme.palette.generic.sysaid_default,
  },
}));

export const StyledAttachmentFile = styled(Box)(() => ({
  width: '100%',
  height: '260px',
  padding: '10px',
}));

export const StyledAttachmentFileLabel = styled('span')(({ theme }) => ({
  color: theme.palette.text.blue_user_name,
  cursor: 'pointer',
  fontSize: theme?.fontSize?.medium,
}));

export const StyleUploadError = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  color: theme.palette.labels.bt_red_reg,
  marginTop: '5px',
}));
