import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useRouter } from '@tanstack/react-router';
import { selectError, setError } from 'store/globalSlice';
import { timerService } from 'services/timerService';
import { useQueryClient } from '@tanstack/react-query';
import usePreviousValue from 'common/utils/hooks/usePreviousValue';
import ToasterMessage from 'common/components/toasterMessage';
import MessagePromptWrapper from 'common/components/messagePrompt/wrapper';
import { useUserInfo } from 'remote-state/userServiceHooks';
import { buildLoginUrl } from 'common/utils/utils';
import { ROUTES_PATHS } from 'constants/index';
import FloatingTicketPanelManager from 'features/floatingTicketPanel/floatingTicketPanelManager';
import ErrorPage from 'features/errorPage';
import { QUERIES_KEYS } from 'constant';
import { SR_TYPE_FIELD_ID } from '../queue/grid/constants';

export default function MainPanel() {
  const error = useSelector(selectError);
  const dispatch = useDispatch();
  const router = useRouter();
  const queryClient = useQueryClient();
  const srTypeFieldId = SR_TYPE_FIELD_ID;
  const { isEnabled } = useUserInfo(QUERIES_KEYS.CURRENT_USER, true);
  //If useUserInfo doesn`t enabled it means that there is no any session information and its
  //and we need to process redirections here not on the lower level where will be shown and rendered inital page while redirecting taking place
  if (!isEnabled) {
    const currentUrl = window.location.href;
    const isAtLoginPage = currentUrl.includes(`${ROUTES_PATHS.LOGIN_URL}`);
    //If user is already in login page no need to redirecting him. Pass him throught router to render login page.
    if (!isAtLoginPage) {
      const pathToLogin = buildLoginUrl();
      window.location.href = pathToLogin;
    }
  }

  useEffect(() => {
    if (!timerService.isTimerActive) {
      timerService.init();
    }
    return () => {
      timerService.clear();
    };
  }, []);

  const inIframe = useCallback(() => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }, []);
  useEffect(() => {
    if (!inIframe()) {
      queryClient.invalidateQueries({ queryKey: [QUERIES_KEYS.APPLICATION_DATA] });
      queryClient.invalidateQueries({ queryKey: [QUERIES_KEYS.RESOURCE_BUNDLE] });
      queryClient.invalidateQueries({ queryKey: [QUERIES_KEYS.FIELD_ATTRIBUTE, srTypeFieldId] });
    }
  }, [queryClient, inIframe, srTypeFieldId]);

  const prevLocation = usePreviousValue(router.latestLocation.href);

  useEffect(() => {
    if (error && prevLocation && prevLocation !== router.latestLocation.href) {
      dispatch(setError(null));
    }
  }, [error, prevLocation, router.latestLocation.href, dispatch]);

  return (
    <>
      {error ? (
        <ErrorPage type={error} />
      ) : (
        <>
          <Outlet />
          <ToasterMessage />
          <MessagePromptWrapper />
          <FloatingTicketPanelManager />
        </>
      )}
    </>
  );
}
