import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { selectActiveView, selectDefaultView, setActiveView, setDefaultView } from 'features/queue/slice';
import { getAllViews, deleteView, createView, updateView, setViewAsDefault } from 'services/queueViewsService';
import { convertViewColumnsConfig } from 'features/queue/utils';

export function useQueueViews() {
  const dispatch = useDispatch();

  const activeView = useSelector(selectActiveView);

  const queryKey = 'queueViews';
  const { data, ...rest } = useQuery([queryKey], () => getAllViews(), {
    staleTime: 1000 * 60 * 15,
    placeholderData: [],
    select: (allViews) =>
      allViews.map((view) => ({ ...view, columnsConfig: convertViewColumnsConfig(view.columnsConfig) })),
  });

  useEffect(() => {
    if (!activeView?.id) {
      const defaultView = data?.find((view) => view.default);
      if (defaultView) {
        const convertedView = { ...defaultView, columnsConfig: convertViewColumnsConfig(defaultView.columnsConfig) };
        dispatch(setDefaultView(convertedView));
        dispatch(setActiveView(convertedView));
      }
    }
  }, [data, activeView, dispatch]);

  return {
    data,
    ...rest,
  };
}

export const useDeleteQueueView = (id) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const defaultView = useSelector(selectDefaultView);
  const deleteQueueView = useMutation(deleteView, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['queueViews', id] });
      if (defaultView) {
        dispatch(setActiveView(defaultView));
      }
    },
  });
  return deleteQueueView;
};

export const useCreateQueueView = (id) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const createQueueView = useMutation(createView, {
    onSuccess: (data) => {
      queryClient.setQueryData(['queueViews', id]);
      queryClient.invalidateQueries({ queryKey: ['queueViews', id] });
      dispatch(setActiveView({ ...data, columnsConfig: convertViewColumnsConfig(data.columnsConfig) }));
    },
  });
  return createQueueView;
};

export const useSetViewAsDefault = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const deleteQueueView = useMutation(setViewAsDefault, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['queueViews'] });
      const convertedView = { ...data, columnsConfig: convertViewColumnsConfig(data.columnsConfig) };

      dispatch(setDefaultView(convertedView));
      dispatch(setActiveView(convertedView));
    },
  });
  return deleteQueueView;
};

export const useUpdateQueueView = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const updateQueueView = useMutation(updateView, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['queueViews'] });
      queryClient.setQueryData(['queueViews', data?.id]);

      const convertedView = { ...data, columnsConfig: convertViewColumnsConfig(data.columnsConfig) };

      if (data?.default) {
        dispatch(setDefaultView(convertedView));
      }
      //check if its default replace the session storage default view with the new one
      dispatch(setActiveView(convertedView));
    },
  });
  return updateQueueView;
};
