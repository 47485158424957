import Priority from 'common/components/priority';
import Categories from '../categories';

function HeaderTags(props) {
  const {
    srType,
    handleSaveValue,
    categoryRequired,
    priorityRequired,
    priority,
    priorityList,
    dataset,
    headerFields,
    isTemplatePage = false,
    isPriorityDisabled,
    isCategoryDisabled,
    handleDirty,
    onFocus,
    dataTestid = 'priority',
    className = '',
    updateFieldValidation,
    categoriesValues,
    forceSrId = null,
    updateOnExit,
    onTemplateChanged,
  } = props;

  const handleSave = (e) => {
    handleSaveValue(e);
    updateOnExit?.(true);
  };

  return (
    <div className={`header-footer-div ${className}`}>
      <Priority
        className={className}
        dataTestid={dataTestid}
        priority={priority}
        disabled={isPriorityDisabled}
        onFocus={onFocus}
        onChange={handleSave}
        priorityList={priorityList}
        required={priorityRequired}
        dataset={dataset}
        fieldId={headerFields?.find((field) => field.fieldName === 'priority')?.fieldId}
        handleDirty={handleDirty}
        headerFields={headerFields}
        isTemplatePage={isTemplatePage}
        updateFieldValidation={updateFieldValidation}
      />
      <Categories
        className={className}
        disabled={isCategoryDisabled}
        required={categoryRequired}
        dataset={dataset}
        headerFields={headerFields}
        srType={srType}
        handleDirty={handleDirty}
        isTemplatePage={isTemplatePage}
        onFocus={onFocus}
        handleSaveValue={handleSave}
        updateFieldValidation={updateFieldValidation}
        defaultValues={categoriesValues}
        forceSrId={forceSrId}
        onTemplateChanged={onTemplateChanged}
      />
    </div>
  );
}
export default HeaderTags;
