import { ButtonUnstyled } from '@mui/core';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledLinkItemProcess = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

export const StyledForm = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '4px',
  border: `1px solid ${theme?.palette?.grey?.divider_grey_v1}!important`,
  padding: '24px',
  width: '100%',
  '.more-action-button': {
    padding: '6px 8px',
  },
}));

export const StyledSubTitle = styled(Box)(({ theme }) => ({
  fontFamily: 'Roboto-Regular',
  fontSize: '12px',
  lineHeight: '16px',
  color: theme?.palette?.text?.secondary_txt,
  paddingLeft: '8px',
  marginTop: '6px',
}));

export const StyledDropdownItem = styled(Box)(({ theme }) => ({
  color: theme?.palette?.text?.title_txt,
  padding: '9px 8px !important',
  fontFamily: 'Roboto-Regular',
  fontSize: theme?.fontSize?.main,
}));

export const StyledButtons = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  padding: '12px 0px 0px 0px',
  justifyContent: 'end',
  alignItems: 'center',
  gap: '8px',
}));

export const StyledLinkBtn = styled(ButtonUnstyled)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  background: theme?.palette?.generic?.sysaid,
  fontFamily: theme?.fonts?.robotoRegular,
  borderRadius: '60px',
  color: 'white',
  padding: '8px 16px',
  height: '32px',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '16px',
  lineHeight: '16px',
  cursor: 'pointer',
  outline: 'none',
  border: 'none',
  ':hover': {
    backgroundColor: theme?.palette?.generic?.sysaid_default_hover,
  },
  ':disabled': {
    backgroundColor: theme?.palette?.generic?.sysaid_mid,
  },
}));

export const StyledCancelBtn = styled(ButtonUnstyled)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px 16px',
  height: '32px',
  background: theme?.palette?.grey?.bt_alt_grey_reg,
  fontFamily: theme?.fonts?.robotoRegular,
  borderRadius: '60px',
  color: theme?.palette?.generic?.sysaid_default,
  fontSize: '16px',
  lineHeight: '16px',
  cursor: 'pointer',
  outline: 'none',
  border: 'none',
  ':hover': {
    color: theme?.palette?.generic?.sysaid_default_hover,
    backgroundColor: theme?.palette?.grey?.bt_alt_grey_hover,
  },
}));
