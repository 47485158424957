import { MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledNavSubListItem = styled(MenuItem)(({ theme }) => ({
  padding: '12px 24px',
  width: '224px',
  boxSizing: 'border-box',

  a: {
    textDecoration: 'none',
  },

  '&:has(.menu-title):hover': {
    backgroundColor: 'unset',
    cursor: 'unset',
  },

  '& .menu-title': {
    color: theme.palette.text.title_txt,
    lineHeight: 1.25,
    fontFamily: 'Roboto-Bold',
    fontSize: '16px',
  },

  '&:has(.menu-item):hover': {
    backgroundColor: theme.palette.background.selected,
  },

  '& .menu-item': {
    color: theme.palette.text.title_txt,
    fontFamily: 'Roboto-Regular',
    fontSize: '14px',
    lineHeight: 1.429,
  },

  '& .icon': {
    paddingRight: '12px',
    display: 'flex',
  },
}));

export default StyledNavSubListItem;
