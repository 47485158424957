import { useEffect, useState, useRef, useCallback, memo } from 'react';
import { InputAdornment } from '@mui/material';
import useDelayHover from 'common/utils/hooks/useDelayHover';
import useDateTimeInfo from 'common/utils/hooks/useDateTimeInfo';
import usePreviousValue from 'common/utils/hooks/usePreviousValue';
import { getDate } from 'common/utils/dateTimeUtils';
import { SR_PANEL_CONSTANTS } from 'features/srPanel/consts';
import MissingTemplateTooltip from 'features/queue/grid/defaultCellRender/CellComponent/MissingTemplateTooltip';
import DatePickerPopup from '../datePickerPopup';
import { ReactComponent as OpenPickerIcon } from '../../../images/icons/icon_Schedule.svg';
import { StyledTooltip } from '../controls/Tooltip/Tooltip';
import { EmptyDatePicker } from './EmptyDatePicker';
import { getDisplayDateFormat } from './utils';
import { StyledDatePicker, DatePickerContainer, StyledMuiTextField } from './style';
import { CONSTANTS } from './constants';

function DatePicker({
  date = null,
  error,
  ticket,
  unread,
  source,
  onFocus,
  required,
  disabled,
  onChange,
  fieldName,
  fieldType,
  fullwidth,
  isNewField,
  defaultDate,
  placeholder,
  hoverEffect,
  delayedHover,
  disablePortal,
  isTemplatePage,
  isCustomDateFilter,
  updateFieldValidation,
  emptyValueTooltip,
  notInTemplate,
  isSROpenedFromQueue,
}) {
  const [selectedDate, setSelectedDate] = useState(date);
  const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);
  const [isError, setIsError] = useState(error);
  const datePickerRef = useRef();
  const hoverRef = useRef();
  useDelayHover(hoverRef, delayedHover, hoverEffect);
  const { timezone, dateFormat } = useDateTimeInfo();
  const isOnlyDateFormat =
    fieldName !== 'insertTime' &&
    (fieldType === SR_PANEL_CONSTANTS.DATE ||
      isCustomDateFilter ||
      dateFormat === CONSTANTS.AMERICAN_DATE_FORMAT ||
      dateFormat === CONSTANTS.EUROPIAN_DATE_FORMAT);
  const displayDateFormat = getDisplayDateFormat(isOnlyDateFormat, dateFormat);
  const stringDate = selectedDate ? getDate(timezone, displayDateFormat, selectedDate) : '';

  const prevDate = usePreviousValue(date);

  useEffect(() => {
    if (prevDate !== date && date !== selectedDate) {
      setSelectedDate(date);
    }
  }, [prevDate, date, selectedDate]);

  useEffect(() => {
    setIsError(error);
  }, [error]);

  const checkAndUpdateValidationStatus = useCallback(
    (value) => {
      let isValid = true;
      const isEmpty = !value;
      if (isEmpty && required) {
        isValid = false;
      }
      if (updateFieldValidation) {
        updateFieldValidation(fieldName, required, isValid);
      }
      setIsError(!isValid);
      return isValid;
    },
    [fieldName, required, updateFieldValidation],
  );

  useEffect(() => {
    checkAndUpdateValidationStatus(selectedDate);
  }, [selectedDate, checkAndUpdateValidationStatus]);

  const openDatePopup = () => {
    if (!disabled) {
      if (!isDatePopupOpen && onFocus) {
        onFocus();
      }
      setIsDatePopupOpen((prev) => !prev);
    }
  };

  const handleChangeDate = (newDate) => {
    checkAndUpdateValidationStatus(newDate);
    if (date !== newDate) {
      setSelectedDate(newDate);
      if (onChange) {
        onChange(newDate);
      }
    }
  };

  const onClosePopup = () => {
    setIsDatePopupOpen(false);
  };

  const closeDatePopupHandler = (newSelectedDate) => {
    handleChangeDate(newSelectedDate);
    onClosePopup();
  };

  const clearDatePicker = () => {
    closeDatePopupHandler(null);
  };

  const isEmptyRequiredValue = required && !stringDate;

  const datepicker = () => (
    <StyledDatePicker
      ref={hoverRef}
      isCustomDateFilter={isCustomDateFilter}
      fullwidth={fullwidth}
      disabled={disabled}
      isEmptyRequiredValue={isEmptyRequiredValue}
    >
      <DatePickerContainer isCustomDateFilter={isCustomDateFilter} source={source}>
        {stringDate ? (
          <StyledMuiTextField
            variant="standard"
            inputRef={datePickerRef}
            disabled={!isSROpenedFromQueue}
            error={(required && !selectedDate) || isError}
            unread={unread}
            isCustomDateFilter={isCustomDateFilter}
            isTemplatePage={isTemplatePage}
            value={stringDate}
            onFocus={!disabled && !!onFocus ? onFocus : undefined}
            ticket={ticket}
            isNewField={isNewField}
            InputProps={{
              readOnly: isSROpenedFromQueue,
              endAdornment: !disabled && (
                <InputAdornment position="end" className="clearIndicatorDirty">
                  <OpenPickerIcon
                    data-testid="calendar-icon-btn"
                    onClick={openDatePopup}
                    style={{ width: '14px', height: '13px' }}
                  />
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
            isSROpenedFromQueue={isSROpenedFromQueue}
          />
        ) : (
          <EmptyDatePicker
            onClick={openDatePopup}
            datePickerRef={datePickerRef}
            required={required}
            disabled={disabled}
            isNewField={isNewField}
            placeholder={isTemplatePage ? '--' : placeholder}
            isTemplatePage={isTemplatePage}
            isDatePopupOpen={isDatePopupOpen}
            warningTooltip={emptyValueTooltip}
          />
        )}
        {isDatePopupOpen && (
          <DatePickerPopup
            open={isDatePopupOpen}
            coord={datePickerRef.current?.getBoundingClientRect()}
            onClosePopup={closeDatePopupHandler}
            date={selectedDate}
            defaultDate={defaultDate}
            disablePortal={disablePortal}
            clearValueHandler={clearDatePicker}
            isOnlyDateFormat={isOnlyDateFormat}
          />
        )}
      </DatePickerContainer>
    </StyledDatePicker>
  );

  if(notInTemplate){
    return <MissingTemplateTooltip />
  }

  return datePickerRef?.current?.scrollWidth > datePickerRef?.current?.offsetWidth ? (
    <StyledTooltip title={stringDate} placement="top" arrow disableHoverListener={!error && isError}>
      {datepicker()}
    </StyledTooltip>
  ) : (
    datepicker()
  );
}

export default memo(DatePicker);
