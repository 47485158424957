import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTemplateBuilder = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  backgroundColor: theme.palette.background.pages_background,
}));
