import moment from 'moment-timezone';
import { actionEnums } from 'features/resolutionPanel/middlePanel/auditLog/constants';

export const getDate = (logTimestamp, timezone, dateFormat) => {
  if (logTimestamp) {
    if (timezone && dateFormat) {
      return moment(logTimestamp).tz(timezone)?.format(dateFormat);
    }
    const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment(logTimestamp).tz(defaultTimezone)?.format('MM/DD/yyyy hh:mm a'); // default format and timezone
  }
};

export const isAutomationAuditLog = (auditLog = {}) =>
  auditLog.logType === actionEnums.EVENT_DUE_DATE || auditLog.logInformation?.updateTaskName;
