import { useState } from 'react';
import { useSelector } from 'react-redux';
import Tooltip from 'common/components/tooltip';
import { ReactComponent as UserIcon } from 'images/icons/userIcon.svg';
import { ReactComponent as ArrowDownIcon } from 'images/icons/arrowdown.svg';
import { selectActiveUser } from 'store/userSlice';
import { StyledAssignessFilter, StyledDropDownModal } from '../style';
import { AssigneeFilter } from './assignee';
import useTexts from '../useTexts';

export default function AssignessFilter({ filterModel }) {
  // const dropDownRef = useRef();
  const { allAssignees, loginUserText } = useTexts();
  const [anchorHtmlElement, setAnchorHtmlElement] = useState(null);
  const calculatedUsername = useSelector(selectActiveUser)?.calculatedUserName;
  const assigneeFilterDisplayKeys = filterModel?.find((filter) => filter?.field === 'assignee')?.displayKeys;

  const isCurrAccSelectedOnly =
    assigneeFilterDisplayKeys?.length === 1 && assigneeFilterDisplayKeys[0] === calculatedUsername && loginUserText;

  const assigneeGroupFilterDisplayKeys = filterModel?.find((filter) => filter?.field === 'assignedGroup')?.displayKeys;

  const assigneeFilterText = [...(assigneeFilterDisplayKeys || []), ...(assigneeGroupFilterDisplayKeys || [])].join(
    ', ',
  );

  // const keyDownHandler = (event) => {
  //   if (event.key === 'Enter') {
  //     if (dropDownRef.current?.closest('.ag-cell-focus')) {
  //       setAnchorHtmlElement(dropDownRef.current);
  //     }
  //   } else if (event.key === 'Escape') {
  //     setAnchorHtmlElement(null);
  //   }
  // };
  // useEffect(() => {
  //   window.addEventListener('keydown', keyDownHandler);
  //   return () => {
  //     window.removeEventListener('keydown', keyDownHandler);
  //   };
  // }, [dropDownRef, setAnchorHtmlElement]);

  return (
    <>
      <StyledAssignessFilter
        data-testid="assignee-filter"
        data-cy="assignee-filter"
        onClick={(event) => setAnchorHtmlElement(event.currentTarget)}
      >
        <UserIcon />

        <Tooltip
          placement="top"
          isTruncatedText
          style={{ lineHeight: 1.2858 }}
          title={isCurrAccSelectedOnly || assigneeFilterText || allAssignees}
          text={isCurrAccSelectedOnly || assigneeFilterText || allAssignees}
        />

        <ArrowDownIcon />
      </StyledAssignessFilter>
      <StyledDropDownModal
        variant="menu"
        anchorEl={anchorHtmlElement}
        open={anchorHtmlElement !== null}
        onClose={() => setAnchorHtmlElement(null)}
      >
        <AssigneeFilter disablePopover isGridFilter />
      </StyledDropDownModal>
    </>
  );
}
