import { Box, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledNewTemplateButton = styled(Box)(({ theme }) => ({
  '.create-btn': {
    borderRadius: '60px',
    color: theme.palette.generic.primary,
    backgroundColor: theme.palette.generic.sysaid,
    padding: '4px 16px',
    height: '32px',
    fontSize: theme.fontSize.main,
    fontFamily: theme.fonts.robotoRegular,
    lineHeight: 1,
    marginRight: '12px',
    textTransform: 'none',
    ':hover': {
      backgroundColor: theme.palette.generic.sysaid,
    },
  },
}));

export const StyledNewTemplateMenu = styled(Paper, { shouldForwardProp: (prop) => prop !== 'menuWidth' })(
  ({ theme, menuWidth }) => ({
    boxShadow: '0px 2px 8px rgba(118, 130, 150, 0.35)',
    borderRadius: '10px',
    width: `${menuWidth}px`,
    '.new-template-menu': {
      display: 'flex',
      padding: '8px 8px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      flex: '1 0 0',
    },
    '.new-template-menu-item': {
      padding: '14px 0 14px 16px',
      lineHeight: '1.4',
      fontSize: theme.fontSize.main,
      fontFamily: theme.fonts.robotoRegular,
      '&:hover': {
        display: 'flex',
        alignitems: 'center',
        gap: '6px',
        alignSelf: 'stretch',
        borderRadius: '6px',
        backgroundColor: theme.palette.grey.bt_alt_grey_light,
      },
    },
  }),
);
