import { ButtonGroup, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledButtonGroup = styled(ButtonGroup, {
  shouldForwardProp: (prop) => !['btnTheme', 'style'].includes(prop),
})(({ theme, btnTheme, style }) => ({
  button: {
    fontSize: theme.fontSize.main,
    textTransform: 'none',
    backgroundColor: theme.palette[btnTheme].btn?.backgroundColor?.main,
    color: theme.palette[btnTheme].btn?.color?.main,
    outline: 'none',
    '&:hover': {
      backgroundColor: theme.palette[btnTheme].btn?.backgroundColor?.hover,
      color: theme.palette[btnTheme].btn?.color?.hover,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette[btnTheme].btn?.backgroundColor?.disabled,
      color: theme.palette[btnTheme].btn?.color?.disabled,
    },
  },
  '.MuiButtonGroup-grouped:not(:last-of-type)': {
    borderTopLeftRadius: '30px',
    borderBottomLeftRadius: '30px',
    border: 'none',
    '& path': {
      stroke: theme.palette[btnTheme].btn?.color?.main,
    },
  },
  '.MuiButtonGroup-grouped:not(:first-of-type)': {
    borderTopRightRadius: '30px',
    borderBottomRightRadius: '30px',
    border: 'none',
    borderLeft: '1px solid #fff',
  },
  ...style,
}));

export const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'shouldhover',
})(({ theme }) => ({
  fontSize: theme.fontSize.main,
  '& svg': {
    marginRight: '10px',
  },
}));
