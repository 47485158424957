import PropTypes from 'prop-types';
import { Autocomplete } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { TextField } from 'common/components/sysaidLibrary/textField';
import dropdownArrow from 'images/icons/arrow-down.svg';
import { StyledAutoComplete } from './style';

const useStyles = makeStyles({
  paper: (props) => ({
    color: props.dropdownColor ? props.dropdownColor : 'rgba(48, 48, 48, 0.65)',
    fontSize: props.dropdownOptionFontSize ? props.dropdownOptionFontSize : 14,
    maxHeight: props.dropdownContainerMaxHeight ? props.dropdownContainerMaxHeight : 100,
  }),
});

export default function AutoComplete(props) {
  const { onChangeFormValues, options, fieldName, fieldPlaceholder, customAutoCompleteStyle, control, fieldClassName } =
    props;

  const classes = useStyles(customAutoCompleteStyle);
  const DropdownIcon = <img className="dropdown-arrow-img" alt="dropdown-arrow-img" src={dropdownArrow} />;

  return (
    <StyledAutoComplete>
      <Autocomplete
        freeSolo
        onInputChange={(e) => onChangeFormValues(fieldName, e.currentTarget.innerText)}
        options={options}
        classes={{ paper: classes.paper }}
        renderInput={(params) => (
          <div ref={params.InputProps.ref} className={fieldClassName}>
            <TextField
              name={fieldName}
              control={control}
              placeholder={fieldPlaceholder}
              inputIcon={DropdownIcon}
              {...params.inputProps}
            />
          </div>
        )}
      />
    </StyledAutoComplete>
  );
}

AutoComplete.propTypes = {
  onChangeFormValues: PropTypes.func,
  options: PropTypes.array,
  fieldName: PropTypes.string,
  fieldPlaceholder: PropTypes.string,
  customAutoCompleteStyle: PropTypes.object,
};
