import { MenuItem, IconButton } from '@mui/material';
import copy from 'copy-to-clipboard';
import { useState } from 'react';
import { ReactComponent as ShareIcon } from '../../../../images/icons/share.svg';
import useTexts from './useTexts';
import { StyledActions } from '../style';
import { StyledMenu } from './style';

export default function ShareCopy() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [copied, setCopied] = useState(false);
  const { copyLinkText, copiedLinkText } = useTexts();
  const url = window.location.href;
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setCopied(false);
  };

  return (
    <StyledActions isActive={Boolean(anchorEl)}>
      <IconButton
        id="copy-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <ShareIcon />
      </IconButton>
      <StyledMenu
        id="copy-menu"
        MenuListProps={{
          'aria-labelledby': 'copy-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            copy(url);
            setCopied(true);
          }}
        >
          <span>{copied ? copiedLinkText ?? 'Copied' : copyLinkText}</span>
        </MenuItem>
      </StyledMenu>
    </StyledActions>
  );
}
