import isArray from 'lodash/isArray';
import {
  format,
  differenceInDays,
  subDays,
  isSameWeek,
  isSameMonth,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
} from 'date-fns';
import { filterFns as importedFilterFns } from '@tanstack/react-table';
import { COLUMN_FILTER_FIELDTYPE, FIELD_TYPE } from '../../constants';

export const numberFilterFn = (row, columnId, filterValue) => {
  if (!filterValue) {
    return true;
  }
  const { values, type } = filterValue;
  const value = values?.[0];
  if (typeof value === 'undefined' || value === null) {
    return true;
  }
  const cellValue = row.getValue(columnId);

  switch (type) {
    case 'lessThan':
      return cellValue < value;
    case 'greaterThan':
      return cellValue > value;
    case 'equals':
    default:
      return importedFilterFns.equals(row, columnId, value);
  }
};

export const textFilterFn = (row, columnId, filterValue) => {
  if (!filterValue) {
    return true;
  }
  const { values } = filterValue;
  const value = values?.[0];

  return !value || value.length === 0 || importedFilterFns.includesString(row, columnId, value);
};

export const setFilterFn = (row, columnId, filterValue) => {
  if (!filterValue) {
    return true;
  }
  const { values } = filterValue;
  return !values || values.length === 0 || importedFilterFns.arrIncludesSome(row, columnId, values);
};

export const dateFilterFn = (row, columnId, filterValue) => {
  if (!filterValue) {
    return true;
  }
  const { values } = filterValue;
  if (!values || (isArray(values) && values.length === 0)) {
    return true;
  }
  const cellValue = row.getValue(columnId);
  const today = new Date();
  const parsedDate = new Date(format(new Date(cellValue), 'yyyy-MM-dd'));

  switch (values) {
    case 'today':
      return differenceInDays(today, parsedDate) === 0;
    case 'yesterday':
      return differenceInDays(today, parsedDate) === 1;
    case 'thisWeek':
      return isSameWeek(today, parsedDate);
    case 'thisMonth':
      return isSameMonth(today, parsedDate);
    case 'last30Days':
      return differenceInDays(today, parsedDate) <= 30;
    default:
      return values.length > 1
        ? differenceInDays(parsedDate, values[0]) >= 0 && differenceInDays(values[1], parsedDate) >= 0
        : differenceInDays(values[0], parsedDate) === 0;
  }
};

export const getDateFilterValueByString = (value) => {
  const today = new Date();
  today.setSeconds(0, 0); //  to prevent infinite rendering because of changing timestamp on each ms

  switch (value) {
    case 'today':
      return [today.getTime()];
    case 'yesterday':
      return [subDays(today, 1).getTime()];
    case 'thisWeek':
      return [startOfWeek(today).getTime(), endOfWeek(today).getTime()];
    case 'thisMonth':
      return [startOfMonth(today).getTime(), endOfMonth(today).getTime()];
    case 'last30Days':
      return [subDays(today, 30).getTime(), today.getTime()];
    default:
      return value;
  }
};

export const getColumnFiltersForRequest = (columnFilters, columnsMap) => {
  const columnFiltersToSend = [];
  columnFilters?.forEach((columnFilter) => {
    const { id, value } = columnFilter;
    if (columnsMap && columnsMap[id].filterType === COLUMN_FILTER_FIELDTYPE.date) {
      columnFiltersToSend.push({ id, value: getDateFilterValueByString(value), isDate: true });
    } else {
      columnFiltersToSend.push(columnFilter);
    }
  });
  return columnFiltersToSend;
};

export const generalFilterFns = {
  setFilterFn,
  textFilterFn,
  numberFilterFn,
  dateFilterFn,
};

export const resolveFilterValueByFieldType = (filterValue, fieldType) => {
  // @TODO change srType data type in DB from string to sr code and remove this function
  switch (fieldType) {
    case FIELD_TYPE.srType:
      return filterValue.map((val) => val.toLowerCase());
    default:
      return filterValue;
  }
};

export const getFilterFnByFilterType = (filterType) => {
  switch (filterType) {
    case COLUMN_FILTER_FIELDTYPE.set:
      return 'setFilterFn';
    case COLUMN_FILTER_FIELDTYPE.text:
      return 'textFilterFn';
    case COLUMN_FILTER_FIELDTYPE.number:
      return 'numberFilterFn';
    case COLUMN_FILTER_FIELDTYPE.date:
      return 'dateFilterFn';
    default:
      return undefined;
  }
};

export const getColumnsMap = (columns) =>
  columns?.reduce((acc, current) => ({ ...acc, [current.accessorKey || current.id]: current }), {});
