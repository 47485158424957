import { styled } from '@mui/material/styles';
import { ReactComponent as DownloadIcon } from 'images/icons/downloadThumbnailIcon.svg';
import { ReactComponent as TrashIcon } from 'images/icons/ThumbnailTrash.svg';

export const StyledAttachmentDetails = styled('div')(() => ({
  position: 'absolute',
  width: '170px',
  height: '46px',
  background: 'rgba(255, 255, 255, 0.6)',
  backdropFilter: 'blur(2px)',
  borderRadius: '0px 0px 6px 6px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0px 8px',
  bottom: '0px',

  '.user-details': {
    display: 'flex',
    marginTop: '5px',
    maxWidth: '100%'
  },
  '.timestamp-details': {
    display: 'flex',
  },
}));

export const StyledUsername = styled('div')(() => ({
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '16px',
  color: '#303030',
  alignSelf: 'center',
  justifySelf: 'center',
  marginLeft: '8px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const StyledTimestamp = styled('div')(() => ({
  width: '121px',
  height: '28px',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '20px',
  color: '#303030',
  justifySelf: 'center',
}));

export const StyledAvatar = styled('div')(() => ({
  justifySelf: 'center',
}));

export const StyledCheckBox = styled('div')(() => ({
  alignSelf: 'center',
  //According to the requirement it should be hidden
  display: 'none',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  right: '5%',
  top: '25%',
  ':hover': {
    cursor: 'pointer',
  },
}));

export const StyledAttachmentActions = styled('div')(({ isActionItem }) => ({
  position: isActionItem ? 'unset' : 'absolute',
  paddingRight: isActionItem && '4px',
  display: 'flex',
  gap: '4px',
  right: '6px',
  top: '6px',

  '.MuiIconButton-root': {
    padding: 0,
  },
}));

export const StyledDownloadIcon = styled(DownloadIcon)(({ theme }) => ({
  background: '#F0F1F5',
  opacity: '0.8',
  borderRadius: '6px',
  width: '24px',
  height: '24px',
  padding: '4px',

  ':hover': {
    cursor: 'pointer',
    background: theme?.palette?.grey?.bt_def_grey_light,
  },
}));

export const StyledTrashIcon = styled(TrashIcon)(({ theme }) => ({
  background: '#F0F1F5',
  opacity: '0.8',
  borderRadius: '6px',
  width: '24px',
  height: '24px',
  padding: '4px',

  ':hover': {
    cursor: 'pointer',
    background: theme?.palette?.grey?.bt_def_grey_light,
  },
}));
