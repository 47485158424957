import { styled } from '@mui/material/styles';
import { List } from '@mui/material';

const StyledUserInfoCardDetails = styled(List)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(3),
}));

export default StyledUserInfoCardDetails;
