import { login, setUserDetails } from 'store/userSlice';
import { getUserDetailsByName } from './userService';

export const isLocalHost = () => (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1');

export const handleLocalHostUserDetails = async (dispatch) => {
    const userName = 'sysaid';
    const calculatedUserName = 'sysaid calculated';
    const userDetails = await getUserDetailsByName(userName);
    dispatch(setUserDetails(userDetails));
    dispatch(
        login({
            username: userName,
            calculatedUserName,
            roles: ['admin'],
            isAuth: true,
        }),
    );
};
