import { ReactComponent as GoBackIcon } from 'images/icons/new_back_icon.svg';
import IconButton from 'common/components/buttons/IconButton';
import { Box } from '@mui/material';
import useTexts from '../useTexts';

function GoBack({ onClick, show }) {
  const { backToSettings } = useTexts();
  if (!show) return null;
  return (
    <Box className="go-back" onClick={onClick} data-testid="go-back-button">
      <IconButton
        className="back-icon-button"
        icon={<GoBackIcon />}
        btnTheme="extraSmallIconButton"
        onClick={onClick}
      />
      <span>{backToSettings}</span>
    </Box>
  );
}
export default GoBack;
