import { useRbTexts } from 'remote-state/applicationHooks';

export const useTexts = () => {
  const texts = useRbTexts();

  const generativeAiSuggested = texts?.['spaces.generativeAi.generativeAiSuggested'];
  const clickingThisWillAutoFill = texts?.['spaces.generativeAi.clickingOnThisSetWillAutoFillAllCategories'];

  return {
    generativeAiSuggested,
    clickingThisWillAutoFill,
  };
};
