import DatePicker from 'common/components/datePicker';
import FieldName from '../fieldName';

export default function DatepickerField(props) {
  const {
    displayName,
    fieldValue,
    field,
    disabled,
    required,
    hasMoreDetails,
    url,
    urlParams,
    isTemplatePage,
    placeholder,
    onChange,
    onFocus,
    updateFieldValidation,
    emptyValueTooltip,
    isNewField = false,
  } = props;

  return (
    <>
      <FieldName
        name={displayName}
        fieldId={field?.fieldId}
        required={required}
        hasMoreDetails={hasMoreDetails}
        url={url}
        urlParams={urlParams}
        isTemplatePage={isTemplatePage}
        hintText={field?.hint && field?.hintText}
      />
      <div style={{ transform: 'translateX(-8px)' }}>
        <DatePicker
          onFocus={onFocus}
          isNewField={isNewField}
          required={required}
          onChange={onChange}
          disabled={disabled}
          date={Number(fieldValue)}
          placeholder={placeholder}
          fieldName={field?.fieldName}
          fieldType={field?.templateFieldAttributes?.ticketTemplateFieldType?.id}
          isTemplatePage={isTemplatePage}
          updateFieldValidation={updateFieldValidation}
          emptyValueTooltip={emptyValueTooltip}
        />
      </div>
    </>
  );
}
