import { DialogContent } from '@mui/material';
import PropTypes from 'prop-types';

import { ReactComponent as CloseIcon } from 'images/icons/icon_close_big.svg';
import TableView from 'common/components/table';
import { StyledCloseButton, StyledDialog, StyledDialogTitle } from './style';
import { columnsKeys, headersTextKeys } from './constants';
import useTexts from './useTexts';

const MigrationHistoryViewDialog = ({ open, historyList, onClose }) => {
  const texts = useTexts();
  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <StyledDialogTitle data-testId="migration-history-dialog-title" data-cy="migration-history-dialog-title">
        {texts.historyTitle}
        {onClose && (
          <StyledCloseButton data-cy="migration-history-dialog-close-button">
            <CloseIcon onClick={onClose} />
          </StyledCloseButton>
        )}
      </StyledDialogTitle>

      <DialogContent data-testId="migration-history-dialog-content" data-cy="migration-history-dialog-content">
        <TableView
          texts={texts}
          headersTextKeys={headersTextKeys}
          columnsKeys={columnsKeys}
          data={historyList}
          stickyHeader
        />
      </DialogContent>
    </StyledDialog>
  );
};

MigrationHistoryViewDialog.propTypes = {
  open: PropTypes.bool,
  historyList: PropTypes.shape({
    logId: PropTypes.number,
    accountId: PropTypes.string,
    serviceReqId: PropTypes.number,
    logTime: PropTypes.string,
    logType: PropTypes.string,
    logDescription: PropTypes.string,
    order: PropTypes.number,
    userName: PropTypes.string,
  }),
  onClose: PropTypes.func,
};

MigrationHistoryViewDialog.defaultProps = {
  open: false,
  historyList: [],
  onClose: () => {},
};

export default MigrationHistoryViewDialog;
