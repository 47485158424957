import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useFieldAttributes } from 'remote-state/ticketServiceHooks';
import { getFieldAttributesMap } from 'common/utils/fieldUtils';
import { selectActiveUser } from 'store/userSlice';

import useResolutionPanelTexts from '../../../../../useTexts';
import FieldSearchableDropdown from '../../../../../../srPanel/templateFieldsGrid/fieldSearchableDropdown';
import { StyledUserDropdownWrapper } from '../style';

const field = {
  defaultValue: null,
  fieldName: 'requestUser',
  fieldId: 16,
  sortBy: 'ValueCaption',
  hideFieldCaption: true,
};

const UserDropdown = ({ srType, selectedUser, onChange }) => {
  const resolutionPanelTexts = useResolutionPanelTexts();
  const currentUser = useSelector(selectActiveUser);

  const { fieldId } = field;
  const fieldAttributesInput = useMemo(() => [{ fieldId, srType }], [fieldId, srType]);
  const fieldAttributes = useFieldAttributes(fieldAttributesInput);
  const fieldAttributesMap = useMemo(() => getFieldAttributesMap(fieldAttributes, [field]), [fieldAttributes]);
  const values = useMemo(
    () => (fieldAttributesMap[field.fieldName]?.values || [])
      .map((item) => item.value === currentUser?.username ?
        { ...item, calculatedUserName: `${item.calculatedUserName} (${resolutionPanelTexts.meText})` } :
        item,
      ),
    [fieldAttributesMap, currentUser?.username, resolutionPanelTexts.meText],
  );

  const handleDropdownChange = useCallback((userName) => {
    onChange({ userName });
  }, [onChange]);

  return (
    <StyledUserDropdownWrapper data-testid="activity-user-dropdown">
      <FieldSearchableDropdown
        field={field}
        fieldValue={selectedUser?.userName}
        isNewField
        values={values}
        onChange={handleDropdownChange}
      />
    </StyledUserDropdownWrapper>
  );
};

export default UserDropdown;
