import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    popupTitle: texts?.['spaces.prompt.queueView.deleteViewModalTitle'],
    deleteBtnText: texts?.['spaces.prompt.queueView.deleteBtnText'],
    popupCancelBtnText: texts?.['spaces.prompt.queueView.cancelBtnText'],
    popupBodyText: texts?.['spaces.prompt.queueView.deleteViewModalBody'],
  };

  return componentTexts;
}
