import { useCallback, useMemo, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useRouter } from '@tanstack/react-router';
import {
  addNewAuditLog,
  removeAuditLogEntry,
  selectAuditLog,
  selectJourneyFilters,
  updateAuditLogEntry,
  updateAuditLogListByFilter,
  updateSelectedFilter,
} from 'features/resolutionPanel/middlePanel/auditLog/store/slice';
import { RESOLUTION_PANEL_CONSTANTS } from 'features/resolutionPanel/constants';
import { convertToCamelCase } from 'features/resolutionPanel/middlePanel/auditLog/utils';
import { getTicketAuditLogsByFilter } from 'services/auditLogsService';

import { actionEnums, EDITABLE_TEXT_TYPES, RICH_TEXT_TYPES } from '../constants';
import { useUserAttributes } from '../../../../../remote-state/userServiceHooks';
import { activeUsername } from '../../../../../store/userSlice';
import { USER_ATTRIBUTE_CONSTANTS } from '../../../../../constants/users';

export const useAuditLog = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const ticketAuditLogs = useSelector(selectAuditLog, shallowEqual);
  const [isLoading, setLoading] = useState(false);
  const { tab: selectedTab, filter: selectedFilter } = useSelector(selectJourneyFilters);
  const username = useSelector(activeUsername);
  const { data: userAttributes } = useUserAttributes(username, USER_ATTRIBUTE_CONSTANTS.JOURNEY);

  // we need exact check here, because if even isAllMessagesExpanded is not set (undefined) items should be expanded by default
  const isAllMessagesExpanded = useMemo(
    () => userAttributes?.attributeValue?.isAllExpanded !== false,
    [userAttributes?.attributeValue?.isAllExpanded],
  );

  const isAllMessagesExpandedRef = useRef(isAllMessagesExpanded);
  const srId = router.latestLocation.search.id;

  const selectedFilterType = useMemo(
    () =>
      convertToCamelCase(RESOLUTION_PANEL_CONSTANTS.JOURNEY_FILTERS.find((select, index) => index === selectedFilter)),
    [selectedFilter],
  );

  // store the audit log slice in a ref so any callbacks functions that change fields(like categories) will use the updated slice and not the initial(empty) slice
  const ticketAuditLogsRef = useRef();
  ticketAuditLogsRef.current = ticketAuditLogs;

  const isAnyFilterSliceEmpty = useCallback(
    (logsByFilter, filterIndex) => selectedFilter !== filterIndex && !logsByFilter.list.length,
    [selectedFilter],
  );

  const isLogTypeIncluded = (logsByFilter, auditLog) => logsByFilter.types.includes(auditLog.logType);

  const updateAuditLogs = useCallback(
    ({ log }) => {
      if (log == null || !Array.isArray(log)) return;
      log?.forEach((logEntry) => {
        if (RICH_TEXT_TYPES.includes(logEntry.logType) || log?.textTruncateEnabled) {
          logEntry.isRichTextTruncated = !isAllMessagesExpanded;
          if (logEntry.logType === actionEnums.SOLUTION_RESOLUTION_CREATED) {
            logEntry.isRichTextTruncatedSecond = true;
          }
        }
        Object.keys(ticketAuditLogsRef.current).forEach((filter, filterIndex) => {
          const logsByFilter = ticketAuditLogsRef.current[filter];
          const logIndex = logsByFilter?.list?.findIndex((log) => log.id === logEntry.id);
          if (logIndex !== -1) {
            if (isLogTypeIncluded(logsByFilter, logEntry)) {
              if (isAnyFilterSliceEmpty(logsByFilter, filterIndex)) {
                return;
              }
              dispatch(updateAuditLogEntry({ filter, updatedEntry: logEntry }));
            } else {
              dispatch(removeAuditLogEntry({ filter, id: logEntry.id }));
            }
          } else if (isLogTypeIncluded(logsByFilter, logEntry) && !isAnyFilterSliceEmpty(logsByFilter, filterIndex)) {
            dispatch(addNewAuditLog({ filter, newAuditLog: logEntry }));
          }
        });
      });
    },
    [dispatch, isAnyFilterSliceEmpty, isAllMessagesExpanded],
  );

  const handleActiveTab = (event, selectedTab, name) => {
    if (name === 'attachment') return;
    dispatch(updateSelectedFilter({ filter: 'tab', selectedValue: selectedTab }));
  };

  const handleActiveFilter = useCallback(
    (selectedFilterId) => {
      dispatch(updateSelectedFilter({ filter: 'filter', selectedValue: selectedFilterId }));
    },
    [dispatch],
  );

  const updateAuditLogList = useCallback(
    (updatedList) => {
      dispatch(updateAuditLogListByFilter({ filter: selectedFilterType, updatedList }));
    },
    [dispatch, selectedFilterType],
  );

  const handleChangeAuditLogEntryProperty = (auditLogsCopy, property, indexFound, value) => {
    const copiedAuditLogItem = { ...auditLogsCopy[indexFound] };
    copiedAuditLogItem[property] = value;
    auditLogsCopy[indexFound] = copiedAuditLogItem;
  };

  const updateAuditLogProperty = useCallback(
    ({ auditLogIds, property, value = null }) => {
      const updates = ticketAuditLogs[selectedFilterType]?.list.map((auditLog) => {
        if (auditLogIds.includes(auditLog.id)) {
          return { ...auditLog, [property]: value !== null ? value : !auditLog[property] };
        }
        return auditLog;
      });
      updateAuditLogList(updates);
    },
    [selectedFilterType, ticketAuditLogs, updateAuditLogList],
  );

  // receives an id to identify the audit log rows -> toggle there property
  const toggleAuditLogsProperty = useCallback(
    ({ auditLogIds, property }) => {
      updateAuditLogProperty({ auditLogIds, property });
    },
    [updateAuditLogProperty],
  );

  // receives an id to identify the audit log rows -> set there property
  const setAuditLogsProperty = ({ auditLogIds, property, value }) => {
    updateAuditLogProperty({ auditLogIds, property, action: handleChangeAuditLogEntryProperty, value });
  };

  const getTicketAuditLogsByType = useCallback(
    async (type) => {
      try {
        const res = await getTicketAuditLogsByFilter(srId, type);

        dispatch(
          updateAuditLogListByFilter({
            filter: type,
            updatedList: res.list,
            totalCount: res.totalCount,
          }),
        );
      } catch (error) {
        console.log(error);
      }
    },
    [srId, dispatch],
  );

  // remove elements with the same name from 'attachment' array thet exist in 'inlineImages' array
  const removeInlineImageAttachmentsNote = (attachments, inlineImages) => {
    const inlineImageNames = inlineImages.map((image) => image.originalName);

    return attachments.filter((attachment) => !inlineImageNames.includes(attachment.originalName));
  };

  const getTicketAuditLogs = useCallback(async () => {
    try {
      const isExpandedBtnChanged = isAllMessagesExpanded !== isAllMessagesExpandedRef.current;

      // we don't need to set loading state if expand btn was toggled
      if (!isExpandedBtnChanged) {
        setLoading(true);
      }

      isAllMessagesExpandedRef.current = isAllMessagesExpanded;
      const res = await getTicketAuditLogsByFilter(srId, selectedFilterType);

      res?.list?.forEach((logElement) => {
        const logInformation = logElement?.logInformation || [];

        if (logInformation?.attachments && logInformation?.inlineImages) {
          const attachments = logInformation?.attachments || [];
          const inlineImages = logInformation?.inlineImages || [];
          logInformation.attachments = removeInlineImageAttachmentsNote(attachments, inlineImages);
        }
        if (logInformation?.solution?.attachments && logInformation?.solution?.inlineImages) {
          const attachments = logInformation?.solution?.attachments || [];
          const inlineImages = logInformation?.solution?.inlineImages || [];
          logInformation.solution.attachments = removeInlineImageAttachmentsNote(attachments, inlineImages);
        }
        if (logInformation?.resolution?.attachments && logInformation?.resolution?.inlineImages) {
          const attachments = logInformation?.resolution?.attachments || [];
          const inlineImages = logInformation?.resolution?.inlineImages || [];
          logInformation.resolution.attachments = removeInlineImageAttachmentsNote(attachments, inlineImages);
        }
      });

      if (res) {
        res.list?.forEach((element) => {
          if (RICH_TEXT_TYPES.includes(element.logType) || element?.logInformation?.textTruncateEnabled) {
            element.isRichTextTruncated = !isAllMessagesExpanded;

            if (element.logType === actionEnums.SOLUTION_RESOLUTION_CREATED) {
              element.isRichTextTruncatedSecond = true;
            }
          }
          if (EDITABLE_TEXT_TYPES.includes(element.logType)) {
            element.isEdited = false;
          }
        });
        dispatch(
          updateAuditLogListByFilter({
            filter: selectedFilterType,
            updatedList: res.list,
            totalCount: res.totalCount,
          }),
        );
      }
    } catch (e) {
      //TODO: handle error on fetching audit log filters types
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [dispatch, selectedFilterType, srId, isAllMessagesExpanded]);

  return {
    updateAuditLogs,
    selectedTab,
    selectedFilterType,
    handleActiveTab,
    handleActiveFilter,
    toggleAuditLogsProperty,
    setAuditLogsProperty,
    getTicketAuditLogs,
    getTicketAuditLogsByType,
    isLoading,
  };
};
