import { forwardRef, useCallback, useState } from 'react';
import { ClickAwayListener, MenuList } from '@mui/material';
import SearchField from 'common/components/searchField';
import Tooltip from 'common/components/tooltip';

import { StyledSelect, StyledListItem, StyledBox, StyledMenuWrapper } from '../styles';
import useTexts from './useTexts';

export default function Select({
  options,
  value,
  name,
  placeholder,
  width = '224px',
  maxHeight = '286px',
  handleChange,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { noValuesText } = useTexts();
  const [searchValue, setSearchValue] = useState('');
  const handleSearchChange = useCallback((e) => setSearchValue(e.target.value), []);
  const handleSearchClear = useCallback(() => setSearchValue(''), []);

  return (
    <StyledSelect
      select
      open={isOpen}
      style={{ width }}
      InputProps={{ 'data-testid': `${name}-select`, className: 'menu-select' }}
      SelectProps={{
        style: { width },
        open: isOpen,
        onChange: (e) => {
          handleChange?.(e.target.value, name);
        },
        onClick: (e) => {
          if (e.target.localName === 'li' || e.target.localName === 'span') {
            setIsOpen(false);
          } else {
            setIsOpen(true);
          }
        },
        displayEmpty: true,
        IconComponent: null,
        renderValue: (value) => {
          if (!value) return placeholder;

          return options.find((option) => option.value === value)?.displayValue;
        },
        MenuProps: {
          open: isOpen,
          disableAutoFocusItem: true,
          disablePortal: true,
          height: 'inherit',
          PaperProps: {
            sx: {
              boxShadow: '0 2px 8px 0 rgba(118, 130, 150, 0.35)',
              borderRadius: '10px',
              width,
              maxHeight,
            },
          },
          MenuListProps: {
            component: forwardRef(({ children }, ref) => (
              <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                <StyledMenuWrapper maxHeight={maxHeight} ref={ref}>
                  <SearchField
                    autoFocus
                    placeholderText="Search"
                    value={searchValue}
                    onChange={handleSearchChange}
                    onClear={handleSearchClear}
                  />
                  <StyledBox>
                    <MenuList id="select-menu-list" sx={{ paddingBlock: 1.5, width }}>
                      {children}
                    </MenuList>
                  </StyledBox>
                </StyledMenuWrapper>
              </ClickAwayListener>
            )),
          },
        },
      }}
      value={value}
    >
      {options?.length ? (
        options
          .filter((item) => item.displayValue.toUpperCase().includes(searchValue.toUpperCase()))
          .map(({ value, displayValue: text }) => (
            <StyledListItem key={value} data-testid={`${value}-option`} value={value} disableGutters disableRipple>
              <Tooltip placement="top" text={text} isTruncatedText data-testid="option" />
            </StyledListItem>
          ))
      ) : (
        <StyledListItem disableGutters disableRipple disabled>
          <Tooltip placement="top" text={noValuesText} isTruncatedText />
        </StyledListItem>
      )}
    </StyledSelect>
  );
}
