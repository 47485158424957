import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    delete: {
      title: texts?.['spaces.attachment.delete.title'],
      successMessage: texts?.['spaces.attachment.delete.successMessage'],
      failMessage: texts?.['spaces.attachment.delete.failMessage'],
    },
    multipleDelete: {
      title: texts?.['spaces.attachment.multipleDelete.title'],
      description: texts?.['spaces.attachment.multipleDelete.description'],
    },
  };

  return componentTexts;
}
