import { styled } from '@mui/styles';
import { Box } from '@mui/material';

export const StyledDependencyPopup = styled(Box)(({ theme }) => ({
  width: '297px',
  maxHeight: '180px',
  borderRadius: '4px',
  position: 'absolute',
  top: '100%',
  right: '0px',
  zIndex: 5,
  border: `1px solid ${theme.palette.grey.divider_grey_v1}`,
  background: theme.palette.generic.primary,
  cursor: 'auto',
}));

export const DependencyContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  margin: '24px 0px 24px 24px',
  '& .dependency': {
    fontSize: '.875rem',
    lineHeight: '1.5rem',
    color: theme.palette.text.title_txt,
    paddingInlineStart: '5px',
    paddingInlineEnd: '36px',
    '& .operator': {
      color: theme.palette.text.disabled,
    },
    '& .link': {
      color: theme.palette.text.blue_user_name,
      cursor: 'pointer',
    },
  },
}));

export const DependencyTitle = styled('h2')(({ theme }) => ({
  fontSize: '.875rem',
  lineHeight: '1.5rem',
  color: theme.palette.text.title_txt,
  padding: '0px',
  margin: '0px',
}));

export const ScrollWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '100px',
  overflowY: 'auto',
}));
