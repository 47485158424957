import { useRbTexts } from 'remote-state/applicationHooks';
import { REPHRASE_TYPE } from './constants';

export const useTexts = () => {
  const texts = useRbTexts();

  return {
    rephraseActions: {
      [REPHRASE_TYPE.Improve]: texts?.['spaces.generativeAi.rephrase.improve'],
      [REPHRASE_TYPE.Casual]: texts?.['spaces.generativeAi.rephrase.casual'],
      [REPHRASE_TYPE.Formal]: texts?.['spaces.generativeAi.rephrase.formal'],
      [REPHRASE_TYPE.Shorten]: texts?.['spaces.generativeAi.rephrase.shorten'],
      [REPHRASE_TYPE.StepByStep]: texts?.['spaces.generativeAi.rephrase.stepByStep'],
    },
    rewriting: texts?.['spaces.generativeAi.rephrase.rewriting'],
    backToOriginal: texts?.['spaces.generativeAi.rephrase.backToOriginal'],
  };
};
