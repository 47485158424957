import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setError } from 'store/globalSlice';
import { ERROR_TYPES } from 'constants/common';
import NoViewPermissions from 'common/components/noViewPermissionsPage';
import useTextsTicket from 'features/TicketPanel/useTexts';
import useTextsTemplate from 'features/templateBuilder/useTexts';

//TODO: Has to change to 404 page that comes from the product
export default function ErrorPage({ type }) {
  const dispatch = useDispatch();
  useEffect(() => () => dispatch(setError(null)), [dispatch]);
  const ticketTexts = useTextsTicket();
  const templateTexts = useTextsTemplate();
  const renderError = () => {
    switch (type) {
      case ERROR_TYPES.NO_VIEW_PERMISSIONS_TICKET:
        return <NoViewPermissions title={ticketTexts.viewPermissionsTitle} text={ticketTexts.viewPermissionsText} />;
      case ERROR_TYPES.NO_VIEW_PERMISSIONS_TEMPLATE:
        return <NoViewPermissions title={templateTexts.viewPermissionsTitle} text={templateTexts.viewPermissionsText} />;
      default:
        return <div>Page does not exist, Error 404</div>;
    }
  };
  return renderError();
}
