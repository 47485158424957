import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from '@tanstack/react-router';
import usePendoTrackEvents from 'common/utils/hooks/usePendoTrackEvents';
import { useRbTexts } from 'remote-state/applicationHooks';
import { useTicketPermissions, useSR } from 'remote-state/ticketServiceHooks';
import { useAuditLog, useEditorActions } from '../../middlePanel/auditLog/hooks';
import { AttachmentButton } from './attachmentButton';
import { StyledFileInputLabel } from './attachmentButton/stlye';
import { selectActionLine, selectAuditLog, selectJourneyFilters } from '../../middlePanel/auditLog/store/slice';
import { actionEnums, editorActionTypes, FILTER_ENUMS } from '../../middlePanel/auditLog/constants';
import { RESOLUTION_PANEL_CONSTANTS } from '../../constants';
import ActivitiesCounter from './activitiesCounter';
import { calculateAggregatedTotalTime } from '../../middlePanel/auditLog/actionLine/activities/utils';
import { selectActiveUser } from '../../../../store/userSlice';
import { PENDO_TRACK_EVENTS } from '../../../../constants';

export function FooterItem({
  name,
  svg,
  translatedLabel,
  defaultLabel,
  type,
  scrollToAuditLogRecord,
  editorClassName = 'editor-element',
  showTicketAsTab,
}) {
  const texts = useRbTexts();
  const router = useRouter();
  const { type: currentEditorType, isExpanded } = useSelector(selectActionLine);
  const { handleEditorFocus, handleActionLineOperation, toggleRichTextEdited } = useEditorActions();
  const srId = router.latestLocation.search.id;

  const { data: hasEditPermissions } = useTicketPermissions('edit', srId === 'new' ? null : srId);
  const {
    sr: {
      data: { requestUser, requestUserName },
    },
  } = useSR();
  const { handleActiveFilter } = useAuditLog();
  const ticketAuditLogs = useSelector(selectAuditLog);
  const { activities } = ticketAuditLogs;
  const journeyFilters = useSelector(selectJourneyFilters);
  const currentUser = useSelector(selectActiveUser);
  const [lastResolution, setLastResolution] = useState(null);
  const SUMMARY = RESOLUTION_PANEL_CONSTANTS.JOURNEY_FILTERS.indexOf('Summary');
  const NOTIFICATIONS = RESOLUTION_PANEL_CONSTANTS.JOURNEY_FILTERS.indexOf('Notifications');
  const MESSAGES = RESOLUTION_PANEL_CONSTANTS.JOURNEY_FILTERS.indexOf('Messages');
  const activitiesTotalTime = useMemo(() => calculateAggregatedTotalTime(activities), [activities]);
  const pendoTrackEvents = usePendoTrackEvents();

  useEffect(() => {
    if (lastResolution) {
      const { id: auditLogId, logInformation } = lastResolution;
      scrollToAuditLogRecord(auditLogId);
      if (hasEditPermissions) {
        handleActionLineOperation({
          actionName: 'edit',
          type,
          logInformation,
          auditLogId,
          handleClickEdit: toggleRichTextEdited({ auditLogId }),
        });
      }
      setLastResolution(null);
    }
  }, [
    handleActionLineOperation,
    hasEditPermissions,
    lastResolution,
    scrollToAuditLogRecord,
    ticketAuditLogs.summary.list,
    toggleRichTextEdited,
    type,
  ]);

  const handleResolutionTabClick = () => {
    if (currentEditorType === editorActionTypes.RESOLUTION && isExpanded) return;

    const lastMatchingResolution = ticketAuditLogs.summary.list.findLast(
      (item) => item.logType === actionEnums.SOLUTION_RESOLUTION_CREATED,
    );

    if (lastMatchingResolution) {
      if (!lastMatchingResolution.logInformation.isDisabledJourneyLog) {
        if (journeyFilters.filter === NOTIFICATIONS || journeyFilters.filter === MESSAGES) {
          handleActiveFilter(SUMMARY);
        }
        setLastResolution(lastMatchingResolution);
        return;
      }
      return lastMatchingResolution.logInformation;
    }
  };

  const handleMessageTabClick = () => {
    if (requestUser?.emailAddress) {
      return {
        recipients: [
          {
            name: requestUser.calculatedUserName,
            emailAddress: requestUser.emailAddress,
            type: 'user',
            id: requestUser.id,
            displayName: requestUser.calculatedUserName,
          },
        ],
      };
    }

    if (requestUserName) {
      return {
        recipients: [
          {
            name: requestUserName,
            emailAddress: requestUserName,
            type: 'email',
          },
        ],
      };
    }
  };

  const handleActivitiesTabClick = () => {
    pendoTrackEvents(PENDO_TRACK_EVENTS.ACTIVITIES_CLICKED, {});

    return {
      user: {
        userName: currentUser?.username || null,
      },
    };
  };

  const handleTabClick = ({ editorType }) => {
    let logInformation = {};

    if (name === 'resolution') {
      logInformation = handleResolutionTabClick();
    } else if (name === 'message') {
      logInformation = handleMessageTabClick();
    } else if (name === FILTER_ENUMS.ACTIVITIES) {
      logInformation = handleActivitiesTabClick();
    }

    handleEditorFocus({ type: editorType, isNewEditor: true, logInformation });
  };

  const content = (
    <>
      {svg} <span className="item-label">{texts?.[translatedLabel] || defaultLabel}</span>
    </>
  );

  return name === 'attachment' ? (
    <StyledFileInputLabel data-testid={`footer-item-${name}`} data-cy={`footer-item-${name}`}>
      <AttachmentButton content={content} showTicketAsTab={showTicketAsTab} />
    </StyledFileInputLabel>
  ) : (
    <button
      className={`footer-item ${editorClassName}`}
      data-testid={`footer-item-${name}`}
      data-cy={`footer-item-${name}`}
      onClick={() => handleTabClick({ editorType: type })}
    >
      {content}
      {name === FILTER_ENUMS.ACTIVITIES && <ActivitiesCounter {...activitiesTotalTime} />}
    </button>
  );
}
