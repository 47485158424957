import { useState } from 'react';
import { ReactComponent as DependenciesIcon } from 'images/icons/workflows/dependencies.svg';
import { StyledDependencyIndicator, DependencyWrapper } from './style';
import { DependencyPopup } from './components/DependencyPopup';
import useTexts from '../../hooks/useTexts';

export const DependencyIndicator = ({ className, logicalOperatorisAnd, dependencies }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const { orOperatorText, andOperatorText } = useTexts();
  const operatorText = logicalOperatorisAnd ? andOperatorText : orOperatorText;

  const handleMouseEnter = () => setShowTooltip(true);
  const handleMouseLeave = () => setShowTooltip(false);

  return (
    <DependencyWrapper
      title="dependency-indicator"
      data-cy="dependency-indicator"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <StyledDependencyIndicator icon={<DependenciesIcon />} className={className} />
      {showTooltip && <DependencyPopup operator={operatorText} dependencies={dependencies} />}
    </DependencyWrapper>
  );
};
