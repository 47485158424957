import { styled } from '@mui/material/styles';
import { Tabs } from '@mui/material';

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  marginInline: theme.spacing(3),
  minHeight: 'unset',
  borderBottom: `1px solid ${theme.palette.grey.divider_grey_v1}`,
  paddingTop: theme.spacing(2.375),
  boxSizing: 'border-box',

  '& .MuiTab-root': {
    color: theme.palette.text.secondary_txt,
    fontFamily: theme.fonts.robotoRegular,
    fontSize: theme.fontSize.main,
    lineHeight: 1.2,
    minHeight: 'unset',
    padding: 0,
    height: '28px',
    textTransform: 'unset',

    '&.Mui-selected': {
      color: theme.palette.text.txt_default,
    },
  },

  '.MuiTabs-indicator': {
    backgroundColor: theme.palette.generic.sysaid_default,
    borderRadius: '10px',
  },
}));
