import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledRelatedItemsEvent = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  gap: '10px',
  flexWrap: 'wrap',

  '.multi-select-list-wrapper': {
    cursor: 'default !important',
  },
  '.multi-select-chip': {
    cursor: 'default !important',
  },
  '.truncated-items': {
    cursor: 'pointer !important',
  },
}));
