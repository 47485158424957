import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    promptTitle: texts?.['spaces.mySettings.messagePrompt.title'],
    promptDescription: texts?.['spaces.mySettings.messagePrompt.description'],
    promptOkBtnText: texts?.['spaces.mySettings.messagePrompt.okBtn'],
    promptCancelBtnText: texts?.['spaces.mySettings.messagePrompt.cancelBtn'],
    logoutBtnText: texts?.['spaces.mySettings.logout'],
    sspBtnText: texts?.['spaces.mySettings.header.ssp'],
    switchToClassicBtnText: texts?.['spaces.mySettings.header.switch.to.classic'] || 'Switch to Classic version',
    footerSaveBtnText: texts?.['spaces.mySettings.footer.saveBtn'],
    footerCancelBtnText: texts?.['spaces.mySettings.footer.cancelBtn'],
    profileTabTitle: texts?.['spaces.mySettings.tabs.profile.title'],
    myInformationHeaderText: texts?.['spaces.mySettings.tabs.profile.myInformation'],
    firstNameLabelText: texts?.['spaces.mySettings.tabs.profile.myInformation.firtName'],
    lastNameLabelText: texts?.['spaces.mySettings.tabs.profile.myInformation.lastName'],
    displayNameLabelText: texts?.['spaces.mySettings.tabs.profile.myInformation.displayName'],
    emailLabelText: texts?.['spaces.mySettings.tabs.profile.myInformation.email'],
    secondaryEmailLabelText: texts?.['spaces.mySettings.tabs.profile.myInformation.secondaryEmail'],
    phoneLabelText: texts?.['spaces.mySettings.tabs.profile.myInformation.phone'],
    mobileLabelText: texts?.['spaces.mySettings.tabs.profile.myInformation.mobile'],
    mobileHintText: texts?.['spaces.mySettings.tabs.profile.myInformation.mobile.hint'],
    outgoingEmailHeaderText: texts?.['spaces.mySettings.tabs.profile.outgoingEmail'],
    signatureLabelText: texts?.['spaces.mySettings.tabs.profile.outgoingEmail.signature'],
    signaturePlaceholderText: texts?.['spaces.mySettings.tabs.profile.outgoingEmail.signature.placeholder'],
    signatureHintText: texts?.['spaces.mySettings.tabs.profile.outgoingEmail.signature.hint'],
    outOfOfficeHeaderText: texts?.['spaces.mySettings.tabs.profile.outOfOffice'],
    outOfOfficeLabelText: texts?.['spaces.mySettings.tabs.profile.outOfOffice.outOfOffice'],
    assignOutOfOfficeText: texts?.['spaces.mySettings.tabs.profile.assignOutOfOffice'],
    passwordTabTitle: texts?.['spaces.mySettings.tabs.password.title'],
    changePasswordHeaderTitle: texts?.['spaces.mySettings.tabs.password.changePassword'],
    ldapUserText: texts?.['spaces.mySettings.tabs.password.ldapUser'],
    currentPasswordLabelText: texts?.['spaces.mySettings.tabs.password.currentPasswordLabel'] || 'Current Password',
    passwordLabelText: texts?.['spaces.mySettings.tabs.password.passwordLabel'],
    passwordValidationText: texts?.['spaces.mySettings.tabs.password.validation'],
    passwordCriteriaText: texts?.['spaces.mySettings.tabs.password.criteria'],
    passwordValidationLengthText: texts?.['spaces.mySettings.tabs.password.validation.length'],
    passwordValidationUppercaseText: texts?.['spaces.mySettings.tabs.password.validation.uppercase'],
    passwordValidationLowercaseText: texts?.['spaces.mySettings.tabs.password.validation.lowercase'],
    passwordValidationNumberText: texts?.['spaces.mySettings.tabs.password.validation.number'],
    passwordValidationSpecialCharsText: texts?.['spaces.mySettings.tabs.password.validation.specialChars'],
    confirmPasswordLabelText: texts?.['spaces.mySettings.tabs.password.confirmPasswordLabel'],
    currentPasswordErrorText:
      texts?.['spaces.mySettings.tabs.password.currentPassword.error'] || 'The Current Password is wrong',
    confirmPasswordErrorText: texts?.['spaces.mySettings.tabs.password.confirmPassword.error'],
    preferencesTabTitle: texts?.['spaces.mySettings.tabs.preferences.title'],
    localizationHeaderText: texts?.['spaces.mySettings.tabs.preferences.localization'],
    languageLabelText: texts?.['spaces.mySettings.tabs.preferences.localization.language'],
    timezoneLabelText: texts?.['spaces.mySettings.tabs.preferences.localization.timezone'],
    dateFormatLabelText: texts?.['spaces.mySettings.tabs.preferences.localization.dateFormat'],
    firstDayOfWeekLabelText: texts?.['spaces.mySettings.tabs.preferences.localization.firstDayOfWeek'],
    dashboardHeaderText: texts?.['spaces.mySettings.tabs.preferences.dashboard'],
    dashboardRotateLabelText: texts?.['spaces.mySettings.tabs.preferences.dashboard.dashboardRotate'],
    notificationsHeaderText: texts?.['spaces.mySettings.tabs.preferences.notifications'],
    emailNotificationsLabelText: texts?.['spaces.mySettings.tabs.preferences.notifications.emailNotifications'],
    americanDisplayText: texts?.['spaces.mySettings.tabs.preferences.dateFormat.american'],
    europeanDisplayText: texts?.['spaces.mySettings.tabs.preferences.dateFormat.european'],
    sundayDisplayText: texts?.['spaces.mySettings.tabs.preferences.firstDayOfWeek.sunday'],
    mondayDisplayText: texts?.['spaces.mySettings.tabs.preferences.firstDayOfWeek.monday'],
    submitText: texts?.['spaces.login.submit'],
    skip: texts?.['spaces.switchToClassicFeedback.skip'] || 'Skip',
    switchFeedbackTitle:
      texts?.['spaces.switchToClassicFeedback.switchFeedbackTitle'] ||
      'Would you mind sharing your reason for switching back to Classic?',
    switchFeedbackMissingFeatures:
      texts?.['spaces.switchToClassicFeedback.switchFeedbackMissingFeatures'] || 'Missing features',
    switchFeedbackTooComplicated:
      texts?.['spaces.switchToClassicFeedback.switchFeedbackTooComplicated'] || 'Too complicated for me',
    switchFeedbackPerformanceIssues:
      texts?.['spaces.switchToClassicFeedback.switchFeedbackPerformanceIssues'] || 'Performance issue',
    switchFeedbackTemporary:
      texts?.['spaces.switchToClassicFeedback.switchFeedbackTemporary'] || 'Just a temporary action',
    switchFeedbackCareElobrate:
      texts?.['spaces.switchToClassicFeedback.switchFeedbackCareElobrate'] || 'Care to elaborate?',
  };
  return componentTexts;
}
