import { useRef } from 'react';
import { useSelector } from 'react-redux';

import { selectActionLine } from 'features/resolutionPanel/middlePanel/auditLog/store/slice';
import ActionLine from 'features/resolutionPanel/middlePanel/auditLog/actionLine';
import { useUserInfo } from 'remote-state/userServiceHooks';

import { QUERIES_KEYS } from 'constant';
import { ReadOnlyEditor } from './readOnlyEditor';
import { checkPermissions } from './utils';

export const EditableEditor = (props) => {
  const readOnlyWrapRef = useRef(null);
  const { isNewEditor, activeIndex } = useSelector(selectActionLine);
  const { isEdited, auditLogId, toggleAuditLogsProperty, logInformation, showKebabMenu, editorType } = props;
  const userInfo = useUserInfo(QUERIES_KEYS.CURRENT_USER_PERMISSIONS);

  const handleCancel = () => {
    toggleAuditLogsProperty({ auditLogIds: [auditLogId], property: 'isEdited' });
  };

  return isEdited && !isNewEditor && activeIndex === auditLogId ? (
    <ActionLine handleCancel={handleCancel} auditLogId={auditLogId} editorType={editorType} />
  ) : (
    <ReadOnlyEditor
      {...props}
      ref={readOnlyWrapRef}
      showKebabMenu={showKebabMenu && checkPermissions(userInfo, logInformation)}
    />
  );
};
