import { endOfDay, startOfDay } from 'date-fns';
import moment from 'moment-timezone';
import { useTexts } from 'common/components/dropdownSelect/hooks';
import useDateTimeInfo from 'common/utils/hooks/useDateTimeInfo';
import { useCallback, useMemo } from 'react';
import { CONSTANTS } from '../../datePicker/constants';

export const getDisplayedFormat = (dateFormat) => {
  switch (dateFormat) {
    case CONSTANTS.BACKEND_AMERICAN_DATE_TIME_FORMAT:
    case CONSTANTS.AMERICAN_DATE_FORMAT:
      return CONSTANTS.AMERICAN_DATE_FORMAT;
    case CONSTANTS.BACKEND_EUROPIAN_DATE_TIME_FORMAT:
    case CONSTANTS.EUROPIAN_DATE_FORMAT:
      return CONSTANTS.EUROPIAN_DATE_FORMAT;
    default:
      return CONSTANTS.AMERICAN_DATE_FORMAT;
  }
};

const defaultRangeTitles = {
  today: 'Today',
  yesterday: 'Yesterday',
  thisWeek: 'This Week',
  thisMonth: 'This Month',
  last30Days: 'Last 30 Days',
};

const defaultRangeValues = {
  today: 'today',
  yesterday: 'yesterday',
  thisWeek: 'thisWeek',
  thisMonth: 'thisMonth',
  last30Days: 'last30Days',
};

export const useDateSelectOptions = () => {
  const { today, yesterday, thisWeek, thisMonth, last30Days } = useTexts();

  const dates = useMemo(
    () => [
      {
        valueKey: 1,
        valueCaption: today || defaultRangeTitles.today,
        values: defaultRangeValues.today,
      },
      {
        valueKey: 2,
        valueCaption: yesterday || defaultRangeTitles.yesterday,
        values: defaultRangeValues.yesterday,
      },
      {
        valueKey: 3,
        valueCaption: thisWeek || defaultRangeTitles.thisWeek,
        values: defaultRangeValues.thisWeek,
      },
      {
        valueKey: 4,
        valueCaption: thisMonth || defaultRangeTitles.thisMonth,
        values: defaultRangeValues.thisMonth,
      },
      {
        valueKey: 5,
        valueCaption: last30Days || defaultRangeTitles.last30Days,
        values: defaultRangeValues.last30Days,
      },
    ],
    [today, yesterday, thisWeek, thisMonth, last30Days],
  );

  return { dates };
};

export const getByTitleDateRange = (date) => {
  if (!date) return {};
  const rangeValue = date.values;
  const oneMinuteInMs = 60 * 1000;
  const sevenDaysInMs = 7 * 24 * 60 * 60 * 1000;
  const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
  const today = new Date();

  const endOfTodaysDateInMs = today.setHours(23, 59, 59, 999);
  const begginingOfTodaysDateInMs = today.setHours(0, 0, 0, 0);
  const valueCaption = defaultRangeTitles[rangeValue];
  const firstDayOfCurrnetMonth = new Date(today.getFullYear(), today.getMonth(), 1).getTime();
  const lastDayOfCurrnetMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getTime();
  switch (rangeValue) {
    case 'today':
      return {
        startDate: begginingOfTodaysDateInMs,
        endDate: endOfTodaysDateInMs,
        valueCaption,
      };
    case 'yesterday':
      return {
        startDate: begginingOfTodaysDateInMs - 24 * 60 * 60 * 1000,
        endDate: begginingOfTodaysDateInMs - oneMinuteInMs,
        valueCaption,
      };
    case 'thisWeek':
      return {
        startDate: begginingOfTodaysDateInMs - sevenDaysInMs,
        endDate: endOfTodaysDateInMs,
        valueCaption,
      };
    case 'thisMonth':
      return {
        startDate: firstDayOfCurrnetMonth,
        endDate: lastDayOfCurrnetMonth,
        valueCaption,
      };
    case 'last30Days':
      return {
        startDate: begginingOfTodaysDateInMs - thirtyDaysInMs,
        endDate: endOfTodaysDateInMs,
        valueCaption,
      };
    default:
      return { startDate: begginingOfTodaysDateInMs, valueCaption };
  }
};

export const useGetCustomDateRange = () => {
  const { timezone, dateFormat } = useDateTimeInfo();

  const getCustomDateRange = (date) => {
    if (!date) return {};

    const customDate = {
      valueCaption: moment.tz(date, timezone).format(dateFormat),
      startDate: Date.parse(startOfDay(date)),
      endDate: Date.parse(endOfDay(date)),
    };
    return { customDate };
  };
  return { getCustomDateRange };
};

export const useGetCustomDate = () => {
  const { timezone, dateFormat } = useDateTimeInfo();

  const getCustomDate = useCallback(
    (date) => moment.tz(date, timezone).format(getDisplayedFormat(dateFormat)),
    [dateFormat, timezone],
  );
  return { getCustomDate };
};
