import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledNoViewPermissions = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '30px',
  backgroundColor: theme.palette.background.pages_background,
  overflowY: 'auto',
  minWidth: '815px',

  svg: {
    width: '70px',
    height: '70px',
  },

  '.MuiTypography-h1': {
    fontWeight: '700',
    fontSize: '1.5rem',
  },

  '.MuiTypography-body1': {
    fontSize: theme?.fontSize?.medium,
  },

  '.MuiButton-root': {
    fontSize: theme?.fontSize?.small,
  },
}));
