export const handleCheckSum = async (file) => {
  if (file) {
      const fileBuffer = await file.arrayBuffer();
      const hashBuffer = await crypto.subtle.digest('SHA-256', fileBuffer);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray.map((byte) => `00${byte.toString(16)}`.slice(-2)).join('');

      return hashHex;
  }
};

export const handleFileExtension = (fileName) => {
    const parts = fileName.split('.');
    return parts.length > 1 ? parts[parts.length - 1] : '';
};

export const handleParams = (localVarUrlObj, localVarRequestOptions, localVarQueryParam = {}) => {
    const localVarHeaderParameter = {};
    const localVarQueryParameter = localVarQueryParam;

    const query = new URLSearchParams(localVarUrlObj.search);
    for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
    }
    localVarUrlObj.search = new URLSearchParams(query).toString();
    localVarRequestOptions.headers = { ...localVarHeaderParameter };

    return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
    };
};
