import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectToasterMessage, setToasterMessage } from 'store/globalSlice';

export const useToasterMessage = () => {
  const toasterMessage = useSelector(selectToasterMessage);
  const dispatch = useDispatch();
  let showFor = 3000;
  if (toasterMessage.showFor) {
    showFor = toasterMessage.showFor;
  }
  useEffect(() => {
    if (toasterMessage?.message) {
      const toasterTimeout = setTimeout(() => {
        dispatch(setToasterMessage({ message: null }));
      }, showFor);
      return () => {
        clearTimeout(toasterTimeout);
      };
    }
  }, [toasterMessage?.message, dispatch, showFor]);
  const handleClose = () => {
    if (toasterMessage.onClose) {
      toasterMessage.onClose();
    }
    dispatch(setToasterMessage({ message: null }));
  };
  return { toasterMessage, handleClose };
};

export default useToasterMessage;
