import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAssigneesList, useSaveSR } from 'remote-state/ticketServiceHooks';
import useDelayHover from 'common/utils/hooks/useDelayHover';
import Assignees from 'common/components/assignee/assignees';
import useValidateSrLockBeforeUpdate from '../../hooks/useValidateSrLockBeforeUpdate';
import { StyledAssigneeRender } from './AssigneeRender.styles';

export const AssigneeRender = memo(({ srId, required, disabled, hoverEffect, delayedHover, initialAssigned }) => {
  const { mutateAsync: saveSR } = useSaveSR(srId);
  const hoverRef = useRef();
  useDelayHover(hoverRef, delayedHover, hoverEffect);
  const [dropdownGroupName, setDropdownGroupName] = useState();
  const {
    admins: { data: admins },
    groups: { data: groups },
    adminsByGroup: { data: dropdownGroupList },
  } = useAssigneesList(dropdownGroupName);
  const [assignee, setAssignee] = useState(initialAssigned);

  useEffect(() => {
    setAssignee(initialAssigned);
  }, [initialAssigned]);

  const handleSaveSR = useCallback(
    async (newAssignees) => {
      const { assignee: admin, assignedGroup: group } = newAssignees;
      if (admin === assignee?.assignee && group === assignee?.assignedGroup) return;
      setAssignee({ assignee: admin, assignedGroup: group });
      const object = { assignee: admin, assignedGroup: group };
      await saveSR({ id: srId, requestParams: object, queueUpdate: true });
    },
    [assignee, saveSR, srId],
  );

  const { handleUpdateSR } = useValidateSrLockBeforeUpdate({ srId, handleSaveSR });

  const currentAssigned = useMemo(() => {
    const assigneeUserFiltered = admins?.filter((admin) => assignee?.assignee === admin.userName);
    const assigneeId = assigneeUserFiltered && assigneeUserFiltered.length ? assigneeUserFiltered[0].id : undefined;
    const profileImage =
      assigneeUserFiltered && assigneeUserFiltered.length ? assigneeUserFiltered[0].profileImage : undefined;
    const calculatedUserName =
      assigneeUserFiltered && assigneeUserFiltered.length ? assigneeUserFiltered[0].calculatedUserName : undefined;
    const groupsFiltered = groups?.filter((group) => assignee?.assignedGroup === group.groupName);
    const groupId = groupsFiltered && groupsFiltered.length ? groupsFiltered[0].id : undefined;
    return {
      admin: { userName: assignee?.assignee, id: assigneeId, profileImage, calculatedUserName },
      group: { groupName: assignee?.assignedGroup, id: groupId },
    };
  }, [admins, groups, assignee]);

  return (
    <StyledAssigneeRender ref={hoverRef}>
      <Assignees
        currentAssigned={currentAssigned}
        required={required}
        admins={admins}
        groups={groups}
        dropdownGroupName={dropdownGroupName}
        dropdownGroupList={dropdownGroupList}
        setDropdownGroupName={setDropdownGroupName}
        disabled={disabled}
        withTitle={false}
        bigAvatar
        onChange={handleUpdateSR}
      />
    </StyledAssigneeRender>
  );
});
