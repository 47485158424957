import { formatToTimeZone } from 'date-fns-timezone';
import { format, endOfDay, startOfDay } from 'date-fns';
import useDateTimeInfo from 'common/utils/hooks/useDateTimeInfo';

export default function useGetCustomDateRange() {
  const { timezone, dateFormat } = useDateTimeInfo();

  const getCustomDateRange = (date) => {
    if (!date) return {};
    const finalFormat = dateFormat || "MM/dd/yyyy hh:mmaaaaa'm'";
    const customDate = {
      valueCaption: timezone
        ? formatToTimeZone(date, finalFormat, { timeZone: timezone })
        : format(new Date(date), finalFormat),
      startDate: Date.parse(startOfDay(date)),
      endDate: Date.parse(endOfDay(date)),
    };

    return customDate;
  };
  return { getCustomDateRange };
}
