import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTemplateToConvertWrapper = styled('div')(() => ({
  marginTop: '24px',
  marginBottom: '10px',
  '.convert-template-title': {
    paddingLeft: '8px',
    paddingBottom: '2px',
  },
}));

export const StyledTemplateFieldWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const StyledTemplateDropdownValue = styled(Box)(({ theme }) => ({
    cursor: 'pointer',
  borderRadius: theme.shape.border.borderRadius,
  alignItems: 'center',
  gap: '6px',
  display: 'flex',
  padding: theme.spacing(0.5, 1),
  fontSize: theme?.fontSize?.medium,
  color: theme?.palette?.text?.title_txt,
  maxWidth: '452px',
  '&:hover': {
    backgroundColor: theme.palette.background.bg_hover_alt,
  },
  svg: {
    width: '16px',
  },
  '.MuiTypography-root': {
    fontSize: theme?.fontSize?.medium,
  },
}));

export const messagePromptStyle = {
  MuiPaper: {
    minWidth: '500px',
  },
  MuiDialogTitle: {
    fontFamily: 'Roboto-Regular',
    lineHeight: '24px',
    fontSize: '18px',
  },
};
