import { useState, useEffect } from 'react';
import { getAttributeQueryOption } from 'features/resolutionPanel/middlePanel/Workflows/api/workflowQueries';

export function useGetAllOptions(name) {
  const [allOptions, setAllOptions] = useState([]);
  useEffect(() => {
    async function fetchOptions() {
      const queryOption = getAttributeQueryOption({ fieldName: name });
      const response = await queryOption.queryFn();
      setAllOptions(response);
    }
    fetchOptions();
  }, [name]);

  return allOptions;
}
