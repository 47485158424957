import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldAttributes } from 'remote-state/ticketServiceHooks';
import { useDispatch } from 'react-redux';
import { STATUS_FIELD_ID } from 'features/srPanel/consts';
import Tooltip from 'common/components/tooltip';
import { ReactComponent as ClockIcon } from 'images/icons/clock.svg';
import { ReactComponent as ArrowDownIcon } from 'images/icons/arrowdown.svg';
import { getStatusListByValueClasses } from 'services/ticketService';
import { DropdownSelect, itemClassNames } from 'common/components/dropdownSelect';
import { StyledAssignessFilter } from '../style';
import useTexts from '../useTexts';
import { COLUMN_FILTER_FIELDTYPE, COLUMN_FILTER_TYPES, STATUS_CLASSES_NAMES } from '../../grid/constants';
import { addColumnFilter, removeColumnFilter } from '../../slice';
import { StyledDisplayKeys } from './style';

export default function StatusesFilter({ statusFilter }) {
  const dispatch = useDispatch();
  const fieldAttributesInput = useMemo(() => [{ fieldId: STATUS_FIELD_ID }], []);
  const statusList = useFieldAttributes(fieldAttributesInput, true)?.[0]?.data?.values;
  // const dropDownRef = useRef();
  const [anchorHtmlElement, setAnchorHtmlElement] = useState(null);
  const [selection, setSelection] = useState({
    values: statusFilter?.values || [],
    valueClasses: statusFilter?.valueClasses || [],
  });
  const { allStatuses, getColumnText } = useTexts();
  const [statuses, setStatuses] = useState(statusList);

  useEffect(() => {
    setSelection({ values: statusFilter?.values || [], valueClasses: statusFilter?.valueClasses || [] });
  }, [statusFilter]);

  useEffect(() => {
    setStatuses(statusList);
  }, [statusList]);

  // const keyDownHandler = (event) => {
  //   if (event.key === 'Enter') {
  //     if (dropDownRef.current?.closest('.ag-cell-focus')) {
  //       setAnchorHtmlElement(dropDownRef.current);
  //     }
  //   } else if (event.key === 'Escape') {
  //     setAnchorHtmlElement(null);
  //   }
  // };
  // useEffect(() => {
  //   window.addEventListener('keydown', keyDownHandler);
  //   return () => {
  //     window.removeEventListener('keydown', keyDownHandler);
  //   };
  // }, [dropDownRef, setAnchorHtmlElement]);

  const updateGridConfiguration = useCallback(
    (filteredSelectedStatuses, displayKeys = []) => {
      const field = 'status';
      const fieldType = COLUMN_FILTER_FIELDTYPE.set;
      const filterObject = {
        filter: {
          fieldId: STATUS_FIELD_ID,
          field,
          values: filteredSelectedStatuses.values,
          valueClasses: filteredSelectedStatuses.valueClasses,
          name: field,
          type: COLUMN_FILTER_TYPES.equals,
          fieldType: { id: 1, typeName: fieldType },
          displayKeys,
        },
        field,
      };

      if (filteredSelectedStatuses.values.length || filteredSelectedStatuses.valueClasses.length) {
        dispatch(addColumnFilter(filterObject));
      } else {
        dispatch(removeColumnFilter({ field }));
      }
    },
    [dispatch],
  );

  const handleClean = useCallback(() => {
    setSelection({ values: [], valueClasses: [] });
    dispatch(removeColumnFilter({ field: 'status' }));
  }, [dispatch]);

  const handleCheck = useCallback(
    (selection, optionsMap) => {
      const valuesForDisplay = selection.values.map((selectedStatus) => optionsMap[selectedStatus]?.value);
      updateGridConfiguration(selection, [valuesForDisplay.join(', ')]);
    },
    [updateGridConfiguration],
  );

  const handleClose = useCallback(() => {
    setAnchorHtmlElement(null);
  }, []);

  const backendQueryConfig = useMemo(
    () => ({
      fetchingPromise: (query) => getStatusListByValueClasses({ query }),
    }),
    [],
  );

  const statusesDisplayKeys = useMemo(() => {
    if (statusFilter) {
      const statusesDisplayKeys = statusFilter.displayKeys?.join(', ') || '';
      if (statusFilter.valueClasses?.length > 0) {
        return (
          <StyledDisplayKeys>
            {statusFilter.valueClasses.map((valueClass) => (
              <span key={valueClass} className="valueClass">
                {getColumnText(STATUS_CLASSES_NAMES[valueClass])}
              </span>
            ))}
            {statusesDisplayKeys && <>, {statusesDisplayKeys}</>}
          </StyledDisplayKeys>
        );
      }
      return statusesDisplayKeys || allStatuses;
    }
    return allStatuses;
  }, [statusFilter, allStatuses, getColumnText]);

  return (
    <>
      <StyledAssignessFilter
        data-testid="status-filter"
        data-cy="status-filter"
        onClick={(event) => setAnchorHtmlElement(event.currentTarget)}
      >
        <ClockIcon />
        <Tooltip
          placement="top"
          isTruncatedText
          title={statusesDisplayKeys}
          text={statusesDisplayKeys}
          style={{ lineHeight: 1.2858 }}
        />
        <ArrowDownIcon />
      </StyledAssignessFilter>
      <DropdownSelect
        options={statuses}
        selection={selection}
        anchor={anchorHtmlElement}
        stylingVariant={itemClassNames.status}
        backendQueryConfig={backendQueryConfig}
        handleClean={handleClean}
        handleClose={handleClose}
        handleChange={handleCheck}
        isMultiple
        isWideDropdown
        isSelectAllHidden
        hasHierarchy
        valueClassesNames={Object.fromEntries(Object.entries(STATUS_CLASSES_NAMES).map(([key, value]) => [key, getColumnText(value)]))}
      />
    </>
  );
}
