import { useState, useEffect, useRef } from 'react';
import { ReactComponent as ToocanOutlinedIcon } from 'images/icons/generativeAi/toocan_outlined.svg';
import mediumYellowStars from 'images/icons/generativeAi/yellow_stars_24x24.gif';
import usePendoTrackEvents from 'common/utils/hooks/usePendoTrackEvents';
import { PENDO_TRACK_EVENTS } from 'constants/index';
import { useGetAIConfigData } from 'remote-state/aiConfigDataHook';
import { QUERIES_KEYS } from 'constant';
import { StyledSummaryPopup } from './StyledSummaryPopup';
import { useTexts } from './useTexts';
import { useTicketSummaryByIdQuery } from './summaryPopupHooks';
import { Sentiment } from './Sentiment';

export const SummaryPopup = ({ title: propsTitle, srId: propsSrId, popperRef }) => {
  const [summary, setSummary] = useState(null);
  const [sentiment, setSentiment] = useState(null);
  const [srId] = useState(propsSrId);
  const { isInitialLoading, isFetching, status, data } = useTicketSummaryByIdQuery(srId);
  const { data: assistiveAI } = useGetAIConfigData(QUERIES_KEYS.ASSISTIVE_AI);
  const [title, setTitle] = useState(propsTitle);
  const texts = useTexts();
  const pendoTrackEvents = usePendoTrackEvents();
  const ref = useRef({ firstRender: true });

  useEffect(() => {
    if (!isFetching) {
      if (status === 'success') {
        setSummary(data.summary);
        setTitle((currTitle) => currTitle || data.title);
        setSentiment(data.sentiment);
        if (ref.current.firstRender) {
          ref.current.firstRender = false;
          pendoTrackEvents(PENDO_TRACK_EVENTS.TICKET_SUMMARY_AND_SENTIMENT_DISPLAYED, {
            srId,
          });
        }
      } else if (status === 'error') {
        setSummary(texts.summaryNotAvailableCheckBackSoon);
        if (ref.current.firstRender) {
          ref.current.firstRender = false;
          pendoTrackEvents(PENDO_TRACK_EVENTS.TICKET_SUMMARY_ERROR, {
            srId,
          });
        }
      }
    }
  }, [status, isFetching, data, texts.summaryNotAvailableCheckBackSoon, popperRef, srId, pendoTrackEvents]);

  useEffect(() => {
    if (!isInitialLoading && popperRef?.current) {
      popperRef.current.update();
    }
  }, [popperRef, isInitialLoading, pendoTrackEvents]);

  return (
    <StyledSummaryPopup>
      <div className="header-container" data-testid="header">
        <ToocanOutlinedIcon className="header-icon" />
        <span data-testid="title">{title}</span>
      </div>
      <div className={`content ${isInitialLoading ? 'loading' : ''}`} data-testid="content">
        {isInitialLoading ? (
          <>
            <img className="loading-stars-icon" data-testid="loader" src={mediumYellowStars} alt="" />
            {texts.loading}
          </>
        ) : (
          <>
            {assistiveAI?.AICaseSummarizations && <div>{summary}</div>}
            {(assistiveAI?.AIEmotions && sentiment) && (
              <div className="sentiment-container" data-testid="sentiment">
                <span className="sentiment-label">{texts.sentimentLabel}</span>
                <Sentiment sentiment={sentiment} />
              </div>
            )}
          </>
        )}
      </div>
    </StyledSummaryPopup>
  );
};
