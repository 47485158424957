import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { editorActionTypes } from '../constants/editorActionTypes';

export const StyledActionLine = styled(Box, {
  shouldForwardProp: (prop) => !['isExpanded', 'editorType'].includes(prop),
})(({ isExpanded, editorType }) => {
  let commonStyle = {};
  if (editorType === editorActionTypes.MESSAGE) {
    commonStyle = {
      '& .richtext-wrap': {
        padding: '12px 24px !important',
        '& .fr-box.fr-basic.fr-bottom': {
          paddingTop: '12px',
        },
        '& .editor-header .action-icon-btn': {
          right: '0',
        },
      },
    };
  }

  return {
    display: 'flex',
    width: '-webkit-fill-available',
    maxWidth: 'calc(100% - 44px)',
    flexDirection: 'column',
    gap: '6px',
    '.attachments-wrapper': {
      display: isExpanded ? 'flex' : 'none !important',
      flexDirection: 'row',
    },
    ...commonStyle,
  };
});

export const StyledDefaultActionLine = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme?.palette?.grey?.scroll}`,
  borderRadius: '10px',
  padding: '10px',
}));
