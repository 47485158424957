import { useMemo, memo, useContext } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import SearchField from 'common/components/searchField';
import List from '@mui/material/List';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import CircularProgress from 'common/components/circularProgress';
import TemplateFieldItem from '../TemplateFieldItem';
import { StyledTemplateFieldsPanel, StyledFieldsList, StyledFieldsWrapper, StyledLoading } from './StyledTemplateFieldsPanel';
import useTexts from '../useTexts';
import { TemplateBuilderContext } from '../TemplateBuilderPanel/context';

function TemplateFieldsPanel(props) {
  const { fields, fetchNextPage, dataLength, hasNextPage, searchValue, isFetching, onSearchChange, onSearchClear } = props;
  const { fieldsText, searchFieldsPlaceholder } = useTexts();
  const getNextPage = async () => {
    fetchNextPage();
  };

  const TemplateFieldItemWrap = (props) => {
    const { id, ...rest } = props;
    const { isDragging: draggedId } = useContext(TemplateBuilderContext);
    const isDragging = useMemo(() => String(draggedId) === String(id), [draggedId, id]);
    return <TemplateFieldItem {...rest} id={`field-${id}`} isDragging={isDragging} />;
  };

  const fieldList = useMemo(() => {
    const contextSortable = [];
    const elems = [];
    fields.forEach((field) => {
      elems.push(
        <TemplateFieldItemWrap
          key={field?.fieldName}
          id={field?.id}
          isFieldInUse={field?.inUse}
          fieldName={field?.displayName}
          fieldType={field?.ticketTemplateFieldType?.id}
        />,
      );
      contextSortable.push(field?.displayName);
    });
    return {
      contextSortable,
      elems,
    };
  }, [fields]);

  return (
    <StyledTemplateFieldsPanel>
      <h3 className="fields-title">{fieldsText}</h3>
      <SearchField
        value={searchValue}
        placeholderText={searchFieldsPlaceholder}
        onChange={onSearchChange}
        onClear={onSearchClear}
      />
      {isFetching ? (
        <StyledLoading>
          <CircularProgress size={40} />
        </StyledLoading>
      ) : (
        <StyledFieldsWrapper>
          <SortableContext
            id="fields-panel"
            className="fields-panel-wrapper"
            items={fieldList.contextSortable}
            strategy={rectSortingStrategy}
          >
            <StyledFieldsList id="scrollableFieldsList">
              <InfiniteScroll
                dataLength={dataLength}
                hasMore={hasNextPage}
                next={getNextPage}
                scrollableTarget="scrollableFieldsList"
              >
                <List className="fields-list">{fieldList.elems}</List>
              </InfiniteScroll>
            </StyledFieldsList>
          </SortableContext>
        </StyledFieldsWrapper>
      )}
    </StyledTemplateFieldsPanel>
  );
}

export default memo(TemplateFieldsPanel);
