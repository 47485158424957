import { useEffect } from 'react';
import { StyledTimeFormat, StyledTimeFormatList } from './style';

const timeFormatList = ['am', 'pm'];

export default function TimeFormatList(props) {
  const { hour, currentTimeFormat, onSelectNewTimeFormat } = props;

  // If there is no selected format time (am / pm), set the format time depending on the hour.
  useEffect(() => {
    if (!currentTimeFormat) {
      if (hour >= 12) {
        onSelectNewTimeFormat('pm');
      } else {
        onSelectNewTimeFormat('am');
      }
    }
  }, [hour, onSelectNewTimeFormat, currentTimeFormat]);

  const isSelected = (newTimeFormat) => {
    if (!currentTimeFormat) {
      return false;
    }
    return newTimeFormat === currentTimeFormat;
  };

  const selectFormatHandler = (newTimeFormat) => (event) => {
    if (newTimeFormat === currentTimeFormat) {
      event.preventDefault();
      return;
    }
    onSelectNewTimeFormat(newTimeFormat);
  };

  return (
    <StyledTimeFormatList data-testid="time-format">
      {timeFormatList.map((timeFormat, index) => (
        <StyledTimeFormat key={index} isSelected={isSelected(timeFormat)} onClick={selectFormatHandler(timeFormat)} aria-selected={isSelected(timeFormat) ? true : undefined}>
          {timeFormat}
        </StyledTimeFormat>
      ))}
    </StyledTimeFormatList>
  );
}
