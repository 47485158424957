import { useMutation, useQuery } from '@tanstack/react-query';

import { getLocalesList, getTimezonesList, saveMySettings } from 'services/mySettingsService';

export function useTimezonesList() {
  return useQuery(['timezones'], getTimezonesList, {
    placeholderData: [],
  });
}

export function useLocalesList() {
  return useQuery(['locales'], getLocalesList, {
    placeholderData: [],
  });
}

export function useSaveSettings() {
  return {
    ...useMutation(saveMySettings),
  };
}
