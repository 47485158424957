import { memo, useCallback, useRef, useState } from 'react';
import { ClickAwayListener } from '@mui/material';
import { InlineFieldContainer } from 'common/components/controls/InlineField/InlineFieldContainer';
import { StyledMenu, StyledTextAreaWrapper, TextAreaRenderer } from './TextAreaRender.styles';
import { TextAreaEditor } from '../TextAreaEditor';
import MissingTemplateTooltip from '../CellComponent/MissingTemplateTooltip';

export const TextAreaRender = memo((props) => {
  const { required, fieldName, className = '', disabled = false, text, multiline, handleSaveValue, notInTemplate } = props;

  const labelRef = useRef(null);
  const textAreaRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentValue, setCurrentValue] = useState(typeof text !== 'undefined' && text !== null ? String(text) : '');
  const isOpened = !!anchorEl;

  const handleSave = (obj) => {
    const text = obj[fieldName];
    handleSaveValue(obj);
    setCurrentValue(text);
  };

  const handleOpen = useCallback(() => {
    if (!disabled) {
      setAnchorEl(labelRef.current);
    }
  }, [disabled]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClickAway = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const isEmptyRequiredValue = required && !currentValue;

  return notInTemplate ? <MissingTemplateTooltip /> : (
    <TextAreaRenderer ref={labelRef} isEmptyRequiredValue={isEmptyRequiredValue}>
      <InlineFieldContainer
        disabled
        fullwidth
        fieldName={fieldName}
        onClick={handleOpen}
        required={required}
        text={currentValue}
      />
      <StyledMenu
        open={isOpened}
        anchorEl={anchorEl}
        placement="bottom-start"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <StyledTextAreaWrapper className={className}>
            <TextAreaEditor ref={textAreaRef}
              fullwidth
              handleClose={handleClose}
              required={required}
              multiline={multiline}
              fieldName={fieldName}
              text={currentValue}
              handleSaveValue={handleSave}
              showSummaryTooltip={false}
            />
          </StyledTextAreaWrapper>
        </ClickAwayListener>
      </StyledMenu>
    </TextAreaRenderer>
  );
});
