import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledContainer = styled(Box)(({ theme }) => ({
  padding: '10px 24px 0px 24px ',
  overflowY: 'auto',
  display: 'flex',
  width: '100%',
  //height: '100%',
  marginBottom: '24px',
  position: 'relative',
  '&.chatbot-open > div': {
    width: '70%',
  },

  /****** Custom main panel scrollbar ******/
  '@supports not selector(::-webkit-scrollbar)': {
    scrollbarColor: `${theme.palette.hover.avatar} transparent`,
    scrollbarWidth: 'thin',
  },
  /* width */
  '* ::-webkit-scrollbar': {
    width: '4px',
    height: '13px',
  },

  /* Handle */
  '* ::-webkit-scrollbar-thumb': {
    background: theme.palette.hover.avatar,
  },

  /* Handle on hover */
  '* ::-webkit-scrollbar-thumb:hover': {
    background: theme.palette.hover.avatar,
  },
}));
