import { useMemo } from 'react';

const useIndicators = ({ actionItem }) =>
  useMemo(() => {
    const indicators = [];

    if (!actionItem.disabled.value) indicators.push({ type: 'status', completed: !!actionItem.completed });
    if (actionItem.disabled.value) indicators.push({ type: 'disabled', disabledType: actionItem.disabled.reason });
    if (actionItem.dependency) {
      indicators.push({
        type: 'dependency',
        logicalOperatorIsAnd: actionItem.dependency.operatorAnd,
        dependencies: actionItem.dependency.dependencies,
      });
    }
    return indicators;
  }, [actionItem]);

export default useIndicators;
