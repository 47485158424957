import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTimeContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderLeft: `1px solid ${theme.palette.icons.bt_def_grey_reg}`,
  boxSizing: 'border-box',
  maxHeight: '244px',
  width: '100%',
}));

export const StyledHoursConatiner = styled(Box)(() => ({
  marginRight: '5px',
  width: '100%',
  boxSizing: 'border-box',
}));

export const StyledMinutesConteiner = styled(Box)(() => ({
  marginRight: '5px',
  width: '100%',
  boxSizing: 'border-box',
}));

export const StyledTimeFormat = styled(Box)(() => ({
  boxSizing: 'border-box',
}));
