import { Box, IconButton } from '@mui/material';

import { ReactComponent as CloseIcon } from 'images/icons/close.svg';

export default function XButton({ handleClick, iconButtonProps = {}, ...restProps }) {
  return (
    <Box id="x-btn" {...restProps}>
      <IconButton disableRipple onClick={handleClick} {...iconButtonProps}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
}
