import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHandleTicketLock, useTicketLockStatus } from 'remote-state/ticketServiceHooks';
import { useRbTexts } from 'remote-state/applicationHooks';
import { selectActiveUser } from 'store/userSlice';
import Section from 'common/components/section';
import TemplateFieldsGrid from 'features/srPanel/templateFieldsGrid';
import { getFieldsMap } from 'common/utils/fieldUtils';
import usePendoTrackEvents from 'common/utils/hooks/usePendoTrackEvents';
import { PENDO_TRACK_EVENTS } from 'constants/pendoTrackEvents';

const SrSection = (props) => {
  const { section, srId, handleDirtyFields, handleSaveSR, sr, hasEditPermissions, initExpanded, onExpansion } = props;
  const texts = useRbTexts();
  const userAccount = useSelector(selectActiveUser);
  const { data: lockingDetails } = useTicketLockStatus(srId);
  const { mutateAsync: lockSR } = useHandleTicketLock(srId);
  const pendoTrackEvents = usePendoTrackEvents();

  const fields = useMemo(() => section?.sectionRows?.flatMap((row) => row.fields), [section?.sectionRows]);
  const fieldsMap = useMemo(() => getFieldsMap(fields), [fields]);

  const handleFieldChange = useCallback(
    async (object) => {
      await handleSaveSR(object);
      const isRichText = Object.keys(object)[0] === 'richTextFields';
      const fieldName = isRichText ? JSON.parse(object.richTextFields)[0]?.columnName : Object.keys(object)[0];
      const fieldValue = isRichText ? object.richTextFields : object[Object.keys(object)[0]];
      const field = fieldsMap[fieldName];
      if (field.customColumn) {
        pendoTrackEvents(PENDO_TRACK_EVENTS.CUSTOM_COLUMN_POPULATED, {
          location: 'ticket',
          srId,
          fieldName,
          fieldValue,
        });
      }
    },
    [handleSaveSR, fieldsMap, pendoTrackEvents, srId],
  );

  const isTicketLocked = lockingDetails?.isLocked && lockingDetails?.lockingUser !== userAccount?.username;

  const handleTicketLock = useCallback(
    () => lockSR({ username: userAccount?.username, srId }),
    [lockSR, userAccount?.username, srId],
  );

  const handleExpansion = (value) => onExpansion(section, value);

  return (
    <Section
      section={section}
      id={`section_${section.name}`}
      sectionTitle={texts[section.name] ?? section.name}
      instructions={section.instructions}
      onExpansion={onExpansion ? handleExpansion : undefined}
      initExpanded={initExpanded}
    >
      <TemplateFieldsGrid
        isTicketPage
        fields={fields}
        sectionRows={section.sectionRows}
        handleDirtyFields={handleDirtyFields}
        handleFieldFocus={handleTicketLock}
        onFieldChange={handleFieldChange}
        isTicketLocked={isTicketLocked}
        hasEditPermissions={hasEditPermissions}
        sr={sr}
      />
    </Section>
  );
};

export default SrSection;
