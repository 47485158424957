import { useState, useRef, useCallback } from 'react';
import { useFormContext, useController } from 'react-hook-form';
import { formatInTimeZone } from 'date-fns-tz';
import DatePickerPopup from 'common/components/datePickerPopup';
import { getDisplayDateFormat } from 'common/components/datePicker/utils';
import useDateTimeInfo from 'common/utils/hooks/useDateTimeInfo';
import { ReactComponent as OpenPickerIcon } from 'images/icons/icon_Schedule.svg';

import { TextField } from '../textField';
import { StyledDatePicker } from './style';
import { adaptDateFormat } from './utils';

export const DatePicker = ({ disabled, name, showTimePicker = true, readOnly, ...restProps }) => {
  const { control, onSubmit } = useFormContext() || {};
  const { field } = useController({ name, control, ...restProps });
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const datePickerRef = useRef();
  const { timezone, dateFormat: displayedDateFormat } = useDateTimeInfo();
  const dateFormat = getDisplayDateFormat(!showTimePicker, displayedDateFormat);
  const dateAsString = field.value ? formatInTimeZone(field.value, timezone, adaptDateFormat(dateFormat)) : '';

  const toggleDatePopup = useCallback(() => {
    if (readOnly || disabled) return;
    setIsPopupOpen((prev) => !prev);
  }, [readOnly, disabled]);

  const handleDateChange = useCallback(
    (newDate) => {
      const date = new Date(field.value);
      const dateTimestamp = date.getTime();
      if ((field.value || newDate) && dateTimestamp !== newDate) {
        field.onChange(newDate);
        onSubmit({ newValue: newDate, fieldName: name });
        field.onBlur();
      }
    },
    [field, name, onSubmit],
  );

  const handlePopupClose = useCallback(
    (value) => {
      handleDateChange(value);
      setIsPopupOpen(false);
    },
    [handleDateChange, setIsPopupOpen],
  );

  const handleClear = useCallback(() => {
    handlePopupClose(null);
  }, [handlePopupClose]);

  const handleBlur = (e) => {
    const menu = document.querySelector('#datepicker-menu');
    if (menu && !menu.contains(e.currentTarget) && e.relatedTarget) return;
    field.onBlur();
  };

  return (
    <StyledDatePicker data-testid="date-picker-text-field" onBlur={handleBlur}>
      {isPopupOpen && (
        <DatePickerPopup
          open={isPopupOpen}
          coord={datePickerRef.current?.getBoundingClientRect()}
          onClosePopup={handlePopupClose}
          date={field.value}
          clearValueHandler={handleClear}
          isOnlyDateFormat={!showTimePicker}
        />
      )}
      <TextField
        ref={datePickerRef}
        disabled={disabled}
        value={dateAsString}
        inputIcon={<OpenPickerIcon className="input-icon" />}
        handleClick={toggleDatePopup}
        name={name}
        readOnly={readOnly}
        dropdownType="date"
        {...restProps}
      />
    </StyledDatePicker>
  );
};
