import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledLoginSession = styled(Box)`
  height: 100%;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;

  .login-page {
    height: 100%;
    width: 100%;
  }
`;

export const StyledITSMLink = styled(Box)(({ theme }) => ({
  position: 'absolute',
  zIndex: 1,
  bottom: '49px',
  left: '50%',
  transform: 'translate(-50%, 50%)',
  a: {
    color: theme.palette.text.blue_user_name,
    textDecoration: 'none',
  },
  '@media screen and (max-height: 800px)': {
    bottom: '20px',
  },
}));
