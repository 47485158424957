import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledAuditLogEvent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'srPanelCollapsed' && prop !== 'logType' && prop !== 'isLogTypeCreateTicket',
})(({ theme, srPanelCollapsed }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',

  '*': {
    cursor: 'default !important',
  },

  '.event-wrapper': {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  '.due-date-wrapper': {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    width: '80px',
    position: 'relative',
  },
  '.due-date-text': {
    fontSize: '12px',
    color: theme.palette.text.txt_default,
  },
  '.red-line': {
    display: 'flex',
    position: 'absolute',
    width: '100%',
    height: '5px',
    background: theme.palette.labels.bt_red_reg,
    borderRadius: '4px',
    bottom: '3px',
  },
  '.detailed-avatar-wrapper': {
    marginRight: '4px',
  },

  '.event-action-wrapper': {
    display: 'flex',
    flexDirection: 'row',
    whiteSpace: 'break-spaces',
  },
  '.event-action-username': {
    whiteSpace: 'nowrap',
    color: theme.palette.text.txt_default,
    fontFamily: theme.fonts.robotoRegular,
    fontSize: theme.fontSize.main,
    fontWeight: '400',
    lineHeight: '20px',
    padding: '4px 6px',
  },
  '.event-action-source': {
    whiteSpace: 'nowrap',
    color: theme.palette.text.secondary_txt,
    fontFamily: theme.fonts.robotoRegular,
    fontSize: theme.fontSize.main,
    fontWeight: '400',
    lineHeight: '20px',
    padding: '4px 6px',
  },
  [theme.breakpoints.between(1024, 1440)]: {
    '.event-action-text': {
      display: srPanelCollapsed ? 'flex' : '',
    },
  },
  [theme.breakpoints.down(1024)]: {
    '.event-action-text': {
      display: 'flex',
    },
  },
}));
