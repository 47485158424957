import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledFooter = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '12px',

    '& .elements-footer': {
        display: 'flex',
        columnGap: '10px',
        alignItems: 'center',
    },
}));
