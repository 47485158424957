import { FileInputLabel } from 'common/components/uploadFile/uploadButton/fileInputLabel';
import { ReactComponent as AttachIcon } from 'images/icons/attachment-green.svg';
import { StyledWrapper, StyledActionButton, StyledAttachmentButton } from './style';
import useTexts from '../useTexts';
import CircularProgress from '../../../common/components/circularProgress';
import { StyledTypeBasedCircularProgress } from '../../../common/components/sysaidLibrary/Button/style';

const AttachmentButton = () => {
  const { addAttachmentText } = useTexts();
  return (
    <StyledAttachmentButton>
      <AttachIcon /> {addAttachmentText}
    </StyledAttachmentButton>
  );
}

export default function FloatingActions(props) {
  const { onSaveClicked, onCancelClicked, isSaving, isNotValid, isDisabled, onUploadAttachmentClicked } = props;
  const { cancelButtonText, createButtonText } = useTexts();
  const handleFilesChange = (event) => {
    onUploadAttachmentClicked(event.target.files);
    event.target.value = null;
  };

  return (
    <StyledWrapper className="floatingActionsFooter">
      <FileInputLabel sx={{ fontSize: 0 }} inputBtnComponent={AttachmentButton} handleChange={handleFilesChange} />
      <div className="actions-footer-right">
        <StyledActionButton disabled={isSaving} onClick={onCancelClicked} data-testid="cancel">
          {cancelButtonText}
        </StyledActionButton>
        <StyledActionButton
          isNotValid={isNotValid}
          disabled={isDisabled || isSaving}
          isCreateButton
          onClick={() => {
            if (isSaving) return;
            onSaveClicked();
          }}
          data-testid="create"
        >
          {isSaving && (
            <StyledTypeBasedCircularProgress>
              <CircularProgress size={16} thickness={4} />
            </StyledTypeBasedCircularProgress>
          )}
          {createButtonText}
        </StyledActionButton>
      </div>
    </StyledWrapper>
  );
}
