import { memo } from 'react';
import { CircularProgress } from '@mui/material';
import { StyledCircularProgress } from '../../style';

const LoadingOverlayMessage = memo((props) => (
  <StyledCircularProgress {...props}>
    <CircularProgress />
  </StyledCircularProgress>
));

export default LoadingOverlayMessage;
