import { useRouter } from '@tanstack/react-router';
import NavLink from 'features/navBar/navLink';
import NavItem from '..';

export default function NavItemLink({ title, icon, url, isOpenInNewTab, expanded }) {
  const router = useRouter();
  const isSelectedNavItemLink = router.latestLocation.pathname.replace('/spaces', '') === url;
  return (
    <NavLink className="nav-item-link" to={url} isOpenInNewTab={isOpenInNewTab}>
      <NavItem isActive={isSelectedNavItemLink} title={title} icon={icon} expanded={expanded} />
    </NavLink>
  );
}
