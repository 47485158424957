import Tooltip from 'common/components/tooltip';
import { StyledItem } from 'features/queue/grid/defaultCellRender/SelectRender/SelectRender.styles';
import useTexts from 'features/queue/grid/defaultCellRender/CategoryRender/useTexts';

export const CategoryChip = ({ onClick, categoryName, forceHover, onMouseEnter, onMouseLeave }) => {
  const { categoryNoValue } = useTexts();

  const displayedText = (categoryName === ' ' || categoryName === '') ? categoryNoValue : categoryName;

  return (
    <StyledItem isDefault isSuggestedCategoryChip forceHover={forceHover}>
      <span
        className="CategoryItem"
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        aria-hidden="true"
      >
        <span className="chip categoryItem suggested">
          <span className="text">
            <Tooltip isTruncatedText text={displayedText} placement="top-start" />
          </span>
        </span>
      </span>
    </StyledItem>
  );
};
