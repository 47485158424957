export function formatDate(date) {
  // Get the month, day, and year
  const month = date.getMonth() + 1; // Add 1 because getMonth() returns a zero-based index
  const day = date.getDate();
  const year = date.getFullYear();

  // Get the hours and minutes
  let hours = date.getHours();
  let minutes = date.getMinutes();

  // Determine if it's AM or PM
  const amOrPm = hours >= 12 ? 'pm' : 'am';

  // Convert to 12-hour format
  hours = hours % 12 || 12;

  // Pad the minutes with a leading zero if necessary
  minutes = minutes.toString().padStart(2, '0');

  // Combine all the values into the desired format
  const formattedDate = `${month}/${day}/${year} ${hours}:${minutes}${amOrPm}`;

  return formattedDate;
}
