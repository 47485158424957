import { startCase } from 'lodash-es';
import { convertToClientField } from 'common/utils/fieldUtils';
import { COMPANY_FIELD_KEY, QUEUE, VIEWS_KEYS } from './constants';
import {
  COLUMN_FILTER_FIELDTYPE,
  COLUMN_FILTER_STATUS,
  COLUMN_FILTER_TYPES,
  STATUS_CLASSES_NAMES,
} from './grid/constants';

const { defaultFilter, chipGap, chipMaxWidth, unassigned } = QUEUE;

export const getAllModeQuickFilter = (statusList) => {
  const filteredStatuses = statusList?.filter((item) => item.valueClass === 0);
  const statusFilterProps = filteredStatuses?.reduce(
    (acc, current) => ({
      values: [...acc.values, current.valueKey],
      displayKeys: [...acc.displayKeys, current.valueCaption],
    }),
    { values: [], displayKeys: [] },
  );

  const sorts = [
    { sortIndex: 0, sort: 'asc', colId: 'priority' },
    { sortIndex: 1, sort: 'desc', colId: 'dueDate' },
  ];

  const statusFilterList = {
    field: defaultFilter.field,
    values: statusFilterProps?.values,
    name: startCase(defaultFilter.field),
    type: COLUMN_FILTER_TYPES.equals,
    fieldType: COLUMN_FILTER_FIELDTYPE.number,
    displayKeys: [defaultFilter.title],
  };
  return {
    quickFilterDisplayName: 'All',
    quickFilterName: 'all',
    filters: [statusFilterList],
    sorts,
  };
};

export const orderFields = (obj1, obj2) => {
  const orderedFields = obj1.slice(0, 1); // copy first 3 elements as-is
  obj1
    .slice(1) // exclude first 3 elements
    .sort((a, b) => obj2.indexOf(a.id) - obj2.indexOf(b.id))
    .forEach((field) => orderedFields.push(field));
  return orderedFields;
};

export const categoriesFieldNames = {
  59: 'primaryCategory',
  60: 'secondaryCategory',
  61: 'thirdLevelCategory',
};

export const convertClientColumnConfig = (item) => {
  let currentItem = convertToClientField(item);
  if (item.id === 59 || item.id === 60 || item.id === 61) {
    currentItem = { ...currentItem, fieldName: categoriesFieldNames[String(item.id)] };
  }
  return currentItem;
};

export const getValueClassesNames = (fieldName, getColumnText) => {
  switch (fieldName) {
    case COLUMN_FILTER_STATUS:
      return Object.fromEntries(
        Object.entries(STATUS_CLASSES_NAMES).map(([key, value]) => [key, getColumnText(value)]),
      );
    default:
      return undefined;
  }
};

export const calculateChipListsByWidth = (chipContainerRef) => {
  const containerRefSpace = chipContainerRef.current.clientWidth;
  let chipsSpace = 0;
  const chips = [...chipContainerRef.current.childNodes];
  for (let i = 0; i <= chips.length - 1; i++) {
    const currentChipWidth = chips[i].offsetWidth + chipGap;
    chipsSpace = currentChipWidth + chipsSpace;
  }
  const remainingChipsSpace = containerRefSpace - chipsSpace;
  if (remainingChipsSpace < 0) {
    const numberOfChipsToDropdown = Math.ceil((remainingChipsSpace * -1) / chipMaxWidth);
    const splitIndex = chips.length - numberOfChipsToDropdown;
    return splitIndex;
  }
  return chips.length;
};

export const handleUnassigned = (chipName, chipDisplayKeys) => {
  if (chipName === COLUMN_FILTER_TYPES.assignee && chipDisplayKeys?.includes(COLUMN_FILTER_TYPES.none)) {
    return chipDisplayKeys.map((item) => (item === COLUMN_FILTER_TYPES.none ? unassigned.title : item));
  }
  return chipDisplayKeys;
};

export const getViewInitializedColumnConfig = (columnsConfig) => {
  const initializedColumnConfig = { ...columnsConfig };
  VIEWS_KEYS.forEach(({ propKey, propFieldKey }) => {
    initializedColumnConfig[propKey] = columnsConfig[propKey].filter(
      (column) => column[propFieldKey] !== COMPANY_FIELD_KEY,
    );
  });
  return initializedColumnConfig;
};

export const convertViewColumnsConfig = (columnsConfig) => ({
  ...columnsConfig,
  customSortModel: columnsConfig.sorts,
  customFilterModel: columnsConfig.filters,
  // Change the categories field name to match the expected names in the frontend (requires a future refactor to avoid this name change)
  columnsOrder: columnsConfig.columnConfiguration.map((field) => convertClientColumnConfig(field)) || [],
});
