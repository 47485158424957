import {
  FIRST_LEVEL_CATEGORY_FIELD_ID,
  SECOND_LEVEL_CATEGORY_FIELD_ID,
  THIRD_LEVEL_CATEGORY_FIELD_ID,
} from 'features/header/categories/constants';
import { convertValues } from 'features/srPanel/templateFieldsGrid/fieldSearchableDropdown/values-convert';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getFieldAttributes } from 'services/ticketService';

const useHiddenSelectOption = (field) => {
  const [hiddenSelectOption, setHiddenSelectOption] = useState();
  const expandedQueryKeys = useMemo(() => {
    const { fieldId, srType, value, queryKeys = [] } = field;
    switch (fieldId) {
      case FIRST_LEVEL_CATEGORY_FIELD_ID:
        return ['categoriesAttr', 'primaryCategory', srType, value];
      case SECOND_LEVEL_CATEGORY_FIELD_ID:
        return ['categoriesAttr', 'secondaryCategories', ...queryKeys, srType, value];
      case THIRD_LEVEL_CATEGORY_FIELD_ID:
        return ['categoriesAttr', 'thirdLevelCategories', ...queryKeys, srType, value];
      default:
        return ['fieldAttribute', fieldId, srType, value];
    }
  }, [field]);
  const queryFunction = useCallback((field) => {
    if (field) {
      const { fetchingPromise, fieldId, value } = field;
      if (fetchingPromise) {
        if (Number.isNaN(Number(value))) {
          return fetchingPromise(undefined);
        }
        return fetchingPromise({ id: value });
      }
      return getFieldAttributes(fieldId, { id: value });
    }
    return Promise.reject(new Error('no field'));
  }, []);
  const convertedList = useMemo(() => convertValues(field?.list), [field?.list]);
  const foundInList = convertedList.find((option) => Number(option?.id) === field.value);
  const { data, isFetching } = useQuery(expandedQueryKeys, () => queryFunction(field), {
    select: (result) => result.values,
    placeholderData: {},
    cacheTime: 1000 * 60,
    staleTime: 0,
    enabled: Boolean(!foundInList && field.value),
  });
  useEffect(() => {
    const { value } = field;
    if (foundInList) {
      setHiddenSelectOption(foundInList);
      return;
    }
    if (!isFetching) {
      const selected = data?.find((option) => Number(option?.id) === value);
      setHiddenSelectOption(selected);
    }
  }, [isFetching, data, field, foundInList]);
  return hiddenSelectOption;
};
export default useHiddenSelectOption;
