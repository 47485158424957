import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectFilterModel } from 'features/queue/slice';
import { COLUMN_FILTER_TYPES } from 'features/queue/grid/constants';
import DatePicker from '../datePicker';
import useTexts from './useTexts';

export default function DateEqualsPicker(props) {
  const { handleEqualsDateFilter, field, filterType } = props;
  const [selectedTime, setSelectedTime] = useState(null);
  const { chooseDate } = useTexts();

  const filterModel = useSelector(selectFilterModel);
  const selectedFilterModel = filterModel?.find((filter) => filter.field === field);
  const prevDate = selectedFilterModel && selectedFilterModel?.values.length > 0 && selectedFilterModel?.values[0];

  useEffect(() => {
    if (selectedFilterModel?.type === COLUMN_FILTER_TYPES.equals) {
      setSelectedTime(prevDate);
    } else {
      setSelectedTime(null);
    }
  }, [selectedFilterModel?.type, filterType, prevDate]);

  const setDefaultTime = () => new Date().getTime();

  const changeDateHandler = (date) => {
    setSelectedTime(date);
    if (date !== selectedTime) {
      handleEqualsDateFilter([date]);
    }
  };

  return (
    <div style={{ display: 'flex', marginTop: '12px', justifyContent: 'center' }}>
      <DatePicker
        isNewField
        disablePortal
        isCustomDateFilter
        date={selectedTime}
        placeholder={chooseDate}
        defaultDate={setDefaultTime()}
        onChange={changeDateHandler}
      />
    </div>
  );
}
