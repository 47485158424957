import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    defaultSearchPlaceholder: texts?.['spaces.header.current-assigned.defaultSearchPlaceholder'],
    drillDownSearchPlaceholder: texts?.['spaces.header.current-assigned.drillDownSearchPlaceholder'],
    teamsText: texts?.['spaces.header.current-assigned.teamsText'],
    assigneeText: texts?.['spaces.header.current-assigned.assigneeText'],
    assignToTeam: texts?.['spaces.header.current-assigned.assignToTeam'],
    membersText: texts?.['spaces.header.current-assigned.groupTitleMembers'],
    emptyResultsText: texts?.['spaces.header.current-assigned.emptyResults'],
    unassignedText: texts?.['spaces.header.current-assigned.unassigned'],
    loginUserText: texts?.['spaces.header.current-assigned.loginUserText'],
    clearAllText: texts?.['spaces.filters.clearAll'],
    selectAll: texts?.['spaces.searchableSetSelect.selectAll'],
    assigneeFilterTitle: texts?.['spaces.filters.assignee.title'],
    clearText: texts?.['spaces.select.clear'],
    mandatoryFieldError: texts?.['spaces.field.tooltip.error.mandatoryFieldError'] || 'Missing required field',
    getColumnText: (displayKey) => texts?.[displayKey],
  };

  return componentTexts;
}
