import { ButtonUnstyled } from '@mui/core';
import { styled } from '@mui/material/styles';

export const StyledPopupButton = styled(ButtonUnstyled, {
  shouldForwardProp: (prop) => prop !== 'variant',
})(({ theme, variant }) => {
  let backgroundColor = theme?.palette?.grey.bt_def_grey_reg;
  let color = theme?.palette?.generic?.primary;
  let backgroundColorHover = theme?.palette?.grey?.bt_def_grey_hover;
  let colorHover = theme?.palette?.text?.title;
  let backgroundColorDisabled = theme?.palette?.grey?.bt_def_grey_light;
  let colorDisabled;

  if (variant === 'primary') {
    backgroundColor = theme?.palette?.grey.bt_def_grey_reg;
    color = theme?.palette?.generic?.primary;
    backgroundColorHover = theme?.palette?.grey?.bt_def_grey_hover;
    backgroundColorDisabled = theme?.palette?.grey?.bt_def_grey_light;
  }
  if (variant === 'secondary') {
    backgroundColor = theme?.palette?.background?.bg_hover_alt;
    color = theme?.palette?.icons?.bt_def_grey_reg;
    backgroundColorHover = theme?.palette?.grey?.bt_def_grey_light;
    colorHover = theme?.palette?.text?.title_txt;
    colorDisabled = theme?.palette?.grey?.bt_alt_grey_light;
  }

  return {
    width: '81px',
    height: '32px',
    borderRadius: '60px',
    border: 'none',
    fontFamily: 'Roboto-regular',
    fontSize: '16px',
    cursor: 'pointer',
    color,
    backgroundColor,
    '&:hover': {
      backgroundColor: backgroundColorHover,
      color: colorHover,
    },
    '&:disabled': {
      backgroundColor: backgroundColorDisabled,
      color: colorDisabled && colorDisabled,
      cursor: 'initial',
    },
  };
});
