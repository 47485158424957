import { Box, Typography } from '@mui/material';
import { StyledSection } from './styles';

export default function MeMenuContentSection({ name, header, children }) {
  return (
    <StyledSection>
      <Box className="section-header">
        <Typography
          data-testid={`${name}-header`}
          paragraph
          sx={{ fontSize: '1.125rem', lineHeight: 1.334, paddingInline: 1, marginBottom: 3 }}
        >
          {header}
        </Typography>
      </Box>
      {children}
    </StyledSection>
  );
}
