import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StyledMinute, StyledMinutesList } from './style';
import { CONSTANTS } from './constants';

export default function MinutesList(props) {
  const { onSelectMinute, inputMinute } = props;
  const [selectedMinute, setSelectedMinute] = useState(null);

  useEffect(() => {
    if (inputMinute !== null && inputMinute !== undefined) {
      setSelectedMinute(String(inputMinute).padStart(2, '0'));
    }
  }, [inputMinute, selectedMinute]);

  const selectMinutesHandler = (minute) => {
    setSelectedMinute(minute);
    onSelectMinute(minute);
  };

  const isSelected = (minute) => minute === (selectedMinute?.length === 1 ? `0${selectedMinute}` : selectedMinute);

  return (
    <StyledMinutesList data-testid="minutes-list">
      {CONSTANTS.MINUTES_LIST.map((minute) => (
        <StyledMinute
          key={minute}
          isSelected={isSelected(minute)}
          onClick={() => selectMinutesHandler(minute)}
          aria-selected={isSelected(minute) ? true : undefined}
        >
          {minute}
        </StyledMinute>
      ))}
    </StyledMinutesList>
  );
}

MinutesList.propTypes = {
  onSelectMinute: PropTypes.func,
  inputMinute: PropTypes.number
}