import { useTexts } from 'common/components/dropdownSelect/hooks/useTexts';
import { StyledChooseCategoryItem } from './StyledChooseCategoryItem';

export const ChooseCategoryItem = ({ index, style }) => {
  const { chooseCategory } = useTexts();

  return (
    <StyledChooseCategoryItem key={index} style={style} data-testid="choose-category-label">
      <span>{chooseCategory}</span>
    </StyledChooseCategoryItem>
  );
};
