import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const StyledDatePicker = styled(Box)(() => ({
  display: 'flex',
  backgroundColor: 'transparent',
  flexDirection: 'column',
  margin: '0px',
  flex: '1 0 calc(35% - 10px)',
  maxWidth: '260px',
}));
