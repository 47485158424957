import { ButtonUnstyled } from '@mui/core';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledMinutesList = styled(Box)(() => ({
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  maxHeight: '244px',
  width: '100%',
  boxSizing: 'border-box',
}));

export const StyledMinute = styled(ButtonUnstyled, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme, isSelected }) => ({
  display: 'block',
  border: 'none',
  padding: '8px 8px',
  borderRadius: '50%',
  fontSize: '12px',
  color: isSelected ? theme?.palette?.generic?.primary : theme?.palette?.text?.title,
  backgroundColor: isSelected ? theme?.palette?.generic.sysaid : 'transparent',
  '&:hover': {
    backgroundColor: isSelected ? theme?.palette?.generic.sysaid : theme?.palette?.text?.inactive_secondary,
    cursor: 'pointer',
  },
  '&:focus': {
    backgroundColor: theme?.palette?.generic.sysaid,
  },
}));
