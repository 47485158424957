import { Input } from '@mui/material';

export function FileInput({ handleChange, handleClick, allowedTypes }) {
  const acceptTypes = allowedTypes?.map((type) => `image/${type}`).join(', ');

  return (
    <Input
      disableUnderline
      type="file"
      sx={{ opacity: 0, width: 0, height: 0 }}
      onChange={handleChange}
      onClick={handleClick}
      inputProps={{ accept: acceptTypes || '*', multiple: true }}
    />
  );
}
