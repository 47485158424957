import loginFrame from 'images/photos/login-frame.jpg'; // login page image hardcoded in spaces client - approved by Product
import { StyledRightPanel } from './style';

export default function RightPanel() {
  return (
    <StyledRightPanel>
      <img id="sysaid-logo" alt="sysaid-logo" src={loginFrame} />
    </StyledRightPanel>
  );
}
