import { styled, Menu } from '@mui/material';
import { commonMenuStyles } from '../style';

export const StyledMenu = styled(Menu)(({ theme }) => ({
  ul: {
    padding: theme.spacing(1),
    li: {
      borderRadius: theme?.shape?.border?.borderRadius,
      padding: '8px',
    },
  },
  ...commonMenuStyles(theme),
}));
