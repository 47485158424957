import Scrollbars from 'react-custom-scrollbars-2';
import { StyledDependencyPopup, DependencyTitle, DependencyContent, ScrollWrapper } from './style';
import useTexts from '../../../../hooks/useTexts';

export const DependencyPopup = ({ operator, dependencies }) => {
  const { dependencyDetailsText, dependentOnText } = useTexts();
  return (
    <StyledDependencyPopup onClick={(e) => e.stopPropagation()} data-testid="dependency-popup">
      <DependencyContent>
        <DependencyTitle>{dependencyDetailsText}</DependencyTitle>
        <ScrollWrapper>
          <Scrollbars
            style={{ width: '100%', height: '100%', right: '5px' }}
            autoHeight
            autoHeightMax={100}
            thumbSize={50}
          >
            {dependencies.map((dependency, index) => (
              <div className="dependency" key={dependency}>
                <span className="operator">{index !== 0 && operator} </span>
                <span>{dependentOnText}</span>
                <span className="link"> {dependency.dependsOnActionItemName}</span>
              </div>
            ))}
          </Scrollbars>
        </ScrollWrapper>
      </DependencyContent>
    </StyledDependencyPopup>
  );
};
