import { SimpleTreeView } from '@mui/x-tree-view';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const StyledGroupParent = styled(Box, {
  shouldForwardProp: (prop) => !['selected', 'isGroupedHierarchy', 'isFirst'].includes(prop),
})(({ theme, selected, isGroupedHierarchy, isFirst }) => {
  if (isGroupedHierarchy) {
    return {
      padding: isFirst ? '4px 8px 0px !important' : '0 8px !important',
      marginBottom: '4px',
      cursor: 'default !important',
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
    };
  }

  return {
    padding: '10px 0 10px 10px !important',
    marginBottom: '3px',
    borderRadius: '6px',
    height: '46px',

    '&:hover': {
      backgroundColor: selected
        ? `${theme.palette.background?.pages_background} !important`
        : `${theme.palette.background?.selected} !important`,
    },
  };
});

export const StyledItemsGroup = styled(Box, {
  shouldForwardProp: (prop) => !['isWideDropdown', 'isGroupedHierarchy'].includes(prop),
})(({ isWideDropdown, isGroupedHierarchy }) => {
  let maxItemTextWidth = '97px';
  if (isWideDropdown) {
    maxItemTextWidth = '172px';
  }
  if (isGroupedHierarchy) {
    maxItemTextWidth = 'initial';
  }
  return {
    marginLeft: '27px',

    '.select-item': {
      '& .MuiListItemText-root': {
        width: isGroupedHierarchy && isWideDropdown ? '266px !important' : 'initial',
      },
      '& .MuiTypography-root': {
        maxWidth: maxItemTextWidth,
      },
      '&:last-child': {
        marginBottom: isGroupedHierarchy && '8px',
      },
    },
  };
});

export const StyledIconWrapper = styled(Box)(() => ({
  '&.Mui-disabled': {
    opacity: '0',
  },

  '>svg': {
    width: '12px',
    height: '12px',
  },
}));

export const StyledLabel = styled(Typography)(() => ({
  textTransform: 'capitalize',
  fontSize: '0.8rem',
  paddingLeft: '3px',
}));

export const StyledTreeView = styled(SimpleTreeView, {
  shouldForwardProp: (prop) => !['isGroupedHierarchy'].includes(prop),
})(({ isGroupedHierarchy }) => {
  if (isGroupedHierarchy) {
    return {
      '& ul, .tree-item-group': {
        marginLeft: '0 !important',
      },
    };
  }
  return {};
});

export const StyledGroupLabelWrapper = styled(Box, {
  shouldForwardProp: (prop) => !['isFirst'].includes(prop),
})(({ isFirst }) => ({
  width: '100%',
  '& .MuiDivider-root': {
    margin: isFirst ? '0' : '4px 0 8px !important',
    display: 'block',
  },
}));

export const StyledGroupLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary_txt,
  fontFamily: theme.fonts.robotoRegular,
  fontSize: theme.fontSize.small,
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px',
}));
