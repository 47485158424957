import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    ...texts,
    backToSettings: texts?.['spaces.settings.header.backtosettings'] || 'Back to Settings',
  };
  return componentTexts;
}
