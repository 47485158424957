import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    titleText: texts?.['spaces.prompts.exitWithoutSave.title'],
    descriptionText: texts?.['spaces.prompts.exitWithoutSave.description'],
    btnOkText: texts?.['spaces.prompts.exitWithoutSave.btnOkText'],
    btnCancelText: texts?.['spaces.prompts.exitWithoutSave.btnCancelText'],
  };

  return componentTexts;
}
