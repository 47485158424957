const priorityClasses = {
  0: '#83B0F4',
  1: '#F4C449',
  2: '#F76969',
  hover: {
    0: '#6092DB',
    1: '#DBAA2C',
    2: '#DE4949',
  },
};

const statusClasses = {
  0: '#516C96',
  1: '#787878',
  2: '#787878',
  hover: {
    0: '#344C73',
    1: '#4F4F4F',
    2: '#4F4F4F',
  },
};

export const itemClassNames = {
  status: 'status',
  priority: 'priority',
  urgency: 'urgency',
  date: 'date',
  mainCategory: 'mainCategory',
  reporter: 'reporter',
};

export const useStyleHelpers = ({ theme, stylingVariant, color }) => {
  const selectItemBorderRadius = () => {
    if (
      stylingVariant !== null &&
      stylingVariant !== undefined &&
      (stylingVariant === itemClassNames.priority || stylingVariant === itemClassNames.mainCategory)
    ) {
      return theme.spacing(2);
    }
    return theme.spacing(0.5);
  };

  const selectItemBgColor = () => {
    switch (stylingVariant) {
      case itemClassNames.priority:
        return priorityClasses[color];
      case itemClassNames.status:
        return statusClasses[color];
      case itemClassNames.mainCategory:
        return '#C9EDF9';
      default:
        return 'transparent';
    }
  };
  const selectHoveredItemBgColor = (disabled) => {
    switch (stylingVariant) {
      case itemClassNames.priority:
        return disabled ? priorityClasses[color] : priorityClasses.hover[color];
      case itemClassNames.status:
        return disabled ? statusClasses[color] : statusClasses.hover[color];
      case itemClassNames.mainCategory:
        return disabled ? '#c9edf9' : '#9FD0E0';
      default:
        return 'transparent';
    }
  };

  const selectItemColor = () => {
    if (
      stylingVariant !== null &&
      stylingVariant !== undefined &&
      (stylingVariant === itemClassNames.priority || stylingVariant === itemClassNames.status)
    ) {
      return '#FFF';
    }
    return '#4F4F4F';
  };

  const selectItemFontStyles = () => {
    if (
      stylingVariant !== null &&
      stylingVariant !== undefined &&
      (stylingVariant === itemClassNames.priority || stylingVariant === itemClassNames.status)
    ) {
      return {
        fontFamily: 'Roboto-Bold',
        fontSize: theme.spacing(1.5),
      };
    }
    if (stylingVariant !== null && stylingVariant !== undefined && stylingVariant === itemClassNames.mainCategory) {
      return {
        fontSize: theme.spacing(1.5),
      };
    }
    return {
      fontSize: theme.spacing(1.75),
    };
  };
  const selectItemPadding = () => {
    if (
      stylingVariant === itemClassNames.priority ||
      stylingVariant === itemClassNames.status ||
      stylingVariant === itemClassNames.mainCategory
    ) {
      return theme.spacing(0.5, 1.5);
    }
    return theme.spacing(0);
  };

  return {
    selectItemBorderRadius,
    selectItemBgColor,
    selectItemColor,
    selectItemFontStyles,
    selectHoveredItemBgColor,
    selectItemPadding,
  };
};
