import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { SMALL_ATTACHMENT_WIDTH, REGULAR_ATTACHMENT_WIDTH } from 'features/attachments/attachmentsList/style';

export const StyledUploadProgressBar = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'error' && prop !== 'isMinimizedSize',
})(({ theme, error, isMinimizedSize }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '170px',
  gridArea: 'progressBar',
  gap: '12px',
  color: error ? theme.palette.labels.bt_red_reg : theme.palette.text.secondary_txt,
  fontFamily: theme.fonts.robotoBold,
  fontSize: theme.fontSize.small,
  fontWeight: 700,
  lineHeight: 1.667,

  '.upload-file-wrapper': {
    maxWidth: `${isMinimizedSize ? SMALL_ATTACHMENT_WIDTH : REGULAR_ATTACHMENT_WIDTH}px`,
    minWidth: `${isMinimizedSize ? SMALL_ATTACHMENT_WIDTH : REGULAR_ATTACHMENT_WIDTH}px`,
    width: `${isMinimizedSize ? SMALL_ATTACHMENT_WIDTH : REGULAR_ATTACHMENT_WIDTH}px`,

    '& .retry': {
      border: 'none',
      font: 'inherit',
      color: 'inherit',
      backgroundColor: 'transparent',
      width: 'fit-content',
      cursor: 'pointer',
      marginTop: '4px',
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'underline',
      justifyContent: 'start',
      gap: '4px',
    },
  },
  '.upload-file-name span': {
    paddingBottom: theme.spacing(0.125),
    textAlign: 'start',
  },

  '.svg': {
    height: '16px',
    cursor: 'pointer',
  },

  '.MuiLinearProgress-root': {
    borderRadius: '2px',
    height: '5px',
    width: '100%',

    '& .MuiLinearProgress-bar': {
      background: error
        ? theme.palette.labels.bt_red_reg
        : `linear-gradient(270deg, ${theme.palette.generic.sysaid_default} 45.31%, #C3DCD4 100%)`,
    },
  },
}));
