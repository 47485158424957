import { forwardRef, useCallback, useState } from 'react';
import { TextField as MuiTextField, InputAdornment } from '@mui/material';
import PropTypes from 'prop-types';
import { useController, useFormContext } from 'react-hook-form';

import useFallbackRef from 'common/utils/hooks/useFallbackRef';
import { ReactComponent as ErrorIcon } from 'images/icons/errorIcon.svg';
import { StyledTextField } from './style';
import { Tooltip } from '../Tooltip';

const noOp = () => {};

export const TextField = forwardRef(
  (
    {
      autoFocus,
      multiline,
      readOnly,
      handleClick = noOp,
      onBlur = noOp,
      onFocus: onParentFocus = noOp,
      rootClass,
      digitsLimit,
      isComplexField,
      value,
      dropdownType,
      name,
      inputIcon,
      disabled,
      adornmentTooltipOffset,
      ...restProps
    },
    forwardedRef,
  ) => {
    const { control, onSubmit = noOp, onFocus } = useFormContext() || {};
    const {
      field,
      fieldState: { isTouched, error, invalid },
    } = useController({ name, control, ...restProps });
    const currentValue = dropdownType ? value : value || field.value;
    const isNewField = !isTouched && !currentValue;
    const ref = useFallbackRef(forwardedRef);
    const [isFocused, setIsFocused] = useState(false);

    const assignRef = (element) => {
      if (typeof field.ref === 'function') {
        field.ref(element);
      } else {
        field.ref.current = element;
      }

      if (ref) {
        ref.current = element;
      }
    };

    const isTextTruncated = ref?.current && ref.current.offsetWidth < ref.current.scrollWidth;

    const onInputChange = useCallback(
      (e) => {
        const value = digitsLimit ? e.target.value.toString().slice(0, digitsLimit) : e.target.value;
        field?.onChange(value);
        if (restProps.onChange) restProps.onChange(value);
      },
      [digitsLimit, field, restProps],
    );

    const handleBlur = useCallback(
      (e) => {
        if (dropdownType) return;
        field.onBlur();
        onBlur();
        setIsFocused(false);
        onSubmit({ newValue: e.target.value, fieldName: name });
      },
      [dropdownType, field, name, onBlur, onSubmit],
    );

    const handleFocus = useCallback(() => {
      setIsFocused(true);
      onParentFocus();
      if (onFocus) onFocus();
    }, [onFocus, onParentFocus]);

    const classes = invalid ? `${rootClass} invalid-field` : rootClass;
    return (
      <StyledTextField
        isNewField={isNewField}
        multiline={multiline}
        dropdownType={dropdownType}
        invalid={invalid && error?.message}
        className={classes}
        readOnly={readOnly || disabled}
        disabled={disabled}
        value={currentValue}
      >
        <Tooltip
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -25],
                },
              },
            ],
          }}
          arrow
          title={isFocused || multiline ? '' : currentValue}
          forceTooltip={isTextTruncated}
        >
          <MuiTextField
            autoFocus={autoFocus}
            variant="standard"
            fullWidth={multiline}
            data-testid="text-field"
            multiline={multiline}
            disabled={disabled}
            {...(multiline && { minRows: 3 })}
            {...restProps}
            {...field}
            value={currentValue ?? ''}
            inputRef={assignRef}
            onClick={handleClick}
            onChange={onInputChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              disableUnderline: true,
              readOnly: readOnly || !!dropdownType,
              autoComplete: 'off',
              endAdornment: (
                <InputAdornment position="end">
                  {inputIcon}
                  {invalid && error?.message && (
                    <Tooltip
                      PopperProps={{
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: adornmentTooltipOffset || [0, -10],
                            },
                          },
                        ],
                      }}
                      arrow
                      title={error.message}
                      placement="top-start"
                      type="error"
                      forceDisplayOnHover
                      isIcon
                    >
                      <ErrorIcon className="error-icon" />
                    </Tooltip>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Tooltip>
      </StyledTextField>
    );
  },
);

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  multiline: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  maxRows: PropTypes.number,
  minRows: PropTypes.number,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  dropdownType: PropTypes.string,
  digitsLimit: PropTypes.number,
  value: PropTypes.string,
  inputIcon: PropTypes.element,
  handleClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

TextField.defaultProps = {
  autoFocus: false,
  multiline: false,
  label: '',
  placeholder: '--',
  type: 'text',
  maxRows: 5,
  minRows: 3,
  disabled: false,
  readOnly: false,
  dropdownType: '',
  value: '',
  inputIcon: null,
};
