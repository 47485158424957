import { ListItemIcon, ListItemText } from '@mui/material';

import { ReactComponent as GreenCheckIcon } from 'images/icons/green-check.svg';
import { ReactComponent as RedXIcon } from 'images/icons/red-x.svg';

import { StyledPasswordValidation } from './styles';

export default function PasswordValidation({ isPasswordValid, hasCriteria, text, variant = 'criteria', name }) {
  return (
    <StyledPasswordValidation
      disableGutters
      disablePadding
      isPasswordValid={isPasswordValid}
      hasCriteria={hasCriteria}
      variant={variant}
      id={`password-validation-${variant}`}
    >
      {(!isPasswordValid || hasCriteria) && variant !== 'title' && (
        <ListItemIcon>
          {hasCriteria ? <GreenCheckIcon data-testid={`${name}-valid`} /> : <RedXIcon data-testid={`${name}-error`} />}
        </ListItemIcon>
      )}
      <ListItemText primary={text} />
    </StyledPasswordValidation>
  );
}
