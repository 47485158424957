import { ThemeProvider } from '@mui/material/styles';
import { BorderLinearProgress, theme } from './style';

export default function DueDateProgressBar(props) {
  const { value, bgcolorindicator, disabled } = props;

  return (
    <ThemeProvider theme={theme}>
      <BorderLinearProgress
        variant="determinate"
        value={Number(value)}
        bgcolorindicator={bgcolorindicator}
        disabled={disabled}
      />
    </ThemeProvider>
  );
}
