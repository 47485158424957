import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    title: texts?.['spaces.header.gridTitle'],
    resetButton: texts?.['spaces.header.resetButton'],
    queueViewsMessageSaveSuccess: texts?.['spaces.header.queueViews.message.saveSuccess'],
    queueViewsMessageSetDefaultSuccess: texts?.['spaces.header.queueViews.message.viewSetAsDefault'],
    queueViewsMessageDeletedSuccessfully: texts?.['spaces.header.queueViews.message.deleteSuccess'],
    emptyResultsText: texts?.['spaces.header.current-assigned.emptyResults'],
    queueViews: texts?.['spaces.header.queueViews.queueViews'],
    publicStr: texts?.['spaces.header.queueViews.public'],
    privateStr: texts?.['spaces.header.queueViews.private'],
    defaultViewTooltip: texts?.['spaces.header.queueViews.defaultView.tooltip'],
    placeholderQueueViews: texts?.['spaces.filters.search.title'],
    viewDetails: texts?.['spaces.header.queueViews.actions.viewDetails'],
    save: texts?.['spaces.header.queueViews.actions.save'],
    saveAs: texts?.['spaces.header.queueViews.actions.saveAs'],
    setAsDefault: texts?.['spaces.header.queueViews.actions.setAsDefault'],
    deleteView: texts?.['spaces.header.queueViews.actions.deleteView'],
    launchClassicQueue: texts?.['spaces.header.queueViews.actions.launchClassicQueue'],
  };

  return componentTexts;
}
