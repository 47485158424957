import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTheme } from '@emotion/react';
import { debounce } from 'lodash-es';
import { mergeArraysByKey } from 'common/utils/utils';
import usePreviousValue from 'common/utils/hooks/usePreviousValue';
import { useSelectContext } from './DropdownSelectContext';
import { convertOptions, getFilteredOptions } from './helpers';

const beChangeHandler = debounce((params) => {
  const value = params.event.target.value;

  if (value == null || !value.length) return;

  params.backendQueryConfig
    .fetchingPromise(value)
    .then((res) => {
      if (!params.isRequestOnGoing) return; // @TODO why? this will always be true and debounce already takes care of consecutive calls
      let result = res.values;
      if (!Array.isArray(result) && Array.isArray(res)) {
        result = res;
      }
      params.setResult(convertOptions(result, params.theme, params.isCategory, params.hasHierarchy));
      params.setIsRequestOnGoing(false);
    })
    .catch((err) => {
      console.error(err);
      params.setIsRequestOnGoing(false);
    });
}, 1500);

export default function useFilteredOptions() {
  const {
    options,
    handleClean,
    checkAll,
    uncheckAll,
    captionString,
    backendQueryConfig,
    setOptions,
    keyString,
    sortBy,
    sortType,
    isDateType,
    hasHierarchy,
    isCategory,
    isListVisible,
    expandAll,
    isDirty,
  } = useSelectContext();

  const [search, setSearch] = useState('');
  const [result, setResult] = useState();
  const [isRequestOnGoing, setIsRequestOnGoing] = useState(false);

  const theme = useTheme();

  const filteredIds = useMemo(() => {
    if (!hasHierarchy) {
      return getFilteredOptions(options, search, keyString, captionString, sortBy, sortType);
    }
    let hierarchyFilteredIds = [];
    for (const key in options) {
      hierarchyFilteredIds = hierarchyFilteredIds.concat(
        getFilteredOptions(options[key], search, keyString, captionString, sortBy, sortType),
      );
    }
    return hierarchyFilteredIds;
  }, [options, search, keyString, captionString, sortBy, sortType, hasHierarchy]);

  const localChangeHandler = useCallback((event) => {
    setSearch(event.target.value);
  }, []);

  const handleUncheckAll = useCallback(() => {
    if (handleClean) {
      handleClean();
    }
    uncheckAll();
  }, [handleClean, uncheckAll]);

  const handleSelectAll = useCallback(() => {
    checkAll(filteredIds);
  }, [checkAll, filteredIds]);

  const clearSearch = useCallback(() => {
    setIsRequestOnGoing(false);
    setSearch('');
  }, []);

  const filteredOptionsKeys = useMemo(() => {
    if (!hasHierarchy) {
      return filteredIds;
    }
    const hierarchyFilteredOptionsKeys = {};
    for (const key in options) {
      hierarchyFilteredOptionsKeys[key] = options[key]
        .filter((option) => filteredIds.includes(option[keyString]))
        .map((option) => option[keyString]);
    }
    return hierarchyFilteredOptionsKeys;
  }, [filteredIds, hasHierarchy, options, keyString]);

  useEffect(() => {
    if (!isListVisible) {
      clearSearch();
    }
  }, [isListVisible, clearSearch]);

  const changeHandler = useCallback(
    (event) => {
      const value = event.target.value;
      if (value == null || !value.length) {
        clearSearch();
        return;
      }

      if (backendQueryConfig && backendQueryConfig.fetchingPromise) {
        setIsRequestOnGoing(true);

        beChangeHandler({
          event,
          setIsRequestOnGoing,
          isRequestOnGoing: true,
          backendQueryConfig,
          setResult,
          theme,
          isCategory,
          hasHierarchy,
        });
      }
      localChangeHandler(event);
      expandAll();
    },
    [clearSearch, backendQueryConfig, localChangeHandler, expandAll, theme, isCategory, hasHierarchy],
  );

  const prevResult = usePreviousValue(result);

  useEffect(() => {
    if (prevResult !== result && !isDateType) {
      if (hasHierarchy) {
        const finalOptions = {};
        for (const key in options) {
          finalOptions[key] = mergeArraysByKey(options[key], result[key], keyString);
        }
        setOptions(finalOptions);
      } else {
        setOptions(mergeArraysByKey(options, result, keyString));
      }
    }
  }, [prevResult, result, isDateType, isCategory, hasHierarchy, options, setOptions, keyString]);

  return {
    changeHandler,
    filteredOptionsKeys,
    search,
    handleUncheckAll,
    handleSelectAll,
    clearSearch,
    isRequestOnGoing,
    isDirty,
  };
}
