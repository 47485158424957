import { Box, Button, styled } from '@mui/material';

export const StyledDatePickerButton = styled(Button, {
  shouldForwardProp: (prop) =>
    !['isError', 'isNewField', 'disabled', 'isTemplatePage', 'isDatePopupOpen'].includes(prop),
})(({ isError, theme, isNewField, disabled, isTemplatePage, isDatePopupOpen }) => {
  const color = isError && !isDatePopupOpen ? theme.palette.labels.bt_red_reg : theme.palette.text.txt_default;
  let backgroundColor = isError && !isDatePopupOpen ? '#FFF2F2' : 'inherit';
  const hoveredErrorColor = isError && theme.palette.labels.bt_red_dark;
  let hoveredBackground = isError ? theme.palette.labels.bt_red_mid : theme.palette.labels.bt_black_light;
  let padding = '4px 8px';
  let lineHeight = '18px';

  if (isTemplatePage) {
    padding = '6px 8px';
    lineHeight = '22px';
  }

  if (isNewField && !isError && !isTemplatePage) {
    backgroundColor = '#EBF5FF';
    hoveredBackground = '#D5E4F2';
  }

  const hoveredStyles = {
    '&:hover': {
      color: isError ? hoveredErrorColor : color,
      border: '0px',
      background: hoveredBackground,
      '& .warning-icon *': {
        fill: hoveredErrorColor,
      },
      '& .openPickerIcon *': {
        stroke: isError ? hoveredErrorColor : color,
      },
    },
  };

  const expandedStyles = {
    border: '1px solid #CCC',

    '& span[data-error-tooltip=true], .warning-icon': {
      display: 'none',
    },
  };

  return {
    borderRadius: '4px',
    border: '0px',
    color,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight,
    padding,
    minHeight: '28px !important',
    width: '110px',
    textTransform: 'none',
    transition: '0.1s',
    display: 'flex',
    cursor: disabled ? 'default' : 'pointer',
    justifyContent: 'space-between',
    background: backgroundColor,
    '&.MuiButtonBase-root': {
      fontFamily: theme.fonts.robotoRegular,
      fontSize: '14px',
      padding,
    },
    '&.Mui-disabled': {
      border: '0px',
    },
    '.openPickerIcon *': {
      stroke: color,
    },
    '& .warning-icon *': {
      fill: color,
    },
    '& span': {
      textAlign: 'start',
    },
    ...(isDatePopupOpen ? expandedStyles : hoveredStyles),
  };
});

export const StyledDatePickerIcons = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  columnGap: '4px',
  flexDirection: 'row',
  flexWrap: 'nowrap',

  '& .warning-icon': {
    display: 'flex',
    margin: '0 2px',
  },
}));
