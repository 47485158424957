import { StyledAvatar, StyledAvatarGroup } from '../style';

export const AvatarGroupWrapper = ({ children, ...restProps }) => {
  const [number, avatars] = children;
  return (
    <StyledAvatarGroup avatarAmount={avatars.length}>
      {number && (
        <StyledAvatar id="number-avatar" className="avatar-wrapper" {...restProps}>
          {number}
        </StyledAvatar>
      )}
      {avatars}
    </StyledAvatarGroup>
  );
};
