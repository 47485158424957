import { forwardRef, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { StyledButton, StyledTypeBasedCircularProgress } from './style';
import CircularProgress from '../../circularProgress';

export const Button = forwardRef(
  ({ showUpdateLoader, disabled, variant, text, Icon, onClick, iconPlacement, completed, ...props }, ref) => {
    const [isUpdating, setIsUpdating] = useState(false);
    const { error } = useSelector((state) => state);
    useEffect(() => {
      setIsUpdating(false);
    }, [text]);
    const handleClick = useCallback(() => {
      if (showUpdateLoader) setIsUpdating(true);
      onClick();
    }, [showUpdateLoader, setIsUpdating, onClick]);

    return (
      <StyledButton
        variant={variant}
        onClick={handleClick}
        disabled={disabled}
        iconPlacement={iconPlacement}
        completed={completed}
        ref={ref}
        {...props}
      >
        {isUpdating && !error?.isError ? (
          <StyledTypeBasedCircularProgress variant={variant} {...props}>
            <CircularProgress size={16} thickness={4} />
          </StyledTypeBasedCircularProgress>
        ) : (
          Icon && <Icon />
        )}
        <span className="btn-text">{text}</span>
      </StyledButton>
    );
  },
);

Button.propTypes = {
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.elementType,
  iconPlacement: PropTypes.oneOf(['right', 'left']),
  onClick: PropTypes.func,
  completed: PropTypes.bool,
  showUpdateLoader: PropTypes.bool,
};

Button.defaultProps = {
  disabled: false,
  variant: 'primary',
  text: '',
  icon: null,
  iconPlacement: 'left',
  onClick: () => {},
  completed: false,
  showUpdateLoader: false,
};
