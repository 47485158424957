import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

export const StyledTimerField = styled(Box, {
  shouldForwardProp: (prop) => !['disabled'].includes(prop),
})(({ theme, disabled }) => ({
  display: 'flex',
  borderRadius: '12px',
  fontSize: theme?.fontSize?.main,
  color: theme?.palette?.text?.txt_default,
  cursor: disabled ? 'default' : 'pointer',
}));
