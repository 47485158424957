import Skeleton from '@mui/material/Skeleton';
import { FadeInBox } from './style';

export const BarLoader = ({ width, height, showBorder, withFade }) => (
  <FadeInBox sx={{ opacity: withFade ? 0 : 1, width: width || '174px' }}>
    <Skeleton
      sx={{
        backgroundColor: '#f0f1f5',
        border: showBorder ? '1px solid #e0e0df' : 'none',
        animationDuration: "10s",
        opacity: 0.6,
        animationDelay: '5s'
      }}
      width={width || '116px'}
      height={height || 28}
      animation="wave"
      variant="rounded"
    />
  </FadeInBox>
);
