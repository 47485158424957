import { styled } from '@mui/material/styles';

const StyledSysAidLogo = styled('img')`
  background-color: white;
  padding: 0;
  max-height: 27px;
  max-width: 150px;
`;

export default StyledSysAidLogo;
