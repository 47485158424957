import { Badge } from '@mui/material';

import { AVATAR_CONSTANTS } from 'constants/index';
import { ReactComponent as ChangeProfilePictureSvg } from 'images/icons/change-profile-picture.svg';

import { StyledChangeProfileBadge } from './styles';

export default function ChangeProfileBadge({ invisible, avatar }) {
  return (
    <Badge
      sx={{
        '.MuiBadge-badge': {
          transition: 'unset',
        },
      }}
      badgeContent={
        <StyledChangeProfileBadge
          type={AVATAR_CONSTANTS.TYPES.CUSTOM}
          icon={<ChangeProfilePictureSvg />}
          customStyles={{
            rootStyles: {
              border: 'unset',
              cursor: 'pointer',
            },
          }}
          disableHover
        />
      }
      overlap="circular"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      invisible={invisible}
    >
      {avatar}
    </Badge>
  );
}
