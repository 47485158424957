import PropTypes from 'prop-types';
import useTexts from '../../../../../useTexts';
import { StyledEvent } from '../../style';
import { CONSTANTS } from '../../../../../../header/constants';
import { capitalizeFirstLetter } from '../../../utils';

const TicketConversionEvent = ({ srId, oldSrType, newSrType }) => {
  const { conversionTicketEventText } = useTexts();

  const oldSrTypeText = parseInt(oldSrType, 10) === CONSTANTS.INCIDENT_TYPE ? capitalizeFirstLetter(CONSTANTS.INCIDENT_TYPE_IN_TEXT) : capitalizeFirstLetter(CONSTANTS.REQUEST_TYPE_IN_TEXT);
  const newSrTypeText = parseInt(newSrType, 10) === CONSTANTS.INCIDENT_TYPE ? `an ${CONSTANTS.INCIDENT_TYPE_IN_TEXT}` : `a ${CONSTANTS.REQUEST_TYPE_IN_TEXT}`;
  const text = `${oldSrTypeText} ${srId} ${conversionTicketEventText} ${newSrTypeText}`;

  return (
    <StyledEvent data-testId="ticket-conversion-event" data-cy="ticket-conversion-event">
      {text}
    </StyledEvent>
  );
};

TicketConversionEvent.propTypes = {
  srId: PropTypes.number,
};

TicketConversionEvent.defaultProps = {
  srId: -1,
};

export default TicketConversionEvent;
