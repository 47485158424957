import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Tab, Box, FormControl } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DropdownSelect, useDropdownControl } from 'common/components/dropdownSelect';
import { StyledDataFilters, StyledTabs, JourneyButton } from './style';
import { useAuditLog } from '../middlePanel/auditLog/hooks';
import { useTabs } from '../middlePanel/auditLog/hooks/useTabs';
import { selectJourneyFilters } from '../middlePanel/auditLog/store/slice';
import useTexts from '../useTexts';
import { RESOLUTION_PANEL_CONSTANTS } from '../constants';
import { MIDDLE_PANEL_TAB_NAMES } from '../middlePanel/constants';
import { useSaveUserAttributes, useUserAttributes } from '../../../remote-state/userServiceHooks';
import { activeUsername } from '../../../store/userSlice';
import { DataFiltersProvider } from './dataFiltersContext';
import { USER_ATTRIBUTE_CONSTANTS } from '../../../constants/users';

const formatOptions = ({ texts, filters, defaultValueKey }) =>
  filters.map(({ valueKey, valueCaption }) => ({
    valueKey,
    valueCaption: texts[valueCaption],
    isDefault: valueKey === defaultValueKey,
  }));

const menuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
};

const DataFilters = ({ srId, showTicketAsTab }) => {
  const texts = useTexts();
  const { tabs, selectedTabIndex, selectedTabName } = useTabs({ srId, showTicketAsTab });
  const { handleActiveTab: handleTabChange, handleActiveFilter } = useAuditLog();
  const { filter: selectedFilter } = useSelector(selectJourneyFilters);
  const filterCaption = texts[RESOLUTION_PANEL_CONSTANTS.JOURNEY_SELECT[selectedFilter]?.valueCaption];
  const { anchor, handleOpen, handleClose } = useDropdownControl();
  const username = useSelector(activeUsername);
  const { data: userAttributes } = useUserAttributes(username, USER_ATTRIBUTE_CONSTANTS.JOURNEY);
  const defaultFilterKey = userAttributes?.attributeValue?.defaultFilterKey;
  // we need exact check here, because if even isAllExpanded is not set (undefined) items should be expanded by default
  const isAllMessagesExpanded = userAttributes?.attributeValue?.isAllExpanded !== false;
  const { mutateAsync: saveUserAttributes } = useSaveUserAttributes();
  const options = useMemo(
    () => formatOptions({ texts, filters: RESOLUTION_PANEL_CONSTANTS.JOURNEY_SELECT, defaultValueKey: defaultFilterKey }),
    [texts, defaultFilterKey],
  );

  const handleChange = useCallback(
    ({ valueKey }) => {
      handleActiveFilter(valueKey);
      handleClose();
    },
    [handleActiveFilter, handleClose],
  );

  const handleSetDefault = async (option) => {
    const attributeValue = {
      // always resend other values otherwise they will be omitted
      ...userAttributes?.attributeValue,
      defaultFilterKey: option?.valueKey
    };
    await saveUserAttributes({ username, attributeName: USER_ATTRIBUTE_CONSTANTS.JOURNEY, attributeValue });
  }

  const handleToggleExpandAllMessages = async (value) => {
    const attributeValue = {
      ...userAttributes?.attributeValue,
      isAllExpanded: value,
    };
    await saveUserAttributes({ username, attributeName: USER_ATTRIBUTE_CONSTANTS.JOURNEY, attributeValue });
  }

  useEffect(() => {
    if (typeof defaultFilterKey === 'number') {
      handleActiveFilter(defaultFilterKey);
    }
  }, [defaultFilterKey, handleActiveFilter]);

  return (
    <StyledDataFilters>
      <Box id="tabs-wrapper">
        <Box id="tabs-listcontainer">
          <StyledTabs value={selectedTabIndex ?? false} onChange={handleTabChange}>
            {tabs?.map((tab, index) => (
              <Tab
                value={index}
                key={`tab-${tab.name}`}
                label={tab.tabText}
                id={`simple-tab-${index}`}
                aria-controls={`simple-tabpanel-${index}`}
                data-cy={`simple-tab-${tab.name}`}
              />
            ))}
          </StyledTabs>
        </Box>
        <Box id="tabs-filters">
          {selectedTabName === MIDDLE_PANEL_TAB_NAMES.JOURNEY && (
            <FormControl variant="standard">
              <JourneyButton
                variant="text"
                onClick={handleOpen}
                endIcon={<KeyboardArrowDownIcon fontSize="small" />}
                disableRipple
                id="journeyFilterButton"
                dropdownOpened={Boolean(anchor)}
              >
                {filterCaption}
              </JourneyButton>
              <DataFiltersProvider
                isSupportDefault
                isAllMessagesExpanded={isAllMessagesExpanded}
                handleSetDefault={handleSetDefault}
                handleToggleExpandAllMessages={handleToggleExpandAllMessages}
              >
                <DropdownSelect
                  isHeaderHidden
                  options={options}
                  selection={selectedFilter}
                  anchor={anchor}
                  handleOpen={handleOpen}
                  handleClose={handleClose}
                  handleChange={handleChange}
                  id="journeyFilterDropdown"
                  MenuProps={menuProps}
                  dropdownHeightExtension={20}
                  isWideDropdown
                />
              </DataFiltersProvider>
            </FormControl>
          )}
        </Box>
      </Box>
    </StyledDataFilters>
  );
};

export default DataFilters;
