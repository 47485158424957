import { forwardRef, memo, useCallback, useRef } from 'react';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import { TreeItem } from '@mui/x-tree-view';
import { Divider } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ReactComponent as ChevronDown } from 'images/icons/chevron_down.svg';
import { ReactComponent as ChevronUp } from 'images/icons/chevron_up.svg';
import Checkbox from '../checkbox';
import { useSelectContext } from '../dropdownSelect/context';
import {
  StyledGroupParent,
  StyledItemsGroup,
  StyledIconWrapper,
  StyledLabel,
  StyledGroupLabel,
  StyledTreeView,
  StyledGroupLabelWrapper,
} from './style';
import { SelectItem } from '../dropdownSelect/SelectItem';

const ItemsGroupParent = forwardRef((props, ref) => {
  const {
    itemId,
    index,
    value,
    label = '',
    labelPlacement = 'end',
    className,
    classes,
    icon: iconProp,
    expansionIcon,
    displayIcon,
    expanded,
    isGroupedHierarchy,
    toggleExpansion,
  } = props;

  const { selection, toggleCheckGroup, options, keyString } = useSelectContext();
  const selected = selection.valueClasses.includes(value);
  const icon = iconProp || expansionIcon || displayIcon;

  const handleExpansionClick = useCallback(() => {
    toggleExpansion(itemId);
  }, [itemId, toggleExpansion]);

  const handleChange = () => {
    toggleCheckGroup(value);
  };

  const indeterminate =
    !selected &&
    selection.values.some((selectedValue) =>
      options[value].map((option) => String(option[keyString])).includes(String(selectedValue)),
    );

  return (
    <StyledGroupParent
      ref={ref}
      selected={selected}
      isGroupedHierarchy={isGroupedHierarchy}
      isFirst={!index}
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        // [classes.selected]: selected,
        // [classes.focused]: focused,
      })}
    >
      {isGroupedHierarchy ? (
        <StyledGroupLabelWrapper isFirst={!index}>
          {!!index && <Divider />}
          <StyledGroupLabel>{label}</StyledGroupLabel>
        </StyledGroupLabelWrapper>
      ) : (
        <>
          <StyledIconWrapper
            onClick={handleExpansionClick}
            // onKeyDown={handleExpansionClick}
            className={clsx(classes.iconContainer)}
            // role="button"
          >
            {icon}
          </StyledIconWrapper>

          <FormControlLabel
            control={<Checkbox checked={selected} indeterminate={indeterminate} onChange={handleChange} />}
            value={value}
            label={
              <Box className={classes.label}>
                <StyledLabel component="div">{label}</StyledLabel>
              </Box>
            }
            labelPlacement={labelPlacement}
            sx={{ marginLeft: 0 }}
          />
        </>
      )}
    </StyledGroupParent>
  );
});

const Item = forwardRef((props, ref) => {
  const { groupIds, isWideDropdown, isGroupedHierarchy } = props;
  return (
    <StyledItemsGroup
      ref={ref}
      isWideDropdown={isWideDropdown}
      isGroupedHierarchy={isGroupedHierarchy}
      className="tree-item-group"
    >
      {groupIds.map((id) => (
        <SelectItem id={id} key={id} elementType="div" />
      ))}
    </StyledItemsGroup>
  );
});

const GroupedDropdownOptions = ({ optionsGroups, isWideDropdown, isGroupedHierarchy }) => {
  const optionsGroupsKeys = Object.keys(optionsGroups || {});
  const ref = useRef();
  const { valueClassesNames, toggleExpansion, expanded } = useSelectContext();

  const ChevronUpIcon = () => <ChevronUp data-testid="expend-icon" />;
  const ChevronDownIcon = () => <ChevronDown data-testid="collapse-icon" />;

  return (
    <StyledTreeView
      ref={ref}
      isGroupedHierarchy={isGroupedHierarchy}
      aria-label="multi-select"
      slots={{ expandIcon: ChevronUpIcon, collapseIcon: ChevronDownIcon }}
      //   multiSelect
      expandedItems={expanded}
      data-testid="grouped-dropdown-options"
    >
      {optionsGroupsKeys.map((optionsGroupKey, index) => {
        const label = (valueClassesNames && valueClassesNames[optionsGroupKey]) ?? optionsGroupKey;
        return (
          <TreeItem
            key={optionsGroupKey}
            itemId={optionsGroupKey}
            label={label}
            data-testid="grouped-dropdown-option"
            data-cy={`grouped-dropdown-option-${optionsGroupKey}`}
            ContentComponent={ItemsGroupParent}
            ContentProps={{
              value: optionsGroupKey,
              index,
              label,
              expanded: expanded.includes(optionsGroupKey) || isGroupedHierarchy,
              isGroupedHierarchy,
              toggleExpansion,
            }}
          >
            {/* {optionsGroups[optionsGroupKey].map((id) => ( */}
            <TreeItem
              // key={id}
              itemId={`${optionsGroupKey}_item`}
              ContentComponent={Item}
              ContentProps={{
                isWideDropdown,
                isGroupedHierarchy,
                groupIds: optionsGroups[optionsGroupKey],
              }}
            />
            {/* ))} */}
          </TreeItem>
        );
      })}
    </StyledTreeView>
  );
};

export default memo(GroupedDropdownOptions);
