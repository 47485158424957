import { useState } from 'react';
import { Box, Button } from '@mui/material';
import { BasicAvatar } from 'common/components/avatars/basicAvatar';
import ChangeProfileBadge from 'common/components/changeProfileBadge';
import Tooltip from 'common/components/tooltip';
import { AVATAR_CONSTANTS } from 'constants/index';
import useTexts from 'features/navBar/meMenu/useTexts';
import NavLink from 'features/navBar/navLink';
import { ReactComponent as LinkExternal } from 'images/icons/link-external.svg';
import LogoutBtn from '../logoutBtn';
import { StyledCardHeader, StyledXButton, StyledSelfServicePortalButton } from '../styles';
import ChangeProfilePicture from './changeProfilePicture';

export default function MeMenuHeader({ userName, settings: { profileImage }, handleChange, closeMenu }) {
  const [showBadge, setShowBadge] = useState(false);
  const [openChangeProfileImage, setOpenChangeProfileImage] = useState(false);
  const { sspBtnText } = useTexts();

  const handleOpenChangeProfileImage = () => {
    setOpenChangeProfileImage(true);
  };

  const handleCloseChangeProfileImage = () => {
    setOpenChangeProfileImage(false);
  };

  return (
    <>
      <ChangeProfilePicture
        open={openChangeProfileImage}
        handleClose={handleCloseChangeProfileImage}
        handleChange={handleChange}
        userName={userName}
        profileImage={profileImage}
      />
      <StyledXButton handleClick={closeMenu} iconButtonProps={{ 'data-testid': 'close-header-button' }} />
      <StyledCardHeader
        avatar={
          <Box
            onMouseEnter={() => setShowBadge(true)}
            onMouseLeave={() => setShowBadge(false)}
            onClick={() => handleOpenChangeProfileImage()}
            data-testid="profile-image"
          >
            <ChangeProfileBadge
              invisible={!showBadge}
              avatar={
                <BasicAvatar
                  size={AVATAR_CONSTANTS.SIZES.XL}
                  profileImage={profileImage}
                  userName={userName}
                  customStyles={{
                    rootStyles: {
                      cursor: 'pointer',
                    },
                  }}
                  disableHover
                />
              }
            />
          </Box>
        }
        title={<Tooltip text={userName} isTruncatedText placement="top" data-testid="username-title" />}
        subheader={<LogoutBtn data-testid="logout-button" data-cy="logout-button" />}
        action={
          <>
            <StyledSelfServicePortalButton>
              <NavLink to="/servicePortal" isOpenInNewTab>
                <Button
                  data-testid="ssp-link"
                  variant="text"
                  disableElevation
                  disableRipple
                  startIcon={<LinkExternal />}
                >
                  {sspBtnText}
                </Button>
              </NavLink>
            </StyledSelfServicePortalButton>
          </>
        }
      />
    </>
  );
}
