import { useSelector } from 'react-redux';
import { merge } from 'lodash';
import PropTypes from 'prop-types';
import { selectActiveUser } from '../../../../../store/userSlice';
import useTexts from '../../../useTexts';
import { useUserDetailsById, useUserDetailsByName } from '../../../../../remote-state/ticketServiceHooks';
import User from '../../../../../common/components/user';
import { StyledLogUser } from './style';

const guestText = 'Guest User';

export default function LogMetaUser({ userName, userId, logInformation }) {
  const currentUser = useSelector(selectActiveUser);
  const { meText } = useTexts();
  const { data: byName } = useUserDetailsByName(userName);
  const { data: byId } = useUserDetailsById(userId);
  const user = merge(byName, byId);
  const isNotExisting = !user?.calculatedUserName && !user?.firstName && !user?.lastName;

  return isNotExisting ? (
    <StyledLogUser>
      <span className="username">{`${guestText} - ${logInformation?.email || userName}`}</span>
    </StyledLogUser>
  ) : (
    <StyledLogUser>
      <User userName={userName} userId={userId}>
        <span className="username">{currentUser?.username === userName ? `${userName} (${meText})` : userName}</span>
      </User>
    </StyledLogUser>
  );
}

LogMetaUser.propTypes = {
  userName: PropTypes.string,
  userId: PropTypes.number,
  logInformation: PropTypes.object,
};
