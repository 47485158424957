import { Stack, createTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import { StyledDropDownMenu } from '../dropdownSelect/MenuContainer/MenuContainer.styles';

export const AssigneeButtonWrapper = styled(Stack, {
  shouldForwardProp: (prop) => !['buttonStyle', 'isError'].includes(prop),
})(({ theme, isError, buttonStyle = {} }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: '4px',
  ...buttonStyle,
  ':hover': !isError && {
    '& .current-assignee': {
      background: theme.palette.background.bg_hover_alt,
    },
    '& .MuiAvatar-root': {
      filter: 'brightness(90%)',
    },
  },
}));

export const StyledMenu = styled(StyledDropDownMenu, {
  shouldForwardProp: (prop) => prop !== 'menuStyle' && prop !== 'withTitle',
})(({ theme, menuStyle = {} }) => ({
  '& .sub-title': {
    padding: theme?.spacing(1.5, 3.5, 1.5, 1.5),
  },
  '& .MuiPaper-root': {
    minWidth: '180px',
    overflow: 'hidden',
    ...menuStyle,
  },
}));

export const getTheme = (styleOverrides) =>
  createTheme({
    components: {
      MuiList: {
        styleOverrides: {
          root: {
            paddingTop: '0',
            paddingBottom: '0',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            maxHeight: `${styleOverrides ? styleOverrides.dropdownMaxHeight : null}px !important`,
            marginTop: '4px',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            paddingTop: '11px',
            paddingBottom: '11px',
            paddingLeft: '11px',
            '&.Mui-selected': {
              backgroundColor: 'rgb(245,246,250) important',
            },
          },
        },
      },
    },
  });
