import { memo, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { Container, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoryListAttributes } from 'services/ticketService';
import {
  selectForceFloatingTicketShowValidationError,
  selectForceShowValidationError,
} from 'store/fieldValidationsSlice';
import { QUERIES_KEYS } from 'constant';
import { useApplicationData } from 'remote-state/applicationHooks';
import { modifyCategoriesFilter, useModifiedCategoriesAttributes, useSR } from 'remote-state/ticketServiceHooks';
import { SESSION_STORAGE } from 'features/queue/constants';
import useHiddenSelectOption from 'common/utils/hooks/useHiddenSelectOption';
import { castCategoryDefaultToNumber } from 'features/templateBuilder/utils/utils';
import { getIsExistingSr } from 'common/utils/srUtils';
import { useGetSuggestedCategories } from 'features/queue/grid/hooks/useGetSuggsetedCategories';
import { useSuggestedCategoryDropdownControl } from 'features/suggestedCategory/suggestedCategoryHooks';
import { VirtualizedScrollContext } from 'common/components/dropdownSelect/VirtualizedScroll/VirtualizedScrollContext';
import { DisplayPage } from 'common/utils/displayPage';
import { actionTypes, useCategory } from 'store/CategoryContext';
import { getCustomHeaderStyles, StyledCategories } from './style';
import { CategorySelect } from './categorySelect';
import { isTruthyOrZero } from './utils';
import {
  CATEGORIES,
  DELETED_CATEGORY_ID,
  FIRST_LEVEL_CATEGORY_FIELD_ID,
  SECOND_LEVEL_CATEGORY_FIELD_ID,
  THIRD_LEVEL_CATEGORY_FIELD_ID,
} from './constants';
import useTexts from './useTexts';
import { useOpenListIfReady } from './useOpenListIfReady';
import { HeaderItemLabel, LabelWrap } from '../style';
import {
  getCategoryDrivenTemplate,
  getCategoryDrivenTemplateWithoutThirdLevel,
} from '../../../services/templateService';
import { SR_TYPE_CODE_MAP } from '../../TicketPanel/constants';
import { useCategoryDrivenEnabled } from '../../../remote-state/accountSettingsServiceHooks';

function selectedCategoriesReducer(state, action) {
  let payload;
  if (isTruthyOrZero(action.payload)) {
    payload = action.payload;
  }
  switch (action.type) {
    case 'SET_PRIMARY_CATEGORY':
      return {
        ...state,
        primaryCategory: parseInt(payload, 10),
        secondaryCategory: undefined,
        thirdLevelCategory: undefined,
      };
    case 'SET_SECONDARY_CATEGORY':
      return { ...state, secondaryCategory: parseInt(payload, 10), thirdLevelCategory: undefined };
    case 'SET_THIRD_LEVEL_CATEGORY':
      return { ...state, thirdLevelCategory: parseInt(payload, 10) };
    case 'RESET_ALL':
      return { primaryCategory: undefined, secondaryCategory: undefined, thirdLevelCategory: undefined };
    case 'SET_ALL':
      return {
        primaryCategory: parseInt(action.payload.primaryCategory, 10),
        secondaryCategory: parseInt(action.payload.secondaryCategory, 10),
        thirdLevelCategory: parseInt(action.payload.thirdLevelCategory, 10),
      };
    default:
      return state;
  }
}
const initialSelectedCategories = {
  primaryCategory: undefined,
  secondaryCategory: undefined,
  thirdLevelCategory: undefined,
};

function getCategoryFieldsMap(headerFields) {
  if (!Array.isArray(headerFields)) return {};
  const headers = headerFields;
  const categoryFields = headers?.filter((field) => field.fieldName.startsWith(`${CATEGORIES.PREFIX_KEY}.`));
  const categoryFieldsMap = {};
  categoryFields?.forEach((f) => {
    categoryFieldsMap[f.fieldName] = f;
  });
  return categoryFieldsMap;
}

function Categories(props) {
  const {
    disabled,
    dataset,
    isTemplatePage,
    headerFields,
    srType,
    handleDirty,
    onFocus,
    handleSaveValue,
    required,
    updateFieldValidation,
    className = '',
    defaultValues,
    forceSrId = null,
    onTemplateChanged,
  } = props;
  const {
    sr: { data: sr },
  } = useSR(forceSrId);
  const { dispatch: categoryDispatch } = useCategory();
  const isExistingSR = getIsExistingSr(sr?.id);
  const [selectedCategories, selectedCategoriesDispatch] = useReducer(
    selectedCategoriesReducer,
    initialSelectedCategories,
  );
  const [isPrimaryListOpen, setIsPrimaryListOpen] = useState(false);
  const [isSecondaryListOpen, setIsSecondaryListOpen] = useState(false);
  const [isThirdListOpen, setIsThirdListOpen] = useState(false);
  const [isSecondaryListReady, setIsSecondaryListReady] = useState(false);
  const [isThirdListReady, setIsThirdListReady] = useState(false);
  const { requestOpen: requestOpenSecondaryList } = useOpenListIfReady({
    isListReady: isSecondaryListReady,
    isListOpen: isSecondaryListOpen,
    openList: () => setIsSecondaryListOpen(true),
  });
  const thirdLevelFocusWrapper = useCallback(() => {
    setIsThirdListOpen(true);
    onFocus?.(THIRD_LEVEL_CATEGORY_FIELD_ID);
  }, [onFocus]);
  const { requestOpen: requestOpenThirdLevelList } = useOpenListIfReady({
    isListReady: isThirdListReady,
    isListOpen: isThirdListOpen,
    openList: () => setTimeout(() => thirdLevelFocusWrapper(), 250),
  });

  const [showExitPrompt, setShowExitPrompt] = useState(false);
  const [shouldResetSelectedCategories, setShouldResetSelectedCategories] = useState(true);
  const [additionalBECategories, setAdditionalBECategories] = useState({ primary: [], secondary: [], third: [] });
  const [isDirty, setIsDirty] = useState(false);
  const [clearedObj, setClearedObj] = useState({});
  const { data: isTripleCategory } = useApplicationData(QUERIES_KEYS.IS_EXTERNAL_MODE);
  const {
    categoryText,
    subCategoryText,
    thirdLevelCategoryText,
    selectCategoryText,
    selectSubCategoryText,
    select3rdLevelCategoryText,
    categoriesText,
  } = useTexts();
  const forceShowValidation = useSelector(
    isExistingSR ? selectForceShowValidationError : selectForceFloatingTicketShowValidationError,
  );
  const [primaryCategories, secondaryCategories, thirdLevelCategories] = useModifiedCategoriesAttributes(
    'all',
    selectedCategories.primaryCategory,
    selectedCategories.secondaryCategory,
    srType,
  );

  const secondaryCategoriesMemo = useMemo(() => secondaryCategories, [secondaryCategories]);
  const thirdLevelCategoriesMemo = useMemo(() => thirdLevelCategories, [thirdLevelCategories]);
  const categoryFieldsMap = getCategoryFieldsMap(headerFields);
  const isCategoryDrivenEnabled = useCategoryDrivenEnabled()?.data?.data;
  const firstLevelCategoryDefault = castCategoryDefaultToNumber(
    categoryFieldsMap[CATEGORIES.FIRST_LEVEL_CATEGORY_FIELD_KEY]?.defaultValue,
  );
  const secondLevelCategoryDefault = castCategoryDefaultToNumber(
    categoryFieldsMap[CATEGORIES.SECOND_LEVEL_CATEGORY_FIELD_KEY]?.defaultValue,
  );
  const thirdLevelCategoryDefault = castCategoryDefaultToNumber(
    categoryFieldsMap[CATEGORIES.THIRD_LEVEL_CATEGORY_FIELD_KEY]?.defaultValue,
  );

  const selectedCategoriesDispatchTimeout = useRef();

  useEffect(() => {
    setClearedObj({});
    selectedCategoriesDispatchTimeout.current = setTimeout(() => {
      selectedCategoriesDispatch({
        type: 'SET_ALL',
        payload: {
          primaryCategory: defaultValues?.primaryCategory,
          secondaryCategory: defaultValues?.secondaryCategory,
          thirdLevelCategory: defaultValues?.thirdLevelCategory,
        },
      });
    }, 500);
  }, [defaultValues?.primaryCategory, defaultValues?.secondaryCategory, defaultValues?.thirdLevelCategory]);

  useEffect(() => () => clearTimeout(selectedCategoriesDispatchTimeout.current), []);

  const theme = useTheme();
  const dispatch = useDispatch();

  const getCurrentValue = useCallback(
    (id, name, list) => {
      let currentCategory = list?.find((item) => item.id === Number(id)); // category id can be 0, and Number(null) is also 0, while Number(undefined) is NaN
      if (!!id && name != null && !currentCategory && !isDirty) {
        currentCategory = { id, name };
      }
      return currentCategory;
    },
    [isDirty],
  );

  const checkAndUpdateValidationStatus = useCallback(
    (primaryCategory, secondaryCategory, thirdLevelCategory) => {
      if (!updateFieldValidation || isTemplatePage) return;
      let isValid = true;
      const categoriesValidationObj = {
        primaryCategory: true,
        secondaryCategory: true,
        thirdLevelCategory: true,
      };
      if (!required) {
        // categories are not required
      } else if (
        primaryCategory === -1 ||
        primaryCategory === undefined ||
        primaryCategory === null ||
        // eslint-disable-next-line no-restricted-globals
        isNaN(primaryCategory)
      ) {
        categoriesValidationObj.primaryCategory = false;
        categoriesValidationObj.secondaryCategory = false;
        categoriesValidationObj.thirdLevelCategory = false;
      } else if (
        secondaryCategory === -1 ||
        secondaryCategory === undefined ||
        secondaryCategory === null ||
        // eslint-disable-next-line no-restricted-globals
        isNaN(secondaryCategory)
      ) {
        categoriesValidationObj.primaryCategory = true;
        categoriesValidationObj.secondaryCategory = false;
        categoriesValidationObj.thirdLevelCategory = false;
      } else if (
        isTripleCategory &&
        (thirdLevelCategory === -1 ||
          thirdLevelCategory === undefined ||
          thirdLevelCategory === null ||
          // eslint-disable-next-line no-restricted-globals
          isNaN(thirdLevelCategory))
      ) {
        categoriesValidationObj.primaryCategory = true;
        categoriesValidationObj.secondaryCategory = true;
        categoriesValidationObj.thirdLevelCategory = false;
      }

      updateFieldValidation('primaryCategory', required, categoriesValidationObj.primaryCategory);
      updateFieldValidation('secondaryCategory', required, categoriesValidationObj.secondaryCategory);
      updateFieldValidation(
        'thirdLevelCategory',
        required,
        !isTripleCategory || categoriesValidationObj.thirdLevelCategory,
      );

      const categoriesHaveValues = isTripleCategory
        ? categoriesValidationObj.primaryCategory &&
          categoriesValidationObj.secondaryCategory &&
          categoriesValidationObj.thirdLevelCategory
        : categoriesValidationObj.primaryCategory && categoriesValidationObj.secondaryCategory;

      if (required && !categoriesHaveValues) {
        isValid = false;
      }
      return isValid;
    },
    [required, isTripleCategory, updateFieldValidation, isTemplatePage],
  );

  const filterExistingCategoriesFromNewList = (newCategories, existingCategories) =>
    newCategories?.filter((v) => !existingCategories.some((pc) => pc.id === v.id)) || [];

  const handleSaveCategories = useCallback(
    async (updatedCategories) => {
      const categoriesForValidation = Object.values(updatedCategories);
      const isValid = checkAndUpdateValidationStatus(...categoriesForValidation);
      const updatedCategoriesValue = !isExistingSR
        ? updatedCategories
        : { category: JSON.stringify(updatedCategories) };

      handleSaveValue(updatedCategoriesValue);
      if (isValid || isTemplatePage) {
        setShowExitPrompt(false);
      } else {
        setShowExitPrompt(true);
      }
    },
    [checkAndUpdateValidationStatus, isExistingSR, isTemplatePage, handleSaveValue],
  );

  const handleChangePrimaryCategory = useCallback(
    (id, searchedCategoriesList) => {
      setClearedObj({});
      if (id === '-1') id = -1;
      const additionalPrimary = filterExistingCategoriesFromNewList(searchedCategoriesList, primaryCategories);
      const { secondaryCategory, thirdLevelCategory } = selectedCategories;
      const categoriesForValidation = [id, secondaryCategory, thirdLevelCategory];
      setAdditionalBECategories((state) => ({ ...state, primary: additionalPrimary }));
      setShouldResetSelectedCategories(false);
      checkAndUpdateValidationStatus(...categoriesForValidation);
      selectedCategoriesDispatch({ type: 'SET_PRIMARY_CATEGORY', payload: id });
      categoryDispatch({ type: actionTypes.SET_SELECTED_PRIMARY_CATEGORY, payload: id });
      categoryDispatch({ type: actionTypes.SET_SELECTED_SECONDARY_CATEGORY, payload: undefined });
      categoryDispatch({ type: actionTypes.SET_SELECTED_THIRD_LEVEL_CATEGORY, payload: undefined });
      requestOpenSecondaryList();
      setIsSecondaryListOpen(true);
      if (isTemplatePage) {
        handleSaveCategories({ ...selectedCategories, primaryCategory: id });
      }
      setShowExitPrompt(true);

      if (handleDirty) {
        handleDirty({ primaryCategory: id });
      }
    },
    [
      handleDirty,
      primaryCategories,
      selectedCategories,
      checkAndUpdateValidationStatus,
      requestOpenSecondaryList,
      handleSaveCategories,
      isTemplatePage,
      categoryDispatch,
    ],
  );

  const handleChangeSecondaryCategory = useCallback(
    async (id, searchedCategoriesList, forceDirty) => {
      if (id === '-1') id = -1;
      if (id === -1) {
        setClearedObj((prev) => ({ ...prev, secondary: false, third: false }));
      }
      const additionalSecondary = filterExistingCategoriesFromNewList(searchedCategoriesList, secondaryCategories);
      const { primaryCategory, thirdLevelCategory } = selectedCategories;
      const categoriesForValidation = [primaryCategory, id, thirdLevelCategory];
      setAdditionalBECategories((state) => ({ ...state, secondary: additionalSecondary }));
      setShouldResetSelectedCategories(false);
      checkAndUpdateValidationStatus(...categoriesForValidation);
      selectedCategoriesDispatch({ type: 'SET_SECONDARY_CATEGORY', payload: id });
      categoryDispatch({ type: actionTypes.SET_SELECTED_SECONDARY_CATEGORY, payload: id });
      categoryDispatch({ type: actionTypes.SET_SELECTED_THIRD_LEVEL_CATEGORY, payload: undefined });
      requestAnimationFrame(() => setIsSecondaryListOpen(false));
      requestOpenThirdLevelList();
      if (!isTripleCategory) {
        if (isDirty || forceDirty) {
          if (isCategoryDrivenEnabled) {
            const data = await getCategoryDrivenTemplateWithoutThirdLevel(
              selectedCategories.primaryCategory,
              id,
              SR_TYPE_CODE_MAP[srType],
            );
            onTemplateChanged(data);
          }
          handleSaveCategories({ ...selectedCategories, secondaryCategory: id });
          sessionStorage.setItem(SESSION_STORAGE.categoryIsSelected, true);
        }
      } else {
        setIsThirdListReady(true);
        setShowExitPrompt(true);
      }

      if (handleDirty) {
        handleDirty({ secondaryCategoty: id });
      }
    },
    [
      isDirty,
      handleDirty,
      secondaryCategories,
      selectedCategories,
      checkAndUpdateValidationStatus,
      isTripleCategory,
      handleSaveCategories,
      requestOpenThirdLevelList,
      categoryDispatch,
      srType,
      onTemplateChanged,
      isCategoryDrivenEnabled,
    ],
  );

  const handleChangeThirdLevelCategory = useCallback(
    async (id, searchedCategoriesList, forceDirty) => {
      if (id === '-1') id = -1;
      setClearedObj((prev) => ({ ...prev, third: false }));
      const additionalThird = filterExistingCategoriesFromNewList(searchedCategoriesList, thirdLevelCategories);
      const { primaryCategory, secondaryCategory } = selectedCategories;
      const categoriesForValidation = [primaryCategory, secondaryCategory, id];
      setAdditionalBECategories((state) => ({ ...state, third: additionalThird }));
      setTimeout(() => setIsThirdListOpen(false), 250);
      setShouldResetSelectedCategories(false);
      checkAndUpdateValidationStatus(...categoriesForValidation);
      selectedCategoriesDispatch({ type: 'SET_THIRD_LEVEL_CATEGORY', payload: id });
      categoryDispatch({ type: actionTypes.SET_SELECTED_THIRD_LEVEL_CATEGORY, payload: id });
      if (isDirty || forceDirty) {
        if (isCategoryDrivenEnabled) {
          const data = await getCategoryDrivenTemplate(
            selectedCategories.primaryCategory,
            selectedCategories.secondaryCategory,
            id,
            SR_TYPE_CODE_MAP[srType],
          );
          onTemplateChanged?.(data);
        }
        handleSaveCategories({ ...selectedCategories, thirdLevelCategory: id });
        sessionStorage.setItem(SESSION_STORAGE.categoryIsSelected, true);
      }
      if (handleDirty) {
        handleDirty({ thirdLevelCategory: id });
      }
    },
    [
      thirdLevelCategories,
      isDirty,
      selectedCategories,
      checkAndUpdateValidationStatus,
      handleSaveCategories,
      categoryDispatch,
      srType,
      onTemplateChanged,
      isCategoryDrivenEnabled,
      handleDirty
    ],
  );

  const handleResetCategory = useCallback(
    (categoryType) => {
      setIsDirty(true);
      selectedCategoriesDispatch({ type: categoryType, payload: undefined });
      if (categoryType === 'SET_PRIMARY_CATEGORY') {
        setClearedObj((prev) => ({ ...prev, primary: true }));
        handleSaveCategories({
          primaryCategory: undefined,
          secondaryCategory: undefined,
          thirdLevelCategory: undefined,
        });
      } else if (categoryType === 'SET_SECONDARY_CATEGORY') {
        setClearedObj((prev) => ({ ...prev, secondary: true, third: true }));
        handleSaveCategories({ ...selectedCategories, secondaryCategory: undefined, thirdLevelCategory: undefined });
      } else {
        setClearedObj((prev) => ({ ...prev, third: true }));
        handleSaveCategories({ ...selectedCategories, thirdLevelCategory: undefined });
      }
    },
    [handleSaveCategories, selectedCategories],
  );

  const { primaryCategoryValue, secondaryCategoryValue, thirdLevelCategoryValue } = useMemo(
    () => ({
      primaryCategoryValue: getCurrentValue(
        selectedCategories.primaryCategory,
        sr?.category?.primaryCategory,
        primaryCategories?.concat(additionalBECategories.primary),
      ),
      secondaryCategoryValue: getCurrentValue(
        selectedCategories.secondaryCategory,
        sr?.category?.secondaryCategory,
        secondaryCategories?.concat(additionalBECategories.secondary),
      ),
      thirdLevelCategoryValue: getCurrentValue(
        selectedCategories.thirdLevelCategory,
        sr?.category?.thirdLevelCategory,
        thirdLevelCategories?.concat(additionalBECategories.third),
      ),
    }),
    [
      additionalBECategories.primary,
      additionalBECategories.secondary,
      additionalBECategories.third,
      getCurrentValue,
      primaryCategories,
      secondaryCategories,
      selectedCategories.primaryCategory,
      selectedCategories.secondaryCategory,
      selectedCategories.thirdLevelCategory,
      sr?.category?.primaryCategory,
      sr?.category?.secondaryCategory,
      sr?.category?.thirdLevelCategory,
      thirdLevelCategories,
    ],
  );

  useGetSuggestedCategories(DisplayPage.TicketPanel);

  const hiddenSelectOptionsPrimaryCategoriesInput = useMemo(
    () => ({
      list: primaryCategories,
      fieldId: FIRST_LEVEL_CATEGORY_FIELD_ID,
      value: primaryCategoryValue?.id,
      srType,
    }),
    [primaryCategories, primaryCategoryValue?.id, srType],
  );
  const hiddenSelectOptionsSecondaryCategoriesInput = useMemo(
    () => ({
      list: secondaryCategories,
      fieldId: SECOND_LEVEL_CATEGORY_FIELD_ID,
      value: secondaryCategoryValue?.id,
      srType,
      queryKeys: [primaryCategoryValue?.id],
    }),
    [primaryCategoryValue?.id, secondaryCategories, secondaryCategoryValue?.id, srType],
  );
  const hiddenSelectOptionsThirdLevelCategoriesInput = useMemo(
    () => ({
      list: thirdLevelCategories,
      fieldId: THIRD_LEVEL_CATEGORY_FIELD_ID,
      value: thirdLevelCategoryValue?.id,
      srType,
      queryKeys: [primaryCategoryValue?.id, secondaryCategoryValue?.id],
    }),
    [primaryCategoryValue?.id, secondaryCategoryValue?.id, thirdLevelCategories, thirdLevelCategoryValue?.id, srType],
  );
  useHiddenSelectOption(hiddenSelectOptionsPrimaryCategoriesInput);
  useHiddenSelectOption(hiddenSelectOptionsSecondaryCategoriesInput);
  useHiddenSelectOption(hiddenSelectOptionsThirdLevelCategoriesInput);

  useEffect(() => {
    const srPrimaryCategoryValue = selectedCategories.primaryCategory;
    const srSecondaryCategoryValue = selectedCategories.secondaryCategory;
    const srThirdLevelCategoryValue = selectedCategories.thirdLevelCategory;

    let primaryCategory = srPrimaryCategoryValue;
    if (clearedObj.primary) primaryCategory = undefined;
    let secondaryCategory = srSecondaryCategoryValue;
    if (clearedObj.secondary) secondaryCategory = undefined;
    let thirdLevelCategory = srThirdLevelCategoryValue;
    if (clearedObj.third) thirdLevelCategory = undefined;

    const initialValues = {
      primaryCategory,
      secondaryCategory,
      thirdLevelCategory,
    };
    const endVals = {};
    if (clearedObj.primary) {
      //..do nothing
    } else if (srPrimaryCategoryValue != null || String(srPrimaryCategoryValue) === String(DELETED_CATEGORY_ID)) {
      categoryDispatch({ type: actionTypes.SET_SELECTED_PRIMARY_CATEGORY, payload: srPrimaryCategoryValue });
      endVals.first = srPrimaryCategoryValue;
    } else if (!isExistingSR || isTemplatePage) {
      endVals.first = firstLevelCategoryDefault || null;
    }
    if (clearedObj.secondary) {
      //..do nothing
    } else if (srSecondaryCategoryValue != null || String(srSecondaryCategoryValue) === String(DELETED_CATEGORY_ID)) {
      endVals.second = srSecondaryCategoryValue;
      categoryDispatch({ type: actionTypes.SET_SELECTED_SECONDARY_CATEGORY, payload: srSecondaryCategoryValue });
    } else if (!isExistingSR || isTemplatePage) {
      endVals.second = secondLevelCategoryDefault || null;
    }
    if (isTripleCategory) {
      if (clearedObj.third) {
        //..do nothing
      } else if (
        srThirdLevelCategoryValue != null ||
        String(srThirdLevelCategoryValue) === String(DELETED_CATEGORY_ID)
      ) {
        categoryDispatch({ type: actionTypes.SET_SELECTED_THIRD_LEVEL_CATEGORY, payload: srThirdLevelCategoryValue });
        endVals.third = srThirdLevelCategoryValue;
      } else if (!isExistingSR || isTemplatePage) {
        endVals.third = thirdLevelCategoryDefault || null;
      }
    }
    selectedCategoriesDispatch({
      type: 'SET_ALL',
      payload: {
        primaryCategory: endVals.first,
        secondaryCategory: endVals.second,
        thirdLevelCategory: endVals.third,
      },
    });
    checkAndUpdateValidationStatus(
      initialValues.primaryCategory,
      initialValues.secondaryCategory,
      initialValues.thirdLevelCategory,
    );
  }, [
    clearedObj.primary,
    clearedObj.secondary,
    clearedObj.third,
    dispatch,
    categoryDispatch,
    isTemplatePage,
    checkAndUpdateValidationStatus,
    isExistingSR,
    isTripleCategory,
    firstLevelCategoryDefault,
    secondLevelCategoryDefault,
    thirdLevelCategoryDefault,
    selectedCategories.primaryCategory,
    selectedCategories.secondaryCategory,
    selectedCategories.thirdLevelCategory,
  ]);

  useEffect(() => {
    if (!isExistingSR && sr?.category?.firstLevelKey === -1) {
      setShouldResetSelectedCategories(true);
    }
  }, [isExistingSR, sr?.category?.firstLevelKey]);

  if (!isExistingSR && selectedCategories.primaryCategory != null && shouldResetSelectedCategories) {
    if (firstLevelCategoryDefault || secondLevelCategoryDefault || thirdLevelCategoryDefault) {
      selectedCategoriesDispatch({
        type: 'SET_ALL',
        payload: {
          primaryCategory: firstLevelCategoryDefault,
          secondaryCategory: secondLevelCategoryDefault,
          thirdLevelCategory: thirdLevelCategoryDefault,
        },
      });
    } else {
      selectedCategoriesDispatch({ type: 'RESET_ALL' });
    }
    setShouldResetSelectedCategories(false);
  }

  useEffect(() => {
    if (clearedObj.secondary === true) return;
    if (
      secondaryCategoriesMemo?.length &&
      selectedCategories.primaryCategory &&
      typeof selectedCategories.secondaryCategory !== 'number'
    ) {
      if (secondaryCategoriesMemo?.length === 1) {
        setTimeout(() => {
          handleChangeSecondaryCategory(secondaryCategoriesMemo[0].id, secondaryCategories, true);
        }, 300);
      } else {
        setIsSecondaryListReady(true);
      }
    }
  }, [
    handleChangeSecondaryCategory,
    clearedObj,
    required,
    secondaryCategoriesMemo,
    secondaryCategories,
    selectedCategories,
  ]);

  useEffect(() => {
    if (
      secondaryCategoriesMemo?.length >= 1 &&
      sr?.category?.secondLevelKey &&
      typeof sr?.category?.secondLevelKey !== 'number'
    ) {
      handleChangeSecondaryCategory(sr.category.secondLevelKey);
    }
  }, [sr, handleChangeSecondaryCategory, secondaryCategoriesMemo?.length]);

  useEffect(() => {
    if (clearedObj.third === true) return;
    if (isTripleCategory) {
      if (
        thirdLevelCategoriesMemo?.length &&
        typeof selectedCategories.secondaryCategory === 'number' &&
        typeof selectedCategories.thirdLevelCategory !== 'number'
      ) {
        if (thirdLevelCategoriesMemo?.length === 1) {
          setTimeout(() => {
            handleChangeThirdLevelCategory(thirdLevelCategoriesMemo[0].id, thirdLevelCategories, true);
          }, 300);
        } else {
          setIsThirdListReady(true);
        }
      }
    }
  }, [
    thirdLevelCategoriesMemo,
    thirdLevelCategories,
    selectedCategories,
    isTripleCategory,
    handleChangeThirdLevelCategory,
    required,
    clearedObj,
  ]);

  useEffect(() => {
    if (clearedObj.third === true || typeof selectedCategories.thirdLevelCategory === 'number') return;
    if (isTripleCategory) {
      if (
        thirdLevelCategoriesMemo?.length >= 1 &&
        sr?.category?.thirdLevelKey &&
        typeof sr?.category?.thirdLevelKey !== 'number'
      ) {
        handleChangeThirdLevelCategory(sr.category.thirdLevelKey, thirdLevelCategories);
      }
    }
  }, [
    sr,
    clearedObj,
    handleChangeThirdLevelCategory,
    isTripleCategory,
    thirdLevelCategories,
    thirdLevelCategoriesMemo?.length,
    selectedCategories.thirdLevelCategory,
  ]);

  useEffect(() => {
    const handleUnload = () => {
      if (showExitPrompt) {
        const e = window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = false;
          return '';
        }
      }
    };

    window.addEventListener('beforeunload', handleUnload);
    return () => window.removeEventListener('beforeunload', handleUnload);
  }, [showExitPrompt]);

  const dropdownStyles = useMemo(
    () => ({
      backgroundColor: theme.palette.blueLabels.bt_cyan_reg,
      color: theme.palette.background.autocompleteSecondary,
      fontFamily: 'Roboto-Regular',
      borderRadius: '16px',
    }),
    [theme],
  );

  const getDefaultTitle = useCallback(
    (value) => {
      switch (value) {
        case 'firstLevel':
          if (isTemplatePage) {
            return categoryText;
          }
          return selectCategoryText;
        case 'secondLevel':
          if (isTemplatePage) {
            return subCategoryText;
          }
          return selectSubCategoryText;
        case 'thirdLevel':
          if (isTemplatePage) {
            return thirdLevelCategoryText;
          }
          return select3rdLevelCategoryText;
        default:
          return '';
      }
    },
    [
      isTemplatePage,
      categoryText,
      selectCategoryText,
      subCategoryText,
      selectSubCategoryText,
      thirdLevelCategoryText,
      select3rdLevelCategoryText,
    ],
  );

  const isSecondaryDisabled = disabled || !selectedCategories.primaryCategory;
  const isThirdDisabled = disabled || !selectedCategories.primaryCategory || !selectedCategories.secondaryCategory;

  const handleFirstLevelChange = useCallback(
    (id, list) => {
      setIsDirty(true);
      setTimeout(() => {
        handleChangePrimaryCategory(id, list);
      }, 300);
    },
    [handleChangePrimaryCategory],
  );

  const handleSecondLevelChange = useCallback(
    (id, list) => {
      setIsDirty(true);
      setTimeout(() => {
        handleChangeSecondaryCategory(id, list, true);
      }, 300);
    },
    [handleChangeSecondaryCategory],
  );

  const handleThirdLevelChange = useCallback(
    (id, list) => {
      setIsDirty(true);
      setTimeout(() => {
        handleChangeThirdLevelCategory(id, list, true);
      }, 300);
    },
    [handleChangeThirdLevelCategory],
  );

  const addCategoryIfMissing = (currentList, id, name) => {
    let newList = currentList;

    if (!currentList.find((category) => category.id === id)) {
      newList = [...currentList, { id, name, valueKey: id }];
    }

    return newList;
  };

  const handleAllLevelChange = useCallback(
    ({ firstLevelKey, secondLevelKey, thirdLevelKey, primaryCategory, secondaryCategory, thirdLevelCategory }) => {
      setIsDirty(true);

      requestAnimationFrame(() => {
        setClearedObj({});
        const { primary, secondary, third } = additionalBECategories;

        const newPrimary = addCategoryIfMissing(primary, firstLevelKey, primaryCategory);
        const newSecondary = addCategoryIfMissing(secondary, secondLevelKey, secondaryCategory);
        const newThird = addCategoryIfMissing(third, thirdLevelKey, thirdLevelCategory);

        setAdditionalBECategories({
          primary: newPrimary,
          secondary: newSecondary,
          third: isTripleCategory ? newThird : third,
        });
        setIsPrimaryListOpen(false);
        setIsSecondaryListOpen(false);
        setIsThirdListOpen(false);

        setShouldResetSelectedCategories(false);
        checkAndUpdateValidationStatus([firstLevelKey, secondLevelKey, thirdLevelKey]);
        selectedCategoriesDispatch({
          type: 'SET_ALL',
          payload: {
            primaryCategory: firstLevelKey,
            secondaryCategory: secondLevelKey,
            thirdLevelCategory: thirdLevelKey,
          },
        });
        categoryDispatch({ type: actionTypes.SET_SELECTED_PRIMARY_CATEGORY, payload: firstLevelKey });
        categoryDispatch({ type: actionTypes.SET_SELECTED_SECONDARY_CATEGORY, payload: secondLevelKey });

        if (!isTripleCategory) {
          handleSaveCategories({ primaryCategory: firstLevelKey, secondaryCategory: secondLevelKey });
        } else {
          categoryDispatch({ type: actionTypes.SET_SELECTED_THIRD_LEVEL_CATEGORY, payload: thirdLevelKey });
          handleSaveCategories({
            primaryCategory: firstLevelKey,
            secondaryCategory: secondLevelKey,
            thirdLevelCategory: thirdLevelKey,
          });
        }
        sessionStorage.setItem(SESSION_STORAGE.categoryIsSelected, true);

        if (handleDirty) {
          handleDirty({ primaryCategory: firstLevelKey, secondaryCategory: secondLevelKey });
        }
      });
    },
    [
      isTripleCategory,
      checkAndUpdateValidationStatus,
      handleDirty,
      handleSaveCategories,
      additionalBECategories,
      categoryDispatch,
    ],
  );

  const {
    virtualizedScrollContextValue,
    selectOptionsWrapperClassName,
    dropdownHeightExtension,
    indexOffset,
    isSuggestedCategoryShown,
  } = useSuggestedCategoryDropdownControl({
    srId: sr?.id,
    currentCategory: {
      primaryId: primaryCategoryValue?.id,
      secondaryId: secondaryCategoryValue?.id,
      thirdLevelId: thirdLevelCategoryValue?.id,
    },
    selectSuggestedCategoryProps: {
      srId: sr?.id,
      panelHandlerOptions: {
        handleAllLevelChange,
      },
    },
  });

  const dropdownProps = useMemo(
    () => ({
      additionalWrapperClassName: selectOptionsWrapperClassName,
      dropdownHeightExtension,
      indexOffset,
    }),
    [selectOptionsWrapperClassName, dropdownHeightExtension, indexOffset],
  );

  const firstLevelOptions = useMemo(
    () => primaryCategories?.map((category) => ({ ...category, customStyle: dropdownStyles })),
    [primaryCategories, dropdownStyles],
  );

  const secondLevelOptions = useMemo(
    () => secondaryCategories?.map((category) => ({ ...category, customStyle: dropdownStyles })),
    [secondaryCategories, dropdownStyles],
  );

  const thirdLevelOptions = useMemo(
    () => thirdLevelCategories?.map((category) => ({ ...category, customStyle: dropdownStyles })),
    [thirdLevelCategories, dropdownStyles],
  );

  const primaryFocusWrapper = useCallback(() => {
    // Update local state when the user opens the dropdowns manually
    setIsPrimaryListOpen(true);
    onFocus?.(FIRST_LEVEL_CATEGORY_FIELD_ID);
  }, [onFocus]);

  const secondaryFocusWrapper = useCallback(() => {
    setIsSecondaryListOpen(true);
    onFocus?.(SECOND_LEVEL_CATEGORY_FIELD_ID);
  }, [onFocus]);

  const primaryOnClose = useCallback(() => {
    setIsPrimaryListOpen(false);
  }, []);

  const secondaryOnClose = useCallback(() => {
    setIsSecondaryListOpen(false);
  }, []);

  const thirdLevelOnClose = useCallback(() => {
    setIsThirdListOpen(false);
  }, []);

  const firstLevelBackendQueryConfig = useMemo(
    () => ({
      fetchingPromise: async (query) => {
        const searchResp = await getCategoryListAttributes(null, null, query, srType, FIRST_LEVEL_CATEGORY_FIELD_ID);
        const result = modifyCategoriesFilter(searchResp?.values, ['primaryCategory']);
        return result;
      },
    }),
    [srType],
  );

  const secondLevelBackendQueryConfig = useMemo(
    () => ({
      fetchingPromise: async (query) => {
        const searchResp = await getCategoryListAttributes(
          selectedCategories.primaryCategory,
          null,
          query,
          srType,
          SECOND_LEVEL_CATEGORY_FIELD_ID,
        );
        const result = modifyCategoriesFilter(searchResp?.values, ['secondaryCategory']);
        return result;
      },
    }),
    [srType, selectedCategories.primaryCategory],
  );

  const thirdLevelBackendQueryConfig = useMemo(
    () => ({
      fetchingPromise: async (query) => {
        const searchResp = await getCategoryListAttributes(
          selectedCategories.primaryCategory,
          selectedCategories.secondaryCategory,
          query,
          srType,
          THIRD_LEVEL_CATEGORY_FIELD_ID,
        );
        const result = modifyCategoriesFilter(searchResp?.values, ['thirdLvlCategory']);
        return result;
      },
    }),
    [srType, selectedCategories.primaryCategory, selectedCategories.secondaryCategory],
  );

  const handleFirstLevelClearValue = useCallback(
    () => handleResetCategory('SET_PRIMARY_CATEGORY'),
    [handleResetCategory],
  );
  const handleSecondLevelClearValue = useCallback(
    () => handleResetCategory('SET_SECONDARY_CATEGORY'),
    [handleResetCategory],
  );
  const handleThirdLevelClearValue = useCallback(
    () => handleResetCategory('SET_THIRD_LEVEL_CATEGORY'),
    [handleResetCategory],
  );

  return (
    <StyledCategories>
      <Container>
        <LabelWrap>
          <HeaderItemLabel className={className}>{categoriesText}</HeaderItemLabel>
          <div className="categories">
            <VirtualizedScrollContext.Provider value={virtualizedScrollContextValue}>
              <CategorySelect
                onChange={handleFirstLevelChange}
                list={firstLevelOptions}
                initValue={primaryCategoryValue}
                customHeaderStyles={(selectedValue) =>
                  getCustomHeaderStyles(selectedValue, required, forceShowValidation)
                }
                defaultTitle={getDefaultTitle('firstLevel')}
                isLoading={defaultValues?.primaryCategory && !isDirty ? !primaryCategoryValue : false}
                backendQueryConfig={firstLevelBackendQueryConfig}
                disabled={disabled}
                handleClearValue={handleFirstLevelClearValue}
                isTemplatePage={isTemplatePage}
                fieldId={categoryFieldsMap?.['category.firstLevelKey']?.fieldId}
                dataset={dataset}
                isOpen={isPrimaryListOpen}
                onFocus={primaryFocusWrapper}
                onClose={primaryOnClose}
                isSuggestedCategoryShown={isSuggestedCategoryShown}
                dropdownProps={dropdownProps}
                className={className}
                required={required}
                isNewField={!isExistingSR}
              />
              <CategorySelect
                onChange={handleSecondLevelChange}
                initValue={secondaryCategoryValue}
                isLoading={defaultValues?.secondaryCategory && !isDirty ? !secondaryCategoryValue : false}
                list={secondLevelOptions}
                customHeaderStyles={(selectedValue) =>
                  getCustomHeaderStyles(selectedValue, required, forceShowValidation)
                }
                defaultTitle={getDefaultTitle('secondLevel')}
                isOpen={isSecondaryListOpen}
                backendQueryConfig={secondLevelBackendQueryConfig}
                disabled={isSecondaryDisabled}
                handleClearValue={handleSecondLevelClearValue}
                isTemplatePage={isTemplatePage}
                fieldId={categoryFieldsMap?.['category.secondLevelKey']?.fieldId}
                dataset={dataset}
                onFocus={secondaryFocusWrapper}
                onClose={secondaryOnClose}
                dropdownProps={dropdownProps}
                className={className}
                required={required}
                isNewField={!isExistingSR}
              />
              {isTripleCategory && (
                <CategorySelect
                  onChange={handleThirdLevelChange}
                  initValue={thirdLevelCategoryValue}
                  isLoading={defaultValues?.thirdLevelCategory && !isDirty ? !thirdLevelCategoryValue : false}
                  list={thirdLevelOptions}
                  customHeaderStyles={(selectedValue) =>
                    getCustomHeaderStyles(selectedValue, required, forceShowValidation)
                  }
                  defaultTitle={getDefaultTitle('thirdLevel')}
                  isOpen={isThirdListOpen}
                  backendQueryConfig={thirdLevelBackendQueryConfig}
                  disabled={isThirdDisabled}
                  handleClearValue={handleThirdLevelClearValue}
                  isTemplatePage={isTemplatePage}
                  fieldId={categoryFieldsMap?.['category.thirdLevelKey']?.fieldId}
                  dataset={dataset}
                  onFocus={thirdLevelFocusWrapper}
                  onClose={thirdLevelOnClose}
                  dropdownProps={dropdownProps}
                  className={className}
                  required={required}
                  isNewField={!isExistingSR}
                />
              )}
            </VirtualizedScrollContext.Provider>
          </div>
        </LabelWrap>
        {/* TODO: According to the open state - this never opens, and as currently just throwing warnings. Temporarily commented out */}
        {/* <MessagePrompt
          open={showPrompt}
          title={texts.warningTitle}
          description={texts.warningDescription}
          PromptIcon={WarningIcon}
          onOkClick={confirmNavigation}
          handleClose={cancelNavigation}
          showCancelBtn
          btnOkText="Cancel"
          btnCancelText="Leave anyway"
        /> */}
      </Container>
    </StyledCategories>
  );
}
export default memo(Categories);
