import { useState, useMemo, memo, useContext, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonBase from 'common/components/buttons/FormButton';
import ButtonWithMenu from 'common/components/buttons/ButtonWithMenu';
import { PUBLIC_VISIBILITY_VALUE } from 'common/components/queueViews/saveViewAsModal/constants';
import { selectActiveView } from 'features/queue/slice';
import { ReactComponent as SaveIcon } from 'images/icons/save-view.svg';
import { ReactComponent as SaveAsIcon } from 'images/icons/save-as.svg';
import { convertFromClientField } from 'common/utils/fieldUtils';
import { useUpdateQueueView, useCreateQueueView } from 'remote-state/queueViewsHooks';
import { useUserInfo } from 'remote-state/userServiceHooks';
import { QUERIES_KEYS } from 'constant';
import { setToasterMessage } from 'store/globalSlice';
import SaveViewAsModal from 'common/components/queueViews/saveViewAsModal';
import { ActionButtons } from './styledDrawer';
import useTexts from './useTexts';
import { ColItemsContext, ConfigOpenContext } from './context';
import { removeMismatchedElements } from '../helpers';

const ActionBox = memo(({ isDirty }) => {
  const dispatch = useDispatch();
  const { setOpen } = useContext(ConfigOpenContext);
  const { cancel, queueViewsMessageSaveSuccess, save, saveAs } = useTexts();
  const { mutate: updateView } = useUpdateQueueView();
  const { data: userData } = useUserInfo(QUERIES_KEYS.CURRENT_USER_FROM_CORE);
  const { items } = useContext(ColItemsContext);
  const [loading, setLoading] = useState(false);
  const activeView = useSelector(selectActiveView);
  const isPublicView = activeView?.visibility === PUBLIC_VISIBILITY_VALUE;
  const isSysAidAdmin = userData?.isSysAidAdmin;

  const [isSaveAsPopUpOpen, setIsSaveAsPopUpOpen] = useState(false);
  const { mutate: createView } = useCreateQueueView();
  const [showUniqueNameErrorText, setShowUniqueNameErrorText] = useState(false);
  const CONFLICT_ERROR_CODE = 409;

  const onErrorCallback = useCallback(
    (error) => {
      if (error.response) {
        if (error.response.status === CONFLICT_ERROR_CODE) {
          setShowUniqueNameErrorText(true);
        } else {
          setIsSaveAsPopUpOpen(false);
          dispatch(
            setToasterMessage({
              message: error.response.data.message,
            }),
          );
        }
      } else {
        setIsSaveAsPopUpOpen(false);
        dispatch(
          setToasterMessage({
            message: error.response.data.message,
          }),
        );
      }
    },
    [dispatch],
  );

  const getUpdatedItems = useCallback(() => {
    //Before saving we need to flatten coupled columns such as categories
    const sortedItems = items.reduce((result, item) => {
      if (Array.isArray(item.children)) {
        const { children, ...rest } = item;
        return result.concat([rest, ...children]);
      }
      result.push(item);
      return result;
    }, []);
    return {
      ...activeView,
      columnsConfig: {
        sorts: activeView.columnsConfig.customSortModel,
        filters: removeMismatchedElements(activeView?.columnsConfig?.customFilterModel, items),
        columnConfiguration: sortedItems?.map((col) => convertFromClientField(col)),
        isQuickFilterApplied: activeView.columnsConfig.isQuickFilterApplied,
      },
    };
  }, [items, activeView]);

  const onSaveAsClicked = ({ viewName, visibility, visibleForGroupIds }) => {
    const createObj = { ...getUpdatedItems(), name: viewName, visibility, visibleForGroupIds };
    delete createObj.id;
    delete createObj.createdDate;
    delete createObj.modifiedDate;
    setShowUniqueNameErrorText(false);

    createView(createObj, {
      onSuccess: () => {
        setOpen(false);
        dispatch(
          setToasterMessage({
            message: queueViewsMessageSaveSuccess,
          }),
        );
      },

      onError: onErrorCallback,
    });
  };

  const handleCloseModals = () => {
    setShowUniqueNameErrorText(false);
    setIsSaveAsPopUpOpen(false);
  };

  const handleCreateView = useCallback(() => {
    setIsSaveAsPopUpOpen(true);
  }, [setIsSaveAsPopUpOpen]);

  const onSaveClicked = useCallback(() => {
    setLoading(true);
    const createObj = getUpdatedItems();
    updateView(createObj, {
      onSuccess: () => {
        setLoading(false);
        setOpen(false);
        dispatch(
          setToasterMessage({
            message: queueViewsMessageSaveSuccess,
          }),
        );
      },
      onError: (error) => {
        dispatch(
          setToasterMessage({
            message: error.response.data.message,
          }),
        );
      },
    });
  }, [dispatch, getUpdatedItems, updateView, setOpen, queueViewsMessageSaveSuccess]);

  const saveButtonMenu = useMemo(
    () => [
      {
        title: saveAs,
        icon: <SaveAsIcon />,
        onClick: () => handleCreateView(),
        disabled: !isDirty,
        'data-testid': 'coulmnConfigurationSaveAsButton',
      },
    ],
    [isDirty, handleCreateView, saveAs],
  );

  return (
    <>
      <ActionButtons>
        <ButtonBase
          data-testid="cc-cancel"
          btnTheme="secondary"
          disabled={false}
          onClick={() => setOpen(false)}
          text={cancel}
        />
        <ButtonWithMenu
          title={save}
          menuList={saveButtonMenu}
          onClick={onSaveClicked}
          loading={loading}
          icon={<SaveIcon />}
          disabled={!isDirty || (isPublicView && !isSysAidAdmin)}
          btnTheme="primary"
          testIdPrefix="coulmnConfiguration"
        />
      </ActionButtons>

      {isSaveAsPopUpOpen && (
        <SaveViewAsModal
          onClose={handleCloseModals}
          onSaveClicked={onSaveAsClicked}
          view={activeView}
          open={isSaveAsPopUpOpen}
          uniqueNameError={showUniqueNameErrorText}
          setShowUniqueNameErrorText={setShowUniqueNameErrorText}
        />
      )}
    </>
  );
});
export default ActionBox;
