import { useCallback, useMemo, useRef } from 'react';
import { useApplicationData } from 'remote-state/applicationHooks';
import { useUserInfo } from 'remote-state/userServiceHooks';
import { QUERIES_KEYS } from 'constant';
import ForwardRefFilter from 'common/components/forwardRefFilter';
import { getIsCategory, getIsUserFieldByFieldName } from 'common/utils/fieldUtils';
import { FILTER_TYPE_CONFIGURATIONS, RESPONSIBLE_MANAGER_FIELD_ID, COLUMN_CELL_TYPES } from '../constants';
import ActionBtnRenderer from '../actionBtnRenderer';
import DefaultCellRender from '../defaultCellRender';
import CustomSelectBox from '../customSelectBox';
import { useFeatureFlagQuery } from '../../../../remote-state/featureFlagsHooks';
import { FEATURE_FLAGS_KEYS } from '../../../../constants/featureFlags';
import { SR_PANEL_CONSTANTS } from '../../../srPanel/consts';
import { COLUMN_FILTER_FIELDTYPE } from '../../../../common/components/grid/constants';
import { COLUMN_WIDTHS_LS_KEY } from './useCacheColumnsWidth';

const requiredFields = ['status', 'assignee', 'srType'];

export const DEFAULT_MIN_WIDTH = 68;
export const DEFAULT_SPECIAL_SELECTS_WIDTH = 120;
export const DEFAULT_DUE_DATE_MIN_WIDTH = 130;
export const DEFAULT_DUE_DATE_WIDTH = 140;
export const DEFAULT_WIDTH = 150;
export const DEFAULT_TEXT_WIDTH = 240;
export const MAX_WIDTH = 700;

export const calculateMinWidth = (title, defaultWidth = DEFAULT_MIN_WIDTH) => {
  const characterWidth = 7;
  const calculated = title.length * characterWidth + 24; // 24px for padding
  return Math.max(calculated, defaultWidth);
};

export const useGetColumnDefinitions = (sr, getColumnText) => {
  const { data: isTripleCategory } = useApplicationData(QUERIES_KEYS.IS_EXTERNAL_MODE);
  const { data: currentUserPermissions } = useUserInfo(QUERIES_KEYS.CURRENT_USER_PERMISSIONS);
  const { data: isSROpenedFromQueue } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.OPEN_SR_FROM_QUEUE,
    defaultValue: false,
  });
  const { data: isArchiveInSpacesEnabled } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.ARCHIVE_IN_SPACES,
    defaultValue: false,
  });

  const cachedColumnWidth = useRef(JSON.parse(localStorage.getItem(COLUMN_WIDTHS_LS_KEY) || '{}'));
  const getSavedWidth = useCallback((colId) => cachedColumnWidth.current[colId] || undefined, []);

  const isBulkActionsEnabled = currentUserPermissions?.userPermissionEnableBulkActionsFromList;
  const staticColumns = useMemo(() => {
    const defaultConfig = {
      headerName: '',
      hide: false,
    };

    const openLinkField = {
      fieldTypeName: 'OPEN_LINK',
      field: 'openticket',
      lockPosition: true,
      pinned: 'left',
      lockPinned: true,
      resizable: false,
      width: 70,
      minWidth: 32,
      suppressSizeToFit: true,
      headerClass: 'opensas',
      cellRenderer: DefaultCellRender,
      ...defaultConfig,
    };
    const archiveField = {
      field: 'archive',
      colId: 'archive',
      headerName: 'Archive',
      customColumn: false,
      lockPosition: true,
      pinned: 'left',
      resizable: false,
      fieldTypeId: SR_PANEL_CONSTANTS.NUMBER,
      fieldTypeName: COLUMN_CELL_TYPES.NUMBER,
      filter: ForwardRefFilter,
      filterParams: {
        filterConf: {
          filterType: COLUMN_FILTER_FIELDTYPE.number,
          type: 'equals',
        },
        listKey: 'archive',
      },
      hide: true,
    };

    if (isBulkActionsEnabled) {
      return [
        {
          field: 'delete',
          lockPosition: true,
          pinned: 'left',
          resizable: false,
          width: 20,
          headerClass: 'deleteClassName',
          cellRenderer: ActionBtnRenderer,
          ...defaultConfig,
        },
        {
          field: 'checkbox',
          checkboxSelection: true,
          lockPosition: true,
          pinned: 'left',
          lockPinned: true,
          resizable: false,
          width: 32,
          minWidth: 32,
          suppressSizeToFit: true,
          headerClass: 'checkboxClassName',
          cellRenderer: CustomSelectBox,
          ...defaultConfig,
        },
        ...(isSROpenedFromQueue ? [] : [openLinkField]),
        ...(isArchiveInSpacesEnabled ? [archiveField] : []),
      ];
    }
    return [
      {
        field: 'delete',
        lockPosition: true,
        pinned: 'left',
        resizable: false,
        width: 20,
        headerClass: 'deleteClassName',
        cellRenderer: ActionBtnRenderer,
        ...defaultConfig,
      },
      ...(isSROpenedFromQueue ? [] : [openLinkField]),
      ...(isArchiveInSpacesEnabled ? [archiveField] : []),
    ];
  }, [isBulkActionsEnabled, isSROpenedFromQueue, isArchiveInSpacesEnabled]);

  const getColumnConfigByType = useCallback(
    ({ fieldType, fieldId, fieldName, isLast = false, headerName }) => {
      const savedWidth = getSavedWidth(fieldName);
      const selectFieldParams = {
        sortBy: getIsUserFieldByFieldName(fieldName) ? 'valueCaption' : undefined,
        keyString: fieldId === RESPONSIBLE_MANAGER_FIELD_ID ? 'value' : 'valueKey', // fieldId === CC_USER_FIELD_ID @TODO return this when the bug on the BE regarding CC filter is fixed
        captionString: 'valueCaption',
      };

      const defaultConfig = {
        minWidth: calculateMinWidth(headerName),
        width: savedWidth || DEFAULT_WIDTH,
        maxWidth: isLast ? undefined : MAX_WIDTH,
        hide: false,
        ...(isLast ? { flex: 1, resizable: false } : {}),
      };

      const params = (() => {
        switch (fieldType) {
          case COLUMN_CELL_TYPES.TEXT:
            return {
              ...defaultConfig,
              width: savedWidth || DEFAULT_TEXT_WIDTH,
            };
          case COLUMN_CELL_TYPES.LONGTEXT:
            return {
              ...defaultConfig,
              width: savedWidth || DEFAULT_TEXT_WIDTH,
              maxWidth: undefined,
              flex: undefined,
            };
          case COLUMN_CELL_TYPES.NUMBER:
            if (fieldName === 'id') {
              return {
                ...defaultConfig,
                pinned: 'left',
                lockPosition: true,
                lockPinned: true,
                width: savedWidth || 90,
                resizable: false,
                suppressSizeToFit: true,
                ...(isSROpenedFromQueue && {
                  cellStyle: {
                    marginLeft: '-12px',
                  },
                }),
              };
            }
            return {
              ...defaultConfig,
              maxWidth: isLast ? undefined : 150,
            };
          case COLUMN_CELL_TYPES.MULTISELECT:
            return {
              ...defaultConfig,
              ...selectFieldParams,
              width: savedWidth || 250,
            };
          case COLUMN_CELL_TYPES.SELECT:
            if (getIsCategory(fieldId)) {
              return {
                ...defaultConfig,
                captionString: 'valueCaption',
                width: savedWidth || 140,
              };
            }
            if (fieldName === 'assignee' || fieldName === 'assignedGroup') {
              return {
                ...defaultConfig,
                width: savedWidth || 200,
              };
            }
            if (fieldName === 'requestUser') {
              return {
                ...defaultConfig,
                ...selectFieldParams,
                width: savedWidth || 200,
              };
            }
            if (fieldName === 'requestUserManager') {
              return { ...defaultConfig, width: savedWidth || 250 };
            }
            if (['srType', 'status', 'priority', 'urgency', 'impact'].includes(fieldName)) {
              return {
                ...defaultConfig,
                ...selectFieldParams,
                width: savedWidth || DEFAULT_SPECIAL_SELECTS_WIDTH,
              };
            }
            return {
              ...defaultConfig,
              ...selectFieldParams,
            };
          case COLUMN_CELL_TYPES.DATE:
          case COLUMN_CELL_TYPES.TIME_REMAINING:
          case COLUMN_CELL_TYPES.DATEANDTIME:
            return {
              ...defaultConfig,
              width: savedWidth ?? (fieldName === 'dueDate' ? DEFAULT_DUE_DATE_WIDTH : 200),
              minWidth:
                fieldName === 'dueDate'
                  ? Math.max(DEFAULT_DUE_DATE_MIN_WIDTH, defaultConfig.minWidth)
                  : defaultConfig.minWidth,
            };
          default:
            return defaultConfig;
        }
      })();
      return params;
    },
    [isSROpenedFromQueue, getSavedWidth],
  );

  const columns = useMemo(() => {
    if (sr) {
      let columnsArr;
      //Check if third level category turned off in general settings if so the remove it from list.
      if (!isTripleCategory) {
        columnsArr = sr.filter((col) => col.fieldName !== 'thirdLevelCategory');
      } else {
        columnsArr = sr;
      }
      return columnsArr.map(
        ({ fieldName: name, id, ticketTemplateFieldType, sysaidEntity, displayName, customColumn }, index) => {
          const headerFromRB = getColumnText && getColumnText(displayName);
          const headerName = headerFromRB || displayName;
          const isLast = index === columnsArr.length - 1;

          const column = {
            field: name,
            fieldId: id,
            sysaidEntity,
            fieldTypeId: ticketTemplateFieldType?.id,
            fieldTypeName: ticketTemplateFieldType?.typeName?.toUpperCase(),
            sortable: true,
            suppressMenu: true,
            headerClass: `${name}HeaderClass ${index >= 1 ? 'unlockedColumn' : ''}`,
            headerName,
            filter: ForwardRefFilter,
            filterParams: {
              filterConf: FILTER_TYPE_CONFIGURATIONS[ticketTemplateFieldType?.id],
              listKey: name,
            },
            customColumn,
            cellRenderer: DefaultCellRender,
            ...getColumnConfigByType({
              fieldType: ticketTemplateFieldType?.typeName?.toUpperCase(),
              fieldName: name,
              fieldId: id,
              headerName,
              isLast,
            }),
          };
          return column;
        },
      );
    }
    return [];
  }, [sr, getColumnText, getColumnConfigByType, isTripleCategory]);

  const hiddenColumns = useMemo(() => {
    const hiddenColumnsList = [];
    const existingNamesSet = new Set(columns.map((obj) => obj.field));
    requiredFields.forEach((field) => {
      if (!existingNamesSet.has(field)) {
        hiddenColumnsList.push({
          field,
          filter: ForwardRefFilter,
          hide: true,
        });
      }
    });
    return hiddenColumnsList;
  }, [columns]);

  const arrayToReturn = useMemo(
    () => [...staticColumns, ...columns, ...hiddenColumns],
    [staticColumns, columns, hiddenColumns],
  );

  return arrayToReturn;
};
