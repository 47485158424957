import { styled } from '@mui/material/styles';
import ListItemButton from '@mui/material/ListItemButton';

export const StyledDragOverlaySection = styled(ListItemButton)(({ theme }) => ({
    border: `1px solid ${theme.palette.grey.divider_grey_v1}`,
    borderLeft: `4px solid ${theme.palette.generic.sysaid}`,
    borderRadius: theme.shape.border.borderRadius,
    background: theme.palette.generic.primary,
    alignItems: 'center',
    padding: '24px 0',
    cursor: 'grabbing',
    marginTop: '20px',
    '.MuiListItemText-root': {
        margin: '0 8px',
    },
    '.MuiListItemText-primary': {
        fontSize: theme.fontSize.medium,
        color: theme.palette.text.txt_default,
        lineHeight: 1,
    },
    '.drag-icon': {
        margin: '0 10px',
    },
    '.toggle-icon': {
        transform: 'rotate(180deg)',
    },
    '&:hover': {
        background: theme.palette.generic.primary,
    },
}));
