import { useState } from 'react';
import { useRouter } from '@tanstack/react-router';
import { Chip, Select, Input } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { useEditorActions } from 'features/resolutionPanel/middlePanel/auditLog/hooks';
import { AVATAR_CONSTANTS } from 'constants/index';
import { ReactComponent as ArrowDownIcon } from 'images/icons/arrowdown.svg';
import { editorActionTypes } from 'features/resolutionPanel/middlePanel/auditLog/constants/editorActionTypes';
import { useSR } from 'remote-state/ticketServiceHooks';
import { useInitialContent } from '../../../hooks/messageHooks';
import { StyledSenderInput, StyledMenuItem, StyledMenuDetails, StyledBasicAvatar } from './style';

const SenderInput = ({ selected }) => {
  const router = useRouter();
  const { handleEditorChange } = useEditorActions();
  const [open, setOpen] = useState(false);
  const srId = router.latestLocation.search.id;
  const { data } = useInitialContent(editorActionTypes.MESSAGE, srId);
  const { sr: { data: srObject } } = useSR();

  const getSender = () => {
    const availableSenders = data?.fromOptions;
    if (availableSenders && selected && Array.isArray(availableSenders) && Array.isArray(selected)) {
      for (const availableSender of availableSenders) {
        for (const selectedSender of selected) {
          if (availableSender.emailAddress?.includes(selectedSender.emailAddress)) {
            return availableSender;
          }
        }
      }
    }

    const openedSrEmail = srObject?.emailAccount;
    return availableSenders?.find((availableSender) => availableSender.emailAddress === openedSrEmail) ??
     availableSenders?.find((account) => account.defaultAccount);
  };

  const defaultSender = getSender();
  const currentSelection = !isEmpty(selected) ? selected : defaultSender;

  const handleSelect = (e) => {
    handleEditorChange('sender', { sender: e.target.value });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (data?.fromOptions?.length === 1)
    return (
      <StyledSenderInput data-testid="default-sender-chip" data-cy="default-sender-chip">
        <Chip
          label={currentSelection?.displayName}
          avatar={
            <StyledBasicAvatar
              disableHover
              size={AVATAR_CONSTANTS.SIZES.SMALL}
              userName={currentSelection?.displayName}
              profileImage={currentSelection?.profileImage}
            />
          }
        />
      </StyledSenderInput>
    );

  return (
    <StyledSenderInput isOpen={open} data-testid="dropdown-sender-field">
      <Select
        labelId="sender-select-chip-label"
        id="sender-select-chip"
        value={currentSelection}
        onOpen={handleOpen}
        onClose={handleClose}
        input={<Input disableUnderline id="sender-select-chip" data-testid="sender-input" data-cy="sender-input" label="Chip" />}
        IconComponent={ArrowDownIcon}
        onChange={handleSelect}
        renderValue={() => (
          <Chip
            label={currentSelection?.displayName}
            clickable
            sx={{ '&:hover': { backgroundColor: '#F5F6FA' } }}
            avatar={
              <StyledBasicAvatar
                disableHover
                size={AVATAR_CONSTANTS.SIZES.SMALL}
                userName={currentSelection?.displayName}
                profileImage={currentSelection?.profileImage}
              />
            }
          />
        )}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        {data?.fromOptions?.map((sender, index) => (
          <StyledMenuItem key={sender.id} value={sender} data-cy={`message-sender-${index}`}>
            <StyledBasicAvatar disableHover userName={sender.displayName} profileImage={sender.profileImage} />
            <StyledMenuDetails>
              <div className="name">{sender?.displayName}</div>
              <div className="email">{sender?.emailAddress}</div>
            </StyledMenuDetails>
          </StyledMenuItem>
        ))}
      </Select>
    </StyledSenderInput>
  );
};

export default SenderInput;
