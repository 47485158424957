import { useState, memo } from 'react';
import { Box } from '@mui/material';
import { StyledConfigBody, StyledSearchField } from './styledDrawer';
import ColumnList from './ColumnList';

const ConfigBody = () => {
  const [searchText, setSearchText] = useState('');
  return (
    <StyledConfigBody>
      <Box style={{ padding: '0px 30px 0px 50px' }}>
        <StyledSearchField
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onClear={() => setSearchText('')}
        />
      </Box>
      <ColumnList searchStr={searchText} />
    </StyledConfigBody>
  );
};

export default memo(ConfigBody);
