import openTicketIcon from 'images/icons/ticket-open.svg';
import closeTicketIcon from 'images/icons/ticket-close.svg';
import viewTicketIcon from 'images/icons/ticket-view.svg';
import dueDateTicketIcon from 'images/icons/ticket-past-due-date.svg';
import fieldChangeTicketIcon from 'images/icons/ticket-field-change.svg';
import openFolderIcon from 'images/icons/folder-open-icon.svg';
import messageSentIcon from 'images/icons/messageSent.svg';
import attachmentsIcon from 'images/icons/attachment-footer-item.svg';
import notificationIcon from 'images/icons/notification.svg';
import resolvedIcon from 'images/icons/ticket-resolved.svg';
import resolvedDueDateIcon from 'images/icons/resolved-duedate.svg';
import articleCreatedIcon from 'images/icons/article-created.svg';
import workflowIcon from 'images/icons/workflows/workflow.svg';
import activityIcon from 'images/icons/activities-footer-item.svg';
import linkedItemIcon from 'images/icons/link-related-item-journey.svg';
import archiveTicketIcon from 'images/icons/archive-icon.svg';
import restoreTicketIcon from 'images/icons/restore-icon.svg';

import TitleImg from './titleImg';
import { actionEnums } from '../constants';

const LogActionImg = ({ logType }) => {
  const renderLogActionImg = () => {
    let chosenClassName = null;
    let commonClassName = 'title-img';
    let logActionSrc = 'title-img';
    switch (logType) {
      // events
      case actionEnums.OPERATION_CREATE:
      case actionEnums.EVENT_REOPEN:
      case actionEnums.TICKET_MIGRATION_HISTORY:
      case actionEnums.TICKET_CONVERSION:
        logActionSrc = openTicketIcon;
        chosenClassName = 'open-ticket-icon';
        break;
      case actionEnums.EVENT_CLOSE:
        logActionSrc = closeTicketIcon;
        chosenClassName = 'close-ticket-icon';
        break;
      case actionEnums.EVENT_VIEW:
        logActionSrc = viewTicketIcon;
        chosenClassName = 'view-ticket-icon';
        break;
      case actionEnums.NOTIFICATION:
      case actionEnums.ESCALATION_MESSAGE:
        logActionSrc = notificationIcon;
        chosenClassName = 'view-ticket-icon';
        break;
      case actionEnums.EVENT_DUE_DATE:
        logActionSrc = dueDateTicketIcon;
        chosenClassName = 'due-date-ticket-icon';
        break;
      case actionEnums.NOTE_CREATE:
      case actionEnums.NOTE_DELETE:
      case actionEnums.NOTE_UPDATE:
        logActionSrc = openFolderIcon;
        break;
      case actionEnums.MESSAGE_SENT:
      case actionEnums.AI_MESSAGE_SEND:
      case actionEnums.MESSAGE_RECEIVED:
      case actionEnums.IM_MESSAGE:
      case actionEnums.SMS_MESSAGE:
      case actionEnums.ITIL_MESSAGE:
        logActionSrc = messageSentIcon;
        chosenClassName = 'message-sent-icon';

        break;
      case actionEnums.ATTACHMENTS_ADDED:
      case actionEnums.ATTACHMENTS_DELETED:
      case actionEnums.ATTACHMENTS_DOWNLOADED:
        logActionSrc = attachmentsIcon;
        break;
      case actionEnums.SOLUTION_RESOLUTION_CREATED:
        commonClassName = 'solution-resolution-created';
        logActionSrc = resolvedIcon;
        break;
      case actionEnums.SOLUTION_UPDATED:
      case actionEnums.RESOLUTION_UPDATED:
        logActionSrc = resolvedIcon;
        break;
      case actionEnums.SOLUTION_SR_RESOLVED:
        logActionSrc = resolvedDueDateIcon;
        break;
      case actionEnums.SOLUTION_NEW_ARTICLE_CREATED:
        logActionSrc = articleCreatedIcon;
        break;
      case actionEnums.ACTION_ITEM_STATUS_COMPLETED:
      case actionEnums.ACTION_ITEM_STATUS_ENABLED:
      case actionEnums.ACTION_ITEM_FIELD_CHANGE:
      case actionEnums.ACTION_ITEM_DATE_FIELD_CHANGE:
      case actionEnums.ACTION_ITEM_STATUS_DISABLED:
      case actionEnums.ACTION_ITEM_ATTACHMENTS_ADDED:
      case actionEnums.ACTION_ITEM_ATTACHMENTS_DELETED:
        logActionSrc = workflowIcon;
        break;
      case actionEnums.ACTIVITY_CREATED:
        logActionSrc = activityIcon;
        chosenClassName = 'activity-icon';
        break;
      case actionEnums.RELATED_ITEM_SR_CREATED:
      case actionEnums.RELATED_ITEM_SR_DELETED:
      case actionEnums.RELATED_ITEM_ASSET_CREATED:
      case actionEnums.RELATED_ITEM_ASSET_DELETED:
      case actionEnums.RELATED_ITEM_CI_CREATED:
      case actionEnums.RELATED_ITEM_CI_DELETED:
        logActionSrc = linkedItemIcon;
        break;
      case actionEnums.SR_ARCHIVED:
        logActionSrc = archiveTicketIcon;
        break;
      case actionEnums.SR_RESTORED:
        logActionSrc = restoreTicketIcon;
        break;

      // default - field changes
      default:
        logActionSrc = fieldChangeTicketIcon;
        chosenClassName = 'field-change-ticket-icon';
    }
    return <TitleImg className={`${commonClassName} ${chosenClassName}`} imgSrc={logActionSrc} />;
  };

  return <div className="log-title">{renderLogActionImg()}</div>;
};

export default LogActionImg;
