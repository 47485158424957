import bold from 'images/icons/richTextEditor/bold.svg';
import italic from 'images/icons/richTextEditor/italic.svg';
import underlined from 'images/icons/richTextEditor/underlined.svg';
import textColor from 'images/icons/richTextEditor/textColour.svg';
import textSize from 'images/icons/richTextEditor/textSize.svg';
import inlineImage from 'images/icons/richTextEditor/inlineImage.svg';
import insertLink from 'images/icons/richTextEditor/insertLink.svg';
import attachment from 'images/icons/richTextEditor/attachment.svg';
import mention from 'images/icons/richTextEditor/mention.svg';
import alignRight from 'images/icons/richTextEditor/alignRight.svg';
import alignLeft from 'images/icons/richTextEditor/alignLeft.svg';
import alignCenter from 'images/icons/richTextEditor/alignCenter.svg';
import bullets from 'images/icons/richTextEditor/bullets.svg';
import numbered from 'images/icons/richTextEditor/numbered.svg';
import rtl from 'images/icons/richTextEditor/rtl.svg';
import ltr from 'images/icons/richTextEditor/ltr.svg';
import highlight from 'images/icons/richTextEditor/highlight.svg';
import moreOptions from 'images/icons/richTextEditor/moreOptions.svg';
import imageRemove from 'images/icons/richTextEditor/imageRemove.svg';
import download from 'images/icons/richTextEditor/download.svg';
import imageExpand from 'images/icons/richTextEditor/imageExpand.svg';
import cannedResponses from 'images/icons/richTextEditor/cannedResponses.svg';
import improve from 'images/icons/generativeAi/rephrase/improve.svg';

export const icons = [
  {
    name: 'bold',
    icon: bold,
  },
  {
    name: 'italic',
    icon: italic,
  },
  {
    name: 'underline',
    icon: underlined,
  },
  {
    name: 'textColor',
    icon: textColor,
  },
  {
    name: 'fontSize',
    icon: textSize,
  },
  {
    name: 'insertLink',
    icon: insertLink,
  },
  {
    name: 'insertImage',
    icon: inlineImage,
  },
  {
    name: 'insertFile',
    icon: attachment,
  },
  {
    name: 'mention',
    icon: mention,
  },
  {
    name: 'align-right',
    icon: alignRight,
  },
  {
    name: 'align-left',
    icon: alignLeft,
  },
  {
    name: 'align',
    icon: alignLeft,
  },
  {
    name: 'align-center',
    icon: alignCenter,
  },
  {
    name: 'align-justify',
    icon: alignLeft,
  },
  {
    name: 'direction',
    icon: ltr,
  },
  {
    name: 'ltr',
    icon: ltr,
  },
  {
    name: 'rtl',
    icon: rtl,
  },
  {
    name: 'formatOL',
    icon: numbered,
  },
  {
    name: 'formatUL',
    icon: bullets,
  },
  {
    name: 'moreOptions',
    icon: moreOptions,
  },
  {
    name: 'highlight',
    icon: highlight,
  },
  {
    name: 'imageRemove',
    icon: imageRemove,
  },
  {
    name: 'download',
    icon: download,
  },
  {
    name: 'imageExpand',
    icon: imageExpand,
  },
  {
    name: 'cannedResponses',
    icon: cannedResponses,
  },
  {
    name: 'improve',
    icon: improve,
  },
];
