import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    refine: texts?.['spaces.queue.resultsMessage.refine'],
    noResultsTitle: texts?.['spaces.queue.resultsMessage.noResults.title'],
    noResultsSecondaryTitle: texts?.['spaces.queue.resultsMessage.noResults.secondaryTitle'],
  };

  return componentTexts;
}
