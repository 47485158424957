export const COMMON_CONSTANTS = {
  NONE: 'none',
};

export const ERROR_TYPES = {
  NO_VIEW_PERMISSIONS_TICKET: 'NO_VIEW_PERMISSIONS_TICKET',
  NO_VIEW_PERMISSIONS_TEMPLATE: 'NO_VIEW_PERMISSIONS_TEMPLATE',
  PAGE_NOT_FOUND: 'PAGE_NOT_FOUND',
};

export const CHANNEL_MESSAGE_TYPES = {
  UPDATED: 'updateTicket',
  UPDATED_ACTION_ITEM: 'updateActionItem',
  DELETE_TICKET: 'deleteTicket',
  ADD_TICKET: 'addTicket',
  LOCK_TICKET: 'lockTicket',
  UNLOCK_TICKET: 'unlockTicket',
  UPDATE_SETTINGS: 'updateSettings',
};

export const SUB_MENU_ITEMS = {
  MY_APPS: 126,
};
