import { Menu } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    maxWidth: '300px',
    borderRadius: '6px',
    '& .MuiTypography-root': {
      cursor: 'pointer',
      lineHeight: '1.25rem',
      fontSize: '.875rem',
      padding: '11px 16px',
      textAlign: 'left',
      '&:hover': {
        backgroundColor: theme.palette.grey.bt_alt_grey_light,
      },
    },
  },
}));
