import { createSlice, createSelector } from '@reduxjs/toolkit';
import { updateState } from './actionLineReducer';
import { editorActionTypes } from '../constants/editorActionTypes';
import { initialNote } from './initialState';
import { JOURNEY_FILTERS, JOURNEY_TABS } from './constants';

export const initialPrompt = {
  isOpen: false,
  promptType: null,
  message: null,
  isCancelClicked: false,
  parallelEditType: null,
  parallelEditParams: null,
};

export const initialActionsState = {
  type: editorActionTypes.NOTE,
  isExpanded: false,
  isEditorChangeProgress: false,
  isNewEditor: true,
  dirtyFields: {},
  isEditorDirty: false,
  isEditorFullSize: false,
  data: initialNote,
  prompt: initialPrompt,
  lastSavedData: initialNote,
  activeIndex: -1,
  activeUploads: [],
  activeFilters: [],
};

export const initialState = {
  actionLine: initialActionsState,
  selectedFilters: {
    tab: JOURNEY_TABS.TICKET_DETAILS,
    filter: JOURNEY_FILTERS.SUMMARY,
  },
  ticketAuditLogs: {
    summary: { totalCount: 0, list: [], types: [] },
    fullJourney: { totalCount: 0, list: [], types: [] },
    activities: { totalCount: 0, list: [], types: [] },
    attachments: { totalCount: 0, list: [], types: [] },
    auditLogItems: { totalCount: 0, list: [], types: [] },
    notifications: { totalCount: 0, list: [], types: [] },
    messages: { totalCount: 0, list: [], types: [] },
  },
  activeFilters: [],
};

export const auditLogSlice = createSlice({
  name: 'auditLog',
  initialState,

  reducers: {
    updateAuditLogListByFilter: (state, action) => {
      const { filter, updatedList, totalCount } = action.payload;
      if (!state.ticketAuditLogs[filter]) {
        state.ticketAuditLogs[filter] = { list: [], totalCount: 0 };
      }
      if (updatedList) {
        state.ticketAuditLogs[filter].list = updatedList;
      }
      if (!Number.isNaN(Number(totalCount))) {
        state.ticketAuditLogs[filter].totalCount = totalCount;
      }
    },
    updateAuditLogEntry: (state, action) => {
      const { filter, updatedEntry } = action.payload;

      if (!state.ticketAuditLogs[filter]) {
        state.ticketAuditLogs[filter] = { list: [], totalCount: 0 };
      }

      const index = state.ticketAuditLogs[filter].list.findIndex((log) => log.id === updatedEntry.id);

      if (index !== -1) {
        state.ticketAuditLogs[filter].list[index] = updatedEntry;
      }
      state.ticketAuditLogs[filter].totalCount = state.ticketAuditLogs[filter].list.length;
    },

    removeAuditLogEntry: (state, action) => {
      const { filter, id } = action.payload;

      if (!state.ticketAuditLogs[filter]) return;

      const newList = state.ticketAuditLogs[filter].list.filter((log) => log.id !== id);

      state.ticketAuditLogs[filter].list = newList;
      state.ticketAuditLogs[filter].totalCount = newList.length;
    },

    updateAuditLogTypes: (state, payload) => {
      const { filter, filterTypes } = payload.payload;
      state.ticketAuditLogs[filter].types = filterTypes[filter];
    },
    updateSelectedFilter: (state, action) => {
      const {
        payload: { filter, selectedValue },
      } = action;
      state.selectedFilters[filter] = selectedValue;
    },
    addNewAuditLog: (state, payload) => {
      const { filter, newAuditLog } = payload.payload;
      const auditLogListCopy = state.ticketAuditLogs[filter].list;
      const updatedAuditLogList = [newAuditLog, ...auditLogListCopy];
      state.ticketAuditLogs[filter].list = updatedAuditLogList;
    },
    updateActionLine: (state, action) => {
      const { payload } = action;
      state.actionLine = { ...state.actionLine, ...{ ...payload } };
    },
    updateActionLineIsEditorDirty: (state, action) => {
      const { payload } = action;
      state.actionLine.dirtyFields = { ...state.actionLine.dirtyFields, ...{ ...payload } };

      state.actionLine.isEditorDirty = Object.values(state.actionLine.dirtyFields).some((isFieldDirty) => isFieldDirty);
    },
    updateEditorToDirty: (state) => {
      state.actionLine.isEditorDirty = true;
    },
    //TODO: May want to look into doing this in a DRYer way - using the attachment slice across the board
    addUpload: (state, action) => {
      state.actionLine.activeUploads.push(action.payload);
    },
    removeUpload: (state, action) => {
      state.actionLine.activeUploads = state.actionLine.activeUploads.filter(
        (upload) => upload.id !== action.payload.id,
      );
    },
    updateUpload: (state, action) => {
      const index = state.actionLine.activeUploads.findIndex((upload) => upload.id === action.payload.id);
      state.actionLine.activeUploads[index] = { ...state.actionLine.activeUploads[index], ...action.payload };
    },
    updateActionLineData: (state, action) => {
      const updateData = updateState(action.payload, state.actionLine.data);
      state.actionLine.data = { ...state.actionLine.data, ...updateData };
    },
    toggleActionLinePrompt: (state, action) => {
      const { payload } = action;
      state.actionLine.prompt = { ...state.actionLine.prompt, ...{ ...payload } };
    },
    clearActionLinePrompt: (state) => {
      state.actionLine.prompt = initialPrompt;
    },
    clearActionLineData: (state) => {
      state.actionLine.data = initialNote;
      state.actionLine.lastSavedData = initialNote;
    },
    clearActionLine: (state, action) => {
      const { payload } = action;
      state.actionLine = { ...initialActionsState, ...{ ...payload } };
    },

    addColumnFilter: (state, action) => {
      const overlapElmIndex = state.activeFilters.findIndex((filter) => filter.field === action.payload.field);
      state.activeFilters =
        overlapElmIndex > -1
          ? [...state.activeFilters.slice(0, overlapElmIndex), action.payload]
          : [...state.activeFilters, action.payload];
    },

    removeFilter: (state, action) => {
      const overlapElmIndex = state.activeFilters.findIndex((filter) => filter.field === action.payload.field);

      if (overlapElmIndex !== -1) {
        state.activeFilters.splice(overlapElmIndex, 1);
      }
    },

    removeAllFilters: (state) => {
      state.activeFilters = [];
    },

    resetAuditLog: () => initialState,
  },
});

export const {
  updateAuditLogTypes,
  updateSelectedFilter,
  updateAuditLogListByFilter,
  updateAuditLogEntry,
  removeAuditLogEntry,
  addNewAuditLog,
  updateActionLine,
  addUpload,
  removeUpload,
  updateUpload,
  updateActionLineIsEditorDirty,
  updateEditorToDirty,
  updateActionLineData,
  toggleActionLinePrompt,
  clearActionLinePrompt,
  clearActionLineData,
  clearActionLine,
  addColumnFilter,
  removeFilter,
  removeAllFilters,
  resetAuditLog,
} = auditLogSlice.actions;

export const selectAuditLog = (state) => state.auditLog.ticketAuditLogs;

export const selectActionLine = (state) => state.auditLog.actionLine;

export const selectJourneyFilters = (state) => state.auditLog.selectedFilters;

export const selectCurrentTab = createSelector([selectJourneyFilters], (selectedFilters) => selectedFilters.tab);
export const selectCurrentFilter = createSelector([selectJourneyFilters], (selectedFilters) => selectedFilters.filter);
export const selectCurrentTabAndFilter = createSelector([selectJourneyFilters], (selectedFilters) => ({
  tab: selectedFilters.tab,
  filter: selectedFilters.filter,
}));

export const selectUploads = (state) => state.auditLog.actionLine.activeUploads;

export const selectActiveFilters = (state) => state.auditLog.activeFilters;

const isUploadActive = (uploads) => uploads.some((upload) => upload.progress);

export const selectHasActiveUploads = createSelector([selectUploads], isUploadActive);

export default auditLogSlice.reducer;
export const auditLogSliceName = auditLogSlice.name;
