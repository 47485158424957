import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from '@tanstack/react-router';
import useTexts from 'features/resolutionPanel/useTexts';

import { StyledFooter } from './style';

export const NoteFooter = (props) => {
  const { shareWithReqUser: shareWithRequestUser, noteAuthor } = props;
  const router = useRouter();
  const queryClient = useQueryClient();
  const { sharedText, internalText, byRequestUserText } = useTexts();
  const srId = router.latestLocation.search.id;
  const { requestUser } = queryClient.getQueryData(['srId', srId]) || {};
  const isNoteAuthorReqUser = requestUser?.userName === noteAuthor;

  const isSharedWithRequestUser = () => {
    if (isNoteAuthorReqUser) return byRequestUserText;
    return shareWithRequestUser ? sharedText : internalText;
  };

  return (
    <StyledFooter
      shareWithRequestUser={shareWithRequestUser}
      isNoteAuthorReqUser={isNoteAuthorReqUser}
      data-testid="note-footer"
      data-cy={shareWithRequestUser ? 'shared-indication-footer' : 'unshared-indication-footer'}
    >
      <div className="log-type">
        <div className="log-type-indicator" />
        {isSharedWithRequestUser()}
      </div>
    </StyledFooter>
  );
};
