import { createContext } from 'react';

export const TemplateBuilderContext = createContext({
  templateId: null,
  isDragging: null,
  setDragging: () => {},
  header: null,
  sections: [],
  sectionsOrder: null,
  srType: null,
  handleStateUpdate: () => {},
  fieldsMap: new Map([]),
  dependantFields: null,
});
