import { useTheme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { ProgressWrapper } from 'containers/App/style';

export default function PendingComponentLoader() {
  const theme = useTheme();
  return (
    <ProgressWrapper>
      <CircularProgress style={{ color: theme.palette.generic.grey_600 }} />
    </ProgressWrapper>
  );
}
