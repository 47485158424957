import { memo, useEffect, useRef } from 'react';
import CustomScrollBar from 'common/components/customScroll';
import useHover from 'common/utils/hooks/useHover';
import { StyledSrPanel, StyledCollapsedPanelLabel } from './style';
import StyledToggleButton from '../toggleButton';
import Sections from './sections';
import FloatingTicketAttachment from './floatingTicketAttachment';

const SrPanel = (props) => {
  const {
    srPanelCollapsed,
    toggleSrPanelCollapse,
    isNavBarCollapsed,
    sr,
    handleSaveSR,
    template,
    hasEditPermissions,
    isFloatingTicketPanel,
    handleDirtyFields,
    srAttachmentsList = [],
    handleDeleteAttachment,
  } = props;
  const scrollRef = useRef(null);
  const [hoverRef, isHovered] = useHover();
  const srPanelClassName = srPanelCollapsed ? 'collapsed' : 'expanded';

  const { id: srId } = sr;

  const scrollToTop = () => {
    if (scrollRef && scrollRef.current) scrollRef.current.scrollTop(0);
  };

  useEffect(() => {
    scrollToTop();
  }, [srId]);

  useEffect(() => {
    if (srAttachmentsList.length > 0) {
      scrollRef.current.scrollToBottom();
    }
  }, [srAttachmentsList]);

  return (
    <StyledSrPanel ref={hoverRef} className={srPanelClassName} isFloatingTicketPanel={isFloatingTicketPanel}>
      {!isFloatingTicketPanel && (
        <StyledToggleButton
          leftChevron={!srPanelCollapsed}
          toggle={toggleSrPanelCollapse}
          isNavBarCollapsed={isNavBarCollapsed}
          className="sr-panel-btn"
          isDisplay={srPanelCollapsed || isHovered}
        />
      )}
      {!srPanelCollapsed ? (
        <CustomScrollBar
          ref={scrollRef}
          autoHide
          thumbSize={150}
          renderTrackVerticalStyle={{ paddingTop: isFloatingTicketPanel ? '0px' : '84px' }}
        >
          <Sections
            sr={sr}
            isFloatingTicketPanel={isFloatingTicketPanel}
            handleSaveSR={handleSaveSR}
            handleDirtyFields={handleDirtyFields}
            template={template}
            hasEditPermissions={hasEditPermissions}
          />
          {srAttachmentsList.length > 0 && (
            <FloatingTicketAttachment
              srAttachmentsList={srAttachmentsList} 
              handleDeleteAttachment={handleDeleteAttachment} 
            />
            )
          }
        </CustomScrollBar>
      ) : (
        <StyledCollapsedPanelLabel>Service Record Details</StyledCollapsedPanelLabel>
      )}
    </StyledSrPanel>
  );
};

export default memo(SrPanel);
