import { Divider } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { isNone } from 'common/utils/utils';
import { getCurrentTimeAtTimezone } from 'common/utils/dateTimeUtils';
import UserInfoCardHeader from './userInfoCardHeader';
import UserInfoCardDetails from './userInfoCardDetails';
import UserInfoCardTeamDetails from './userInfoCardTeamDetails';
import { StyledUserInfoCard } from './styles';

const componentId = 'user-info-card';

const UserInfoCard = ({ user = {}, team = {} }) => {
  const { calculatedUserName, firstName, lastName, profileImage, jobTitle, emailAddress, phone, timezone } = user;
  const { teamName, teamMembers } = team;
  const memberCount = teamMembers?.length || 0;

  // TODO: get display name from backend;
  const displayName = calculatedUserName || `${firstName} ${lastName}`;

  const time = timezone ? `${getCurrentTimeAtTimezone(timezone)}, ${timezone}` : getCurrentTimeAtTimezone(timezone);

  return (
    <StyledUserInfoCard id={componentId}>
      {!isEmpty(user) && (
        <>
          <UserInfoCardHeader
            profileImage={profileImage}
            displayName={displayName}
            email={emailAddress}
          />
          <UserInfoCardDetails jobTitle={jobTitle} phoneNumber={phone} localTime={time} />
        </>
      )}
      {!isNone(teamName) && (
        <>
          <Divider />
          <UserInfoCardTeamDetails teamName={teamName} memberCount={memberCount} isUserCard={!isEmpty(user)} />
        </>
      )}
    </StyledUserInfoCard>
  );
};
export default UserInfoCard;
