import { styled } from '@mui/material/styles';
import { Dialog, DialogTitle } from '@mui/material';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '.table-paper': {
    boxShadow: 'none',
  },

  '& .MuiDialog-paper': {
    borderRadius: '4px',
    boxShadow: `0px 2px 8px 0px ${theme.palette.background.boxShadow}`,
    maxWidth: '77vw',
  },

  '.MuiTableContainer-root': {
    borderRadius: '10px',
    border: `1px solid ${theme?.palette?.grey?.divider_grey_v1}`,
    boxShadow: 'none',
    maxHeight: '73vh',

    '&::-webkit-scrollbar': {
      width: '4px',
    },

    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      background: theme?.palette?.grey?.divider_grey_v1,
    },

    '&::-webkit-scrollbar-track': {
      marginTop: '2px',
      marginBottom: '2px',
    },
  },

  '.MuiDialogContent-root': {
    padding: '0px 20px 20px 20px',
  },

  '.MuiTable-root': {
    border: 'none',
  },

  '.MuiTableRow-head': {
    height: '44px',
  },

  '.MuiTableCell-root': {
    borderBottom: `1px solid ${theme.palette.labels.bt_black_light}`,
    maxWidth: '300px',
  },

  '.MuiTableCell-head': {
    padding: '12px 24px',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '20px',
    background: theme.palette.background.selected,
  },

  '.MuiTableCell-body': {
    padding: '16px 24px',
    color: theme.palette.text.txt_default,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
  },
}));

export const StyledCloseButton = styled('button')(() => ({
  position: 'absolute',
  top: '19px',
  right: '19px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0,
  background: 'transparent',
  border: 'none',
  svg: {
    cursor: 'pointer',
    width: '16px',
    height: '16px',
  },
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: theme?.fontSize?.medium,
  padding: '14px 34px',
}));
