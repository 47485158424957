import { useCallback, useMemo } from 'react';
import { SR_PANEL_CONSTANTS, STATUS_FIELD_ID } from 'features/srPanel/consts';
import { useFieldAttributes } from 'remote-state/ticketServiceHooks';

export function useStatusClass() {
  const fieldAttributesInput = useMemo(() => [{ fieldId: STATUS_FIELD_ID }], []);
  const statusList = useFieldAttributes(fieldAttributesInput)?.[0]?.data?.values;

  const getStatusFieldData = useCallback(
    (srId, srStatusValue) => {
      const selectedStatusId = srStatusValue?.[srId];
      const statusElement = statusList?.find((el) => el.id === selectedStatusId);
      const statusValueText = statusElement?.value;
      const valueClass = statusElement?.valueClass;
      const isClosedStatusClass = valueClass === SR_PANEL_CONSTANTS.CLOSED_CLASS;
      return { isClosedStatusClass, valueClass, statusValueText, selectedStatusId };
    },
    [statusList],
  );

  return [getStatusFieldData];
}
