import { memo } from 'react';

import { StyledLabel } from '../../solutionResolution/style';

const Label = ({ text, required = false }) => (
  <StyledLabel>
    {text}
    {required && <span className="required"> *</span>}
  </StyledLabel>
);

export default memo(Label);
