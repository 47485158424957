import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';

import { ReactComponent as SuccessIcon } from 'images/icons/SuccessIcon.svg';
import { ReactComponent as ErrorIcon } from 'images/icons/ErrorSign.svg';
import { ReactComponent as CloseIcon } from 'images/icons/icon_close_big.svg';

import {
  StyledToasterWrapper,
  StyledToaster,
  StyledCloseButton,
  StyledMessage,
  StyledDetailsText,
  StyledDetails,
  StyledCloseDetailsButton,
} from './style';
import useTexts from './useTexts';

const toasterIconTypes = {
  success: SuccessIcon,
  error: ErrorIcon,
  loading: () => <CircularProgress thickness={3} size={36} />,
};

const Toaster = ({ type, message, errorDetails, onClose }) => {
  const [showErrorDetails, setShowErrorDetails] = useState(false);
  const { errorDetailsText } = useTexts();
  const MessageIcon = toasterIconTypes[type];

  useEffect(() => {
    if (message && type === 'success') {
      const toasterTimeout = setTimeout(() => {
        onClose({ message: null, type: 'success' });
      }, 3000);
      return () => {
        clearTimeout(toasterTimeout);
      };
    }
  }, [message, onClose, type]);

  const handleClose = () => {
    onClose({ message: null, type: 'success' });
  };

  const handleShowErrorDetails = () => {
    setShowErrorDetails(true);
  };

  const handleCloseErrorDetails = () => {
    setShowErrorDetails(false);
  };

  return message ? (
    <StyledToasterWrapper>
      <StyledToaster>
        <MessageIcon />
        <StyledMessage>
          {message}
          {errorDetails && <StyledDetailsText onClick={handleShowErrorDetails}>{errorDetailsText}</StyledDetailsText>}
        </StyledMessage>
        {onClose && (
          <StyledCloseButton>
            <CloseIcon onClick={handleClose} />
          </StyledCloseButton>
        )}
      </StyledToaster>
      {showErrorDetails && (
        <StyledDetails>
          {errorDetails}
          <StyledCloseDetailsButton>
            <CloseIcon onClick={handleCloseErrorDetails} />
          </StyledCloseDetailsButton>
        </StyledDetails>
      )}
    </StyledToasterWrapper>
  ) : null;
};

Toaster.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  errorDetails: PropTypes.element,
  onClose: PropTypes.func,
};

Toaster.defaultProps = {
  type: 'success',
  message: null,
  errorDetails: null,
  onClose: null,
};

export default Toaster;
