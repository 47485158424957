import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useRouter } from '@tanstack/react-router';
import { QUERIES_KEYS } from 'constant';
import { APP_CONSTANTS } from 'constants/app';
import { getCookie } from 'common/utils/utils';
import { isLocalHost } from 'services/localhost';
import { getApplicationData } from 'services/applicationService';
import { useCallback } from 'react';
import { useResourceBundle } from './userServiceHooks';
import rbMapping from './rbMapping';

const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });

export const useApplicationData = (selectKey, options = {}) => {
  const sessionId = getCookie(APP_CONSTANTS.SESSION_ID);
  const getDataByKey = useCallback(
    (applicationData) => {
      switch (selectKey) {
        case QUERIES_KEYS.GENERAL_SETTINGS:
          return applicationData.generalSettings || {};
        case QUERIES_KEYS.RESOURCE_BUNDLE:
          return applicationData.resourceBundle?.resourceBundleMap || {};
        case QUERIES_KEYS.IS_EXTERNAL_MODE:
          return applicationData.isExternalMode ?? true;
        case QUERIES_KEYS.ADMINISTRATORS:
          return applicationData.administrators
            ? [...applicationData.administrators].sort((a, b) => collator.compare(a.userName, b.userName))
            : [];
        case QUERIES_KEYS.BULK_ACTIONS_LIMIT:
          return applicationData.bulkActionsLimit || 0;
        case QUERIES_KEYS.EDITION:
          return applicationData.edition;
        case QUERIES_KEYS.GROUPS:
          return applicationData.groups
            ? [...applicationData.groups].sort((a, b) => collator.compare(a.groupName, b.groupName))
            : [];
        case QUERIES_KEYS.MENU_ITEMS:
          if (!applicationData.menuItems) return {};
          return { menu: applicationData.menuItems.menu, userOptions: applicationData.menuItems.userOptions };
        case QUERIES_KEYS.IS_AI_ENABLED:
          return applicationData.isAiEnabled;
        case QUERIES_KEYS.ENABLED_SWITCH_UI:
          return applicationData.enabledSwitchUi;
        case QUERIES_KEYS.DEFAULT_UI:
          return applicationData.defaultUi;
        default:
          return applicationData;
      }
    },
    [selectKey],
  );
  const { enabled, forceCall, ...rest } = options;
  return useQuery({
    queryKey: [QUERIES_KEYS.APPLICATION_DATA],
    queryFn: getApplicationData,
    placeholderData: {},
    enabled: (isLocalHost() || !!sessionId || forceCall) && options.enabled !== false,
    select: (applicationData) => getDataByKey(applicationData),
    ...rest,
  });
};

window.rbTextTransformDone = false;
export function useRbTexts() {
  const router = useRouter();
  const queryClient = useQueryClient();
  const isLoginPage = router.latestLocation.pathname.includes('/spaces/login');
  const isForgotPasswordPage = router.latestLocation.pathname.includes('/spaces/forgot-password');
  const { data } = useApplicationData(undefined, { enabled: !isLoginPage });
  const { data: rbForLoginPage } = useResourceBundle({ enabled: isLoginPage });
  const texts = isLoginPage || isForgotPasswordPage ? rbForLoginPage : data?.resourceBundle?.resourceBundleMap;

  if (window.rbTextTransformDone) return texts;
  if (!texts || Object.keys(texts) === 0) {
    return {};
  }

  for (const key in texts) {
    if (rbMapping[key] && texts[rbMapping[key]]) {
      texts[key] = texts[rbMapping[key]];
    }
  }

  window.rbTextTransformDone = true;
  queryClient.setQueryData([QUERIES_KEYS.RESOURCE_BUNDLE], (prev) => {
    if (prev) {
      prev.resourceBundleMap = texts;
    }
    return prev;
  });

  queryClient.setQueryData([QUERIES_KEYS.APPLICATION_DATA], (prev) => {
    if (prev) {
      prev.resourceBundle.resourceBundleMap = texts;
    }
    return prev;
  });

  return texts;
}
