import { styled } from '@mui/material/styles';
import { Indicator } from '../Indicator';

export const StyledDependencyIndicator = styled(Indicator)(({ theme }) => ({
  backgroundColor: theme.palette.grey.scroll,
  color: theme.palette.icons.icons_grey_v3,
}));

export const DependencyWrapper = styled('div')({
  position: 'relative',
});
