import { useEffect, useRef, useCallback, memo, useMemo } from 'react';
import { createRoot } from 'react-dom/client';
import { useDispatch, useSelector } from 'react-redux';
import { AgGridReact } from '@ag-grid-community/react';
import { ReactComponent as ColumnDragIcon } from 'images/icons/gh_dragg.svg';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { useIsLastRowWithData } from 'remote-state/ticketServiceHooks';
import { useFeatureFlagQuery } from 'remote-state/featureFlagsHooks';
import { setSrDetailsForSummary } from 'store/ticketSummarySlice';
import { PERFORMANCE_MEASURE } from 'constants/performanceMeasure';
import { APP_CONSTANTS } from 'constants/index';
import usePerformanceMeasure from 'common/utils/hooks/usePerformanceMeasure';
import PropTypes from 'prop-types';
import { FEATURE_FLAGS_KEYS } from 'constants/featureFlags';
import { GRID, COLUMN_FILTER_TYPES } from './constants';
import CustomColumnHeader from './customColumnHeader';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import ActionBtnRenderer from './actionBtnRenderer';
import EndResultsMessage from './endResultsMessage';
import LoadingOverlayMessage from './loadingOverlayMessage';
import { setHoveredSR, setMultiSort, setPrefetchSrParams } from '../slice';
import DefaultCellRender from './defaultCellRender';
import { isOneStepToNextPage } from './helpers';
import useCacheColumnsWidth from './hooks/useCacheColumnsWidth';
import { selectDirection, selectDirectionChanged, setDirectionChanged } from '../../../store/globalSlice';

const resizeLastPinnedColumn = (params) => {
  if (!params.columnApi) {
    return
  }

  const allColumns = params.columnApi.getAllColumns();
  const actualColumns = allColumns.filter((e) => e.visible);
  const lastColumn = actualColumns[actualColumns.length - 1];
  if (lastColumn.pinned) {
    const gridWidth = params.api.gridOptionsWrapper?.eGridDiv?.offsetWidth;
    const columnsBeforePinned = actualColumns.slice(0, -1);
    const columnsBeforePinnedWidth = columnsBeforePinned.reduce(
      (total, col) => total + col.getActualWidth(),
      0,
    );
    // Stretching last column to fill empty space
    if (gridWidth > (columnsBeforePinnedWidth + lastColumn.minWidth)) {
      params.columnApi.setColumnWidth(lastColumn.colId, gridWidth - columnsBeforePinnedWidth);
    }
  } else {
    const pinnedColumns = actualColumns.filter(e => e.pinned)
    const lastPinnedColumn = actualColumns[pinnedColumns.length - 1]

    // Reset streched column width
    if (lastPinnedColumn.actualWidth !== lastPinnedColumn.minWidth) {
      params.columnApi.setColumnWidth(lastPinnedColumn.colId, lastPinnedColumn.minWidth);
    }
  }
}

const GridComponent = memo(
  ({
    scrollToId,
    gridRef,
    context,
    onGridReady,
    columnDefs,
    handleClosePopup,
    onRowSelected,
    onCellClicked,
    onRowClicked,
    onBodyScroll,
    handleColumnMoved,
    onFilterOpened,
    tabToNextCell,
  }) => {
    const dispatch = useDispatch();
    const firstMountRef = useRef(null);
    const previousParamsRef = useRef(null);
    const { data: isRTLEnabled } = useFeatureFlagQuery({
      flagKey: FEATURE_FLAGS_KEYS.RTL_ENABLED,
      defaultValue: false,
    });
    const direction = useSelector(selectDirection);
    const isRTL = direction === 'rtl' && isRTLEnabled;
    const { data: isLastRowWithData } = useIsLastRowWithData();
    const { performanceMeasureEventReport, getEventName } = usePerformanceMeasure();
    const { handleColumnResized } = useCacheColumnsWidth()
    const directionChanged = useSelector(selectDirectionChanged);
    const { data: isSROpenedFromQueue } = useFeatureFlagQuery({
      flagKey: FEATURE_FLAGS_KEYS.OPEN_SR_FROM_QUEUE,
      defaultValue: false,
    });

    const onPaginationChanged = useCallback(() => {
      if (gridRef.current?.api) {
        const checkPageParams = isOneStepToNextPage({
          currentPage: gridRef.current.api.paginationGetCurrentPage() + 1,
          totalPages: gridRef.current.api.paginationGetTotalPages(),
          pageSize: gridRef.current.api.paginationGetPageSize(),
          blockSize: GRID.BLOCK_SIZE,
        });

        if (
          previousParamsRef.current?.fetchNextPage !== checkPageParams.fetchNextPage &&
          checkPageParams.fetchNextPage
        ) {
          previousParamsRef.current = checkPageParams;
          dispatch(setPrefetchSrParams(checkPageParams));
        }
      }
    }, [gridRef, dispatch]);

    const defaultColDef = useMemo(
      () => ({
        filter: 'agTextColumnFilter',
        resizable: true,
        lockPinned: true,
        suppressSizeToFit: 'false',
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: [COLUMN_FILTER_TYPES.equals, COLUMN_FILTER_TYPES.contains],
          handleClosePopup,
        },
        cellRenderer: DefaultCellRender,
        cellStyle: isSROpenedFromQueue ? { cursor: 'pointer' } : {},
      }),
      [handleClosePopup, isSROpenedFromQueue],
    );


    const onGridReadyCallback = useCallback(
      (params) => {
        onGridReady(params);
        resizeLastPinnedColumn(params)
        const agPagingRowSummaryEl = document.querySelector('.ag-paging-row-summary-panel');
        if (agPagingRowSummaryEl) {
          const element = document.createElement('span');
          element.className = 'ag-paging-row-summary-panel-number';
          element.id = 'pagination_row_count';
          agPagingRowSummaryEl.appendChild(element);
        }
      },
      [onGridReady],
    );

    const getColumnDraggIcon = useCallback(() => {
      const domNode = document.createElement('div');
      const root = createRoot(domNode);
      root.render(<ColumnDragIcon />);
      return domNode;
    }, []);

    const handlePopupAlignment = useCallback((params) => {
      const closestDivWidth = params.eventSource.closest('div').offsetWidth;
      const ePopup = params.ePopup;

      ePopup.style.top = '64px';

      let oldLeftStr = ePopup.style.left;
      oldLeftStr = oldLeftStr.substring(0, oldLeftStr.indexOf('px'));

      const oldLeft = parseInt(oldLeftStr, 10);
      const newLeft = oldLeft - closestDivWidth;
      ePopup.style.left = `${newLeft}px`;
    }, []);

    const isFullWidthRow = useCallback(
      (params) => {
        if (params.rowNode?.rowIndex === GRID.MAX_ROWS_ALLOWED_NUM - 1) {
          if (isLastRowWithData) {
            return params.api.getInfiniteRowCount() > GRID.BLOCK_SIZE;
          }
          return true;
        }
      },
      [isLastRowWithData],
    );

    const onCellMouseOver = useCallback(
      (e) => {
        if (Array.isArray(e?.data)) {
          const srId = e?.data?.find((col) => col.field === 'id')?.value;
          const title = e?.data?.find((col) => col.field === 'title')?.value;
          dispatch(setSrDetailsForSummary({ srId, title }));
          dispatch(setHoveredSR(srId));
        }
      },
      [dispatch],
    );

    const handleKeyDown = useCallback(
      (event) => {
        if (event.metaKey || event.ctrlKey) {
          dispatch(setMultiSort(true));
        }
      },
      [dispatch],
    );

    const handleKeyUp = useCallback(
      (event) => {
        if (event.metaKey || event.ctrlKey) {
          dispatch(setMultiSort(false));
        }
      },
      [dispatch],
    );

    const getRowId = useCallback(({ data }) => {
      if (data.length > 0) {
        return data.find((sr) => sr?.field === 'id')?.value;
      }
    }, []);

    const onCellEditingStarted = useCallback(
      (e) => {
        onCellClicked(e?.node?.id);
      },
      [onCellClicked],
    );

    const onCellEditingStopped = useCallback(() => {
      onCellClicked(null);
    }, [onCellClicked]);

    useEffect(() => {
      document.addEventListener('keydown', handleKeyDown);
      document.addEventListener('keyup', handleKeyUp);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
        document.removeEventListener('keyup', handleKeyUp);
      };
    }, [handleKeyDown, handleKeyUp]);

    useEffect(() => {
      firstMountRef.current = true;
    }, []);

    const handleModelUpdated = useCallback((event) => {
      if (event.api.getInfiniteRowCount() === 0) {
        event.api.showNoRowsOverlay();
        return;
      }
      const centerColsTable = document.getElementsByClassName('ag-center-cols-container')?.[0];
      const leftColsQueue = document.getElementsByClassName('ag-pinned-left-cols-container')?.[0];
      // const viewPort = document.getElementsByClassName('ag-body-viewport')?.[0];
      const findee = document.getElementsByClassName('rowIDelem')?.[0];

      const loadedClassName = 'queueLoaded';

      const hideIsLoadedClassName = () => {
        if (
          centerColsTable &&
          centerColsTable?.classList.contains(loadedClassName) &&
          centerColsTable.classList?.length > 1
        ) {
          centerColsTable.classList.remove(centerColsTable.classList[centerColsTable.classList.length - 1]);
        }
      };

      if (findee?.textContent) {
        event.api.hideOverlay();
        if (centerColsTable && !centerColsTable?.classList.contains(loadedClassName)) {
          centerColsTable.classList.add(loadedClassName);
        }
      } else if (leftColsQueue && centerColsTable) {
        hideIsLoadedClassName();
      }
    }, []);

    const modules = useMemo(() => [InfiniteRowModelModule], []);

    const sortingOrder = useMemo(() => ['desc', 'asc', null], []);

    // const statusBar = useMemo( // @TODO - check if works after ag grid update and remove the hack with pagination_row_count
    //   () => ({
    //     statusPanels: [
    //       {
    //         statusPanel: PaginationStatusBar,
    //       },
    //     ],
    //   }),
    //   [],
    // );

    const rowClassRules = useMemo(
      () => ({
        // eslint-disable-next-line func-names
        'shadow-inset-center': function (params) {
          return params.node.id === parseInt(scrollToId, 10);
        },
      }),
      [scrollToId],
    );

    const components = useMemo(
      () => ({
        agColumnHeader: CustomColumnHeader,
        ActionBtnRenderer,
      }),
      [],
    );
    const normalizedColumnDefs = useMemo(() => (!Array.isArray(columnDefs) ? [] : columnDefs), [columnDefs]);

    const icons = useMemo(
      () => ({
        columnMoveMove: getColumnDraggIcon,
        columnDrag: getColumnDraggIcon,
        columnMovePin: getColumnDraggIcon,
        columnMoveRight: getColumnDraggIcon,
        columnMoveLeft: getColumnDraggIcon,
      }),
      [getColumnDraggIcon],
    );

    const rowCount = gridRef.current?.api?.getInfiniteRowCount();

    useEffect(() => {
      if (columnDefs?.length && gridRef?.current) {
        resizeLastPinnedColumn(gridRef?.current)
      }
    }, [columnDefs, gridRef])

    useEffect(() => {
      const agPinnedRowEl = document.getElementById('pagination_row_count');
      if (agPinnedRowEl) {
        agPinnedRowEl.innerHTML = rowCount || 0;
      }
    }, [rowCount]);

    const api = gridRef.current?.api;
    useEffect(() => {
      const currentLocation = api?.paginationGetCurrentPage();
      const lastLocation = Number(localStorage.getItem('currentPage'));
      if (!Number.isNaN(lastLocation) && currentLocation !== lastLocation) {
        api?.paginationGoToPage(lastLocation);
      }

      return () => {
        if (api) {
          const currentPage = api?.paginationGetCurrentPage() ?? 0;
          localStorage.setItem('currentPage', String(currentPage));
          api.cleanDownReferencesToAvoidMemoryLeakInCaseApplicationIsKeepingReferenceToDestroyedGrid();
        }
      };
    }, [api]);

    const handlePerformanceReport = () => {
      performance.mark(PERFORMANCE_MEASURE.QUEUE.DATA_POPULATION);
      const { eventName, startEventName, data = {} } = getEventName();
      if (eventName && startEventName) {
        const startTime = performance.getEntriesByName(startEventName)[0]?.startTime;
        const endTime = performance.getEntriesByName(PERFORMANCE_MEASURE.QUEUE.DATA_POPULATION)[0]?.startTime;
        const duration = endTime - startTime + GRID.RENDER_DATA_TIME;
        performanceMeasureEventReport(eventName, {
          tenantId: localStorage.getItem(APP_CONSTANTS.ACCOUNT_ID_LOCAL_KEY),
          duration,
          ...data,
        });
      } else {
        performance.clearMarks(PERFORMANCE_MEASURE.QUEUE.DATA_POPULATION);
      }
    };
    if(directionChanged) {
      setTimeout(()=>dispatch(setDirectionChanged(false)), 3000);
      return;
    }
    return (
      <>
        <AgGridReact
          enableRtl={isRTL}
          modules={modules}
          ref={gridRef}
          onGridReady={onGridReadyCallback}
          columnDefs={normalizedColumnDefs}
          defaultColDef={defaultColDef}
          // rowBuffer={0}
          rowSelection="multiple"
          blockLoadDebounceMillis={500}
          suppressRowClickSelection
          onRowSelected={onRowSelected}
          rowModelType="infinite"
          // cacheOverflowSize={0}
          // infiniteInitialRowCount={0}
          suppressAnimationFrame
          maxConcurrentDatas={10}
          sortingOrder={sortingOrder}
          suppressDragLeaveHidesColumns
          noRowsOverlayComponent={EndResultsMessage}
          context={context}
          onCellClicked={onCellClicked}
          onCellEditingStarted={onCellEditingStarted}
          onCellEditingStopped={onCellEditingStopped}
          onRowClicked={onRowClicked}
          getRowId={getRowId}
          getRowClass={(params) => {
            if (params.data === undefined) {
              return 'ag-empty-row';
            }
          }}
          onBodyScroll={onBodyScroll}
          onPaginationChanged={onPaginationChanged}
          onColumnMoved={handleColumnMoved}
          loadingOverlayComponent={LoadingOverlayMessage}
          suppressPropertyNamesCheck // TODO: remove once cellRenderers are implemented with params
          // suppressColumnVirtualisation
          // alwaysMultiSort
          multiSortKey="ctrl"
          rowClassRules={rowClassRules}
          components={components}
          // statusBar={statusBar}
          reactiveCustomComponents
          headerHeight={44}
          onFilterOpened={onFilterOpened}
          icons={icons}
          rowHeight={44}
          postProcessPopup={handlePopupAlignment}
          isFullWidthRow={isFullWidthRow}
          fullWidthCellRenderer={EndResultsMessage}
          tabToNextCell={tabToNextCell}
          navigateToNextCell={tabToNextCell}
          onCellMouseOver={onCellMouseOver}
          onModelUpdated={handleModelUpdated}
          onFirstDataRendered={handlePerformanceReport}
          onSortChanged={handlePerformanceReport}
          onFilterChanged={handlePerformanceReport}
          cacheBlockSize={GRID.BLOCK_SIZE}
          pagination
          paginationAutoPageSize
          onColumnResized={handleColumnResized}
          // paginationPageSize={50}
          // suppressPaginationPanel
        />
      </>
    );
  },
);

GridComponent.propTypes = {
  columnDefs: PropTypes.array.isRequired,
};
export default memo(GridComponent);
