import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    modal: {
      title: texts?.['spaces.reloadPageModal.title'] || 'New version update required',
      description: texts?.['spaces.reloadPageModal.description'] || 'We\'ll need to reload this page to update your environment',
      btnOkText: texts?.['spaces.reloadPageModal.OkText'] || 'Refresh',
    }
  };

  return componentTexts;
}
