import HoursList from './hoursList';
import MinutesList from './minutesList';
import { StyledTimeContainer, StyledHoursConatiner, StyledMinutesConteiner, StyledTimeFormat } from './style';
import TimeFormatList from './timeFormatList';

export default function CustomTimePicker(props) {
  const { hour, minute, onSelectHour, onSelectMinute, is12HourTimeFormat, currentTimeFormat, onSelectNewTimeFormat } =
    props;

  const hourSelectHandler = (hour) => {
    onSelectHour(hour);
  };

  const minuteSelectHandler = (minutes) => {
    onSelectMinute(minutes);
  };

  return (
    <StyledTimeContainer data-testid="custom-time-picker">
      <StyledHoursConatiner>
        <HoursList is12HourTimeFormat={is12HourTimeFormat} inputHour={hour} onSelectHour={hourSelectHandler} />
      </StyledHoursConatiner>
      <StyledMinutesConteiner>
        <MinutesList inputMinute={minute} onSelectMinute={minuteSelectHandler} />
      </StyledMinutesConteiner>
      {is12HourTimeFormat && (
        <StyledTimeFormat>
          <TimeFormatList
            currentTimeFormat={currentTimeFormat}
            onSelectNewTimeFormat={onSelectNewTimeFormat}
            hour={hour}
          />
        </StyledTimeFormat>
      )}
    </StyledTimeContainer>
  );
}
