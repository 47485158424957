import { useCallback, useMemo, useRef, useState } from 'react';
import moment from 'moment';

import { useSelector } from 'react-redux';
import { selectActiveUser } from 'store/userSlice';
import useResolutionPanelTexts from 'features/resolutionPanel/useTexts';
import { ReactComponent as TrashIcon } from 'images/icons/trash.svg';
import { useUserDetailsByName } from 'remote-state/ticketServiceHooks';

import { StyledActivityLog } from '../style';
import useTexts from '../useTexts';
import { useEditorActions } from '../../../hooks';
import ActionLinePrompt from '../../prompts/actionLinePrompt';
import { promptTypes } from '../../../constants';
import TruncatedTextButton from '../../../logDescription/editor/truncatedTextButton';
import { useDisplayTextTruncation } from '../../../logDescription/fieldChange/hooks/useDisplayTextTruncation';
import { addLeadingZero, convertMillisecondsToHoursAndMinutes } from '../utils';

const ActivityLog = ({ auditLog, editorType, toggleAuditLogsProperty }) => {
  const { logInformation, id: auditLogId, logType } = auditLog;

  const texts = useTexts();
  const resolutionPanelTexts = useResolutionPanelTexts();
  const [isShowPrompt, setIsShowPrompt] = useState(false);
  const descriptionRef = useRef(null);
  const { handleActionLineOperation, deleteEditorItem } = useEditorActions();
  const { data: userDetails } = useUserDetailsByName(logInformation?.userName);
  const currentUser = useSelector(selectActiveUser);
  const { showTruncationButton } = useDisplayTextTruncation({
    divRef: descriptionRef,
    logType,
    textTruncateEnabled: true,
  });

  const startTime = moment(logInformation?.fromTime).format('MM/DD/YYYY hh:mm a');
  const endTime = moment(logInformation?.toTime).format('MM/DD/YYYY hh:mm a');
  const totalTime = useMemo(
    () => convertMillisecondsToHoursAndMinutes(logInformation?.totalTime),
    [logInformation?.totalTime],
  );
  const userName =
    userDetails?.userName === currentUser?.username
      ? `${userDetails?.calculatedUserName} (${resolutionPanelTexts.meText})`
      : userDetails?.calculatedUserName;

  const handleDelete = useCallback(() => {
    handleActionLineOperation({
      type: editorType,
      actionName: 'delete',
      logInformation,
      auditLogId,
      handleClickDelete: () => setIsShowPrompt(true),
    });
  }, [editorType, auditLogId, logInformation, handleActionLineOperation]);

  const handleClosePrompt = useCallback(
    ({ isOkBtnClicked }) => {
      setIsShowPrompt(false);

      if (isOkBtnClicked) {
        deleteEditorItem({ editorItemId: logInformation?.id, auditLogId, editorType });
      }
    },
    [logInformation?.id, auditLogId, editorType, deleteEditorItem],
  );

  const toggleTruncationButton = () => {
    toggleAuditLogsProperty({ auditLogIds: [auditLogId], property: 'isRichTextTruncated' });
  };

  return (
    <StyledActivityLog>
      {isShowPrompt && (
        <ActionLinePrompt
          promptType={promptTypes.CANCEL}
          onOkClick={() => handleClosePrompt({ isOkBtnClicked: true })}
          onCancelClick={() => handleClosePrompt({ isOkBtnClicked: false })}
          message={{
            title: resolutionPanelTexts.modal[editorType].delete.title,
            description: resolutionPanelTexts.modal[editorType].delete.description,
          }}
          btnTexts={{ okBtn: resolutionPanelTexts.deleteText }}
        />
      )}
      <div className="sections-wrapper">
        <a
          data-testid="delete-activity"
          className="delete-activity"
          role="button"
          tabIndex="0"
          onKeyDown={handleDelete}
          onClick={handleDelete}
        >
          <TrashIcon />
        </a>
        <section>
          <span>{texts.responsibleUser}:</span>
          <span>{userName}</span>
        </section>
        <section>
          <span>{texts.startTime}:</span>
          <span>{startTime}</span>
        </section>
        <section>
          <span>{texts.endTime}:</span>
          <span>{endTime}</span>
        </section>
        <section>
          <span>{texts.total}:</span>
          <span>
            {totalTime.hours}:{addLeadingZero(totalTime.minutes)}
          </span>
        </section>
        <section ref={descriptionRef}>
          <span>{texts.description}:</span>
          <span className={auditLog.isRichTextTruncated && 'truncated'}>{logInformation?.description || ''}</span>
        </section>
      </div>
      {showTruncationButton && (
        <div className="truncate-btn-wrapper">
          <TruncatedTextButton isRichTextTruncated={auditLog.isRichTextTruncated} onClick={toggleTruncationButton} />
        </div>
      )}
    </StyledActivityLog>
  );
};

export default ActivityLog;
