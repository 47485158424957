import { convertValues } from 'features/srPanel/templateFieldsGrid/fieldSearchableDropdown/values-convert';

export const normalize = (values, keyString, hasHierarchy) => {
  if (values === null || values === undefined) return {};
  let newValues = [];
  if (!Array.isArray(values)) {
    if (hasHierarchy) {
      for (const key in values) {
        newValues = newValues.concat(values[key]);
      }
    } else {
      // the queue page is sending an object instead of an array, so we have to convert that object into an array that we can work with
      newValues = Object.values(values);
    }
  } else {
    newValues = values;
  }
  return newValues.reduce(
    (acc, option) => ({
      ...acc,
      [option[keyString]]: {
        ...option,
      },
    }),
    {},
  );
};

export const convertOptions = (options, theme, isCategory, hasHierarchy) => {
  if (hasHierarchy) {
    const convertedOptions = {};
    for (const key in options) {
      convertedOptions[key] = convertValues(options[key], theme, isCategory);
    }
    return convertedOptions;
  }
  return convertValues(options, theme, isCategory);
};

export const getFilteredOptions = (options, search, keyString, captionString, sortBy, sortType) => {
  if (options?.length > 0) {
    let filteredOptions = [...options];
    if (search) {
      filteredOptions = filteredOptions.filter((option) =>
        option[captionString]?.toLowerCase().includes(search.toLowerCase()),
      );
    }
    if (sortBy) {
      filteredOptions = filteredOptions.sort((a, b) =>
        sortType === 'desc' ? b[sortBy]?.localeCompare(a[sortBy]) : a[sortBy]?.localeCompare(b[sortBy]),
      );
    }
    return filteredOptions.map((item) => item[keyString]);
  }
  return [];
};

export const getOptionClass = (optionKey, options, keyString) =>
  Object.keys(options).find((group) => options[group].find((item) => item[keyString] === optionKey));
