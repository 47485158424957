import { useDispatch, useSelector } from 'react-redux';
import { selectEscalationPrompt, updateEscalationPrompt } from 'store/srSlice';
import { useUpdateSR } from 'remote-state/ticketServiceHooks';
import useTexts from './useTexts';
import MessagePrompt from '../messagePrompt';

export default function EscalationPrompt() {
  const dispatch = useDispatch();
  const { show: showEscalationPrompt, srId } = useSelector(selectEscalationPrompt);
  const { checkAndUpdateSr } = useUpdateSR({ refreshQueue: true });
  const { title, btnOkText, btnCancelText, message } = useTexts();

  const handleCloseEscalationPrompt = () => {
    dispatch(updateEscalationPrompt({ show: false, srId: null }));
  };

  const handleOkClickEscalationPrompt = () => {
    const updatedSR = { id: srId, requestParams: { escalation: 0 } };
    checkAndUpdateSr(updatedSR);
    handleCloseEscalationPrompt();
  };

  return (
    <>
      {showEscalationPrompt && (
        <MessagePrompt
          open
          showCancelBtn
          title={title}
          btnOkText={btnOkText}
          btnCancelText={btnCancelText}
          onOkClick={handleOkClickEscalationPrompt}
          onClose={handleCloseEscalationPrompt}
        >
          {message}
        </MessagePrompt>
      )}
    </>
  );
}
