import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledToasterMessage = {
  top: '-44vh',
};

export const StyledTicketPanel = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.pages_background,
  overflowY: 'auto',

  '& .middle-header': {
    marginTop: '2px',
  },

  '& .assignee-tooltip-wrapper': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export const StyledCircularProgress = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  '& .MuiCircularProgress-root': {
    color: theme.palette.generic.grey_600,
  },
}));
