import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const templateSlice = createSlice({
  name: 'template',
  initialState,
});

export default templateSlice.reducer;
export const templateBuilderSliceName = templateSlice.name;
