import { useState } from 'react';
import PropTypes from 'prop-types';

import MessagePrompt from 'common/components/messagePrompt';
import { ReactComponent as TrashButton } from 'images/icons/bulkActions/trash.svg';
import { useDeleteTicketsByIdsQuery } from 'remote-state/bulkActionsHooks';

import useTexts from '../useTexts';
import { StyledActionIcon, StyledActionTitle, StyledAction } from '../style';
import { actionTypes } from '../constants';

export const DeleteAction = ({
  selectedRows,
  onLoading,
  onActionClicked,
  onUncheckSuccessTickets,
  onSetIsCacheRefreshNeeded,
  onChangeToasterMessage,
  dataset,
}) => {
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const { deleteTickets } = useDeleteTicketsByIdsQuery();
  const { deleteTicketText, deleteTicketWarningMessageText, deleteTicketCancelText, deleteTicketMessageText } =
    useTexts(selectedRows.length);

  const handleDeleteClicked = () => {
    setShowDeletePrompt(true);
  };

  const handleCloseDeletePrompt = () => {
    setShowDeletePrompt(false);
  };

  const handleDeleteSelectedTickets = async () => {
    const ticketIds = selectedRows.map(({ id }) => id);
    const selectedTicketsCount = selectedRows.length;
    let messageType = null;
    let messageText = null;

    onLoading(true);
    handleCloseDeletePrompt();

    try {
      const { numOfSuccessTickets, numOfFailedTickets, tickets } = await deleteTickets(ticketIds);
      onSetIsCacheRefreshNeeded(true);

      messageType = numOfFailedTickets > 0 ? 'error' : 'success';
      messageText = deleteTicketMessageText({
        selectedTicketsCount,
        failureCount: numOfFailedTickets,
        successCount: numOfSuccessTickets,
      });

      if (numOfSuccessTickets > 0) {
        onUncheckSuccessTickets(tickets);
      }
    } catch (error) {
      messageType = 'error';
      messageText = deleteTicketMessageText({
        selectedTicketsCount,
        failureCount: selectedTicketsCount,
        successCount: 0,
      });
    }

    onChangeToasterMessage({
      message: messageText,
      type: messageType,
    });

    onLoading(false);
  };

  return (
    <>
      <StyledAction
        isCursorPointer
        onClick={() => onActionClicked({ actionType: actionTypes.DELETE, onClick: handleDeleteClicked })}
        {...dataset}
      >
        <StyledActionIcon>
          <TrashButton />
        </StyledActionIcon>
        <StyledActionTitle>{deleteTicketText}</StyledActionTitle>
      </StyledAction>
      {showDeletePrompt && (
        <MessagePrompt
          open={showDeletePrompt}
          title={deleteTicketWarningMessageText}
          btnOkText={deleteTicketText}
          btnCancelText={deleteTicketCancelText}
          onOkClick={handleDeleteSelectedTickets}
          showCancelBtn
          onClose={handleCloseDeletePrompt}
        />
      )}
    </>
  );
};

DeleteAction.propTypes = {
  onLoading: PropTypes.func,
  onActionClicked: PropTypes.func,
  onUncheckSuccessTickets: PropTypes.func,
  onSetIsCacheRefreshNeeded: PropTypes.func,
  onChangeToasterMessage: PropTypes.func,
};

DeleteAction.defaultProps = {
  onLoading: () => {},
  onActionClicked: () => {},
  onUncheckSuccessTickets: () => {},
  onSetIsCacheRefreshNeeded: () => {},
  onChangeToasterMessage: () => {},
};

export default DeleteAction;
