import { MoreAttachmentsWrapper } from 'features/attachments/attachmentsList/MoreAttachmentsWrapper';
import { StyledFloatingTicketAttachment } from './style';

export default function FloatingTicketAttachment(props) {
  const { attachmentWrapperRef, srAttachmentsList, handleDeleteAttachment } = props;
  const FLOATING_TICKET_DISPLAY_ATTACHMENTS_LIMIT = 5;
  return (
    <StyledFloatingTicketAttachment ref={attachmentWrapperRef} data-testid="new-sr-attachment-list">
      <MoreAttachmentsWrapper
        isActionItem={false}
        attachments={srAttachmentsList}
        handleDisplayPreview={() => null}
        handleDeleteAttachment={(attachmentId) => handleDeleteAttachment(attachmentId)}
        displayAsThumbnail
        isHideDetails
        displayAttachmentsLImit={FLOATING_TICKET_DISPLAY_ATTACHMENTS_LIMIT}
      />
    </StyledFloatingTicketAttachment>
  );
}
