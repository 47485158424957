import { useErrorBoundary } from 'use-error-boundary';
import MessagePrompt from 'common/components/messagePrompt';
import { isLocalHost } from 'services/localhost';

import useTexts from './hooks/useTexts';

export function ErrorBoundary({ children }) {
  const { title, btnOkText, mainText } = useTexts();

  const {
    ErrorBoundary: Boundary,
    reset,
    didCatch,
    error,
  } = useErrorBoundary({
    onDidCatch: (error) => {
      // TODO add error logger for client-side errors?
      if (isLocalHost) {
        console.error('Error caught in error boundary', error);
        throw error;
      }
    },
  });

  return !didCatch ? (
    <Boundary>{children}</Boundary>
  ) : (
    <MessagePrompt
      open={!isLocalHost && error}
      title={title || 'Something went wrong'}
      btnOkText={btnOkText || 'Refresh'}
      onOkClick={() => window.location.reload()}
      onClose={() => reset()}
    >
      {mainText ||
        'Try refreshing the page or try again later. If the problem persits, contact the system administrator.'}
    </MessagePrompt>
  );
}
