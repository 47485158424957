import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    selectCategoryText: texts?.['spaces.header.category.selectCategory'],
    selectSubCategoryText: texts?.['spaces.header.category.selectSubCategory'],
    select3rdLevelCategoryText: texts?.['spaces.header.category.select3rdLevelCategory'],
    categoryText: texts?.['spaces.header.category.categoryText'],
    subCategoryText: texts?.['spaces.header.category.subCategoryText'],
    thirdLevelCategoryText: texts?.['spaces.header.category.thirdLevelCategoryText'],
    categoriesText: texts?.['spaces.menu.settings.customization.categories'],
  };

  return componentTexts;
}
