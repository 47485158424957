import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledTemplateFieldsPanel = styled('div')(({ theme }) => ({
  height: '100%',
  '& .fields-title': {
    margin: '20px 24px 8px 24px',
    height: '24px',
    color: theme?.palette.text.txt_default,
    fontSize: theme.fontSize.medium,
    fontWeight: '400',
  },
  '.SearchField': {
    width: 'auto',
    margin: '0 24px 16px 24px',
    ' > div': {
      width: '100%',
    },
  },
  '.fields-list': {
    padding: '0 12px 0 24px',
  },
  li: {
    padding: '4px 0',
  },
  '[data-rbd-placeholder-context-id]': {
    display: 'none !important',
  },
}));

export const StyledFieldsWrapper = styled('div')(() => ({
  position: 'relative',
  width: '100%',
  maxHeight: 'calc(100% - 105px)',
  height: '100%',
}));

export const StyledFieldsList = styled('div')(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
  marginRight: '7px',
  ...theme.customScrollbar,
}));

export const StyledLoading = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '80%',
  width: '100%',
}));
