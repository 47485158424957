import DateEqualsPicker from 'common/components/dateEqualsPicker';
import DateRangePicker from 'common/components/dateRangePicker';
import { useState } from 'react';
import { CONSTANTS } from '../constants';
import { useSelectContext } from '../context';
import { Divider } from '../DropdownSelectGroup/DropdownSelectGroup.styles';
import { useTexts } from '../hooks';
import { CustomDateButton, CustomDateButtonTitle, CustomDateContainer, StyledArrowIcon } from './CustomDate.styles';
import { CustomDateSelect } from './CustomDateSelect';

export const CustomDate = ({ isCustomSelectOpened, handleChange, fieldName }) => {
  const { custom } = useTexts();
  const [filterType, setFilterType] = useState(1);
  const { handleChange: handleCustomDateChange } = useSelectContext();

  const handleSelectChange = (value) => {
    setFilterType(value);
  };

  return (
    <CustomDateContainer>
      {!isCustomSelectOpened && <Divider isDateType sx={{ margin: '2px 0' }} />}
      <CustomDateButton onClick={handleChange} isCustomSelectOpened={isCustomSelectOpened}>
        <CustomDateButtonTitle>{custom}</CustomDateButtonTitle>
        <StyledArrowIcon isCustomSelectOpened={isCustomSelectOpened} />
      </CustomDateButton>
      {isCustomSelectOpened && <CustomDateSelect handleSelectChange={handleSelectChange} filterType={filterType} />}
      {isCustomSelectOpened && filterType === CONSTANTS.FILTER_TYPE_DATE && (
        <DateEqualsPicker filterType={filterType} field={fieldName} handleEqualsDateFilter={handleCustomDateChange} />
      )}
      {isCustomSelectOpened && filterType === CONSTANTS.FILTER_TYPE_DATE_RANGE && (
        <DateRangePicker filterType={filterType} field={fieldName} handleRangeDateFilter={handleCustomDateChange} />
      )}
    </CustomDateContainer>
  );
};
