import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import './index.css';

import ExpandMessage from 'images/icons/expandMessage.png';

export const getRichTextValueByColumnName = (richTextFields, columnName) => {
  let parsedRichTextFields;
  try {
    parsedRichTextFields = JSON.parse(richTextFields);
  } catch (err) {
    parsedRichTextFields = richTextFields;
  }
  return parsedRichTextFields?.find((field) => field.columnName === columnName) || ''; // TODO Alex K should check by fieldId, needs API update
};

const convertToMessageDate = (timestamp, timezone) =>
  moment(timestamp)
    .tz(timezone || Intl.DateTimeFormat().resolvedOptions().timeZone)
    .format('llll');

export const checkFileType = (file) => file.type.includes('image/');

export const handlePasteFile = (img) => {
  const base64Data = img.src.split(',')[1];
  const type = img.src.match(/data:([^;]+);base64,/)[1];
  img.remove();
  const extension = type.split('/')[1];
  const filename = `${uuidv4()}.${extension}`;

  const binaryData = atob(base64Data);

  const buffer = new Uint8Array(binaryData.length);
  for (let i = 0; i < binaryData.length; i++) {
    buffer[i] = binaryData.charCodeAt(i);
  }
  const blob = new Blob([buffer], { type });
  const syntheticEvent = {
    target: {
      files: [new File([blob], filename, { type })],
    },
  };
  return syntheticEvent;
};
export const forwardMessageBody = (sender, messageText, timestamp, timezone) =>
  `<div>
    <p></p>
    <div class="toggle-content-display">
      <img class="toggle-img" src=${ExpandMessage} alt="expand-button" />
    </div>
      <div class="forwarded-message-wrapper">
        <span class="fowarded-message-metadata">
          On ${convertToMessageDate(timestamp, timezone)} ${sender.displayName}
          ${sender.emailAddress && `<span class="sender-email">&lt;${sender.emailAddress}&gt;&nbsp;</span>`} wrote:
        </span>
        <div class="forwarded-message-content">
            <div class="vertical-line"></div>
            <div>${messageText}</div>
        </div>
      </div>
  </div>`;
