import { useRbTexts } from 'remote-state/applicationHooks';
import { SentimentEnum } from './Sentiment/SentimentEnum';

export const useTexts = () => {
  const texts = useRbTexts();

  const summaryNotAvailableCheckBackSoon = texts?.['spaces.generativeAi.summaryNotAvailableCheckBackSoon'];
  const loading = texts?.['spaces.generativeAi.loading'];
  const sentimentLabel = texts?.['spaces.generativeAi.sentiment.label'];
  const sentiments = {
    [SentimentEnum.VERY_NEGATIVE]: texts?.['spaces.generativeAi.sentiment.veryNegative'],
    [SentimentEnum.NEGATIVE]: texts?.['spaces.generativeAi.sentiment.negative'],
    [SentimentEnum.NEUTRAL]: texts?.['spaces.generativeAi.sentiment.neutral'],
    [SentimentEnum.POSITIVE]: texts?.['spaces.generativeAi.sentiment.positive'],
  };

  return {
    summaryNotAvailableCheckBackSoon,
    loading,
    sentiments,
    sentimentLabel,
  };
};
