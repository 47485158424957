import { styled } from '@mui/material/styles';

export const StyledCustomerLogoWrapper = styled('div')`
  padding: 24px 20px;
  height: 107px;
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
`;

export const StyledCustomerLogoFooterWrapper = styled('div')`
  padding: 24px 18px;
  display: flex;
  justify-content: left;
  align-items: center;
`;

export const StyledCustomerLogo = styled('img')`
  padding: 0;
  max-height: 50px;
  max-width: 150px;
`;

export default StyledCustomerLogo;
