import { useCallback } from 'react';
import { datadogRum } from '@datadog/browser-rum';

import { PERFORMANCE_MEASURE } from 'constants/performanceMeasure';
import { getMarkAndData } from '../performanceManager';
import { getEnvironment } from '../utils';

export default function usePerformanceMeasure() {
  const performanceMeasureEventReport = useCallback((eventName, data, disabled = false) => {
    if (disabled) return;
    const { duration } = data;
    const environment = getEnvironment();
    if (!Number.isNaN(duration) && Number.isFinite(duration) && duration > 0) {
      datadogRum?.addAction(eventName, {...data, environment})
    }
    performance.clearMarks();
  }, []);

  const getEventName = () => {
    if (performance.getEntriesByName(PERFORMANCE_MEASURE.QUEUE.VIEW_CHANGE).length) {
      const data = getMarkAndData(PERFORMANCE_MEASURE.QUEUE.VIEW_CHANGE);
      return {
        eventName: PERFORMANCE_MEASURE.EVENTS.QUEUE_VIEW_CHANGE,
        startEventName: PERFORMANCE_MEASURE.QUEUE.VIEW_CHANGE,
        data,
      };
    }
    if (performance.getEntriesByName(PERFORMANCE_MEASURE.QUEUE.SORT_CHANGE).length) {
      const data = getMarkAndData(PERFORMANCE_MEASURE.QUEUE.SORT_CHANGE);
      return {
        eventName: PERFORMANCE_MEASURE.EVENTS.QUEUE_SORT_CHANGE,
        startEventName: PERFORMANCE_MEASURE.QUEUE.SORT_CHANGE,
        data,
      };
    }
    if (performance.getEntriesByName(PERFORMANCE_MEASURE.QUEUE.FILTER_CHANGE).length) {
      const data = getMarkAndData(PERFORMANCE_MEASURE.QUEUE.FILTER_CHANGE);
      return {
        eventName: PERFORMANCE_MEASURE.EVENTS.QUEUE_FILTER_CHANGE,
        startEventName: PERFORMANCE_MEASURE.QUEUE.FILTER_CHANGE,
        data,
      };
    }
    if (performance.getEntriesByName(PERFORMANCE_MEASURE.QUEUE.SR_TYPE_CHANGE).length) {
      const data = getMarkAndData(PERFORMANCE_MEASURE.QUEUE.SR_TYPE_CHANGE);
      return {
        eventName: PERFORMANCE_MEASURE.EVENTS.QUEUE_SR_TYPE_CHANGE,
        startEventName: PERFORMANCE_MEASURE.QUEUE.SR_TYPE_CHANGE,
        data,
      };
    }
    if (performance.getEntriesByName(PERFORMANCE_MEASURE.TICKET.BACK_BTN_CLICK).length)
      return {
        eventName: PERFORMANCE_MEASURE.EVENTS.NAVIGATE_FROM_TICKET_TO_QUEUE,
        startEventName: PERFORMANCE_MEASURE.TICKET.BACK_BTN_CLICK,
      };
    if (performance.getEntriesByName(PERFORMANCE_MEASURE.QUEUE.MOUNT).length) {
      return {
        eventName: PERFORMANCE_MEASURE.EVENTS.QUEUE_LOADING_TIME,
        startEventName: PERFORMANCE_MEASURE.QUEUE.MOUNT,
      };
    }
    return { eventName: '', startEventName: '' };
  };

  return { performanceMeasureEventReport, getEventName };
}
