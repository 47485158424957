import useTexts from 'common/components/dueDate/useTexts';
import { getDays, getHours, getMinutes } from '../dateTimeUtils';

export function useFormatMilliseconds(milliseconds) {
  const { day: dayText, hour: hourText, minute: minuteText } = useTexts();

  let hours;
  let minutes;
  let showDays;
  let showHours;

  if (!milliseconds) return;
  const diffInSeconds = Math.trunc(milliseconds / 1000);
  const days = getDays(diffInSeconds);
  if (!days && diffInSeconds < 0) {
    hours = getHours(diffInSeconds);
  } else {
    hours = Math.abs(getHours(diffInSeconds));
  }
  if (!days && !hours && diffInSeconds < 0) {
    minutes = getMinutes(diffInSeconds);
  } else {
    minutes = Math.abs(getMinutes(diffInSeconds));
  }
  if (days === 1 && !hours) {
    showDays = '';
    showHours = `24${hourText} `;
  } else if (days === -1 && !hours) {
    showDays = '';
    showHours = `-24${hourText} `;
  } else {
    showDays = days ? `${days}${dayText} ` : '';
    showHours = hours ? `${hours}${hourText} ` : '';
  }
  const showMinutes = `${minutes}${minuteText}`;
  return `${showDays}${showHours}${showMinutes}`;
}
