import { useRef, useEffect, useState, useCallback } from 'react';
import { isFunction, isObject } from 'lodash-es';
import { setStyleProperty } from '../utils';

/*
The useDelayHover hook enables some action after 'x' milliseconds of hovering.
The hook receives 2 variables: delay, hoverEffect
delay - the number of milliseconds for the delay
hoverEffect - can be an object or a callback function
If hoverEffect is an object it will look like this: { propertyName, value, priority }
The hook returns a ref and a value
*/

const useDelayHover = (ref, delay, hoverEffect) => {
  const [isHoverActive, setIsHoverActive] = useState(false);

  const handleHoverEffectAsObject = useCallback(
    (isHover, hoverObj) => {
      const beforeHoverStyle = ref?.current?.style?.getPropertyValue(hoverObj.propertyName);
      if (isHover) {
        setStyleProperty(ref, hoverObj.propertyName, hoverObj.value, hoverObj.priority);
      } else {
        setStyleProperty(ref, beforeHoverStyle.propertyName, beforeHoverStyle.value, beforeHoverStyle.priority);
      }
    },
    [ref],
  );

  const handleHoverEffect = useCallback(
    (isHover) => {
      if (isObject(hoverEffect)) {
        handleHoverEffectAsObject(isHover, hoverEffect);
      } else if (isFunction(hoverEffect)) {
        hoverEffect();
      }
    },
    [handleHoverEffectAsObject, hoverEffect],
  );

  const handleMouseOver = useCallback(() => {
    setIsHoverActive(true);
    handleHoverEffect(true);
  }, [handleHoverEffect]);

  const handleMouseOut = useCallback(() => {
    setIsHoverActive(false);
    handleHoverEffect(false);
  }, [handleHoverEffect]);

  const mouseOverTimeout = useRef();

  const handleMouseOverWithDelay = useCallback(() => {
    if (typeof delay !== 'undefined' && delay !== null) {
      mouseOverTimeout.current = setTimeout(handleMouseOver, delay);
    } else {
      handleMouseOver();
    }
  }, [handleMouseOver, delay]);

  useEffect(() => {
    const node = ref.current;
    if (node) {
      node.addEventListener('mouseover', handleMouseOverWithDelay);
      node.addEventListener('mouseout', handleMouseOut);
      return () => {
        node.removeEventListener('mouseover', handleMouseOverWithDelay);
        node.removeEventListener('mouseout', handleMouseOut);
      };
    }
    if (mouseOverTimeout.current) {
      return () => clearTimeout(mouseOverTimeout.current);
    }
  }, [ref, handleMouseOut, handleMouseOverWithDelay]);
  return isHoverActive;
};

export default useDelayHover;
