import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { selectActiveUser } from 'store/userSlice';
import { useHandleTicketLock, useTicketLockStatus } from 'remote-state/ticketServiceHooks';
import Actions from '../../../header/actions';

const ActionBtnRenderer = (props) => {
  const { data, api } = props;
  const [hovered, setHovered] = useState(false);
  const userAccount = useSelector(selectActiveUser);
  const currentUser = userAccount?.username;
  const id = !Array.isArray(data) ? undefined : data?.find(({ field }) => field === 'id')?.value;
  const srType = !Array.isArray(data) ? undefined : data?.find(({ field }) => field === 'srType')?.value;
  const { data: lockingDetails } = useTicketLockStatus(id);
  const { mutate: lockSR } = useHandleTicketLock(id);

  const onBackButtonClick = () => {
    if (id) {
      lockSR({ username: currentUser, srId: id });
    }
    api.setRowData([]);
    api.refreshInfiniteCache();
  };

  if (!Array.isArray(data)) return <></>;

  return (
    <Box sx={{ '.delete-sr-action-btn': { display: 'none' } }}>
      <Actions
        handleMouseEnter={() => setHovered(true)}
        handleMouseLeave={() => setHovered(false)}
        btnClassName="delete-sr-action-btn"
        srId={id}
        srType={srType}
        onClickParent={onBackButtonClick}
        hovered={hovered}
        disabled={lockingDetails?.isLocked && currentUser !== lockingDetails?.lockingUser}
      />
    </Box>
  );
};

export default ActionBtnRenderer;

// Leaving this commented code for reference to the original styling
// We cannot currently implement this design
// If we can figure out how to implement it, this is the preferred method

// import { styled } from '@mui/material/styles';
// import { ReactComponent as IconMessage } from '../../../../images/icons/icon_message.svg';

// const StyledBtnContainer = styled(Box)(() => ({
//   display: 'flex',
//   gap: '8px',
//   justifyContent: 'center',
//   alignItems: 'center',
// }));

// const ActionBtnRenderer = (props) => {

//   return (
//     <div>
//       <StyledBtnContainer>
//         <IconMessage
//           onClick={(event) => {
//             event.stopPropagation();
//           }}
//         />
//         <div>
//           <Actions srId={props.data?.id} onClickParent={onBackButtonClick} />
//         </div>
//       </StyledBtnContainer>
//     </div>
//   );
// };
