import { forwardRef, useState } from 'react';
import { useRouter } from '@tanstack/react-router';
import useHover from 'common/utils/hooks/useHover';
import RichTextEditor from 'common/components/controls/RichTextEditor';
import useTexts from 'features/resolutionPanel/useTexts';
import ActionLinePrompt from 'features/resolutionPanel/middlePanel/auditLog/actionLine/prompts/actionLinePrompt';
import { KebabMenu } from './kebabMenu';
import { useEditorActions } from '../../hooks';
import { useDisplayTextTruncation } from '../fieldChange/hooks/useDisplayTextTruncation';

import { StyledAuditLogEditor, StyledWrapper, StyleReadonlyEditorWrapper } from './style';
import {
  actionEnums,
  DELETE_ATTACHMENT_ENABLED,
  MESSAGE_EVENTS,
  NOTE_EVENTS,
  promptTypes,
  READ_ONLY_HAS_HEADER as HAS_HEADER,
  RESOLUTION_EVENTS,
  RESOLUTION_UPDATING_EVENTS,
} from '../../constants';
import { editorActionTypes } from '../../constants/editorActionTypes';
import { MessageHeader } from './customHeaders/message';
import { Quote } from '../../actionLine/header/quote';
import { QuoteSubject } from '../../actionLine/header/quote/QuoteSubject';
import { QuoteContent } from '../../actionLine/header/quote/QuoteContent';
import TruncatedTextButton from './truncatedTextButton';
import usePendoTrackEvents from '../../../../../../common/utils/hooks/usePendoTrackEvents';
import { PENDO_TRACK_EVENTS } from '../../../../../../constants';
import { lineHeightHandler } from '../../utils';

export const ReadOnlyEditor = forwardRef((props, ref) => {
  const {
    value,
    customFooter,
    logType,
    logInformation,
    auditLogId,
    showKebabMenu = true,
    isRichTextTruncated = true,
    scrollToRecord,
    isFirst = true,
    isTicketLocked,
    toggleAuditLogsProperty,
  } = props;
  const router = useRouter();
  const { deleteText } = useTexts();
  const texts = useTexts();
  const [hoverRef, isHovered] = useHover();
  const { handleActionLineOperation, deleteEditorItem, saveEditorDataByType, toggleRichTextEdited } = useEditorActions();
  const [isShowPrompt, setIsShowPrompt] = useState(false);
  const srId = router.latestLocation.search.id;
  const { showTruncationButton } = useDisplayTextTruncation({
    divRef: hoverRef,
    logType,
    isRichText: logInformation?.isRichText,
    maxRowsAmount: 2,
  });
  const pendoTrackEvents = usePendoTrackEvents();

  const trackShowMoreEvents = () => {
    if (isRichTextTruncated) {
      if (logType >= actionEnums.NOTE_CREATE && logType <= actionEnums.NOTE_ACTION) {
        pendoTrackEvents(PENDO_TRACK_EVENTS.SHOW_MORE_JOURNEY_NOTES);
      }
      if (logType >= actionEnums.MESSAGE_SENT && logType <= actionEnums.MESSAGE_ACTION) {
        pendoTrackEvents(PENDO_TRACK_EVENTS.SHOW_MORE_JOURNEY_MESSAGES);
      }
    }
  };

  const toggleTruncationButton = () => {
    trackShowMoreEvents();
    toggleAuditLogsProperty({ auditLogIds: [auditLogId], property: 'isRichTextTruncated' });
  };

  const getEditorTypeByLogType = () => {
    if (NOTE_EVENTS.includes(logType)) return editorActionTypes.NOTE;
    if (MESSAGE_EVENTS.includes(logType)) return editorActionTypes.MESSAGE;
    if (RESOLUTION_EVENTS.includes(logType)) return editorActionTypes.RESOLUTION;
  };

  const editorType = getEditorTypeByLogType();

  const handleClickEdit = ({ shouldResetData }) => {
    if (shouldResetData) {
      toggleRichTextEdited({ auditLogId });
    }
  };

  const handleClickDelete = () => {
    setIsShowPrompt(true);
  };

  const handleClosePrompt = ({ isOkBtnClicked }) => {
    setIsShowPrompt(false);
    if (isOkBtnClicked) {
      deleteEditorItem({ editorItemId: logInformation?.id, auditLogId, editorType });
    }
  };

  const getPromptTypeByLogType = () => {
    if (RESOLUTION_UPDATING_EVENTS.includes(logType)) return editorActionTypes.RESOLUTION;
    return editorActionTypes.NOTE;
  };

  const handleDeleteAttachment = async (attachmentId) => {
    let attachmentsList = logInformation?.attachments;
    if (logType === actionEnums.SOLUTION_RESOLUTION_CREATED) {
      if (isFirst) {
        attachmentsList = logInformation?.solution?.attachments;
      } else {
        attachmentsList = logInformation?.resolution?.attachments;
      }
    }
    const attachments = attachmentsList.map((item) => {
      if (item.id === attachmentId) {
        return { ...item, ...{ deleted: true } };
      }
      return item;
    });
    const updateData = { ...logInformation, attachments, auditLogId };

    await saveEditorDataByType({
      updateData,
      isEditing: true,
      logType: getEditorTypeByLogType(),
      promptType: getPromptTypeByLogType(),
    });
  };

  const renderHeader = () => {
    const editorType = getEditorTypeByLogType();
    switch (editorType) {
      case editorActionTypes.MESSAGE:
        return <MessageHeader logInformation={logInformation} isRichTextTruncated={isRichTextTruncated} />;
      default:
        return null;
    }
  };
  const shouldShowTruncateButton = () =>
    [
      actionEnums.NOTE_CREATE,
      actionEnums.NOTE_DELETE,
      actionEnums.MESSAGE_SENT,
      actionEnums.AI_MESSAGE_SEND,
      actionEnums.MESSAGE_RECEIVED,
      actionEnums.IM_MESSAGE,
      actionEnums.SMS_MESSAGE,
      actionEnums.ITIL_MESSAGE,
    ].includes(logType);

  return (
    <StyleReadonlyEditorWrapper data-cy={`readonly-${logType}-editor-${auditLogId}`}>
      <StyledAuditLogEditor
        isRichTextTruncated={isRichTextTruncated}
        ref={hoverRef}
        logType={logType}
        isHovered={isHovered}
        showKebabMenu={showKebabMenu}
        className="audit-log-editor"
      >
        <StyledWrapper>
          {logInformation?.quotedRecord && (
            <Quote
              subject={<QuoteSubject subject={logInformation?.quotedRecord?.subject} />}
              content={<QuoteContent content={logInformation?.quotedRecord?.text} />}
              type={logInformation?.quotedRecord?.type}
              isSavedQuote
              scrollToRecord={() => scrollToRecord(logInformation?.quotedRecord?.auditLogId)}
            />
          )}
          <div className="wrapper-no-menu">
            <div className="header-body-content">
              {HAS_HEADER.includes(logType) && renderHeader()}
              {isFirst && (
                <div ref={ref} className="first-content-wrap rich-text-ellipsis">
                  <RichTextEditor
                    isReadOnly
                    isExpanded
                    sourceId={srId}
                    editorText={lineHeightHandler(value)}
                    attachmentsList={
                      logType === actionEnums.SOLUTION_RESOLUTION_CREATED
                        ? logInformation?.solution?.attachments
                        : logInformation?.attachments
                    }
                    onAttachmentDeleted={handleDeleteAttachment}
                    editorType={editorType}
                    deleteAttachmentEnabled={DELETE_ATTACHMENT_ENABLED.includes(logType)}
                  />
                </div>
              )}

              {!isFirst && (
                <div ref={ref} className="second-content-wrap rich-text-ellipsis">
                  <RichTextEditor
                    isReadOnly
                    isExpanded
                    editorText={lineHeightHandler(value)}
                    attachmentsList={
                      logType === actionEnums.SOLUTION_RESOLUTION_CREATED
                        ? logInformation?.resolution?.attachments
                        : logInformation?.attachments
                    }
                    onAttachmentDeleted={handleDeleteAttachment}
                    editorType={editorType}
                    deleteAttachmentEnabled={DELETE_ATTACHMENT_ENABLED.includes(logType)}
                  />
                </div>
              )}
            </div>
            {customFooter && <div className="footer-wrap">{customFooter}</div>}
          </div>
        </StyledWrapper>
        {showKebabMenu && !RESOLUTION_EVENTS.includes(logType) && (
          <KebabMenu
            isTicketLocked={isTicketLocked}
            editorType={editorType}
            logType={logType}
            isHovered={isHovered}
            handleKebabMenuAction={(actionName) =>
              handleActionLineOperation({
                type: editorType,
                actionName,
                logInformation,
                auditLogId,
                handleClickEdit,
                handleClickDelete,
              })
            }
          />
        )}
        {isShowPrompt && (
          <ActionLinePrompt
            promptType={promptTypes.CANCEL}
            onOkClick={() => handleClosePrompt({ isOkBtnClicked: true })}
            onCancelClick={() => handleClosePrompt({ isOkBtnClicked: false })}
            message={{
              title: texts.modal[editorType].delete.title,
              description: texts.modal[editorType].delete.description,
            }}
            btnTexts={{ okBtn: deleteText }}
          />
        )}
      </StyledAuditLogEditor>
      {shouldShowTruncateButton() && showTruncationButton && (
        <TruncatedTextButton isRichTextTruncated={isRichTextTruncated} onClick={toggleTruncationButton} />
      )}
    </StyleReadonlyEditorWrapper>
  );
});
