import { Box, Menu } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TextAreaRenderer = styled(Box)(({ isEmptyRequiredValue }) => ({
  width: '100%',
  '.MuiInput-input': {
    pointerEvents: 'none!important',
  },
  '&': !isEmptyRequiredValue && {
    '.inline-field .Mui-disabled': {
      cursor: 'pointer !important',
    },
  },
}));

export const StyledTextAreaWrapper = styled(Box)(() => ({
  '.textarea-wrap': {
    width: '410px !important',
    maxWidth: '300px',
    minWidth: '300px',
    height: '90px',
    position: 'relative',
    border: '1px solid rgb(230,230,230)',
    borderRadius: '4px!important',
  },
  'textarea': {
    width: '100%',
    height: '60px!important',
    padding: '10px',
    fontSize: '14px',
    lineHeight: '1.5',
    resize: 'none',
    overflow: 'auto',
    borderRadius: '4px',
  }
}));

export const StyledMenu = styled(Menu)(() => ({
  '.MuiPaper-root': {
    overflow: 'hidden',
    minHeight: '0!important',
    minWidth: '0!important',
    boxShadow: 'none!important',
  },
  '.MuiList-root': {
    padding: '0',
  },
  '.Mui-focused': {
    border: 'none !important',
    color: `grey!important`,
    font: 'robotoRegular',
  }
}));
