import { memo, Fragment } from 'react';

export function getHighlightedText(text, higlight) {
  const parts = text?.split(new RegExp(`(${higlight})`, 'gi'));
  return {
    isFound: parts?.length > 1,
    elem: (
      <span>
        {parts?.map((part, index) => (
          <Fragment key={String(index)}>
            {part.toLowerCase() === higlight.toLowerCase() ? (
              <span style={{ color: '#699D8C' }} data-testid="highlighted-text">
                {part}
              </span>
            ) : (
              part
            )}
          </Fragment>
        ))}
      </span>
    ),
  };
}

const HighLight = ({ higlight, value }) => {
  if (!higlight || !value) {
    return <span data-testid="no-highlighted-text">{value}</span>;
  }
  return <>{getHighlightedText(value, higlight).elem}</>;
};

export default memo(HighLight);
