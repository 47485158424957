import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledGridDropDownMenu = styled(Box, { shouldForwardProp: (prop) => prop !== 'isViewEdited' })(
  ({ isViewEdited }) => ({
    width: isViewEdited ? 'calc(100% - 110px)' : 'calc(100% - 40px)',
    '.SearchField': {
      width: '100%',
    },
  }),
);

export const StyledGridDropDownMenuWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const StyledTitleMenu = styled(Box)(({ theme, isDefaultSelected }) => ({
  width: isDefaultSelected ? 'calc(100% - 36px)' : '100%',
  maxWidth: '582px',
  color: theme?.palette?.text?.title_txt,
  padding: theme.spacing(0.5, 1),
  fontSize: theme?.fontSize?.medium,
  display: 'flex',
  gap: '6px',
  alignItems: 'center',
  borderRadius: theme.shape.border.borderRadius,
  cursor: 'pointer',
  '.MuiTypography-root': {
    fontSize: theme?.fontSize?.medium,
  },
  svg: {
    width: '16px',
  },
  '&.select-view-open ,&:hover': {
    backgroundColor: theme.palette.background.bg_hover_alt,
  },
}));
