import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledFooter = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'shareWithRequestUser' && prop !== 'isNoteAuthorReqUser',
})(({ theme, shareWithRequestUser, isNoteAuthorReqUser, isMessage }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: isMessage ? '32px' : 0,
  padding: '6px 0px 4px',

  '.log-type': {
    color: theme.palette.text.secondary_txt,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: '4px',
    lineHeight: '16px',

    '.log-type-indicator': {
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      background:
        isNoteAuthorReqUser || shareWithRequestUser
          ? theme.palette.labels.bt_orange_hover
          : theme.palette.grey.bt_def_grey_light,
    },
  },
}));

export const StyledWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
  fontSize: '12px',
  color: theme.palette.text.secondary_txt,
  cursor: 'pointer',

  '&:hover': {
    '& .message-footer-action-text': {
      textDecoration: 'underline',
    },
  },
}));
