import { ClearButton, StyledDatePickerActions } from './style';
import PopupButton from '../popupButton';
import useTexts from './useTexts';

export default function DatePickerActions({ onAction, clearValueHandler }) {
  const { clear, cancel, save } = useTexts();

  const onClear = () => {
    if (clearValueHandler) {
      clearValueHandler();
    }
  };
  return (
    <StyledDatePickerActions data-testid="datepicker-actions">
      <ClearButton variant="primary" onClick={onClear}>
        {clear}
      </ClearButton>
      <div>
        <PopupButton variant="secondary" onAction={onAction} action={false}>
          {cancel}
        </PopupButton>
        <PopupButton variant="primary" onAction={onAction} action dataTestId="save-date-btn">
          {save}
        </PopupButton>
      </div>
    </StyledDatePickerActions>
  );
}
