import { useSelector } from 'react-redux';
import useTexts from 'features/resolutionPanel/useTexts';
import { selectJourneyFilters } from 'features/resolutionPanel/middlePanel/auditLog/store/slice';
import { SR_TYPES, SR_TYPE_CODE_MAP } from 'features/TicketPanel/constants';
import { useSrById } from 'remote-state/ticketServiceHooks';
import { useFeatureFlagQuery } from 'remote-state/featureFlagsHooks';
import { FEATURE_FLAGS_KEYS } from 'constants/featureFlags';
import { useWorkflowQuery } from '../../Workflows/api/workflowQueries';
import { MIDDLE_PANEL_TAB_NAMES } from '../../constants';
import { useGetSrRelatedItems } from '../../relatedItems/hooks/useRelatedItems';

const initializeTabs = ({ tabTexts, hasWorkflow, showTicketAsTab, isRelatedItemsFFOn, relatedItemsCount }) => {
  const newTabs = [];
  if (showTicketAsTab)
    newTabs.push({ name: MIDDLE_PANEL_TAB_NAMES.TICKET_DETAILS, tabText: tabTexts.ticketDetailsTab });
  if (hasWorkflow) newTabs.push({ name: MIDDLE_PANEL_TAB_NAMES.WORKFLOWS, tabText: tabTexts.workflowTab });
  newTabs.push(
    { name: MIDDLE_PANEL_TAB_NAMES.JOURNEY, tabText: tabTexts.journeyTab },
    { name: MIDDLE_PANEL_TAB_NAMES.ATTACHMENTS, tabText: tabTexts.attachmentsTab },
  );
  if (isRelatedItemsFFOn)
    newTabs.push({
      name: MIDDLE_PANEL_TAB_NAMES.RELATED_ITEMS,
      tabText: `${tabTexts.relatedItemsTab}${relatedItemsCount > 0 ? ` (${relatedItemsCount})` : ''}`,
    });
  return newTabs;
};

function useInitializeTabs({ srId, tabTexts, showTicketAsTab }) {
  const { srType } = useSrById(srId).data ?? {};
  const hasWorkflow = Boolean(SR_TYPE_CODE_MAP[srType] !== SR_TYPES.incident);
  const { data: isRelatedItemsFFOn } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.RELATED_ITEMS_SPACES_MVP,
  });

  const { data: relatedItems } = useGetSrRelatedItems(srId);

  const tabs = useWorkflowQuery({
    srId,
    enabled: hasWorkflow,
    select: (data) =>
      initializeTabs({
        tabTexts,
        hasWorkflow: hasWorkflow && data?.phases?.length > 0,
        showTicketAsTab,
        isRelatedItemsFFOn,
        relatedItemsCount: relatedItems?.count,
      }),
  });

  if (!hasWorkflow) {
    return {
      data: initializeTabs({
        tabTexts,
        hasWorkflow: false,
        showTicketAsTab,
        isRelatedItemsFFOn,
        relatedItemsCount: relatedItems?.count,
      }),
      isLoading: false,
      isError: false,
    };
  }

  return tabs;
}

export const useTabs = ({ srId, showTicketAsTab }) => {
  const texts = useTexts();
  const { tab: currentSelectedTabIndex } = useSelector(selectJourneyFilters);
  const { data: tabs, isLoading } = useInitializeTabs({
    tabTexts: texts,
    srId,
    showTicketAsTab,
  });
  const indexOfTab = currentSelectedTabIndex ?? 0;
  const selectedTabIndex = indexOfTab >= tabs?.length ? indexOfTab - 1 : indexOfTab;
  const selectedTabName = tabs === undefined ? false : tabs[selectedTabIndex]?.name;

  return {
    tabs,
    selectedTabIndex,
    selectedTabName,
    isLoading,
  };
};
