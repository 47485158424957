import { useEffect, useState } from 'react';
import { ListItemText } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { BUTTON_THEMES } from 'common/components/buttons/constants';
import ImageCropper from 'common/components/uploadFile/imageCropper';
import { usePhotoLimitsQuery, useSaveProfilePictureQuery } from 'remote-state/userServiceHooks';

import {
  StyledButton,
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogContentText,
  StyledDialogTitle,
  StyledList,
  StyledListItem,
  StyleUploadError,
} from './styles';
import useTexts from './useTexts';
import AttachmentFile from './attachmentFile';
import { QUERIES_KEYS } from '../../../../../constant';
import { AVATAR_CONSTANTS } from '../../../../../constants';
import { details } from '../../../../../store/userSlice';

export default function ChangeProfilePicture({ open, handleClose }) {
  const [imageURL, setImageURL] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const queryClient = useQueryClient();
  const userDetails = useSelector(details);

  const {
    data: {
      enabledTypes = AVATAR_CONSTANTS.ALLOWED_UPLOAD_FILE_TYPES,
      maxSize: maxFileSize = AVATAR_CONSTANTS.MAX_UPLOAD_FILE_SIZE,
    } = {},
  } = usePhotoLimitsQuery();
  const { saveProfilePicture, isLoading: isLoadingSaveImage } = useSaveProfilePictureQuery();

  const modifiedTypes = enabledTypes?.map((type) => type.toUpperCase()).join(', ');
  const disabledButtons = (!imageURL && !croppedImage) || isLoadingSaveImage;
  const {
    profileTitleText,
    titleText,
    fileTypesTitleText,
    fileSizeTitleText,
    fileSizeErrorText,
    fileTypesErrorText,
    uploadFileErrorText,
    cancelText,
    saveText,
  } = useTexts();

  useEffect(() => {
    setUploadError(null);
  }, [croppedImage]);

  const handleChangeCroppedImage = (file) => {
    setCroppedImage(file);
  };

  const handleClearImage = () => {
    setImageURL(null);
    handleChangeCroppedImage(null);
    setUploadError(null);
    handleClose();
  };

  const handleCloseDialog = () => {
    handleClose();
    handleClearImage();
  };

  const handleUploadFileError = (status) => {
    switch (status) {
      case 413:
        setUploadError(fileTypesErrorText);
        break;
      case 415:
        setUploadError(fileSizeErrorText);
        break;
      default:
        setUploadError(uploadFileErrorText);
        break;
    }
  };

  const handleSaveProfilePicture = async () => {
    try {
      await saveProfilePicture(croppedImage);
      queryClient.invalidateQueries({ queryKey: [QUERIES_KEYS.CURRENT_USER] });
      queryClient.invalidateQueries({ queryKey: [QUERIES_KEYS.USER_DETAILS_BY_NAME, userDetails.userName] });
      queryClient.invalidateQueries({ queryKey: [QUERIES_KEYS.USER_DETAILS_BY_ID, userDetails.id] });
      handleCloseDialog();
    } catch (error) {
      if (error.response) {
        const { status } = error.response;
        handleUploadFileError(status);
      }
    }
  };

  return (
    <StyledDialog open={open} onClose={handleCloseDialog}>
      <StyledDialogTitle>{profileTitleText}</StyledDialogTitle>
      <StyledDialogContent>
        <StyledDialogContentText>
          <StyledList
            disablePadding
            subheader={
              <StyledListItem disableGutters disablePadding className="subheader">
                <ListItemText primary={titleText} />
              </StyledListItem>
            }
          >
            <StyledListItem disableGutters>
              <ListItemText data-testid="profile-type-title" primary={`${fileTypesTitleText}: ${modifiedTypes}`} />
            </StyledListItem>
            <StyledListItem disableGutters>
              <ListItemText
                data-testid="profile-size-title"
                primary={`${fileSizeTitleText} ${maxFileSize / 1024} KB`}
              />
            </StyledListItem>
          </StyledList>
        </StyledDialogContentText>
        {imageURL ? (
          <ImageCropper imageURL={imageURL} onCropComplete={handleChangeCroppedImage} />
        ) : (
          <AttachmentFile
            onChangeImageURL={setImageURL}
            onCropComplete={setCroppedImage}
            allowedTypes={enabledTypes}
            maxFileSize={maxFileSize}
            onUploadFileError={handleUploadFileError}
          />
        )}
        <StyleUploadError>{uploadError}</StyleUploadError>
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledButton
          className="btn-secondary"
          btnTheme={BUTTON_THEMES.SECONDARY}
          text={cancelText}
          onClick={handleClearImage}
          data-testid="clear-image"
        />
        <StyledButton
          className="btn-primary"
          btnTheme={BUTTON_THEMES.PRIMARY}
          text={saveText}
          disabled={disabledButtons}
          onClick={handleSaveProfilePicture}
          data-testid="save-crop-image"
        />
      </StyledDialogActions>
    </StyledDialog>
  );
}
