import { useRouter } from '@tanstack/react-router';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { useHandleTicketUnlock, useSR } from 'remote-state/ticketServiceHooks';
import { selectActiveUser } from 'store/userSlice';
import { StyledLogoutButton } from './styles';
import useTexts from './useTexts';

export default function LogoutBtn(props) {
  const router = useRouter();
  const { logoutBtnText } = useTexts();
  const userAccount = useSelector(selectActiveUser);
  const { srId } = useSR();
  const { mutate: unlockSR } = useHandleTicketUnlock(srId);

  const handleLogout = async () => {
    unlockSR({ username: userAccount?.username, srId });
    router.navigate({ to: '/spaces/logout', search: { from: router.latestLocation.href } });
  };

  return (
    <StyledLogoutButton>
      <Button onClick={handleLogout} varient="text" disableElevation disableRipple data-testid="btn-logout" {...props}>
        {logoutBtnText}
      </Button>
    </StyledLogoutButton>
  );
}
