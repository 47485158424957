import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    save: texts?.['spaces.datePicker.dueDate.save'],
    cancel: texts?.['spaces.datePicker.dueDate.cancel'],
    clear: texts?.['spaces.datePicker.dueDate.clear'],
  };

  return componentTexts;
}
