import { styled } from '@mui/material/styles';

export const StyledTemplateLabel = styled('span')(({ theme }) => ({
  fontFamily: 'Roboto-Regular',
  fontSize: theme.fontSize.main,
  lineHeight: 1,
  marginRight: 5,
  color: theme.palette.text.secondary_txt,

  '&.label-dark': {
    color: theme.palette.text.txt_default,
  },
}));
