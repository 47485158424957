import { useCallback } from 'react';
import { ReactComponent as PlusIcon } from 'images/icons/workflows/plus.svg';
import { ReactComponent as MinusIcon } from 'images/icons/workflows/minus.svg';
import { Assignee } from 'common/components/sysaidLibrary/Assignee';
import { IconButton } from '../IconButton';
import { StyledAccordion } from './style';
import ActionItemHeader from './components/ActionItemHeader';
import ActionItemBody from './components/ActionItemBody';
import ActionItemFooter from './components/ActionItemFooter';
import CompleteButton from './components/CompleteButton';
import { WORFKLOWS_CONSTANTS } from '../../constants';
import useFormValidation from './hooks/useFormValidation';
import useIndicators from './hooks/useIndicators';
import { StatusIndicator } from '../StatusIndicator';
import { DisabledIndicator } from '../DisabledIndicator';
import { DependencyIndicator } from '../DependencyIndicator';

const renderIndicator = (indicator, index) => {
  switch (indicator.type) {
    case 'status':
      return <StatusIndicator key={`status-${index}`} completed={indicator.completed} />;
    case 'disabled':
      return <DisabledIndicator key={`disabled-${index}`} type={indicator.disabledType} />;
    case 'dependency':
      return (
        <DependencyIndicator
          key={`dependency-${index}`}
          logicalOperatorIsAnd={indicator.logicalOperatorIsAnd}
          dependencies={indicator.dependencies}
        />
      );
    default:
      return null;
  }
};

const ActionItem = ({ phaseId, actionItem, handleSave, isViewer, isDisabled, urlAccordionExpansion }) => {
  const {
    handleFormErrors,
    hasAssigneeField,
    isAssigneeRequired,
    showError,
    isFormEditable,
    isButtonDisabled,
    assigneesReadOnly,
  } = useFormValidation({ actionItem, isViewer });
  const indicators = useIndicators({ actionItem });

  const handleChangeAssignee = useCallback(
    (newAssignees) => {
      handleSave({
        actionItemId: actionItem.id,
        phaseId,
        fieldName: WORFKLOWS_CONSTANTS.FIELDS.ASSIGNED_TO.NAME,
        value: newAssignees,
      });
    },
    [handleSave, actionItem.id, phaseId],
  );
  return (
    <StyledAccordion
      disabled={isDisabled}
      id={`ai-${actionItem.id}`}
      data-testid="action-item"
      data-cy={`ai-${actionItem.id}`}
      className="action-item"
      initialOpen={urlAccordionExpansion}
      disableGutters
      elevation={0}
      closeIcon={<IconButton isDisabled={isDisabled} IconComponent={PlusIcon} data-cy="action-item-expand-icon" />}
      openIcon={<IconButton isDisabled={isDisabled} IconComponent={MinusIcon} data-cy="action-item-collapse-icon" />}
      summary={
        <ActionItemHeader
          title={actionItem.title}
          assignee={
            hasAssigneeField && (
              <Assignee
                assignedUser={actionItem.assignee}
                assignedGroup={actionItem.assignedGroup}
                handleChangeAssignee={handleChangeAssignee}
                readOnly={assigneesReadOnly}
                disabled={isViewer}
                isRequired={isAssigneeRequired}
                onClick={(e) => e.stopPropagation()}
              />
            )
          }
          indicators={indicators.map(renderIndicator)}
        />
      }
    >
      <ActionItemBody
        phaseId={phaseId}
        actionItemId={actionItem.id}
        formValues={actionItem.formValues}
        fields={actionItem.fields}
        readOnly={actionItem.readOnly}
        handleSave={handleSave}
        handleFormErrors={handleFormErrors}
        isEditable={isFormEditable}
      />
      <ActionItemFooter>
        <CompleteButton
          completed={actionItem.completed}
          showUpdateLoader
          onClick={() =>
            handleSave({
              actionItemId: actionItem.id,
              phaseId,
              fieldName: WORFKLOWS_CONSTANTS.FIELDS.STATUS.NAME,
              value: actionItem.completed
                ? WORFKLOWS_CONSTANTS.ACTION_ITEM_ENABLE_VALUE
                : WORFKLOWS_CONSTANTS.ACTION_ITEM_COMPLETE_VALUE,
            })
          }
          disabled={isButtonDisabled}
          showError={showError}
        />
      </ActionItemFooter>
    </StyledAccordion>
  );
};

export default ActionItem;
