import { axiosInstance } from './axiosConfig';

export function getTicketAuditLogsByFilter(srId, filterType) {
  return axiosInstance.get(`journeyAuditLog/${srId}/${filterType}`).then((response) => response.data);
}

export function getFilterAuditLogTypes(filtersArr) {
  return axiosInstance.get(`journeyAuditLog/types/${filtersArr}`).then((response) => response.data);
}

export function getMoreTicketAuditLogByFilter(srId, filterType, lastId) {
  return axiosInstance.get(`journeyAuditLog/${srId}/${filterType}/${lastId}`).then((response) => response.data);
}

export function getJourneyAuditLogsByFilterType({ srId, filterType, orderParam = 'logTimestamp', countRows = 1 }) {
  return axiosInstance
    .get(`journeyAuditLog/${srId}/${filterType}/${orderParam}/${countRows}`)
    .then((response) => response.data);
}
