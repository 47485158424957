import { useRouter } from '@tanstack/react-router';
import sysaidLogoGreen from 'images/photos/sysaid-logo-green.png';
import useTexts from '../../useTexts';
import LeftSideUpperLogo from '../../leftSideUpperLogo';
import RightPanel from '../../rightPanel';

import { StyledCheckMailPage } from './style';

export const CheckMailPage = () => {
  const { checkMailText, passwordSentText, returnToSignInText } = useTexts();
  const router = useRouter();

  const onClickReturnToSignIn = () => {
    router.navigate({ to: '/spaces/login' });
  };

  return (
    <StyledCheckMailPage>
      <div id="login-wrapper">
        <div id="left-side-container">
          <LeftSideUpperLogo />
          <div id="left-side-wrapper">
            <div className="left-side-logo"><img alt="sysaid-logo" src={sysaidLogoGreen} /></div>
            <div id="left-side-header">{checkMailText}</div>
            <div id="left-side-sub-header" className="check-mail-sub-header line">
              {passwordSentText}
            </div>
            <button id="left-side-submit-button" onClick={onClickReturnToSignIn}>
              {returnToSignInText}
            </button>
          </div>
        </div>
        <RightPanel />
      </div>
    </StyledCheckMailPage>
  );
};
