const FileSize = {
  MB: 1024 * 1024,
  KB: 1024,
};

const ResourceType = {
  REQUEST: 'REQUEST',
  CHANGE: 'CHANGE',
  INCIDENT: 'INCIDENT',
  PROBLEM: 'PROBLEM',
  SR: 'SR',
  TEMPLATE: 'TEMPLATE',
};

const SortDirection = {
  ASC: 'ASC',
  DESC: 'DESC',
};

const SortBy = {
  CREATEDDATE: 'CREATED_DATE',
  FILENAME: 'FILE_NAME',
  EXTENSION: 'EXTENSION',
};

const SubResourceType = {
  SR: 'SR',
  NOTE: 'NOTE',
  MESSAGE: 'MESSAGE',
  RESOLUTION: 'RESOLUTION',
  SOLUTION: 'SOLUTION',
  TEMP: 'TEMP',
  DESCRIPTION: 'DESCRIPTION',
  ACTION_ITEM: 'ACTION_ITEM',
};

const AttachmentType = {
  IMAGE: 'IMAGE',
  TEXT: 'TEXT',
  PDF: 'PDF',
  XLS: 'XLS',
};

const AttachmentPath = {
  DEFAULT: 'attachments/',
  V2: 'attachments/v2/',
  V3: 'attachments/v3/',
};

export { FileSize, ResourceType, SubResourceType, AttachmentType, AttachmentPath, SortDirection, SortBy };
