import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    message: texts?.['spaces.resolutionPanel.journey.errorBoundary.message'],
    refresh: texts?.['spaces.resolutionPanel.journey.errorBoundary.refresh'],
    support: texts?.['spaces.resolutionPanel.journey.errorBoundary.support'],
  };

  return componentTexts;
}
