import { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { baseURL } from 'services/axiosBaseInstance';
import { useUserInfo } from 'remote-state/userServiceHooks';
import { useTicketAttachments } from 'remote-state/attachments';
import { QUERIES_KEYS } from 'constant';
import { USER_CONSTANTS } from 'constants/users';

import Thumbnail from 'common/components/thumbnail';
import { getDate } from 'common/utils/dateTimeUtils';
import {
  selectActiveFilters,
  updateActionLineData,
} from '../../../../features/resolutionPanel/middlePanel/auditLog/store/slice';
import { AttachmentPath } from '../../../../services/attachments';

export const useUploadFromExistingAttachments = ({ setIsOpen }) => {
  const [searchString, setSearchString] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [displayPreview, setDisplayPreview] = useState(false);
  const [selectedFileIndex, setSelectedImageIndex] = useState(0);

  const dispatch = useDispatch();
  const activeFilters = useSelector(selectActiveFilters);

  const userInfo = useUserInfo(QUERIES_KEYS.CURRENT_USER_FROM_CORE);
  const { valueCaption: timezone } = userInfo?.info?.find((atr) => atr.key === USER_CONSTANTS.TIMEZONE) || {};
  const { valueCaption: dateFormat } = userInfo?.info?.find((atr) => atr.key === USER_CONSTANTS.DATE_FORMAT) || {};

  const isSomethingSelected = selectedItems && selectedItems?.length > 0;

  const {
    srAttachments: { data: attachments = [] },
    updateSRAttachmentsAfterDelete,
  } = useTicketAttachments();

  const filesData = useMemo(() => {
    const files = [];
    const extensions = [];
    const hasActiveFilters = activeFilters && activeFilters?.length > 0;

    for (const attachment of attachments) {
      let isCoveringFilterCriteria = true;

      if (!extensions.includes(attachment?.metadata?.extension)) {
        extensions.push(attachment?.metadata?.extension);
      }

      if (hasActiveFilters) {
        activeFilters?.forEach((filter) => {
          if (
            filter?.field === 'Time' &&
            !(
              filter?.filter?.values?.startDate < new Date(attachment?.metadata?.createdDate)?.getTime() &&
              filter?.filter?.values?.endDate > new Date(attachment?.metadata?.createdDate)?.getTime()
            )
          ) {
            isCoveringFilterCriteria = false;
          } else if (filter?.field === 'Type' && !filter?.filter?.name?.includes(attachment?.metadata?.extension)) {
            isCoveringFilterCriteria = false;
          }
        });
      }

      const isSearchContainingName = searchString
        ? attachment?.metadata?.originalName?.toLowerCase()?.includes(searchString?.toLowerCase())
        : true;

      const fileUrl = `${baseURL}${AttachmentPath.V3}${attachment?.metadata?.id}/payload`;

      if (isSearchContainingName && isCoveringFilterCriteria) {
        files.push({
          key: attachment?.metadata?.id,
          extension: attachment?.metadata?.extension,
          createdDate: attachment?.metadata?.createdDate,
          src: fileUrl,
          attachmentId: attachment?.metadata?.id,
          userName: attachment?.metadata?.username,
          fileName: attachment?.metadata?.originalName,
          removeParams: { fileUrl },
          metadata: attachment?.metadata,
        });
      }
    }
    return { files, extensions };
  }, [attachments, activeFilters, searchString]);

  const handleDeleteAttachment = (attachmentId, fileId) => {
    updateSRAttachmentsAfterDelete(attachmentId, fileId);
  };

  const handleDisplayPreview = (index) => {
    setSelectedImageIndex(index);
    setDisplayPreview(true);
  };

  const onSearchChange = (e) => {
    setSearchString(e.target.value);
  };
  const onSearchClear = () => {
    setSearchString('');
  };

  const handleImageRemove = () => {
    setDisplayPreview(false);
  };

  const onCheckHandler = (attachment) => {
    let isChecked = false;
    const selectedItemsRef = [...selectedItems];
    const keyExists = selectedItemsRef[attachment?.attachmentId];

    if (keyExists) {
      selectedItemsRef.splice(attachment?.attachmentId, 1);
    } else {
      isChecked = true;
      selectedItemsRef[attachment?.attachmentId] = { ...attachment?.metadata };
    }

    setSelectedItems(selectedItemsRef);

    return isChecked;
  };

  const thumbnails = filesData?.files?.map((file, index) => {
    const {
      key,
      src,
      fileName,
      userName,
      metadata,
      extension,
      createdDate,
      attachmentId,
      count: attachmentUsageCount,
    } = file;

    return (
      <Thumbnail
        key={key}
        index={index}
        fileUrl={src}
        username={userName}
        metadata={metadata}
        extension={extension}
        attachmentId={attachmentId}
        extendedFileName={fileName}
        attachmentUsageCount={attachmentUsageCount}
        isItemChecked={Boolean(selectedItems?.[key])}
        onCheckHandler={onCheckHandler}
        onDisplayPreview={handleDisplayPreview}
        onAttachmentDeleted={handleDeleteAttachment}
        timestamp={getDate(timezone, dateFormat, createdDate)}
      />
    );
  });

  const addAttachmentsHandler = () => {
    const newSelectedItems = selectedItems?.filter((item) => item);

    const newValue = {
      list: 'attachments',
      operation: 'overwrite',
      listItem: newSelectedItems,
    };

    dispatch(updateActionLineData(newValue));
    setIsOpen(false);
  };

  const handleCancelBtnClick = () => {
    setIsOpen(false);
  };

  return {
    onSearchClear,
    onSearchChange,
    setDisplayPreview,
    handleImageRemove,
    handleCancelBtnClick,
    addAttachmentsHandler,

    thumbnails,
    searchString,
    displayPreview,
    selectedFileIndex,
    isSomethingSelected,
    filesData: filesData?.files,
    fileTypes: filesData?.extensions,
  };
};
