import { styled } from '@mui/material/styles';

export const StyledFloatingTicketAttachment = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '12px',
    padding: '20px 40px 20px 52px',
    '> div': {
      width: '102px',
      '.attachment-uploader-thumbnail, video': {
        height: '72px',
        img: {
          width: '100%',
        },
      },
      '.attach-fileUrl-text': {
        fontSize: theme.fontSize.small,
      },
    },
}));
