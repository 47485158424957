import { useState, useMemo, memo } from 'react';
import { ReactComponent as Settings } from 'images/icons/settings.svg';
import { StyledMenuButton } from 'common/components/dropDownMenu/StyledDropDownMenu';
import { ColumnsConfigurationBox } from './styledDrawer';
import ColumnsConfiguration from './ColumnsConfiguration';
import { ConfigOpenContext } from './context';

const ColumnsConfigurationComponent = () => {
  const [open, setOpen] = useState(false);
  const value = useMemo(() => ({ open, setOpen }), [open]);
  return (
    <ColumnsConfigurationBox>
      <div className="openDrawer">
        <StyledMenuButton disableRipple onClick={() => setOpen(true)}>
          <Settings />
        </StyledMenuButton>
      </div>
      <ConfigOpenContext.Provider value={value}>
        {useMemo(
          () => (
            <ColumnsConfiguration />
          ),
          [],
        )}
      </ConfigOpenContext.Provider>
    </ColumnsConfigurationBox>
  );
};

export default memo(ColumnsConfigurationComponent);
