import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectFieldSrList } from 'store/fieldValidationsSlice';

function useValidation() {
  const fieldSrList = useSelector(selectFieldSrList);
  const checkRequiredFieldsValidation = useCallback(
    (srId = 'new', skip) => {
      if (!skip) {
        skip = [];
      }
      const fieldSr = fieldSrList?.[srId];
      let isValidStatus = true;
      if (fieldSr) {
        const fieldNameList = Object.keys(fieldSr);
        isValidStatus = fieldNameList.every((fieldName) => {
          if (fieldSr[fieldName].required && !skip.includes(fieldName)) {
            return fieldSr[fieldName].isValid;
          }
          return true;
        });
      }
      return isValidStatus;
    },
    [fieldSrList],
  );

  return [checkRequiredFieldsValidation];
}

export default useValidation;
