import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledHeader = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginBottom: '8px',
}));

export const StyledWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: '3px',
  width: '100%',
  maxWidth: '100%',
  padding: '4px 0',

  ':first-of-type': {
    padding: '0 0 4px',
  },
}));

export const StyledTitle = styled(Box)(({ theme }) => ({
  color: theme.palette.text.title_txt,
  fontFamily: theme.fonts.robotoBold,
  whiteSpace: 'nowrap',
  wordBreak: 'keep-all',
}));

export const StyledContent = styled(Box)(({ theme }) => ({
  color: theme.palette.text.title_txt,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  width: '100%',
}));

export const StyledSentToChildSrs = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '6px',
  padding: '2px 8px',
  alignItems: 'center',
  backgroundColor: theme.palette.grey.divider_grey_v2,
  span: {
    color: theme.palette.text.title_txt,
    fontSize: '12px',
    lineHeight: '16px',
  },
  '.icon': {
    width: '13px',
    aspectRatio: 'auto',
  },
}));
