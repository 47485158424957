import PropTypes from 'prop-types';
import { StyledFileTypeLogo } from '../../../common/components/thumbnail/style';
import { StyledInactiveAttachmentChip } from './StyledInactiveAttachmentChip';
import Tooltip from '../../../common/components/tooltip';

export const InactiveAttachmentChip = (props) => {
  const {
    icon,
    filename,
    isDeleted,
    attachmentId
  } = props;

  return (
    <>
      <StyledInactiveAttachmentChip
        isDeleted={isDeleted}
        data-cy={`inactive-attachment-${attachmentId}`}
      >
        <StyledFileTypeLogo
          className="attachment-icon"
        >
          <img data-cy="inactive-attachment-icon" alt="" src={icon} />
        </StyledFileTypeLogo>
        <Tooltip
          className="attachment-filename"
          text={filename}
        />
      </StyledInactiveAttachmentChip>
    </>
  );
};

InactiveAttachmentChip.propTypes = {
  icon: PropTypes.object,
  filename: PropTypes.string
}
