import { styled } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export const CustomDateButton = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isCustomSelectOpened',
})(({ theme, isCustomSelectOpened }) => ({
  padding: theme.spacing(1.25, 1),
  boxSizing: 'border-box',
  width: '100%',
  height: theme.spacing(5.25),
  display: 'flex',
  alignItems: 'center',
  justifyContent: isCustomSelectOpened ? 'felx-start' : 'space-between',
  borderRadius: '6px',

  '&:hover': {
    backgroundColor: theme.palette.grey.bt_alt_grey_light,
    cursor: 'pointer',
  },
}));

export const CustomDateButtonTitle = styled('span')(({ theme }) => ({
  color: theme.palette.text.title_txt,
  fontFamily: theme.fonts.robotoRegular,
  fontSize: theme.spacing(1.75),
}));

export const CustomDateContainer = styled('div')(({ theme }) => ({
  bottom: '0',
  left: '0',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(0, 1, 0, 0),
}));

export const StyledArrowIcon = styled(KeyboardArrowRightIcon, {
  shouldForwardProp: (prop) => prop !== 'isCustomSelectOpened',
})(({ theme, isCustomSelectOpened }) => ({
  color: theme.palette.grey.bt_def_grey_reg,
  fontSize: '20px',
  fontFamily: 'Roboto-Light',
  order: isCustomSelectOpened ? -1 : 0,
  transform: isCustomSelectOpened ? `translateX(-${theme.spacing(0.5)}) rotate(-180deg)` : 'none',
  opacity: '0.8',
}));
