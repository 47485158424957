import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeUploads: [],
};

export const attachmentsSlice = createSlice({
  name: 'attachments',
  initialState,
  reducers: {
    addUpload: (state, action) => {
      state.activeUploads.push(action.payload);
    },
    removeUpload: (state, action) => {
      state.activeUploads = state.activeUploads.filter((upload) => upload.id !== action.payload.id);
    },
    removeUploads: (state, action) => {
      state.activeUploads = state.activeUploads.filter((upload) => !action.payload.ids.includes(upload.id));
    },
    updateUpload: (state, action) => {
      const index = state.activeUploads.findIndex((upload) => upload.id === action.payload.id);
      state.activeUploads[index] = { ...state.activeUploads[index], ...action.payload };
    },
    setActiveUploads: (state, { payload: activeUploads }) => {
      state.activeUploads = activeUploads;
    },
    clearFailedUploads: (state) => {
      state.activeUploads = state.activeUploads.filter((upload) => !upload.error);
    },
    clearActiveUploads: () => initialState,
  },
});

export const { addUpload, removeUpload, removeUploads, updateUpload, clearFailedUploads, clearActiveUploads } =
  attachmentsSlice.actions;

export const selectActiveUploads = (allState) => allState.attachments.activeUploads;

export default attachmentsSlice.reducer;
export const attachmentsSliceName = attachmentsSlice.name;
