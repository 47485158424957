import PropTypes from 'prop-types';

import { InputLabel } from '@mui/material';
import { FileInput } from '../fileInput';

export function FileInputLabel({
  inputBtnComponent: InputBtn,
  btnProps,
  allowedTypes,
  handleChange,
  handleClick,
  ...restProps
}) {
  return (
    <InputLabel {...restProps}>
      <FileInput handleChange={handleChange} handleClick={handleClick} allowedTypes={allowedTypes} />
      <InputBtn {...btnProps} />
    </InputLabel>
  );
}

FileInputLabel.propTypes = {
  inputBtnComponent: PropTypes.elementType,
  btnProps: PropTypes.object,
  allowedTypes: PropTypes.array,
  handleChange: PropTypes.func,
  handleClick: PropTypes.func,
};

FileInputLabel.defaultProps = {
  btnProps: {},
  handleChange: () => {},
  handleClick: () => {},
};
