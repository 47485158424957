import { styled } from '@mui/material/styles';
import { ReactComponent as LockIcon } from 'images/icons/checkboxLock.svg';
import { Box } from '@mui/system';

export const StyledLockIcon = styled(LockIcon)(() => ({
  color: 'white',
  background: '#7D899D',
  padding: '4px',
  borderRadius: '3px',
  width: '16px',
  height: 'auto',
}));

export const StyledTooltipTitle = styled(Box)(({ theme }) => ({
  span: {
    color: theme.palette.generic.sysaid_mid,
  },
}));
