import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import useTexts from 'features/header/useTexts';
import { useTicketLockStatus } from 'remote-state/ticketServiceHooks';
import { selectActiveUser } from 'store/userSlice';
import { StyledLockIcon, StyledTooltipTitle } from './style';

export const useStylesBootstrap = makeStyles(() => ({
  tooltip: {
    padding: '12px',
    borderRadius: '10px',
    backgroundColor: '#494949',
    fontFamily: 'Roboto-Regular',
    fontSize: '12px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.67',
    letterSpacing: 'normal',
    color: '#fff',
    marginLeft: '-6px',
  },
  arrow: {
    color: '#494949',
    marginLeft: '6px',
  },
}));

const CustomSelectBox = ({ data = [] }) => {
  const srId = data && data[0]?.value;
  const userAccount = useSelector(selectActiveUser);
  const { data: lockingDetails } = useTicketLockStatus(srId);
  const { lockedByText } = useTexts(srId);
  let isLocked;
  let lockingUser;
  if (lockingDetails) {
    isLocked = lockingDetails.isLocked;
    lockingUser = lockingDetails.lockingUser;
  }
  const currentUserName = userAccount?.username;
  const tooltipClass = useStylesBootstrap();
  const notLockedByCurrUser = currentUserName !== lockingUser;

  //TODO: @Performance: Fix this dangerous event based memory leak.
  useEffect(() => {
    const agRowEl = document.querySelectorAll(`.ag-body-viewport .ag-row[row-id="${srId}"]`);
    const agPinnedRowEl = document.querySelector(`.ag-pinned-left-cols-container .ag-row[row-id="${srId}"]`);
    if (agRowEl && isLocked && notLockedByCurrUser) {
      agRowEl.forEach((item) => {
        item?.addEventListener('mouseover', () => {
          agPinnedRowEl.classList.add('ag-selection-checkbox-hide');
        });
        item?.addEventListener('mouseleave', () => {
          agPinnedRowEl.classList.remove('ag-selection-checkbox-hide');
        });
      });
    }
  }, [srId, isLocked, notLockedByCurrUser]);

  return (
    <>
      {isLocked && notLockedByCurrUser && (
        <Tooltip
          placement="top-start"
          title={
            <StyledTooltipTitle>
              {lockedByText} <span>{lockingUser}</span>
            </StyledTooltipTitle>
          }
          arrow
          classes={tooltipClass}
        >
          <StyledLockIcon />
        </Tooltip>
      )}
    </>
  );
};
export default CustomSelectBox;
