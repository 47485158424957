import { styled } from '@mui/material/styles';
import { Paper } from '@mui/material';

const StyledNavSubList = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'columnCount',
})(({ theme, columnCount }) => ({
  boxShadow: `0px 2px 8px ${theme.palette.background.boxShadow}`,
  borderRadius: '4px',
  boxSizing: 'border-box',
  padding: '6px 0',
  width: columnCount === 2 ? '502px' : '224px',
  height: 'fit-content',
  maxHeight: 'calc(100vh - 185px)',
  overflowY: 'auto',
  ...theme.customScrollbar,
  '&::-webkit-scrollbar': {
    width: '18px',
    maxHeight: '120px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey.scroll,
    border: `7px solid ${theme.palette.generic.primary}`,
  },
  backgroundColor: theme.palette.generic.primary,
  a: {
    textDecoration: 'none',
  },

  '& .submenu': {
    padding: 0,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },

  '& .sublist': {
    padding: '6px 0',
    maxWidth: '224px',
    breakInside: 'avoid',
  },
}));

export default StyledNavSubList;
