import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTextFieldIgnoreProps = [
  'isNewField',
  'multiline',
  'invalid',
  'readOnly',
  'value',
  'dropdownType',
  'disabled',
];

const commonStyles = (theme, invalid, readOnly) => ({
  fontFamily: theme?.fonts?.robotoRegular,
  fontSize: '0.875rem',
  color: invalid ? theme?.palette?.labels?.bt_red_reg : theme?.palette?.text?.txt_default,
  ':hover': {
    color: invalid && !readOnly ? '#AB2727' : theme?.palette?.text?.txt_default,
  },
});

const handleBackgroundColor = (theme, invalid, isNewField, readOnly) => {
  if (invalid) return theme?.palette?.labels?.bt_red_light;
  return isNewField && !readOnly ? theme?.palette?.background?.bg_new_input_text : 'transparent';
};

const getInputCursorType = (readOnly, dropdownType) => {
  if (readOnly) return 'default';
  if (['select', 'date'].includes(dropdownType)) return 'pointer';
};

const handleHoverBackgroundColor = (readOnly, theme, invalid) => {
  if (readOnly) return null;
  return invalid ? theme?.palette?.labels?.bt_red_mid : theme?.palette?.background?.bg_hover_main;
};

export const StyledTextField = styled(Box, {
  shouldForwardProp: (prop) => !StyledTextFieldIgnoreProps.includes(prop),
})(({ theme, isNewField, multiline, invalid, readOnly, value, dropdownType }) => ({
  display: 'flex',

  '.MuiFormControl-root': {
    gap: '4px',
    wordBreak: 'break-word',
  },
  '.MuiInputBase-root': {
    height: multiline ? 'auto' : '28px',
    width: !multiline && '224px',
    backgroundColor: handleBackgroundColor(theme, invalid, isNewField, readOnly),
    borderRadius: '4px',
    boxSizing: 'border-box',
    border: '1px solid',
    borderColor: 'transparent',
    transition: 'border-color 0.2s ease, background-color 0.4s ease, border-radius 0.4s ease',
    pointerEvents: readOnly ? 'none' : 'auto',
    '&:focus-within': {
      borderColor: dropdownType ? 'none' : theme?.palette?.grey?.divider_grey_v3,
      borderRadius: dropdownType ? 'none' : theme?.shape?.border?.borderRadius,
    },
    '&:hover:not(:focus-within)': {
      borderRadius: '4px',
      background: handleHoverBackgroundColor(readOnly, theme, invalid),
      '.error-icon': {
        filter:
          'brightness(0) saturate(100%) invert(19%) sepia(73%) saturate(2446%) hue-rotate(342deg) brightness(93%) contrast(92%)',
      },
      '.input-icon': {
        display: 'flex',
        filter: invalid
          ? 'brightness(0) saturate(100%) invert(19%) sepia(73%) saturate(2446%) hue-rotate(342deg) brightness(93%) contrast(92%)'
          : 'none',
      },
    },
  },
  '& .MuiInputBase-input::placeholder': {
    color: invalid ? theme?.palette?.labels?.bt_red_reg : theme?.palette?.text?.title_txt,
    opacity: 1,
  },
  '& .MuiInputBase-input:hover::placeholder': {
    color: invalid ? '#AB2727' : theme?.palette?.text.title_txt,
    opacity: 1,
  },
  '.MuiInputAdornment-root': {
    height: '100%',
    cursor: dropdownType ? 'pointer' : 'text',
    padding: '6px',
    gap: '7px',
    marginLeft: 0,
  },
  '.MuiTypography-root': {
    display: 'flex',
  },
  '.Mui-disabled:not(.MuiFormLabel-root)': {
    pointerEvents: 'none',
    WebkitTextFillColor: theme.palette.text.txt_default,
    color: theme.palette.text.txt_default,
  },
  input: {
    padding: '4px 8px',
    cursor: getInputCursorType(readOnly, dropdownType),
    ...(['date', 'select'].includes(dropdownType) && {
      MozUserSelect: 'none',
      WebkitUserSelect: 'none',
      MsUserSelect: 'none',
      userSelect: 'none',
    }),
    textOverflow: 'ellipsis',
    ...commonStyles(theme, invalid, readOnly),
  },
  textArea: {
    padding: '6px 8px',
    ...commonStyles(theme, invalid, readOnly),
  },

  '.MuiFormLabel-root': {
    fontSize: '1rem',
    color: theme?.palette?.text?.secondary_txt,
    fontFamily: theme?.fonts?.robotoRegular,
    marginLeft: '8px',
  },
  '.Mui-focused': {
    color: `${theme?.palette?.text?.secondary_txt} !important`,
  },
  '.MuiFormLabel-asterisk': {
    color: theme?.palette?.labels?.bt_red_reg,
  },

  // removes the arrows displayed for number input fields - all browsers
  'input::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
  },
  'input::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
  },
  'input[type=number]': {
    MozAppearance: 'textfield',
  },

  '.input-icon': {
    display: value || isNewField ? 'none' : 'flex',
    filter: invalid
      ? 'brightness(0) saturate(100%) invert(47%) sepia(11%) saturate(2428%) hue-rotate(314deg) brightness(114%) contrast(99%)'
      : 'none',
  },

  scrollbarColor: `${theme?.palette?.hover?.avatar}`,
  scrollbarWidth: 'thin',
  '*::-webkit-scrollbar': {
    width: '4px',
  },
  '*::-webkit-scrollbar-thumb': {
    borderRadius: '10px',
    background: theme?.palette?.hover?.avatar,
  },
}));
