import { useQuery } from '@tanstack/react-query';
import { expandQueryKey } from 'common/utils/utils';
import { getHistoryListBySrId } from 'services/ticketMigrationService';

export function useTicketHistory({ srId, historyCutoffDate, selectFn = null }) {
  const queryKey = expandQueryKey(['ticketHistory', srId]);

  const { data, ...rest } = useQuery(queryKey, () => getHistoryListBySrId({ srId, historyCutoffDate }), {
    placeholderData: [],
    select: selectFn || null,
  });

  const sortedData = data
    ? [...data].sort((a, b) => {
        if (a.order < b.order) {
          return -1;
        }
        if (a.order > b.order) {
          return 1;
        }
        return 0;
      })
    : null;

  return {
    data: sortedData,
    ...rest,
  };
}
