import { createSlice, current } from '@reduxjs/toolkit';
import { isEqual, keyBy } from 'lodash-es';
import { QUEUE } from './constants';
import { GRID_MODELS, ID_FIELD_ID } from './grid/constants';
/*
The grid uses an internal filter and sort models.
The grid sorting and filtering, can be controlled both from within the grid
(by pressing sort or filter) and by the grid configuration header (QF toolbar & assignee).

To sync the header configuration with the internal grid model, we are using external state.

The sorts and filters set by the configuration header can not be modified outside of that header.
Only additional sorts and filters, can be added from inside the grid.

fullSortModel = gridConfigurationSortModel (not editable) + customSortModel (editable)
fullFilterModel = gridConfigurationFilterModel (not editable) + customFilterModel (editable)
*/

function compareArrays(arr1, arr2, key, valueKey) {
  const arr2Map = keyBy(arr2, key);
  for (const obj1 of arr1) {
    const obj2 = arr2Map[obj1[key]];
    if (!obj2) {
      return true;
    }

    if (!isEqual(obj1[valueKey], obj2[valueKey])) {
      return true;
    }
  }

  const arr1Map = keyBy(arr1, key);
  for (const obj2 of arr2) {
    if (!arr1Map[obj2[key]]) {
      return true;
    }
  }
  return false;
}

const isViewChanged = (activeView, defaultView) => {
  const activeFilterModel = activeView?.columnsConfig?.customFilterModel || [];
  const defaultFilterModel = defaultView?.columnsConfig?.customFilterModel || [];

  // Check if the lengths of the customFilterModel arrays are different
  if (activeFilterModel.length !== defaultFilterModel.length) {
    return true;
  }

  // Check if the rest of the view configurations are different
  const activeViewCopy = {
    ...activeView,
    columnsConfig: { ...activeView.columnsConfig, customFilterModel: null },
  };

  const defaultViewCopy = {
    ...defaultView,
    columnsConfig: { ...defaultView.columnsConfig, customFilterModel: null },
  };

  if (!isEqual(activeViewCopy, defaultViewCopy)) {
    return true;
  }

  // Check if the customFilterModel arrays are different
  if (compareArrays(activeFilterModel, defaultFilterModel, 'fieldId', 'values')) {
    return true;
  }

  return false;
};

const defaultState = {
  gridConfiguration: {
    quickFilter: QUEUE.defaultQuickFilter,
  },
  selectedTickets: [],
  activeView: {
    columnsConfig: { columnsOrder: [], customFilterModel: [], customSortModel: [], isQuickFilterApplied: false },
  },
  defaultView: {
    columnsConfig: { columnsOrder: [], customFilterModel: [], customSortModel: [], isQuickFilterApplied: false },
  },
  searchText: '',
  isUnassigned: false,
  activeSR: null,
  showErrorModal: false,
  isViewEdited: false,
  isTicketInvalid: {},
  redirectURL: '',
  srIdToRefreshQueue: '',
  gridMode: GRID_MODELS.Realtime,
  searchSrParams: null,
  prefetchSrParams: null,
  multiSort: false,
};

export const initialState = {
  gridConfiguration: defaultState.gridConfiguration,
  //sorts that were added from inside the grid
  defaultColumnsOrder: [],
  //filters added from inside the grid
  rememberRow: { rowId: null, rowIndex: null },
  filterEditor: null,
  isUnassigned: defaultState.isUnassigned,
  activeSR: null,
  showErrorModal: false,
  activeView: defaultState.activeView,
  originalView: {},
  defaultView: defaultState.defaultView,
  isViewEdited: defaultState.isViewEdited,
  redirectURL: '',
  selectedTickets: [],
  gridMode: defaultState.gridMode,
  searchSrParams: defaultState.searchSrParams,
  prefetchSrParams: defaultState.prefetchSrParams,
  multiSort: false,
  searchText: defaultState.searchText,
  selectRenderValue: { srId: null, fieldName: null, fieldValue: null },
};

export const queueSlice = createSlice({
  name: 'queue',
  initialState,

  reducers: {
    setColumnsOrder: (state, { payload }) => {
      state.activeView.columnsConfig.columnsOrder = payload;
      state.isViewEdited = isViewChanged(current(state.activeView), current(state.defaultView));
    },
    setSearchText: (state, { payload }) => {
      state.searchText = payload;
    },
    gridConfigurationChanged: (state, action) => {
      state.gridConfiguration = action.payload;
    },

    customSortModelChanged: (state, { payload }) => {
      state.activeView.columnsConfig.customSortModel = payload;
      state.isViewEdited = isViewChanged(current(state.activeView), current(state.defaultView));
    },
    changeAllColumnFilter: (state, { payload }) => {
      state.activeView.columnsConfig.customFilterModel = payload;
    },
    addColumnFilter: (state, { payload }) => {
      const filters = state.activeView.columnsConfig.customFilterModel.filter(
        (filter) => filter.field !== payload.field,
      );
      const isQuickFilter = payload?.isQuickFilter;
      state.activeView.columnsConfig.customFilterModel = [...filters, payload.filter];
      const newFilters = [...filters, payload.filter];

      const isOnlyAssignee = newFilters?.length === 1 && newFilters?.find((filter) => filter.field === 'assignee');

      if (isOnlyAssignee) {
        state.gridConfiguration.quickFilter = {
          quickFilterDisplayName: 'All',
          quickFilterName: 'all',
        };
      } else {
        state.gridConfiguration.quickFilter = QUEUE.deselectedQuickFilter;
      }

      if (isQuickFilter) {
        state.activeView.columnsConfig.isQuickFilterApplied = isQuickFilter;
      }
      state.isViewEdited = isViewChanged(current(state.activeView), current(state.defaultView));
    },

    removeColumnFilter: (state, { payload }) => {
      const newFilterModel = state.activeView.columnsConfig.customFilterModel.filter(
        (filter) => filter.field !== payload.field,
      );
      state.activeView.columnsConfig.customFilterModel = newFilterModel;
      const isQuickFilter = payload?.isQuickFilter;
      const isOnlyAssignee =
        newFilterModel?.length === 1 && newFilterModel?.find((filter) => filter.field === 'assignee');

      if (isOnlyAssignee) {
        state.gridConfiguration.quickFilter = {
          quickFilterDisplayName: 'All',
          quickFilterName: 'all',
        };
      }

      if (isQuickFilter) {
        state.activeView.columnsConfig.isQuickFilterApplied = false;
      }

      state.isViewEdited = isViewChanged(current(state.activeView), current(state.defaultView));
    },

    removeColumnFilters: (state) => {
      state.activeView.columnsConfig.customFilterModel = state.activeView.columnsConfig.customFilterModel.filter(
        (filter) => filter.field === 'assignee',
      );
    },

    clearAssigneeFilter: (state) => {
      state.activeView.columnsConfig.customFilterModel = state.activeView.columnsConfig.customFilterModel.filter(
        (filter) => filter.field !== 'assignee' && filter.field !== 'assignedGroup',
      );
    },

    clearAllFilters: (state) => {
      state.activeView.columnsConfig.customFilterModel = [];
      state.activeView.columnsConfig.isQuickFilterApplied = false;
      state.isViewEdited = isViewChanged(current(state.activeView), current(state.defaultView));
    },

    clearSortsAndFilters: (state) => {
      const newState = {
        ...state,
        isViewEdited: true,
        gridConfiguration: { quickFilter: QUEUE.deselectedQuickFilter },
      };

      if (state.activeView?.columnsConfig) {
        state.activeView.columnsConfig.customFilterModel = [];
        state.activeView.columnsConfig.customSortModel = [];
      }
      newState.isViewEdited = isViewChanged(current(newState.activeView), current(newState.defaultView));

      state = newState;
    },

    setIsUnassigned: (state, action) => {
      state.isUnassigned = action.payload;
    },

    rememberRowChanged: (state, action) => {
      state.rememberRow = action.payload;
    },

    openFilterEditor: (state, action) => {
      state.filterEditor = action.payload;
    },

    closeFilterEditor: (state) => {
      state.filterEditor = null;
    },

    setActiveSr: (state, action) => {
      state.activeSR = action.payload;
    },

    setShowErrorModal: (state, action) => {
      state.showErrorModal = action.payload;
    },

    setRedirectURL: (state, action) => {
      state.redirectURL = action.payload;
    },

    setActiveView: (state, { payload }) => {
      const filters = payload?.columnsConfig?.customFilterModel.map((element) => {
        if (element.field === 'id') {
          return {
            ...element,
            fieldId: ID_FIELD_ID,
            displayKeys: element.values,
          };
        }
        return {
          ...element,
          fieldId: payload?.columnsConfig?.columnsOrder.find((column) => column.fieldName === element.field)?.id,
        };
      });

      let viewData = payload;

      if (payload?.columnsConfig) {
        viewData = {
          ...payload,
          columnsConfig: {
            ...payload.columnsConfig,
            isQuickFilterApplied: payload.columnsConfig.isQuickFilterApplied || false,
            customFilterModel: filters || [],
          },
        };
      }

      if (payload?.default) {
        state.defaultView = viewData;
      }
      state.isViewEdited = false;
      state.activeView = viewData;
      state.originalView = viewData;
    },

    setDefaultView: (state, { payload }) => {
      state.defaultView = payload;
    },

    setOriginalView: (state, { payload }) => {
      state.originalView = payload;
    },

    resetActiveView: (state) => {
      //TODO: Refactor and use origin state with persistor
      const originalView = state.originalView;

      state.isViewEdited = false;
      state.activeView = originalView;
    },

    setHoveredSR: (state, action) => {
      state.hoveredSR = action.payload;
    },
    setSelectedTickets: (state, action) => {
      state.selectedTickets = action.payload;
    },
    updateSelectedTicket: (state, action) => {
      state.selectedTickets.push(action.payload);
    },
    removeSelectedTicket: (state, action) => {
      if (action.payload) {
        state.selectedTickets = state.selectedTickets.filter((rowSelected) => rowSelected.id !== action.payload);
      } else {
        state.selectedTickets = [];
      }
    },
    editSelectedTicket: (state, action) => {
      const { id, data } = action.payload;
      const index = state.selectedTickets.findIndex((ticketId) => ticketId.id === id);

      if (index !== -1) {
        state.selectedTickets[index] = { id, ...data };
      }
    },
    setSrIdToRefreshQueue: (state, action) => {
      state.srIdToRefreshQueue = action.payload;
    },
    setGridMode: (state, action) => {
      state.gridMode = action.payload;
    },
    setSearchSrParams: (state, action) => {
      state.searchSrParams = action.payload;
    },
    setPrefetchSrParams: (state, action) => {
      state.prefetchSrParams = action.payload;
    },
    setMultiSort: (state, action) => {
      state.multiSort = action.payload;
    },
    setSelectRenderValue: (state, action) => {
      state.selectRenderValue = action.payload;
    },
  },
});

export const selectFullSortModel = (allSlicesState) => {
  const customSortModel = allSlicesState?.queue?.activeView?.columnsConfig?.customSortModel;

  return customSortModel?.length ? customSortModel : [QUEUE.initialSortColumn];
};

export const selectFilterModel = (allSlicesState) => allSlicesState.queue.activeView?.columnsConfig?.customFilterModel;

export const selectNotQuickFiltersModel = (allSlicesState) =>
  selectFilterModel(allSlicesState)?.filter((filter) => !filter.isQuickFilter);

export const selectRememberRow = (allSlicesState) => allSlicesState.queue.rememberRow;

export const selectIsUnassigned = (allSlicesState) => allSlicesState.queue.isUnassigned;

export const selectActiveSR = (allSlicesState) => allSlicesState.queue.activeSR;

export const selectShowErrorModal = (allSlicesState) => allSlicesState.queue.showErrorModal;

export const selectRedirectURL = (allSlicesState) => allSlicesState.queue.redirectURL;

export const selectIsQuickFilterApplied = (allSlicesState) =>
  allSlicesState.queue.activeView?.columnsConfig?.isQuickFilterApplied;

export const selectColumnsOrder = (allSlicesState) => allSlicesState.queue.activeView?.columnsConfig?.columnsOrder;

export const selectColumnsDefaultOrder = (allSlicesState) => allSlicesState.queue.defaultColumnsOrder;

export const selectedQueueTicket = (allSlicesState) => allSlicesState.queue.selectedTickets;

export const selecteSrIdToRefreshQueue = (allSlicesState) => allSlicesState.queue.srIdToRefreshQueue;

export const selectActiveView = (state) => state.queue.activeView;

export const selectDefaultView = (state) => state.queue.defaultView;

export const selectOriginalView = (state) => state.queue.originalView;

export const selectSearchText = (state) => state.queue.searchText;

export const selectIsViewsEdited = (allSlicesState) => allSlicesState.queue.isViewEdited;

export const selectGridMode = (state) => state.queue.gridMode ?? defaultState.gridMode;

export const selectSearchSrParams = (allSlicesState) => allSlicesState.queue.searchSrParams;
export const selectPrefetchSrParams = (allSlicesState) => allSlicesState.queue.prefetchSrParams;

export const selectMultiSort = (allSlicesState) => allSlicesState.queue.multiSort;

export const selectSelectRenderValue = (allSlicesState) => allSlicesState.queue.selectRenderValue;

export const {
  setActiveSr,
  setHoveredSR,
  setActiveView,
  setDefaultView,
  setOriginalView,
  setRedirectURL,
  resetActiveView,
  clearAllFilters,
  setIsUnassigned,
  addColumnFilter,
  openFilterEditor,
  setShowErrorModal,
  closeFilterEditor,
  removeColumnFilter,
  rememberRowChanged,
  removeColumnFilters,
  clearAssigneeFilter,
  clearSortsAndFilters,
  setAllModeQuickFilter,
  customSortModelChanged,
  setIsQuickFilterApplied,
  gridConfigurationChanged,
  setSelectedTickets,
  updateSelectedTicket,
  removeSelectedTicket,
  setSrIdToRefreshQueue,
  setColumnsOrder,
  changeAllColumnFilter,
  setSrTypeFilterCounters,
  setGridMode,
  setSearchSrParams,
  setPrefetchSrParams,
  setMultiSort,
  setSearchText,
  setSelectRenderValue,
} = queueSlice.actions;

export default queueSlice.reducer;
export const queueSliceName = queueSlice.name;
