import { SelectItem } from 'common/components/dropdownSelect/SelectItem';
import { CONSTANTS } from 'common/components/dropdownSelect/constants';
import { SuggestedCategory } from '.';
import { ChooseCategoryItem } from './chooseCategoryItem';

export const getItemSizeForSuggestedCategoryCreator =
  ({ isThirdLevelCategoryEnabled }) =>
  (index) => {
    let itemSize = CONSTANTS.DROPDOWN_ITEM_HEIGHT;
    if (index === 0) {
      itemSize = isThirdLevelCategoryEnabled
        ? CONSTANTS.SUGGESTED_CATEGORIES_CARD_HEIGHT
        : CONSTANTS.SUGGESTED_CATEGORIES_CARD_WITHOUT_THIRD_LEVEL_HEGIHT;
    } else if (index === 1) {
      itemSize = CONSTANTS.CHOOSE_CATEGORY_HEIGHT;
    }

    return itemSize;
  };

export const rowFnForSuggestedCategoryCreator =
  ({ srId, indexOffset, suggestedCategory, selectAiSuggestedCategories }) =>
  ({ ids, index, style }) => {
    let rowComponent = (
      <div key={index} style={{ ...style, paddingRight: '12px' }}>
        <SelectItem id={ids[index - indexOffset]} />
      </div>
    );

    if (index === 0) {
      rowComponent = (
        <SuggestedCategory
          srId={srId}
          suggestedCategory={suggestedCategory}
          selectAiSuggestedCategories={selectAiSuggestedCategories}
          style={style}
        />
      );
    } else if (index === 1) {
      rowComponent = <ChooseCategoryItem key={index} style={style} />;
    }

    return rowComponent;
  };
