import useTexts from '../useTexts';
import { StyledDefaultText } from './StyledTemplatePropertiesPanel';

export default function DefaultPropertiesPanel({ isDefaultText }) {
  const { propertiesPanelDefaultText, propertiesPanelSpecialFieldText } = useTexts();

  return (
    <StyledDefaultText>
      {isDefaultText ? propertiesPanelDefaultText : propertiesPanelSpecialFieldText}
    </StyledDefaultText>
  );
}
