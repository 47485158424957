import { useCallback, useMemo, useRef } from 'react';
import { DropdownSelect, itemClassNames, useDropdownControl } from 'common/components/dropdownSelect';
import { groupBy } from 'lodash-es';
import { useSelector } from 'react-redux';
import { ReactComponent as ArrowDownIcon } from 'images/icons/arrowdown.svg';
import Tooltip from 'common/components/tooltip';
import { searchViews } from 'services/queueViewsService';
import DefaultViewIcon from 'common/components/queueViews/components/defaultView/defaultView';
import { useQueueViews } from 'remote-state/queueViewsHooks';
import { selectDefaultView, selectActiveView } from 'features/queue/slice';
import { PERFORMANCE_MEASURE } from 'constants/performanceMeasure';
import { createPerformanceMarkWithData } from 'common/utils/performanceManager';
import { StyledGridDropDownMenu, StyledGridDropDownMenuWrapper, StyledTitleMenu } from './style';
import useTexts from '../../useTexts';

export const ViewList = (props) => {
  const menuRef = useRef(null);
  const { onChange, isViewEdited } = props;
  const { queueViews, privateStr, publicStr } = useTexts();
  const { anchor, handleOpen, handleClose } = useDropdownControl();
  const defaultView = useSelector(selectDefaultView);
  const activeView = useSelector(selectActiveView);
  const views = useQueueViews();
  const viewsList = views?.data;

  const { PRIVATE: privateViews, PUBLIC: publicViews } = useMemo(() => groupBy(viewsList, 'visibility'), [viewsList]);

  const viewClassesNames = useMemo(
    () =>
      (publicViews &&
        privateViews && {
          0: publicStr,
          1: privateStr,
        }) ||
      (publicViews &&
        !privateViews && {
          0: publicStr,
        }) ||
      (!publicViews &&
        privateViews && {
          0: privateStr,
        }) ||
      undefined,
    [publicViews, privateViews, publicStr, privateStr],
  );

  const handleChange = useCallback(
    (value) => {
      if (onChange) {
        createPerformanceMarkWithData(PERFORMANCE_MEASURE.QUEUE.VIEW_CHANGE, {
          viewId: value?.id,
          viewName: value?.name,
        });
        onChange(value);
      }
      handleClose();
    },
    [onChange, handleClose],
  );

  const onOpen = () => {
    handleOpen({ target: menuRef?.current });
  };

  const selection = useMemo(() => (activeView?.id || activeView?.id === 0 ? [activeView?.id] : []), [activeView?.id]);

  const backendQueryConfig = useMemo(
    () => ({
      fetchingPromise: (query) => searchViews(query),
    }),
    [],
  );

  return (
    <StyledGridDropDownMenu isViewEdited={isViewEdited}>
      <StyledGridDropDownMenuWrapper>
        {activeView?.default && <DefaultViewIcon data-testid="defaultViewIcon" />}
        <StyledTitleMenu ref={menuRef} onClick={onOpen} className={anchor ? 'select-view-open' : null}>
          <Tooltip
            isTruncatedText
            title={activeView?.name}
            text={<span>{activeView?.name}</span>}
            data-testid="queue-view-title-tooltip"
          />
          <ArrowDownIcon />
        </StyledTitleMenu>
      </StyledGridDropDownMenuWrapper>
      {viewClassesNames && (
        <DropdownSelect
          isWideDropdown
          title={queueViews}
          options={[publicViews, privateViews].filter((item) => item)}
          showHomeIcon={defaultView?.id}
          handleChange={handleChange}
          handleClose={handleClose}
          selection={{ valueClasses: [], values: selection }}
          stylingVariant={itemClassNames.view}
          anchor={anchor}
          keyString="id"
          captionString="name"
          sortBy="modifiedDate"
          sortType="desc"
          backendQueryConfig={backendQueryConfig}
          hasHierarchy
          isMultiple={false}
          isGroupedHierarchy
          valueClassesNames={viewClassesNames}
        />
      )}
    </StyledGridDropDownMenu>
  );
};
