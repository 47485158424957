import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    resolveIncident: texts?.['spaces.resolutionPanel.journey.solutionResolution.resolveTheIncident'],
    resolve: texts?.['spaces.resolutionPanel.journey.solutionResolution.resolve'],
    addResolution: texts?.['spaces.resolutionPanel.journey.solutionResolution.addResolution'],
    solution: texts?.['spaces.resolutionPanel.journey.solutionResolution.solution'],
    resolution: texts?.['spaces.resolutionPanel.journey.solutionResolution.resolution'],
    resolutionStatus: texts?.['spaces.resolutionPanel.journey.solutionResolution.resolutionStatus'],
    closureInformation: texts?.['spaces.resolutionPanel.journey.solutionResolution.closureInformation'],
    assignToMe: texts?.['spaces.resolutionPanel.journey.solutionResolution.assignToMe'],
  };

  return componentTexts;
}
