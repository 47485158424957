import { useCallback } from 'react';
import { FixedSizeList } from 'react-window';
import { useSelector } from 'react-redux';

import { selectActiveUser, details } from 'store/userSlice';
import { AssigneeItem } from 'common/components/assigneeItem';
import CustomScrollBar from 'common/components/customScroll';
import { ReactComponent as RightChevron } from 'images/icons/sysaidLibrary/right-chevron.svg';
import useTexts from 'common/components/assignee/useTexts';

import { ASSIGNEE_CONSTANTS } from '../../constants';
import { StyledMenuItem } from '../style';

export const List = ({
  type,
  dropdownMaxHeight,
  dropdownDisplayedItemsCount,
  customScrollProps,
  assigneeItemHeight,
  options,
  currentAssigned,
  handleSelect,
  dropdownWidth,
  isRequired,
  searchText,
}) => {
  const userAccount = useSelector(selectActiveUser);
  const userDetails = useSelector(details);
  const { unassignedText, loginUserText, getColumnText } = useTexts();

  const handleAssigneeClick = useCallback(
    (assignee) => {
      handleSelect({ [type]: assignee });
    },
    [handleSelect, type],
  );

  const checkSelected = (assignee) => {
    if (assignee === currentAssigned?.user?.calculatedUserName && !currentAssigned?.group) return true;
    if (assignee === currentAssigned?.group?.groupName) return true;
  };

  const renderUnassignedOption = () => {
    if (isRequired || searchText) return;
    return (
      <StyledMenuItem
        itemHeight={assigneeItemHeight}
        key={ASSIGNEE_CONSTANTS.UNASSIGNED.key}
        value={getColumnText(ASSIGNEE_CONSTANTS.UNASSIGNED.title)}
        onClick={() =>
          handleSelect({
            user: ASSIGNEE_CONSTANTS.UNASSIGNED,
          })
        }
        selected={currentAssigned.user === ASSIGNEE_CONSTANTS.UNASSIGNED}
        className="unassigned-option"
      >
        <AssigneeItem
          text={unassignedText}
          type="unassigned"
          isSelected={currentAssigned.user === ASSIGNEE_CONSTANTS.UNASSIGNED}
        />
      </StyledMenuItem>
    );
  };

  const renderLoggedInUserOption = () => {
    const loggedInUserName = userAccount?.calculatedUserName;
    if (loggedInUserName?.includes(searchText)) {
      return (
        <StyledMenuItem
          itemHeight={assigneeItemHeight}
          key={userAccount?.userId}
          value={loggedInUserName}
          onClick={() => handleSelect({ user: userDetails })}
          selected={currentAssigned.user?.calculatedUserName === loggedInUserName}
          className="logged-in-user-option"
        >
          <AssigneeItem
            text={`${loggedInUserName} (${loginUserText})`}
            type={type}
            isSelected={currentAssigned.user?.calculatedUserName === loggedInUserName}
          />
        </StyledMenuItem>
      );
    }
  };

  return (
    <CustomScrollBar autoHeightMax={dropdownMaxHeight} {...customScrollProps}>
      {type === 'user' && renderLoggedInUserOption()}
      {type === 'user' && renderUnassignedOption()}
      <FixedSizeList
        height={assigneeItemHeight * dropdownDisplayedItemsCount}
        itemCount={dropdownDisplayedItemsCount}
        itemSize={assigneeItemHeight}
        width={dropdownWidth}
      >
        {({ index }) => {
          const option = options[index] || {};
          const name = option?.calculatedUserName || option?.groupName;
          return (
            <StyledMenuItem
              key={option?.id}
              itemHeight={assigneeItemHeight}
              onClick={() => handleAssigneeClick(option)}
              selected={checkSelected(name)}
            >
              <AssigneeItem
                type={type}
                text={name}
                profileImage={option?.profileImage}
                isSelected={checkSelected(name)}
              />
              {type === 'group' && <RightChevron />}
            </StyledMenuItem>
          );
        }}
      </FixedSizeList>
    </CustomScrollBar>
  );
};
