import { useEffect, useCallback } from 'react';
import { useRouter } from '@tanstack/react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { selectActiveUser } from 'store/userSlice';
import { isEqual } from 'lodash-es';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useHandleTicketLock } from 'remote-state/ticketServiceHooks';
import { createSchema, fieldTypeComponentsMapper } from 'common/utils/form';
import { StyledActionItemBody, FieldContainer, FieldGrid } from './style';
import useTexts from '../../../hooks/useTexts';

const currentFieldTypes = ['text', 'longtext', 'number', 'float', 'select', 'multiSelect', 'date', 'dateAndTime', 'attachment']; //remove once all fields types are supported

const isValueChanged = (originalValue, newValue) => {
  if ((originalValue === '0' && newValue === 0) || (originalValue === 0 && newValue === '0')) return false;
  if ((originalValue === null && newValue === 0) || (originalValue === 0 && newValue === null)) return true;

  return !isEqual(originalValue, newValue);
};

const tooltipOffset = [-10, -5];
const noOp = () => {};

export default function ActionItemBody({
  fields,
  formValues,
  readOnly,
  actionItemId,
  phaseId,
  isEditable,
  handleSave = noOp,
  handleFormErrors = noOp,
}) {
  const router = useRouter();
  const srId = router.latestLocation.search.id;
  const { requiredFieldText } = useTexts();
  const userAccount = useSelector(selectActiveUser);
  const { mutate: lockSR } = useHandleTicketLock(srId);
  const schema = createSchema(fields, requiredFieldText);
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    values: formValues,
    resetOptions: {
      keepErrors: true,
      keepDirty: true,
      keepTouched: true,
    },
  });
  const {
    formState: { isValid },
    setError,
    clearErrors,
    handleSubmit,
  } = methods;
  useEffect(() => {
    handleFormErrors({ actionItemId, isValid });
  }, [isValid, actionItemId, handleFormErrors]);

  const shouldSaveField = useCallback(
    ({ oldValue, newValue, fieldName }) => {
      try {
        schema.validateSyncAt(fieldName, { [fieldName]: newValue });
      } catch (error) {
        return { shouldSave: true, error: error.message };
      }
      const valueChanged = isValueChanged(oldValue, newValue);
      return { shouldSave: valueChanged, error: null };
    },
    [schema],
  );
  const handleSaveField = useCallback(
    ({ oldValue, newValue, fieldName }) => {
      const { shouldSave, error } = shouldSaveField({ oldValue, newValue, fieldName });
      if (error) {
        setError(fieldName, { type: 'manual', message: error });
        
      }
      clearErrors(fieldName);

      if (shouldSave) {
        handleSave({
          value: newValue,
          actionItemId,
          phaseId,
          fieldName,
        });
      }
    },
    [shouldSaveField, clearErrors, setError, handleSave, actionItemId, phaseId],
  );

  const handleFocus = useCallback(() => {
    lockSR({ username: userAccount?.username, srId });
  }, [lockSR, userAccount?.username, srId]);

  return (
    <FormProvider {...methods} onSubmit={handleSaveField} onFocus={handleFocus}>
      <StyledActionItemBody
        onSubmit={handleSubmit((data) => handleSaveField(data, document.activeElement.name))}
        data-cy="ai-body"
      >
        <Scrollbars style={{ width: '100%', right: '3px' }} autoHeight autoHeightMax={250} thumbSize={50}>
          <FieldContainer>
            <FieldGrid>
              {fields
                ?.filter(
                  (field) =>
                    currentFieldTypes.includes(field.type) &&
                    field.fieldName !== 'assignedTo' &&
                    field.fieldName !== 'assignedGroup',
                ) // remove once all fields types are supported
                ?.map((field) => {
                  const { component: FieldComponent, props } = fieldTypeComponentsMapper({ ...field, actionItemId });
                  return (
                    <FieldComponent
                      key={`${actionItemId}-${field?.fieldName}`}
                      data-testid={`${actionItemId}-${field?.fieldName}`}
                      data-view-only={field.readOnly || readOnly}
                      name={field.fieldName}
                      required={field.required}
                      disabled={field.disabled || !isEditable}
                      label={field.displayName}
                      readOnly={field.readOnly || readOnly}
                      adornmentTooltipOffset={tooltipOffset}
                      {...props}
                    />
                  );
                })}
            </FieldGrid>
          </FieldContainer>
        </Scrollbars>
      </StyledActionItemBody>
    </FormProvider>
  );
}
