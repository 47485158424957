import { styled } from '@mui/material';

export const OptionsContainer = styled('div', {
  shouldForwardProp: (prop) =>
    ![
      'hasResults',
      'hasHierarchy',
      'noDivider',
      'dropdownHeightExtension',
      'hasColumns',
      'limitContainerHeight',
    ].includes(prop),
})(({ hasResults, hasHierarchy, noDivider, theme, dropdownHeightExtension, hasColumns, limitContainerHeight }) => ({
  minHeight: hasResults && '90px',
  overflow: hasResults ? 'auto' : 'hidden',
  overflowX: 'hidden',
  ...(noDivider && { marginTop: '8px' }),
  ...(hasColumns && { padding: '0 16px 0 8px', overflowX: 'hidden', minHeight: '70px', overflowY: 'hidden' }),
  ...(hasColumns && !hasResults && { minHeight: '40px' }),
  '.scrollbars-container': {
    width: hasColumns && 'calc(100% + 4px)',
    minHeight: hasColumns && limitContainerHeight && '175px !important', // 14-inch screens(height =~ 1000px) -> limit results to 4 rows
  },

  '.scroll_wrapper': {
    minHeight: '90px !important',
    maxHeight: `${230 + dropdownHeightExtension}px!important`,
    '>:nth-of-type(1)': {
      width: '100%',
      marginRight: '0!important',
      marginBottom: '0!important',
      minHeight: 'auto!important',
      maxHeight: `${230 + dropdownHeightExtension}px!important`,
      overflow: !hasHierarchy && 'auto!important',
      overflowX: 'hidden!important',
      '@supports not selector(::-webkit-scrollbar)': {
        scrollbarColor: `${theme?.palette?.grey?.divider_grey_v1} transparent`,
        scrollbarWidth: 'thin',
      },

      '::-webkit-scrollbar': {
        width: '12px',
        height: '12px',
        borderRadius: '28px',
      },
      '::-webkit-scrollbar-thumb': {
        minHeight: '80px',
        maxHeight: '50%',
        borderRadius: '28px',
        border: '4px solid #fff',
        background: theme?.palette?.grey?.divider_grey_v1,
      },
      '>:nth-of-type(1)': {
        '@supports not selector(::-webkit-scrollbar)': {
          scrollbarColor: `${theme?.palette?.grey?.divider_grey_v1} transparent`,
          scrollbarWidth: 'thin',
        },
        '::-webkit-scrollbar': {
          width: '4px',
          height: '4px',
        },
        '::-webkit-scrollbar-thumb': {
          borderRadius: '4px',
          background: theme?.palette?.grey?.divider_grey_v1,
        },
      },
    },
    '>:nth-of-type(2)': {
      display: 'none',
    },
    '>:nth-of-type(3)': {
      display: 'none',
    },
    '&.has-suggested-category': {
      maxHeight: '386px !important',
      '>:nth-of-type(1)': {
        maxHeight: '386px !important',
      },
    },
  },
}));

export const Divider = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isDateType',
})(({ theme, isDateType }) => ({
  height: '1px',
  backgroundColor: theme.palette.grey.divider_grey_v1,
  display: 'flex',
  margin: isDateType ? '0' : theme.spacing(1),
}));

export const StyledCircularWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '48px',
  '> span': {
    color: theme.palette.generic.grey_600,
  },
}));
