import { initialDirectionTool, initialResponsiveToolbar } from './callbacks';

export const REPHRASE_TOOLBAR = 'rephraseToolbar';
const BUTTONS_POPUP_TEMPLATE = '[_BUTTONS_]';

const pluginOverloads = [
  {
    templateName: 'image.insert',
    popupTemplate: '[_UPLOAD_LAYER_]',
  },
];

const customPlugins = [
  {
    name: 'directionTool',
    templateName: 'directionTool.popup',
    popupTemplate: BUTTONS_POPUP_TEMPLATE,
    callback: initialDirectionTool,
  },
  {
    name: 'responsiveToolbar',
    templateName: 'responsiveToolbar.popup',
    popupTemplate: BUTTONS_POPUP_TEMPLATE,
    callback: initialResponsiveToolbar,
  },
];

export { customPlugins, pluginOverloads };
