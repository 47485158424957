import IconButton from 'common/components/buttons/IconButton';
import smallYellowStars from 'images/icons/generativeAi/yellow_stars_16x16.gif';
import { Divider } from '@mui/material';
import { Tooltip } from 'common/components/sysaidLibrary/Tooltip';
import { ReactComponent as RevertIcon } from 'images/icons/generativeAi/rephrase/revert.svg';
import { StyledRephraseToolbar } from './style';
import { REPHRASE_TYPE, getIconByType } from './constants';
import { useTexts } from './useTexts';

export const RephraseToolbar = ({
  toolbarRef,
  bottomPosition,
  isLoading,
  lastClickedAction,
  preventTextSelectionResetWhenButtonsDisabled,
  handleClick,
  revert,
  canRevert,
}) => {
  const { rephraseActions: typeTexts, rewriting, backToOriginal } = useTexts();

  return (
    <StyledRephraseToolbar
      ref={toolbarRef}
      direction="row"
      sx={{ bottom: `${bottomPosition}px`}}
      onMouseDown={preventTextSelectionResetWhenButtonsDisabled}
      className="rephrase-toolbar"
    >
      {Object.values(REPHRASE_TYPE).map((type) => {
        const isActionInProgress = isLoading && lastClickedAction === type;

        const wrappedButton = isActionInProgress ? (
          <img src={smallYellowStars} className="loading-stars-icon" alt="loading indicator" key="loader" />
        ) : (
          <IconButton
            className="icon-button"
            icon={getIconByType(type)}
            btnTheme="extraSmallIconButton"
            disabled={isLoading}
            onClick={() => handleClick(type)}
          />
        );

        const key = isActionInProgress ? 'loader' : type;

        return (
          <Tooltip
            forceDisplayOnHover
            isIcon
            title={isActionInProgress ? rewriting : typeTexts[type]}
            disableInteractive
            arrow
            placement="top"
            key={key}
          >
            {wrappedButton}
          </Tooltip>
        );
      })}
      {canRevert && (
        <>
          <Divider orientation="vertical" variant="middle" />
          <Tooltip
            forceDisplayOnHover
            isIcon
            title={backToOriginal}
            disableInteractive
            arrow
            placement="top"
            key="revert"
          >
            <IconButton
              className="icon-button"
              icon={<RevertIcon />}
              btnTheme="extraExtraSmallIconButton"
              disabled={isLoading}
              onClick={revert}
            />
          </Tooltip>
        </>
      )}
    </StyledRephraseToolbar>
  );
};
