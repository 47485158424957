import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledFileInputLabel = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'inputBtnComponent',
})(() => ({
  '.MuiInput-root': {
    display: 'block',
  },
}));
