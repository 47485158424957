import { Stack, styled } from '@mui/material';
import { TOOLBAR_HEIGHT } from './constants';

export const StyledRephraseToolbar = styled(Stack)(({ theme }) => ({
  height: TOOLBAR_HEIGHT,
  backgroundColor: theme.palette.common.white,
  position: 'absolute',
  right: "15px",
  zIndex: 2,
  borderRadius: '0.25rem',
  padding: '0.25rem',
  boxShadow: `0px 2px 8px 0px ${theme.palette.background.boxShadow}`,
  '.icon-button': {
    minWidth: 'auto',
  },
  '.MuiDivider-root': {
    height: '15px',
    margin: '0.22rem 0.25rem',
  },
  '.loading-stars-icon': {
    margin: '0.1875rem 0.1875rem',
  },
  display: 'flex',
  lineHeight: 1,
}));
