import { Box, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledShareSwitch = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
  lineHeight: '18px',
  height: '18px !important',
  '&& .Mui-disabled': {
    '+ .MuiSwitch-track': {
      backgroundColor: `${theme.palette?.switchToggle.unchecked} !important`,
    },
  },
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& + .MuiSwitch-track': {
        backgroundColor: `${theme.palette?.labels.bt_orange_hover} !important`,
      },
    },
  },
}));

export const StyledAttachmentLinkCheckboxWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
}));

export const StyledAttachmentLinkCheckboxLabel = styled('p')(({ theme }) => ({
  fontSize: '14px',
  fontFamily: theme.fonts.roboto,
  color: theme.palette.text.secondary_txt,
}));

export const StyledMenu = styled(Menu)(({ theme }) => ({
  ul: {
    display: 'flex',
    padding: '8px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '6px',
    boxShadow: `0px 2px 8px 0px ${theme.palette.background.boxShadow}`,
  },
}));

export const StyledMenuItem = styled(MenuItem)(() => ({
  '&&': {
    display: 'flex',
    gap: '8px',
    padding: '8px',

    ':hover': {
      background: 'none',
    },
  },
}));

export const StyledTitle = styled('span')(() => ({
  display: '-webkit-box',
  '-webkit-box-orient': 'vertical',
  '-webkit-line-clamp': 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const StyledActions = styled('div', {
  shouldForwardProp: (props) => props !== 'disabled' && props !== 'isActive',
})(({ theme, disabled, isActive }) => ({
  '& .MuiButtonBase-root': {
    backgroundColor: isActive ? theme.palette.icons.bt_blue_chip_light_transparent : 'transparent',
    cursor: !disabled ? 'pointer' : 'default',
    display: 'flex',
    padding: '2px',
    justifyContent: 'center',
    alignItems: 'baseline',
    gap: '8px',
    borderRadius: '4px',

    '&:hover': {
      backgroundColor: theme.palette.grey.bt_alt_grey_reg,
    },
  },
}));
