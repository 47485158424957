import { saveFile } from 'common/utils/files';
import { changeDirection, changeImagePreviewConfigs } from './callbacks';

const buttonOverloads = [
  {
    name: 'fontSize',
    props: {
      title: 'spaces.richTextEditor.fontSize',
      icon: 'fontSize',
      displaySelection: null,
      defaultSelection: null,
    },
  },
  {
    name: 'bold',
    props: {
      title: 'spaces.richTextEditor.bold',
    },
  },
  {
    name: 'formatUL',
    props: {
      title: 'spaces.richTextEditor.unorderedList',
    },
  },
  {
    name: 'formatOL',
    props: {
      title: 'spaces.richTextEditor.orderedList',
    },
  },
  {
    name: 'textColor',
    props: {
      title: 'spaces.richTextEditor.textColor',
    },
  },
  {
    name: 'insertLink',
    props: {
      title: 'spaces.richTextEditor.insertLink',
    },
  },
  {
    name: 'align',
    props: {
      title: 'spaces.richTextEditor.align',
      options: {
        left: 'spaces.richTextEditor.alignLeft',
        center: 'spaces.richTextEditor.alignCenter',
        right: 'spaces.richTextEditor.alignRight',
        justify: 'spaces.richTextEditor.alignJustify',
      },
    },
  },
  {
    name: 'italic',
    props: {
      title: 'spaces.richTextEditor.italic',
    },
  },
  {
    name: 'underline',
    props: {
      title: 'spaces.richTextEditor.underline',
    },
  },
  {
    name: 'insertImage',
    props: {
      title: 'spaces.richTextEditor.insertImage',
      icon: 'insertImage',
      callback({ callbacks, id }) {
        const wrapper = document.querySelector(`#richtext-wrap-${id}`);
        const uploadButton = wrapper?.querySelector('#image-uploader');
        callbacks.onChangeUploadImageProgress(true);
        uploadButton?.click();
      },
    },
  },
  {
    name: 'insertFile',
    props: {
      title: 'spaces.richTextEditor.insertFile',
      icon: 'insertFile',
      callback({ callbacks, id }) {
        document.querySelector('.fr-tooltip').style.display = 'none';
        callbacks.onChangeUploadFileProgress(true);
        callbacks.setUploadFromMenuId(id);
        callbacks.setIsUploadFromMenuOpened(true);
      },
    },
  },
];

const customButtons = [
  {
    name: 'directionTool',
    props: {
      title: 'spaces.richTextEditor.direction',
      icon: 'direction',
      undo: false,
      focus: false,
      plugin: 'directionTool',
      callback({ editor }) {
        editor.directionTool.showDirectionToolPopup();
      },
    },
  },
  {
    name: 'leftToRight',
    props: {
      icon: 'ltr',
      title: 'spaces.richTextEditor.LTR',
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      callback({ editor }) {
        changeDirection(editor, 'ltr', 'left');
      },
    },
  },
  {
    name: 'rightToLeft',
    props: {
      icon: 'rtl',
      title: 'spaces.richTextEditor.RTL',
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      callback({ editor }) {
        changeDirection(editor, 'rtl', 'right');
      },
    },
  },
  {
    name: 'mention',
    props: {
      icon: 'mention',
      title: 'spaces.richTextEditor.mention',
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      callback({ editor, refs }) {
        refs.tribute.showMenuForCollection(editor.el);
      },
    },
  },
  {
    name: 'responsiveToolbar',
    props: {
      icon: 'moreOptions',
      title: 'spaces.richTextEditor.moreOptions',
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      callback({ editor }) {
        editor.responsiveToolbar.showResponsiveToolbarPopup();
      },
    },
  },
  {
    name: 'highlight',
    props: {
      icon: 'highlight',
      title: 'spaces.richTextEditor.highlight',
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      callback({ editor }) {
        editor.inlineClass.apply('fr-class-highlighted');
      },
    },
  },
  {
    name: 'imageDownload',
    props: {
      title: 'spaces.richTextEditor.download',
      icon: 'download',
      focus: false,
      undo: false,
      refreshAfterCallback: false,
      callback({ editor }) {
        const img = editor.image.get();
        saveFile({ url: img.attr('src') });
      },
    },
  },
  {
    name: 'imageInfo',
    props: {
      title: 'spaces.richTextEditor.info',
      icon: 'imageExpand',
      focus: false,
      undo: false,
      refreshAfterCallback: false,
      callback({ editor, callbacks }) {
        const imagePreview = changeImagePreviewConfigs(editor);
        callbacks.onChangeImagePreviewsConfig(imagePreview);
      },
    },
  },
  {
    name: 'cannedResponses',
    props: {
      title: 'spaces.richTextEditor.cannedResponses',
      focus: false,
      undo: false,
      refreshAfterCallback: false,
      callback({ editor, callbacks }) {
        callbacks.toggleDropdown({ editor: editor.el });
      },
    },
  },
];

export { customButtons, buttonOverloads };
