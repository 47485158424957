import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import { ReactComponent as DragIcon } from 'images/icons/gh_dragg.svg';
import SortIcon from './SortIcon';
import FilterIcon from './FilterIcon';

export const StyledCustomColumnHeader = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  justifyItems: 'center',
  alignContent: 'center',
  /* marginLeft: '10px', */
}));

export const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'left',
  width: '100%',
  '.MuiBadge-badge': {
    width: '13px',
    height: '13px',
    fontSize: '10px',
    minWidth: '13px',
    fontWeight: '400',
    top: '-3px',
    backgroundColor: theme.palette.text.disable_txt_V2,
  },
}));

export const StyledColumnLabel = styled(Typography)(({ theme }) => ({
  fontSize: theme.fontSize.main,
  lineHeight: 1.429,
  color: theme.palette.text.title_txt,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));

export const StyledFilterIcon = styled(FilterIcon, {
  shouldForwardProp: (prop) => prop !== 'isFilterActive',
})(({ isFilterActive }) => ({
  display: isFilterActive ? 'block' : 'none',
  marginLeft: isFilterActive ? '4px' : '0px',
}));

export const StyledSortIcon = styled(SortIcon, {
  shouldForwardProp: (prop) => prop !== 'isSortActive',
})(({ isSortActive }) => ({
  display: isSortActive ? 'block' : 'none',
  marginLeft: isSortActive ? '4px' : '0px',
  height: '16px',
}));

export const StyledDragIcon = styled(DragIcon)(() => ({
  visibility: 'hidden',
  cursor: 'grab',
}));

export const StyledCheckBox = styled(Checkbox)(() => ({
  '&&': {
    width: '16px',
    height: '16px',
    right: '3px',
    marginRight: '35px',
    objectFit: 'contain',
    color: '#999',
    transform: 'scale(0.9)',
  },
}));
