import { Fade, Paper } from '@mui/material';
import { StyledPopper } from '../../../../../../common/components/sysaidLibrary/Select/style';

export const MoreItemsPopup = ({ toggleMoreItems, anchorEl, items, displayItemsLimit, stroke }) => (
  <StyledPopper
    open={toggleMoreItems}
    anchorEl={anchorEl?.current}
    placement="bottom-start"
    width="250"
    onClick={(e) => e.stopPropagation()}
    transition
  >
    {({ TransitionProps }) => (
      <Fade {...TransitionProps} timeout={350}>
        <Paper className="paper">
          <div className="list-wrapper">
            <ul className="paper-list">
              {items
                .map((item) => (
                  <li key={`${item}-popup-item`} className={`${stroke ? 'stroke ' : ''}paper-list-item`}>
                    {item}
                  </li>
                ))
                .slice(displayItemsLimit)}
            </ul>
          </div>
        </Paper>
      </Fade>
    )}
  </StyledPopper>
);
