import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();

  const componentTexts = {
    filterByText: texts?.['spaces.filters.filterBy'],
    searchPlaceholderText: texts?.['spaces.filters.search.title'],
    categoryNoValue: texts?.['spaces.categories.noValue'],
  };

  return componentTexts;
}
