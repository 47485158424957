import { memo } from 'react';
import { Typography } from '@mui/material';
import { USER_CONSTANTS } from 'constants/users';
import { ReactComponent as RedXIcon } from 'images/icons/red-x.svg';
import useTexts from '../../useTexts';

import ErrorMessage from './errorMessage';
import MeMenuContentSection from './meMenuContentSection';
import MeMenuFormField from './meMenuFormField';
import PasswordValidation from './passwordValidations';
import { StyledPasswordTabContent, StyledPasswordValidationsList } from './styles';
import PasswordField from './meMenuFormField/PasswordField';

const PasswordTabContent = ({
  settings: {
    currentPassword,
    password,
    confirmPassword,
    isCurrentPasswordValid,
    isPasswordValid,
    isLengthValid,
    hasUpperCase,
    hasLowerCase,
    hasSpecialChar,
    hasNumber,
    hasMinRequirements,
    isPasswordMismatch,
    updatedSettings: { ldap = 0 },
  },
  handleChange,
  handleBlur,
}) => {
  const {
    changePasswordHeaderTitle,
    ldapUserText,
    currentPasswordLabelText,
    passwordLabelText,
    confirmPasswordLabelText,
    passwordValidationText,
    passwordCriteriaText,
    passwordValidationLengthText,
    passwordValidationUppercaseText,
    passwordValidationLowercaseText,
    passwordValidationNumberText,
    passwordValidationSpecialCharsText,
    currentPasswordErrorText,
    confirmPasswordErrorText,
  } = useTexts();

  const invisibleInputStyle = {
    width: 0,
    height: 0,
    opacity: 0,
    position: 'absolute',
    left: '-10000px',
    overflow: 'hidden'
  };

  return ldap !== USER_CONSTANTS.MANUAL_USER_SOURCE ? (
    <MeMenuContentSection>
      <Typography data-testid="ldap-title" paragraph sx={{ fontSize: '.75rem', lineHeight: 1.1429 }}>
        {ldapUserText}
      </Typography>
    </MeMenuContentSection>
  ) : (
    <MeMenuContentSection name="password" header={changePasswordHeaderTitle}>
      <StyledPasswordTabContent>
        <div className="inputs-container">

          {
            /*
            If a site sets autocomplete="off" for username and password <input> fields, then the browser still offers
            to remember this login, and if the user agrees, the browser will autofill those fields the next time the user visits the page.
            Link: https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion

            When browser sees fields with types "text" and "password" in one page, it tries to autofill those values with
            credentials, even if these inputs are in different React components. These empty inputs are solving this problem
            and force browser to autofill only them.
          */
          }
          <input style={invisibleInputStyle} type="text" name="empty-username-input" />
          <input style={invisibleInputStyle} type="password" name="empty-password-input" />

          <MeMenuFormField
            label={currentPasswordLabelText}
            name="currentPassword"
            type="password"
            inputComponent={PasswordField}
            inputProps={{
              value: currentPassword,
              handleChange: (newCurrentPassword) => handleChange(newCurrentPassword, 'currentPassword'),
              handleBlur: (newCurrentPassword) => handleBlur(newCurrentPassword, 'currentPassword'),
              required: true,
              error: !isCurrentPasswordValid,
            }}
          />
          <MeMenuFormField
            label={passwordLabelText}
            name="password"
            type="password"
            inputComponent={PasswordField}
            inputProps={{
              value: password,
              handleChange: (newPassword) => handleChange(newPassword, 'password'),
              error: !isPasswordValid,
            }}
          />
          <MeMenuFormField
            label={confirmPasswordLabelText}
            name="confirmPassword"
            type="password"
            inputComponent={PasswordField}
            inputProps={{
              value: confirmPassword,
              handleChange: (newConfirmPassword) => handleChange(newConfirmPassword, 'confirmPassword'),
              required: Boolean(password),
              error: isPasswordMismatch,
            }}
          />
        </div>
        <ErrorMessage>
          <StyledPasswordValidationsList disablePadding>
            <PasswordValidation variant="title" text={passwordValidationText} />
            <PasswordValidation
              name="password-length-validation"
              isPasswordValid={isPasswordValid}
              hasCriteria={isLengthValid}
              text={passwordValidationLengthText}
              variant="length"
            />
            <PasswordValidation
              name="password-combined-validation"
              isPasswordValid={isPasswordValid}
              hasCriteria={hasMinRequirements}
              text={passwordCriteriaText}
              variant="combined"
            />
            <PasswordValidation
              name="password-uppercase-validation"
              isPasswordValid={isPasswordValid}
              hasCriteria={hasUpperCase}
              text={passwordValidationUppercaseText}
              variant="uppercase"
            />
            <PasswordValidation
              name="password-lowercase-validation"
              isPasswordValid={isPasswordValid}
              hasCriteria={hasLowerCase}
              text={passwordValidationLowercaseText}
              variant="lowercase"
            />
            <PasswordValidation
              name="password-number-validation"
              isPasswordValid={isPasswordValid}
              hasCriteria={hasNumber}
              text={passwordValidationNumberText}
              variant="number"
            />
            <PasswordValidation
              name="password-special-chars-validation"
              isPasswordValid={isPasswordValid}
              hasCriteria={hasSpecialChar}
              text={passwordValidationSpecialCharsText}
              variant="special-char"
            />
          </StyledPasswordValidationsList>
        </ErrorMessage>
      </StyledPasswordTabContent>
      {!isCurrentPasswordValid && (
        <div className="input-error" data-testid="current-password-error">
          <RedXIcon />
          <span>{currentPasswordErrorText}</span>
        </div>
      )}
      {!!confirmPassword && isPasswordMismatch && (
        <div className="input-error" data-testid="confirm-password-error">
          <RedXIcon />
          <span>{confirmPasswordErrorText}</span>
        </div>
      )}
    </MeMenuContentSection>
  );
};

export default memo(PasswordTabContent);
