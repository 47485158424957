import { CONSTANTS } from './constants';

export const getDisplayDateFormat = (isOnlyDate, dateFormat) => {
  let format;
  const isEuropianFormat =
    dateFormat === CONSTANTS.EUROPIAN_DATETIME_FORMAT ||
    dateFormat === CONSTANTS.BACKEND_EUROPIAN_DATE_TIME_FORMAT ||
    dateFormat === CONSTANTS.EUROPIAN_DATE_FORMAT;
  if (isOnlyDate && isEuropianFormat) {
    format = CONSTANTS.EUROPIAN_DATE_FORMAT;
  } else if (isOnlyDate && !isEuropianFormat) {
    format = CONSTANTS.AMERICAN_DATE_FORMAT;
  } else {
    format = dateFormat;
  }
  return format;
};

export const getPlaceholder = (disabled, dateFormat) => {
  if (disabled) return '--';
  return dateFormat === CONSTANTS.BACKEND_DATE_TIME_FORMAT
    ? CONSTANTS.AMERICAN_DATETIME_FORMAT
    : CONSTANTS.EUROPIAN_DATETIME_FORMAT;
};
