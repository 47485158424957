import { SIMPLE_CONTENT_PATTERN } from './consts';

const hasImage = (paragraph) => {
    const containsOneChildren = paragraph.children.length === 1;
    const childIsImage = containsOneChildren && paragraph.children[0]?.tagName.toLowerCase() === 'img';

    return childIsImage;
};

const notParagrhaph = (childNode) => childNode.nodeName.toLowerCase() !== 'p';

const detectImageOrMarkdown = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    const children = [...doc.body.childNodes];
    const paragraphs = [...doc.querySelectorAll('p')];

    const someParagraphsContainImages = paragraphs.some(hasImage);
    const someNodesAreNotParagraphs = children.some(notParagrhaph);

    return someParagraphsContainImages || someNodesAreNotParagraphs;
};

export const considerSimpleContent = ({ text, isPassing = false }) => ({ text, isPassing: isPassing && SIMPLE_CONTENT_PATTERN.test(text) });
export const considerRichContent = ({ text, isPassing = false }) => ({ text, isPassing: isPassing && !detectImageOrMarkdown(text) });
