import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

export const StyledTemplateSectionInstructions = styled(TextField)(({ theme }) => ({
  marginBottom: '8px',
  '&:hover': {
    backgroundColor: theme.palette.background.selected,
  },
  '.MuiInputBase-root': {
    color: theme?.palette?.text?.title_txt,
    padding: '6px 8px',
    fontSize: theme.fontSize.main,
    lineHeight: '1.5rem',
    '&.MuiOutlinedInput-root': {
      '& fieldset': {
        border: theme.shape.border.border,
      },
      '&.Mui-focused': {
        backgroundColor: theme?.palette?.generic?.primary,
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${theme?.palette?.generic?.sysaid}`,
      },
    },
  },
  '.MuiFormHelperText-root': {
    position: 'absolute',
    bottom: '1px',
    right: '8px',
    padding: '2px',
    margin: '0 0 8px 0',
    height: '18px',
    display: 'inline-flex',
    fontSize: theme?.fontSize?.small,
    color: theme?.palette?.text?.disable_txt,
    alignSelf: 'end',
    '&.Mui-focused': {
      backgroundColor: theme?.palette?.generic?.primary,
    },
  },
}));
