import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash-es';

import { ReactComponent as ClearIcon } from 'images/icons/sysaidLibrary/cancel-icon.svg';
import { ReactComponent as SmallClearIcon } from 'images/icons/sysaidLibrary/cancel-icon-small.svg';
import { ReactComponent as SearchIcon } from 'images/icons/sysaidLibrary/search-icon.svg';
import { AVATAR_CONSTANTS } from 'constants/index';
import { useTexts } from 'common/components/dropdownSelect/hooks';
import { useAssigneeQuery } from 'remote-state/userServiceHooks';
import { isNone } from 'common/utils/utils';

import { AssigneeDropdown } from './AssigneeDropdown';
import { SearchBar } from '../selectHeader/SearchBar';
import { ClearSelectControl } from '../selectHeader/ClearSelectControl';
import { AssigneeButton } from './AssigneeButton';
import { ASSIGNEE_CONSTANTS } from './constants';
import { StyledAssignee } from './style';
import { useAssignee } from './useAssignee';

const noOp = () => {};
export const Assignee = ({
  isRequired,
  assignedUser,
  assignedGroup,
  onClick = noOp,
  handleChangeAssignee = noOp,
  disabled,
  readOnly,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [currentAssigned, setCurrentAssigned] = useState({
    user: ASSIGNEE_CONSTANTS.UNASSIGNED,
    group: null,
  });
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  const [showGroupDropdown, setShowGroupDropdown] = useState(false);
  const { clearText, infoSearchPlaceholder } = useTexts();
  const { users, groups } = useAssigneeQuery(debouncedSearchText);
  const { handleFindAssignees } = useAssignee();

  useEffect(() => {
    const findAssignees = async () => {
      if (isNone(assignedUser) && isNone(assignedGroup)) {
        setCurrentAssigned({ user: ASSIGNEE_CONSTANTS.UNASSIGNED, group: null });
        return;
      }
      const { currentUser, currentGroup } = await handleFindAssignees({ user: assignedUser, group: assignedGroup });
      setCurrentAssigned({ user: currentUser, group: currentGroup });
    };
    findAssignees();
  }, [assignedUser, assignedGroup, handleFindAssignees]);

  const handleDebouncedSearchChange = debounce(() => {
    setDebouncedSearchText(searchText);
  }, 300);

  useEffect(() => {
    handleDebouncedSearchChange();
    return () => {
      handleDebouncedSearchChange.cancel();
    };
  }, [searchText, handleDebouncedSearchChange]);

  const handleSearchTextChange = useCallback((event) => {
    const newValue = event.target.value;
    setSearchText(newValue);
  }, []);

  const handleCurrentAssignedChange = useCallback(
    ({ user, group }) => {
      const hasUserChanged = user?.calculatedUserName !== currentAssigned?.user?.calculatedUserName;
      const hasGroupChanged = group?.groupName !== currentAssigned?.group?.groupName;

      if (!hasUserChanged && !group && !currentAssigned?.group?.groupName) return;
      if (!hasGroupChanged && !user && !currentAssigned?.user?.calculatedUserName) return;
      if (!hasGroupChanged && !hasUserChanged) return;

      setCurrentAssigned({ ...currentAssigned, user, group });
    },
    [currentAssigned],
  );

  const handleClose = useCallback(() => {
    setSearchText('');
    setOpen(false);
    setShowGroupDropdown(false);
  }, [setSearchText, setOpen, setShowGroupDropdown]);

  const handleSelect = useCallback(
    ({ user, group }) => {
      handleCurrentAssignedChange({ user, group });
      handleChangeAssignee({ user, group });
      setSelectedGroup(null);
      handleClose();
    },
    [handleChangeAssignee, handleClose, handleCurrentAssignedChange],
  );

  const handleSelectedItemClear = useCallback(() => {
    handleSelect({ user: ASSIGNEE_CONSTANTS.UNASSIGNED, group: null });
    handleClose();
  }, [handleClose, handleSelect]);

  const handleSearchTextClear = useCallback(() => {
    setSearchText('');
  }, [setSearchText]);

  const toggleDropdown = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
      setOpen((previousOpen) => !previousOpen);
    },
    [setAnchorEl, setOpen],
  );

  const handleGroupSelect = useCallback(({ group }) => {
    setSearchText('');
    setSelectedGroup(group);
    setShowGroupDropdown(true);
  }, []);

  const onClickBackFromGroupDropdown = useCallback(() => {
    setSearchText('');
    setSelectedGroup(null);
    setShowGroupDropdown(false);
  }, []);

  const calcDropdownMaxHeight = useMemo(() => {
    if (users?.length && groups?.length)
      return ASSIGNEE_CONSTANTS.ASSIGNEE_ITEM_HEIGHT * ASSIGNEE_CONSTANTS.DROPDOWN_GROUPS_AND_ADMINS_SCROLL_THRESHOLD;
    return ASSIGNEE_CONSTANTS.ASSIGNEE_ITEM_HEIGHT * ASSIGNEE_CONSTANTS.DROPDOWN_SCROLL_THRESHOLD;
  }, [groups?.length, users?.length]);

  const calcDropdownMaxItems =
    users?.length && groups?.length
      ? ASSIGNEE_CONSTANTS.DROPDOWN_GROUPS_AND_ADMINS_MAX_ITEMS
      : ASSIGNEE_CONSTANTS.DROPDOWN_MAX_ITEMS;

  return (
    <StyledAssignee data-view-only={readOnly} onClick={onClick} isRequired={isRequired} data-testid="assignee">
      <AssigneeButton
        currentAssigned={currentAssigned}
        disabled={disabled || readOnly}
        avatarSize={AVATAR_CONSTANTS.SIZES.SMALL}
        handleClick={toggleDropdown}
        isRequired={isRequired}
      />
      <AssigneeDropdown
        anchorEl={anchorEl}
        open={open}
        users={users}
        groups={groups}
        searchText={debouncedSearchText}
        handleClose={handleClose}
        handleSelect={handleSelect}
        currentAssigned={currentAssigned}
        showGroupDropdown={showGroupDropdown}
        handleGroupSelect={handleGroupSelect}
        selectedGroup={selectedGroup}
        onClickBackFromGroupDropdown={onClickBackFromGroupDropdown}
        assigneeItemHeight={ASSIGNEE_CONSTANTS.ASSIGNEE_ITEM_HEIGHT}
        dropdownMaxItemsCount={calcDropdownMaxItems}
        dropdownMaxHeight={calcDropdownMaxHeight}
        isRequired={isRequired}
        headerProps={
          <>
            <SearchBar
              placeholder={infoSearchPlaceholder}
              searchIcon={<SearchIcon />}
              clearIcon={<ClearIcon />}
              handleChange={handleSearchTextChange}
              handleClear={handleSearchTextClear}
              value={searchText}
              autoFocus
            />
            {!isRequired && (
              <ClearSelectControl
                clearText={clearText}
                clearIcon={<SmallClearIcon />}
                handleClearValue={handleSelectedItemClear}
                disableClear={
                  currentAssigned?.user === ASSIGNEE_CONSTANTS.UNASSIGNED ||
                  (!currentAssigned?.user && !currentAssigned?.group)
                }
              />
            )}
          </>
        }
      />
    </StyledAssignee>
  );
};
