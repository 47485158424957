export const formatTranslation = (text, params = []) => {
  if (text) {
    let populatedText = text;
    params.forEach((arrayItem) => {
        populatedText = populatedText.replace(arrayItem.placeHolder, arrayItem.value);
    });

    return populatedText;
  }
  return '';
};
