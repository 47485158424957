import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const StyledIconButton = styled(Button, {
  shouldForwardProp: (prop) => !['btnTheme'].includes(prop),
})(({ theme, btnTheme }) => ({
  // Bumped specificity to override template styling of MuiButton
  '&&&&&&': {
    borderRadius: theme.palette?.[btnTheme]?.btn?.borderRadius,
    fontSize: theme?.palette?.[btnTheme]?.btn?.fontSize,
    lineHeight: 1.7143,
    padding: theme?.palette?.[btnTheme]?.btn?.padding,
    textTransform: 'unset',
    fontFamily: theme?.fonts?.robotoRegular,
    backgroundColor: theme?.palette?.[btnTheme]?.btn?.backgroundColor?.main,
    color: theme?.palette?.[btnTheme]?.btn?.color?.main,

    '.MuiButton-startIcon': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: theme?.palette?.[btnTheme]?.btn?.width,
      height: theme?.palette?.[btnTheme]?.btn?.height,
    },

    '&:hover': {
      backgroundColor: theme?.palette?.[btnTheme]?.btn?.backgroundColor?.hover,
      color: theme?.palette?.[btnTheme]?.btn?.color?.hover,
    },

    '&.Mui-disabled': {
      backgroundColor: theme?.palette?.[btnTheme]?.btn?.backgroundColor.disabled,
      color: theme?.palette?.[btnTheme]?.btn?.color?.disabled,
    },

    '& .MuiButton-startIcon': {
      marginLeft: theme?.palette?.[btnTheme]?.btn?.marginLeft,
      marginRight: theme?.palette?.[btnTheme]?.btn?.marginRight,
    },
  },
}));
