import { styled } from '@mui/material/styles';
import { Dialog } from '@mui/material';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    maxWidth: '100%',
    height: '100vh',
    backgroundColor: theme.palette.generic.grey_200,
  },
}));

export const StyledHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '15px 15px 20px',
  '& > img': {
    width: '70px',
  },
  '& > button': {
    borderRadius: '20px',
    textTransform: 'unset',
    paddingLeft: '20px',
    paddingRight: '20px',
    '& > svg': {
      fontSize: theme.fontSize.normal,
    },
  },
}));
