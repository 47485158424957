import { ReactComponent as ImproveIcon } from 'images/icons/generativeAi/rephrase/improve.svg';
import { ReactComponent as FormalIcon } from 'images/icons/generativeAi/rephrase/formal.svg';
import { ReactComponent as CasualIcon } from 'images/icons/generativeAi/rephrase/casual.svg';
import { ReactComponent as ShortenIcon } from 'images/icons/generativeAi/rephrase/shorten.svg';
import { ReactComponent as StepByStepIcon } from 'images/icons/generativeAi/rephrase/step_by_step.svg';

export const REPHRASE_TYPE = {
  Improve: 'improve',
  Casual: 'casual',
  Formal: 'formal',
  Shorten: 'shorten',
  StepByStep: 'stepByStep',
};

const FROALA_MARKER_TEMPLATE = (dataType) =>
  `<span class="fr-marker" data-id="0" data-type="${dataType}" style="display: none; line-height: 0;">&ZeroWidthSpace;</span>`;
export const FROALA_SELECTION_START_MARKER = FROALA_MARKER_TEMPLATE(true);
export const FROALA_SELECTION_END_MARKER = FROALA_MARKER_TEMPLATE(false);

export const CONTAINER_PADDING = 8;

export const TOOLBAR_HEIGHT = 30;
export const TOOLBAR_BOTTOM_POSITION = 38;
export const DISTANCE_FROM_EDITOR_TOOLBAR = 10;

export const getIconByType = (rephraseType) => {
  switch (rephraseType) {
    case REPHRASE_TYPE.Improve: {
      return <ImproveIcon />;
    }
    case REPHRASE_TYPE.Casual: {
      return <CasualIcon />;
    }
    case REPHRASE_TYPE.Formal: {
      return <FormalIcon />;
    }
    case REPHRASE_TYPE.Shorten: {
      return <ShortenIcon />;
    }
    case REPHRASE_TYPE.StepByStep: {
      return <StepByStepIcon />;
    }
    default: {
      return null;
    }
  }
};
