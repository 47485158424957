import { useSelector } from 'react-redux';
import { selectActiveUser } from 'store/userSlice';
import StatusMessage from 'common/components/statusMessage';
import { ReactComponent as LockIcon } from 'images/icons/lock.svg';
import useTexts from '../useTexts';
import { StyledSRLockedHeader } from './style';

function SrLockHeader({ lockingDetails, positionTop }) {
  const userAccount = useSelector(selectActiveUser);
  const { lockedByText, lockedInQueueByText } = useTexts();

  const hashIndex = lockedInQueueByText.indexOf('#') + 1;
  const lockedInQueueText = `${lockedInQueueByText.slice(0, hashIndex)}${
    lockingDetails.srId
  } ${lockedInQueueByText.slice(hashIndex)}`;

  return (
    <StyledSRLockedHeader className={`sr-lock-header ${positionTop ? 'position-top' : ''}`}>
      {lockingDetails.isLocked && lockingDetails.lockingUser !== userAccount?.username && (
        <StatusMessage
          message={`${positionTop ? lockedInQueueText : `${lockedByText} ${lockingDetails.lockingUser}`} `}
          AlertIcon={LockIcon}
        />
      )}
    </StyledSRLockedHeader>
  );
}
export default SrLockHeader;
