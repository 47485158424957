import React, { useState, useRef, useEffect } from 'react';
import { Box, Popper } from '@mui/material';
import { ClickAwayListener } from '@mui/base';
import UserInfoCard from 'common/components/userInfoCard';
import { useUserDetailsByName, useAssigneesList, useUserDetailsById } from 'remote-state/ticketServiceHooks';
import { merge } from 'lodash';

const User = ({ userName, userId, teamName, children, customSx, popper }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const hoverTimeout = useRef(null);
  const { data: byName } = useUserDetailsByName(userName);
  const { data: byId } = useUserDetailsById(userId);
  const user = merge(byName, byId);
  const {
    adminsByGroup: { data: teamMembers },
  } = useAssigneesList(teamName, user?.groups);

  const team = {
    teamName: teamName || user?.groups?.[0],
    teamMembers
  }

  const ref = useRef(null);
  const toShowDetails = !!anchorEl;

  const hideDetails = () => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
    }
    hoverTimeout.current = setTimeout(() => setAnchorEl(null), 200);
  };

  const showDetails = () => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
    }
    hoverTimeout.current = setTimeout(() => setAnchorEl(ref.current), 500);
  };

  useEffect(
    () => () => {
      if (hoverTimeout.current) {
        clearTimeout(hoverTimeout.current);
      }
    },
    [],
  );
  return (
    <ClickAwayListener onClickAway={hideDetails}>
      <Box
        ref={ref}
        onClick={hideDetails}
        onMouseLeave={hideDetails}
        sx={customSx}
        data-testid="user-component"
      >
        {React.cloneElement(children, {
          onMouseEnter: showDetails
        })}
        <Popper
          open={toShowDetails}
          anchorEl={anchorEl}
          placement={popper?.placement || 'bottom-start'}
          sx={popper?.sx}
          onClick={(e) => e.stopPropagation()}
        >
          <Box>
            <UserInfoCard user={user} team={team} />
          </Box>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

export default User;
