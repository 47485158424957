import { useState, useRef, useCallback } from 'react';
import { useFormContext, useController } from 'react-hook-form';
// import useDebounceSearch from 'common/utils/hooks/useDebounceSearch';
import { useDebounceValue } from 'common/utils/hooks/useDebounceValue';
import { useWorkflowAttributeQuery } from 'features/resolutionPanel/middlePanel/Workflows/api/workflowQueries';
import { ReactComponent as ClearIcon } from 'images/icons/sysaidLibrary/cancel-icon.svg';
import { ReactComponent as SmallClearIcon } from 'images/icons/sysaidLibrary/cancel-icon-small.svg';
import { ReactComponent as SearchIcon } from 'images/icons/sysaidLibrary/search-icon.svg';
import { SearchBar } from '../selectHeader/SearchBar';
import { ClearSelectControl } from '../selectHeader/ClearSelectControl';
import { TextField } from '../textField';
import { Select } from '../Select';
import { StyledSelectField } from './style';

/**  This should also be using the 'generic' list API (and each query with batching should be using the 'enabled' flag to decide what should be used),
 *   but as the regular call has been broken at this point, we're just using the workflow api to get the field value.
 */
export const SelectField = ({
  name,
  label,
  disabled,
  readOnly,
  keyExpr = 'id',
  displayExpr = 'value',
  ...restProps
}) => {
  const { control, onSubmit } = useFormContext() || {};
  const { field } = useController({ name, control });
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText, setDebouncedSearchText] = useDebounceValue(searchText, 200);
  const { data: options } = useWorkflowAttributeQuery({
    fieldName: name,
    ...(debouncedSearchText && { paramKey: 'query', paramValue: searchText }),
  });

  const isSelectPopulated = options?.values?.length > 0;
  const { data: selectedOption } = useWorkflowAttributeQuery({
    fieldName: name,
    ...(field?.value && { paramValue: field.value }),
    enabled: isSelectPopulated && !debouncedSearchText && !!field?.value,
  });

  const anchorEl = useRef(null);
  const listRef = useRef(null);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    setDebouncedSearchText(e.target.value);
  };

  const clearSearch = useCallback(() => {
    setSearchText('');
    setDebouncedSearchText('');
  }, [setDebouncedSearchText]);

  const handleTextFieldClick = useCallback(() => {
    if (disabled || readOnly) return;
    setOpen((prevState) => !prevState);
  }, [readOnly, disabled]);

  const handleSelectionChange = useCallback(
    (data) => {
      onSubmit(data);
      field.onChange(data.newValue);
      setOpen(false);
      clearSearch();
      field.onBlur();
    },
    [onSubmit, field, clearSearch],
  );

  const handleBlur = useCallback(
    (event) => {
      if (listRef?.current && !listRef.current?.contains(event.relatedTarget)) {
        setOpen(false);
        field.onBlur();
      }
    },
    [field],
  );
  const handleClose = () => {
    setSearchText('');
    setOpen(false);
  };
  return (
    <StyledSelectField onBlur={handleBlur}>
      <TextField
        name={name}
        label={label}
        ref={anchorEl}
        value={selectedOption?.[displayExpr]}
        dropdownType="select"
        readOnly={readOnly}
        disabled={disabled}
        handleClick={handleTextFieldClick}
        {...restProps}
      />
      <Select
        open={open}
        anchorEl={anchorEl?.current}
        value={field?.value}
        width={224}
        handleSelect={handleSelectionChange}
        options={options?.values}
        ref={listRef}
        handleClose={handleClose}
        keyExpr={keyExpr}
        displayExpr={displayExpr}
        fieldName={name}
        headerProps={
          <>
            <SearchBar
              placeholder="Search..."
              searchIcon={<SearchIcon />}
              clearIcon={<ClearIcon />}
              handleChange={handleSearchChange}
              handleClear={clearSearch}
              value={searchText}
              autoFocus
            />
            {!restProps?.required && (
              <ClearSelectControl
                ClearText="Clear"
                clearIcon={<SmallClearIcon />}
                handleClearValue={() =>
                  handleSelectionChange({ oldValue: field?.value, newValue: null, fieldName: field.name })
                }
                disableClear={!selectedOption?.[displayExpr]}
              />
            )}
          </>
        }
      />
    </StyledSelectField>
  );
};
