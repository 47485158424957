import { Grid, Divider } from '@mui/material';

import { SwitchToggle } from 'common/components/controls/Switch/SwitchToggle';
import { useLocalesList, useTimezonesList } from 'remote-state/mySettingsHooks';

import useTexts from '../../useTexts';
import { emailNotificationsFormat } from '../../utils';
import MeMenuContentSection from './meMenuContentSection';
import MeMenuFormField from './meMenuFormField';
import Select from './meMenuFormField/Select';
import { StyledSwitchWrapper } from './styles';

export default function PreferencesTabContent({
  settings: {
    updatedSettings: {
      language = '',
      timezone = '',
      dateFormat = '',
      firstDayOfWeek = undefined,
      dashboardRotate = false,
      emailNotifications = false,
    },
  },
  handleChange = () => {},
}) {
  const {
    localizationHeaderText,
    languageLabelText,
    timezoneLabelText,
    dateFormatLabelText,
    firstDayOfWeekLabelText,
    dashboardHeaderText,
    dashboardRotateLabelText,
    notificationsHeaderText,
    emailNotificationsLabelText,
    americanDisplayText,
    europeanDisplayText,
    sundayDisplayText,
    mondayDisplayText,
  } = useTexts();

  const { data: locales } = useLocalesList();
  const { data: timezones } = useTimezonesList();
  const dateFormats = [
    { value: 'American', displayValue: americanDisplayText },
    { value: 'European', displayValue: europeanDisplayText },
  ];
  const daysOfWeek = [
    { value: 'Sunday', displayValue: sundayDisplayText },
    { value: 'Monday', displayValue: mondayDisplayText },
  ];

  return (
    <>
      <MeMenuContentSection name="preferences" header={localizationHeaderText}>
        <Grid container rowGap={1.25}>
          <Grid container rowGap={1.25}>
            <Grid item>
              <MeMenuFormField
                label={languageLabelText}
                name="language"
                inputComponent={Select}
                inputProps={{
                  options: locales,
                  value: language,
                  handleChange,
                }}
              />
            </Grid>
          </Grid>
          <Grid container rowGap={1.25}>
            <Grid item>
              <MeMenuFormField
                label={timezoneLabelText}
                name="timezone"
                inputComponent={Select}
                inputProps={{
                  options: timezones,
                  value: timezone,
                  handleChange,
                }}
              />
            </Grid>
          </Grid>
          <Grid container rowGap={1.25}>
            <Grid item>
              <MeMenuFormField
                label={dateFormatLabelText}
                name="dateFormat"
                inputComponent={Select}
                inputProps={{
                  options: dateFormats,
                  value: dateFormat,
                  handleChange,
                }}
              />
            </Grid>
          </Grid>
          <Grid container rowGap={1.25}>
            <Grid item>
              <MeMenuFormField
                label={firstDayOfWeekLabelText}
                name="firstDayOfWeek"
                inputComponent={Select}
                inputProps={{
                  options: daysOfWeek,
                  value: firstDayOfWeek,
                  handleChange,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </MeMenuContentSection>
      <Divider />
      <MeMenuContentSection header={dashboardHeaderText}>
        <StyledSwitchWrapper>
          <SwitchToggle
            dataTestId="dashboard-rotate"
            isChecked={dashboardRotate}
            handleSwitchChange={(e) => handleChange(e.target.checked, 'dashboardRotate')}
            switchText={dashboardRotateLabelText}
          />
        </StyledSwitchWrapper>
      </MeMenuContentSection>
      <Divider />
      <MeMenuContentSection header={notificationsHeaderText}>
        <StyledSwitchWrapper>
          <SwitchToggle
            dataTestId="email-notifications"
            isChecked={emailNotificationsFormat(emailNotifications)}
            handleSwitchChange={(e) => handleChange(e.target.checked, 'emailNotifications')}
            switchText={emailNotificationsLabelText}
          />
        </StyledSwitchWrapper>
      </MeMenuContentSection>
    </>
  );
}
