import { ReactComponent as AiStars } from 'images/icons/ai-stars.svg';
import { ReactComponent as WarningIcon } from 'images/icons/warning-icon.svg';
import { FloatingTicketInstanceModes } from 'features/floatingTicketPanel/floatingTicketPanelManager/modes';

import {
  StyledAutoPopulateTicket,
  StyledButtons,
  StyledFooter,
  StyledTextBox,
  StyledTitle,
  StyledNoDescriptionAlert,
  StyledWarningIcon,
} from './style';
import useTexts from './useTexts';
import { CONSTANTS } from './constants';

export const AutoPopulateTicket = ({
  autoFocus,
  data,
  handleChange,
  handleCancel,
  handleSave,
  panelMode,
  srHasDescription,
}) => {
  const {
    createNewIssueText,
    autoPopulatePlaceholder,
    skipToManualText,
    saveMeTimeText,
    descriptionAsNotePromptTitle,
  } = useTexts();
  const isPanelExpanded = panelMode === FloatingTicketInstanceModes.EXPANDED;

  return (
    <StyledAutoPopulateTicket>
      <StyledTitle data-testid="auto-populate-title">{createNewIssueText}</StyledTitle>
      <StyledTextBox
        data-testid="auto-populate-textbox"
        autoFocus={autoFocus}
        placeholder={autoPopulatePlaceholder}
        multiline
        inputProps={{ maxLength: CONSTANTS.INPUT_CHARACTERS_LIMIT, 'data-testid': 'auto-populate-textbox-field' }}
        fullWidth
        helperText={`${data.length}/${CONSTANTS.INPUT_CHARACTERS_LIMIT}`}
        onChange={handleChange}
        isDirty={data}
        isPanelExpanded={isPanelExpanded}
      />
      <StyledFooter>
        {!srHasDescription && (
          <StyledNoDescriptionAlert>
            <StyledWarningIcon>
              <WarningIcon />
            </StyledWarningIcon>
            {descriptionAsNotePromptTitle}
          </StyledNoDescriptionAlert>
        )}
        <StyledButtons>
          <button data-testid="auto-populate-cancel-btn" className="btn cancel" onClick={handleCancel}>
            {skipToManualText}
          </button>
          <button
            data-testid="auto-populate-save-btn"
            className="btn save"
            disabled={!data || data?.length > CONSTANTS.INPUT_CHARACTERS_LIMIT}
            onClick={handleSave}
          >
            <AiStars />
            <span>{saveMeTimeText}</span>
          </button>
        </StyledButtons>
      </StyledFooter>
    </StyledAutoPopulateTicket>
  );
};
