import { styled } from '@mui/material/styles';
import { ListItem } from '@mui/material';

const StyledUserInfoCardDetail = styled(ListItem)(({ theme }) => ({
  paddingBlock: theme.spacing(0.75),
  alignItems: 'center',
  maxWidth: 'max-content',
  '.MuiListItemIcon-root': {
    minWidth: theme.spacing(2),
    paddingRight: theme.spacing(1.5),
    paddingBlock: theme.spacing(0.25),

    '&[id$="secondary-action"]': {
      paddingRight: 0,

      '& .MuiIconButton-root': {
        marginLeft: theme.spacing(0.75),
        padding: 0,
      },
    },
  },

  '.MuiListItemText': {
    '&-root': {
      margin: 0,
    },

    '&-primary': {
      fontSize: '0.875rem',
      lineHeight: '1.4',
      fontFamily: theme.fonts.robotoRegular,
      color: theme?.palette?.text?.title_txt,

      '& .MuiTypography-root': {
        fontSize: 'inherit',
        lineHeight: 'inherit',
        textAlign: 'inherit',
        fontFamily: 'inherit',
        color: theme?.palette?.text?.title_txt,
      },

      '& .phone-number-ref': {
        textDecoration: 'none',
        color: theme?.palette?.text?.title_txt,
      },

      '& .phone-number-ref:hover': {
        textDecoration: 'underline',
        color: theme?.palette?.text?.blue_user_name
      }
    },
  },
}));

export default StyledUserInfoCardDetail;
