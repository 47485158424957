import { axiosInstance } from './axiosConfig';

export const createView = (createObj) => axiosInstance.post('queueviews', createObj).then((response) => response.data);
export const setViewAsDefault = (updateObj) =>
  axiosInstance.post('defaultqueueview', updateObj).then((response) => response.data);
export const updateView = (updateObj) => axiosInstance.patch('queueviews', updateObj).then((response) => response.data);
export const getAllViews = () => axiosInstance.get('queueviews').then((response) => response.data);
export const searchViews = (viewName) =>
  axiosInstance.get(`queueviews?viewName=${viewName}`).then((response) => response.data);
export const deleteView = (id) => axiosInstance.delete(`queueviews/${id}`).then((response) => response.data);

export const getColumnsConfigurationFields = () => axiosInstance.get('list').then((response) => response.data);
