import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Checkbox from 'common/components/checkbox';
import { SR_PANEL_CONSTANTS } from 'features/srPanel/consts';
import { useStatusList, useClosureInformationList, useSR } from 'remote-state/ticketServiceHooks';
import { useAllowSelfAssignQuery } from 'remote-state/accountSettingsServiceHooks';
import { selectActiveUser } from 'store/userSlice';
import useTexts from '../useTexts';
import Select from './Select';
import { StyleHeader, StyledAssignee, StyledDropDowns } from '../style';
import { editorActionData } from '../../../constants';
import { editorActionTypes } from '../../../constants/editorActionTypes';

const ResolutionHeader = ({ onChange, closureInformationCaption, statusCaption, assignee }) => {
  const userAccount = useSelector(selectActiveUser);
  const username = userAccount?.username;
  const {
    sr: { data: sr },
  } = useSR();

  const { assignToMe, resolutionStatus, closureInformation } = useTexts();
  const { data: allowSelfAssign } = useAllowSelfAssignQuery();
  const statusListInput = useMemo(
    () => [{ srType: sr.srType }, { valueClass: SR_PANEL_CONSTANTS.CLOSED_CLASS }],
    [sr.srType],
  );
  const { data: statusList } = useStatusList(statusListInput[0], statusListInput[1]);
  const { data: closureInformationList } = useClosureInformationList();
  const editorActionSettings = editorActionData[editorActionTypes.RESOLUTION];

  const handleAssignToMe = useCallback(
    ({ target }) => {
      const assignee = target.checked ? username : null;
      onChange('assignee', assignee);
    },
    [onChange, username],
  );

  const handleChangeDropDownSelection = useCallback(
    (key, keyCaption, option) => {
      onChange(key, option.valueKey);
      onChange(keyCaption, option.valueCaption);
    },
    [onChange],
  );

  return (
    <StyleHeader>
      <StyledDropDowns>
        <Select
          onChange={handleChangeDropDownSelection}
          options={statusList}
          selection={statusCaption}
          title={resolutionStatus}
          dataTestId="solutionResolutionStatus"
          keyValue={editorActionSettings.status}
          keyCaption={editorActionSettings.statusCaption}
          dataCy="solution-status"
          required
        />
        <Select
          onChange={handleChangeDropDownSelection}
          options={closureInformationList}
          selection={closureInformationCaption}
          title={closureInformation}
          dataTestId="closureInformation"
          keyValue={editorActionSettings.closureInformation}
          keyCaption={editorActionSettings.closureInformationCaption}
          dataCy="solution-closureInformation"
          required
        />
      </StyledDropDowns>

      {allowSelfAssign && username !== sr?.assignee && (
        <StyledAssignee data-testid="assign-to-me" data-cy="assign-to-me">
          <Checkbox checked={assignee} onChange={handleAssignToMe} />
          <span>{assignToMe}</span>
        </StyledAssignee>
      )}
    </StyleHeader>
  );
};

ResolutionHeader.propTypes = {
  closureInformationCaption: PropTypes.string,
  assignee: PropTypes.string,
  onChange: PropTypes.func,
};

ResolutionHeader.defaultProps = {
  closureInformationCaption: null,
  assignee: null,
  onChange: () => {},
};

export default ResolutionHeader;
