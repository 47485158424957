export const ITEM_TYPES = {
  SR: { id: 1, key: 'sr' },
  ASSET: { id: 5, key: 'asset' },
  CI: { id: 6, key: 'ci' },
};

export const TABLE_ROW_HEIGHT = 45;
export const TABLE_MAX_ROWS = 5;
export const MAX_LINKED_ITEMS = 50;
export const MAX_LINKED_ITEMS_FOR_PARENT = 100;
export const ITEMS_DROPDOWN_LEFT_OFFSET = 8;

export const LOCATION_FIELD_ID = 20;
export const LOCATION_FIELD_ATTRIBUTES = [{ fieldName: 'location', fieldId: LOCATION_FIELD_ID, customColumn: false }];

export const ASSET_URL = '/CompInfo.jsp?noframe=YES&computerID=';
export const CI_URL = '/CIEdit.jsp?noframe=YES&id=';

export const DEFAULT_ITEM_TYPE = ITEM_TYPES.SR;

export const SCROLL_POSITION_KEY_PREFIX = 'relatedItems_table_scrollPosition';
export const SR_RELATED_ITEMS = 'srRelatedItems';
export const SR_LINKED_INCIDENTS_COUNT = 'srLinkedIncidentsCount';
export const RECENT_ASSETS = 'recentAssets';
export const RECENT_CIS = 'recentCis';
export const RELATED_ITEMS_COLLAPSED_SESSION_KEY = 'relatedItemsCollapsed';
export const OMIT_API_SEARCH = -1;
export const FIELD_ATTRIBUTES = { fieldName: 'relatedItems', fieldId: OMIT_API_SEARCH };

export const UNCHANGED_CHILD_STATUS_POPUP = 'unchangedChildStatusPopup';
export const PARENT_STATUS_CHANGE_POPUP = 'parentStatusChangePopup';
export const SR_TO_INCIDENT_STATUS_CHANGE_POPUP = 'srToIncidentStatusChangePopup';

export const RELATIONSHIP_ENUMS = {
  1: 'none',
  2: 'causes',
  3: 'causedBy',
  4: 'parent',
  5: 'child',
  6: 'relatesTo',
  7: 'blocks',
  8: 'blockedBy',
  9: 'mergedFrom',
  10: 'mergedTo',
};

export const RELATIONSHIP_TYPES = {
  NONE: 1,
  CAUSED: 2,
  CAUSED_BY: 3,
  PARENT: 4,
  CHILD: 5,
  RELATES_TO: 6,
  BLOCKED: 7,
  BLOCKED_BY: 8,
  MERGED_FROM: 9,
  MERGED_TO: 10,
};

export const NEW_RELATIONSHIPS_IN_SPACES = [
  RELATIONSHIP_TYPES.RELATES_TO,
  RELATIONSHIP_TYPES.BLOCKED,
  RELATIONSHIP_TYPES.BLOCKED_BY,
];

export const PARENT_RELATIONSHIPS = [RELATIONSHIP_TYPES.PARENT, RELATIONSHIP_TYPES.CHILD];
