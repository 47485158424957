import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import logoImg from 'images/photos/sysaid-logo-green.png';
import { useEffect } from 'react';
import { IframeWrapper } from '../externalPage/iframe-wrapper';
import { StyledDialog, StyledHeader } from './styles';
import useTexts from './useTexts';
import { removeCookie } from '../../common/utils/utils';
import { APP_CONSTANTS } from '../../constants';

const ExternalModal = ({ open, onClose, url }) => {
  const { closeText } = useTexts();

  useEffect(() => () => removeCookie(APP_CONSTANTS.IN_SPACES_IFRAME_COOKIE), []);

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      fullWidth
    >
      <StyledHeader>
        <img src={logoImg} alt="sysaid-logo" />
        <Button
          variant="contained"
          className="btn"
          size="small"
          onClick={onClose}
          aria-label={closeText}
          data-testid="close-btn"
        >
          <CloseIcon />
          {closeText}
        </Button>
      </StyledHeader>
      <IframeWrapper url={url} />
    </StyledDialog>
  );
};

export default ExternalModal;
