import { useRef } from 'react';
import { Button } from '@mui/material';
import { BUTTON_THEMES } from 'common/components/buttons/constants';
import ButtonBase from 'common/components/buttons/FormButton';
import { removeCookie, getCookie, setCookie } from 'common/utils/utils';
import { useApplicationData } from 'remote-state/applicationHooks';
import { useFeatureFlagQuery } from 'remote-state/featureFlagsHooks';
import { ReactComponent as Switch } from 'images/icons/Switch.svg';
import { QUERIES_KEYS } from 'constant';
import { APP_CONSTANTS } from 'constants/index';
import { FEATURE_FLAGS_KEYS } from 'constants/featureFlags';
import useTexts from '../useTexts';
import { StyledFooter, StyledSwitchToClassicButton } from '../styles';
import FeedbackDialogOnSwitch from '../meMenuHeader/feedbackOnSwitch';

export default function MeMenuFooter({ disabled, handleSave, handleClose }) {
  const feedbackRef = useRef(null);
  const { footerSaveBtnText, footerCancelBtnText, switchToClassicBtnText } = useTexts();
  const { data: enabledSwitchUi } = useApplicationData(QUERIES_KEYS.ENABLED_SWITCH_UI);

  const { data: isFeedbackDialogOn } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.SHOW_FEEDBACK_ON_SWITCH_TO_CLASSIC,
    defaultValue: false,
  });

  const handleSwitchClick = () => {
    const isFeedbackAlreadyViewed = getCookie(APP_CONSTANTS.IN_SPACES_SWITCH_FEEDBACK_SHOWN_COOKIE);
    //If user viewed Feedback during last 90 days then we wouldnt show it again. We also check FF
    if (isFeedbackDialogOn && !isFeedbackAlreadyViewed) {
      feedbackRef.current?.open();
      setCookie(APP_CONSTANTS.IN_SPACES_SWITCH_FEEDBACK_SHOWN_COOKIE, 'true', 90);
    } else {
      removeCookie(APP_CONSTANTS.IN_SPACES_IFRAME_COOKIE);
      window.open('/index.jsp#/HelpDesk.jsp?fromId=List&showFirstPage=true&comeFromSpaces=true', '_self', 'noreferrer');
    }
  };

  return (
    <StyledFooter>
      {enabledSwitchUi && (
        <StyledSwitchToClassicButton>
          <Button
            data-testid="switch-to-classic-btn"
            variant="text"
            disableElevation
            disableRipple
            onClick={handleSwitchClick}
            startIcon={<Switch />}
          >
            {switchToClassicBtnText}
          </Button>
        </StyledSwitchToClassicButton>
      )}
      <ButtonBase data-testid="close-button" text={footerCancelBtnText} btnTheme={BUTTON_THEMES.SECONDARY} onClick={handleClose} />
      <ButtonBase data-testid="save-button" text={footerSaveBtnText} btnTheme={BUTTON_THEMES.PRIMARY} disabled={disabled} onClick={handleSave} />
      <FeedbackDialogOnSwitch ref={feedbackRef} />
    </StyledFooter>
  );
}
