import { actionEnums, FILTER_ENUMS } from './middlePanel/auditLog/constants';
import { editorActionTypes } from './middlePanel/auditLog/constants/editorActionTypes';

const journeyFilters = [
  FILTER_ENUMS.SUMMARY,
  FILTER_ENUMS.FULL_JOURNEY,
  FILTER_ENUMS.ACTIVITIES,
  FILTER_ENUMS.AUDIT_LOG_ITEMS,
  FILTER_ENUMS.EXPAND_SWITCH,
];

export const RESOLUTION_PANEL_CONSTANTS = {
  JOURNEY_FILTERS: journeyFilters,
  JOURNEY_SELECT: [
    {
      id: journeyFilters[0],
      valueKey: 0,
      valueCaption: 'summaryFilter',
    },
    {
      id: journeyFilters[1],
      valueKey: 1,
      valueCaption: 'fullJourneyFilter',
    },
    {
      id: journeyFilters[2],
      valueKey: 2,
      valueCaption: 'activitiesFilter',
    },
    {
      id: journeyFilters[3],
      valueKey: 3,
      valueCaption: 'auditLogItemsFilter',
    },
    {
      id: journeyFilters[4],
      valueKey: 4,
      valueCaption: 'expandSwitch',
    },
  ],
};

export const HAS_HEADER = [editorActionTypes.MESSAGE];

export const HAS_SIGNATURE = [editorActionTypes.MESSAGE];

export const LOG_DESCRIPTION_BREAK_LINE_TYPES = [
  actionEnums.ATTACHMENTS_ADDED,
  actionEnums.ATTACHMENTS_DELETED,
  actionEnums.ATTACHMENTS_DOWNLOADED,
  actionEnums.ACTION_ITEM_ATTACHMENTS_ADDED,
  actionEnums.ACTION_ITEM_ATTACHMENTS_DELETED,
  actionEnums.NOTE_UPDATE,
  actionEnums.NOTE_DELETE,
  actionEnums.NOTIFICATION,
  actionEnums.ESCALATION_MESSAGE,
  actionEnums.SOLUTION_UPDATED,
  actionEnums.RESOLUTION_UPDATED,
];
