import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    statusText: texts?.['spaces.actionItem.fields.status'],
    assigneeText: texts?.['spaces.gridTemplate.field.assignee'],
  };

  return componentTexts;
}
