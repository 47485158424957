import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getJourneyAuditLogsByFilterType } from 'services/auditLogsService';

const queryKey = ['journeyAuditLogsByType'];

export const useJourneyLogsByFilterQuery = ({
  srId,
  filterType,
  orderParam = 'logTimestamp',
  countRows = 1,
  select,
}) => {
  const queryClient = useQueryClient();
  const extendedQueryKey = [...queryKey, srId, filterType];

  const journeyAuditLogsByTypeQuery = useQuery({
    queryKey: extendedQueryKey,
    queryFn: () => getJourneyAuditLogsByFilterType({ srId, filterType, orderParam, countRows }),
    placeholderData: {},
    staleTime: 0,
    cacheTime: 0,
    enabled: !!srId && !!filterType && (typeof srId === 'number'),
    select,
  });

  const updateJourneyAuditLogsByType = (newValue) => {
    queryClient.setQueryData(extendedQueryKey, newValue);
  };

  return { journeyAuditLogsByTypeQuery, updateJourneyAuditLogsByType };
};
