import { useState, useCallback } from 'react';

export const useTrackIsCustomCompFocused = () => {
    const [isChildFocused, setIsChildFocused] = useState(false);

    const onFocus = useCallback(() => {
        setIsChildFocused(true);
    }, []);

    const onBlur = useCallback(() => {
        setIsChildFocused(false);
    }, []);

    return {
        onFocus,
        onBlur,
        isChildFocused,
    };
};
