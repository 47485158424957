import { ButtonUnstyled } from '@mui/core';
import { styled } from '@mui/styles';

export const StyledClearButton = styled(ButtonUnstyled, {
  shouldForwardProp: (prop) => prop !== 'iconPlacement' && prop !== 'disabled',
})(({ theme, iconPlacement, disabled }) => ({
  '&&': {
    fontSize: '0.875rem',
    color: disabled ? theme.palette.grey.bt_def_grey_light : theme.palette.text.title_txt,
    display: 'flex',
    flexDirection: iconPlacement === 'left' ? 'row' : 'row-reverse',
    alignItems: 'center',
    borderRadius: '4px',
    gap: '4px',
    padding: '6px 6px',
    width: 'fit-content',
    marginLeft: 'auto',
    border: 'none',
    outline: 'none',
    backgroundColor: 'white',
    '& svg': {
      filter: disabled
        ? 'brightness(0) saturate(100%) invert(91%) sepia(10%) saturate(235%) hue-rotate(179deg) brightness(88%) contrast(84%)'
        : 'brightness(0) saturate(100%) invert(31%) sepia(9%) saturate(12%) hue-rotate(2deg) brightness(99%) contrast(100%)',
    },

    '&:hover': {
      cursor: disabled ? 'auto' : 'pointer',
      backgroundColor: disabled ? 'transprent' : theme.palette.grey.bt_alt_grey_reg,
    },
  },
}));
