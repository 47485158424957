export const CONSTANTS = {
  NEW_VAL: 'new',
  RIGHT_BUFFER: 80,
  OPENED_MAX_WIDTH: 745,
  TICKET_RIGHT_MARGIN: 8,
  DEFAULT_RIGHT_BUFFER: 8,
  MAIN_PANEL_PADDINGS: 32,
  COLLAPSED_MIN_WIDTH: 106,
  DYNAMIC_OPENED_WIDTH: 0.69,
  COLLAPSED_DEFAULT_WIDTH: 360,
  MIN_COLLAPSED_TICKET_PADDINGS: 30,
  MAX_COLLAPSED_TICKET_PADDINGS: 40,
  COLLAPSED_TICKET_2_BTNS_WIDTH: 64,
  COLLAPSED_TICKET_3_BTNS_WIDTH: 96,
  COLLAPSED_TICKET_STYLE_BREAKPOINT: 138,
  NAV_BAR_EXPANDED_WIDTH: 240,
  NAV_BAR_COLLAPSED_WIDTH: 56,
};
