import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTemplateList = styled(Box, {
  shouldForwardProp: (prop) => !['hasFilters'].includes(prop),
})(({ theme, hasFilters }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',

  '.go-back': {
    padding: '10px 10px 20px 10px',
    cursor: 'pointer',
    width: 'fit-content',
    'button.back-icon-button': {
      minWidth: 'auto',
      marginRight: '10px',
    },
    '&> span': {
      fontSize: '12px',
    },
  },

  '.template-list-page': {
    marginTop: '3px',
    borderRadius: '10px',
    height: '100%',
    backgroundColor: 'white',
  },

  '& .container': {
    padding: '0px 24px 150px 24px',
    height: '100%',
  },

  '.template-name': {
    width: '100%',
    color: theme.palette.generic.secondary,
    display: 'flex',
    alignItems: 'center',
    gap: '10px',

    '.default-chip': {
      borderRadius: '12px',
      padding: '4px 12px',
      fontSize: theme.fontSize.small,
      color: theme.palette.generic.sysaid_default_hover,
      backgroundColor: theme.palette.generic.sysaid_very_light,
      textTransform: 'capitalize',
    },
  },

  '.list-actions': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',

    '& .template-preview-button': {
      lineHeight: '24px',

      '& .MuiButton-startIcon': {
        marginLeft: '0',
        marginRight: '4px',
      },
    },

    '& .more-action-button': {
      width: '19px',
      height: '19px',
      padding: '3px',
    },
  },

  '.created-by': {
    display: 'flex',
    width: '118px',
    alignItems: 'center',
    gap: '6px',
  },

  '.user-info': {
    color: '#787878',
    fontFamily: theme?.fonts?.robotoRegular,
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: '20px',
  },

  '& .table-body': {
    '.table-row': {
      borderRight: `1px solid ${theme?.palette?.grey?.divider_grey_v1}`,
      borderLeft: `1px solid ${theme?.palette?.grey?.divider_grey_v1}`,

      '& .cell': {
        height: '63px',
        maxHeight: '63px',
      },

      '& .cell:last-child': {
        borderRadius: `0 0 ${theme?.components?.main?.borderRadius} 0`,
      },

      '& .cell:first-child': {
        borderRadius: `0 0 0 ${theme?.components?.main?.borderRadius}`,
      },

      '.visibleInSelfServicePortalCell': {
        paddingRight: '48px', // 24px + 16px + 8px defaultPadding + sortIcon + gap
      },
    },
  },

  '.grid-scroll': {
    borderTop: 'none',
  },

  '& .wrapper > .table-body': {
    borderBottom: 'none',
  },

  '& .wrapper > .table-row': {
    '> .header-cell': {
      borderTop: hasFilters ? '' : `1px solid ${theme?.palette?.grey?.divider_grey_v1}`,
    },

    '> .header-cell:first-child': {
      borderLeft: `1px solid ${theme?.palette?.grey?.divider_grey_v1}`,
      borderRadius: hasFilters ? '' : `${theme?.components?.main?.borderRadius} 0 0 0`,
    },

    '> .header-cell:last-child': {
      borderRight: `1px solid ${theme?.palette?.grey?.divider_grey_v1}`,
      borderRadius: hasFilters ? '' : `0 ${theme?.components?.main?.borderRadius} 0 0`,
    },
  },

  '& .scroll-bar': {
    width: 'calc(100% + 4px)',
    marginRight: '-22px !important',

    '> .table-row:last-child': {
      borderBottom: `1px solid ${theme.palette.grey.divider_grey_v1}`,
      borderRadius: `0 0 ${theme.components.main.borderRadius} ${theme.components.main.borderRadius}`,
      marginBottom: '24px',
    },
  },

  '& .horizontal-scrollbar': {
    display: 'none',
  },

  '.grid-filters': {
    border: `1px solid ${theme?.palette?.grey?.divider_grey_v1}`,
    borderBottom: 'none',
    display: hasFilters ? '' : 'none',
  },
}));
