import { useCallback, useMemo } from 'react';
import { useAssigneesList, useFieldAttributes } from 'remote-state/ticketServiceHooks';
import { useDateSelectOptions, useGetCustomDate } from 'common/components/forwardRefFilter/hooks/useDateSelectOptions';
import { convertValue } from 'features/srPanel/templateFieldsGrid/fieldSearchableDropdown/values-convert';
import { ASSIGNEE_FIELD_ID, COLUMN_FILTER_TYPES } from 'features/queue/grid/constants';
import useTexts from './useTexts';

const CATEGORIES_FIELD_NAME = {
  'category.firstLevelKey': 'primaryCategory',
  'category.secondLevelKey': 'secondaryCategory',
  'category.thirdLevelKey': 'thirdLevelCategory',
};

export function useGetFiltersWithDisplayKeys(filters) {
  const { unassignedText, fromText, toText } = useTexts();
  const { dates } = useDateSelectOptions();
  const { getCustomDate } = useGetCustomDate();

  const {
    admins: { data: admins },
    groups: { data: groups },
  } = useAssigneesList();

  const unassignedObject = useMemo(
    () => ({
      key: 'unassigned',
      title: [unassignedText],
      userName: 'none',
      calculatedUserName: 'none',
      isUnassigned: true,
      id: -9999,
    }),
    [unassignedText],
  );

  const fields = useMemo(
    () =>
      filters
        ?.flatMap((filter) => ({
          fieldId: filter.fieldId,
          values: filter.values,
          customColumn: filter.customColumn,
          queryForIds: filter.fieldId !== ASSIGNEE_FIELD_ID, // @TODO add support for assignee field
        }))
        .filter((field) => !field.displayKeys?.length > 0),
    [filters],
  );

  const fieldsAttributesData = useFieldAttributes(fields);

  const getAttributesValues = useCallback((filter, valuesList) => {
    const values = filter?.values?.reduce(
      (acc, value) => {
        const data = valuesList.find((item) => String(item.id) === String(value));
        const resolvedAcc = acc;
        if (data) {
          const convertedData = convertValue(data);
          return {
            keys: [...resolvedAcc.keys, convertedData?.displayKey || convertedData?.valueCaption],
            list: [...resolvedAcc.list, { ...convertedData, id: Number(convertedData?.id) }],
          };
        }
        return {
          keys: resolvedAcc.keys,
          list: resolvedAcc.list,
        };
      },
      { keys: [], list: [] },
    );
    return values;
  }, []);

  const getAssigneeFilterValues = useCallback(
    (filter) => {
      const valuesList = filter?.field === 'assignedGroup' ? groups : admins;

      return filter?.values?.reduce(
        (acc, value) => {
          const isUnassigned = value === 'none';
          if (isUnassigned) {
            return {
              keys: [...acc.keys, unassignedText],
              list: [...acc.list, unassignedObject],
            };
          }
          const occurence = valuesList.find((v) => {
            const key = v.valueKey || v.groupName || v.userName || v.id;
            // some apis returns id as string and some as numbers
            // eslint-disable-next-line
            return key == value;
          });

          if (occurence) {
            return {
              keys: [...acc.keys, occurence?.calculatedUserName || occurence?.groupName],
              list: [...acc.list, occurence],
            };
          }
          return { keys: acc.keys, list: acc.list };
        },
        { keys: [], list: [] },
      );
    },
    [admins, groups, unassignedObject, unassignedText],
  );

  const getDateFilterValues = useCallback(
    (filter, keys, list) => {
      const values = filter?.values;
      switch (filter?.type) {
        case COLUMN_FILTER_TYPES.equals:
          return { keys: [getCustomDate(Number(values[0]))], list };

        case COLUMN_FILTER_TYPES.inRange:
          return {
            keys: [`${fromText} ${getCustomDate(Number(values[0]))} ${toText} ${getCustomDate(Number(values[1]))}`],
            list,
          };

        case COLUMN_FILTER_TYPES.dynamicTime:
          return { keys: [`${dates.find((date) => date.values === values[0])?.valueCaption}`], list: dates };

        default:
          return { keys, list };
      }
    },
    [dates, fromText, getCustomDate, toText],
  );

  const getOtherFilterValues = useCallback(
    (filter) => {
      switch (filter?.fieldType?.typeName) {
        case 'date': {
          return getDateFilterValues(filter, [], []);
        }

        default: {
          const fieldAttributeInfo = fieldsAttributesData?.find((fieldAttributesData) => {
            const fieldNameFromData = fieldAttributesData.data?.fieldName;
            const fieldName = CATEGORIES_FIELD_NAME[fieldNameFromData] ?? fieldNameFromData;
            return fieldName === filter.field;
          });
          if (fieldAttributeInfo) {
            const valuesList = fieldAttributeInfo?.data?.values;
            if (valuesList?.length > 0) {
              const values = getAttributesValues(filter, valuesList);
              return values;
            }
          }
        }
      }
      return {};
    },
    [fieldsAttributesData, getAttributesValues, getDateFilterValues],
  );

  const missingFilterDisplayKeys = useMemo(
    () =>
      filters?.map((filter) => {
        const isAssigneeFilter = filter?.field === 'assignedGroup' || filter.field === 'assignee';
        if (isAssigneeFilter) {
          const { keys, list } = getAssigneeFilterValues(filter);
          return { ...filter, displayKeys: keys, list };
        }
        if (filter.displayKeys?.length) {
          return { ...filter, displayKeys: filter.displayKeys, list: filter.values };
        }
        const { keys, list } = getOtherFilterValues(filter);
        return { ...filter, displayKeys: keys, list };
      }),

    [filters, getAssigneeFilterValues, getOtherFilterValues],
  );

  return missingFilterDisplayKeys;
}

export default useGetFiltersWithDisplayKeys;
