import { useUserDetailsByName } from 'remote-state/ticketServiceHooks';
import { AVATAR_CONSTANTS } from 'constants/index';
import { StyledTemplateLabel } from '../StyledTemplateLabel';
import { StyledTemplateMetadata } from './StyledTemplateMetadata';
import { BasicAvatar } from '../../../common/components/avatars/basicAvatar';
import useTexts from '../useTexts';

export default function TemplateMetadata(props) {
  const { dateCreated, createdBy, srType } = props;
  const formatDateCreated = dateCreated ? new Date(dateCreated).toLocaleDateString() : null;
  const { createDateText, createdByText, templateTypeText } = useTexts();
  const { data: userDetails } = useUserDetailsByName(createdBy);

  return (
    <StyledTemplateMetadata>
      <div>
        <StyledTemplateLabel id="created-by">{createdByText}</StyledTemplateLabel>
        <BasicAvatar
          size={AVATAR_CONSTANTS.SIZES.SMALL}
          userName={userDetails.userName}
          profileImage={userDetails.profileImage}
          disableHover
        />
        <StyledTemplateLabel className="label-dark">{createdBy}</StyledTemplateLabel>
      </div>
      {formatDateCreated && (
        <div>
          <StyledTemplateLabel>{createDateText}</StyledTemplateLabel>
          <StyledTemplateLabel className="label-dark">{formatDateCreated}</StyledTemplateLabel>
        </div>
      )}
      <div data-testid="type-metadata">
        <StyledTemplateLabel>{templateTypeText} </StyledTemplateLabel>
        <StyledTemplateLabel className="label-dark capitalize">{srType}</StyledTemplateLabel>
      </div>
    </StyledTemplateMetadata>
  );
}
