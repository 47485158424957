const CONFIG = {
  className: 'carousel-wrapper',
  animation: 'slide',
  autoPlay: false,
  indicators: false,
  navButtonsAlwaysVisible: true,
  navButtonsWrapperProps: { className: 'nav-button-wrapper' },
};

const MIN_ZOOM_AMOUNT = 0.5;

const MAX_ZOOM_AMOUNT = 2;

export { CONFIG, MIN_ZOOM_AMOUNT, MAX_ZOOM_AMOUNT };
