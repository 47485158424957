import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTheme } from '@mui/styles';
import { normalize, convertOptions } from './helpers';

export const useSelectControl = (
  initialOptions,
  isMultiple,
  handleChange,
  selection,
  keyString,
  isCategory,
  hasHierarchy,
  isGroupedHierarchy,
) => {
  const [options, setOptions] = useState(initialOptions);
  const [cursor, setCursor] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    setOptions(initialOptions);
  }, [initialOptions]);

  const convertedOptions = useMemo(
    () => convertOptions(options, theme, isCategory, hasHierarchy, selection),
    [options, theme, isCategory, hasHierarchy, selection],
  );

  const optionsMap = useMemo(
    () => normalize(convertedOptions, keyString, hasHierarchy),
    [convertedOptions, keyString, hasHierarchy],
  );

  const toggleCheckOption = useCallback(
    (id) => {
      if (isMultiple) {
        const selectedValues = hasHierarchy ? selection.values.map(String) : selection.map(String);
        const values = selectedValues.includes(String(id))
          ? selectedValues.filter((item) => item !== String(id))
          : [...selectedValues, id];
        handleChange(hasHierarchy ? { ...selection, values } : values, optionsMap);
      } else {
        const value = (
          isGroupedHierarchy ? Object.values(convertedOptions).flatMap((item) => item) : convertedOptions
        ).find((option) => String(option[keyString]) === String(id));
        handleChange(value, optionsMap);
      }
    },
    [isMultiple, handleChange, selection, convertedOptions, keyString, optionsMap, hasHierarchy, isGroupedHierarchy],
  );

  const toggleCheckGroup = useCallback(
    (group) => {
      if (selection.valueClasses.includes(group)) {
        const valueClassesFiltered = selection.valueClasses.filter((valueClass) => valueClass !== group);
        handleChange({ ...selection, valueClasses: valueClassesFiltered }, optionsMap);
      } else {
        const values = selection.values
          .map(String)
          .filter(
            (current) => !convertedOptions[group].find((groupValue) => String(groupValue[keyString]) === current),
          );
        handleChange({ values, valueClasses: [...selection.valueClasses, group] }, optionsMap);
      }
    },
    [selection, convertedOptions, optionsMap, handleChange, keyString],
  );

  const uncheckAll = useCallback(() => {
    if (hasHierarchy) {
      handleChange({ valueClasses: [], values: [] });
    } else {
      handleChange([]);
    }
  }, [handleChange, hasHierarchy]);

  const checkAll = useCallback(
    (filteredIds) => {
      if (hasHierarchy) {
        handleChange({ valueClasses: Object.keys(convertedOptions), values: [] }, optionsMap);
      } else {
        handleChange(filteredIds, optionsMap);
      }
    },
    [handleChange, convertedOptions, optionsMap, hasHierarchy],
  );

  const setOptionsMemo = useCallback((options) => setOptions(options), []);

  return {
    options: convertedOptions,
    optionsMap,
    setOptions: setOptionsMemo,
    toggleCheckOption,
    toggleCheckGroup,
    checkAll,
    uncheckAll,
    cursor,
    setCursor,
    isDirty: hasHierarchy ? selection.values.length > 0 || selection.valueClasses.length > 0 : selection.length > 0,
  };
};
