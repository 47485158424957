import { useTexts } from 'common/components/dropdownSelect/hooks';

export default function useDateSelectOptions() {
  const { today, yesterday, thisWeek, thisMonth, last30Days } = useTexts();

  const defaultRangeTitles = {
    today: 'Today',
    yesterday: 'Yesterday',
    thisWeek: 'This Week',
    thisMonth: 'This Month',
    last30Days: 'Last 30 Days',
  };

  const defaultRangeValues = {
    today: 'today',
    yesterday: 'yesterday',
    thisWeek: 'thisWeek',
    thisMonth: 'thisMonth',
    last30Days: 'last30Days',
  };

  const dates = [
    {
      valueKey: 1,
      valueCaption: today || defaultRangeTitles.today,
      values: defaultRangeValues.today,
    },
    {
      valueKey: 2,
      valueCaption: yesterday || defaultRangeTitles.yesterday,
      values: defaultRangeValues.yesterday,
    },
    {
      valueKey: 3,
      valueCaption: thisWeek || defaultRangeTitles.thisWeek,
      values: defaultRangeValues.thisWeek,
    },
    {
      valueKey: 4,
      valueCaption: thisMonth || defaultRangeTitles.thisMonth,
      values: defaultRangeValues.thisMonth,
    },
    {
      valueKey: 5,
      valueCaption: last30Days || defaultRangeTitles.last30Days,
      values: defaultRangeValues.last30Days,
    },
  ];

  return { dates };
}
