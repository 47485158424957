import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    profileTitleText: texts?.['spaces.mySettings.profile.profileTitle'],
    titleText: texts?.['spaces.mySettings.profile.title'],
    fileTypesTitleText: texts?.['spaces.mySettings.profile.fileTypesTitle'],
    fileSizeTitleText: texts?.['spaces.mySettings.profile.fileSizeTitle'],
    fileSizeErrorText: texts?.['spaces.mySettings.profile.fileSizeError'],
    fileTypesErrorText: texts?.['spaces.mySettings.profile.fileTypesError'],
    uploadFileErrorText: texts?.['spaces.mySettings.profile.uploadFileError'],
    cancelText: texts?.['spaces.mySettings.profile.cancel'],
    saveText: texts?.['spaces.mySettings.profile.save'],
    orText: texts?.['spaces.mySettings.profile.or'],
    uploadFileText: texts?.['spaces.mySettings.profile.uploadFileText'],
    chooseImageText: texts?.['spaces.mySettings.profile.chooseImage'] || 'Choose an image',
    dragImageText: texts?.['spaces.mySettings.profile.dragImage'] || 'drag it here',
  };
  return componentTexts;
}
