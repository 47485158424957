import { Box, styled } from '@mui/material';

export const TicketBodySelectButton = styled(Box, {
  shouldForwardProp: (prop) =>
    ![
      'hasTextStyles',
      'hasValues',
      'disabled',
      'isNewField',
      'isError',
      'isEmptyField',
      'anchor',
      'isTableView',
      'isTemplatePage',
    ].includes(prop),
})(
  ({
    theme,
    hasValues,
    disabled,
    isNewField,
    isError,
    isEmptyField,
    anchor,
    isTableView,
    isTemplatePage,
  }) => {
    let textColor = theme?.palette?.text?.txt_default;

    let backgroundColor = 'transparent';
    let hoveredBackground = theme?.palette?.background?.bg_hover_main;

    if (disabled) {
      hoveredBackground = 'transparent';
    }

    if (isNewField && isEmptyField && !disabled) {
      backgroundColor = theme?.palette?.background?.bg_new_input_text;
      hoveredBackground = theme?.palette?.background?.bg_hover_main;
    }
    if (isTableView && !disabled) {
      hoveredBackground = theme?.palette?.background?.bg_hover_alt;
    }

    if ((isError && !isNewField) || (isNewField && isEmptyField && isError)) {
      textColor = theme?.palette?.labels?.bt_red_reg;
      backgroundColor = theme?.palette?.labels?.bt_red_light;
      hoveredBackground = theme?.palette?.labels?.bt_red_mid;
    }

    const hoveredStyles = {
      cursor: !disabled ? 'pointer' : 'default',
      backgroundColor: hoveredBackground,
      color: isError ? theme.palette.labels.bt_yellow_green : textColor,
      borderRadius: theme.shape.border.borderRadius,
      '.warning-icon *': {
        fill: theme?.palette?.labels?.bt_red_dark,
      },
    };

    return {
      fontSize: theme.fontSize.main,
      position: 'relative',
      backgroundColor,
      height: isTemplatePage ? theme.spacing(4.5) : theme.spacing(3.5),
      padding: theme.spacing(1),
      color: !hasValues ? theme.palette.text.disable_txt : textColor,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      boxSizing: 'border-box',
      borderRadius: theme.shape.border.borderRadius,
      width: '100%',
      maxWidth: '224px',
      transform: isTableView ? '' : `translateX(-${theme.spacing(1)})`,
      '.warning-icon': {
        position: 'absolute',
        right: '8px',
        top: '6px',
      },
      '&:hover': hoveredStyles,
      ...(anchor ? hoveredStyles : {}),
    };
  },
);
