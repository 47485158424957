import { useMemo } from 'react';
import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = useMemo(
    () => ({
      getColumnText: (displayKey) => texts?.[displayKey],
      sortingInfoText: texts?.['spaces.queue.sortingInfoText'],
      filterCustomFieldExceeded: texts?.['spaces.queue.filterCustomFieldExceeded'] || 'You cannot have more than 3 filters or sorts on custom text fields at the same time',
    }),
    [texts],
  );

  return componentTexts;
}
