import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Assignee from 'common/components/assignee';
import { useAssigneesList } from 'remote-state/ticketServiceHooks';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import useTexts from 'common/components/assignee/useTexts';
import {
  addColumnFilter,
  removeColumnFilter,
  selectNotQuickFiltersModel,
  selectIsUnassigned,
  setIsUnassigned,
  clearAssigneeFilter,
} from '../../../slice';
import {
  ASSIGNEE_FIELD_ID,
  COLUMN_FILTER_ASSIGNEE,
  COLUMN_FILTER_ASSIGNEE_GROUP,
  COLUMN_FILTER_TYPES,
} from '../../../grid/constants';
import { QUEUE } from '../../../constants';
import useGetFiltersWithDisplayKeys from '../../useGetFiltersWithDisplayKeys';

export const AssigneeFilter = (props) => {
  const { disablePopover, isGridFilter, isQueueTableFilter } = props;
  const isUnassigned = useSelector(selectIsUnassigned);
  const fullFilterModel = useSelector(selectNotQuickFiltersModel);
  const assigneeFilterModels = useGetFiltersWithDisplayKeys(fullFilterModel);
  const [groupList, setGroupList] = useState([]);
  const [adminsList, setAdminsList] = useState([]);
  const [dropdownGroupName, setDropdownGroupName] = useState();
  const [isOutsideClickTriggered, setIsOutsideClickTriggered] = useState(false);
  const { assigneeFilterTitle } = useTexts();

  const {
    admins: { data: admins },
    groups: { data: groups },
    adminsByGroup: { data: dropdownGroupList },
  } = useAssigneesList(dropdownGroupName);
  const dispatch = useDispatch();

  const getAdminsFromFilter = useCallback(
    (assigneeFields) => {
      const list = [];
      if (Array.isArray(assigneeFields[0]?.list)) {
        list.push(...assigneeFields[0]?.list);
      } else {
        const findList = admins.find((item) => item.userName === assigneeFields[0]?.values[0]);
        if (findList) {
          list.push(findList);
        }
      }
      return list;
    },
    [admins],
  );

  useEffect(() => {
    const assigneeFields = assigneeFilterModels.filter((f) => f.field === COLUMN_FILTER_ASSIGNEE);
    const assigneeGroupFields = assigneeFilterModels.filter((f) => f.field === COLUMN_FILTER_ASSIGNEE_GROUP);
    const assigneeValues = getAdminsFromFilter(assigneeFields);
    const assigneeGroupValues =
      assigneeGroupFields.length && assigneeGroupFields[0].list ? assigneeGroupFields[0].list : [];

    setAdminsList(assigneeValues);
    setGroupList(assigneeGroupValues);
  }, [assigneeFilterModels, getAdminsFromFilter]);

  const updateGridConfiguration = useCallback(
    (values, keys, list, field = COLUMN_FILTER_ASSIGNEE) => {
      const displayKeys = keys;

      const filterObject = {
        filter: {
          field,
          fieldId: ASSIGNEE_FIELD_ID,
          values,
          list,
          name: field,
          type: COLUMN_FILTER_TYPES.equals,
          fieldType: { typeName: 'TEXT', id: 1 },
          displayKeys,
        },
        field,
      };
      if (list.length) {
        dispatch(addColumnFilter(filterObject));
      } else {
        dispatch(removeColumnFilter({ field }));
      }
    },
    [dispatch],
  );

  const updateFilterList = (list, value, key) => {
    const index = list.findIndex((object) => object[key] === value[key]);
    return index > -1 ? [...list.slice(0, index), ...list.slice(index + 1)] : [...list, value];
  };

  const handleSelectAssignee = useCallback(
    (admin, group) => {
      if (admin && !admin.isUnassigned) {
        if (isUnassigned) {
          dispatch(setIsUnassigned(false));
        }
        const updatedAdminsList = updateFilterList(adminsList, admin, 'calculatedUserName');
        setAdminsList(updatedAdminsList);
        updateGridConfiguration(
          updatedAdminsList.map((assignee) => assignee.userName),
          updatedAdminsList.map((assignee) => assignee.calculatedUserName),
          updatedAdminsList,
        );
      } else if (group) {
        const updatedGroupList = updateFilterList(groupList, group, 'groupName');
        setGroupList(updatedGroupList);
        updateGridConfiguration(
          updatedGroupList.map((grp) => grp.groupName),
          updatedGroupList.map((grp) => grp.groupName),
          updatedGroupList,
          COLUMN_FILTER_ASSIGNEE_GROUP,
        );
      } else {
        let unAssignedList = [];
        let newValuesList = [];
        if (!isUnassigned) {
          newValuesList = [...adminsList];
          newValuesList = updateFilterList(newValuesList, QUEUE.unassigned, 'calculatedUserName');
          unAssignedList = newValuesList.map((u) => u.calculatedUserName);
        } else {
          newValuesList = adminsList.filter((u) => u.calculatedUserName !== QUEUE.unassigned.calculatedUserName);
          unAssignedList = newValuesList.map((u) => u.calculatedUserName);
        }
        setAdminsList(unAssignedList);
        updateGridConfiguration(unAssignedList, unAssignedList, newValuesList);
        dispatch(setIsUnassigned(!isUnassigned));
      }
    },
    [groupList, adminsList, dispatch, updateGridConfiguration, isUnassigned],
  );

  const setAssigneeItems = ({ groups, admins }) => {
    const copiedAdminsNames = admins.map((assignee) => assignee.calculatedUserName);
    const copiedGroupsNames = groups.map((group) => group.groupName);
    setAdminsList(admins);
    setGroupList(groups);

    updateGridConfiguration(
      admins.map((assignee) => assignee.userName),
      copiedAdminsNames,
      admins,
    );
    updateGridConfiguration(copiedGroupsNames, copiedGroupsNames, groups, COLUMN_FILTER_ASSIGNEE_GROUP);
  };

  const handleClearAll = (admins, groups, withSearch) => {
    if (!withSearch) {
      dispatch(clearAssigneeFilter());
      dispatch(setIsUnassigned(false));
    } else {
      const collectAdminNames = admins.map((admin) => admin.userName);
      const collectGroupNames = groups.map((group) => group.groupName);

      const filteredAdmins = adminsList.filter((admin) => !collectAdminNames.includes(admin.userName));
      const filteredGroups = groupList.filter((group) => !collectGroupNames.includes(group.groupName));

      setAssigneeItems({ groups: filteredGroups, admins: filteredAdmins });
    }
  };

  const handleSelectAll = (admins, groups) => {
    const copiedAdmins = [...admins, ...adminsList];
    const copiedGroups = [...groups, ...groupList];

    // //If "Select all" clicked with search input we shouldn`t add unassigned option to the selected list.
    // if (!excludeUnassiged) {
    //   copiedAdmins = updateFilterList(copiedAdmins, QUEUE.unassigned, 'calculatedUserName');
    // }
    setAssigneeItems({ groups: copiedGroups, admins: copiedAdmins });
  };

  const clickAwayListener = () => {
    setIsOutsideClickTriggered(true);

    setTimeout(() => {
      setIsOutsideClickTriggered(false);
    }, 1000);
  };

  return (
    <ClickAwayListener onClickAway={clickAwayListener}>
      <Assignee
        assigneeTitle={isGridFilter || isQueueTableFilter ? '' : assigneeFilterTitle}
        filteredAssignees={adminsList}
        filteredGroups={groupList}
        isQueueTableFilter={isQueueTableFilter}
        isGridFilter={isGridFilter}
        handleChangeAssignees={handleSelectAssignee}
        isMultiSelect
        onClear={handleClearAll}
        onSelectAll={handleSelectAll}
        disablePopover={disablePopover}
        adminList={admins}
        groupList={groups}
        setDropdownGroupName={setDropdownGroupName}
        dropdownGroupName={dropdownGroupName}
        dropdownGroupList={dropdownGroupList}
        isOutsideClickTriggered={isOutsideClickTriggered}
        isSelectAllHidden
      />
    </ClickAwayListener>
  );
};
