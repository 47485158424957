import { useState, useContext, memo, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Status from 'common/components/status';
import Assignees from 'common/components/assignee/assignees';
import HeaderTags from 'features/header/header-tags';
import { TitleField } from 'common/components/controls/TitleField';
import { InlineFieldContainer } from 'common/components/controls/InlineField/InlineFieldContainer';
import { useFieldAttributes, useStatusList, useAssigneesList } from 'remote-state/ticketServiceHooks';
import { CONSTANTS } from 'common/components/controls/constants';
import { getDefaultAssignees, getDefaultCategory } from 'features/templateBuilder/utils/utils';
import { activeComponentTypeEnum, setActiveComponent } from 'features/templateBuilder/activeComponentSlice';
import {
  StyledTemplateHeader,
  StyledTemplateButtonsArea,
  StyledTemplateButtonsAreaRight,
} from './StyledTemplateHeader';
import useTexts from './useTexts';
import { TemplateBuilderContext } from '../../TemplateBuilderPanel/context';

export const extractField = (headerFields, key) => headerFields?.find((f) => f.fieldName === key);
export const extractCategoriesFields = (headerFields) =>
  headerFields.reduce(
    (result, curr) => {
      switch (curr.fieldName) {
        case 'category.firstLevelKey':
          result.primaryCategory = curr.defaultValue;
          break;
        case 'category.secondLevelKey':
          result.secondaryCategory = curr.defaultValue;
          break;
        case 'category.thirdLevelKey':
          result.thirdLevelCategory = curr.defaultValue;
          break;
        default:
          break;
      }
      return result;
    },
    {
      primaryCategory: null,
      secondaryCategory: null,
      thirdLevelCategory: null,
    },
  );

function FormHeader() {
  const { header, srType, handleStateUpdate } = useContext(TemplateBuilderContext);
  const dispatch = useDispatch();
  const priorityFieldId = header?.find((elm) => elm.fieldName === 'priority')?.fieldId;
  const fieldAttributesInput = useMemo(() => [{ fieldId: priorityFieldId }], [priorityFieldId]);
  const priorityList = useFieldAttributes(fieldAttributesInput)?.[0]?.data?.values;
  const statusListInput = useMemo(() => ({ srType }), [srType]);
  const { data: statusList } = useStatusList(statusListInput);
  const { formTitlePlaceholder } = useTexts();
  const [dropdownGroupName, setDropdownGroupName] = useState();
  const {
    admins: { data: admins },
    groups: { data: groups },
    adminsByGroup: { data: dropdownGroupList },
  } = useAssigneesList(dropdownGroupName);

  const assigneeField = extractField(header, 'assignee');
  const currentAssigned = useMemo(() => {
    const assignedGroupField = extractField(header, 'assignedGroup');
    const assigneeUserFiltered = admins?.filter((assignee) => assigneeField?.defaultValue === assignee.userName);
    const calculatedUserName =
      assigneeUserFiltered && assigneeUserFiltered.length ? assigneeUserFiltered[0].calculatedUserName : undefined;
    return {
      admin: { userName: assigneeField?.defaultValue || '', calculatedUserName },
      group: { groupName: assignedGroupField?.defaultValue || '' },
    };
  }, [header, admins, assigneeField]);

  const titleField = extractField(header, 'title');
  const priorityField = extractField(header, 'priority');
  const statusField = extractField(header, 'status');
  const categoriesValues = useMemo(() => extractCategoriesFields(header), [header]);

  const updateHeader = useCallback(
    (type, val) => {
      let newHeader;
      if (type === 'object') {
        newHeader = header.map((headerField) => {
          const newVal = val[headerField.fieldName];
          return {
            ...headerField,
            defaultValue: Object.keys(val).includes(headerField.fieldName) ? newVal : headerField.defaultValue,
          };
        });
      } else {
        newHeader = header.map((headerField) => ({
          ...headerField,
          defaultValue: headerField.fieldName === type ? val : headerField.defaultValue,
        }));
      }
      handleStateUpdate([{ header: newHeader }]);
    },
    [header, handleStateUpdate],
  );

  const onFocus = useCallback(
    (fieldId) => {
      dispatch(setActiveComponent({ componentType: activeComponentTypeEnum.HEADER, componentId: fieldId }));
    },
    [dispatch],
  );

  const handleSaveTitle = useCallback(({ title }) => updateHeader('title', title), [updateHeader]);

  const handleSaveAssignees = useCallback(
    (value) => updateHeader('object', getDefaultAssignees(value)),
    [updateHeader],
  );

  const handleSaveStatus = useCallback(({ id }) => updateHeader('status', id), [updateHeader]);

  const updateHeaderTags = useCallback(
    (value) => {
      if (Object.keys(value)[0] === 'priority') {
        updateHeader('priority', value.priority);
      } else {
        updateHeader('object', getDefaultCategory(value));
      }
    },
    [updateHeader],
  );

  const dataset = useMemo(
    () => ({
      'data-ispropertiesfield': true,
    }),
    [],
  );

  if (!header) {
    return null;
  }
  const headerFields = {};
  const defaultValues = {};

  if (header) {
    header.forEach((headerField) => {
      if (headerField.defaultValue) {
        defaultValues[headerField.fieldName] = headerField.defaultValue;
      }
      headerFields[headerField?.fieldName] = headerField.fieldId;
    });
  }

  return (
    <StyledTemplateHeader>
      <div data-cy="ticket-header-title">
        <InlineFieldContainer
          isTemplatePage
          onFocus={onFocus}
          dataset={dataset}
          className="template-page"
          name={titleField?.fieldName}
          fieldId={titleField?.fieldId}
          variant="templateDesignerTitle"
          text={defaultValues.title || ''}
          fieldName={titleField?.fieldName}
          CustomFieldComponent={TitleField}
          handleSaveValue={handleSaveTitle}
          placeholder={formTitlePlaceholder}
          limit={CONSTANTS.LIMIT_TITLE_CHARACTERS}
        />
      </div>
      <StyledTemplateButtonsArea>
        <HeaderTags
          isTemplatePage
          srType={srType}
          dataset={dataset}
          onFocus={onFocus}
          headerFields={header}
          priorityList={priorityList}
          className="template-header"
          dataTestid="template-priority"
          handleSaveValue={updateHeaderTags}
          priority={priorityField?.defaultValue}
          categoriesValues={categoriesValues}
        />
        <StyledTemplateButtonsAreaRight>
          <Assignees
            isTemplatePage
            admins={admins}
            groups={groups}
            onFocus={onFocus}
            dataset={dataset}
            bigAvatar
            onChange={handleSaveAssignees}
            fieldId={assigneeField?.fieldId}
            currentAssigned={currentAssigned}
            dropdownGroupName={dropdownGroupName}
            dropdownGroupList={dropdownGroupList}
            setDropdownGroupName={setDropdownGroupName}
          />
          <Status
            isTemplatePage
            srType={srType}
            onFocus={onFocus}
            dataset={dataset}
            statusList={statusList}
            onChange={handleSaveStatus}
            dataTestid="template-status"
            fieldId={statusField?.fieldId}
            status={statusField?.defaultValue}
          />
        </StyledTemplateButtonsAreaRight>
      </StyledTemplateButtonsArea>
    </StyledTemplateHeader>
  );
}
export default memo(FormHeader);
