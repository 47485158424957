import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { QUERIES_KEYS } from 'constant';
import { getUserDetailsByName } from 'services/userService';

export const useAssignee = () => {
  const queryClient = useQueryClient();

  const handleFindAssignees = useCallback(
    async ({ user, group }) => {
      let assignedTo;
      let assignedGroup;

      if (user) {
        const userData = await queryClient.fetchQuery({
          queryKey: [QUERIES_KEYS.USER_DETAILS_BY_NAME, user],
          queryFn: () => getUserDetailsByName(user),
        });
        assignedTo = userData || { id: 99999, calculatedUserName: user, userName: user };
        //in the case when user name is present, but user is not more present in database, show its name instead of empty space
        if (!userData?.calculatedUserName) assignedTo = { calculatedUserName: user, userName: user };
      }
      if (group) assignedGroup = { id: 99999, groupName: group };

      return { currentUser: assignedTo, currentGroup: assignedGroup };
    },
    [queryClient],
  );

  return { handleFindAssignees };
};
