import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledLogTitle = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isEditorAction' && prop !== 'isWorkflowNotification',
})(({ theme, isEditorAction, isWorkflowNotification }) => ({
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  maxWidth: '240px',
  ...(isWorkflowNotification && {
    //TODO: Handle correctly when tooltips are properly implemented on Journey items
    maxWidth: '400px',
  }),

  '.log-title-img-wrapper': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '32px',
    height: '32px',
    zIndex: 2,
    borderRadius: '50%',
    backgroundColor: theme.palette.generic.sysaid_light,
  },
  '.title-text': {
    display: 'flex',
    whiteSpace: 'break-spaces',
    alignItems: 'center',
    color: theme.palette.text.secondary_txt,
    fontSize: theme.fontSize.main,
    lineHeight: '1.429',
    fontFamily: theme.fonts.robotoRegular,
    height: '32px',
    paddingLeft: isEditorAction ? '8px' : '0px',
    ...(isWorkflowNotification && {
      //TODO: Handle correctly when tooltips are properly implemented on Journey items
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'inline-block',
      lineHeight: '2rem',
    }),
  },
  '.sub-title-text': {
    marginLeft: '16px',
    whiteSpace: 'break-spaces',
    color: theme.palette.text.secondary_txt,
    fontFamily: theme.fonts.robotoRegular,
  },
  '.by-text': {
    display: 'flex',
  },
}));
