import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';

export const StyledResolutionPanel = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'showTicketAsTab',
})(({ theme, showTicketAsTab }) => ({
  backgroundColor: theme.palette.generic.primary,
  overflowY: 'auto',
  flex: 3,
  borderRadius: '10px',
  marginTop: showTicketAsTab ? '22px' : '0px',
  '&:hover .vertical-scrollbar': {
    opacity: '1 !important',
  },
  // Fix double scrollbar for chrome
  '&.scroll-bar > div:first-of-type::-webkit-scrollbar': {
    background: 'transparent',
  },
  // Fix double scrollbar for other browsers
  '&.scroll-bar > div:first-of-type': {
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
  },

  '&.horizontal': {
    borderRadius: '20px',
  },

  '&.vertical': {
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
  },
}));
