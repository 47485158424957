import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  escalationPrompt: {
    show: false,
    srId: null,
  },
  isSectionsExpanded: null,
  SRStatus: {},
  prevSRStatus: {},
  updatingSRs: [], // service requests that sent update request but haven't settled yet
};

export const srSlice = createSlice({
  name: 'srNew',
  initialState,

  reducers: {
    updateEscalationPrompt: (state, { payload: updateData }) => {
      state.escalationPrompt = { ...state.escalationPrompt, ...updateData };
    },
    setIsSectionsExpanded: (state, { payload }) => {
      state.isSectionsExpanded = payload;
    },
    updateSRStatus: (state, { payload: updateData }) => {
      if (updateData === null) {
        state.SRStatus = {};
      } else {
        state.SRStatus = { ...state.SRStatus, ...updateData };
      }
    },
    updatePreviousSRStatus: (state, { payload: updateData }) => {
      state.prevSRStatus = { ...state.prevSRStatus, ...updateData };
    },
    addUpdatingSR: (state, { payload }) => {
      state.updatingSRs = [...state.updatingSRs, payload];
    },
    removeUpdatingSR: (state, { payload }) => {
      state.updatingSRs = state.updatingSRs.filter((updatingSR) => updatingSR !== payload);
    },
  },
});

export const {
  updateEscalationPrompt,
  setIsSectionsExpanded,
  updateSRStatus,
  updatePreviousSRStatus,
  addUpdatingSR,
  removeUpdatingSR,
} = srSlice.actions;

export const selectEscalationPrompt = (allSliceState) => allSliceState.sr.escalationPrompt;

export const selectIsSectionsExpanded = (allSliceState) => allSliceState.sr.isSectionsExpanded;

export const selectSRStatus = (allSliceState) => allSliceState.sr.SRStatus;

export const selectPrevSRStatus = (allSliceState) => allSliceState.sr.prevSRStatus;

export const selectUpdatingSRs = (allSliceState) => allSliceState.sr.updatingSRs;

export default srSlice.reducer;
export const srSliceName = srSlice.name;
