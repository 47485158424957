import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledIconButton = styled(Box)(({ theme, expanded, isDisabled }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '32px',
  height: '32px',
  borderRadius: '100%',
  background: isDisabled ? theme.palette.generic.sysaid_default_hover : theme.palette.generic.sysaid_light,
  transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)',
  path: {
    stroke: isDisabled && theme.palette.generic.primary,
  },

  '&:hover': {
    background: theme.palette.generic.sysaid_hover,
    path: {
      fill: 'transparent',
      filter: 'brightness(99999)',
    },
  },
}));
