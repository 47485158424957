import ListItemText from '@mui/material/ListItemText';
import { ReactComponent as DragIcon } from '../../../images/icons/gh_dragg.svg';
import { StyledDragOverlayField } from './StyledDragOverlayField';

export default function DragOverlayField(props) {
  const { fieldName, isBig } = props;
  return (
    <StyledDragOverlayField disableTouchRipple isBig={isBig}>
      <DragIcon className="drag-icon" />
      <ListItemText primary={fieldName} data-testid="field-name" />
    </StyledDragOverlayField>
  );
}
