import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTemplateNameWrapper = styled(Box)(() => ({
  textAlign: 'left',
}));

export const StyledTemplateNameLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary_txt,
  fontFamily: theme.fonts.robotoRegular,
  fontSize: theme.fontSize.small,
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '14px',
  padding: '2px 0',
}));

export const StyledTemplateName = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.title_txt,
  fontFamily: theme.fonts.robotoRegular,
  width: '100%',
  fontSize: theme.fontSize.main,
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '24px',
  padding: '4px 0 0',
  whiteSpace: 'normal',
  overflow: 'hidden',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
  display: '-webkit-box',
}));
