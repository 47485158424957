import { ReactComponent as IconHome } from 'images/icons/iconHome.svg';
import { StyledTooltip } from 'common/components/controls/Tooltip/Tooltip';
import useTexts from 'features/queue/gridConfigurationHeader/useTexts';
import { IconStyle } from './style';

export default function DefaultViewIcon() {
  const { defaultViewTooltip } = useTexts();

  return (
    <IconStyle data-testid="default-icon">
      <StyledTooltip placement="top" title={defaultViewTooltip}>
        <IconHome />
      </StyledTooltip>
    </IconStyle>
  );
}
