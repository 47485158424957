import ListItemText from '@mui/material/ListItemText';
import { useRbTexts } from 'remote-state/applicationHooks';
import { ReactComponent as DragIcon } from 'images/icons/gh_dragg.svg';
import { ReactComponent as ToggleIcon } from 'images/icons/accordionToggleButton.svg';
import { StyledDragOverlaySection } from './StyledDragOverlaySection';

export default function DragOverlaySection({ sectionName }) {
  const texts = useRbTexts();
  const name = texts[sectionName] || sectionName;
  return (
    <StyledDragOverlaySection disableTouchRipple>
      <DragIcon className="drag-icon" />
      <ToggleIcon className="toggle-icon" />
      <ListItemText primary={name} />
    </StyledDragOverlaySection>
  );
}
