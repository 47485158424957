import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTable = styled(Box, {
  shouldForwardProp: (prop) =>
    !['rowHeight', 'rowsCountFitInTable', 'tableCollapsed', 'isLastTable', 'isSrTable'].includes(prop),
})(({ rowHeight, rowsCountFitInTable, tableCollapsed, isLastTable }) => ({
  display: 'flex',
  width: '100%',
  '.wrapper': {
    maxHeight: `calc(${rowsCountFitInTable * rowHeight + rowHeight}px)`,
  },
  '.table-body': {
    maxHeight: `calc(${rowsCountFitInTable * rowHeight}px)`,
  },
  '.MuiAccordion-root': {
    width: '100%',
  },
  '.MuiCollapse-root': {
    height: !tableCollapsed ? '0px !important' : `calc(${rowsCountFitInTable * rowHeight + rowHeight}px) !important`,
    marginBlockEnd: isLastTable && '20px',
  },
}));
