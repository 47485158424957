import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledGroupDropdown = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledGroupDetailsWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'width',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  padding: '6px 0px 6px 4px',

  '.group-details': {
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
  },
  '.group-name': {
    color: 'black',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    maxWidth: '170px',
  },
  '.group-members-count': {
    fontSize: '0.75rem',
    color: theme.palette.grey.bt_def_grey_reg,
  },
  svg: {
    transform: 'rotate(-180deg)',
    cursor: 'pointer',
  },
}));
