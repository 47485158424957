import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';

export const StyledTicketTabs = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  boxSizing: 'border-box',
  alignItems: 'center',
}));

export const StyledTabContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '11px',
  height: '100%',
  width: '100%',
  paddingTop: '24px',
}));
