import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  prompt: null,
};

export const messagePromptSlice = createSlice({
  name: 'messagePrompt',
  initialState,

  reducers: {
    showPrompt: (state, action) => {
      state.prompt = action.payload;
    },
    cancelPrompt: (state) => {
      state.prompt = null;
    },
  },
});

export const { showPrompt, cancelPrompt } = messagePromptSlice.actions;

export const selectMessagePrompt = (state) => state.messagePrompt.prompt;

export default messagePromptSlice.reducer;
export const messagePromptSliceName = messagePromptSlice.name;
