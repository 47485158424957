import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledLinksComponent = styled(Box)(({ theme, error, active }) => () => {
  let borderColor = theme.palette.background.bg_hover_alt;
  if (active) {
    borderColor = theme.palette.generic.sysaid_default;
  }
  if (error) {
    borderColor = theme.palette.labels.bt_red_reg;
  }
  return {
    border: '1px solid',
    borderRadius: '4px',
    borderColor: `${borderColor}!important`,
    minHeight: '40px',
    circle: {
      fill: error && theme?.palette?.labels?.bt_red_hover,
    },
    ':hover': {
      background: error && `${theme?.palette?.generic?.primary}!important`,
      '.drag-icon': {
        opacity: 1,
      },
    },
  };
});

export const StyledLinksWrapper = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
}));

export const StyledLinkItemWrapper = styled(Box)(({ theme, widthSize, linkName, active }) => () => {
  let color = theme.palette.text.secondary_txt;
  let width = '100%';
  if (widthSize) {
    width = '170px';
  }
  if (linkName?.length) {
    color = theme.palette.text.blue_user_name;
  } else {
    color = theme.palette.text.secondary_txt;
  }
  return {
    border: 'unset',
    width,
    minWidth: widthSize ? '170px' : 'unset',
    ':hover': {
      cursor: widthSize ? 'text' : 'pointer',
    },
    '.MuiFormControl-root': {
      border: !widthSize && 'none!important',
      '&:hover': {
        background: !widthSize && active && `${theme.palette.background.bg_hover_main}!important`,
      },
    },
    '.MuiInputBase-root': {
      border: !widthSize && 'none!important',
      '&:hover': {
        background: !widthSize && active && `${theme.palette.background.bg_hover_main}!important`,
      },
    },
    '.MuiInputBase-input': {
      textFillColor: 'none!important',
      color: `${color}!important`,
      '::placeholder': {
        color: `${theme.palette.text.secondary_txt}!important`,
      },
    },
    '.Mui-disabled': {
      textFillColor: `${color}!important`,
      color: `${color}!important`,
      '::placeholder': {
        color: `${theme.palette.text.secondary_txt}!important`,
      },
    },
  };
});

export const StyledLinkItem = styled(Box)(({ theme, error, active, editable }) => () => {
  let inputBgColor = theme?.palette?.generic?.primary;
  let inputBgHover = theme?.palette?.generic?.primary;
  let inputBorderColor = 'transparent';
  if (error || editable) {
    inputBorderColor = theme.palette.grey.divider_grey_v3;
  }
  if (active && editable) {
    inputBgHover = theme?.palette?.generic?.primary;
    inputBgColor = theme?.palette?.generic?.primary;
  }
  if (!editable && active) {
    inputBgColor = theme?.palette?.generic?.primary;
    inputBgHover = theme.palette.background.bg_hover_main;
  }
  if (error) {
    inputBgColor = theme?.palette?.generic?.primary;
    inputBgHover = theme?.palette?.generic?.primary;
  }
  if (!active) {
    inputBgColor = theme?.palette?.generic?.primary;
    inputBgHover = theme?.palette?.generic?.primary;
  }
  return {
    width: '100%',
    display: 'flex',
    columnGap: '3px',
    padding: '3px',
    alignItems: 'center',
    boxSizing: 'border-box',
    borderRadius: '4px',
    background: inputBgColor,
    borderBottom: '1px solid #DADCDE',
    '.MuiInputBase-root': {
      borderRadius: '4px',
      border: `1px solid ${inputBorderColor}!important`,
      padding: '6px 8px!important',
    },
    ':hover': {
      cursor: 'pointer',
      background: inputBgHover,
      '.MuiInputBase-root': {
        background: `${inputBgHover}!important`,
      },
      '.Mui-focused': {
        background: `${inputBgHover}!important`,
      },
      '.icons_wrapper': {
        display: 'flex',
      },
    },
  };
});

export const StyledLinkComponentHeader = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const StyledClearButton = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary_txt,
  cursor: 'pointer',
  fontSize: '12px',
  lineHeight: '16px',
  paddingLeft: '2px',
  '.clear-icon': {
    marginRight: '6px',
  },
}));
export const StyledLinkItemConfigure = styled(Box)(({ theme, editable, isTemplatePage }) => ({
  width: editable && isTemplatePage ? '0' : '118px',
  color: theme.palette.text.secondary_txt,
  fontSize: '16px',
  lineHeight: '20px',
  borderRadius: '4px',
  margin: '6px 0',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '8px',
}));

export const StyledCreatedTime = styled(Box)(({ theme, editable }) => ({
  color: editable ? theme.palette.text.disable_txt_V2 : theme.palette.text.secondary_txt,
  fontSize: '12px',
  lineHeight: '16px',
  whiteSpace: 'nowrap',
  ':hover': {
    background: theme?.palette?.generic?.primary,
  },
}));

export const StyledIconsWrapper = styled(Box)(({ isTemplatePage }) => ({
  position: 'absolute',
  right: isTemplatePage ? '5px' : '20px',
  cursor: 'pointer',
  display: 'none',
  width: '55px',
  height: '28px',
  background: '#fff',
  boxShadow: '0px 2px 5px rgba(48, 48, 48, 0.3)',
  borderRadius: '4px',
  boxSizing: 'border-box',
  justifyContent: 'space-around',
  alignItems: 'center',
  span: {
    width: '22px',
    height: '22px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    ':hover': {
      background: 'rgba(149, 169, 201, 0.3)',
    },
  },
}));

export const StyledEmptyLink = styled(Box)(({ theme }) => ({
  width: '90px',
  height: '28px',
  display: 'flex',
  alignItems: 'center',
  margin: '5px auto',
  justifyContent: 'center',
  padding: '0 5px',
  borderRadius: '5px',
  cursor: 'pointer',
  '&:hover': {
    background: theme.palette.background.bg_hover_main,
  },
}));

export const StyledEmptyLinkText = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary_txt,
  fontSize: '16px',
  lineHeight: '20px',
  borderRadius: '4px',
  margin: '6px auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
