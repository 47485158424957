import { SR_TYPES } from 'features/TicketPanel/constants';
import { useUserInfo } from 'remote-state/userServiceHooks';
import { QUERIES_KEYS } from 'constant';
import { BASIC_EDITION } from 'features/settings/constants';
import { useRbTexts, useApplicationData } from 'remote-state/applicationHooks';

export type ServiceRequestType = { type: string; title: string };

const useServiceRequestTypeList = (): ServiceRequestType[] => {
  const texts = useRbTexts();

  const { newTicketIncident, newTicketRequest, newTicketProblem, newTicketChange } = {
    newTicketIncident: texts?.['spaces.navigation-bar.newTicket.Incident'],
    newTicketRequest: texts?.['spaces.navigation-bar.newTicket.Request'],
    newTicketProblem: texts?.['spaces.navigation-bar.newTicket.Problem'],
    newTicketChange: texts?.['spaces.navigation-bar.newTicket.Change'],
  };

  const { data } = useUserInfo(QUERIES_KEYS.CURRENT_USER_PERMISSIONS);
  const { data: edition } = useApplicationData(QUERIES_KEYS.EDITION);

  const userPermissionChgMgmtCreateWithoutDesign = data?.userPermissionChgMgmtCreateWithoutDesign;
  const userPermissionChgMgmtCreateRFC = data?.userPermissionChgMgmtCreateRFC;

  let newTicketTypes = [
    { type: SR_TYPES.incident, title: newTicketIncident },
    { type: SR_TYPES.request, title: newTicketRequest },
  ];

  let canUserCreateChangesOrProblems = true;
  if (!userPermissionChgMgmtCreateWithoutDesign && !userPermissionChgMgmtCreateRFC) {
    canUserCreateChangesOrProblems = false;
  }

  if (canUserCreateChangesOrProblems) {
    newTicketTypes = newTicketTypes.concat([
      { type: SR_TYPES.problem, title: newTicketProblem },
      { type: SR_TYPES.change, title: newTicketChange },
    ]);
  }

  const isBasicEdition = edition === BASIC_EDITION;
  if (isBasicEdition) {
    newTicketTypes = [{ type: SR_TYPES.incident, title: newTicketIncident }];
  }

  return newTicketTypes;
};

export default useServiceRequestTypeList;
