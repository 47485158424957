import { useQuery } from '@tanstack/react-query';
import { APP_CONSTANTS } from 'constants/app';
import { getFlagValue, getFlags } from 'services/featureFlagsService';

export const useFeatureFlagQuery = ({ flagKey, defaultValue }) => {
  const accountId = localStorage.getItem(APP_CONSTANTS.ACCOUNT_ID_LOCAL_KEY);

  return {
    ...useQuery({
      queryKey: ['featureFlag', { accountId, flagKey }],
      queryFn: () => getFlagValue({ flagKey, accountId, defaultValue }).catch(() => defaultValue),
      enabled: !!(accountId && flagKey),
      staleTime: 1000 * 20,
    }),
  };
};

export const useFeatureFlagsQuery = () => {
  const accountId = localStorage.getItem(APP_CONSTANTS.ACCOUNT_ID_LOCAL_KEY);

  return {
    ...useQuery({
      queryKey: ['featureFlags', { accountId }],
      queryFn: () => getFlags({ accountId }),
      enabled: !!accountId,
    }),
  };
};
