import { useRef, useMemo, useCallback, forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import { FormControlLabel, Radio, Box } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { APP_CONSTANTS, PENDO_TRACK_EVENTS } from 'constants/index';
import useTexts from 'features/navBar/meMenu/useTexts';
import { removeCookie } from 'common/utils/utils';
import CustomDialog from 'common/components/dialog/index';
import usePendoTrackEvents from 'common/utils/hooks/usePendoTrackEvents';
import { StyledTextField, StyledRadioGroup } from './style';

function FeedbackDialogOnSwitch(_, ref) {
  const {
    submitText,
    skip,
    switchFeedbackTitle,
    switchFeedbackMissingFeatures,
    switchFeedbackTooComplicated,
    switchFeedbackPerformanceIssues,
    switchFeedbackTemporary,
    switchFeedbackCareElobrate,
  } = useTexts();
  const dialogRef = useRef(null);
  const pendoTrackEvents = usePendoTrackEvents();
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { isValid },
    setFocus,

    watch,
  } = useForm({
    defaultValues: {
      reason: null,
      description: '',
    },
    mode: 'onChange',
  });
  const selectedReason = watch('reason');

  useImperativeHandle(ref, () => ({
    open: () => {
      dialogRef.current?.open();
    },
  }));

  const feedBackOptionsList = useMemo(
    () => [
      {
        label: switchFeedbackMissingFeatures,
        value: 'Missing features',
      },
      {
        label: switchFeedbackTooComplicated,
        value: 'Too complicated for me',
      },
      {
        label: switchFeedbackPerformanceIssues,
        value: 'Performance issue',
      },
      {
        label: switchFeedbackTemporary,
        value: 'Just a temporary action',
      },
      {
        label: switchFeedbackCareElobrate,
        value: 'Care to elaborate?',
      },
    ],
    [
      switchFeedbackMissingFeatures,
      switchFeedbackTooComplicated,
      switchFeedbackPerformanceIssues,
      switchFeedbackTemporary,
      switchFeedbackCareElobrate,
    ],
  );

  const handleClose = useCallback(() => {
    setTimeout(() => {
      dialogRef.current?.close();
      removeCookie(APP_CONSTANTS.IN_SPACES_IFRAME_COOKIE);
      window.open('/index.jsp#/HelpDesk.jsp?fromId=List&showFirstPage=true&comeFromSpaces=true', '_self', 'noreferrer');
    }, 1500);
  }, []);

  const handleSkip = useCallback(() => {
    setLoading(true);
    pendoTrackEvents(PENDO_TRACK_EVENTS.SWITCH_TO_CLASSIC_FEEDBACK, {
      reason: null,
      description: '',
      skip: true,
    });
    handleClose();
  }, [pendoTrackEvents, handleClose]);

  const handleOnFormSubmit = useCallback(
    (data) => {
      setLoading(true);
      pendoTrackEvents(PENDO_TRACK_EVENTS.SWITCH_TO_CLASSIC_FEEDBACK, { ...data, skip: false });
      handleClose();
    },
    [handleClose, pendoTrackEvents],
  );

  const actions = useMemo(
    () => [
      { label: skip, onClick: handleSkip, disabled: loading, 'data-testid': 'switchToClassicFeedbackSkip' },
      {
        label: submitText,
        onClick: handleSubmit(handleOnFormSubmit),
        btnTheme: 'primary',
        disabled: !isValid && loading,
        'data-testid': 'switchToClassicFeedbackSubmit',
      },
    ],
    [submitText, skip, isValid, handleSubmit, handleOnFormSubmit, handleSkip, loading],
  );

  const isTextAreaShown = useMemo(() => selectedReason === 'Care to elaborate?', [selectedReason]);

  useEffect(() => {
    if (isTextAreaShown) {
      setFocus('description');
    }
  }, [isTextAreaShown, setFocus]);

  return (
    <CustomDialog ref={dialogRef} onClose={handleClose} title={switchFeedbackTitle} actions={actions} maxWidth="sm">
      <Box>
        <Controller
          name="reason"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <StyledRadioGroup {...field} data-testid="switchToClassicFeedbackReason">
              {feedBackOptionsList.map((option) => (
                <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
              ))}
            </StyledRadioGroup>
          )}
        />
      </Box>
      <Box sx={{ display: isTextAreaShown ? 'block' : 'none' }}>
        <Controller
          name="description"
          control={control}
          rules={{ required: false }}
          render={({ field: { onChange, value, ref } }) => (
            <StyledTextField
              size="small"
              onChange={onChange}
              inputRef={ref}
              value={value}
              minRows={6}
              maxRows={10}
              fullWidth
              data-testid="switchToClassicFeedbackDescription"
              multiline
            />
          )}
        />
      </Box>
    </CustomDialog>
  );
}

export default forwardRef(FeedbackDialogOnSwitch);
