import { ReactComponent as GoBackIcon } from 'images/icons/new_back_icon.svg';

function GoBack({ onClickParent }) {
  return (
    // TODO: dont make this a sepperate component, use button instead of div
    // eslint-disable-next-line
    <div className="btn-prev-next" onClick={onClickParent} data-testid="btn-prev-next">
      <GoBackIcon />
    </div>
  );
}
export default GoBack;
