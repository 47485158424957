import { useCallback, useState } from 'react';

export const useDropdownControl = (isReadOnly, onOpen) => {
  const [anchor, setAnchor] = useState(null);

  const handleOpen = useCallback(
    (event) => {
      if (!isReadOnly) {
        if (onOpen) {
          onOpen();
        }
        setAnchor(event.currentTarget || event.target);
      }
    },
    [isReadOnly, onOpen],
  );

  const handleClose = useCallback(() => {
    setAnchor(null);
  }, []);

  return { anchor, handleOpen, handleClose };
};
