import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fields: [],
};

const isFieldExist = (field, sourceId, fieldId) => field.sourceId === sourceId && field.fieldId === fieldId;

export const richTextFieldsSlice = createSlice({
  name: 'richTextFields',
  initialState,

  reducers: {
    updateRichFieldData: (state, action) => {
      const { fieldId, sourceId, data } = action.payload;
      if ((fieldId || fieldId === 0) && (sourceId || sourceId === 0)) {
        const exitingElement = state.fields.find((field) => isFieldExist(field, sourceId, fieldId));
        if (exitingElement) {
          state.fields = state.fields.map((field) => {
            if (isFieldExist(field, sourceId, fieldId)) {
              field.data = { ...field.data, ...data };
            }
            return field;
          });
        } else {
          state.fields.push({ fieldId, sourceId, data, lastSavedData: data, isEditorDirty: false });
        }
      }
    },
    resetSavedRichFieldData: (state, action) => {
      const { fieldId, sourceId, lastSavedData } = action.payload;
      state.fields = state.fields.map((field) => {
        if (isFieldExist(field, sourceId, fieldId)) {
          return { ...field, lastSavedData };
        }
        return field;
      });
    },
    setIsRichFieldEditorDirty: (state, action) => {
      const { fieldId, sourceId, isEditorDirty } = action.payload;
      state.fields = state.fields.map((field) => {
        if (isFieldExist(field, sourceId, fieldId)) {
          return { ...field, isEditorDirty };
        }
        return field;
      });
    },
    removeRichFieldBySource: (state, action) => {
      const { sourceId } = action.payload;
      state.fields = state.fields.filter((field) => field.sourceId !== sourceId);
    },
    clearRichFields: (state) => {
      state.fields = [];
    },
  },
});

export const {
  setIsRichFieldEditorDirty,
  updateRichFieldData,
  removeRichFieldBySource,
  clearRichFields,
  resetSavedRichFieldData,
} = richTextFieldsSlice.actions;

export const selectRichTextFields = (state) => state.richTextFields.fields;

export const findField = (fields, id) => fields.find((field) => field.fieldId === id);

export default richTextFieldsSlice.reducer;
export const richTextFieldsSliceName = richTextFieldsSlice.name;
